import React from "react";
import AppComponent from './AppComponent';
import StatusComponent from './StatusComponent';
import { Status } from './StatusComponent';
import {  get_check, get_nocheck, get_unknowncheck, get_pendingcheck } from './Utils';

import { Radio } from 'antd';
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class PreferenceOption extends AppComponent {
  render() {
    const { value, style, onChange, status, disabled, options, unselectable } = this.props;

    return (
      <div style={ style }>
        <RadioGroup value={ value } buttonStyle={ status == this.STATUS_NONE ? "outline" : "solid" } onChange={ onChange } disabled={ disabled }>
          { options.map(el => <RadioButton key={ el.id } disabled={ unselectable && unselectable.find(u => u == el.id) != null || (el.id == 5 && status == this.STATUS_NONE) }  value={ el.id }>{ el.text }</RadioButton> ) }
        </RadioGroup>
        { "\u00a0\u00a0" }
        <Status status={ status } />
      </div>
    );
  }
}

class Preference extends StatusComponent {

  defaultOptions = [{id: 1, text: "No"},
                    {id: 2, text: "Willing"},
                    {id: 3, text: "Ready"},
                    {id: 4, text: "Eager"}];

  defaultFieldName = "preference";

  getInitialStatus = () => {
    return this.props.value ? this.STATUS_GOOD : this.STATUS_NONE;
  }

  getRequest = (new_value) => {
    const { value, onChange, endpoint, addNewData, fieldName } = this.props;

    const data = { [fieldName ? fieldName : this.defaultFieldName]: new_value };

    if ( value && value != 1) {
      if (new_value == 5){
        return { method: "DELETE",
        endpoint: endpoint + value.id + "/",
        body: JSON.stringify(data),
        onChange: onChange, };
      } else {
        return { method: "PATCH",
        endpoint: endpoint + value.id + "/",
        body: JSON.stringify(data),
        onChange: onChange, };
      }
      
    } else {
      addNewData ? addNewData(data) : null;
      return { method: "POST",
               endpoint: endpoint,
               body: JSON.stringify(data),
               onChange: onChange, };
    }
  }

  render() {
    const { value, editable, options, defaultValue, fieldName } = this.props;
    const { status } = this.state;

    return ( <PreferenceOption {...this.props} disabled={ !editable } value={ value ? value[fieldName ? fieldName : this.defaultFieldName] : typeof defaultValue !== 'undefined' ? defaultValue : 1 } onChange={ (e) => this.setValue(e.target.value) } status={ status } options={ options ? options : this.defaultOptions } /> );
  }
}

export default Preference;
