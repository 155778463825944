import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import { oxford, get_load_total } from '../../Utils';
import { CSVLink } from "react-csv";

import { Tabs, PageHeader, Layout, Table, Form, Switch, Button, Modal, Input, InputNumber, Radio, Select, Menu, Dropdown, Icon, Tooltip, Checkbox, Alert, Divider, List, Card, Breadcrumb, Popconfirm, message, Typography } from 'antd';
import CustomTabs from "../../CustomTabs";
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;


class PreferenceBreakdown extends AppComponent {
    state = {
        endpoint_course_prefs: "/api/preferences/course/",
        course_prefs: [],
        loading_course_prefs: true,

        endpoint_meetingtime_prefs: "/api/preferences/meetingtime/",
        meetingtime_prefs: [],
        loading_meetingtime_prefs: true,

        endpoint_pref_notes: "/api/preferences/note/",
        pref_notes: [],
        loading_pref_notes: true,

        selectedCampus: [this.props.campus],
    };

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
            this.setState({ loading_course_prefs: true, loading_meetingtime_prefs: true, selectedCampus: [this.props.campus] });
            this.getData();
        }
    }

    getData = () => {
        this.doGet(this.state.endpoint_course_prefs + "?semester=" + this.props.semesters.join(","), data => this.setState({ course_prefs: data, loading_course_prefs: false }));
        this.doGet(this.state.endpoint_meetingtime_prefs + "?semester=" + this.props.semesters.join(","), data => this.setState({ meetingtime_prefs: data, loading_meetingtime_prefs: false }));
        this.doGet(this.state.endpoint_pref_notes + "?semester=" + this.props.semesters.join(","), data => this.setState({ pref_notes: data, loading_pref_notes: false }));
    }

    convertPreference = (preferenceNum) => {
        return preferenceNum == 4 ? "Eager" : preferenceNum == 3 ? "Ready" : preferenceNum == 2 ? "Willing" : "No";
    }

    filterTablePrefRender = (list, filterAgainst, record, preference) => {
        return (<><ul>{list.filter(el => el[filterAgainst] == record & el.preference == preference).map(el => <li>{this.print_full_instructor(el.faculty)}</li>)}</ul></>);
    }

    filterTablePrefDownload = (list, filterAgainst, record, preference) => {
        return list.filter(el => el[filterAgainst] == record & el.preference == preference).map(el => this.print_full_instructor(el.faculty));
    }

    handleCampusChange = (value) => {
        this.setState({ selectedCampus: value })
    }


    render() {
        const { selectedCampus, course_prefs, loading_course_prefs, meetingtime_prefs, loading_meetingtime_prefs, loading_pref_notes, pref_notes } = this.state;

        let campusCoursePrefs = course_prefs.filter(el => el.preference != 1 & selectedCampus.includes(this.get_instructor(el.faculty).campus));
        let campusMeetingtimes = meetingtime_prefs.filter(el => el.preference != 1 & selectedCampus.includes(this.get_instructor(el.faculty).campus));
        let campusPrefNotes = pref_notes.filter(el => selectedCampus.includes(this.get_instructor(el.faculty).campus)).sort((a, b) => this.instructor_comparator(this.get_instructor(a.faculty), this.get_instructor(b.faculty)));
        let courses = [...new Set(campusCoursePrefs.map(el => el.course))].sort((a, b) => this.course_comparator(this.get_course(a), this.get_course(b)));
        let meetingtimes = [...new Set(campusMeetingtimes.map(el => el.meetingtime))].sort((a, b) => this.meetingtime_comparator(this.get_meetingtime(a), this.get_meetingtime(b)));


        const courseColumns = [
            {
                title: 'Course',
                key: 'course',
                width: '25%',
                render: (text, record, idx) => this.print_full_course(record),
                download: (record) => this.print_course(record),
            }, {
                title: 'Preferences',
                key: 'preferences',
                children: [
                    {
                        title: 'Eager',
                        key: 'eager',
                        render: (text, record, idx) => this.filterTablePrefRender(campusCoursePrefs, "course", record, 4),
                        download: (record) => this.filterTablePrefDownload(campusCoursePrefs, "course", record, 4),
                    }, {
                        title: 'Ready',
                        key: 'ready',
                        render: (text, record, idx) => this.filterTablePrefRender(campusCoursePrefs, "course", record, 3),
                        download: (record) => this.filterTablePrefDownload(campusCoursePrefs, "course", record, 3),
                    }, {
                        title: 'Willing',
                        key: 'willing',
                        render: (text, record, idx) => this.filterTablePrefRender(campusCoursePrefs, "course", record, 2),
                        download: (record) => this.filterTablePrefDownload(campusCoursePrefs, "course", record, 2),
                    }]
            }];

        const meetingtimeColumns = [
            {
                title: 'Meeting Time',
                key: 'meetingtime',
                width: '25%',
                render: (text, record, idx) => this.print_meetingtime(record),
                download: (record) => this.print_meetingtime(record),
            }, {
                title: 'Preferences',
                key: 'preferences',
                children: [
                    {
                        title: 'Eager',
                        key: 'eager',
                        render: (text, record, idx) => this.filterTablePrefRender(campusMeetingtimes, "meetingtime", record, 4),
                        download: (record) => this.filterTablePrefDownload(campusMeetingtimes, "meetingtime", record, 4),
                    }, {
                        title: 'Ready',
                        key: 'ready',
                        render: (text, record, idx) => this.filterTablePrefRender(campusMeetingtimes, "meetingtime", record, 3),
                        download: (record) => this.filterTablePrefDownload(campusMeetingtimes, "meetingtime", record, 3),
                    }, {
                        title: 'Willing',
                        key: 'willing',
                        render: (text, record, idx) => this.filterTablePrefRender(campusMeetingtimes, "meetingtime", record, 2),
                        download: (record) => this.filterTablePrefDownload(campusMeetingtimes, "meetingtime", record, 2),
                    }]
            }];

        const noteColumns = [
            {
                title: 'Instructor',
                key: 'instructor',
                width: '25%',
                render: (text, record, idx) => this.print_full_instructor(record.faculty),
            }, {
                title: 'Note',
                key: 'notes',
                render: (text, record, idx) => record.notes,
            }];

        return (
            <Content {...this.props} title={this.props.semester + " Teaching Preferences Breakdown"} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { text: "Planning" }, { text: this.props.semester }]}>
                <div style={{ marginBottom: "10px" }}>
                    <p>Please use the below tabs to view the faculty submitted teaching and meeting time preferences, if desired, please use the below campus selector to view multiple campuses. By default, faculty preferences are organized by their home campus.</p>
                    <Form layout="inline">
                        <FormItem label="Campus">
                            <Select mode="multiple" style={{ width: '250px' }} value={selectedCampus} maxTagCount={1} onChange={this.handleCampusChange}>{this.campus_list().filter(el => el.popular && (this.permission("can", "admin") || this.props.user.campuses.includes(el.id))).map(el => (<Option value={el.id} disabled={this.props.campus == el.id}>{this.print_campus(el.id)}</Option>))}</Select>
                        </FormItem>
                    </Form>
                </div>
                <CustomTabs {...this.props}>
                    <TabPane tab={"Course Preferences"} key={"course"}>
                        <div style={{ display: "flex" }}>
                            <span style={{ marginLeft: "auto", marginBottom: "5px" }}>
                                <CSVLink filename="faculty_preferences_course_export.csv" data={this.getCsvData(courses, courseColumns)}><Button icon="download">Download</Button></CSVLink>
                            </span>
                        </div>
                        <Table {...this.props} dataSource={courses} loading={loading_course_prefs} columns={courseColumns} size="small" pagination={false} />
                    </TabPane>
                    <TabPane tab={"Meeting Time Preferences"} key={"meetingtime"}>
                        <div style={{ display: "flex" }}>
                            <span style={{ marginLeft: "auto", marginBottom: "5px" }}>
                                <CSVLink filename="faculty_preferences_meetingtime_export.csv" data={this.getCsvData(meetingtimes, meetingtimeColumns)}><Button icon="download">Download</Button></CSVLink>
                            </span>
                        </div>
                        <Table {...this.props} dataSource={meetingtimes} loading={loading_meetingtime_prefs} columns={meetingtimeColumns} size="small" pagination={false} />
                    </TabPane>
                    <TabPane tab={"Preference Notes"} key={"notes"}>
                        <Table {...this.props} dataSource={campusPrefNotes} loading={loading_pref_notes} columns={noteColumns} size="small" pagination={false} />
                    </TabPane>
                </CustomTabs>
            </Content>
        );
    }
}

export default PreferenceBreakdown;