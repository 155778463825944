import React, { Component } from "react";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import SectionTable from "./SectionTable";
import CoordinatorTable from "./CoordinatorTable";
import ClassSizeTable from "./ClassSizeTable";
import Content from "./Content";

import AppComponent from "./AppComponent";
import { oxford, get_load_total } from './Utils';

import { PageHeader, Layout, Table, Form, Switch, Collapse, Button, Modal, Input, InputNumber, Radio, Select, Menu, Dropdown, Icon, Tooltip, Checkbox, Alert, Divider, List, Card, Breadcrumb, Popconfirm, message, Typography } from 'antd';
const Option = Select.Option;
const OptGroup = Select.OptGroup;
const FormItem = Form.Item;
const ButtonGroup = Button.Group;
const RadioGroup = Radio.Group;
const { Text, Title } = Typography;
const { TextArea } = Input;

function getBlankPreferencesArray() {
  return [{ title: "Eager", objects: [] },
  { title: "Ready", objects: [] },
  { title: "Willing", objects: [] },
  { title: "No Preference", objects: [] },
  { title: "No", objects: [] },
  { title: "Other", objects: [] }];
}

const PlannedSectionImportForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      disabled: false,
      semester: null,
      gradugrad: null,
      campuses: [],
    };

    reset = () => { this.setState({ disabled: false, semester: null, gradugrad: null, campuses: [] }) }

    render() {
      const { visible, onCancel, onImport, form, item, sections, importSemester } = this.props;
      const { getFieldDecorator } = form;
      const { disabled, semester, gradugrad, campuses } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      return (
        <Modal
          visible={visible}
          title={"Import sections to " + this.print_semester(importSemester)}
          okText={disabled ? "Importing sections" : "Import"}
          onCancel={() => { onCancel(); this.reset(); }}
          onOk={() => { this.setState({ disabled: true }, () => onImport(this.reset)) }}
          okButtonProps={{ disabled: disabled }}
          width={800}
        >
          <p>Use the form below to create sections based on the sections that existing in a previous semester.  You can select whether you wish to import on graduate or undergraduate classes, as well as which campus(es) to import from.  For each unique <i>course</i> offered in the selected semester (no matter the number of sections), the form will create one section of that course.</p>
          <Form onSubmit={this.handleSubmit} >
            <FormItem {...formItemLayout} label="Semester">
              {getFieldDecorator('semester', {
                initialValue: semester,
                rules: [{ required: true, message: 'Please select the semester you wish to import from.' }],
              })(<Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                {this.semester_list().filter(s => importSemester && (s.code < this.get_semester(importSemester).code)).map(el => <Option key={el.id} value={el.id}>{this.print_semester(el.id)}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Grad/Undergrad">
              {getFieldDecorator('gradugrad', {
                rules: [{ required: true, message: 'Please select an option.' }],
                initialValue: gradugrad,
                onChange: (event) => { this.setState({ gradugrad: event }) },
              })(<RadioGroup>
                <Radio value="both">Both</Radio>
                <Radio value="graduate">Graduate</Radio>
                <Radio value="undergraduate">Undergraduate</Radio>
              </RadioGroup>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Campus(es)">
              {getFieldDecorator('campuses', {
                initialValue: campuses,
                rules: [{ required: true, message: 'Please the campus(es) to import from.' }],
                onChange: (event) => { this.setState({ campuses: event }) },
              })(<Select showSearch mode="multiple" style={{ width: 360 }}>
                {this.campus_list().filter(c => c.popular).map(c => <Option key={c.id} value={c.id}>{this.print_campus(c.id)}</Option>)}
              </Select>
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

const PlannedSectionForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      instructors: [],
    }

    componentDidMount() {
      this.reset(null);
    }

    componentDidUpdate(prevProps) {
      if ((prevProps.item != this.props.item) || (prevProps.initial_semester != this.props.initial_semester) || (prevProps.semester != this.props.semester) || (prevProps.campus != this.props.campus)) {
        this.reset(null);
      }
    }

    reset = (sem) => {
      const { initial_semester } = this.props;

      this.setState({
        semester: this.props.item ? this.props.item.semester : sem ? sem : initial_semester,
        campus: this.props.item ? this.props.item.campus : this.props.campus,
        course: this.props.item ? this.props.item.course : null,
        title: this.props.item ? this.props.item.title : null,
        method: this.props.item && this.props.item.method ? this.props.item.method : 1,
        meetingtime: this.props.item ? this.props.item.meetingtime : null,
        honaccnustartnuin: this.props.item ? (this.props.item.honors ? "honors" : this.props.item.accelerated ? "accelerated" : this.props.item.nustart ? "nustart" : this.props.item.nuin ? "nuin" : "none") : "none",
        instructors: this.props.item ? this.props.item.instructors : [],
        capacity: this.props.item ? this.props.item.capacity : null,
        room: this.props.item && this.props.item.room ? this.props.item.room : null,
        crosslist: this.props.item ? this.props.item.crosslist : null,
        ugrad_stats: this.props.item ? this.props.item.ugrad_stats : false,
        loadcount: this.props.item ? this.props.item.loadcount : 1,
        notes: this.props.item ? this.props.item.notes : null,
      });
    }

    mapPreference = (data) => {
      return data ? (data.preference == 1 ? 4 : 4 - data.preference) : 3;
    }

    addInstructorLoad = (i) => {
      const { loads, sections, other_sections, coordinators } = this.props;
      const load = loads.find(el => el.instructor == i.id);
      const assigned = sections.filter(el => !el.deleted && el.instructors.includes(i.id)).reduce((r, a) => r + this.get_instructor_section_loadcount(a, a.crosslist ? sections.find(s => s.id == a.crosslist) : null, i.id), 0) + other_sections.filter(el => el.instructors.includes(i.id)).reduce((r, a) => r + this.get_instructor_section_loadcount(a, a.crosslist ? other_sections.find(s => s.id == a.crosslist) : null), 0) + coordinators.filter(c => c.instructor == i.id).reduce((r, a) => r + a.loadcount, 0)

      i.load = load ? get_load_total(load) : -1;
      i.assigned = assigned

      return i;
    }

    getInstructorList = () => {
      const { course_prefs, campus } = this.props;
      const { course } = this.state;

      var instructorlist = getBlankPreferencesArray();

      const local_instructors = this.instructor_list().filter(i => i.campus == campus);
      const other_instructors = this.instructor_list().filter(i => i.campus != campus);

      local_instructors.forEach(el => { instructorlist[this.mapPreference(course_prefs.find(pel => pel.faculty == el.id && pel.course == course))].objects.push(this.addInstructorLoad(el)); });

      instructorlist = instructorlist.concat({ title: "Other campuses", objects: other_instructors.map(i => this.addInstructorLoad(i)) });

      return instructorlist;
    }

    getMeetingTimeList = () => {
      const { meetingtime_prefs, campus } = this.props;
      const { instructors } = this.state;

      const compare = (a, b) => a < b ? -1 : (a > b ? 1 : 0);

      var meetingtimelist = getBlankPreferencesArray();
      this.meetingtime_list().forEach(el => { meetingtimelist[this.mapPreference(meetingtime_prefs.find(pel => pel.meetingtime == el.id && instructors && instructors.includes(pel.faculty)))].objects.push(el); });
      return meetingtimelist;
    }

    getCrosslistList = () => {
      const { item, sections } = this.props;
      const { course, meetingtime } = this.state;

      //filter sections based on if there is no item or if the selected planned section id is not equal to the option id,
      //AND the meetingtimes are the same
      const filtered = sections.filter(el => (!item || (item.id != el.id)) && (meetingtime == el.meetingtime));

      //if there isn't an already existing planning section record selected that is being edited
      if (!item) {

        //if a course is selected and that course has a crosslist already 
        if (course && this.get_course(course).crosslist) {
          //then return that item (planningsection) id to negative 1, and return it with the rest of the filtered list.
          //this adds an option to the crosslist for creating a new section of that crosslisted course as well. 
          return [{ id: -1, course: this.get_course(course).crosslist, instructors: [] }].concat(filtered);
        }

        return filtered;
      } else {
        return filtered;
      }
    }

    render() {
      const { visible, onCancel, onCreate, form, item, sections } = this.props;
      const { getFieldDecorator } = form;
      const { semester, campus, course, title, method, meetingtime, honaccnustartnuin, capacity, room, crosslist, instructors, ugrad_stats, loadcount, notes } = this.state;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      const instructorlist = this.getInstructorList();
      const meetingtimelist = this.getMeetingTimeList();
      //whenever the form is rendered, this list of potential crosslists get's called
      //item is the selected modal item
      const crosslistlist = this.getCrosslistList();

      return (
        <Modal
          visible={visible}
          title={item ? "Edit section " : "Create a new section"}
          okText={item ? "Save" : "Create"}
          onCancel={() => { onCancel(); this.reset(null); }}
          onOk={() => { onCreate(); this.reset(null); }}
          width={800}
        >
          <Form onSubmit={this.handleSubmit} >
            <FormItem {...formItemLayout} label="Semester">
              {getFieldDecorator('semester', {
                rules: [{ required: true, message: 'Please select a semester.' }],
                initialValue: semester,
                onChange: (event) => { this.reset(event) },
              })(<Select showSearch style={{ width: 360 }} filterOption={this.filter} disabled={this.props.semesters.length == 1} >
                {this.props.semesters.map(el => <Option key={el} value={el}>{this.print_semester(el)}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Campus">
              {getFieldDecorator('campus', {
                rules: [{ required: true, message: 'Please select a campus.' }],
                initialValue: campus,
                onChange: (event) => { this.setState({ campus: event }, () => { if (event == 118) { this.props.form.setFieldsValue({ meetingtime: null, room: null, method: 6, ugrad_stats: false }) } else { this.props.form.setFieldsValue({ method: 1 }) } }) },
              })(<Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                {this.campus_list().filter(el => el.popular && (this.permission("can", "admin") || el.id == this.props.campus || el.name == "Online")).map(el => <Option key={el.id} value={el.id}>{this.print_campus(el.id)}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Course">
              {getFieldDecorator('course', {
                rules: [{ required: true, message: 'Please select a course.' }],
                initialValue: course,
                onChange: (event) => { this.setState({ course: event, course_prefs: [], crosslist: this.get_course(event).crosslist ? -1 : null, ugrad_stats: this.get_course(event).ugrad_stats, loadcount: this.get_course(event).loadcount }) },
              })(<Select showSearch style={{ width: 360 }} filterOption={this.filter} >
                {this.course_list().filter(el => !el.deprecated).map(el => <Option key={el.id} value={el.id}>{this.print_full_course(el.id)}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Title">
              {getFieldDecorator('title', {
                initialValue: title,
                onChange: (event) => { this.setState({ title: event }) },
              })(<Input style={{ width: 360 }} placeholder={course ? this.get_course(course).title : ""} />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Instructor(s)">
              {getFieldDecorator('instructors', {
                initialValue: instructors,
                onChange: (event) => { this.setState({ instructors: event, meetingtime_prefs: [] }) },
              })(<Select showSearch mode="multiple" style={{ width: 360 }} filterOption={this.filter} >
                {instructorlist.map((el, idx) => [<Option is_header={true} key={"label-" + el.title} disabled={true}>{el.title}</Option>].concat(
                  el.objects.map(i => (<Option key={i.id} value={i.id}>{[this.print_instructor(i.id) + " (", <NumberFormat key="num-assigned" displayType="text" decimalScale={2} fixedDecimalScale={true} value={i.assigned} />, "/", i.load == -1 ? "?" : <NumberFormat key="num-load" displayType="text" decimalScale={2} fixedDecimalScale={true} value={i.load} />, ")"]}</Option>))
                ))}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Meeting Time">
              {getFieldDecorator('meetingtime', {
                initialValue: meetingtime,
                onChange: (event) => { this.setState({ meetingtime: event }) },
              })(<Select showSearch style={{ width: 360 }} allowClear={true} filterOption={this.filter} disabled={meetingtimelist.length == 0}>
                {meetingtimelist.map(el => (
                  <OptGroup key={el.title} label={el.title} >
                    {el.objects.map(i => (<Option key={i.id} value={i.id}>{this.print_meetingtime(i.id)}</Option>))}
                  </OptGroup>
                ))}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Method">
              {getFieldDecorator('method', {
                rules: [{ required: true, message: 'Please select an instruction method.' }],
                initialValue: method,
                onChange: (event) => { this.setState({ method: event }) },
              })(<Select showSearch style={{ width: 120 }} filterOption={this.filter} >
                {this.method_list().map(el => <Option key={el.id} value={el.id} disabled={(el.id == 6 && campus != 118) || (el.id != 6 && campus == 118)}>{el.name}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Honors/Accelerated">
              {getFieldDecorator('honaccnustartnuin', {
                rules: [{ required: true, message: 'Please select an option.' }],
                initialValue: honaccnustartnuin,
                onChange: (event) => { this.setState({ honaccnustartnuin: event }) },
              })(<RadioGroup>
                <Radio value="none">None</Radio>
                <Radio value="honors">Honors</Radio>
                <Radio value="accelerated">Accelerated</Radio>
                <Radio value="nustart">NUStart</Radio>
                <Radio value="nuin">NUin</Radio>
              </RadioGroup>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Capacity">
              {getFieldDecorator('capacity', {
                initialValue: capacity,
                onChange: (event) => { this.setState({ capacity: event }) },
              })(<InputNumber style={{ width: 120 }} min={1} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Room">
              {getFieldDecorator('room', {
                initialValue: room,
                onChange: (event) => { this.setState({ room: event }) },
              })(<Select showSearch style={{ width: 120 }} allowClear={true} filterOption={this.filter} disabled={campus == 118} >
                {this.room_list().filter(el => el.ccis_owned).map(el => (
                  <Option key={el.id} value={el.id} disabled={!el.active}>{el.building.abbrv + " " + el.number}</Option>
                ))}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Crosslist">
              {getFieldDecorator('crosslist', {
                initialValue: crosslist,
                onChange: (event) => { this.setState({ crosslist: event }) },
              })(<Select showSearch style={{ width: 360 }} allowClear={true} filterOption={this.filter} >
                {crosslistlist
                  .map(el => <Option key={el.id} value={el.id} disabled={el.crosslist && (!item || !(el.crosslist.crosslist == item.id))}>{(el.id == -1 ? "Create new section of " : "") + this.print_course(el.course) + " " + oxford(el.instructors.map(this.print_instructor)) + (el.meetingtime ? " at " + this.print_meetingtime(el.meetingtime) : "")}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Load Count">
              {getFieldDecorator('loadcount', {
                rules: [{ required: true, message: 'Please enter the teaching load credit.' }],
                initialValue: loadcount,
                onChange: (event) => { this.setState({ loadcount: event }) },
              })(<InputNumber style={{ width: 120 }} min={0} max={1} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Undergraduate Stats">
              {getFieldDecorator('ugrad_stats', {
                initialValue: ugrad_stats,
                valuePropName: 'checked',
                onChange: (event) => { this.setState({ ugrad_stats: event }) },
              })(<Checkbox disabled={campus == 118}>
              </Checkbox>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Notes">
              {getFieldDecorator('notes', {
                initialValue: notes ? notes : "",
                rules: [{ required: false }],
              })(<TextArea style={{ width: 360 }} rows={3} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);


class PlanningSchedule extends AppComponent {
  state = {
    data: [],
    other_data: [],
    loads: [],

    loading: true,
    loading_other: true,
    loading_loads: true,

    endpoint: "/api/planning/",
    endpoint_section: "/api/planning/section/",
    endpoint_new: "/api/planning/section/new/",
    endpoint_other: "/api/schedule/",
    endpoint_load: "/api/load/",
    show_deleted: false,

    modal_item: null,
    modal_visible: false,
    modal_semester: null,

    modal_import_visible: false,
    modal_import_semester: null,

    endpoint_coordinator: "/api/schedule/coordinator/",
    coordinators: [],
    loading_coordinators: true,

    endpoint_warnings: "/api/planning/warnings/",
    warnings: [],
    loading_warnings: true,

    endpoint_course: "/api/preferences/course/",
    course_prefs: [],
    loading_course_prefs: true,

    endpoint_meetingtime: "/api/preferences/meetingtime/",
    meetingtime_prefs: [],
    loading_meetingtime_prefs: true,

    online_visible: false,
    course_planning_open: false
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    const { semesters } = this.props;

    this.setState({ loading_coordinators: true, loading_other: true, loading_loads: true, online_visible: false, course_planning_open: this.coursePlanningStatus(semesters) });
    this.doGet(this.state.endpoint_other + "?semester__year=" + this.get_year(),
      data => this.setState({ other_data: data, loading_other: false }));
    this.doGet(this.state.endpoint_load + "?year=" + this.get_year(),
      data => this.setState({ loads: data, loading_loads: false }));
    this.doGet(this.state.endpoint_coordinator + "?semester__year=" + this.get_year(),
      data => this.setState({ coordinators: data, loading_coordinators: false }));
    this.doGet(this.state.endpoint_meetingtime + "?semester=" + semesters.join(","),
      data => this.setState({ meetingtime_prefs: data, loading_meetingtime_prefs: false }));
    this.doGet(this.state.endpoint_course + "?semester=" + semesters.join(","),
      data => this.setState({ course_prefs: data, loading_course_prefs: false }));
    this.refreshData()
  }

  refreshData = () => {
    this.setState({ loading: true, loading_warnings: true });
    this.doGet(this.state.endpoint + "?semester=" + this.props.semesters.join(",") + "&campus=" + this.props.campus + ",118",
      data => this.setState({ data: this.deduplicateSections(data), loading: false }));
    this.doGet(this.state.endpoint_warnings + "?semester=" + this.props.semesters.join(",") + "&campus=" + this.props.campus,
      data => this.setState({ warnings: data, loading_warnings: false }));
  }

  getActions = (text, record, idx) => {
    const menu = (
      <Menu >
        <Menu.Item>
          <a onClick={() => this.setState({ modal_item: record, modal_visible: true, modal_semester: record.semester })}>Edit</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleCopy(record.id)}>Duplicate</a>
        </Menu.Item>
        <Menu.Item>
          <Popconfirm placement="top" title="Are you sure you wish to delete this section?" onConfirm={() => this.handleDelete(record.id)} okText="Yes" cancelText="No"><span style={{ color: "red" }}>Delete</span></Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} disabled={!this.state.course_planning_open}>
        <a className="ant-dropdown-link">
          Actions <Icon type="down" />
        </a>
      </Dropdown>
    );
  }

  handleCreateUpdate = () => {
    const form = this.formRef.props.form;
    const { modal_item } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }

      this.setState({ modal_visible: false, modal_item: null, modal_semester: null });

      values.honors = values.honaccnustartnuin == "honors";
      values.accelerated = values.honaccnustartnuin == "accelerated";
      values.nustart = values.honaccnustartnuin == "nustart";
      values.nuin = values.honaccnustartnuin == "nuin";
      delete values.honaccnustartnuin;

      if (values.crosslist == -1) {
        values.crosslist = null;
        values.create_crosslist = true;
      } else {
        values.create_crosslist = false;
      }

      if (values.room == undefined) {
        values.room = null;
      }

      if (values.meetingtime == undefined) {
        values.meetingtime = null;
      }

      if (modal_item) {
        this.doPatch(this.state.endpoint_section + modal_item.id + "/edit/", () => { message.success("Edited section."); form.resetFields(); this.refreshData(); }, JSON.stringify(values));
      } else {
        this.doPost(this.state.endpoint_new, () => { message.success("Created new section."); form.resetFields(); this.refreshData(); }, JSON.stringify(values));
      }
    });
  }

  handleImport = (func) => {
    const form = this.importFormRef.props.form;

    form.validateFields((err, values) => {
      if (err) { return; }

      this.doGet(this.state.endpoint_other + "?deleted=False&semester=" + values.semester + "&campus=" + values.campuses.join(","),
        data => {
          var courses = [...new Set(data.map(s => s.course))];
          if (values.gradugrad == "graduate") { courses = courses.filter(c => this.get_course(c).number >= 5000); }
          if (values.gradugrad == "undergraduate") { courses = courses.filter(c => this.get_course(c).number < 5000); }
          this.createCourses(courses, () => this.setState({ modal_import_visible: false }, func));
        }
      );
    });
  }

  createCourses = (courses, func) => {
    const { campus } = this.props;
    const { modal_import_semester } = this.state;

    if (courses?.length > 0) {
      const course = courses.shift();
      const values = { semester: modal_import_semester, course: course, campus: campus, create_crosslist: false, loadcount: this.get_course(course).loadcount };
      this.doPost(this.state.endpoint_new, () => { this.createCourses(courses, func); }, JSON.stringify(values));
    } else {
      message.success("Done creating courses.");
      this.refreshData();
      func();
    }
  }

  handleCopy = (id) => {
    this.doGet(this.state.endpoint_section + id + "/copy/", () => { message.success("Copied section."); this.refreshData(); });
  }

  handleDelete = (id) => {
    this.doDelete(this.state.endpoint_section + id + "/", () => { message.success("Deleted section."); this.refreshData(); });
  }

  handleToggle = (e) => {
    this.setState({ online_visible: e },
    );
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  saveImportFormRef = (formRef) => {
    this.importFormRef = formRef;
  }

  sections_exist = () => {
    const { semesters } = this.props;
    const { other_data } = this.state;
    return other_data.find(el => semesters.includes(el.semester)) != null;
  }

  decoratorIcon = (pref, el) => {
    if (pref == 4) {
      return (<Icon key={"icon-" + el} type="check-circle" theme="twoTone" twoToneColor="#52c41a" />);
    } else if (pref == 3) {
      return (<Icon key={"icon-" + el} type="plus-circle" theme="twoTone" />);
    } else if (pref == 2) {
      return (<Icon key={"icon-" + el} type="minus-circle" theme="twoTone" twoToneColor="#fa8c16" />);
    } else if (pref == 1) {
      return (<Icon key={"icon-" + el} type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />);
    } else {
      return (<Icon key={"icon-" + el} type="question-circle" theme="twoTone" twoToneColor="#999999" />);
    }
  }

  decorateCourse = (record, instructor, sem) => {
    const { course_prefs } = this.state;

    const pref = course_prefs.find(cp => instructor == cp.faculty && record.course == cp.course);
    return this.decoratorIcon(pref ? pref.preference : null, instructor);
  }

  decorateMeetingtime = (record, instructor, sem) => {
    const { meetingtime_prefs } = this.state;

    const pref = meetingtime_prefs.find(cp => instructor == cp.faculty && record.meetingtime == cp.meetingtime);
    return this.decoratorIcon(pref ? pref.preference : null, instructor);
  }

  coursePlanningStatus = (semesters) => {
    const values = semesters.map(semester => this.get_semester(semester).course_planning_open);
    if (values.includes(false)) {
      return false;
    }
    return true;
  }

  render() {
    const { loading, loading_coordinators, data, other_data, loads, coordinators, warnings, course_prefs, meetingtime_prefs, online_visible } = this.state;
    const { semesters, campus } = this.props;
    const { Panel } = Collapse;
    const key = this.props.semester + "-" + this.props.campus + "-" + (this.state.modal_item ? this.state.modal_item.id : "new");

    var hides = ["semester", "num", "crn", "enrollment", "trace"];

    var chars = [
      { title: "Coordinators", content: coordinators.length ? <CoordinatorTable {...this.props} loading={loading_coordinators} coordinators={coordinators.filter(c => semesters.includes(c.semester))} /> : "None" },
    ];
    chars = chars.concat(this.props.semesters.map(sem => { return { title: "Class Size for " + this.print_semester(sem), content: <ClassSizeTable {...this.props} loading={loading} sections={data} semester_specific={sem} /> }; }))

    const ids = data.map(e => e.id);
    const warnings_shown = {};
    const warnings_section = warnings.filter(el => ids.includes(el.id));
    const warnings_global = warnings.filter(el => !ids.includes(el.id));
    const defaultActiveKey = this.props.campus == 116 | this.props.campus == 118 ? "1" : "2"

    // When the show online toggle is true, online_visible state gets set to true, and will show campus and online sections
    // When false, it will show the filtered on campus courses
    const onCampusCourses = data.filter(course => course.campus == campus)
    const coursesToDisplay = online_visible ? data : onCampusCourses

    return (
      <Content {...this.props} title={this.props.semester + " Planning Schedule"} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { text: "Planning" }, { text: this.props.semester }]}>
        {campus != 118 && (
          <p style={{ textAlign: 'right', width: '100%' }} >
            <Text strong> Show Online campus &nbsp;</Text>
            <Switch checked={online_visible} onChange={this.handleToggle} />
          </p>
        )}
        {" "}
        {this.sections_exist() ? (<Alert message="Sections already exist" description={"Published sections for the " + this.props.semester + " semester already exist; thus the planning schedule should not be viewed as authoritative.  Please use the Schedules link to view the current schedule from Banner."} type="warning" showIcon />) : null}

        <Divider orientation="left">Characteristics</Divider>
        <List
          grid={this.grid}
          dataSource={chars}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />

        <Divider orientation="left">Warnings</Divider>
        <Collapse accordion bordered={false} defaultActiveKey="1">
          <Panel header="Show warnings" key={defaultActiveKey} style={{ fontStyle: 'italic' }}>
            {warnings_section.length + warnings_global?.length > 0 ? warnings_global.map((el, idx) => (<React.Fragment key={idx + " " + el.warning}><Alert message={el.warning} type="warning" showIcon /><br /></React.Fragment>)).concat(warnings_section.map(el => {
              if (el.warning_type == "global") {
                if (warnings_shown[el.warning]) {
                  return null;
                } else {
                  warnings_shown[el.warning] = 1;
                }
              }
              const foundSection = data.find(e => e.id == el.id);
              return (<React.Fragment key={foundSection.id + " " + el.warning}><Alert message={(el.warning_type == "section" ? this.print_course(foundSection.course) + " at " + this.print_meetingtime(foundSection.meetingtime) + ": " : "") + el.warning} type="warning" showIcon /><br /></React.Fragment>);
            }))
              : <Alert message="No errors detected" type="success" showIcon />}
          </Panel>
        </Collapse>
        {this.props.semesters.map(sem => (
          <React.Fragment key={sem} >
            <Divider orientation="left">{this.print_semester(sem)} Section Schedule</Divider>
            {this.state.course_planning_open ? null : (<Alert message="Courses have been submitted" description={"To make changes to sections for the " + this.props.semester + " semester, please email Michelle Ormerod (m.ormerod@northeastern.edu) for graduate sections or Jessica Biron (j.biron@northeastern.edu) and Megan Clough Groshek (m.cloughgroshek@northeastern.edu) for undergraduate sections."} type="warning" showIcon style={{ marginBottom: 15 }} />)}
            <SectionTable
              {...this.props}
              data={coursesToDisplay.filter(el => el.semester == sem)}
              location={this.state.loading | this.state.loading_other || this.state.loading_loads}
              planning={true}
              getActions={this.getActions}
              hide_fields={hides}
              course_planning_open={this.state.course_planning_open}
              onNew={() => this.setState({ modal_item: null, modal_visible: true, modal_semester: sem })}
              onImport={() => this.setState({ modal_import_visible: true, modal_import_semester: sem })}
              warnings={warnings}
              instructorDecorator={(record, instructor) => this.decorateCourse(record, instructor, sem)}
              meetingtimeDecorator={(record, instructor) => this.decorateMeetingtime(record, instructor, sem)}
            />
          </React.Fragment>
        ))}

        <PlannedSectionForm
          {...this.props}
          key={key}
          sections={coursesToDisplay.filter(el => el.semester == this.state.modal_semester)}
          other_sections={other_data} //.filter(el => el.semester == sem) }
          initial_semester={this.state.modal_semester}
          coordinators={coordinators}
          loads={loads}
          meetingtime_prefs={meetingtime_prefs}
          course_prefs={course_prefs}
          item={this.state.modal_item}
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.modal_visible}
          onCancel={() => this.setState({ modal_visible: false, modal_item: null, modal_semester: null })}
          onCreate={this.handleCreateUpdate}
        />
        <PlannedSectionImportForm
          {...this.props}
          visible={this.state.modal_import_visible}
          onCancel={() => this.setState({ modal_import_visible: false, modal_import_semester: null })}
          onImport={this.handleImport}
          importSemester={this.state.modal_import_semester}
          wrappedComponentRef={this.saveImportFormRef}
        />
      </Content>
    );
  }
}

class ScheduleBreakdown extends AppComponent {
  state = {
    data: [],
    loading: true,
    endpoint: "/api/planning/",

    other_data: [],
    loading_other: true,
    endpoint_other: "/api/schedule/",
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    const { semester, semesters } = this.props;

    this.doGet(this.state.endpoint + "?semester=" + this.props.semesters.join(",") + "&campus=" + this.props.campus,
      data => this.setState({ data: this.deduplicateSections(data), loading: false }));
    this.doGet(this.state.endpoint_other + "?semester=" + this.props.semesters.join(",") + "&campus=" + this.props.campus,
      data => this.setState({ other_data: data, loading_other: false }));
  }

  sections_exist = () => {
    const { semesters } = this.props;
    const { other_data } = this.state;
    return other_data.find(el => semesters.includes(el.semester)) != null;
  }

  render() {
    const { loading, endpoint, data, other_data } = this.state;

    let schedule = this.sections_exist() ? other_data : data;

    const sequences = this.meetingtime_list().filter(m => m.sequence);
    const chars = sequences.map(mt => {
      return {
        meetingtime: mt, title: "Sequence " + mt.sequence + ": " + this.print_meetingtime(mt.id),
        content: schedule.filter(s => s.meetingtime == mt.id && ((!s.crosslist) || (schedule.find(d => d.id == s.crosslist) == null) || (this.course_comparator(this.get_course(s.course),
          this.get_course(schedule.find(d => d.id == s.crosslist).course)) < 0))).map(s =>
            <div>{this.print_course(s.course) + (s.crosslist && (schedule.find(d => d.id == s.crosslist) != null) ? "/" + this.print_course(schedule.find(d => d.id == s.crosslist).course) : "")
              + " " + oxford(s.instructors.map(this.print_instructor))
              + " " + s.enrollment
              + "/" + s.capacity
              + " " + (s.room ? this.print_room(s.room) : "")
              + " (" + (s.room ? !this.get_room(s.room).capacity ? "N/A" : this.get_room(s.room).capacity : "")
              + ")"}
            </div>)
      };
    });

    const thrice = chars.filter(s => s.meetingtime.sequence.match(/^[1-9]$/));
    const twice = chars.filter(s => s.meetingtime.sequence.match(/^[A-Z]$/));
    const night = chars.filter(s => s.meetingtime.sequence.match(/^N[1-9]$/));
    const other = chars.filter(s => s.meetingtime.sequence.match(/^[^N].+$/));

    return (
      <Content {...this.props} title={this.props.semester + " Schedule Breakdown"} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { text: "Breakdown" }, { text: this.props.semester }]}>
        {this.sections_exist() ?
          (<React.Fragment><Alert message="Using the published schedule" type="warning" showIcon /><p /></React.Fragment>)
          :
          (<React.Fragment><Alert message="Using the planning schedule" type="warning" showIcon /><p /></React.Fragment>)
        }

        <Button icon="redo" onClick={() => this.getData()} style={{ float: 'right' }}>Refresh</Button>

        <Divider orientation="left">Thrice-Weekly Day Sequences</Divider>
        <List
          grid={this.grid}
          dataSource={thrice}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />

        <Divider orientation="left">Twice-Weekly Day Sequences</Divider>
        <List
          grid={this.grid}
          dataSource={twice}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />

        <Divider orientation="left">Night Sequences</Divider>
        <List
          grid={this.grid}
          dataSource={night}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />

        <Divider orientation="left">Sequence Components</Divider>
        <List
          grid={this.grid}
          dataSource={other}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />
      </Content>
    );
  }
}

export { ScheduleBreakdown };
export default PlanningSchedule;
