import React from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import { format_nuid } from "../../Utils";
import { Form, Table, Icon, Menu, Dropdown, Switch, Modal, Input, Typography, Alert, Button, Divider, Radio, Select } from "antd";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import { string } from "prop-types";
import GenericExport from "./GenericExport";


class UGScholarshipApplicationExport extends GenericExport {
    createCSV = (data) => {
        let returnData = [];

        data.forEach(el => {
            let record = el.record;
            let student = record.student;
            record.scholarships.forEach(scholarship => {
                let rowObject = {
                    "Created at": moment(el.created_at).format("MMM DD, YYYY HH:mm"),
                    "Workflow ID": el.id,
                    "Student Firstname": student.firstname,
                    "Student Lastname": student.lastname,
                    "Student NUID": student.nuid,
                    "Student Campus": this.print_campus(student.campus),
                    "Email": el.email,
                    "Scholarship": scholarship.name,
                    "gpa": record.gpa,
                    "Personal Statement": record.personal_statement.replace(/"/g, '""'),
                }
    
                el.steps.forEach( step => {
                        rowObject[step.title] = step.decision != "Unassigned" ? step.decision : "";
                })
                returnData.push(rowObject)
            }
        )
        });
        return returnData;
    }

    render() {

        const { disabled } = this.props;
        const { data } = this.state;

        return (
            <React.Fragment>
                {
                    !disabled && data ?
                        (
                            <CSVLink filename={"UGScholarshipApplication-Data-Export_" + moment().format("DD-MM-YYYY") + ".csv"} data={this.createCSV(data)}>
                                <Button type="primary">
                                    Download
                                </Button>
                            </CSVLink>
                        ) : (
                            <Button type="primary" loading={true}>Download</Button>
                        )
                }
            </React.Fragment>
        );
    }
}

export default UGScholarshipApplicationExport;
