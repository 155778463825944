import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";

import { Icon, Radio, Table, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const MSThesisFormForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      khouryReaders: 3,
      nonKhouryReaders: 0,
      petition_type: "authorization",
      uploadedList: [],
    }

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({ khouryReaders: 3, nonKhouryReaders: 0, petition_type: "authorization", uploadedList: [] });
      this.props.form.resetFields();
    }


    handleSubmit = () => {
      const { uploadedList } = this.state;
      const form = this.props.form;
      form.validateFields((err, values) => {
        if (!err) {
          if (values["non_members"]) {
            // set the first member of the committee to be the advisor
            values["members"] = [...values["members"], ...values["non_members"]].filter(e => { return e });
            delete values["non_members"];
          }
          let members = values["members"].filter(e => { return e });
          members[0].advisor = true;
          delete values["members"];
          this.props.onSubmit({ ...(values), ...{ thesis_form: { members: members } }, ...{ files: uploadedList } }, this.reset());
        } else {
          message.error(err);
        }
      });
    };

    getFile = (type) => {
      const { uploadedList } = this.state;
      return (
        <React.Fragment>
          {(uploadedList.filter(el => el[1]["type"] == type).length != 0) && (
            <div style={{ marginTop: "10px" }}>
              <p>{type} <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={() => this.onRemove(type)} /></p>
            </div>
          )}
        </React.Fragment>
      )
    };

    setFile = (file, type) => {
      this.setState(state => ({
        uploadedList: [...state.uploadedList, [window.btoa(file), type]],
      }));
    };

    beforeUpload = (file, type) => {
      if (file.size / 1024 / 1024 > 10) {
        message.error('File is larger than 10MB');
        return;
      }
      const reader = new FileReader();
      const setFile = this.setFile;
      reader.onload = function (event) {
        setFile(event.target.result, type);
      };
      reader.readAsBinaryString(file);
    };

    onRemove = type => {
      this.setState(state => {
        const matches = (el) => el[1]["type"] == type;
        const index = state.uploadedList.findIndex(matches);
        const newuploadedList = state.uploadedList.slice();
        newuploadedList.splice(index, 1);
        return {
          uploadedList: newuploadedList,
        };
      });
    };

    disableUpload = (type) => {
      const { uploadedList } = this.state;
      return uploadedList.filter(el => el[1]["type"] == type).length != 0;
    }

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const { khouryReaders, nonKhouryReaders, petition_type, uploadedList } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

      return (
        <>
          <p>If you wish to amend a previously-submitted thesis form, please simply submit a new one. The Khoury graduate team will use the most recent one that you submit.</p>
          <div style={{ marginTop: "15px" }}>
            <Form>
              <FormItem {...formItemLayout} label="I would like to submit the following thesis type" extra="Please select a type">
                {getFieldDecorator("petition_type", { rules: [{ required: true }], initialValue: petition_type, onChange: event => this.setState({ petition_type: event.target.value }) })
                  (
                    <Radio.Group>
                      <Radio style={{ display: 'block' }} value={"authorization"}>Thesis Authorization</Radio>
                      <Radio style={{ display: 'block' }} value={"completion"}>Thesis Completion</Radio>
                    </Radio.Group>
                  )}
              </FormItem>
              <FormItem {...formItemLayout} label="Thesis Title">
                {getFieldDecorator("thesis_title", {
                  rules: [{ required: true, message: "Please input a thesis title" }], initialValue: ""
                })
                  (<Input style={{ width: 360 }} />)}
              </FormItem>
              <Divider orientation="left">Thesis Committee</Divider>
              <p><b>Note: </b>Please ensure you have one faculty advisor and at least two readers.</p>
              {[...Array(khouryReaders)].map((_, idx) =>
                <>
                  <FormItem {...formItemLayout} label={(idx == 0 ? "Faculty Advisor" : "Khoury Reader " + (idx))} extra={"Please select" + (idx == 0 ? " your faculty advisor" : " a Khoury reader " + (idx))}>
                    <>
                      {getFieldDecorator("members." + (idx + 1) + ".employee", {
                        rules: [{ required: true, message: "Please select a committee member" }],
                      })
                        (
                          <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                            {this.employee_list().map(el => (
                              <Option key={el.id} value={el.id}>{this.print_full_employee(el.id)}</Option>
                            ))}
                          </Select>
                        )}
                      {" "}
                      {idx == [...Array(khouryReaders)].length - 1 && idx >= 3 ? (<Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={() => this.setState({ khouryReaders: khouryReaders - 1 })} />) : null}
                    </>
                  </FormItem>
                </>
              )}
              <FormItem {...formItemLayout} label=" " colon={false}>
                <Button onClick={() => this.setState({ khouryReaders: khouryReaders + 1 })}>Add Khoury Reader</Button>
              </FormItem>
              <Divider orientation="left">Non-Khoury (External) Reader(s) </Divider>
              {[...Array(nonKhouryReaders)].map((el, idx) =>
                <>
                  <FormItem {...this.formItemLayout} label=" " colon={false}>
                    <h4>{"External Reader " + (idx + 1)}</h4>
                  </FormItem>
                  <FormItem {...formItemLayout} label="Firstname" extra="Please enter their first name">
                    {getFieldDecorator("non_members." + (idx + 1) + ".firstname", { rules: [{ required: true, message: "Please enter their first name" }] })
                      (
                        <Input style={{ width: 360 }} />
                      )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="Lastname" extra="Please enter their last name">
                    {getFieldDecorator("non_members." + (idx + 1) + ".lastname", { rules: [{ required: true, message: "Please enter their last name" }] })
                      (
                        <Input style={{ width: 360 }} />
                      )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="Email" extra="Please enter their email">
                    {getFieldDecorator("non_members." + (idx + 1) + ".email", { rules: [{ type: 'email', required: true, message: "Please enter an email" }] })
                      (
                        <Input style={{ width: 360 }} />
                      )}
                  </FormItem>
                </>)}
              <FormItem {...formItemLayout} label=" " colon={false}>
                <>
                  <Button onClick={() => this.setState({ nonKhouryReaders: nonKhouryReaders + 1 })}>Add External Reader</Button>
                  {" "}
                  {[...Array(nonKhouryReaders)]?.length > 0 ? (<Button type="danger" onClick={() => this.setState({ nonKhouryReaders: nonKhouryReaders - 1 })}>Delete</Button>) : null}
                </>
              </FormItem>
              <Divider orientation="left">Add Documentation</Divider>
              <p>Please use the below space to upload the required documents, you cannot proceed without upload.</p>
              <strong>Note: You can only upload one document per field. It must be a PDF and no larger than 10 MB.</strong>
              {petition_type == "authorization" ? (
                <FormItem {...formItemLayout} label="Thesis Proposal" extra={"Please upload your thesis proposal"} >
                  {getFieldDecorator("thesis_proposal_upload", { rules: [{ required: true, message: "Please upload a thesis proposal." }] })(
                    <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Thesis Proposal" })} accept=".pdf" showUploadList={false}>
                      <Button disabled={this.disableUpload("Thesis Proposal")}><Icon type="upload" />Upload Thesis Proposal</Button>
                      {this.getFile("Thesis Proposal")}
                    </Upload>
                  )}
                </FormItem>
              ) : (
                <>
                  <FormItem {...formItemLayout} label="Thesis Completion Form" extra={['Complete and upload following Thesis Approval ', <a key="link" target="_blank" href="https://s32615.pcdn.co/wp-content/uploads/2020/11/MS-Thesis-Completion-Form.pdf">form</a>, " with signatures from Advisor and Reader(s)."]}>
                    {getFieldDecorator("completion_form_upload", { rules: [{ required: true, message: "Please upload your completion form." }] })(
                      <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Completion Form" })} accept=".pdf" showUploadList={false}>
                        <Button disabled={this.disableUpload("Completion Form")}><Icon type="upload" />Completion Form</Button>
                        {this.getFile("Completion Form")}
                      </Upload>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="Thesis" extra={"Please upload your thesis"} >
                    {getFieldDecorator("thesis_upload", { rules: [{ required: true, message: "Please upload a thesis." }] })(
                      <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Thesis" })} accept=".pdf" showUploadList={false}>
                        <Button disabled={this.disableUpload("Thesis")}><Icon type="upload" />Upload Thesis</Button>
                        {this.getFile("Thesis")}
                      </Upload>
                    )}
                  </FormItem>
                </>
              )}
              <FormItem {...this.formItemLayout} label=" " colon={false}>
                <Button type="primary" onClick={() => this.handleSubmit()}>Submit</Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);


class MSThesisForm extends AppComponent {
  render() {
    const { record } = this.props;
    const item = record.record;

    return (
      <Descriptions bordered title={"MS Thesis Form by " + record.created_by}>
        <Descriptions.Item label="Submitted" span={2}>{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Requestor" span={2}>{[record.created_by + " ", <a key="email" href={"mailto:" + item.email}><Icon type="mail" theme="twoTone" /></a>]}</Descriptions.Item>
        <Descriptions.Item label="NUID" span={2}>{item.student.nuid}</Descriptions.Item>
        {this.permission("can", "staff") &&
          <Descriptions.Item label="" span={2}>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item>}
        <Descriptions.Item label="Campus" span={2}>{this.print_campus(item.student.campus)}</Descriptions.Item>
        <Descriptions.Item label="Type" span={4}>{item.petition_type}</Descriptions.Item>
        <Descriptions.Item label="Thesis Title" span={4}>{item.thesis_title}</Descriptions.Item>
        <Descriptions.Item label="" span={4}><strong>Reader(s)</strong></Descriptions.Item>
        {item.thesis_form.members.map((el, idx) => (
          <Descriptions.Item label={"Member " + (idx + 1) + (el.external ? " (External)" : el.advisor ? " (Faculty Advisor)" : "")} span={4}>{
            [(el.external ? el.firstname + " " + el.lastname : this.print_full_employee(el.employee)) + " ", (el.member_email ? <a key="email" href={"mailto:" + el.member_email}><Icon type="mail" theme="twoTone" /></a> : null), " ", (el.website ? <a key="website" href={el.website}><Icon type="home" theme="twoTone" /></a> : null)]}</Descriptions.Item>
        ))}
        <Descriptions.Item label="Attached Files" span={ 4 }>{[record.files.map(el => <a onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type +".pdf"}</a>) ]}</Descriptions.Item>
      </Descriptions>
    );
  }
}

class MSThesisFormTable extends WorkflowTable {
  renderMembers = (members) => {
    return members[0]?.advisor ? members : members.reverse()
  }

  get_columns = () => {
    return [
      {
        title: "Type",
        key: "petition_type",
        width: 120,
        render: (text, record, idx) => record.record.petition_type
      }, {
        title: "Thesis Title",
        key: "thesis_title",
        width: 200,
        render: (text, record, idx) => record.record.thesis_title,
      }, {
        title: "Committee Members",
        key: "members",
        width: 180,
        render: (text, record, idx) => this.renderMembers(record.record.thesis_form.members).map((el, idx) => <p key={el.id}>{(idx+1) + ". " + (el.external ? el.firstname + " "+ el.lastname : this.print_full_employee(el.employee)) + (el.advisor ? " (Faculty Advisor)" : el.external ? " (External)" : "")}</p>),
      }
    ];
  }
}

class MSThesisFormOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ms/thesisform/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "MS Thesis Form";
  }

  get_workflowtype = () => {
    return "msthesisform";
  }

  get_name_plural = () => {
    return "MS Thesis Forms";
  }

  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "MS" }, { text: "Thesis Form" }];
  }

  get_form = (func) => {
    return <MSThesisFormForm {...this.props} onSubmit={this.submit_form} />;
  }

  get_record_view = (record) => {
    return <MSThesisForm {...this.props} record={record} />
  }

  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }

  get_overview_text = () => {
    return (
      <div>
        <p>This form intended for MS students doing a thesis:</p>
        <ul>
          <li>Please first submit this form with your thesis proposal attached three weeks prior to completing your project course.</li>
          <li>After your thesis defense is complete, submit a new form with your thesis attached.</li>
        </ul>
        <p>For more information about completing a master’s thesis, <strong><a href="https://northeastern.sharepoint.com/sites/KhouryResourceCenter" target="_blank">please visit here.</a></strong></p>
      </div>
    );
  }
}

export { MSThesisFormOverview, MSThesisForm, MSThesisFormTable };