import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";

import { Icon, Radio, Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const UGHomeCollegeChangeForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
    }
    
    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.props.form.resetFields();
    }

    handleSubmit = () => {
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit( values , this.reset());
        } else {
          message.error(err);
        }
      });
    };


    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };
      
      return (
        <>
          <p>If you wish to amend a previously-submitted petition, please simply submit a new petition. The Khoury Undergrad team will simply use the most recent one that you submit.</p>
        <Form>
          <FormItem {...formItemLayout} label="Current College" extra="What is your current college?">
            {getFieldDecorator("current_college", { rules: [{required: true, message: "Please enter a college"}] })
            (
              <Select style={{ width: 360 }} filterOption={ this.filter }>
                {this.college_list().filter(el => !["CS", "PR", "LW"].includes(el.code)).map(el => (<Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>))}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Co-Op Preparation Class" extra="Have you completed a co-op preparation class?">
            {getFieldDecorator("coop_prep_class", { rules: [{required: true, message: "Please select yes or no"}] })
            (
              <Radio.Group>
                <Radio style={{display: 'block'}} value={true}>Yes</Radio>
                <Radio style={{display: 'block'}} value={false}>No</Radio>
              </Radio.Group>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Co-Op" extra="Have you already completed a co-op?">
            {getFieldDecorator("coop", { rules: [{required: true, message: "Please select yes or no"}] })
            (
              <Radio.Group>
                <Radio style={{display: 'block'}} value={true}>Yes</Radio>
                <Radio style={{display: 'block'}} value={false}>No</Radio>
              </Radio.Group>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Pattern of Attendance" extra="Please enter your pattern of attendance.">
            {getFieldDecorator('pattern_of_attendance', { rules: [{ required: true, message: 'Please enter your pattern of attendance' }]})
            (
              <Input maxLength={2} style={{width: "50px"}}/> 
            )}
          </FormItem>
          <FormItem {...this.formItemLayout} label=" " colon={ false }><Button type="primary" onClick={ this.handleSubmit }>Submit</Button></FormItem>
        </Form>
        </>
      );
    }
  }
);        
 

class UGHomeCollegeChange extends AppComponent {
  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
        <Descriptions bordered title={ "Home College Change request by " + record.created_by}>
          <Descriptions.Item label="Submitted" span={ 2 }>{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" span={ 2 }>{[ record.created_by + " ", <a key="email" href={"mailto:" + item.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" span={ 2 }>{ item.student.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 2 }>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(item.student.campus) }</Descriptions.Item>
          <Descriptions.Item label="Pattern of Attendance" span={ 2 }>{item.pattern_of_attendance}</Descriptions.Item>
          <Descriptions.Item label="Current College" span={ 2 }>{this.print_college(item.current_college)}</Descriptions.Item>
          <Descriptions.Item label="Taken a Co-Op Class?" span={ 2 }>{item.coop_prep_class ? "Yes" : "No"}</Descriptions.Item>
          <Descriptions.Item label="Been on Co-Op?" span={ 2 }>{item.coop ? "Yes" : "No"}</Descriptions.Item>
        </Descriptions>
      );
   }
}

class UGHomeCollegeChangeTable extends WorkflowTable {
  get_columns = () => {
    return [
      {
        title: "Current College",
        key: "current_college",
        width: 200,
        render: (text, record, idx) => this.print_college(record.record.current_college),
      }
    ];
  }
}

class UGHomeCollegeChangeOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ug/homecollegechange/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "Home College Change";
  }

  get_workflowtype = () => {
    return "ughomecollegechange";
  }

  get_name_plural = () => {
    return "Home College Changes";
  }
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "UG" }, { text: "Home College Change" }];
  }
  
  get_form = (func) => {
    return <UGHomeCollegeChangeForm {...this.props} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <UGHomeCollegeChange {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
    <p>
      This form is for students in a combined major with a home college that is <b>not</b> in Khoury to switch to Khoury as their home college.
      Students in Khoury wishing to change home college should speak with their Academic Advisor.
      Requests submitted after the drop deadline of a semester will be effective the following semester.
    </p>
    );
  }  
}

export { UGHomeCollegeChangeOverview, UGHomeCollegeChange, UGHomeCollegeChangeTable };