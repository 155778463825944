import React, { Component } from "react";
import AppComponent from "../AppComponent";
import Content from "../Content";
import moment from "moment-timezone";
import { isEmpty, renderStatus } from "../Utils";

import { Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Steps, Modal, Icon, Popconfirm, Tooltip } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { Step } = Steps;

class WorkflowTable extends AppComponent {

  is_current_active_step = (s) => {
    return (s.decision == "Assigned") || (s.decision == "Reviewed");
  }

  is_current_step = (s) => {
    return this.is_current_active_step(s) || (s.decision == "Refused") || (s.decision == "Cancelled");
  }
  
  get_current_active_step = (record) => {
    return record.steps.find(s => this.is_current_active_step(s));
  }
  
  get_current_step = (record) => {
    const step = record.steps.find(s => this.is_current_step(s));
    if (step) { return step; }
    return record.steps[record.steps.length-1];
  }
  
  can_cancel = (record) => {
    const step = this.get_current_active_step(record);
    return step && step.cancelable && record.person == this.props.user.person.id; 
  }
  
  do_cancel = (record) => {    
    const { reload } = this.props;
    
    const step = this.get_current_active_step(record);
    this.doPatch("/api/workflow/step/" + step.id + "/cancel/", reload, JSON.stringify({ decision: "Cancelled" }));
  }

  get_columns = () => {
    return "NOT IMPLEMENTED";
  }
  
  get_actions = (record) => {
    const { type, onView } = this.props;
    
    if (type == "process") {
      return ( <div><p><Button onClick={ () => onView(record) }>View/Process</Button></p></div> );
    } else if (type == "historic") {
      return ( <div><p><Button onClick={ () => onView(record) }>View</Button></p></div> );
    } else {
      return ( <div><p><Button onClick={ () => onView(record) }>View</Button></p><p><Popconfirm icon={ <Icon type="question-circle-o" /> } placement="left" title={ "Are you sure you wish to cancel this request?" } onConfirm={ () => this.do_cancel(record) } okText="Yes" cancelText="No" disabled={ !this.can_cancel(record) }><Button type="danger" disabled={ !this.can_cancel(record) }>Cancel</Button></Popconfirm></p></div> );
    }
  }
    
  get_status = (status) => {
    if ((status == "Accepted") || (status == "Skipped") || (status == "Reassigned")) {
      return "finish";
    } else if (status == "Assigned") {
      return "process";
    } else if ((status == "Refused") || (status == "Cancelled")) {
      return "error";
    } else if (status == "Unassigned") {
      return "wait";
    }
  }
 
  get_steps = (record) => { 
    const { type } = this.props;
    
    return (
      <Steps direction="vertical" size="small">
        { record.steps.map(s => {
            const status = this.get_status(s.decision);
            const cancelled = record.steps.filter(se => se.decision == "Cancelled")?.length > 0;
            const description = <><p><b>{cancelled ? "Cancelled" : s.decision == "Unassigned" ? "Pending" : s.decision}{(s.edited_by || s.decision == "Assigned") ? " at " + moment(s.state_last_changed_at).format("MMM DD, YYYY HH:mm") : ""}</b></p><div style={{maxWidth: 300}}><i>{s.justification ? s.justification : s.student_justification}</i></div></>;

          
            const style = {};
            if (((type == "process") || (type == "historic")) && (this.get_current_step(record).id != s.id)) {
              style["display"] = "none";
            }
          
            if (s.decision == "Cancelled") {
              return (<Step style={ style } icon={<Icon type="stop" />} status={ status } key={ s.id } title={ s.title } description={ description } /> );
            } else if (s.decision == "Accepted") {
              return (<Step style={ style } icon={<Icon type="check-circle" />} status={ status } key={ s.id } title={ s.title } description={ description } /> );
            } else if (s.decision == "Refused") {
              return (<Step style={ style } icon={<Icon type="exclamation-circle" />} status={ status } key={ s.id } title={ s.title } description={ description } /> );
            } else if (s.decision == "Reassigned") {
              return (<Step style={ style } icon={<Icon type="issues-close" />} status={ status } key={ s.id } title={ s.title } description={ description } /> );
            } else if (s.decision == "Skipped") {
              return (<Step style={ style } icon={<Icon type="minus-circle" />} status={ status } key={ s.id } title={ s.title } description={ description } /> );
            } else {
              return (<Step style={ style } status={ status } key={ s.id } title={ s.title } description={ description } /> );
            }
          }
        )}
      </Steps> );   
  }
  
  render() {
    const { type, data, loading } = this.props;
    
    var columns = [{
      title: "Request", 
      key: "record", 
      width: 75,
      render: (text, record, idx) => record.id,
    }];
    
    if ((type == "process") || (type == "historic")) {
      columns.push({
        title: "Requestor",
        key: "requestor", 
        width: 130,
        render: (text, record, idx) => record.created_by,
      });
    }
    
    columns.push({
      title: "Submitted",
      key: "submitted",
      width: 110,
      render: (text, record, idx) => moment(record.created_at).format("MMM DD, YYYY HH:mm"),
    });
    
    columns = columns.concat(this.get_columns()).concat([{
        title: "Status",
        key: "status",
        width: 300,
        render: (text, record, idx) => this.get_steps(record),
      }, {
        title: "Actions",
        key: "actions",
        width: 100,
        align: 'center',
        render: (text, record, idx) => this.get_actions(record), 
      }]);

    return ( <Table dataSource={ data } style={{ overflow: "scroll" }} loading={ loading } columns={ columns } rowKey="id" pagination={false} size="small" /> );
  }
}

export { WorkflowTable };