import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import Content from "../../../Content";
import { renderStatus } from "../../../Utils";

import { Table, Form, Input, Divider, Select, Button, Switch, Alert } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

//For Spring 2024 and after, it was requested that students no longer be able to specify which section they preferred in the form
//The course they choose is still saved as a Section in the backend, 
//The code for enabling section choice has been left but commented out in case it is desired again some day
//anySection default state will need to be changed back to false
const ClosedCourseForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {
    state = {
      selectedCourse: null,
      selectedSections: null,
      selectedSemester: null,
      anySection: true,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.semester !== this.props.semester) {
        this.props.form.resetFields();
        this.setState({ selectedCourse: null, anySection: true });
      }
    }

    handleSubmit = e => {
      const form = this.props.form;
      const { anySection, selectedSections, selectedSemester } = this.state;
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const data = {
            // sections: values["sections"] ? values["sections"] : [selectedSections[0].id],
            sections: [selectedSections[0].id],
            semester: selectedSemester,
            selected_drop_course: values["currentCourse"] == "N/A" ? "" : values["currentCourse"] && Number.isInteger(values["currentCourse"]) ? values["currentCourse"] : "",
            selected_drop_non_khoury_course: values["currentCourse"] == "N/A" ? "" : values["currentCourse"] && !Number.isInteger(values["currentCourse"]) ? values["currentCourse"] : "",
            rationale: values["Rationale"],
            any_section: anySection
          };
          this.doPost("/api/petition/ug/closedcourse/", () => {
            form.resetFields();
            this.props.getData();
            this.setState({ selectedCourse: null, anySection: true});
          }, JSON.stringify(data));
        } else {
          message.error(err);
        }
      });
    };

    getCurrentCourses = (semester) => {
      const { transcript } = this.props;
      if (transcript.length == 0) {
        return [];
      }
      return transcript.courses.filter(a => !a.withdrawn && (this.get_semester(a.semester).code == this.get_semester(semester).code)).sort((firstEl, secondEl) => this.getCourseName(firstEl).localeCompare(this.getCourseName(secondEl)));
    }

    getCourseName = (el) => {
      return (el.course ? this.print_course(el.course) : el.course_name);
    }

    render() {
      const { form, sections, semesters, petitions } = this.props;
      const { getFieldDecorator } = form;
      const { selectedCourse, selectedSemester, anySection } = this.state;

      var semester_list = this.semester_list().filter(
        el =>
          el.code >= this.get_semester(semesters[0]).code &&
          (el.code.slice(-2) == "10" ||
            el.code.slice(-2) == "30" ||
            el.code.slice(-2) == "60")
      );
      semester_list.reverse();

      const full_sections = sections.filter(
        el => el.enrollment / el.capacity > 0.9
      );

      const course_semester_list = full_sections.map(el => {
        return {
          course: el.course,
          semester: el.semester,
          key: el.course + "-" + el.semester
        };
      });
      const course_semester_list_keys = course_semester_list.map(el => el.key);
      const distinct_course_semester_list = course_semester_list.filter(
        (obj, pos, arr) =>
          course_semester_list_keys.indexOf(obj.key) == pos &&
          this.get_course(obj.course).number < 5000
      );

      // const sectionList = full_sections.filter(
      //   el =>
      //     el.course == selectedCourse &&
      //     el.semester == selectedSemester &&
      //     el.enrollment / el.capacity > 0.9
      // );

      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true
      };

      return (
        <Form onSubmit={this.handleSubmit}>
          <Form.Item {...formItemLayout} label="Semester" extra="You can change your semester in the top right corner.">
            <TextArea style={{ width: 390 }} rows={1} disabled defaultValue={this.props.semester}></TextArea>
          </Form.Item>
          <FormItem
            {...formItemLayout}
            label="Current Course"
            extra="Which course can we drop if we can accommodate your request?"
          >
            {getFieldDecorator("currentCourse", {
              rules: [{
                required: true,
                message: "Please select a course."
              }],
              initialValue: "",
            })(
              <Select style={{ width: 390 }} filterOption={this.filter} allowClear>
                <Option key="N/A" value="N/A">N/A - No course to drop</Option>
                {this.props.semesters.map(sem =>
                  <OptGroup label={this.print_semester(sem)}>
                    {this.getCurrentCourses(sem).map(el => (
                      el.course ? (
                        <Option key={el.course} value={el.course}>
                          {this.print_full_course(el.course)}{" "}
                        </Option>) : (
                        <Option key={el.course_name} value={el.course_name}>
                          {el.course_name}{" "}
                        </Option>
                      )
                    ))}
                  </OptGroup>)}
              </Select>
            )}
          </FormItem>
          {form.getFieldValue("currentCourse") == "N/A" && (
            <FormItem {...formItemLayout} className="no-print" label=" " colon={false}>
              <Alert style={{ width: 390 }} message={<p><b>Note:</b> If you have a full schedule and pick N/A, your request will be denied. A drop course is required to process requests if the student has a full schedule.</p>} type={"warning"} showIcon />
            </FormItem>
          )}
          <FormItem
            {...formItemLayout}
            label="Course"
            extra="Please select the course you would like to add to your petition."
          >
            {getFieldDecorator("courses", {
              rules: [{ required: true, message: "Please select a course." }],
              initialValue: "",
              onChange: event => {
                
                const selectedCourse = distinct_course_semester_list[event].course;
                const selectedSemester = distinct_course_semester_list[event].semester;
              
                //If going back to section selection, this is done in the anySection FormItem with sectionList (commented out above)
                const updatedSectionList = full_sections.filter(
                  el =>
                    el.course === selectedCourse &&
                    el.semester === selectedSemester &&
                    el.enrollment / el.capacity > 0.9
                );
                //

                this.setState({
                  selectedCourse: distinct_course_semester_list[event].course,
                  selectedSemester: distinct_course_semester_list[event].semester,
                  selectedSections: updatedSectionList,
                  anySection: true
                }, 
                  // form.resetFields(["sections"])
                )
              }
            })(
              <Select
                style={{ width: 390 }}
                filterOption={this.filter}
              >
                {this.props.semesters.map(sem =>
                  <OptGroup label={this.print_semester(sem)}>
                    {distinct_course_semester_list.map((el, idx) => (
                      el.semester == sem && (
                        <Option key={idx} value={idx} disabled={petitions.some(e => e.course === el.course && el.semester == sem && e.decision != "Cancelled")}>
                          {this.print_full_course(el.course)}{" "}
                        </Option>
                      )))}
                  </OptGroup>)}
              </Select>
            )}
          </FormItem>
          {/* <Form.Item {...formItemLayout} label="Any Section" extra="Please toggles this option if you want to add any section to your petition">
            {getFieldDecorator("anySection", {
              rules: [{ required: false, message: "Please select an option." }],
              onChange: event => this.setState({ anySection: event, selectedSections: sectionList })
            })
              (<Switch
                checked={anySection}
                disabled={
                  !selectedCourse ||
                  !selectedSemester ||
                  sectionList.length == 0
                } />)
            }
          </Form.Item> */}
          {/* {!anySection ? (
            <FormItem
              {...formItemLayout}
              label="Section(s)"
              extra="Please select the section(s) you would like to add to your petition."
            >
              {getFieldDecorator("sections", {
                rules: [{ required: true, message: "Please select a section." }],
                initialValue: [],
                onChange: event =>
                  this.setState({ selected_section: event })
              })(
                <Select
                  mode="multiple"
                  style={{ width: 390 }}
                  filterOption={this.filter}
                  disabled={
                    !selectedCourse ||
                    !selectedSemester ||
                    sectionList.length == 0
                  }
                >
                  {sectionList && sectionList?.length > 0 ? (
                    (sectionList.map(el => (
                      <Option key={el.id} value={el.id}>
                        <span>
                          {" "}
                          CRN: {el.crn}, Section: {el.number}, Time:{" "}
                          {this.print_meetingtime(el.meetingtime)}
                          {" "}
                          ({this.print_campus(el.campus)})
                        </span>
                      </Option>
                    )))
                  ) : null}
                </Select>
              )}
            </FormItem>) : null} */}
          <FormItem
            {...formItemLayout}
            label="Rationale"
            extra="Please provide a rationale or any other information you'd like to include to your request."
          >
            {getFieldDecorator("Rationale", {
              rules: [{ required: true, message: "Please input a rationale." }],
              initialValue: "",
            })(<TextArea style={{ width: 390 }} rows={4} />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 }
            }}
          >
            <Button type="primary" htmlType="submit">
              {"Submit"}
            </Button>
          </FormItem>
        </Form>
      );
    }
  }
);

class UGClosedCoursePetition extends AppComponent {
  state = {
    endpoint_section: "/api/schedule/",
    loading_sections: true,
    sections: [],

    endpoint_viewpetition: "/api/petition/ug/closedcourse/",
    loading_petitions: true,
    viewpetition: [],
    endpoint_viewable: "/api/petitionviewable/",
    visible: false,
    update: false,

    transcript_endpoint: "/api/student/transcript/",
    transcript: [],
    transcript_loading: true,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_viewable + "?semester=" + this.props.semesters.join(","), data => { this.setState({ visible: ((data[0] || {}).petitions_visible_cc || false) }); });
    this.doGet(this.state.endpoint_section + "?semester=" + this.props.semesters.join(","), data => this.setState({ sections: data, loading_sections: false }));
    this.doGet(this.state.endpoint_viewpetition + "?semester=" + this.props.semesters.join(",") + "&campus=" + this.props.campus + "?person=" + this.props.user.person.id, data => this.setState({ viewpetition: data, loading_petitions: false }));
    this.doGet(this.state.transcript_endpoint + this.props.user.nuid + "/", data => this.setState({ transcript: data, transcript_loading: false }));
  };

  handleCancel = (recordID) => {
    this.doPatch(this.state.endpoint_viewpetition + recordID + "/", () => { this.getData(); }, JSON.stringify({ decision: "Cancelled" }));
  };

  getActions = (text, record, idx) => {
    return (
      <Button onClick={() => this.handleCancel(record.id)} disabled={record.decision != "Submitted"}>Cancel</Button>
    );
  };

  render() {
    const { semester } = this.props;
    const { sections, viewpetition, loading_sections, loading_petitions, transcript } = this.state;

    const loading = loading_sections || loading_petitions;

    const columns = [
      // {
      //   title: "CRN(s)",
      //   key: "crn",
      //   width: 70,
      //   render: (text, record, idx) => <>{record.any_section ? "Any" : record.crn.map(el => <p>{el}</p>)}</>
      // },
      {
        title: "Course to Drop",
        key: "selected_drop_course",
        width: 320,
        render: (text, record, idx) => record.selected_drop_course ? this.print_full_course(record.selected_drop_course) :
          record.selected_drop_non_khoury_course ? record.selected_drop_non_khoury_course : "N/A",
      },
      {
        title: "Course",
        key: "course",
        width: 320,
        render: (text, record, idx) => this.print_full_course(record.course),
      },
      {
        title: "Semester",
        key: "semester",
        width: 120,
        render: (text, record, idx) => this.print_semester(record.semester),
      },
      {
        title: "Decision",
        key: "decision",
        width: 100,
        render: (text, record, idx) => renderStatus(record.decision),
      },
      {
        title: "Actions",
        key: "actions",
        width: 100,
        align: "right",
        render: this.getActions
      }
    ];

    return (
      <Content
        {...this.props}
        title={"UG Closed Course Petition for " + semester}
        breadcrumbs={[{ link: "/student", text: "Students" }, { text: "UG" }, { text: "Closed Course" }]}
      >
        <p>
          Welcome to the new closed course petition form. <strong>Note: We cannot
            guarantee access to any of these classes; If you see an open seat,
            feel free to register yourself in the meantime.</strong> This survey is only used to
          register your interest in the course in case we are able to make it
          available to you. All of your entries will not be saved until you click
          submit; if an error occurs, a popup will be shown.
        </p>
        <React.Fragment>
          <Divider orientation="left">
            Submitted Closed Course Petitions
          </Divider>
          <Table
            dataSource={viewpetition}
            columns={columns}
            loading={loading}
            rowKey="id"
          />
          {this.state.visible && (
            <React.Fragment>
              <Divider orientation="left">New Closed Course Petition</Divider>
              <p>
                Please fill out the form below, which will be used to help the
                advising staff determine which course(s) are the best fit for
                your degree program. Once you have selected a course, please
                select a section you would like to petition. Note: You cannot
                petition a course that has open seats, so only courses and
                sections that are full or close to capacity are shown.
              </p>

              <ClosedCourseForm
                {...this.props}
                sections={sections}
                petitions={viewpetition}
                transcript={transcript}
                getData={this.getData}
              />
            </React.Fragment>
          )}
          {!this.state.visible && (
            <Divider orientation="center">
              {" "}
              Application Period for {this.props.semester} is Closed
            </Divider>
          )}
        </React.Fragment>
      </Content>
    );
  }
}

export default UGClosedCoursePetition;