import React, { Component } from "react";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import { oxford } from './Utils';

import { Table, Form, Switch, Layout, Tooltip, Icon } from 'antd';
const FormItem = Form.Item;

class CoordinatorTable extends AppComponent {
  render() {
    const { coordinators, hide_columns } = this.props;

    const columns = [
      {
        title: 'Semester',
        key: 'semester',
        render: (text, record, idx) => this.print_semester(record.semester),
      }, {
        title: 'Course',
        key: 'course',
        render: (text, record, idx) => this.print_course(record.course),
      }, {
        title: 'Instructor',
        key: 'sections',
        align: 'left',
        render: (text, record, idx) => this.link_instructor(record.instructor),
      }, {
        title: 'Load',
        key: 'load',
        align: 'right',
        render: (text, record, idx) => ( <NumberFormat displayType="text" decimalScale={ 2 } fixedDecimalScale={ true } value={ record.loadcount } /> ),
      }
    ];

    const mycolumns = columns.filter(el => !hide_columns || !hide_columns.includes(el.key));

    return <Table {...this.props} dataSource={ coordinators } columns={ mycolumns } bordered={ false } pagination={ false } size="small" rowKey={ (record) => record.id } />;
  }
}

export default CoordinatorTable;