import React from "react";
import { Radio, Icon, Select, Switch, Typography, Alert, Button } from "antd";
import AppComponent from "../../AppComponent";
import { get_check_nocheck } from "../../Utils";
import { CSVLink } from "react-csv";
import EditableTable from "../../EditableComponents";
import Content from "../../Content";
import Transcript from "../../Transcript";
import { renderStatus } from "../../Utils";

const { Text, Title } = Typography;

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const render_preference_level = number => {
  var result = [];
  for (let i = 1; i < number; ++i) {
    result.push(
      <Icon key={i} type="star" theme="twoTone" twoToneColor="#CDC60E" />
    );
  }

  return result;
};

const columnsStudent = props => [
  {
    title: "NUID",
    key: "nuid",
    width: 90,
    render: (text, record, idx) => record.nuid,
    sorter: (a, b) => a.nuid - b.nuid,
    download: record => record.nuid
  },
  {
    title: "Name",
    key: "name",
    width: 150,
    sorter: (a, b) => a.lastname.localeCompare(b.lastname),
    render: (text, record, idx) => [ record.firstname + " " + record.lastname, " ",
      <a key="email" href={"mailto:" + record.email}>
        <Icon type="mail" theme="twoTone" />
      </a>
    ],
    download: record => record.firstname + " " + record.lastname,
  },
  {
    title: "Transcript",
    key: "transcript",
    render: (text, record, idx) => <Transcript {...props} nuid={record.nuid} />
  },
  {
    title: "GPA",
    dataIndex: "gpa",
    key: "gpa",
    width: 70,
    align: "center",
    download: record => record.gpa
  },
  {
    title: "Taken Graduate Courses",
    dataIndex: "takenGrad",
    key: "takenGrad",
    width: 90,
    align: "center",
    render: takenGrad => get_check_nocheck(takenGrad),
    download: record => record.takenGrad
  },
  {
    title: "Graduate History",
    dataIndex: "gradHistory",
    key: "gradHistory",
    width: 200,
    download: record => record.gradHistory
  }
];

const preferenceColumn = {
  title: "Preference Level",
  dataIndex: "preference",
  key: "preference",
  sorter: (a, b) => a.preference - b.preference,
  render: number => render_preference_level(number),
  download: record =>
    record.preference == 4
      ? "First"
      : record.preference == 3
      ? "Second"
      : "Third"
};

const columnsSection = [
  {
    title: "Course",
    dataIndex: "course",
    key: "course",
    width: 750,
    download: record => record.course
  }
];

const amountColumn = {
  title: "Amount of Applications",
  dataIndex: "amount",
  key: "amount",
  download: record => record.amount
};

const approvalColumn = {
  title: "Decision",
  key: "approved",
  width: 100,
  render: (text, record, idx) =>
    renderStatus(record.approved ? "Accepted" : "Denied"),
  download: record => (record.approved ? "Accepted" : "Denied")
};

// {
//   title: "Approved",
//   dataIndex: "approved",
//   key: "approved",
//   editable: true
// };

const noteColumn = {
  title: "Student Note",
  dataIndex: "notes",
  key: "notes",
  download: record => record.notes
};

const actionColumn = { title: "Action", dataIndex: "action", key: "action" };

class PlusOneResponses extends AppComponent {
  state = {
    endpoint: "/api/preferences/plusone/",
    responses: [],
    loading: true,

    endpoint_students: "/api/plusone/student/",
    students: [],
    loading_student: true,

    endpoint_sections: "/api/schedule/",
    sections: [],
    loading_sections: true,

    courses: [],

    currentTable: "student",
    columns: [],
    responsesByStudents: [],
    responesBySections: [],
    currentSemester: "",

    endpoint_viewable: "/api/petitionviewable/",
    visible: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.state.loading = true;
      this.state.loading_student = true;
      this.state.loading_sections = true;
      this.update();
    }
  }

  componentDidMount() {
    this.update();
  }

  update = () => {
    const { semesters } = this.props;
    const semester_info = this.props.semesters_list_grouped[
      this.props.semester
    ];
    this.doGet(
      this.state.endpoint_viewable +
        "?semester=" +
        this.props.semesters.join(","),
      data => {
        this.setState({ visible: data[0].petitions_visible_po });
      }
    );
    this.doGet(
      this.state.endpoint +
        "?section__semester=" +
        this.props.semesters.join(","),
      data => {
        this.setState({ responses: this.makeDictionary(data), loading: false });
      }
    );
    this.doGet(this.state.endpoint_students, data => {
      this.setState({
        students: this.makeDictionary(data),
        loading_student: false
      });
    });
    this.doGet(
      this.state.endpoint_sections +
        "?semester=" +
        this.props.semesters.join(","),
      data => {
        this.setState({
          sections: this.makeDictionary(data),
          loading_sections: false
        });
      }
    );

    var allCourses = this.course_list();
    this.setState({
      courses: this.makeDictionary(allCourses),
      current_semester: semester_info[0].name
    });
  };

  onChange = checked => {
    const { semesters } = this.props;
    const semester = this.get_semester(semesters[0]);
    const id = semester.id;
    this.doPatch(
      "/api/petitionviewable/" + id + "/",
      () => {
        this.setState({ visible: checked });
      },
      JSON.stringify({ petitions_visible_po: checked })
    );
  };

  makeDictionary(data, key = "id") {
    var grouped = {};

    for (let i = 0; i < data.length; ++i) {
      let record = data[i];
      let record_key = record[key];
      grouped[record_key] = record;
    }

    return grouped;
  }

  groupUnique(data, key) {
    const { current_semester } = this.state;
    var grouped = {};

    for (let i = 0; i < data.length; ++i) {
      let record = data[i];
      let section = record[key];
      if (!grouped[section]) {
        grouped[section] = [];
      }

      //if (record.semester === current_semester) {
        grouped[section].push(record);
      //}
    }

    return grouped;
  }

  changeEntry = (id, key, value) => {
    const { responses } = this.state;

    let newEntry = responses[id];
    newEntry[key] = value;
    let newResponses = { ...responses };
    newResponses[id] = newEntry;

    this.setState({ responses: newResponses });
  };

  handleSave = row => {
    let id = row["entry_id"];
    let note = row["notes"];

    this.changeEntry(id, "notes", note);
    // this.updateNote(id, note);
  };

  toggleApprove = (entry_id, value) => {
    this.changeEntry(entry_id, "staff_approval", value);
    this.approveApplication(entry_id, value);
  };

  // updateNote = (id, value) => {
  //   const { endpoint } = this.state;

  //   this.doPatch(
  //     endpoint + id + "/",
  //     () => console.log("success"),
  //     JSON.stringify({ staff_note: value, id: id })
  //   );
  // };

  approveApplication = (id, value) => {
    const { endpoint } = this.state;
    this.doPatch(
      endpoint + id + "/",
      () => console.log("success"),
      JSON.stringify({ staff_approval: value, id: id })
    );
  };

  handleClick = id => {
    this.setState({ currentTable: event.target.value });
  };

  createRow = record => {
    const { currentTable } = this.state;
    let entries = record.entries;
    let key = record.key;

    const data =
      currentTable === "section"
        ? this.createStudents(entries)
        : this.createSections(entries);
    const tableData = [];

    for (let i = 0; i < data.length; ++i) {
      let response = data[i];

      let entry = response.entries[0];
      let id = entry["id"];
      let preferenceLevel = entry["preference"];
      let approved = entry["staff_approval"];
      if (currentTable != "section") {
        response["nuid"] = record.nuid;
        response["firstname"] = record.firstname;
        response["lastname"] = record.lastname;
        response["gpa"] = record.gpa;
        response["takenGrad"] = record.takenGrad;
        response["gradHistory"] = record.gradHistory;
        response["notes"] = record.notes;
      } else {
        response["course"] = record.course;
      }

      response["preference"] = preferenceLevel;
      response["approved"] = approved;
      response["entry_id"] = id;

      tableData.push(response);
    }
    return tableData;
  };

  createCSV = data => {
    let csvData = [];

    data.forEach(record => {
      let row = this.createRow(record);
      csvData = csvData.concat(row);
    });
    return csvData;
  };

  renderRow = record => {
    const { currentTable } = this.state;

    const tableData = this.createRow(record);

    var actionCol = {
      ...actionColumn,
      render: (text, record, idx) => (
        <span>
          <a
            key="approval"
            href="#"
            onClick={e => {
              e.preventDefault();
              this.toggleApprove(record.entry_id, !record.approved);
            }}
          >
            {" "}
            {!record.approved ? (
              <Icon
                style={{ fontSize: "1.5em" }}
                type="check-square"
                theme="twoTone"
                // twoToneColor="#52c41a"
              />
            ) : (
              <Icon
                style={{ fontSize: "1.5em" }}
                type="close-square"
                theme="twoTone"
                // twoToneColor="#eb2f96"
              />
            )}
          </a>
          {/* <Divider type = "vertical" />
                                                         <a key="email" href={ "mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> */}
        </span>
      )
    };

    var columns =
      currentTable === "section"
        ? [
            ...columnsStudent(this.props),
            preferenceColumn,
            approvalColumn,
            actionCol
          ]
        : [...columnsSection, preferenceColumn, approvalColumn, actionCol];

    return (
      <EditableTable
        {...this.props}
        style={{ margin: "16px", marginLeft: "10px" }}
        columns={columns}
        pagination={false}
        dataSource={tableData.filter(record => record.preference > 1)}
        handleSave={this.handleSave}
        approve={this.approve}
      />
    );
  };


  getCourseNameBySection(sectionId) {
    const { sections, courses } = this.state;
    let section = sections[sectionId];
    let crn = section["crn"];
    let courseId = section["course"];
    let semester = section["semester"];
    let course = courses[courseId];
    let name =
      // "CRN:" + crn + " | " +
      this.print_course(courseId) +
      " " +
      course.title +
      " " +
      "(CRN: " +
      crn +
      " - " +
      this.print_semester(semester) +
      ")";
    return name;
  }

  createStudents(data) {
    const { students } = this.state;

    var groupedResponses = this.groupUnique(data, "student");

    var ids = Object.keys(groupedResponses);

    var data = [];
    for (let i = 0; i < ids.length; ++i) {
      let id = ids[i];

      let student = students[id];

      let gpa = student.gpa;
      let takenGrad = student.taken_grad;
      let gradHistory = student.grad_history;
      let nuid = student.nuid;
      let notes = student.notes;
      let firstname = student.firstname;
      let lastname = student.lastname;
      let email = student.email;
      let amount = groupedResponses[id].filter(record => record.preference > 1)
        .length;

      let record = {
        nuid: nuid,
        firstname: firstname,
        lastname: lastname,
        email: email,
        studentId: id,
        gpa: gpa,
        takenGrad: takenGrad,
        gradHistory: gradHistory,
        amount: amount,
        entries: groupedResponses[id],
        key: id,
        notes: notes
      };
      data.push(record);
    }
    return data;
  }

  changeSemester = value => {
    this.setState({ current_semester: value });
  };

  createSections(data) {
    const { sections, courses, students } = this.state;

    var groupedResponses = this.groupUnique(data, "section");

    var ids = Object.keys(groupedResponses);

    var data = [];

    for (let i = 0; i < ids.length; ++i) {
      let id = ids[i];
      let courseName = this.getCourseNameBySection(id);
      let amount = groupedResponses[id].filter(record => record.preference > 1)
        .length;

      let record = {
        course: courseName,
        amount: amount,
        entries: groupedResponses[id],
        key: "section" + id
      };

      data.push(record);
    }

    return data;
  }

  render() {
    const { semester } = this.props;
    const {
      responses,
      students,
      courses,
      sections,
      loading,
      loading_student,
      loading_sections,
      currentTable
    } = this.state;

    var dataSections;
    var dataStudents;

    var everythingDone = !loading && !loading_student && !loading_sections;
    var responsesList = Object.values(responses);

    if (everythingDone) {
      dataSections = this.createSections(responsesList);
      dataStudents = this.createStudents(responsesList);
    }

    const columnsStudents = [...columnsStudent(this.props), noteColumn];
    const columnsSections = [...columnsSection, amountColumn];

    var columnsCSV =
      currentTable === "section"
        ? [
            ...columnsSection,
            ...columnsStudent(this.props),
            noteColumn,
            preferenceColumn,
            approvalColumn
          ]
        : [
            ...columnsStudent(this.props),
            noteColumn,
            ...columnsSection,
            preferenceColumn,
            approvalColumn
          ];

    const semester_info = this.props.semesters_list_grouped[
      this.props.semester
    ];
    const summer = semester_info.length > 1;
    const semesters = semester_info.map(info => info.name);
    const style = { margin: 16 };
    // if (everythingDone) this.createCSV(dataStudents);

    return (
      <Content
        {...this.props}
        title={"PlusOne"}
        breadcrumbs={[
          { link: "/staff", text: "Staff" },
          { text: "Petition" },
          { text: "PlusOne" },
          { text: semester }
        ]}
      >
        <div style={{ display: "flex", paddingBottom: 10 }}>
          <span style={{ display: "flex", marginLeft: "auto" }}>
            <Text strong> close / open the petition window &nbsp;</Text>
            <Switch checked={this.state.visible} onChange={this.onChange} />
          </span>
        </div>

        {!this.state.visible && (
          <Alert
            message="The Petition Window is Closed"
            description="Currently, students cannot submit new applications"
            type="warning"
            showIcon
          />
        )}

        <React.Fragment>
          <RadioGroup style={{ margin: "16px" }} defaultValue="student">
            <RadioButton onClick={this.handleClick} value="student">
              Students
            </RadioButton>
            <RadioButton onClick={this.handleClick} value="section">
              Sections
            </RadioButton>
          </RadioGroup>

          {everythingDone && (
            <CSVLink
              filename="plusone_export.csv"
              data={this.getCsvData(
                currentTable === "student"
                  ? this.createCSV(dataStudents)
                  : this.createCSV(dataSections),
                columnsCSV
              )}
            >
              <Button icon="download">Download</Button>
            </CSVLink>
          )}

          <EditableTable
            {...this.props}
            dataSource={
              currentTable === "student" ? dataStudents : dataSections
            }
            columns={
              currentTable === "student" ? columnsStudents : columnsSections
            }
            bordered={false}
            pagination={false}
            size="small"
            loading={!everythingDone}
            expandedRowRender={this.renderRow}
            handleSave={this.handleSave}
          />
        </React.Fragment>
      </Content>
    );
  }
}

export default PlusOneResponses;
