import React, { Component } from "react";
import AppComponent from './AppComponent';
import queryString from 'query-string';
import { Tabs } from 'antd';

class CustomTabs extends AppComponent {

  render() {
    const { default_Active_Key, tabPosition, tabBarExtraContent } = this.props;

    const query_tab = queryString.parse(window.location.search)["tab"];
    const enabled = this.props.children.filter(c => c && c.props && ((!c.props.hasOwnProperty("disabled")) || (c.props.disabled == false)));
    const enabled_child_tab = enabled?.length > 0 ? enabled[0].key : null;
    const all_child_tab = this.props.children?.length > 0 && this.props.children[0] ? this.props.children[0].key : null;

    const key = query_tab ? query_tab : default_Active_Key ? default_Active_Key : enabled_child_tab ? enabled_child_tab : all_child_tab;
    return (
        <React.Fragment>
          <div className="card-container">
            <Tabs defaultActiveKey={ "" + key } tabBarExtraContent={tabBarExtraContent} tabPosition={tabPosition} onChange={e => {"onChange" in this.props && this.props.onChange(e); this.props.replaceQueryString(e)}} type="card" size="large">
              {this.props.children}
            </Tabs>
          </div>
        </React.Fragment>
      );
  }
}

export default CustomTabs;
