import React from "react";
import { Table, Input, Button, Popconfirm, Form, Icon } from "antd";

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => {
  return (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  );
};

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    let value = e.currentTarget.value;
    this.toggleEdit();

    handleSave({ ...record, note: value });
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      approve,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {form => {
              this.form = form;

              const notEmptyCell = (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );

              const emptyCell = (
                <a
                  key="note"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    this.toggleEdit();
                  }}
                >
                  <Icon type="plus-square" theme="twoTone" />
                </a>
              );
              const noCheck = (
                <Icon
                  onClick={approve}
                  type="close-circle"
                  theme="twoTone"
                  twoToneColor="#eb2f96"
                />
              );
              const check = (
                <Icon
                  onClick={approve}
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
              );

              if (dataIndex === "approved") {
                return record[dataIndex] ? check : noCheck;
              }

              return editing ? (
                <FormItem style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: false,
                        message: `${title} is required.`
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(
                    <Input
                      ref={node => (this.input = node)}
                      onPressEnter={this.save}
                      onBlur={this.save}
                    />
                  )}
                </FormItem>
              ) : record[dataIndex] ? (
                notEmptyCell
              ) : (
                emptyCell
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      dataSource,
      columns,
      handleSave,
      loading,
      expandedRowRender,
      bordered,
      pagination,
      size,
      scroll
    } = this.props;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const newColumns = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave
        })
      };
    });
    return (
      <div>
        <Table
          components={components}
          dataSource={dataSource}
          columns={newColumns}
          bordered={bordered}
          pagination={pagination}
          size={size}
          loading={loading}
          scroll={scroll}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default EditableTable;
