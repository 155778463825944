import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import queryString from "query-string";
import key from "weak-key";
import LoggedInComponent from "./LoggedInComponent";
import { oxford, print_load_component, text_max, add_brs } from "./Utils";
import moment from "moment";

import {
  Layout,
  Menu,
  Breadcrumb,
  Icon,
  Table,
  Tag,
  Tooltip,
  Spin,
  Divider,
  Popover,
  message
} from "antd";
const { SubMenu } = Menu;
const { Content, Sider, Footer } = Layout;

class AppComponent extends LoggedInComponent {
  STATUS_GOOD = 0;
  STATUS_FAILED = 1;
  STATUS_PENDING = 2;
  STATUS_NONE = 3;

  get_text = e => {
    return e.props && e.props.children
      ? this.get_text(e.props.children)
      : Array.isArray(e)
      ? e
          .map(f => this.get_text(f))
          .flat()
          .reduce((r, a) => r + a, "")
      : e.toLowerCase
      ? e
      : "";
  };

  filter = (value, option) => {
    const { children } = option.props;
      
    if (option.type.isSelectOptGroup) {
      return children.includes((child) => child.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0);
    }
    return ( this.get_text(option).toLowerCase().indexOf(value.toLowerCase()) >= 0 ||  option.props.is_header );
  };

  grid = { gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 };

  grid_calendar = { gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 };

  grid_preference = { gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 };

  grid_photos = { gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6 };
  
  formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

  getLink = (dest, args) => {
    return dest.startsWith("http")
      ? dest
      : dest + this.props.getQueryString(false, false) + (args ? "&" + Object.keys(args).map(k => k + "=" + args[k]).join("&") : "");
  };

  is_beta = () =>
    window.location.hostname == "admin-beta.khoury.northeastern.edu" || window.location.hostname == "admin-audit.khoury.northeastern.edu" || window.location.hostname == "admin-alpha.khoury.northeastern.edu";
  is_local = () => window.location.hostname == "127.0.0.1";

  top_margin = () => {
    return (
      64 +
      (this.is_beta() || this.is_local() ? 32 : 0) +
      (this.props.user.impersonator ? 32 : 0) + 
      (32 * this.props.alerts.length)
    );
  };

  permission = (type, required) => {
    const perm = this.props.user.groups.map(el => el.name);
    if (type == "can" || type == "cannot") {
      if (perm.includes("admin") && type == "can") return type == "can";
      return type == "can" ? perm.includes(required) : !perm.includes(required);
    } else if (type == "is") {
      return perm.includes(required);
    }
  }
  
  // Due to Django stupidity, it will return multiple copies of sections due to sorting.  We keep only the first one here.
  deduplicateSections = data => {
    const ids = [];
    return data.filter(e => {
      if (ids.includes(e.id)) {
        return false;
      } else {
        ids.push(e.id);
        return true;
      }
    });
  };
  
  get_base_loadcount = (section, crosslist, instructor) => {
    const { new_loadcount } = this.props;
    
    if (!new_loadcount.use || !section.loadcount) { return section.loadcount; }
    
    const i = instructor ? this.get_instructor(instructor) : null;
    if (i != null) {
      const rank_id = this.get_rank_from_history(i.ranks, this.get_semester(section.semester));
      if (rank_id) {
        const rank = this.get_instructorrank(rank_id);
        if (rank.rank.includes("Part-time") || rank.rank.includes("Assistant Professor")) {
          return section.loadcount;
        }
      } else {
        return section.loadcount;
      }
    }

    const size = new_loadcount.enrollment_cap == "enrollment" ? section.enrollment + (crosslist ? crosslist.enrollment : 0) : section.capacity + (crosslist ? crosslist.capacity : 0);
    
//    const course = this.get_course(section.course);
//    const credits = course.hours / 4.0;
    
    return section.loadcount * (new_loadcount.min + (new_loadcount.max-new_loadcount.min) / (1 + Math.exp(-1 * new_loadcount.k * (size - new_loadcount.x_0))));
  }

  get_instructor_section_loadcount = (section, crosslist, instructor) => {
    return this.get_base_loadcount(section, crosslist, instructor) / ((section.instructors?.length > 0 ? section.instructors.length : 1.0) * (crosslist ? 2.0 : 1.0));
  }
  
  get_total_section_loadcount = (section, crosslist) => {
    return section.instructors?.length > 0 ? section.instructors.reduce((r,i) => r + this.get_base_loadcount(section, crosslist, i), 0) / section.instructors.length : this.get_base_loadcount(section, crosslist, null);
  }
  
  // turns a table into something react-csv likes

  //the below function does not skip over columns without a "download" function
  // getCsvData = (data, columns) => {
  //   let all_data = [];
  //   data.forEach(e => {
  //     let dict = {};
  //     columns.forEach(column => {
  //       dict[column.title] = column.download ? column.download(e) : null;
  //     });
  //     all_data.push(dict);
  //   });
  //   return all_data;
  // };

  //this function skips over columns without a "download" function and uses the column key for the csv title if the column is missing a title

  getCsvData = (data, columns) => {
    const filteredData = [];
  
    data.forEach(item => {
      const row = {};
  
      columns.forEach(column => {
        const processColumn = (col, parentKey = '') => {
          const key = parentKey ? `${parentKey}_${col.key}` : col.key;
  
          if (col.download) {
            const title = col.title ? col.title : col.key;
            row[title] = col.download(item);
          }
  
          if (col.children && col.children?.length > 0) {
            col.children.forEach(childCol => {
              processColumn(childCol, key);
            });
          }
        };
  
        processColumn(column);
      });
  
      filteredData.push(row);
    });
  
    return filteredData;
  };
  

  lookup = (id, object, name) => {
    const value = object[id];
    if (!value) {
      if (id) {
        message.error("Could not find " + name + " with ID " + id + ".");
      }
      return null;
    }

    return value;
  };

  get_semester = semester_id => {
    return this.lookup(semester_id, this.props.semester_list, "semester");
  };

  find_semester = (date) => {
    return this.semester_list().filter(el => 
      moment(date, "YYYY-MM-DD").isBetween(moment(el.startdate, "YYYY-MM-DD"), moment(el.enddate, "YYYY-MM-DD"), undefined, '[]')
    );
  }


  get_taportal_status = semester_ids => {
    return semester_ids.map(sem => this.get_semester(sem).ta_application).some(Boolean)
  }

  //Research Apprenticeship form visibility per semester

  get_research_apprenticeship_nomination_status = semester_ids => {
    return semester_ids.map(sem => this.get_semester(sem).apprenticeship_nomination).some(Boolean)
  }

  get_research_apprenticeship_application_status = semester_ids => {
    return semester_ids.map(sem => this.get_semester(sem).apprenticeship_application).some(Boolean)
  }

  get_research_apprenticeship_faculty_review_status = semester_ids => {
    return semester_ids.map(sem => this.get_semester(sem).apprenticeship_faculty_review).some(Boolean)
  }

  get_semestertype = semestertype_id => {
    return this.lookup(
      semestertype_id,
      this.props.semestertype_list,
      "semester type"
    );
  };

  semester_conflict = (sem_id_a, sem_id_b) => {
    if (sem_id_a == sem_id_b) {
      return true;
    }
    
    const sem_a = this.get_semester(sem_id_a);
    const sem_b = this.get_semester(sem_id_b);
    
    return sem_a.year == sem_b.year && (sem_a.semestertype == 50 || sem_b.semestertype == 50) && (sem_a.semestertype == 40 || sem_b.semestertype == 40 || sem_a.semestertype == 60 || sem_b.semestertype == 60);
  }

  get_year = year_id => {
    return year_id;
  };

  calendar_year = (semester_id) => {
    const str = this.get_semester(semester_id).name;
    return str.substring(str.length-4);
  }

  get_committee = committee_id => {
    return this.lookup(committee_id, this.props.committee_list, "committee");
  };

  get_committee_year = committee_year_id => {
    return this.lookup(committee_year_id, this.props.committeeyear_list, "committee");
  };

  get_position = position_id => {
    return this.lookup(position_id, this.props.position_list, "position");
  };

  get_position_year = position_year_id => {
    return this.lookup(position_year_id, this.props.positionyear_list, "position");
  };

  get_course = course_id => {
    return this.lookup(course_id, this.props.course_list, "course");
  };

  get_fund = fund_id => {
    return this.lookup(fund_id, this.props.fund_list, "fund");
  };

  get_account = account_id => {
    return this.lookup(account_id, this.props.account_list, "account");
  }

  get_grade = grade_id => {
    return this.lookup(grade_id, this.props.grade_list, "grade");
  };

  get_nupath = nupath_id => {
    return this.lookup(nupath_id, this.props.nupath_list, "NUPath");
  };

  get_nucore = nucore_id => {
    return this.lookup(nucore_id, this.props.nucore_list, "NUCore");
  };

  get_employee = employee_id => {
    return this.lookup(employee_id, this.props.employee_list, "employee");
  };

  get_instructor = instructor_id => {
    return this.lookup(instructor_id, this.props.instructor_list, "instructor");
  };

  get_major = major_id => {
    return this.lookup(major_id, this.props.major_list, "major");
  };

  get_degreetype = degreetype_id => {
    return this.lookup(
      degreetype_id,
      this.props.degreetype_list,
      "degree type"
    );
  };

  get_degree = degree_id => {
    return this.lookup(degree_id, this.props.degree_list, "degree");
  };
  
  get_area = area_id => {
    return this.lookup(area_id, this.props.area_list, "area");
  }

  get_instructorrank = rank_id => {
    return this.lookup(rank_id, this.props.instructorrank_list, "instructor rank");
  };

  get_instructortype = type_id => {
    return this.instructortype_list().find(e => e.id == type_id);
  };

  get_instructorsubtype = subtype_id => {
    return this.instructorsubtype_list().find(e => e.id == subtype_id);
  };

  get_instructor_emails_from_sections = sections => {
    const instructors = sections.map(el => el.instructors);
    return instructors.map(instr => this.get_instructor_email(instr));
  }

  get_instructor_email = instructor_id => {
    const instructor = this.get_instructor(instructor_id);
    return instructor && instructor.email ? instructor.email : "";    
  }

  get_method = method_id => {
    return this.lookup(method_id, this.props.method_list, "method");
  };

  get_campus = campus_id => {
    return this.lookup(campus_id, this.props.campus_list, "campus");
  };

  get_college = college_id => {
    return this.lookup(college_id, this.props.college_list, "college");
  };

  get_subject = subject_id => {
    return this.lookup(subject_id, this.props.subject_list, "subject");
  };

  get_room = room_id => {
    return this.lookup(room_id, this.props.room_list, "room");
  };

  get_meetingtime = meetingtime_id => {
    return this.lookup(
      meetingtime_id,
      this.props.meetingtime_list,
      "meeting time"
    );
  };

  cycle_to_text = short_hand => {
    return short_hand == "FL" ? "Fall" : "Spring";
  };

  course_comparator = (a, b) => {
    return a.subject == b.subject
      ? a.number - b.number
      : this.get_subject(a.subject).abbrv.localeCompare(
          this.get_subject(b.subject).abbrv
        );
  };

  account_comparator = (a, b) => {
    return this.get_account(a).description.localeCompare(this.get_account(b).description);
  };

  meetingtime_comparator = (a, b) => {
    const compare_element = (a, b) => {
      return a < b ? -1 : a > b ? 1 : 0;
    };

    const compare = (a, b) => {
      const res = a.map((e, i) => compare_element(e, b[i])).find(el => el != 0);
      return res ? res : 0;
    };

    const compare_mtb = (a, b) => {
      if (!a) {
        return -1;
      }
      if (!b) {
        return 1;
      }

      const a_arr = [a.day, a.start, a.end];
      const b_arr = [b.day, b.start, b.end];

      return compare(a_arr, b_arr);
    };

    var a_mtb = a.meetingtimeblocks;
    var b_mtb = b.meetingtimeblocks;

    a_mtb.sort(compare_mtb);
    b_mtb.sort(compare_mtb);

    const res = a_mtb
      .map((e, i) => compare_mtb(e, b_mtb[i]))
      .find(el => el != 0);
    return res ? res : 0;
  };

  employee_comparator = (a, b) => {
    const a_first = a.firstname_preferred ? a.firstname_preferred : a.firstname;
    const b_first = b.firstname_preferred ? b.firstname_preferred : b.firstname;
    return a.lastname == b.lastname
      ? a_first.localeCompare(b_first)
      : a.lastname.localeCompare(b.lastname);
  };

  instructor_comparator = (a, b) => {
    return this.employee_comparator(this.get_employee(a.employee), this.get_employee(b.employee));
  };

  lookup_list = (name, sorter) => {
    const list = Object.values(this.props[name]);
    if (sorter) {
      list.sort(sorter);
    }
    return list;
  };

  semester_sort = (a, b) => (b ? b.code : 0) - (a ? a.code : 0);

  semester_list = () => {
    return this.lookup_list("semester_list", this.semester_sort);
  };

  semester_list_grouped = () => {
    const s = this.props.semesters_list_grouped;
    const result = Object.keys(s).sort((a, b) =>
      s[b][0].startdate.localeCompare(s[a][0].startdate)
    );
    return result.map(el => {
      return { id: el, name: el };
    });
  };

  get_year = () => {
    return this.get_semester(this.props.semesters[0]).year;
  };

  semestertype_list = () => {
    return this.lookup_list("semestertype_list", (a, b) => a.id - b.id);
  };

  year_list = () => {
    var years = [];
    Object.values(this.props.semester_list)
      .map(el => el.year)
      .forEach(el => {
        if (!years.includes(el)) {
          years.push(el);
        }
      });
    years.sort();
    return years;
  };

  campus_sort = (a, b) => a.name.localeCompare(b.name);

  college_sort = (a, b) => a.name.localeCompare(b.name);

  campus_list = () => {
    return this.lookup_list("campus_list", this.campus_sort);
  };

  college_list = () => {
    return this.lookup_list("college_list", this.college_sort);
  };

  committee_list = () => {
    return this.lookup_list("committee_list", (a, b) =>
      a.name.localeCompare(b.name)
    );
  };

  committeeyear_list = () => {
    return this.lookup_list("committeeyear_list", (a, b) =>
      a.year == b.year
        ? this.get_committee(a.committee).name.localeCompare(
            this.get_committee(b.committee).name
          )
        : a.year - b.year
    );
  };

  position_list = () => {
    return this.lookup_list("position_list", (a, b) =>
      a.name.localeCompare(b.name)
    );
  };

  positionyear_list = () => {
    return this.lookup_list("positionyear_list", (a, b) =>
      a.year == b.year
        ? this.get_position(a.position).name.localeCompare(
            this.get_position(b.position).name
          )
        : a.year - b.year
    );
  };

  course_list = () => {
    return this.lookup_list("course_list", this.course_comparator);
  };

  course_list_graduate = () => {
    return this.lookup_list("course_list", this.course_comparator).filter(el => this.get_course(el.id).number >= 5000);
  };

  fund_list = () => {
    return this.lookup_list("fund_list", (a, b) => a.fund == b.fund ? a.index - b.index : a.fund - b.fund);
  };

  account_list = () => {
    return this.lookup_list("account_list", this.account_comparator);
  };

  grade_sort = (a, b) => (a ? a.grade : "Z").localeCompare(b ? b.grade : "Z");

  grade_list = () => {
    return this.lookup_list("grade_list", this.grade_sort);
  };

  course_list_from_sections = sections => {
    const courses = sections.reduce((r, a) => {
      r[a.course] = 1;
      return r;
    }, {});
    return this.course_list().filter(el => courses[el.id]);
  };

  course_list_from_sections_with_groups = (sections, groups) => {
    const sections_not_in_groups = sections.filter(s => groups.find(g => g.sections.find(gs => gs.id == s.id)) == null);
    const non_group_list = this.course_list_from_sections(sections_not_in_groups).map(c => { return { course: c }; });
    
    return non_group_list.concat(groups.map(g => { return { group: g }; })).sort((a, b) => {
      const a_course = a.course ? a.course : a.group.sections.map(s => this.get_course(s.course)).sort(this.course_comparator)[0];
      const b_course = b.course ? b.course : b.group.sections.map(s => this.get_course(s.course)).sort(this.course_comparator)[0];
      return this.course_comparator(a_course, b_course);
    });
  };

  instructor_list_from_sections = sections => {
    const instructors = sections.reduce((r, a) => {
      a.instructors.forEach(i => (r[i] = 1));
      return r;
    }, {});
    return this.instructor_list().filter(el => instructors[el.id]);
  };

  coop_advisor_list = () => {
    const instructorList = this.instructor_list();
    return instructorList.filter(el => {
      const lastRank = el.ranks[el.ranks.length - 1];
      
      if (lastRank?.end === null) {
        const rankSubtype = this.get_instructorrank(lastRank.rank).subtype.subtype;
        return rankSubtype === "Co-Op";
      }
      return false;
    });
  };

  advisor_list = () => {
    const instructorList = this.instructor_list();
    return instructorList.filter(el => {
      const lastRank = el.ranks[el.ranks.length - 1];
      if (lastRank?.end === null) {
        const rankSubtype = this.get_instructorrank(lastRank.rank).subtype.subtype;
        return rankSubtype === "Advising";
      }
      return false;
    });
  };

  course_campus_semester_list_from_sections = sections => {
    var objects = Object.values(
      sections
        .filter(s => !s.deleted)
        .reduce((r, a) => {
          const key = a.course + "-" + a.campus + "-" + a.semester;
          r[key] = {
            course: a.course,
            campus: a.campus,
            semester: a.semester,
            ta_ratio: this.get_course(a.course).ta_ratio,
            enrollment: a.enrollment + (r[key] ? r[key].enrollment : 0)
          };
          return r;
        }, {})
    );
    objects.sort((a, b) =>
      this.course_comparator(
        this.get_course(a.course),
        this.get_course(b.course)
      )
    );
    return objects;
  };

  course_list_missing_from_sections = sections => {
    const courses = sections.reduce((r, a) => {
      r[a.course] = 1;
      return r;
    }, {});
    return this.course_list().filter(el => !courses[el.id]);
  };

  employee_list = () => {
    return this.lookup_list("employee_list", this.employee_comparator);
  };

  instructor_list = () => {
    return this.lookup_list("instructor_list", this.instructor_comparator);
  };

  faculty_list = () => {
    return this.instructor_list().filter(el => el.ranks.length !== 0).filter(el => 
      this.is_tenure_track(el.ranks[el.ranks.length-1].rank)  || 
      this.is_tenure_track_untenured(el.ranks[el.ranks.length-1].rank)  || 
      this.is_full_time_non_tenure_track(el.ranks[el.ranks.length-1].rank)
    );
  }

  non_tenure_track_ft_faculty_list = () => {
    return this.instructor_list().filter(el => el.ranks.length !== 0).filter(el => 
      this.is_full_time_non_tenure_track(el.ranks[el.ranks.length-1].rank)
    );
  }

  area_list = () => {
    return this.lookup_list("area_list", (a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  instructorrank_list = () => {
    return this.lookup_list("instructorrank_list", (a, b) =>
      a.subtype.mytype.id == b.subtype.mytype.id ? a.subtype.id == b.subtype.id ? a.order - b.order : a.subtype.order - b.subtype.order : a.subtype.mytype.order - b.subtype.mytype.order
    );
  };

  instructorsubtype_list = (mytype_id) => {
    const r = this.instructorrank_list().reduce((r, a) => { r[a.subtype.id] = { id: a.subtype.id, subtype: a.subtype.subtype, order: a.subtype.order, mytype_id: a.subtype.mytype.id }; return r; }, {});
    return Object.values(r).sort((a, b) => a.mytype_id == b.mytype_id ? a.order - b.order : this.get_instructorrank(a.mytype_id).order - this.get_instructorrank(b.mytype_id).order);
  }

  instructortype_list = () => {
    const r = this.instructorrank_list().reduce((r, a) => { r[a.subtype.mytype.id] = { id: a.subtype.mytype.id, mytype: a.subtype.mytype.mytype, order: a.subtype.mytype.order }; return r; }, {});
    return Object.values(r).sort((a, b) => a.order - b.order);
  }

  get_from_history = (history, semester) => {
    const result = history.find(h => (this.get_semester(h.start).code <= semester.code) && (h.end == null || this.get_semester(h.end).code >= semester.code));
    return result;
  }
  
  get_rank_from_history = (history, semester) => {
    const result = this.get_from_history(history, semester);
    return result ? result.rank : null;
  }
    
  major_list = () => {
    return this.lookup_list("major_list", (a, b) =>
      a.name.localeCompare(b.name)
    );
  };

  degreetype_list = () => {
    return this.lookup_list("degreetype_list", (a, b) =>
      a.name.localeCompare(b.name)
    );
  };

  degree_list = () => {
    return this.lookup_list("degree_list", (a, b) =>
      this.get_degreetype(a.degree_type).abbrv ==
      this.get_degreetype(b.degree_type).abbrv
        ? this.get_major(a.major).name.localeCompare(this.get_major(b.major).name)
        : this.get_degreetype(a.degree_type).abbrv.localeCompare(
            this.get_degreetype(b.degree_type).abbrv
          )
    );
  };

  meetingtime_list = () => {
    return this.lookup_list("meetingtime_list", this.meetingtime_comparator);
  };

  method_list = () => {
    return this.lookup_list("method_list", (a, b) =>
      a.name.localeCompare(b.name)
    );
  };

  room_list = () => {
    return this.lookup_list("room_list", (a, b) =>
      a.building.name == b.building.name
        ? a.number.localeCompare(b.number)
        : a.building.name.localeCompare(b.building.name)
    );
  };

  building_list = () => {
    var buildings = [];
    Object.values(this.props.room_list)
      .map(el => el.building)
      .forEach(el => {
        if (!buildings.find(myel => myel.id == el.id)) {
          buildings.push(el);
        }
      });
    buildings.sort((a, b) => a.name.localeCompare(b.name));
    return buildings;
  };

  subject_list = () => {
    return this.lookup_list("subject_list", (a, b) =>
      a.abbrv.localeCompare(b.abbrv)
    );
  };

  print_semester = semester_id => {
    if (!semester_id) {
      return null;
    }
    const semester = this.get_semester(semester_id);
    return semester.name;
  };

  print_year = year_id => {
    return year_id ? year_id - 1 + "-" + year_id : null;
  };

  print_years_from_semesters = semester_list => {
    const semesters = semester_list.map(this.get_semester);

    const all_years = {};
    semesters.forEach(s => all_years[s.year] = (all_years[s.year] ? all_years[s.year] + 1 : 1));

    return [...new Set(semesters.map(s => all_years[s.year] == 5 ? this.print_year(s.year) : this.print_semester(s.id)))];
  }

  print_semestertype = semestertype_id => {
    const semestertype = this.get_semestertype(semestertype_id);
    return semestertype.name;
  };

  print_major = major_id => {
    return this.get_major(major_id).name;
  };

  print_degreetype = degreetype_id => {
    return this.get_degreetype(degreetype_id).abbrv;
  };

  print_full_degreetype = degreetype_id => {
    return this.get_degreetype(degreetype_id).name;
  };

  print_degree = degree_id => {
    const degree = this.get_degree(degree_id);
    return (
      this.print_degreetype(degree.degree_type) +
      " " +
      this.print_major(degree.major)
    );
  };

  print_committee = committee_id => {
    return this.get_committee(committee_id).name;
  };

  print_committee_description = committee_id => {
    return this.get_committee(committee_id).description;
  };

  print_committee_mailinglist = committee_id => {
    return this.get_committee(committee_id).mailinglist_name ? "khoury-" + this.get_committee(committee_id).mailinglist_name + "-committee" : null;
  };
  
  print_committeeyear = cy => {
    return this.print_committee(cy.committee) + " (" + this.print_year(cy.year) + ")"; 
  };

  print_position = position_id => {
    return this.get_position(position_id).name;
  };

  print_position_description = position_id => {
    return this.get_position(position_id).description;
  };

  link_committee = committee_id => {
    return (
      <Link
        key={committee_id}
        to={this.getLink("/faculty/committees/" + committee_id + "/")}
      >
        {this.print_committee(committee_id)}
      </Link>
    );
  };

  link_fund = fund_id => {
    return (
      <Link
        key={fund_id}
        to={this.getLink("/employee/fund/" + fund_id + "/")}
      >
        {this.print_fund(fund_id)}
      </Link>
    );
  };

  link_fund_staff = fund_id => {
    return (
      <Link
        key={fund_id}
        to={this.getLink("/employee/fund/" + fund_id + "/")}
      >
        {this.print_fund(fund_id)}
      </Link>
    );
  };

  translate_payroll_to_transactions = payroll => {
    return payroll.map(p => {
      return {
        "id": p.id,
        "revenue_amount": p.amount,
        "date": p.pay_date,
        "account": p.account,
        "description": p.payee,
      };
    });
  };

  link_position = position_id => {
    return (
      <Link
        key={position_id}
        to={this.getLink("/employee/positions/" + position_id + "/")}
      >
        {this.print_position(position_id)}
      </Link>
    );
  };

  print_committeeassignment = ca => {
    return this.print_committee(ca.committee) + (ca.chair ? " (Chair)" : "");
  };

  print_committeemembership_details = cm => {
    return []
      .concat(cm.loadcount ? [" (", print_load_component(cm.loadcount), ")"] : [])
      .concat(cm.chair ? ["\u00a0", <Tag size="small" key={"chair-" + cm.id} color="orange">Chair</Tag>] : [])
      .concat(cm.ex_officio ? ["\u00a0", <Tag size="small" key={"ex_officio-" + cm.id} color="green">Ex officio</Tag>] : []);
  }

  print_committeemembership_instructor = cm => {
    return [this.link_full_instructor(cm.instructor)].concat(this.print_committeemembership_details(cm));
  };

  print_committeemembership_committee = cm => {
    return [this.link_committee(this.get_committee_year(cm.committee_year).committee)].concat(this.print_committeemembership_details(cm));
  };

  print_positionyear = py => {
    return [this.link_position(py.position)].concat(py.loadcount ? [" (",  print_load_component(py.loadcount), ")"] : []); 
  };

  print_fund = fund_id => {
    return this.get_fund(fund_id).index ? this.get_fund(fund_id).index : <i>No index</i>;
  };

  print_account = account_id => {
    return this.get_account(account_id).code;
  };

  print_full_account = account_id => {
    const account = this.get_account(account_id);
    return account.code + " " + account.description;
  };

  print_fund_with_owners = fund_id => {
    const fund = this.get_fund(fund_id)
    return [fund.custom_name ? fund.custom_name : fund.name, " ("].concat(fund.owners.map(fo => this.print_employee(fo.owner))).concat([")"]);
  };

  print_full_fund = fund_id => {
    const fund = this.get_fund(fund_id);
    return fund.index + " " + (fund.custom_name ? fund.custom_name : fund.name);
  };

  print_grade = grade_id => {
    if (!grade_id) {
      return "";
    }
    return this.get_grade(grade_id).grade;
  };

  link_committeeassignment = ca => {
    return [
      this.link_committee(ca.committee),
      ca.chair
        ? [
            "\u00a0",
            <Tag key={"chair-" + ca.id} color="orange">
              Chair
            </Tag>
          ]
        : ""
    ];
  };

  print_course = course_id => {
    const course = this.get_course(course_id);
    const subject = this.get_subject(course.subject);
    return subject.abbrv + " " + course.number;
  };

  print_section = section => {
    return (
      this.print_course(section.course) +
      " section " +
      section.number +
      " (CRN " +
      section.crn +
      ")"
    );
  };

  section_comparator = (a, b) => this.course_comparator(a.course, b.course) == 0 ? a.number - b.number : this.course_comparator(a.course, b.course);

  print_subject = subject_id => {
    const subject = this.get_subject(subject_id);
    return subject.name;
  };

  print_subject_from_course = course_id => {
    const course = this.get_course(course_id);
    const subject = this.get_subject(course.subject);
    return subject.abbrv;
  };

  print_number_from_course = course_id => {
    return this.get_course(course_id).number
  }

  print_subject_code = subject_id => {
    const subject = this.get_subject(subject_id);
    return subject.abbrv;
  };

  print_full_course = course_id => {
    return (
      <span key={"course-" + course_id}>
        {this.print_course(course_id)} {this.print_course_title(course_id)}
      </span>
    );
  };

  print_full_course_with_details = course => {
    return (
      <span key={"course-" + course.course}>
        {"CRN:" + course.crn + " "}
        {this.print_course(course.course) + "-" + course.number}{" "}
        {this.print_course_title(course.course)}
      </span>
    );
  };

  print_full_course_with_details_string = (course) => {
    return ("CRN:" + course.crn + " " + this.print_course(course.course) + " " + this.get_course(course.course).title);
  }

  print_course_description = course_id => {
    return this.get_course(course_id).description;
  };

  print_course_description_short = course_id => {
    return text_max(this.print_course_description(course_id), 200);
  };

  print_course_title = course_id => {
    const course = this.get_course(course_id);
    return <i>{course.title}</i>;
  };
  
  print_course_schedule = schedule => {
    const SEMESTER_COLORS = {
      F: "volcano",
      S: "green",
      U1: "geekblue",
      U: "gold",
      U2: "magenta",
    };

    if (!schedule) { return null; }
    
    var result = schedule.semesters.map(s => (
      <Tooltip title={this.get_semestertype(s).name} key={s}>
        <Tag color={SEMESTER_COLORS[this.get_semestertype(s).abbrv]}>{this.get_semestertype(s).abbrv}</Tag>
      </Tooltip>
    ));
    
    if (schedule.years != "Every Year") {
      result = result.concat([<br />, "(", schedule.years, ")"]);
    }
    
    return result;
  };

  link_course = course_id => {
    if (this.permission("can", "faculty") | this.permission("can", "staff")) {
      const course = this.get_course(course_id);
  
      const content = (
        <div>
          <p>{ course.description }</p>
          { course.hours } semester hours, { course.loadcount } load count
        </div>
      );
  
      return (
        <Popover content={ content } title={ this.print_full_course(course_id) }>
          <Link
            key={course_id}
            to={this.getLink("/teaching/courses/" + course_id + "/")}
          >
            {this.print_course(course_id)}
          </Link>
        </Popover>
      );
    } else {
      return this.print_course(course_id);
    }
  };
  
  link_section = (section_id, crn) => {
    return this.permission("can", "staff") ? ( <Link to={ this.getLink("/teaching/schedules/section/" + section_id) }>{ crn }</Link> ) : crn;
  }

  link_ta = (ta_id, name) => {
    return (
      <Link key={ta_id} to={this.getLink("/staff/ta/view/" + ta_id + "/")}>
        {name}
      </Link>
    );
  };

  link_full_course = course_id => {
    return (
      <Link
        key={course_id}
        to={this.getLink("/teaching/courses/" + course_id + "/")}
      >
        {this.print_full_course(course_id)}
      </Link>
    );
  };

  print_nupath = nupath_id => {
    const NUPATH_COLORS = {
      AD: "volcano",
      FQ: "green",
      ND: "geekblue",
      WI: "gold",
      CE: "magenta",
      ER: "purple"
    };
    const nupath = this.get_nupath(nupath_id);
    return (
      <Tooltip title={nupath.name} key={nupath.code}>
        <Tag color={NUPATH_COLORS[nupath.code]}>{nupath.code}</Tag>
      </Tooltip>
    );
  };

  print_nucore = nucore_id => {
    const NUCORE_COLORS = {
      T1: "orange",
      M1: "blue",
      M2: "red",
      W2: "cyan",
      C1: "lime"
    };
    const nucore = this.get_nucore(nucore_id);
    return (
      <Tooltip title={nucore.name} key={nucore.code}>
        <Tag color={NUCORE_COLORS[nucore.code]}>{nucore.code}</Tag>
      </Tooltip>
    );
  };

  print_area = area => {
    const AREA_COLORS = {
      "AI/DS": "orange",
      HCI: "blue",
      SW: "red",
      SYS: "cyan",
      THY: "lime"
    };
    return (
      <Tooltip title={area.name} key={area.code}>
        <Tag color={AREA_COLORS[area.code]}>{area.code}</Tag>
      </Tooltip>
    );
  };

  print_campus = campus_id => {
    if (!campus_id) {
      return "";
    }
    const campus = this.get_campus(campus_id);
    return campus.name;
  };

  print_college = college_id => {
    if (!college_id) {
      return "";
    }
    const college = this.get_college(college_id);
    return college.name;
  };
  
  print_college_tag = (college_id, frac) => {
    if (!college_id) {
      return "";
    }

    const COLLEGE_COLORS = {
      "CS": "volcano",
      "EN": "green",
      "AD": "geekblue",
      "SH": "gold",
      "LW": "magenta",
      "SC": "cyan",
      "BU": "lime",
      "BV": "blue",
    };
    
    const college = this.get_college(college_id);
    return <Tag color={ COLLEGE_COLORS[college.code] }>{ college.abbrv } { frac ? " (" + (frac*100) + "%)" : "" }</Tag>;
  }

  print_method = method_id => {
    if (!method_id) {
      return "";
    }
    const method = this.get_method(method_id);
    return method.name;
  };

  print_full_room = room_id => {
    if (!room_id) {
      return "";
    }
    const room = this.get_room(room_id);
    return room.building.name + " " + room.number;
  };

  print_room = room_id => {
    if (!room_id) {
      return "";
    }
    const room = this.get_room(room_id);
    return room.building.abbrv + " " + room.number;
  };

  link_room = room_id => {
    if (!room_id) {
      return "";
    }
    return (
      <Link key={room_id} to={this.getLink("/teaching/rooms/" + room_id + "/")}>
        {this.print_room(room_id)}
      </Link>
    );
  };

  link_full_room = room_id => {
    if (!room_id) {
      return "";
    }
    return (
      <Link key={room_id} to={this.getLink("/teaching/rooms/" + room_id + "/")}>
        {this.print_full_room(room_id)}
      </Link>
    );
  };

  print_nuflex_type = room_id => {
    if (!room_id) {
      return "";
    }
    const room = this.get_room(room_id);
    if (!room.nuflex_type) {
      return "";
    }
    return <a target="_blank" href="https://nuflex.northeastern.edu/classroom-technology/">{ room.nuflex_type }</a>;
  }

  print_person_first = person => {
    return (person.firstname_preferred ? person.firstname_preferred : person.firstname);
  };

  print_employee = employee_id => {
    if (!employee_id) {
      return "";
    }

    const employee = this.get_employee(employee_id);
    return employee.lastname + ", " + this.print_person_first(employee)[0] + ".";
  };

  print_full_employee = employee_id => {
    if (!employee_id) {
      return "";
    }

    const employee = this.get_employee(employee_id);
    return this.print_person_first(employee) + " " + employee.lastname;
  };

  print_instructor = instructor_id => {
    if (!instructor_id) {
      return "";
    }

    var instructor = instructor_id;
    if (typeof instructor_id === 'number') {
      instructor = this.get_instructor(instructor_id);
    }
    return this.print_employee(instructor.employee);
  };

  print_instructor_list = instructors => {
    return oxford(instructors.map(el => this.print_instructor(el)));
  };

  print_full_instructor_list = instructors => {
    return oxford(instructors.map(el => this.print_full_instructor(el)));
  };

  print_full_student = student => {
    return this.print_person_first(student) + " " + student.lastname;
  };

  print_full_student_reverse = student => {
    return student.lastname + ", " + this.print_person_first(student);
  };

  print_full_instructor = instructor_id => {
    if (!instructor_id) {
      return "";
    }

    var instructor = instructor_id;
    if (typeof instructor_id === 'number') {
      instructor = this.get_instructor(instructor_id);
    }
    return this.print_full_employee(instructor.employee)
  };

  link_employee_instructor = employee_id => {
    const employee = this.get_employee(employee_id);
    return employee.faculty ? this.link_instructor(employee.faculty) : this.print_employee(employee_id);
  }

  link_instructor = instructor_id => {
    const { semesters } = this.props;

    if (typeof instructor_id === 'object') {
      return this.print_instructor(instructor_id);
    }

    const instructor = this.get_instructor(instructor_id);
    
    if (this.permission("can", "admin")) {
      const content = (
        <div>
          <p>{ this.print_instructorrank_and_appointment(this.get_from_history(instructor.ranks, this.get_semester(semesters[0]))) }<br/>
             { instructor.campus ? this.print_campus(instructor.campus) : <>Campus not known</> }</p>
          { instructor.email ? <a href={ "mailto:" + instructor.email }>{ instructor.email }</a> : <i>Email not known</i>  }
        </div>
      );
      
      return (
        <Popover content={content} title={ this.print_full_instructor(instructor_id) }>
          <Link
            key={instructor_id}
            to={this.getLink("/faculty/instructors/" + instructor_id + "/")}
          >
            {this.print_instructor(instructor_id)}
          </Link>
        </Popover>
      );
    } else {
      return this.print_instructor(instructor_id);
    }
  };

  link_full_instructor = instructor_id => {
    const { semesters } = this.props;

    if (typeof instructor_id === 'object') {
      return this.print_full_instructor(instructor_id);
    }

    const instructor = this.get_instructor(instructor_id);

    if (this.permission("can", "admin")) {
      const content = (
        <div>
          <p>{ this.print_instructorrank_and_appointment(this.get_from_history(instructor.ranks, this.get_semester(semesters[0]))) }<br/>
             { instructor.campus ? this.print_campus(instructor.campus) : <i>Campus not known</i> }</p>
          { instructor.email ? <a href={ "mailto:" + instructor.email }>{ instructor.email }</a> : <i>Email not known</i>  }
        </div>
      );

      return (
        <Popover content={content} title={ this.print_full_instructor(instructor_id) }>
          <Link
            key={instructor_id}
            to={this.getLink("/faculty/instructors/" + instructor_id + "/")}
          >
            {this.print_full_instructor(instructor_id)}
          </Link>
        </Popover>
      );
    } else {
    return this.print_full_instructor(instructor_id);
    }
  };

  print_full_instructorsubtype = subtype_id => {
    if (!subtype_id) {
      return "";
    }
    const subtype = this.get_instructorsubtype(subtype_id);
    return subtype.subtype + " (" + this.get_instructortype(subtype.mytype_id).mytype + ")";
  };
  
  print_instructorrank = rank_id => {
    if (!rank_id) {
      return "";
    }
    const rank = this.get_instructorrank(rank_id);
    return rank.rank;
  };

  print_full_instructorrank = rank_id => {
    if (!rank_id) {
      return "";
    }
    const rank = this.get_instructorrank(rank_id);
    return rank.rank + " (" + rank.subtype.subtype + ", " + rank.subtype.mytype.mytype + ")";
  };
  
  print_joint_appoinments = (rankhistory, show_tenurehome, notags) => {
    const total = rankhistory.appointment.map(ap => ap.fraction).reduce((r,a) => r+a, 0);
    const colleges = rankhistory.appointment.filter(ap => this.get_college(ap.college).code != "CS");
    var result = add_brs(colleges.map(ap => notags ? [this.get_college(ap.college).abbrv, " (", (ap.fraction/total)*100, "%)"] : this.print_college_tag(ap.college, ap.fraction/total)));
    
    if (show_tenurehome && rankhistory.tenure_home && (this.get_college(rankhistory.tenure_home).code != "CS")) {
      result = result.concat([", tenure home ", this.get_college(rankhistory.tenure_home).abbrv]);
    }

    return result;
  }
  
  get_next_sabbatical = (instructor_id) => {
    const instructor = this.get_instructor(instructor_id);
    const tt_starts = instructor.ranks.filter(r => this.is_tenure_track(r.rank));
    
    if (tt_starts?.length > 0) {
      const tt_start = tt_starts[0];
      const next_fall_year = this.semester_list().reverse().find(s => this.get_semestertype(s.semestertype).name == "Fall" && moment(s.startdate, "YYYY-MM-DD") > moment()).year - 1;

      const last = instructor.sabbaticals.length == 0 ? 
                      this.get_semestertype(this.get_semester(tt_start.start).semestertype).name == "Fall" ? this.get_semester(tt_start.start).year-1 : this.get_semester(tt_start.start).year :
                      this.get_semester(instructor.sabbaticals[instructor.sabbaticals.length-1].end).year;
      
      const last_sabbatical = instructor.sabbaticals?.length > 0 ? instructor.sabbaticals[instructor.sabbaticals.length-1] : null;
      const deferrals_last_sabbatical = instructor.sabbaticaldeferrals.filter(d => last_sabbatical == null || d.year > this.get_semester(last_sabbatical.start).year).length;
      const second_last_sabbatical = instructor.sabbaticals.length > 1 ? instructor.sabbaticals[instructor.sabbaticals.length-2] : null;
      const deferrals_second_last_sabbatical = instructor.sabbaticaldeferrals.filter(d => last_sabbatical != null && d.year <= this.get_semester(last_sabbatical.start).year && (second_last_sabbatical == null || d.year > this.get_semester(second_last_sabbatical.start).year)).length;
      const leaves_last_sabbatical = instructor.leaves.filter(l => l.sabbatical_clock_extension > 0 && (last_sabbatical == null || this.get_semester(l.start).year > this.get_semester(last_sabbatical.start).year)).map(l => l.sabbatical_clock_extension).reduce((r,a) => r+a, 0);

      const last_sabbatical_or_tt_start = last_sabbatical == null ? tt_start.start : last_sabbatical.end;
      const part_time_last_sabbatical = new Set(instructor.ranks.filter(r => (r.appointment.reduce((r,a) => r+a.fraction, 0) < 35.0/40.0) && (this.get_semester(r.start).code >= this.get_semester(last_sabbatical_or_tt_start).code)).map(r => [...Array((r.end ? this.get_semester(r.end).year : next_fall_year) + 1 - this.get_semester(r.start).year).keys()].map(y => y + this.get_semester(r.start).year)).flat()).size;
      
      const eligible = (last + 6 - deferrals_second_last_sabbatical + leaves_last_sabbatical + part_time_last_sabbatical);
            
      return "Fall " + Math.max(eligible, next_fall_year);
    } else {
      return null;
    }
  }
  
  get_third_year_review = (instructor_id) => {
    const instructor = this.get_instructor(instructor_id);
    const tt_starts = instructor.ranks.filter(r => this.is_tenure_track(r.rank));
    
    if (tt_starts?.length > 0) {
      const tt_start = tt_starts[0];
      const real_start = this.get_semestertype(this.get_semester(tt_start.start).semestertype).name == "Fall" ? this.get_semester(tt_start.start).year-1 : this.get_semester(tt_start.start).year;
      
      const third_year = real_start + 3;
      const leaves = instructor.leaves.filter(l => l.tenure_clock_extension > 0 && this.get_semester(l.start).year <= third_year).map(l => l.tenure_clock_extension).reduce((r,a) => r+a, 0);
      const parental_reliefs = instructor.parentalreliefs.filter(l => this.get_semester(l.semester).year <= third_year).length;
      
      return third_year + leaves + parental_reliefs;
    } else {
      return null;
    }
  }
  
  get_tenure_review = (instructor_id) => {
    const instructor = this.get_instructor(instructor_id);
    const tt_starts = instructor.ranks.filter(r => this.is_tenure_track(r.rank));
    const extensions = instructor.tenureclockextensions.reduce((a, r) => r.extension + a, 0);
    
    if (tt_starts?.length > 0) {
      const tt_start = tt_starts[0];
      const real_start = this.get_semestertype(this.get_semester(tt_start.start).semestertype).name == "Fall" ? this.get_semester(tt_start.start).year-1 : this.get_semester(tt_start.start).year;
      const leaves = instructor.leaves.filter(l => l.tenure_clock_extension > 0).map(l => l.tenure_clock_extension).reduce((r,a) => r+a, 0);
      const parental_reliefs = instructor.parentalreliefs.filter(p => p.tenure_extension == true).length;
      
      return real_start + 6 + leaves + parental_reliefs + extensions;
    } else {
      return null;
    }
  }

  is_tenure_track = (rank_id) => this.get_instructorrank(rank_id).subtype.mytype.mytype == "Tenured/Tenure-Track";
  is_tenure_track_untenured = (rank_id) => this.get_instructorrank(rank_id).subtype.subtype == "Tenure-Track";
  is_full_time_non_tenure_track = (rank_id) => this.get_instructorrank(rank_id).subtype.mytype.mytype == "Full-Time Non-Tenure-Track";
  is_part_time = (rank_id) => this.get_instructorrank(rank_id).mytype.mytype == "Part-time";
  is_staff = (rank_id) => this.get_instructorrank(rank_id).subtype.mytype.mytype == "Staff";

  print_instructorrank_and_appointment = (rankhistory) => {
    if (! rankhistory) { return "Unknown rank" }

    var result = [this.print_instructorrank(rankhistory.rank)];
    const khoury = rankhistory.appointment.filter(ap => this.get_college(ap.college).code == "CS");
    const total = rankhistory.appointment.map(ap => ap.fraction).reduce((r,a) => r+a, 0);
    
    const colleges = this.print_joint_appoinments(rankhistory, true, true);
    if (total < 0.99) {
      result = result.concat([" (", total*100, "%)"])
    }
    
    if (khoury.length == 1) {
      if (colleges?.length > 0) {
        result = result.concat([", jointly appointed with ", colleges])
      }
    } else {
      result = result.concat([", ", colleges])
    }
    
    return result;
  }

  print_instructortype = type_id => {
    if (!type_id) {
      return "";
    }
    const type = this.get_instructortype(type_id);
    return type.mytype;
  };

  print_meetingtime = meetingtime_id => {
    if (!meetingtime_id) {
      return "";
    }
    const meetingtime = this.get_meetingtime(meetingtime_id);
    //    return ( meetingtime.popular ? "(" + meetingtime.sequence + ") " : "") + meetingtime.name;
    return meetingtime.name;
  };

  print_ta_preference = preferece => {
    return preferece == 1 ? preferece + "st" : preferece == 2 ? preferece + "nd" : preferece == 3 ? preferece + "rd" : preferece + "th";
  };
}

export default AppComponent;
