import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";

import { Icon, Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const UGPrerequisiteOverrideForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      selected_course: null,
      selected_semester: null,
      selected_section: null,
      major: null,
      gpa: null,
      rationale: null,
    }

    componentDidMount() {
      this.reset();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.semester !== this.props.semester) {
        this.reset();
      }
    }

    reset = () => {
      this.setState({ selected_course: null, selected_semester: null, selected_section: null, major: null, gpa: null });
      this.props.form.resetFields();
    }


    handleSubmit = () => {
      const { major, gpa } = this.state;
      const form = this.props.form;
      form.validateFields((err, values) => {
        if (err) { return; }
        let any_section = false;
        let section = values["Section"];
        if (typeof section === "string") {
          section = parseInt(section.substring(1));
          any_section = true;
        }

        const data = { section: section, rationale: values["Rationale"], any_section: any_section, gpa: gpa, major: major };
        this.props.onSubmit(data, this.reset);
      });
    };

    render() {
      const { form, sections, semesters } = this.props;
      const { getFieldDecorator } = form;
      const { selected_course, selected_semester, selected_section, major, gpa } = this.state;

      var semester_list = this.semester_list().filter(el => el.code >= this.get_semester(semesters[0]).code && (el.code.slice(-2) == "10" || el.code.slice(-2) == "30" || el.code.slice(-2) == "60"));

      semester_list.reverse();

      const course_semester_list = sections.map(el => {
        return { course: el.course, semester: el.semester, key: el.course + "-" + el.semester };
      });

      // To get only ugrad courses.
      const distinct_course_semester_list = course_semester_list.filter((obj, pos, arr) => course_semester_list.map(el => el.key).indexOf(obj.key) == pos && this.get_course(obj.course).number < 5000);

      const section_list = sections.filter(el => el.course == selected_course && el.semester == selected_semester);

      var major_list = this.major_list();

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

      return (
        <>
          <p>If you wish to amend a previously-submitted petition, please simply submit a new petition. The Khoury Undergrad team will simply use the most recent one that you submit.</p>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="GPA" extra="Please enter your current GPA.">
              {getFieldDecorator('gpa', {
                rules: [{ required: true, message: 'Please enter your GPA.' }],
                initialValue: gpa,
                onChange: (event) => this.setState({ gpa: event }),
              })(<InputNumber min={0} max={4} step={0.01} />)}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Course"
              extra="Please select the course you would like to add to your petition."
            >
              {getFieldDecorator("Courses", {
                rules: [{ required: true, message: "Please select a course." }],
                initialValue: "",
                onChange: event => {
                  this.setState({
                    selected_course: distinct_course_semester_list[event].course,
                    selected_semester:
                      distinct_course_semester_list[event].semester
                  }),
                    form.resetFields();
                }
              })(
                <Select
                  style={{ width: 360 }}
                  filterOption={this.filter}
                >
                  {this.props.semesters.map(sem =>
                    <OptGroup key={sem} label={this.print_semester(sem)}>
                      {distinct_course_semester_list.map((el, idx) => (
                        el.semester == sem && (
                          <Option key={idx} value={idx}>
                            {this.print_full_course(el.course)}{" "}
                          </Option>
                        )))}
                    </OptGroup>)}
                </Select>
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Section"
              extra="Please select the section you would like to add to your petition."
            >
              {getFieldDecorator("Section", {
                rules: [{ required: true, message: "Please select a section." }],
                initialValue: "",
                onChange: event =>
                  this.setState({ selected_section: event, edited: true })
              })(
                <Select
                  style={{ width: 360 }}
                  filterOption={this.filter}
                  disabled={
                    !selected_course ||
                    !selected_semester ||
                    section_list.length == 0
                  }
                >
                  {section_list && section_list?.length > 0 ? (
                    <Option
                      key={"A" + section_list[0].id.toString()}
                      value={"A" + section_list[0].id.toString()}
                    >
                      <span>Any section</span>
                    </Option>
                  ) : null}
                  {section_list
                    ? section_list.map(el => (
                      <Option key={el.id} value={el.id}>
                        <span>
                          {" "}
                          CRN: {el.crn}, Section: {el.number}, Time:{" "}
                          {this.print_meetingtime(el.meetingtime)}
                        </span>
                      </Option>
                    ))
                    : course_list.map(el => (
                      <Option key={el.crn} value={el.crn}>
                        CRN: {el.crn}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Rationale"
              extra="What coursework and experience will lead to your success in this course without the prequisite(s)?"
            >
              {getFieldDecorator("Rationale", {
                rules: [{ required: true, message: "Please input a rationale." }],
                initialValue: "",
                onChange: event =>
                  this.setState({ rationale: event.target.value })
              })(<TextArea style={{ width: 360 }} rows={4} />)}
            </FormItem>
            <FormItem {...this.formItemLayout} label=" " colon={false}><Button type="primary" onClick={this.handleSubmit}>Submit</Button></FormItem>
          </Form>
        </>
      );
    }
  }
);


class UGPrerequisiteOverride extends AppComponent {
  render() {
    const { record } = this.props;
    const item = record.record;

    return (
      <Descriptions bordered title={"Prerequisite Override request by " + record.created_by}>
        <Descriptions.Item label="Submitted" span={2}>{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Requestor" span={2}>{[record.created_by + " ", <a key="email" href={"mailto:" + item.email}><Icon type="mail" theme="twoTone" /></a>]}</Descriptions.Item>
        <Descriptions.Item label="NUID" span={2}>{item.student.nuid}</Descriptions.Item>
        {this.permission("can", "staff") &&
          <Descriptions.Item label="" span={2}>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item>}
        <Descriptions.Item label="Semester" span={2}>{this.print_semester(item.semester)}</Descriptions.Item>
        <Descriptions.Item label="Course" span={4}>{this.print_full_course(item.course)}</Descriptions.Item>
        <Descriptions.Item label="CRN" span={4}>{item.any_section ? "Any" : item.crn}</Descriptions.Item>
        <Descriptions.Item label="Rationale" span={4}>{item.rationale}</Descriptions.Item>
      </Descriptions>
    );
  }
}

class UGPrerequisiteOverrideTable extends WorkflowTable {
  get_columns = () => {
    return [
      {
        title: "CRN",
        key: "crn",
        width: 80,
        render: (text, record, idx) => record.record.any_section ? "Any" : record.record.crn,
      },
      {
        title: "Course",
        key: "course",
        width: 350,
        render: (text, record, idx) => this.print_full_course(record.record.course),
      },
      {
        title: "Semester",
        key: "semester",
        width: 120,
        render: (text, record, idx) => this.print_semester(record.record.semester),

      }
    ];
  }
}

class UGPrerequisiteOverrideOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ug/prerequisiteoverride/",
    visible: true,

    endpoint_section: "/api/schedule/",
    sections: [],

    endpoint_viewable: "/api/petitionviewable/",
    student_visible: false,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint + "?section__semester=" + this.props.semesters.join(","), data => { this.setState({ data: data, loading: false }) }));
    this.doGet(this.state.endpoint_section + "?semester=" + this.props.semesters.join(",") + "&campus=" + this.props.campus + ",118", data => this.setState({ sections: data, loading: false }));
    this.doGet(this.state.endpoint_viewable + "?semester=" + this.props.semesters.join(","), data => { this.setState({ student_visible: ((data[0] || {}).petitions_visible_pr || false) }); });
  }

  get_name = () => {
    return "UG Prerequisite Override for " + this.props.semester;
  }

  get_workflowtype = () => {
    return "ugprerequisiteoverride";
  }

  get_name_plural = () => {
    return "UG Prerequisite Overrides for " + this.props.semester;
  }

  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "UG" }, { text: "Prerequisite Override" }];
  }

  get_form = (func) => {
    const { sections, student_visible } = this.state;
    if (student_visible) {
      return <UGPrerequisiteOverrideForm {...this.props} onSubmit={this.submit_form} sections={sections} />;
    } else {
      return <Alert message="Application Period is Closed" description={"No Prerequisite Override Petitions can be currently submitted"} type="warning" showIcon />
    }
  }

  get_record_view = (record) => {
    return <UGPrerequisiteOverride {...this.props} record={record} />
  }

  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }

  get_overview_text = () => {
    return (
      <p>
        Welcome to the new prerequisite override petition form. <strong>Note: We cannot guarantee access to any of these classes; </strong> This survey is only used as a petition to request access to a course where you lack the prerequisites to register yourself. All of your entries will not be saved until you click  submit; if an error occurs, a popup will be shown.
      </p>
    );
  }
}

export { UGPrerequisiteOverrideOverview, UGPrerequisiteOverride, UGPrerequisiteOverrideTable };