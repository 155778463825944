import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import moment from "moment-timezone";
import { renderStatus, text_max, oxford, add_brs } from "../../Utils";
import { WorkflowSubmit } from "../WorkflowSubmit";
import { WorkflowTable } from "../WorkflowTable";
import NumberFormat from 'react-number-format';

import { Popover, Table, Form, Input, Divider, Upload, Icon, Select, Button, InputNumber, message, Timeline, Radio, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;



const EquipmentRefreshForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      uploadedList: []
    }

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({uploadedList: []});
      this.props.form.resetFields();
    }

    handleSubmit = () => {
      const { uploadedList } = this.state;
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit({...(values),...{files: uploadedList}}, this.reset());
        } else {
          message.error(err);
        }
      });
    };

    //functions for file upload
    getFile = (type) => {
      const { uploadedList } = this.state;
      return(
        <React.Fragment>
          {(uploadedList.filter(el => el[1]["type"] == type).length != 0) && (
            <div style={{marginTop:"10px"}}>
              <p>{ type } <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={ () => this.onRemove(type)} /></p>
            </div>
          )}
        </React.Fragment>
      )};


    setFile = (file, type) => {
      this.setState(state => ({
        uploadedList: [...state.uploadedList, [window.btoa(file), type]],
      }));
    };

    beforeUpload = (file, type) => {
      const reader = new FileReader();
      const setFile = this.setFile;
      reader.onload = function(event) {
        setFile(event.target.result, type);
      };
      reader.readAsBinaryString(file);
  };

    onRemove = type => {
      this.setState(state => {
        const matches = (el) => el[1]["type"] == type;
        const index = state.uploadedList.findIndex(matches);
        const newuploadedList = state.uploadedList.slice();
        newuploadedList.splice(index, 1);
        return {
          uploadedList: newuploadedList,
        };
      });
    };

    disableUpload = (type) => {
      const { uploadedList } = this.state;
      return uploadedList.filter(el => el[1]["type"] == type).length != 0;
    }


    render() {
      const { visible, onCancel, onCreate, form, item, sections, campus, semester, user } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      const { error, errors, fundings, funds, others } = this.state;

      return (
        <>
          <p>Please use the form below to submit a request for equipment refresh funding.</p>
          <p>
            Teaching Faculty members are considered eligible after 4 years of full-time service, or having completed 4 years of full-time service since the last approved refresh request.
          </p>
          <Form>
            <FormItem {...this.formItemLayout} label="Eligible" extra={"Please indicate if you match the eligibility criteria detailed above."}>
              {getFieldDecorator('self_approval', { rules: [{ required: true, message: 'Please select an option.' }] })
                (
                  <RadioGroup>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                  </RadioGroup>
                )}
            </FormItem>
            <FormItem {...this.formItemLayout} label="Amount" extra="Amount requested (up to $2,000)">
              {getFieldDecorator('amount_requested', { rules: [{ required: true, message: 'Please enter an amount.' }], initialValue: null})
              (
                <InputNumber min={0} max={2000} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
              )}
            </FormItem>
            <FormItem {...this.formItemLayout} label="Notes" extra="You can optionally include general notes or provide additional context here.">
              {getFieldDecorator('notes', { initialValue: ""})
              (
                <TextArea rows={2} />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Equipment Details" extra={"Please upload a PDF with a screenshot of an actual quote or the price of the item(s) in your cart. Note that while support from this fund has a maximum limit of $2,000, the cost of the equipment can exceed that amount."} >
                  {getFieldDecorator("equipment_refresh_upload", { rules: [{ required: true }] })(
                    <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Equipment Refresh" })} accept=".pdf" showUploadList={false}>
                      <Button disabled={this.disableUpload("Equipment Refresh")}><Icon type="upload" />Upload</Button>
                      {this.getFile("Equipment Refresh")}
                    </Upload>
                  )}
            </FormItem>
            <FormItem {...this.formItemLayout} label=" " colon={false}><Button type="primary" onClick={this.handleSubmit}>Submit</Button></FormItem>
          </Form>
        </>
      );
    }
  }
);

class EquipmentRefresh extends AppComponent {

  render() {
    const { record } = this.props;
    const item = record.record;
    const { semesters } = this.props;
    const instructor = this.get_instructor(item.instructor);

    return (
      <Descriptions bordered title={"Previous equipment refresh requests"}>
        <Descriptions.Item label="Requestor">{this.link_full_instructor(item.instructor)}</Descriptions.Item>
        <Descriptions.Item label="Employment History" span={24}>
          <Timeline className="instructor-timeline">
            {instructor.ranks.map(r =>
              <Timeline.Item color="green">
                <b>{this.print_semester(r.start)} - {r.end ? this.print_semester(r.end) + ": " : "Present: "}</b>
                {this.print_instructorrank_and_appointment(r)}
              </Timeline.Item>
            )}
          </Timeline>
        </Descriptions.Item>
        <Descriptions.Item label="Submitted">{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Meets Criteria (Self-Reported)"span={ 2 }>{item.self_approval ? "Yes" : "No"}</Descriptions.Item>
        <Descriptions.Item label="Latest approved submission date" >{item.last_approved_submission_date ? moment(item.last_approved_submission_date).format("MMM DD, YYYY") : "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Amount Requested" span={ 2 }>{<NumberFormat value={ item.amount_requested } prefix="$" thousandSeparator=","  displayType='text' decimalScale={ 0 } />}</Descriptions.Item>
        <Descriptions.Item label="Notes" span={ 4 }>{item.notes}</Descriptions.Item>
        <Descriptions.Item label="Attached Files" span={ 4 }>{[record.files.map(el => <a onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type +".pdf"}</a>) ]}</Descriptions.Item>
      </Descriptions>
    );
  }
}

class EquipmentRefreshTable extends WorkflowTable {
  get_columns = () => {
    return [{
        title: "Meets Criteria (Self-Reported)",
        width: 150,
        render: (text, record, idx) => record.record.self_approval ? "Yes" : "No"
      },{
        title: "Amount",
        width: 100,
        render: (text, record, idx) => <NumberFormat value={ record.record.amount_requested } prefix="$" thousandSeparator=","  displayType='text' decimalScale={ 0 } />,
      }];
  }
}

class EquipmentRefreshOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/faculty/equipmentrefresh/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "Equipment Refresh Requests" ;
  }

  get_workflowtype = () => {
    return "equipmentrefresh";
  }

  get_name_plural = () => {
    return "Equipment Refresh Requests";
  }

  get_breadcrumbs = () => {
    return [{ link: "/faculty", text: "Faculty" }, { text: "Equipment Refresh" }];
  }

  get_form = (func) => {
    return <EquipmentRefreshForm {...this.props} onSubmit={this.submit_form} />;
  }

  get_record_view = (record) => {
    return <EquipmentRefresh {...this.props} record={record} />
  }

  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func(); }, JSON.stringify(data));
  }

  get_overview_text = () => {
    return (
      <div>
        <p>Please refer to the table below for a list of your previous equipment refresh support submissions. After you've submitted a proposal, it will be directed to the Associate Dean of Teaching Faculty. They will then review the submission to ensure that all necessary conditions are met in order to qualify for support.</p>
        <p>Only purchase equipment <b>after</b> approval. Following approval and subsequent purchase, you may upload your receipt to Concur to initiate the reimbursement process.</p>
      </div>
    );
  }
}

export { EquipmentRefreshOverview, EquipmentRefresh, EquipmentRefreshTable };