import React, { Component } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './../AppComponent';
import StatusComponent from './../StatusComponent';
import { Status } from './../StatusComponent';
import Preference from './../Preference';
import TAApplicationTable from './TAApplicationTable';
import TADataExport from './TADataExport';
import TAHoursTable from './TAHoursTable';
import StudentCreateForm from './../Student/StudentForms';
import Content from './../Content';
import CustomTabs from './../CustomTabs';
import readXlsxFile from 'read-excel-file';
import { add_brs, add_dividers, add_dividers_horiz, format_percent, format_decimal, is_assigned, getTAStatus, get_check_nocheck, renderAuditStatus, copy, getSemesterWeeks } from './../Utils';

import { Tag, Steps, Upload, Popover, Popconfirm, Button, PageHeader, Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, InputNumber, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, Tabs, message, Modal, Timeline } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const { Step } = Steps;

class TAHoursStaffCard extends AppComponent {
  state = {
    endpoint_taapplication: "/api/ta/application/",
    taapplications: [],
    loading_taapplications: true,

    endpoint_hours: "/api/ta/hours/",
    hours: [],
    loading_hours: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.setState({ loading_taapplications: true, loading_hours: true }, this.getData);
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taapplication + "?semester=" + this.props.single_semester + "&state=Hired&campus=" + this.props.campus,
      data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_hours + "?taapplication__semester=" + this.props.single_semester + "&taapplication__campus=" + this.props.campus,
      data => this.setState({ hours: data, loading_hours: false }));
  }

  render() {
    const { loading_taapplications, taapplications, loading_hours, hours } = this.state;
    const apps = taapplications.filter(el => el.semester == this.props.single_semester && el.ta.level != "PH");

    const weeks = getSemesterWeeks(this.get_semester(this.props.single_semester));
    const columns = [
      {
        title: 'Week',
        key: 'week',
        render: (text, record, idx) => weeks[record.week][0].format("M/DD") + "-" + weeks[record.week][weeks[record.week].length - 1].format("M/DD"),
      }, {
        title: 'None',
        key: 'none',
        align: 'right',
        render: (text, record, idx) => <NumberFormat fixedDecimalScale={true} decimalScale={1} displayType={'text'} value={record.none} suffix="%" />,
      }, {
        title: 'Submitted',
        key: 'submitted',
        align: 'right',
        render: (text, record, idx) => <NumberFormat fixedDecimalScale={true} decimalScale={1} displayType={'text'} value={record.submitted} suffix="%" />,
      }, {
        title: 'Confirmed',
        key: 'confirmed',
        align: 'right',
        render: (text, record, idx) => <NumberFormat fixedDecimalScale={true} decimalScale={1} displayType={'text'} value={record.confirmed} suffix="%" />,
      }
    ]

    var weeknums = Object.keys(weeks).filter(w => w <= moment().format("ww"));
    weeknums.sort();
    weeknums = weeknums.map(el => {
      const myhours = hours.filter(h => weeks[el].find(d => d.format("YYYY-MM-DD") == h.date));

      const myapps = myhours.map(h => h.taapplication);
      const submitted_apps = myhours.filter(h => h.confirmed_at == null).map(h => h.taapplication);
      const confirmed_apps = myhours.filter(h => h.confirmed_at != null).map(h => h.taapplication);

      const none = apps.filter(a => !myapps.includes(a.id)).length * 100.0 / apps.length;
      const submitted = apps.filter(a => submitted_apps.includes(a.id)).length * 100.0 / apps.length;
      const confirmed = apps.filter(a => confirmed_apps.includes(a.id)).length * 100.0 / apps.length;
      return { week: el, none: none, submitted: submitted, confirmed: confirmed };
    });

    return (<Card size="small" title={"TA hours submission for " + this.print_semester(this.props.single_semester)}>
      {loading_taapplications || loading_hours ? (<Spin tip="Loading data" />) : apps.length == 0 ? <i>No TAs found.</i> : <Table {...this.props} dataSource={weeknums} columns={columns} bordered={false} pagination={false} size="small" rowKey={(record, idx) => idx} />}
    </Card>);
  }
}

class TAIndividual extends AppComponent {
  state = {
    endpoint_tahours: "/api/ta/hours/",
    tahours: [],
    loading_tahours: true,

    endpoint_taapplication: "/api/ta/application/",
    taapplications: [],
    loading_taapplications: true,

    chart_level: "",
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taapplication + "?campus=" + this.props.campus + "&semester=" + this.props.semesters.join(",") + "&state=Hired",
      data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_tahours + "?taapplication__campus=" + this.props.campus + "&taapplication__semester=" + this.props.semesters.join(","),
      data => this.setState({ tahours: data, loading_tahours: false }));
  }

  total_hours = (semester, ap_id, tahours) => {
    const hours = tahours.filter(el => el.taapplication == ap_id);
    const value = hours.reduce((r, a) => r + a.hours, 0);
    return { "semester": this.print_semester(semester), "hours": value }
  }

  render() {
    const { semester, campus } = this.props;
    const ta_id = this.props.match.params.ta_id;
    const { taapplications, tahours, loading_taapplications, loading_tahours } = this.state;
    const applications = taapplications.filter(el => el.ta.nuid == ta_id);
    const hours_per_sem = applications.map(el => this.total_hours(el.semester, el.id, tahours));
    const ta_name = applications[0] ? applications[0].ta.firstname + " " + applications[0].ta.lastname : "";
    return (
      <Content {...this.props} title={ta_name + " TA Overview"} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "TA" }, { text: "Overview" }, { text: ta_name }]}>
        <Divider orientation="left">Overview</Divider>
        {loading_taapplications || loading_tahours ? <p>Loading</p> : <Chart height={400} data={hours_per_sem} forceFit>
          <Legend />
          <Axis name="semester" />
          <Axis name="hours" />
          <Tooltip />
          <Geom type="interval" position="semester*hours" color="hours" />
        </Chart>}
        <Divider orientation="left">{this.print_semester(semester)} Hours</Divider>
        <p>Table displaying all submitted hours from all TAs</p>
        <p>Key: Yellow 75%, Orange 90% and Red 100% or more of max hours</p>
        <TAHoursTable {...this.props} loading={loading_taapplications || loading_tahours} taapplications={applications} tahours={tahours} />
      </Content>
    );
  }
}

class TACollegeOverview extends AppComponent {
  state = {
    endpoint_tahours: "/api/ta/hours/",
    tahours: [],
    loading_tahours: true,

    endpoint_taapplication: "/api/ta/application/",
    taapplications: [],
    loading_taapplications: true,

    endpoint_schedule: "/api/schedule/",
    schedule: [],
    loading_schedule: true,

    endpoint_groups: "/api/schedule/group/",
    groups: [],
    loading_groups: true,

    filter: "",
    chart_level: "",
    chart_xaxis: "hours",
    chart_position: "course*hours",
    summer_semester: 0,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taapplication + "?semester=" + this.props.semesters.join(",") + "&state=Hired", data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_schedule + "?campus=" + this.props.campus + "&semester=" + this.props.semesters.join(","), data => this.setState({ schedule: data, loading_schedule: false }));
    this.doGet(this.state.endpoint_tahours + "?taapplication__semester=" + this.props.semesters.join(","), data => this.setState({ tahours: data, loading_tahours: false }));
    this.doGet(this.state.endpoint_groups + "?semester=" + this.props.semesters.join(","), data => this.setState({ groups: data, loading_groups: false }));
  }

  totals_for_semester = (taapplications, tahours) => {
    const { chart_level, schedule } = this.state;
    const myapps = taapplications.filter(el => chart_level ? el.ta.level == chart_level : true);

    const mycourses = [...new Set(myapps.map(el => el.course))];

    const mysections = Object.values(schedule.filter(el => mycourses.includes(el.course)).reduce((r, el) => {
      var course = el.course;
      var crosslist = el.crosslist && schedule.find(s => s.id == el.crosslist) ? schedule.find(s => s.id == el.crosslist).course : null;
      if (course && crosslist && this.course_comparator(this.get_course(course), this.get_course(crosslist)) > 0) {
        r[crosslist + "-" + course] = [crosslist, course];
      } else {
        r[course + "-" + crosslist] = [course, crosslist];
      }
      return r;
    }, {}));
    mysections.sort((a, b) => this.course_comparator(this.get_course(a[0]), this.get_course(b[0])));

    const result = mysections.map(el => this.totals_for_course(el[0], el[1], myapps, tahours)).filter(el => el.hours > 0);
    return result;
  }

  totals_for_course = (course, crosslist, taapplications, tahours) => {
    const { chart_level, filter, schedule } = this.state;
    const course_tas = taapplications.filter(el => (el.course == course || el.course == crosslist) && (chart_level ? el.ta.level == chart_level : true));
    const number_of_tas = course_tas ? course_tas.length : 1;
    const enrollment = schedule.filter(el => el.course == course || el.course == crosslist).reduce((r, a) => r + a.enrollment, 0);

    const denominator = (filter == "CT" ? number_of_tas : (filter == "CE" ? enrollment : 1));
    const course_cost = course_tas.map(el => this.cost_per_ta(tahours, el.id, el.pay_rate));
    const course_hours = tahours.filter(el => course_tas.map(el => el.id).includes(el.taapplication));
    return {
      "course": this.print_course(course) + (crosslist ? "/" + this.print_course(crosslist) : ""),
      "cost": course_cost.reduce((r, a) => r + a, 0) / denominator,
      "hours": denominator > 0 ? course_hours.reduce((r, a) => r + a.hours, 0) / denominator : 0
    };
  }

  cost_per_ta = (tahours, ta_id, pay_rate) => {
    const hours = tahours.filter(el => el.taapplication == ta_id);
    const value = hours.reduce((r, a) => r + a.hours, 0);
    return value * pay_rate;
  }

  handleSelect = prop => (value) => {
    this.setState({ [prop]: value });
  }

  AddGroupCampusToTAApps = (taapplications, groups) => {
    let filterApps = taapplications;

    if (taapplications.length == 0 || groups.length == 0) {
      return [];
    }

    filterApps.forEach((el) => {
      el["campus"] = el.campus ? el.campus : groups.find(g => g.id == el.group).primary_campus;
    });

    filterApps.filter(el => el.campus == this.props.campus);

    return filterApps;
  }

  render() {
    const { semester, campus, semesters } = this.props;
    const { chart_level, loading_schedule, loading_tahours, loading_taapplications, taapplications, tahours, chart_xaxis, chart_position, chart_loading, filter, summer_semester, groups } = this.state;

    let TAAppsWithGroups = this.AddGroupCampusToTAApps(taapplications, groups);

    const total_hours_per_course = this.totals_for_semester(TAAppsWithGroups.filter(el => el.semester == (semester.includes("Summer") ? semesters[summer_semester] : semesters[0])), tahours);

    return (
      <Content {...this.props} title={semester + " TA Overview"} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "TA" }, { text: "Overview" }]}>
        <Divider orientation="left">Overview</Divider>
        <Form layout="inline">
          {semester.includes("Summer") ?
            <FormItem label="Summer Semester" style={{ float: 'right' }}>
              <Select style={{ width: 200 }} value={summer_semester} onChange={this.handleSelect('summer_semester')}>
                <Option key={0} value={0}>Summer 1</Option>
                <Option key={1} value={1}>Summer Full</Option>
                <Option key={2} value={2}>Summer 2</Option>
              </Select>
            </FormItem> : null}
          <FormItem label="TA Level" style={{ float: 'right' }}>
            <Select style={{ width: 200 }} value={chart_level} onChange={this.handleSelect('chart_level')}>
              <Option key={""} value={""}>All</Option>
              <Option key={"UG"} value={"UG"}>UG</Option>
              <Option key={"MS"} value={"MS"}>MS</Option>
            </Select>
          </FormItem>
          <FormItem label="Normalize by" style={{ float: 'right' }}>
            <Select style={{ width: 200 }} value={filter} onChange={this.handleSelect('filter')}>
              <Option key={""} value={""}>None</Option>
              <Option key={"CT"} value={"CT"}>Number of TAs</Option>
              <Option key={"CE"} value={"CE"}>Student Enrollment</Option>
            </Select>
          </FormItem>
          <FormItem label="Show" style={{ float: 'right' }}>
            <Select style={{ width: 200 }} value={chart_position} onChange={this.handleSelect('chart_position')}>
              <Option key={"course*hours"} value={"course*hours"}>Hours Billed</Option>
              <Option key={"course*cost"} value={"course*cost"}>Dollars Paid</Option>
            </Select>
          </FormItem>
        </Form>
        <p>Graphical representation of hours submitted by all TAs per course.</p>
        {loading_taapplications || loading_tahours ? <div className="loading"><Spin /></div> : <Chart height={400} data={total_hours_per_course} scale={{ course: { alias: "" }, hours: { alias: "Hours" + (filter == "CT" ? " per TA" : filter == "CE" ? " per student" : "") }, cost: { alias: "$" + (filter == "CT" ? " per TA" : filter == "CE" ? " per student" : "") }, }} forceFit>
          <Legend />
          <Axis name="course" title="course" label={{ rotate: 30 }} />
          <Axis name="hours" title="hours" />
          <Axis name="cost" title="cost" />
          <Tooltip />
          <Geom type="interval" position={chart_position} color={chart_xaxis} />
        </Chart>}
        <Divider orientation="left">All Hours</Divider>
        <p>Table displaying all submitted hours from all TAs</p>
        <p>Key: Yellow 75%, Orange 90% and Red 100% or more</p>
        {semesters.map(sem => (
          <React.Fragment key={sem}>
            <Divider orientation="left">{this.print_semester(sem)} TA Hours</Divider>
            <TAHoursTable {...this.props} loading={loading_taapplications || loading_tahours || loading_schedule} taapplications={TAAppsWithGroups.filter(el => el.semester == sem)} tahours={tahours} tasemester={sem} />
          </React.Fragment>))}
      </Content>
    );
  }
}

class TAAssignButton extends StatusComponent {
  state = {
    endpoint_taapplication: "/api/ta/application/",
  }

  getInitialStatus() {
    return this.STATUS_GOOD;
  }

  getButton = (action) => {
    const { value, onClick } = this.props;


    if (value.unassignable) {
      return <Button disabled>Unassignable</Button>
    }

    return value.state == "Submitted" || value.state == "Reviewed" ? (<Button onClick={() => action && this.handleClick()} >Assign</Button>) : value.state == "Assigned" || value.state == "Pre-Assigned" ? (<Button onClick={() => action && this.handleClick()} type="primary">Unassign</Button>) : (<Button onClick={() => action && this.handleClick()} type="primary" disabled>Unassign</Button>);
  }

  handleClick = () => {
    const { value, onClick, courseOrSectionGroup } = this.props;

    const data = { "state": value.state == "Submitted" || value.state == "Reviewed" ? "Pre-Assigned" : "Reviewed" }
    if (value.course && courseOrSectionGroup && courseOrSectionGroup.group) {
      data["campus"] = null;
      data["group"] = courseOrSectionGroup.group.id;
    }
    this.doPatch(this.state.endpoint_taapplication + value.id + "/", onClick, JSON.stringify(data));
  }

  render() {
    const { value, taapplications, campus, courseOrSectionGroup } = this.props;
    const ta_status = taapplications.filter(el => el.ta.id == value.ta.id && (el.state == "Assigned" || el.state == "Processed" || el.state == "Hired") && this.semester_conflict(value.semester, el.semester));

    const pending = (value.state == "Submitted" || value.state == "Reviewed");
    const total_hours = ta_status.reduce((r, a) => r + a.hours, 0) + (pending ? value.hours : 0);

    const max_hours = value.ta.level == "PH" ? 40 : 20;

    const errors = [];

    // Pop up warnings if the app is about to move from un-assigned to assigned
    if (pending) {
      if (total_hours > max_hours) {
        errors.push("This student is already marked " + ta_status.map(el => el.state + " " + this.print_course(el.course)).join(", ") + ".  Adding them to this class may put them over the limit of " + max_hours + " hours/week.  Are you sure you want to continue?");
      } else if (ta_status?.length > 0) {
        errors.push("This student is already marked " + ta_status.map(el => el.state + " " + this.print_course(el.course)).join(", ") + ".  Are you sure you want to also assign them to this class?");
      }

      if (campus != value.ta.campus) {
        errors.push("This student is at a different campus than the course.  Are you sure you wish to assign them?");
      }

      if (value.campus && courseOrSectionGroup && courseOrSectionGroup.group) {
        errors.push("This student applied to all sections.  Are you sure you want to switch their application to this section group?")
      }
    }

    if (errors?.length > 0) {
      return (<Popconfirm placement="topRight" arrowPointAtCenter title={add_brs(errors)} onConfirm={this.handleClick} okText="Yes" cancelText="No">{this.getButton(false)}</Popconfirm>);
    } else {
      return this.getButton(true);
    }
  }
}

class TAHireButtons extends StatusComponent {
  state = {
    endpoint_taapplication: "/api/ta/application/",
    resignedModal_visible: false,

  }

  getInitialStatus() {
    return this.STATUS_GOOD;
  }

  getRequest = (state) => {
    const { value, onClick } = this.props;
    const data = { "state": state }

    return {
      method: "PATCH",
      endpoint: this.state.endpoint_taapplication + value.id + "/",
      body: JSON.stringify(data),
      onChange: onClick,
    };
  }

  getButton = (state, app, totalhours, disabled) => {
    const max_hours = app.ta.level == "PH" ? 40 : 20;

    const warn = totalhours > max_hours && (state == "EIB" || state == "Hire-Submitted" || state == "I-9-incomplete" || state == "Hired");
    const radio = (<RadioButton value={state} key={state} onClick={e => !warn && this.setValue(state)} disabled={disabled}>{state}</RadioButton>);
    return warn ?
      (<Popconfirm placement="topRight" arrowPointAtCenter title={["This student has multiple applications in the hiring queue with a total of over " + max_hours + " hours/week.", <br />, "Are you sure you want to move them to the " + state + " state?"]} onConfirm={e => this.setValue(state)} okText="Yes" cancelText="No">{radio}</Popconfirm>)
      : radio;
  }

  render() {
    const { value, disabled, allTaapplications } = this.props;
    const { status } = this.state;

    const unfinished = (value.ta.level != "PH" && (!value.hire_id || !value.pay_rate || !value.hours));

    // do not filter by campus here, as we want apps from all campuses
    var totalhours = allTaapplications.filter(el => el.ta.id == value.ta.id && (el.state == "Assigned" || el.state == "I-9-incomplete" || el.state == "EIB" || el.state == "Hire-Submitted" || el.state == "Hired") && this.semester_conflict(value.semester, el.semester)).reduce((r, a) => r + a.hours, 0);
    if (value.state == "Rejected" || value.state == "Resigned") {
      totalhours += value.hours;
    }

    const radios = (<RadioGroup buttonStyle="solid" value={value.state} disabled={disabled}>
      {this.getButton("EIB", value, totalhours, false)}
      {this.getButton("I-9-incomplete", value, totalhours, false)}
      {this.getButton("Hire-Submitted", value, totalhours, false)}
      {this.getButton("Hired", value, totalhours, unfinished)}
      {this.getButton("Rejected", value, totalhours, false)}
      {this.getButton("Resigned", value, totalhours, false)}
    </RadioGroup>);

    return (<div style={{ width: "550px" }}>
      {unfinished ? (<Tooltip title="You must first provide a Job ID, pay rate, and hours before you can hire this TA.">{radios}</Tooltip>) : radios}
      {"\u00a0\u00a0"}
      <Status status={status} />
    </div>);
  }
}

class TAHoursControl extends StatusComponent {
  state = {
    endpoint_taapplication: "/api/ta/application/",
  }

  getInitialStatus() {
    return this.STATUS_GOOD;
  }

  getRequest = (hours) => {
    const { value, onChange } = this.props;

    const data = { "hours": hours == "" ? null : hours };

    return {
      method: "PATCH",
      endpoint: this.state.endpoint_taapplication + value.id + "/",
      body: JSON.stringify(data),
      onChange: onChange,
    };
  }

  render() {
    const { value, disabled, allTaapplications } = this.props;
    const { status } = this.state;

    const totalhours = allTaapplications.filter(el => el.ta.id == value.ta.id && (el.state == "Assigned" || el.state == "Processed" || el.state == "Hired") && this.semester_conflict(value.semester, el.semester)).reduce((r, a) => r + a.hours, 0);
    const max_hours = value.ta.level == "PH" ? 40 : 20;
    const warn = totalhours > max_hours;

    const control = (<InputNumber disabled={disabled} style={{ width: '50px', border: warn ? '2px solid red' : '' }} min={1} max={max_hours} step={1} defaultValue={value.hours} onChange={this.setValue} />);

    return (<div style={{ width: "80px" }}>
      {warn ? (<Tooltip title={"This student has more than one application in the hiring process and exceeds " + max_hours + " hours/week combined."}>{control}</Tooltip>) : control}
      {"\u00a0\u00a0"}
      <Status status={status} />
    </div>

    );
  }
}

class TAPayRateControl extends StatusComponent {
  state = {
    endpoint_taapplication: "/api/ta/application/",
    toolTipContent: "Loading",
  }

  getInitialStatus() {
    return this.STATUS_GOOD;
  }

  getRequest = (rate) => {
    const { value, onChange } = this.props;

    const data = { "pay_rate": rate == "" ? null : rate };

    return {
      method: "PATCH",
      endpoint: this.state.endpoint_taapplication + value.id + "/",
      body: JSON.stringify(data),
      onChange: onChange,
    };
  }

  calculatePayRate = (data) => {
    const { semesters, value } = this.props;
    const apps = data.filter(el => !semesters.includes(el.semester));
    const previous_pays = apps.map(el => el.pay_rate);
    const max_pay = Math.max(...previous_pays);
    this.setState({ toolTipContent: ("Previous hires: " + previous_pays.length + ", and pay: " + ([6077, 6078].includes(value.ta.campus) ? "C$" : "$") + (previous_pays.length ? max_pay : 0)) });

  }

  payRateFormula(visible) {
    const { value } = this.props;
    if (visible) {
      this.doGet(this.state.endpoint_taapplication + "?ta=" + value.ta.id + "&state=Hired", data => this.calculatePayRate(data));
    }
  };

  render() {
    const { value, disabled } = this.props;
    const { status, toolTipContent } = this.state;
    const currency = [6077, 6078].includes(value.ta.campus) ? "C$" : "$";

    return (
      <div style={{ width: "105px" }}>
        <Tooltip title={toolTipContent} onVisibleChange={(visible) => this.payRateFormula(visible)}>
          <InputNumber disabled={disabled || value.ta.level == "PH"} style={{ width: '75px' }} min={0} max={30} step={0.25}       
          formatter={value => (currency +" "+ value)}
          parser={value => value.replace('$', '').replace('C', '')} defaultValue={value.pay_rate} onChange={this.setValue} />
        </Tooltip>
        {"\u00a0\u00a0"}
        <Status status={status} />
      </div>
    );
  }
}

class TAJobIdControl extends StatusComponent {
  state = {
    endpoint_taapplication: "/api/ta/application/",
  }

  getInitialStatus() {
    return this.STATUS_GOOD;
  }

  getRequest = (jobId) => {
    const { value, onChange } = this.props;

    const data = { "hire_id": jobId == "" ? null : jobId };

    return {
      method: "PATCH",
      endpoint: this.state.endpoint_taapplication + value.id + "/",
      body: JSON.stringify(data),
      onChange: onChange,
    };
  }

  render() {
    const { value, disabled } = this.props;
    const { status } = this.state;

    return (<div style={{ width: "110px" }}>
      <InputNumber disabled={disabled || value.ta.level == "PH"} style={{ width: '80px' }} min={0} max={999999} step={1} defaultValue={value.hire_id} onChange={this.setValue} />
      {"\u00a0\u00a0"}
      <Status status={status} />
    </div>);
  }
}

class TAAssign extends AppComponent {
  state = {
    endpoint_schedule: "/api/schedule/",
    sections: [],
    loading_schedule: true,

    endpoint_taapplication: "/api/ta/application/",
    taapplications: [],
    loading_taapplications: true,

    endpoint_tareview: "/api/ta/rate/",
    tareviews: [],
    loading_tareviews: true,

    endpoint_groups: "/api/schedule/group/",
    groups: [],
    loading_groups: true,

    endpoint_release_taapplication: "/api/ta/application/release/",

    selected_semester: null,
    selected_course_or_section_group: null,

  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.cancelRequests();
      this.setState({ loading_taapplications: true, loading_schedule: true, loading_tareviews: true, loading_groups: true, groups: [] }, this.getData());
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taapplication + "?semester=" + this.props.semesters.join(","),
      data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_schedule + "?campus=" + this.props.campus + "&semester=" + this.props.semesters.join(",") + "&deleted=False",
      data => this.setState({ sections: data, loading_schedule: false }));
    this.doGet(this.state.endpoint_tareview + "?taapplication__semester=" + this.props.semesters.join(","),
      data => this.setState({ tareviews: data, loading_tareviews: false }));
    this.doGet(this.state.endpoint_groups + "?semester=" + this.props.semesters.join(","),
      data => this.setState({ groups: data, loading_groups: false }));
  }

  release_assignments = (semester) => {
    const { campus } = this.props;
    this.doPost(this.state.endpoint_release_taapplication, response => { message.success("Released"), this.setState({ loading_taapplications: true, loading_schedule: true, loading_tareviews: true }, this.getData()); }, JSON.stringify({ semester: semester, campus: campus }));
  }

  filter_course_list_campus = (sections, campus) => {
    const { groups } = this.state;

    return sections.filter(el => el.course ? true : groups.find(g => g.id == el.group.id).primary_campus == campus);
  }

  annotateCourseorSectionGroup = (record, sem) => {
    const { sections, taapplications, tareviews, groups } = this.state;

    var mysections = [];
    var myapplications = [];

    if (record.course) {
      mysections = sections.filter(el => el.course == record.course.id && el.semester == sem && groups.find(g => g.sections.find(gs => gs.id == el.id)) == null);
      myapplications = taapplications.filter(el => el.course == record.course.id && el.semester == sem && el.campus == this.props.campus && !el.group);
    } else {
      mysections = groups.find(g => g.id == record.group.id).sections;
      mysections = mysections.filter(el => el.deleted == false)
      myapplications = taapplications.filter(el => el.semester == sem && ((el.group == record.group.id) || mysections.find(s => s.course == el.course && s.campus == el.campus && el.group == null)));
    }

    var enrollment = mysections.map(el => el.enrollment).reduce((r, a) => r + a, 0);

    record.instructors_list = mysections.map(el => el.instructors).flat();
    record.sections = mysections.length;
    record.instructors_reviewed = Object.keys(tareviews.filter(el => { const app = taapplications.find(ta => ta.id == el.taapplication); return app && app.course == record.id && app.semester == sem; }).map(el => el.instructor).reduce((r, a) => { r[a] = 1; return r; }, {})).length;
    record.instructors = Object.keys(mysections.map(el => el.instructors).flat().reduce((r, a) => { r[a] = 1; return r; }, {})).length;
    record.enrollment = enrollment;
    record.estimated = enrollment * this.get_semester(sem).speed / (1.0 * (mysections.length != 0 ? this.get_course(mysections[0].course).ta_ratio : 25));
    record.applications = myapplications.length;
    record.assigned = myapplications.filter(el => is_assigned(el.state) && ((record.course && record.course.id == el.course) || (record.group && record.group.id == el.group))).reduce((r, a) => r + a.hours / 20.0, 0);

    return record;
  }

  formatColor = (num) => {
    if (num < -0.5) {
      return (<span className="ta-under">{num}</span>);
    } else if (num > 0.5) {
      return (<span className="ta-over">+{num}</span>);
    } else if (num > 0) {
      return (<span className="ta-ontarget">+{num}</span>);
    } else {
      return (<span className="ta-ontarget">{num}</span>);
    }
  }

  generateTAAppsDataForExport = (taapplications, groups, sem) => {

    if (taapplications.length == 0 || groups.length == 0) {
      return [];
    }

    let filterApps = taapplications.filter(el => el.semester == sem);


    filterApps.forEach((el) => {
      el["campus"] = el.campus ? el.campus : groups.find(g => g.id == el.group).primary_campus;
    });

    return filterApps;
  }

  renderOverview = () => {
    const { semester, campus, type } = this.props;
    const { loading_schedule, sections, loading_taapplications, taapplications, loading_tareviews, loading_groups, tareviews, groups } = this.state;
    const loading = loading_schedule || loading_taapplications || loading_tareviews || loading_groups;
    const columns = [
      {
        title: 'Course',
        key: 'course',
      }, {
        title: 'Instructors',
        key: 'list_instructors',
        render: (text, record, idx) => this.print_instructor_list([...new Set(record.instructors_list)]),
      }, {
        title: 'Sections',
        key: 'sections',
        width: 75,
        align: 'right',
        render: (text, record, idx) => record.sections,
      }, {
        title: 'Reviews',
        key: 'reviews',
        width: 75,
        align: 'right',
        render: (text, record, idx) => record.instructors_reviewed + "/" + record.instructors,
      }, {
        title: 'Enrollment',
        key: 'enrollment',
        width: 80,
        align: 'right',
        render: (text, record, idx) => record.enrollment,
      }, {
        title: 'Estimated',
        key: 'estimated',
        width: 75,
        align: 'right',
        render: (text, record, idx) => <NumberFormat fixedDecimalScale={true} decimalScale={2} displayType={'text'} value={record.estimated} />,
      }, {
        title: 'Applications',
        key: 'applications',
        width: 90,
        align: 'right',
        render: (text, record, idx) => record.applications,
      }, {
        title: 'Assigned',
        key: 'assigned',
        width: 75,
        align: 'right',
        render: (text, record, idx) => <NumberFormat fixedDecimalScale={true} decimalScale={2} displayType={'text'} value={record.assigned} />,
      }, {
        title: 'Over/Under',
        key: 'overunder',
        width: 85,
        align: 'right',
        render: (text, record, idx) => <NumberFormat fixedDecimalScale={true} decimalScale={2} displayType={'text'} value={record.assigned - record.estimated} renderText={this.formatColor} />,
      }];

    return (
      <Content {...this.props} title={semester + " TA Assignments"} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "TA" }, { text: "Assign" }]}>
        <p>Welcome to the Khoury course assistant application assignment portal. This site is designed for administrators to assign applications to courses.</p>
        {this.props.semesters.map(sem => {
          var mycolumns = copy(columns);
          mycolumns[0].render = (text, record, idx) => (<a onClick={() => { this.setState({ selected_course_or_section_group: record, selected_semester: sem }) }}>{record.course ? this.print_full_course(record.course.id) : record.group.name}</a>);

          const mysections = sections.filter(el => el.semester == sem && this.get_course(el.course).lab_for.length == 0);
          const mygroups = groups.filter(g => g.semester == sem);

          return (
            <React.Fragment key={sem}>
              <Divider orientation="left">{this.print_semester(sem)} courses ({this.print_campus(this.props.campus)})</Divider>
              {!this.get_taportal_status([sem]) && (
                <div style={{ paddingBottom: "10px" }}>
                  <Alert message="TA Portal is currently closed for students" type="warning" showIcon />
                </div>
              )}
              <span style={{ float: 'right' }}>
                <Button type="primary" onClick={() => { this.release_assignments(sem) }}>Release</Button>
                {" "}
                <TADataExport {...this.props} loading={loading} taapplications={this.generateTAAppsDataForExport(taapplications, groups, sem)} tasemester={sem} groups={groups} />
              </span>
              <div style={{ clear: 'both' }}></div>
              <CustomTabs {...this.props} default_Active_Key="courses">
                <TabPane tab={"Courses"} key={"courses"}>
                  <Table {...this.props} dataSource={this.filter_course_list_campus(this.course_list_from_sections_with_groups(mysections, mygroups), this.props.campus).map(el => this.annotateCourseorSectionGroup(el, sem))} columns={mycolumns} loading={loading} bordered={false} pagination={false} size="small" rowKey="id" key={"table"} />
                </TabPane>
                <TabPane tab={"Students"} key={"student"}>
                  <TAApplicationTable {...this.props} campus={this.props.campus} taapplications={taapplications.filter(el => el.semester == sem && el.campus == this.props.campus && (el.state == "Submitted" || el.state == "Reviewed"))} groups={groups} tareviews={tareviews} allTaapplications={taapplications} key={"table-free"} loading={loading} getAction={e => <TAAssignButton {...this.props} onClick={this.updateApplication} value={e} taapplications={taapplications} campus={this.props.campus} />} actionWidth={120} width={900} assign_table={true} hide_fields={["profile", "notes", "taken", "grade", "taed", "photo", "hours", "username", "gpa", "semester", "jobId", "nuid", "payRate"]} />
                </TabPane>
              </CustomTabs>
            </React.Fragment>
          );
        }
        )}
      </Content>
    );
  }

  updateApplication = (app) => {
    const { taapplications } = this.state;

    const newapps = taapplications.map(el => {
      if (el.id == app.id) {
        el.hours = app.hours;
        el.state = app.state;
        el.campus = app.campus;
        el.group = app.group;
      }

      return el;
    });

    this.setState({ taapplications: newapps });
  }

  renderCourseOrSectionGroup() {
    const { campus, semester } = this.props;
    const { loading_taapplications, loading_schedule, loading_groups, taapplications, selected_course_or_section_group, selected_semester, tareviews, groups } = this.state;

    const loading = loading_taapplications || loading_schedule || loading_groups;
    var apps = [];

    var already_assigned = [];

    if (selected_course_or_section_group.course) {
      apps = taapplications.filter(el => el.semester == selected_semester && selected_course_or_section_group.course.id == el.course && el.campus == this.props.campus && !el.group);
      already_assigned = apps.filter(el => el.state != "Submitted" && el.state != "Reviewed");
    } else {
      const course_apps = taapplications.filter(el => el.semester == selected_semester && selected_course_or_section_group.group.sections.find(s => s.course == el.course && s.campus == el.campus) && el.group == null);
      const group_apps = taapplications.filter(el => el.semester == selected_semester && el.group == selected_course_or_section_group.group.id);
      apps = group_apps.concat(course_apps.filter(ca => group_apps.find(ga => ga.ta.id == ca.ta.id) == null));
      already_assigned = group_apps.filter(el => el.state != "Submitted" && el.state != "Reviewed");
    }

    const not_assigned = apps.filter(el => el.state == "Submitted" || el.state == "Reviewed");

    const overview = this.annotateCourseorSectionGroup(selected_course_or_section_group, selected_semester);
    const title = selected_course_or_section_group.course ? this.print_course(selected_course_or_section_group.course.id) : selected_course_or_section_group.group.name;

    return (
      <Content {...this.props} title={this.print_semester(selected_semester) + " TA Assignments for " + title} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "TA" }, { link: "/staff/ta/assign", text: "Assign" }, { text: title }]}>
        <p>Welcome to the Khoury course assistant application assignment portal. This site is designed for administrators to assign applications to courses. You can also <span onClick={() => this.setState({ selected_course_or_section_group: null })}><a>go back to the course list</a></span>.</p>
        <p><b>Estimated TA support:</b> <NumberFormat fixedDecimalScale={true} decimalScale={2} displayType={'text'} value={overview.estimated} /></p>
        <p><b>Assigned TA support:</b> <NumberFormat fixedDecimalScale={true} decimalScale={2} displayType={'text'} value={overview.assigned} /></p>

        <CustomTabs {...this.props} default_Active_Key="Not yet">
          <TabPane tab={["Not yet assigned (", not_assigned.length, ")"]} key="Not yet">
            <p>Below are the students who are not yet assigned to {title}.  You can assign them here by selecting the Assign button.</p>
            <TAApplicationTable {...this.props} campus={this.props.campus} taapplications={not_assigned} groups={groups} tareviews={tareviews} allTaapplications={taapplications} key={"table-free"} loading={loading} getAction={e => <TAAssignButton {...this.props} onClick={this.updateApplication} value={e} taapplications={taapplications} campus={this.props.campus} courseOrSectionGroup={selected_course_or_section_group} />} actionWidth={120} width={900} assign_table={true} hide_fields={["profile", "photo", "hours", "username", "course", "gpa", "semester", "jobId", "nuid", "payRate"]} rowClassName={record => selected_course_or_section_group.group && !record.group ? "taapplication-course" : null} />
          </TabPane>

          <TabPane tab={["Already assigned (", <NumberFormat fixedDecimalScale={true} decimalScale={2} displayType={'text'} value={overview.assigned} />, ")"]} key="Already">
            <p>Below are the students who are not already assigned to {title}.  You can unassign them here by selecting the Unassign button.</p>
            <TAApplicationTable {...this.props} campus={this.props.campus} taapplications={already_assigned} groups={groups} tareviews={tareviews} allTaapplications={taapplications} key={"table-already"} loading={loading} getAction={e => <TAAssignButton {...this.props} onClick={this.updateApplication} value={e} taapplications={taapplications} />} getHours={e => <TAHoursControl {...this.props} allTaapplications={taapplications} onChange={this.updateApplication} value={e} courseOrSectionGroup={selected_course_or_section_group} />} actionWidth={120} width={900} hide_fields={["profile", "photo", "username", "nuid", "course", "gpa", "semester", "jobId", "payRate"]} rowClassName={record => selected_course_or_section_group.group && !record.group ? "taapplication-course" : null} />
          </TabPane>
        </CustomTabs>
      </Content>
    );
  }

  render() {
    const { selected_course_or_section_group, selected_semester, sections } = this.state;
    if (selected_course_or_section_group && selected_semester) {
      return this.renderCourseOrSectionGroup();
    } else {
      return this.renderOverview();
    }
  }
}

class CheckTAInformation extends AppComponent {
  state = {
    endpoint_taapplication: "/api/ta/application/",
    file: null,
    current: 0,
    spreadsheetColumns: [],
    data: null,
    NUID: 0,
    jobId: 3,
    payRate: 14,
    postionTitle: 6,
    campus: 8,
    currency: 15,
    end_date: 10,
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester) || (prevProps.taapplications !== this.props.taapplications)) {
      this.setState({ file: null, current: 0, spreadsheetColumns: [], data: null });
    }
  }

  nextStep = () => {
    const { current } = this.state;
    this.setState({ current: current + 1 });
  }

  // {type: Workday Value:, Admin Portal Value, Action}
  generateWarnings = (taapp, workday) => {
    const { jobId, payRate, campus, currency } = this.state;
    let warnings = [];
    if (parseInt(workday[jobId]) !== parseInt(taapp.hire_id)) {
      warnings.push({ type: "Hire ID Mismatch", taapp: taapp, workdayValue: parseInt(workday[jobId]), adminPortalvalue: parseInt(taapp.hire_id) });
    }
    if (parseInt(workday[payRate]) !== parseInt(taapp.pay_rate) && parseInt(workday[currency]) === "USD") {
      warnings.push({ type: "Pay Mismatch", taapp: taapp, workdayValue: parseInt(workday[payRate]), adminPortalvalue: parseInt(taapp.pay_rate) });
    }
    if (!workday[campus].includes(this.print_campus(taapp.ta.campus).split(',')[0])) {
      warnings.push({ type: "Campus Mismatch", taapp: taapp, workdayValue: workday[campus], adminPortalvalue: this.print_campus(taapp.ta.campus) });
    }
    return warnings;
  }

  compareData = (taapp) => {
    const { NUID, data, postionTitle, end_date } = this.state;
    let rObj = [];
    if (!data || !app || NUID == null || postionTitle == null) {
      return null;
    }
    taapp.forEach(app => {
      let workday = data.filter(row => parseInt(row[NUID]) == parseInt(app.ta.nuid) &&
        (row[postionTitle].includes("Teaching Assistant") ||
          row[postionTitle].includes("Instructional Assistant") ||
          row[postionTitle].includes("Master's Assistant") ||
          row[postionTitle].includes("Masters Assistant") ||
          row[postionTitle].includes("TA")) &&
        moment(row[end_date], "MM/DD/YY").isBetween(moment(this.get_semester(app.semester).startdate), moment(this.get_semester(app.semester).enddate).add(2, 'weeks'), undefined, '[]'));
      if (workday.length == 1) {
        let warnings = this.generateWarnings(app, workday[0])
        if (warnings.length !== 0) {
          rObj.push({ NUID: app.ta.nuid, warnings: warnings, app: app, workday: workday[0] })
        }
      }
    });
    return rObj;
  }

  setFile = (file) => { this.setState({ file: file }); }

  processSpreadsheetUpload = () => {
    readXlsxFile(this.state.file).then((rows) => {
      this.setState({ spreadsheetColumns: (rows[0].length != 0 && rows[0][0] != null ? rows[0] : rows[1]), data: rows, current: 1 });
    })
  }


  updateApp = (checked, record) => {
    const { taapp, workdayValue, adminPortalvalue, type } = record;
    let value = checked ? workdayValue : adminPortalvalue;
    let data = (type == "Hire ID Mismatch") ? { "hire_id": value } : { "pay_rate": value }
    this.doPatch(this.state.endpoint_taapplication + taapp.id + "/", response => { message.success("Updated.") }, JSON.stringify(data));
  }

  renderWarning = (warnings) => {
    const columns = [
      {
        title: 'Type',
        key: 'type',
        width: 150,
        render: (text, record, idx) => record.type,
      }, {
        title: 'Admin Portal',
        key: 'adminPortalvalue',
        width: 150,
        render: (text, record, idx) => record.adminPortalvalue,
      }, {
        title: 'Actions',
        key: 'actions',
        width: 80,
        render: (text, record, idx) => <Switch disabled={record.type == "Campus Mismatch"} checkedChildren={"WD"} unCheckedChildren={"KAP"} onChange={e => this.updateApp(e, record)} />,
      }, {
        title: 'Workday',
        key: 'workdayValue',
        width: 150,
        render: (text, record, idx) => record.workdayValue,
      }];

    return <Table columns={columns} dataSource={warnings} size="small" pagination={false} />
  }

  render() {
    const { taapplications, loading } = this.props;
    const { current, spreadsheetColumns, NUID, jobId, payRate, postionTitle, campus, currency, end_date } = this.state;
    const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

    const props = {
      onChange: this.handleChange,
      onChange: this.processSpreadsheetUpload,
      beforeUpload: file => {
        const reader = new FileReader();
        const setFile = this.setFile;
        reader.onload = function (event) {
          setFile(event.target.result);
        };
        reader.readAsArrayBuffer(file);
      }
    };

    const columns = [
      {
        title: 'Student',
        key: 'student',
        width: 150,
        render: (text, record, idx) => this.print_full_student_reverse(record.app.ta),
      }, {
        title: 'NUID',
        key: 'nuid',
        width: 90,
        render: (text, record, idx) => record.NUID,
      }, {
        title: 'TA Campus',
        key: 'campus',
        width: 80,
        render: (text, record, idx) => this.print_campus(record.app.ta.campus),
      }, {
        title: 'Semester',
        key: 'semester',
        width: 100,
        render: (text, record, idx) => this.print_semester(record.app.semester),
      }, {
        title: 'Course',
        key: 'course',
        width: 75,
        render: (text, record, idx) => this.print_course(record.app.course),
      }, , {
        title: 'Workday',
        key: 'workday',
        width: 25,
        render: (text, record, idx) => <><Tooltip title={<ul>{record.workday.filter(el => { return (el); }).map(el => { return (<li>{JSON.stringify(el)}</li>); })}</ul>}><Button>View</Button></Tooltip></>,
      }, {
        title: 'Warnings',
        key: 'warnings',
        width: 500,
        render: (text, record, idx) => this.renderWarning(record.warnings),
      }];

    const steps = [
      {
        title: 'Select Spreadsheet',
        content: (
          <>
            <div style={{ paddingTop: "20px", paddingBottom: "10px" }}>
              <p><b> Please Note:</b></p>
              <ul>
                <li>Please pre-filter (excel sorting or filtering will be ignored; filtered out rows <b>must</b> be deleted) the spreadsheet to only include TAs.</li>
                <li>The Admin Portal data is restricted to your currently selected semester and campus.</li>
                <li>If a student has more than one TA job a semester, please check this data manually.</li>
              </ul>
            </div>
            <Upload {...props} >
              <Button type="primary" loading={loading}>
                <Icon type="upload" /> Click to Upload
              </Button>
            </Upload>
          </>
        )
      }, {
        title: 'Match Columns',
        content: (
          <>
            <div style={{ paddingTop: "20px" }}>
              <p> To help match the data columns in the Workday spreadsheet to those in the Admin portal please select the corresponding columns in the spreadsheet to those in the Admin Portal.</p>
              <p><b> Please Note:</b> We have selected default matches, if these are incorrect, please use the inputs below to adjust.</p>
              <Form>
                <FormItem {...formItemLayout} label="NUID Column" extra={""}>
                  <Select style={{ maxWidth: "50%" }} defaultValue={NUID} showSearch onChange={event => this.setState({ NUID: event })}>{spreadsheetColumns.map((el, idx) => <Option value={idx}>{el}</Option>)}</Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Position Title" extra={""}>
                  <Select style={{ maxWidth: "50%" }} defaultValue={postionTitle} onChange={event => this.setState({ postionTitle: event })}>{spreadsheetColumns.map((el, idx) => <Option value={idx}>{el}</Option>)}</Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Campus" extra={""}>
                  <Select style={{ maxWidth: "50%" }} defaultValue={campus} onChange={event => this.setState({ campus: event })}>{spreadsheetColumns.map((el, idx) => <Option value={idx}>{el}</Option>)}</Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Hire (Job) ID Column" extra={""}>
                  <Select style={{ maxWidth: "50%" }} defaultValue={jobId} onChange={event => this.setState({ jobId: event })}>{spreadsheetColumns.map((el, idx) => <Option value={idx}>{el}</Option>)}</Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Pay Rate" extra={""}>
                  <Select style={{ maxWidth: "50%" }} defaultValue={payRate} onChange={event => this.setState({ payRate: event })}>{spreadsheetColumns.map((el, idx) => <Option value={idx}>{el}</Option>)}</Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Employment End Date" extra={""}>
                  <Select style={{ maxWidth: "50%" }} defaultValue={end_date} onChange={event => this.setState({ end_date: event })}>{spreadsheetColumns.map((el, idx) => <Option value={idx}>{el}</Option>)}</Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Currency" extra={""}>
                  <Select style={{ maxWidth: "50%" }} defaultValue={currency} onChange={event => this.setState({ currency: event })}>{spreadsheetColumns.map((el, idx) => <Option value={idx}>{el}</Option>)}</Select>
                </FormItem>
                <Button disable={NUID == null || jobId == null || payRate == null || postionTitle == null} onClick={this.nextStep}>Next</Button>
              </Form>
            </div>
          </>
        )
      }, {
        title: 'Compare',
        content: (
          <>
            <div style={{ paddingTop: "20px", paddingBottom: "10px" }}>
              <p><b> Please Note:</b></p>
              <ul>
                <li>You cannot edit a TAs campus via this form; Please inform the Admin Portal of any students that need their campus updated.</li>
              </ul>
            </div>
            <Button icon="redo" onClick={() => this.props.getApplications()}>Refresh</Button>
            <Table columns={columns} dataSource={this.compareData(taapplications)} />
          </>
        )
      }];

    return (
      <>
        <div style={{ marginTop: "15px" }}>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div className="steps-content">{steps[current].content}</div>
      </>
    );
  }
}


class TAHire extends AppComponent {
  state = {
    endpoint_taapplication: "/api/ta/application/",
    taapplications: [],
    loading_taapplications: true,

    endpoint_groups: "/api/schedule/group/",
    groups: [],
    loading_groups: true,

    resignedModal_visible: false,
    checkInfoModal_visible: false,
    specific_ta: "",
    file: null,
    selectedTab: "Pending processing",
  }

  componentDidMount() {
    this.getApplications();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getApplications();
    }
  }

  getApplications = () => {
    this.setState({ loading_taapplications: true, loading_groups: true });
    this.doGet(this.state.endpoint_taapplication + "?state=Assigned,Accepted,Hire-Submitted,EIB,I-9-incomplete,Hired,Rejected,Resigned" + "&semester=" + this.props.semesters.join(","),
      data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_groups + "?semester=" + this.props.semesters.join(","),
      data => this.setState({ groups: data, loading_groups: false }));
  }

  updateApplication = (app) => {
    const { taapplications } = this.state;

    const newapps = taapplications.map(el => {
      if (el.id == app.id) {
        el.hours = app.hours;
        el.state = app.state;
        el.hire_id = app.hire_id;
        el.pay_rate = app.pay_rate;
      }

      return el;
    });

    this.setState({ specific_ta: app.ta, resignedModal_visible: (app.state == "Resigned") ? true : false, taapplications: newapps });
  }

  getTable = (applications, disabled, hide_fields = []) => {
    const { loading_taapplications, loading_groups, taapplications, groups } = this.state;

    let loading = loading_taapplications || loading_groups;

    return (<TAApplicationTable {...this.props} campus={this.props.campus} taapplications={applications} groups={groups} allTaapplications={taapplications} loading={loading} getAction={e => <TAHireButtons {...this.props} onClick={this.updateApplication} value={e} disabled={disabled} allTaapplications={taapplications} />} getHours={e => <TAHoursControl {...this.props} onChange={this.updateApplication} value={e} allTaapplications={taapplications} />} getJobId={e => <TAJobIdControl {...this.props} onChange={this.updateApplication} value={e} />} getPayRate={e => <TAPayRateControl {...this.props} onChange={this.updateApplication} value={e} />} width={900} hide_fields={["profile", "photo", "username", "grade", "rank", "gpa", "taed", "taken", "reviews", "recommendations", "tareviews", "history", "submitted_at"] + hide_fields} />);
  }

  changeResignedModalVisibility = () => {
    this.setState({ resignedModal_visible: !this.state.resignedModal_visible, specific_ta: "" });
  }

  getGroupCampus = (id, campus) => {
    const { groups } = this.state;
    let group = groups.find(g => g.id == id);
    return group ? group.primary_campus == campus : false;

  }

  changeTab = (activeKey) => {
    const { selectedTab } = this.state;
    if (activeKey == "Compare Data" || selectedTab == "Compare Data") {
      this.getApplications();
    }
    this.setState({ selectedTab: activeKey })
  }

  render() {
    const { semester, campus } = this.props;
    const { taapplications, specific_ta, loading_taapplications, loading_groups } = this.state;

    const mytaapplications = taapplications.filter(el => (el.campus == campus) || el.group && this.getGroupCampus(el.group, campus));

    const assigned = mytaapplications.filter(el => el.state == "Assigned");
    const accepted = mytaapplications.filter(el => el.state == "Accepted");
    const i9incomplete = mytaapplications.filter(el => el.state == "I-9-incomplete");
    const eib = mytaapplications.filter(el => el.state == "EIB");
    const hireSubmitted = mytaapplications.filter(el => el.state == "Hire-Submitted");
    const hired = mytaapplications.filter(el => el.state == "Hired");
    const rejected = mytaapplications.filter(el => el.state == "Rejected");
    const resigned = mytaapplications.filter(el => el.state == "Resigned");
    let loading = loading_taapplications || loading_groups;

    return (
      <Content {...this.props} title={semester + " TA Hiring"} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "TA" }, { text: "Hire" }]}>
        <p>Welcome to the Khoury course assistant application hiring portal. This site is designed for administrators to hire students after the are assigned to courses.</p>

        <Modal
          visible={this.state.resignedModal_visible}
          title={"Please provide some details"}
          footer={null}
          width={800}
          onCancel={this.changeResignedModalVisibility}
          destroyOnClose={true}
        >
          <p>Please enter this data if you would like to prevent this TA from TAing in the future (either temporarily or permanently), otherwise please hit the skip button.</p>
          <TAHRForm {...this.props} student={{ id: specific_ta.student, firstname: specific_ta.firstname, lastname: specific_ta.lastname }} finishedHook={this.changeResignedModalVisibility} />
        </Modal>
        <CustomTabs {...this.props} default_Active_Key="Pending acceptance" onChange={this.changeTab}>
          <TabPane tab="Pending acceptance" disabled={assigned.length == 0} key="Pending acceptance">
            <p>Below are all of the TAs who have been assigned, but who have yet to accept their offer (TAs must officially accept the offer before they can be hired).  You cannot take any actions on their applications at this time.  If needed, you can <a href={"mailto:" + assigned.map(el => el.ta.email).join(",")}>send an email to all these TAs</a>.</p>
            {this.getTable(assigned, true, ["payRate"])}
          </TabPane>
          <TabPane tab="Pending processing" disabled={accepted.length == 0} key="Pending processing">
            <p>Below are all of the TAs who are ready to be hired, but have not yet had any action taken on their application.  If needed, you can <a href={"mailto:" + accepted.map(el => el.ta.email).join(",")}>send an email to all these TAs</a>.</p>
            {this.getTable(accepted, false)}
          </TabPane>
          <TabPane tab="On EIB" disabled={eib.length == 0} key="EIB">
            <p>Below are all of the TAs who have been marked as being on the eib import to Workday. These TAs will not be able to submit hours yet.  If needed, you can <a href={"mailto:" + eib.map(el => el.ta.email).join(",")}>send an email to all these TAs</a>.</p>
            {this.getTable(eib, false)}
          </TabPane>
          <TabPane tab="I-9 incomplete" disabled={i9incomplete.length == 0} key="I-9 incomplete">
            <p>Below are all of the TAs who have been marked as in process (typically, they are waiting for their I-9).  These TAs will not be able to submit hours yet.  If needed, you can <a href={"mailto:" + i9incomplete.map(el => el.ta.email).join(",")}>send an email to all these TAs</a>.</p>
            {this.getTable(i9incomplete, false)}
          </TabPane>
          <TabPane tab="Hire Submitted" disabled={hireSubmitted.length == 0} key="Hire Submitted">
            <p>Below are all of the TAs who have been marked as initial hire submitted in Workday. These TAs will not be able to submit hours yet.  If needed, you can <a href={"mailto:" + hireSubmitted.map(el => el.ta.email).join(",")}>send an email to all these TAs</a>.</p>
            {this.getTable(hireSubmitted, false)}
          </TabPane>
          <TabPane tab="Hired" disabled={hired.length == 0} key="Hired">
            <p>Below are all of the TAs who have been completely hired.  These TAs will be able to submit hours.  If needed, you can <a href={"mailto:" + hired.map(el => el.ta.email).join(",")}>send an email to all these TAs</a>.</p>
            {this.getTable(hired, false)}
          </TabPane>
          <TabPane tab="Rejected" disabled={rejected.length == 0} key="Rejected">
            <p>Below are all of the TAs who have been rejected during the hiring process (e.g., they are on co-op, or have any academic integrity issue).  These TAs will not be able to submit hours.  If needed, you can <a href={"mailto:" + rejected.map(el => el.ta.email).join(",")}>send an email to all these TAs</a>.</p>
            {this.getTable(rejected, false)}
          </TabPane>
          <TabPane tab="Resigned" disabled={resigned.length == 0} key="Resigned">
            <p>Below are all of the TAs who have resigned from their position either voluntarily or involuntarily.  These TAs will not be able to submit hours. If needed, you can <a href={"mailto:" + resigned.map(el => el.ta.email).join(",")}>send an email to all these TAs</a>.</p>
            {this.getTable(resigned, false)}
          </TabPane>
          <TabPane style={{ color: "#363636" }} tab="Compare Data" disabled={hired.length == 0 && i9incomplete.length == 0} key="Compare Data">
            <p>Use this process to compare the data in the Admin Portal to that in Workday to ensure it is accruate, it can currently check Hire ID's, pay-rates and job location. To use this functionality, please use the below upload field to browse your computer and find the spreadsheet that you exported from Workday. Please leave the spreadsheet formatting as is as the upload expects the spreadsheet to be in the exact format that Workday created.</p>
            {<CheckTAInformation {...this.props} getApplications={this.getApplications} loading={loading} taapplications={mytaapplications.filter(el => el.state == "Processed" || el.state == "Hired")} />}
          </TabPane>
        </CustomTabs>
      </Content>
    );
  }
}

class TAAuditReview extends AppComponent {
  state = {

    endpoint_taaudit: "/api/ta/audit/",
    taaudit: [],
    loading: true,

    endpoint_tahours: "/api/ta/hours/",
    tahours: [],
    loading_tahours: true,

    endpoint_taapplication: "/api/ta/application/",
    taapplications: [],
    loading_taapplications: true,
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.doGet(this.state.endpoint_taaudit, data => this.setState({ taaudit: data, loading: false }));
    this.doGet(this.state.endpoint_taapplication + "?campus=" + this.props.campus + "&semester=" + this.props.semesters.join(",") + "&state=Hired",
      data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_tahours + "?taapplication__campus=" + this.props.campus + "&taapplication__semester=" + this.props.semesters.join(","),
      data => this.setState({ tahours: data, loading_tahours: false }));
  }

  handleUpdate = (id, action) => {
    const data = { "status": action };
    this.doPatch(this.state.endpoint_taaudit + id + "/", response => { this.update(); }, JSON.stringify(data));
  }

  getActions = (text, record, idx) => {
    const menu = (
      <Menu>
        <Menu.Item>
          <a onClick={() => this.handleUpdate(record.id, "Accurate")}>Accurate</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleUpdate(record.id, "Inaccurate")}>Inaccurate</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleUpdate(record.id, "Emailed")}>Emailed</a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} disabled={(record.status == "Accurate" || record.status == "Inaccurate")}>
        <a className="ant-dropdown-link">
          Actions <Icon type="down" />
        </a>
      </Dropdown>
    );
  }

  getTAApp = (taapplications, record) => {
    return taapplications.filter(el => el.id == record.taapplication);
  }

  render() {
    const { semester } = this.props;
    const { loading, loading_taapplications, loading_hours, taaudit, taapplications, tahours } = this.state;
    const columns = [
      {
        title: 'NUID',
        key: 'nuid',
        fixed: 'left',
        width: 80,
        render: (text, record, idx) => record.taapplication.ta.nuid,
        sorter: (a, b) => a.taapplication.ta.nuid - b.taapplication.ta.nuid,
      }, {
        title: 'Name',
        key: 'name',
        width: 100,
        render: (text, record, idx) => [this.print_full_student(record.taapplication.ta) + " ", <a key="email" href={"mailto:" + record.taapplication.ta.email}><Icon type="mail" theme="twoTone" /></a>],
      }, {
        title: 'Course',
        key: 'course',
        width: 80,
        render: (text, record, idx) => this.print_course(record.taapplication.course),
      }, {
        title: 'Submitted Hours',
        key: 'hours',
        width: 80,
        render: (text, record, idx) => <Popover content={<TAHoursTable {...this.props} loading={loading_taapplications || loading_hours || loading} taapplications={taapplications.filter(el => el.id == record.taapplication.id)} tahours={tahours} tasemester={record.taapplication.semester} />
        } title="Hours"><Button type="primary">View Hours</Button></Popover>,
      }, {
        title: 'Weeks Audited',
        key: 'weeks',
        width: 80,
        render: (text, record, idx) => record.week,
      }, {
        title: 'TA Explanation',
        key: 'explanation',
        width: 250,
        render: (text, record, idx) => <div style={{ overflowY: 'auto', height: '80px' }}>{record.explanation}</div>
      }, {
        title: 'Instructor Review',
        key: 'instructor',
        width: 80,
        render: (text, record, idx) => renderAuditStatus(record.instructor_confirmed),
      }, {
        title: 'Created',
        key: 'created',
        width: 80,
        render: (text, record, idx) => moment(record.created_at).format("MMMM Do YYYY, h:mm:ss a"),
      }, {
        title: 'TA Acknowledged',
        key: 'confirmed',
        width: 80,
        render: (text, record, idx) => record.confirmed_at ? moment(record.confirmed_at).format("MMMM Do YYYY, h:mm:ss a") : "Not reviewed by TA",
      }, {
        title: 'Audit Decision',
        key: 'decision',
        width: 100,
        render: (text, record, idx) => renderAuditStatus(record.status),
      }, {
        title: 'Actions',
        key: 'actions',
        width: 100,
        align: 'right',
        render: this.getActions,
      }];

    return (
      <Content {...this.props} title={semester + " TA Audit"} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "Audit" }]}>
        <Table dataSource={taaudit.filter(el => this.props.semesters.join(",").includes(el.taapplication.semester))} columns={columns} bordered={false} pagination={false} size="small" loading={loading || loading_taapplications} rowKey="id" />
      </Content>
    );
  }
}

class TAHR extends AppComponent {
  state = {

    endpoint_tahr: "/api/student/hr/",
    tahr: [],
    loading: true,
  }

  componentDidMount() {
    this.update();
  }

  update = () => {
    this.doGet(this.state.endpoint_tahr, data => this.setState({ tahr: data, loading: false }));
  }

  getActions = (text, record, idx) => {
    return (
      <TAHREdit {...this.props} record={record} callBack={this.update} />
    );
  }

  render() {
    const { tahr, loading } = this.state;
    const columns = [
      {
        title: 'NUID',
        key: 'nuid',
        render: (text, record, idx) => record.nuid,
        sorter: (a, b) => a.nuid - b.nuid,
      }, {
        title: 'Name',
        key: 'name',
        render: (text, record, idx) => this.print_full_student(record),
      }, {
        title: 'Type',
        key: 'type',
        render: (text, record, idx) => record.permanent ? "Permanent" : this.print_semester(record.till),
      }, {
        title: 'Last Updated By',
        key: 'last_updated_by',
        render: (text, record, idx) => record.log ? record.log[record.log.length - 1].user : "",
      }, {
        title: 'Actions',
        key: 'actions',
        render: this.getActions,
      }];

    return (
      <Content {...this.props} title={"TA HR"} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "TA" }, { text: "HR" }]}>
        <p>The information on this page is <strong>STRICTLY CONFIDENTIAL</strong> and may not be shared with other staff or faculty members. Please confirm with Khoury HR <strong>(before)</strong> what can and cannot be shared with a student.</p>
        <ul>
          <li>If a faculty member asks why a specific student cannot be hired, please only inform them that the student is ineligible</li>
          <li>Escalate to the Associate Dean of Undergrad Education and Experience for undergrads <strong>or</strong> the Associate Dean of Graduate Administration for graduate students regarding questions</li>
        </ul>
        <Divider orientation="left">List of Students who are Ineligible to be a TA</Divider>
        <Table dataSource={ tahr } columns={columns} bordered={ false } pagination={ false } size="small" loading={ loading } rowKey="id" />
        <Divider orientation="left">Add</Divider>
        <TAHRForm {...this.props} finishedHook={this.update} />
      </Content>
    );
  }
}


const TAHREditForm = Form.create({ name: "form_in_modal" })(
  class extends AppComponent {
    render() {
      const { form, record } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

      return (
        <React.Fragment>
          <Form>
            <FormItem {...formItemLayout} label="Updated Reasoning" extra={"Provide a short reasoning"}>
              {getFieldDecorator("reasoning", { rules: [{ required: true, message: "Please input a reasoning" }], initialValue: "" })
                (
                  <TextArea style={{ width: 360 }} rows={4} />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Permanent?" extra="Is this permanent?">
              {getFieldDecorator("permanent", { rules: [{ required: true, message: "Please select if this permanent or not" }], initialValue: record.permanent })
                (<Radio.Group>
                  <Radio style={{ display: 'block' }} value={true}>Yes</Radio>
                  <Radio style={{ display: 'block' }} value={false}>No</Radio>
                </Radio.Group>)}
            </FormItem>
            {!form.getFieldValue("permanent") && (
              <FormItem {...formItemLayout} label="Till" extra={"Please enter a semester until they cannot apply"}>
                {getFieldDecorator("till", { rules: [{ required: true, message: "Please enter a semester" }], initialValue: record.till })
                  (
                    <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                      {this.semester_list().filter(el => el.semestertype != 50).map(el => (
                        <Option key={el.id} value={el.id}>{this.print_semester(el.id)}</Option>
                      ))}
                    </Select>
                  )}
              </FormItem>
            )
            }
          </Form>
        </React.Fragment>
      );
    }
  });

class TAHREdit extends AppComponent {
  state = {
    endpoint: "",
    visible: false,
  }

  reset = () => {
    this.setState({ visible: false });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleUpdate = () => {
    const form = this.formRef.props.form;
    const { record } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.doPatch("/api/student/hr/" + record.id + "/", (data) => { this.reset(), this.props.callBack(); }, JSON.stringify(values));
      } else {
        message.error(err);
      }
    });
  };

  render() {
    const { record } = this.props;
    const { visible } = this.state;
    const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

    return (
      <div>
        <Modal
          destroyOnClose={ true }
          visible={visible}
          title={"Edit " + record.firstname + "'s Record"}
          okText={"Confirm"}
          onOk={() => { this.handleUpdate() }}
          onCancel={() => { this.setState({ visible: false }) }}
          width={600} >
          <p><b>Status:</b> {record.status}</p>
          <p><b>Type:</b> {record.permanent ? "Permanent" : this.print_semester(record.till)}</p>
          <p><b>Edit Log:</b></p>
          <Timeline>
            {record.log.map(entry =>
              <Timeline.Item color="blue"><b>{entry.user}</b>&nbsp;edited on:&nbsp;{moment(entry.submitted_at).format("MMM DD, YYYY")}</Timeline.Item>
            )}
          </Timeline>

          <p>Use the below fields to amend the record. If removing a permanent ban, please select a till semester (can be in the past).</p>
          <TAHREditForm {...this.props} wrappedComponentRef={this.saveFormRef} />
        </Modal>
        <Button onClick={() => this.setState({ visible: true })}>Edit</Button>
      </div>
    );
  }
}

const TAHRForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {

    state = {
      endpoint_students: "/api/student/",
      students: [],
      loading_students: true,
      permanent: false,
      status: "",
      student: "",
      edit_record_visible: false,

      // controlling what parts of the form to show
      skip_visible: false,
      add_student: false,

      types: ["Academic Integrity", "Job Performance", "Job Conduct", "Other"],
    }

    componentDidMount() {
      const { student } = this.props;

      if (student) {
        this.setState({ student: student, skip_visible: true });
      }
    }

    loadStudents = (v) => {
      const { endpoint_students, students } = this.state;
      if (v) {
        this.setState({ loading_students: true, students: [] }, () => this.doGet(endpoint_students + (v ? "?search=" + v : ""), data => this.setState({ students: data, loading_students: false })));
      }
    }

    handleSubmit = () => {
      const { student } = this.state;
      const form = this.props.form;
      form.validateFields((err, values) => {
        if (!err) {
          !values["student"] && this.state.student ? values["student"] = student.id : null;
          this.doPost("/api/student/hr/", () => { this.formReset(); }, JSON.stringify(values));
        } else {
          message.error(err);
        }
      });
    };

    formReset = () => {
      this.setState({ permanent: false, status: "", student: "", skip_visible: false, add_student: false });
      this.props.form.resetFields();
      this.props.finishedHook();
    }

    closeAddStudent = (data) => {
      this.setState({ student: data, add_student: false });
    }

    render() {
      const { students, loading_students, permanent, types, status, add_student, skip_visible, student } = this.state;
      const { form, data } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

      return (
        <React.Fragment>
          <Form>
            {(!add_student && !student) ? (
              <FormItem {...formItemLayout} label="Student" extra={"Please select a student"}>
                <>
                  {getFieldDecorator("student", { rules: [{ required: !add_student, message: "Please select a student" }], initialValue: "" })
                    (
                      <Select showSearch autocomplete="off" style={{ width: 360 }} filterOption={this.filter} onSearch={this.loadStudents} placeholder="Type here to search" notFoundContent={loading_students ? <Spin size="small" /> : null} >
                        {students.map(u => <Option key={u.id} value={u.id}>{this.print_full_student(u)} ({u.nuid})</Option>)}
                      </Select>
                    )}
                  {" "}
                  <Button onClick={() => this.setState({ add_student: true })}>Add Student</Button>
                </>
              </FormItem>) : null}
            {(!add_student && student) && (
              <FormItem {...formItemLayout} label="Student"><p>{this.print_full_student(student)}</p></FormItem>
            )}
            {add_student ? (
              <StudentCreateForm {...this.props} completeHook={this.closeAddStudent} />
            ) : (
              <>
                <FormItem {...formItemLayout} label="Type" extra={"Please select a type"}>
                  {getFieldDecorator("status", { rules: [{ required: true, message: "Please select a type" }], initialValue: "", onChange: event => this.setState({ status: event }) })
                    (
                      <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                        {types.map(el => (
                          <Option key={el} value={el}>{el}</Option>
                        ))}
                      </Select>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Reasoning" extra={"Provide a short reasoning"}>
                  {getFieldDecorator("reasoning", { rules: [{ required: true, message: "Please input a reasoning" }], initialValue: (status == "Academic Integrity") ? "Academic Integrity" : "" })
                    (
                      <TextArea style={{ width: 360 }} rows={4} />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Permanent?" extra="Is this permanent?">
                  {getFieldDecorator("permanent", {
                    rules: [{ required: true, message: "Please select if this permanent or not" }], initialValue: (status == "Academic Integrity") ? true : permanent, onChange: event => this.setState({ permanent: event.target.value })
                  })
                    (<Radio.Group disabled={status == "Academic Integrity"}>
                      <Radio style={{ display: 'block' }} value={true}>Yes</Radio>
                      <Radio style={{ display: 'block' }} value={false}>No</Radio>
                    </Radio.Group>)}
                </FormItem>
                {(!permanent && (status != "Academic Integrity")) && (
                  <FormItem {...formItemLayout} label="Till" extra={"Please enter a semester until they cannot apply"}>
                    {getFieldDecorator("till", { rules: [{ required: true, message: "Please enter a semester" }], initialValue: "" })
                      (
                        <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                          {this.semester_list().filter(el => el.semestertype != 50).map(el => (
                            <Option key={el.id} value={el.id}>{this.print_semester(el.id)}</Option>
                          ))}
                        </Select>
                      )}
                  </FormItem>
                )}
              </>
            )}
            <FormItem {...formItemLayout} label=" " colon={false}>
              <>
                {skip_visible && !add_student && (<Button onClick={() => this.props.finishedHook()}>Skip</Button>)}
                {" "}
                {!add_student && (<Button type="primary" onClick={() => this.handleSubmit()}>Submit</Button>)}
              </>
            </FormItem>
          </Form>
        </React.Fragment>
      );
    }
  });

export { TAAssign, TACollegeOverview, TAIndividual, TAHire, TAAuditReview, TAHR, TAHoursStaffCard };