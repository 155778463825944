import React, { Component } from "react";
import Markdown from 'react-markdown'
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './AppComponent';
import CoordinatorTable from './CoordinatorTable';
import SectionTable from './SectionTable';
import TAHoursTable from './TA/TAHoursTable';
import Content from './Content';
import { add_dividers, format_percent, format_decimal, add_brs, if_empty } from './Utils';

import { PageHeader, Table, Form, Switch, Card, List, Layout, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, message, Typography } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;

const { Text, Title } = Typography;

class Course extends AppComponent {

  getAllCourses = (course_id) => {
    var courses = [course_id];
    if (this.get_course(course_id).previous) {
      return courses.concat(this.getAllCourses(this.get_course(course_id).previous))
    } else {
      return courses;
    }
  }

  state = {
    endpoint: "/api/schedule/",
    sections: [],
    loading: true,

    endpoint_planning: "/api/planning/",
    planning_sections: [],
    loading_planning: true,

    endpoint_charters: "/api/charters/",
    charters: [],
    loading_charters: true,

    endpoint_coordinator: "/api/schedule/coordinator/",
    coordinators: [],
    loading_coordinators: true,

    endpoint_course_prefs: "/api/preferences/course/",
    course_prefs: [],
    loading_course_prefs: true,

    endpoint_enrollment: "/api/schedule/course/" + this.props.match.params.course_id + "/enrollment/",
    enrollment: [],
    loading_enrollment: true,

    endpoint_taapplication: "/api/ta/application/",
    taapplications: [],
    loading_taapplications: true,

    endpoint_tahours: "/api/ta/hours/",
    tahours: [],
    loading_tahours: true,

    chart_semestertype: 10,
    table_semestertype: 0,
    all_campuses: false,
  }

  componentDidMount() {
    this.setState({ all_campuses: this.props.user.groups.map(el => el.name).includes("admin") }, () => {
      this.getData();;
    });
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    const courses = this.getAllCourses(this.props.match.params.course_id).join(",");

    this.doGet(this.state.endpoint +  "?course=" + courses + (this.state.all_campuses ? "" : "&campus=" + this.props.campus),
      data => this.setState({ sections: data, loading: false }));


    this.doGet(this.state.endpoint_coordinator + "?course=" + courses,
      data => this.setState({ coordinators: data, loading_coordinators: false }));
    this.doGet(this.state.endpoint_enrollment + (this.state.all_campuses ? "" : "?campus=" + this.props.campus),
      data => this.setState({ enrollment: data, loading_enrollment: false }));

    if (this.permission("can", "faculty") || this.permission("can", "staff")) {
      this.doGet(this.state.endpoint_charters + "?course=" + this.props.match.params.course_id,
        data => this.setState({ charters: data[0], loading_charters: false }));
    }

    if (this.permission("can", "staff")) {
      this.doGet(this.state.endpoint_taapplication + "?course=" + this.props.match.params.course_id + "&semester=" + this.props.semesters.join(",") + "&state=Hired" + (this.state.all_campuses ? "" : "&campus=" + this.props.campus),
        data => this.setState({ taapplications: data, loading_taapplications: false }));
      this.doGet(this.state.endpoint_tahours + "?taapplication__course=" + this.props.match.params.course_id + (this.state.all_campuses ? "" : "&taapplication__campus=" + this.props.campus) + "&taapplication__semester=" + this.props.semesters.join(","),
        data => this.setState({ tahours: data, loading_tahours: false }));
    }

    if (this.permission("can", "admin")) {
      this.doGet(this.state.endpoint_course_prefs + "?course=" + this.props.match.params.course_id + "&semester=" + this.props.semesters.join(","),
        data => {
          var prefs = data;
          prefs.sort((a, b) => this.instructor_comparator(this.get_instructor(a.faculty), this.get_instructor(b.faculty)));

          this.setState({ course_prefs: prefs, loading_course_prefs: false });
        });
      this.doGet(this.state.endpoint_planning + "?course=" + courses + (this.state.all_campuses ? "" : "&campus=" + this.props.campus) ,
        data => this.setState({ planning_sections: this.deduplicateSections(data), loading_planning: false }));
    }
  }

  handleSelect = prop => (value) => {
    this.setState({ [prop]: value });
  }

  allCampuses = (checked) => {
    const courses = this.getAllCourses(this.props.match.params.course_id).join(",");
    this.setState({ loading: true, all_campuses: checked }, () => {
      this.getData();
    });
  };


  getAllSections = () => {
    const { sections, planning_sections } = this.state;

    const max_semester = Math.max(...sections.map(el => this.get_semester(el.semester).code));
    return sections.concat(planning_sections.filter(el => this.get_semester(el.semester).code > max_semester));
  }

  get_overview_table_data = () => {
    const { planning_sections, enrollment, table_semestertype } = this.state;
    const semesters = this.semester_list();
    semesters.reverse();

    return semesters.filter(el => table_semestertype == 0 || el.semestertype == table_semestertype).map(semester => {
      const mysections = this.getAllSections().filter(section => section.semester == semester.id && !section.deleted);
      const enrollments = enrollment.filter(el => el.semester == semester.id && el.duration >= 0).map(el => el.enrollment);

      return {
        semester: semester.id,
        sections: mysections.length,
        capacity: mysections.reduce((a, r) => a + r.capacity, 0),
        enrollment: mysections.reduce((a, r) => a + r.enrollment, 0),
        max_enrollment: enrollments?.length > 0 ? Math.max(...enrollments) : mysections.reduce((a, r) => a + r.enrollment, 0),
        students_passed: mysections.reduce((a, r) => a + r.students_passed, 0),
        students_failed: mysections.reduce((a, r) => a + r.students_failed, 0),
        students_withdrawn: mysections.reduce((a, r) => a + r.students_withdrawn, 0)
      };
    }).filter(el => el.sections > 0);
  }

  getCoursePrefs = (sem) => {
    const { course_prefs } = this.state;

    return [
      { title: "No", content: if_empty(add_brs(course_prefs.filter(el => el.preference == 1 && el.semester == sem).map(el => this.link_instructor(el.faculty))), "No instructors listed.") },
      { title: "Willing", content: if_empty(add_brs(course_prefs.filter(el => el.preference == 2 && el.semester == sem).map(el => this.link_instructor(el.faculty))), "No instructors listed.") },
      { title: "Ready", content: if_empty(add_brs(course_prefs.filter(el => el.preference == 3 && el.semester == sem).map(el => this.link_instructor(el.faculty))), "No instructors listed.") },
      { title: "Eager", content: if_empty(add_brs(course_prefs.filter(el => el.preference == 4 && el.semester == sem).map(el => this.link_instructor(el.faculty))), "No instructors listed.") },
    ];
  }

  render() {
    const { loading, sections, planning_sections, loading_planning, coordinators, loading_enrollment, enrollment, chart_semestertype, table_semestertype, charters, loading_charters, course_prefs, loading_course_prefs, loading_taapplications, loading_tahours, taapplications, tahours, all_campuses } = this.state;
    const course = this.get_course(this.props.match.params.course_id);
    const courses = this.getAllCourses(this.props.match.params.course_id);
    courses.shift();

    const chars = [
      { title: "Description", content: course.description },
      { title: "Semester Hours", content: course.hours },
      courses?.length > 0 ? { title: "Previous Numbers", content: add_dividers(courses.map(el => this.link_full_course(el))) } : null,
      course.previous ? { title: "Replaces", content: this.link_full_course(course.previous) } : null,
      course.next ? { title: "Replaced By", content: this.link_full_course(course.next) } : null,
      this.permission("can", "staff") ? { title: "Load Count", content: <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={course.loadcount} /> } : null,
      { title: "Crosslist", content: course.crosslist ? this.link_course(course.crosslist) : "None" },
      { title: "Lab", content: course.lab ? this.link_course(course.lab) : "None" },
      { title: "Lab For", content: course.lab_for?.length > 0 ? add_dividers(course.lab_for.map(el => this.link_course(el))) : "None" },
      { title: "Prerequisites", content: course.prerequisites.length ? add_dividers(course.prerequisites.map(el => this.link_course(el))) : "None" },
      { title: "Corequisites", content: course.corequisites.length ? add_dividers(course.corequisites.map(el => this.link_course(el))) : "None" },
      { title: "Prerequisite For", content: this.course_list().filter(c => !c.next && c.prerequisites.includes(parseInt(this.props.match.params.course_id))).length ? add_dividers(this.course_list().filter(c => !c.next && c.prerequisites.includes(parseInt(this.props.match.params.course_id))).map(c => this.link_course(c.id))) : "None" },
      { title: "Schedule", content: course.schedule ? this.print_course_schedule(course.schedule) : "No regular schedule noted" },
      this.permission("can", "staff") ? { title: "TA Ratio", content: course.ta_ratio + " students per TA" } : null,
      { title: "NUPath", content: course.nupath.length ? course.nupath.map(el => this.print_nupath(el)) : "None" },
      { title: "NUCore", content: course.nucore.length ? course.nucore.map(el => this.print_nucore(el)) : "None" },
      { title: "PhD Breadth Area", content: course.area.length ? course.area.map(el => this.print_area(el)) : "None" },
      this.permission("can", "staff") ? { title: "Notes", content: course.notes ? course.notes : "None" } : null,
      this.permission("can", "staff") ? { title: "Coordinators", content: coordinators.length ? <CoordinatorTable {...this.props} coordinators={coordinators} hide_fields={['course']} /> : "None" } : null,
    ].filter(el => el != null);

    const charters_chars = [
      { title: "Textbooks", content: charters && charters.textbooks ? charters.textbooks : "None" },
      { title: "Topics", content: charters && charters.topics ? charters.topics : "None" },
      { title: "Activities", content: charters && charters.activities ? charters.activities : "None" },
      { title: "Outcomes", content: charters && charters.outcomes ? charters.outcomes : "None" },
      { title: "Measurements", content: charters && charters.measurements ? charters.measurements : "None" },
      { title: "Links to recent courses", content: charters && charters.links ? charters.links : "None" },
      { title: "Other notes", content: charters && charters.notes ? charters.notes : "None" },
    ];

    const overviewcolumns = [
      {
        title: 'Semester',
        key: 'semester',
        fixed: 'left',
        width: 100,
        render: (text, record, idx) => this.print_semester(record.semester),
      }, {
        title: 'Sections',
        dataIndex: 'sections',
        key: 'sections',
        align: 'right',
      }, {
        title: 'Capacity',
        children: [{
          title: 'Total',
          dataIndex: 'capacity',
          key: 'capacity_total',
          align: 'right',
          width: 100,
        }, {
          title: 'Average',
          key: 'capacity_average',
          align: 'right',
          render: (text, record, idx) => format_decimal(record.capacity / record.sections, 0),
          width: 100,
        }],
      }, {
        title: 'Enrollment',
        children: [{
          title: 'Total',
          dataIndex: 'enrollment',
          key: 'enrollment_total',
          align: 'right',
          width: 100,
        }, {
          title: 'Max',
          dataIndex: 'max_enrollment',
          key: 'enrollment_max',
          align: 'right',
          width: 100,
        }, {
          title: 'Average',
          key: 'enrollment_average',
          align: 'right',
          render: (text, record, idx) => format_decimal(record.enrollment / record.sections, 0),
          width: 100,
        }],
      }, {
        title: 'Outcomes',
        children: [{
          title: 'Passed',
          key: 'students_passed',
          align: 'right',
          render: (text, record, idx) => format_percent(record.students_passed / record.max_enrollment, 0),
          width: 100,
        }, {
          title: 'Failed',
          key: 'students_failed',
          align: 'right',
          render: (text, record, idx) => format_percent(record.students_failed / record.max_enrollment, 0),
          width: 100,
        }, {
          title: 'Withdrawn',
          key: 'students_withdrawn',
          align: 'right',
          render: (text, record, idx) => format_percent(record.students_withdrawn / record.max_enrollment, 0),
          width: 100,
        }, {
          title: 'Dropped',
          key: 'students_dropped',
          align: 'right',
          render: (text, record, idx) => (record.students_passed == 0 && record.students_failed == 0 && record.students_withdrawn == 0) ? format_percent(0, 0) : format_percent((record.max_enrollment - record.students_passed - record.students_failed - record.students_withdrawn) / record.max_enrollment, 0),
          width: 100,
        }],
      }];

    const overview_data = this.get_overview_table_data();
    const myenrollment = enrollment.filter(el => (chart_semestertype == 0 || this.get_semester(el.semester).semestertype == chart_semestertype) && (el.duration <= 30)).map(el => { el.semestername = this.get_semester(el.semester).name; return el });

    const cols = {
      enrollment: {
        min: 0,
      },
      duration: {
        max: 30,
      }
    };

    return (
      <Content {...this.props} title={this.print_full_course(course.id)} page_title={this.print_course(course.id)} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { link: "/teaching/courses", text: "Courses" }, { text: this.print_course(course.id) }]}>
        <>
          <span style={{ float: 'right' }} >
            <Text strong> Show all campuses &nbsp;</Text>
            <Switch checked={all_campuses} loading={loading} onChange={this.allCampuses} />
          </span>
          <Divider orientation="left">Characteristics</Divider>
          <List
            grid={this.grid}
            dataSource={chars}
            renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
          />
        </>

        {this.permission("can", "faculty") || this.permission("can", "staff") ? (
          <React.Fragment>
            <Divider orientation="left">Charters</Divider>
            <List
              grid={this.grid}
              dataSource={charters_chars}
              renderItem={item => (<List.Item><Card size="small" title={item.title}><Markdown source={item.content} /></Card></List.Item>)}
            />
          </React.Fragment>
        ) : null}

        <Divider orientation="left">Semester Overview</Divider>
        <Form layout="inline">
          <FormItem label="Semester Type">
            <Select style={{ width: 200 }} value={table_semestertype} onChange={this.handleSelect('table_semestertype')}>
              <Option key={0} value={0}>All</Option>
              {this.semestertype_list().map(el => (<Option key={el.id} value={el.id}>{this.print_semestertype(el.id)}</Option>))}
            </Select>
          </FormItem>
        </Form>
        <Table columns={overviewcolumns} dataSource={overview_data} scroll={{ x: 800 }} loading={this.state.loading} bordered={false} pagination={false} size="small" rowKey="semester" />

        <Divider orientation="left">Enrollment History</Divider>
        <Form layout="inline">
          <FormItem label="Semester Type">
            <Select style={{ width: 200 }} value={chart_semestertype} onChange={this.handleSelect('chart_semestertype')}>
              <Option key={0} value={0}>All</Option>
              {this.semestertype_list().map(el => (<Option key={el.id} value={el.id}>{this.print_semestertype(el.id)}</Option>))}
            </Select>
          </FormItem>
        </Form>
        <Chart height={400} data={myenrollment} scale={cols} forceFit>
          <Legend />
          <Axis name="duration" />
          <Axis name="enrollment" />
          <Geom type="line" position="duration*enrollment" size={2} color="semestername" />
        </Chart>

        <Divider orientation="left">Section History</Divider>
        <SectionTable {...this.props} data={this.getAllSections()} loading={this.state.loading} show_fields={["campus"]} hide_fields={["course", "actions"]} />

        {this.permission("can", "staff") && this.props.semesters.map(sem => (
          <React.Fragment key={sem}>
            <Divider orientation="left">{this.print_semester(sem)} TA Hours</Divider>
            <TAHoursTable {...this.props} loading={loading_taapplications || loading_tahours} taapplications={taapplications.filter(el => el.semester == sem)} tahours={tahours} tasemester={sem} />
          </React.Fragment>))}

        {this.permission("can", "admin") && this.props.semesters.map(sem => (
          <React.Fragment key={sem}>
            <Divider orientation="left">{this.print_semester(sem)} Teaching Preferences </Divider>
            {loading_course_prefs ? (<Spin tip="Loading instructor preferences" />) : <List
              grid={this.grid}
              dataSource={this.getCoursePrefs(sem)}
              renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
            />}
          </React.Fragment>))}
      </Content>
    );
  }
}

export default Course;
