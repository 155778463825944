import React, { Component } from "react";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import { oxford } from './Utils';

import { Table, Form, Switch, Layout, Tooltip, Icon } from 'antd';
const FormItem = Form.Item;

class ClassSizeTable extends AppComponent {
  get_section_size = (s) => {
    const { sections } = this.props;

    const crosslist = s.crosslist ? sections.find(el => el.id == s.crosslist) : null;

    if (s.crosslist && crosslist == null) {
      console.log("Could not find pair of");
      console.log(s);
    }

    const current = s.enrollment != null ? s.enrollment + (crosslist ? crosslist.enrollment : 0) : undefined;
    var capacity = s.capacity ? (s.enrollment ? Math.max(s.enrollment, s.capacity) : s.capacity) + (crosslist ? crosslist.enrollment ? Math.max(crosslist.enrollment, crosslist.capacity) : crosslist.capacity : 0) : undefined;
    const counts = s.ugrad_stats || (crosslist ? crosslist.ugrad_stats : false);

    return {
      ugrad_stats: counts,
      weight: (crosslist ? 0.5 : 1),
      current: current,
      capacity: capacity,
      course: s,
      crosslist: crosslist
    };
  }

  get_size_bucket = (size) => {
    return typeof size !== 'undefined' ?
      (size < 20 ? 0 :
        size < 30 ? 1 :
          size < 40 ? 2 :
            size < 50 ? 3
              : 4)
      : 5;
  }

  sum_sections = (sections) => {
    return sections.reduce((r, a) => r += a.weight, 0);
  }

  get_class_size_data = () => {
    const { sections, semester_specific } = this.props;

    const start = [{
      category: "<19",
      current: [],
      capacity: [],
      goal: this.get_semester(semester_specific).under20goal ? this.get_semester(semester_specific).under20goal : null,
    }, {
      category: "20-29",
      current: [],
      capacity: [],
      goal: null,
    }, {
      category: "30-39",
      current: [],
      capacity: [],
      goal: null,
    }, {
      category: "40-49",
      current: [],
      capacity: [],
      goal: null,
    }, {
      category: ">49",
      current: [],
      capacity: [],
      goal: this.get_semester(semester_specific).over49goal ? this.get_semester(semester_specific).over49goal : null,
    }, {
      category: "Unk.",
      current: [],
      capacity: [],
      goal: null,
    }];

    var result = sections.filter(el => el.semester == semester_specific).map(el => this.get_section_size(el)).filter(el => el.ugrad_stats).reduce((r, a) => { r[this.get_size_bucket(a.current)].current.push(a); r[this.get_size_bucket(a.capacity)].capacity.push(a); return r; }, start);

    return result;
  }

  get_additional_allowed = (goal, current, total, invert) => {
    const max = Math.floor(total * goal / 100.0);
    const additional = (current - max) * (invert ? -1 : 1);
    if (additional > 1) {
      return additional + " additional sections allowed";
    } else if (additional == 1) {
      return additional + " additional section allowed";
    } else if (additional == 0) {
      return "No additional sections allowed";
    } else if (additional == -1) {
      return (-1 * additional) + " section over limit!";
    } else {
      return (-1 * additional) + " sections over limit!";
    }
  }

  render() {
    const { semester_specific, loading } = this.props;

    const data = this.get_class_size_data();
    const all = data.reduce((r, a) => r.concat(a.current), []);

    const columns = [
      {
        title: '',
        key: 'category',
        render: (text, record, idx) => record.category,
      }, {
        title: 'Goal',
        key: 'goal',
        align: 'right',
        width: 75,
        render: (text, record, idx) => record.goal ? <NumberFormat displayType="text" decimalScale={1} fixedDecimalScale={true} value={record.goal * 1.0} prefix={record.category == "<19" ? ">" : "<"} suffix="%" /> : null,
      }, {
        title: 'Current',
        key: 'current',
        align: 'right',
        width: 75,
        render: (text, record, idx) => <Tooltip placement="top" title={<React.Fragment>{this.sum_sections(record.current) + " sections out of " + this.sum_sections(all)}<br />{record.goal ? this.get_additional_allowed(record.goal, this.sum_sections(record.current), this.sum_sections(all), record.category == ">49") : null}</React.Fragment>}><NumberFormat displayType="text" decimalScale={1} fixedDecimalScale={true} value={this.sum_sections(record.current) * 100.0 / this.sum_sections(all)} suffix="%" /></Tooltip>,
      }, {
        title: 'Capacity',
        key: 'capacity',
        width: 75,
        align: 'right',
        render: (text, record, idx) => <Tooltip placement="top" title={<React.Fragment>{this.sum_sections(record.capacity) + " sections out of " + this.sum_sections(all)}<br />{record.goal ? this.get_additional_allowed(record.goal, this.sum_sections(record.capacity), this.sum_sections(all), record.category == ">49") : null}</React.Fragment>}><NumberFormat displayType="text" decimalScale={1} fixedDecimalScale={true} value={this.sum_sections(record.capacity) * 100.0 / this.sum_sections(all)} suffix="%" /></Tooltip>,
      },
    ];

    return <Table {...this.props} dataSource={data} columns={columns} loading={loading} bordered={false} pagination={false} size="small" rowKey={(record) => record.category} />;
  }
}

export default ClassSizeTable;