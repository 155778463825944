import React, { Component } from "react";
import AppComponent from "../AppComponent";
import Preference from "../Preference";
import Content from "../Content";

import { Table, Icon, Descriptions, Divider } from "antd";

const ALIGNBRIDGECOMPLETE = {
  Y: "Yes",
  N: "No",
  NA: "N/A"
}

class FacultyApplicationReview extends AppComponent {
  state = {
    endpoint_projects: "/api/researchapprenticeship/project/",
    loading_projects: true,
    projects: [],

    endpoint_prefs: "/api/researchapprenticeship/faculty/preferences/",
    prefs: [],
    loading_prefs: true,

    editable: true,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.semester !== this.props.semester
    ) {
      this.getData();
    }
  }

  getData = () => {
    this.setState({ loading_projects: true }, () => this.doGet(this.state.endpoint_projects + "?semester=" + this.props.semesters[0], data => { this.setState({ projects: data, loading_projects: false }) }));
    this.getPrefs();
  };

  getPrefs = () => {
    this.doGet(
      this.state.endpoint_prefs +
      "?semester=" +
      this.props.semesters[0],
      data => this.setState({ prefs: data, loading_prefs: false })
    );
  };

  options = [
    { id: 4, text: "1st" },
    { id: 3, text: "2nd" },
    { id: 2, text: "3rd" },
    { id: 5, text: "Reset" }
  ];

  render_application_pref = (record, project) => {
    const {
      loading_prefs,
      loading_projects,
      prefs,
      editable,
      projects,
    } = this.state;

    //finding the number of applicants per project
    const projectInfo = projects.find(proj => proj.id == project)
    const numberOfApplicants = projectInfo?.applications?.length

    //find the application where project_rank matches the pref application
    const pref = prefs.find(el => el.application == record.project_rank);

    //filter preferences based on the current project
    const projectPrefs = prefs.filter(a => a.project === project);

    //generate a list of unselectable preference options based on the current project
    const unselectable = [...Object.keys(
      projectPrefs.reduce((r, a) => {
        if (a.preference > 1 && a.application != record.project_rank) {
          r[a.preference] = 1;
        }
        return r;
      }, {})

    ), ...numberOfApplicants == 1 ? [3, 2] : numberOfApplicants == 2 ? [2] : []];


    return (
      <Preference
        {...this.props}
        key={record.id + "-" + (pref ? pref.id : null)}
        application={record.project_rank}
        editable={editable && !loading_projects && !loading_prefs}
        value={pref}
        defaultValue={1}
        unselectable={unselectable}
        options={this.options} //.slice(0, projectPrefs.length)}
        onChange={this.getPrefs}
        endpoint="/api/researchapprenticeship/faculty/preferences/"
        addNewData={f => (f["application"] = record.project_rank, f["project"] = projectInfo.id)}
      />
    )
  };

  render_application_description = (record) => {
    return (
      <div style={{ margin: 0 }}>
        <Descriptions >
          <Descriptions.Item label="Reasoning" span={4}>{record.reasoning}</Descriptions.Item>
          <Descriptions.Item label="Campus" span={1}>{this.print_campus(record.student.campus)}</Descriptions.Item>
          <Descriptions.Item label="Align Bridge Coursework Completed" span={2}>{ALIGNBRIDGECOMPLETE[record.align_bridge_complete]}</Descriptions.Item>
          <Descriptions.Item label="GPA" span={1}>{record.gpa}</Descriptions.Item>
          <Descriptions.Item label="Attached Files" span={2}>
            {record.files.map((el, index) => (
              <span key={index}>
                <a onClick={() => this.openPDF(el.attachment, el.file_type + ".pdf")}>
                  <Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />
                  {el.file_type + ".pdf"}
                </a>
                {"\u00a0\u00a0"}
              </span>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="Underrepresented Groups in Tech" span={4}>{record.underrepresented_groups.map(el => el).join(', ')}</Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  render() {
    const { projects, prefs, loading_projects, loading_prefs } = this.state;

    const columns = [
      {
        title: "Applicant",
        key: "student",
        width: 300,
        render: (text, record, idx) => record.student.lastname + ", " + (record.student.firstname_preferred ? record.student.firstname_preferred : record.student.firstname),
      },
      {
        title: "Preference",
        key: "faculty",
        width: 200,
        render: (text, record, idx) => this.render_application_pref(record, record.project),
      },
    ];

    return (
      <Content
        {...this.props}
        title={"MS Research Apprenticeship Application Review for " + this.props.semester}
        breadcrumbs={[{ link: "/employee", text: "Employee" }, { text: "Research Apprenticeship" }, { text: "Application Review" }]}
      >
        <React.Fragment>
          {(this.get_research_apprenticeship_application_status(this.props.semesters) ||
            !this.get_research_apprenticeship_faculty_review_status(this.props.semesters)) ? (
            <Divider orientation="center">
              {" "}
              Application Ranking Period for {this.props.semester} is Closed
            </Divider>
          )
            :
            (
              <React.Fragment>
                <div>
                  <p>
                    Welcome to the MS Research Apprenticeship Application Review. Below is a list of applications for your Research Apprenticeship Project(s).
                    To access detailed application information, click the plus sign next to the applicants' names.                 While we highly recommend interviewing your top candidates, it is not mandatory to interview every applicant.
                  </p>
                  <p><b> Please rank your top three applicants for each project.</b> When possible, please
                    prioritize applicants from underrepresented groups in the tech community.
                  </p>
                </div>
                <br></br>
              </React.Fragment>
            )}
        </React.Fragment>

        {!this.get_research_apprenticeship_application_status(this.props.semesters) &&
          this.get_research_apprenticeship_faculty_review_status(this.props.semesters) &&
          <React.Fragment>
            {projects?.length > 0 ?
              projects.map((proj) => (
                <React.Fragment key={proj.id}>
                  <Divider orientation="left">{proj.name}</Divider>
                  {proj.applications.length > 0 ?
                    <Table dataSource={proj.applications} rowKey={record => record.id} columns={columns} loading={loading_projects || loading_prefs} pagination={false} bordered={false}
                      expandedRowRender={(record) =>
                        this.render_application_description(record)}>
                    </Table>
                    :
                    <i style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>No applicants</i>}
                </React.Fragment>
              ))
              :
              <Divider orientation="center"> No projects to show</Divider>

            }
          </React.Fragment>
        }
      </Content>
    );
  };
};

export { FacultyApplicationReview };
