import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import moment from "moment-timezone";
import { renderStatus, text_max, oxford, add_brs } from "../../Utils";
import { WorkflowSubmit } from "../WorkflowSubmit";
import { WorkflowTable } from "../WorkflowTable";

import { Popover, Table, Form, Input, Divider, Checkbox, Select, Button, InputNumber, message, Radio, Switch, Typography, Modal, Alert, Descriptions, Upload, Icon } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const ParentalReductionRequestForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
	state = {
	  year: null,
	  notes: null,
	  admin_stipend: false,
	  tenure_extension: true,
      file: null,
	  fileList: [],
	}
	
	formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

	componentDidMount() {
	  this.reset();
	}
	
	reset = () => {
	  this.setState({ notes: "", semester: null, others: {}, loadcount: null }); 
	  this.props.form.resetFields();
	}
  	
	handleSubmit = () => {
	  const { semesters, user } = this.props;
	  const { notes, semester, loadcount, tenure_extension } = this.state;
    
		const data = { notes: notes, semester: semester, instructor: user.instructor, loadcount: loadcount, tenure_extension: this.is_ttt() ? tenure_extension : null };
		console.log(data);
		this.props.onSubmit(data, this.reset);
	}

	is_ttt = () => {
		const instructor = this.props.user.instructor;

		var year = this.props.semester_list[this.props.semesters[0]].name;
		year = parseInt(year.substring(year.length - 4));
		
		// Only show if they are tenure-track and have a Khoury tenure home as their most recent appointment
		if (instructor && this.props.instructor_list[instructor].ranks && this.props.instructor_list[instructor].ranks.length > 0) {
		  const ranks = this.props.instructor_list[instructor].ranks;
		  if (ranks[ranks.length - 1].tenure_home && (this.props.college_list[ranks[ranks.length - 1].tenure_home].code == "CS")) {
			if (this.props.instructorrank_list[ranks[ranks.length - 1].rank].subtype.subtype == "Tenure-Track") {
			  return true;
			}	 
		  }
		} 

		return false;
	}
		
	render() {
	  const { visible, onCancel, onCreate, form, item, sections, campus, user } = this.props;
	  const { getFieldDecorator } = form;
      const { semester, loadcount } = this.state;
    
      const semester_list = this.semester_list().filter(el => (el.speed == 1) && (moment(el.startdate, "YYYY-MM-DD") > moment()) && (moment(el.startdate, "YYYY-MM-DD") < moment().add(1, 'years'))).reverse().map(el => el.id);
  
	  return (
		<>
		  <p>Please use the form below if you wish to request parental teaching relief in accordance with the University policy.</p>
      
		  <Form>
			<FormItem {...this.formItemLayout} label="Semester">
			  {getFieldDecorator('semester', {
				rules: [{ required: true, message: 'Please select the semester you wish to request parental teaching relief for.' }],
				onChange: (event) => { this.setState({ semester: event }) },
			  })(<Select showSearch style={{ width: 180 }}>
				  { semester_list.map(el => <Option key={ el } value={ el }>{ this.print_semester(el) }</Option> ) }
				</Select>
			  )}
			</FormItem>

			<FormItem {...this.formItemLayout} label="Normal Semester Course Load">
			  {getFieldDecorator('loadcount', {
				rules: [{ required: true, message: 'Please enter the number of courses you would normally teach in the selected semester.' }],
				onChange: (event) => { this.setState({ loadcount: event }) },
              })(<InputNumber min={1} max={3} />)}
			</FormItem>

			{ this.is_ttt() ? 
				<FormItem {...this.formItemLayout} label="Tenure Clock Extension?">
				{getFieldDecorator('tenure_extension', {
					onChange: (event) => { console.log(event); this.setState({ tenure_extension: event.target.checked }) },
				})(<Checkbox>Please request to extend my tenure clock by one year.</Checkbox>)}
				</FormItem>
			: null }

            <FormItem {...this.formItemLayout} label="Notes" extra="You can optionally include any additional information here, such as the anticipated date of the birth or adoption event as well as any information about previous parental teaching relief received.">
                {getFieldDecorator('notes', {
                initialValue: "",
                onChange: (event) => { this.setState({ notes: event.target.value }) },
                })(<TextArea rows={ 4 } />
                )}
            </FormItem>

            <FormItem {...this.formItemLayout} label=" " colon={ false }><Button type="primary" onClick={ this.handleSubmit } disabled={ !semester }>Submit</Button></FormItem>
		  </Form>
		</>
	  );
	}
  }
);

class ParentalReductionRequestSearchForm extends AppComponent {
  render() {
	const { form } = this.props;
    const semester_list = this.semester_list().filter(el => (el.speed == 1)).reverse();
	
	return (
	  <>
		<FormItem {...this.formItemLayout} label="Semester" extra="Optionally select the semester of the research reduction request.">
		  {form.getFieldDecorator("parentalreductionrequest__semester", {
			onChange: this.props.onSearchChange,
		  })(<Select style={{ width: 200 }} allowClear>
			{ semester_list.map(s => <Option key={ s } value={ s }>{ this.print_semester(s.id) }</Option> )}
		  </Select>)}
		</FormItem>
	  </>
	)
  }
}

class ParentalReductionRequest extends AppComponent {
  render() {
   	const { record } = this.props;
   	const item = record.record;
	 
	return (
	  <Descriptions bordered title={ "Parental teaching relief request" }>
  		<Descriptions.Item label="Requestor">{ this.link_full_instructor(item.instructor) }</Descriptions.Item>
  		<Descriptions.Item label="Submitted">{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
  		<Descriptions.Item label="Semester">{ this.print_semester(item.semester) }</Descriptions.Item>
  		<Descriptions.Item label="Reduction">{ item.loadcount }</Descriptions.Item>
  		<Descriptions.Item label="Tenure Extension">{ item.tenure_extension ? "Yes" : "No" }</Descriptions.Item>
  		<Descriptions.Item label="Notes">{ item.notes ? item.notes : <i>No notes provided</i> }</Descriptions.Item>
    </Descriptions>
	);
  }
}

class ParentalReductionRequestTable extends WorkflowTable {
  get_columns = () => {      
	return [{
	  title: "Semester",
	  align: 'left',
	  render: (text, record, idx) => this.print_semester(record.record.semester),
	}, {
	  title: "Notes",
	  align: 'left',
	  render: (text, record, idx) => record.record.notes
	}];
  }
}

class ParentalReductionRequestOverview extends WorkflowSubmit {
  state = {
  	endpoint: "/api/load/parentalreduction/",
  	visible: true,
  }

  getData = () => {
	this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
	return "Parental Teaching Relief";
  }

  get_workflowtype = () => {
	return "parentalreductionrequest";
  }

  get_name_plural = () => {
	return "Parental Teaching Reliefs";
  }
  
  get_breadcrumbs = () => {
	return [{ link: "/faculty", text: "Faculty" }, { text: "Parental Teaching Relief" }];
  }
  
  get_form = (func) => {
	return <ParentalReductionRequestForm {...this.props} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
	return <ParentalReductionRequest {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
	this.doPost(this.state.endpoint, () => { this.getData(); func(); }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
	return (
	  <div>
        <p>In accordance with University policy, all full-time faculty members may request one semester of teaching relief due to the birth or adoption of a child.  Receiving this benefit requires the Dean to request approval from the Provost's Office, and this form is intended to initiate that process.</p>
	  </div>
	);
  }  
}

export { ParentalReductionRequestOverview, ParentalReductionRequest, ParentalReductionRequestTable, ParentalReductionRequestSearchForm };