import React, { Component } from "react";
import { Link } from "react-router-dom";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import SectionTable from './SectionTable';
import Content from './Content';
import { add_dividers, if_empty, add_brs } from "./Utils";

import { PageHeader, Alert, Table, Form, Switch, Tooltip, Icon, Layout, Menu, Dropdown, Spin, Calendar, Col, Statistic, Row, List, Card, Divider, Badge, Tag, Breadcrumb, message } from 'antd';

class PositionCard extends AppComponent {
  render() {    
    const instructor = this.props.instructor || this.props.user.instructor;
    const year = this.props.year || this.get_year();
    const positionyears = this.positionyear_list().filter(cy => cy.year == year && cy.holders.find(i => i == instructor));
    
    return ( <Card size="small" title={ "Your positions in " + this.print_year(year) }>{ positionyears.length ? add_brs(positionyears.map(el => [( <Link key={ el.position } to={ this.getLink("/employee/positions/" + el.position) }>{ this.print_position(el.position) }</Link>)])) : <i>No positions found</i> }</Card> );
  }
}

class Position extends AppComponent {
  get_holder_table = () => {
    const data = this.positionyear_list().filter(cy => cy.position == this.props.match.params.position_id);
    
    const columns = [
      {
        title: 'Year',
        key: 'year',
        width: 240,
        render: (text, record, idx) => this.print_year(record.year),
      }, {
        title: 'Holder(s)',
        key: 'holders',
        render: (text, record, idx) => add_dividers(record.holders.map(this.print_instructor)),
      }];

    return <Table {...this.props} dataSource={ data } columns={ columns } scroll={{ x: 800 }} bordered={ false } pagination={ false } size="small" rowKey="year" key={ "table-all" } />
  }

  render() {

    return (
      <Content {...this.props} title={ this.print_position(this.props.match.params.position_id) } breadcrumbs={ [{ link: "/employee", text: "Employee" }, { link: "/employee/positions", text: "Positions" }, { text: this.print_position(this.props.match.params.position_id) }] }>
        <p>{ this.print_position_description(this.props.match.params.position_id) }</p>
        <Divider orientation="left">Holder History</Divider>
        { this.get_holder_table() }
      </Content>
    );
  }
}

export default Position;
export { PositionCard };