import React, { Component } from "react";
import moment from "moment-timezone";
import AppComponent from '../../AppComponent';
import Content from '../../Content';
import CustomTabs from "../../CustomTabs";
import { add_dividers, get_check, get_nocheck, format_nuid, get_check_nocheck, get_pausecheck, oxford, get_merit_tag, add_dividers_horiz, Spin, get_merit_score, add_brs, if_empty, isEmpty } from "../../Utils";

import { PageHeader, Layout, Dropdown, Menu, Breadcrumb, Icon, Input, Select, DatePicker, Table, Tabs, Tag, Tooltip, Radio, Button, Divider, Form, Switch, message, Modal, List, Card, InputNumber, Alert, Upload, Steps } from 'antd';
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const TabPane = Tabs.TabPane;
const { SubMenu } = Menu;
const { Step } = Steps;
const { Sider, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const REVIEW_SCORES = ["Exemplary", "Good", "Needs Improvement"];

const MeritReviewerForm = Form.create({ name: 'form_not_in_modal' })(
  class extends AppComponent {
    state = {
      changed: false,
    }

    reset = () => {
      this.props.form.resetFields();
    }

    handleSubmit = (isComplete) => {
      const { handleCreateUpdate, currentFacultySelected, getMeritYear, deanReview} = this.props;
      const form = this.props.form;
      const year = getMeritYear()
      form.validateFields((err, values) => {
        if (!err) {
          if (isComplete) {
            const invalidFields = Object.keys(values).filter(
              key => values[key] === null || values[key] === undefined || values[key] === ''
            );
    
            if (invalidFields.length > 0) {
              const errorMessage = `All fields are required for submission. You may save your progress.`;
              message.error(errorMessage);

              invalidFields.forEach(field => {
                form.setFields({
                  [field]: {
                    value: values[field],
                    errors: [new Error('Field is required for submission.')], 
                  },
                });
              });
              return;
            }
          }

          const deanComplete = isComplete && deanReview
          const dean_review_started = deanReview

          const data = { ...values, "reviewee": currentFacultySelected, "year": year, "is_complete": isComplete, "dean_complete": deanComplete, "dean_review_started": dean_review_started};

          handleCreateUpdate(() => {
            this.reset();
          }, data);
        } else {
          message.error(err);
        }
      });
    };

    render() {
      const { review, form, disabled, deanReview} = this.props;
      const { getFieldDecorator } = form;
      const { changed } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Form>
          <FormItem {...formItemLayout} label="Teaching Score" extra="Please select a teaching score.">
            {getFieldDecorator('score_teaching', {
              rules: [{ required: false, message: 'Please select a score.' }],
              initialValue: review ? review.score_teaching : "",
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 360 }} disabled={disabled}>
              {REVIEW_SCORES.map(el => <Option key={el} value={el}>{el}</Option>)}
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Rationale" extra="Please provide rationale for the selected score.">
            {getFieldDecorator('rationale_teaching', {
              rules: [{ required: false, message: 'Please provide rationale.' }],
              initialValue: review?.rationale_teaching,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={12} disabled={disabled} style={{ width: 360 }} />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Service Score" extra="Please select a service score.">
            {getFieldDecorator('score_service', {
              rules: [{ required: false, message: 'Please select a score.' }],
              initialValue: review?.score_service,
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 360 }} disabled={disabled}>
              {REVIEW_SCORES.map(el => <Option key={el} value={el}>{el}</Option>)}
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Rationale" extra="Please provide rationale for the selected score.">
            {getFieldDecorator('rationale_service', {
              rules: [{ required: false, message: 'Please provide rationale.' }],
              initialValue: review?.rationale_service,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={12} disabled={disabled} style={{ width: 360 }} />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Impact Score" extra="Please select an impact score.">
            {getFieldDecorator('score_impact', {
              rules: [{ required: false, message: 'Please select a score.' }],
              initialValue: review?.score_impact,
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 360 }} disabled={disabled}>
              {REVIEW_SCORES.map(el => <Option key={el} value={el}>{el}</Option>)}
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Rationale" extra="Please provide rationale for the selected score.">
            {getFieldDecorator('rationale_impact', {
              rules: [{ required: false, message: 'Please provide rationale.'}],
              initialValue: review?.rationale_impact,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={12} disabled={disabled} style={{ width: 360 }} />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Professional Development Score" extra="Please select a professional development score.">
            {getFieldDecorator('score_professional_development', {
              rules: [{ required: false, message: 'Please select a score.' }],
              initialValue: review?.score_professional_development,
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 360 }} disabled={disabled}>
              {["Good", "Needs Improvement"].map(el => <Option key={el} value={el}>{el}</Option>)}
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Rationale" extra="Please provide rationale for the selected score.">
            {getFieldDecorator('rationale_professional_development', {
              rules: [{ required: false, message: 'Please provide rationale.' }],
              initialValue: review?.rationale_professional_development,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={12} disabled={disabled} style={{ width: 360 }} />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label=" " colon={false}>
            <Button type="primary" onClick={() => this.handleSubmit(false)} disabled={deanReview ? false : !changed}>{"Save Progress"}</Button>
            <span style={{ marginLeft: '8px' }}></span>
            <Button type="primary" onClick={() => this.handleSubmit(true)} disabled={deanReview ? false : !changed}>{deanReview ? "Submit" : review?.is_complete ? "Resubmit" : "Submit"}</Button>
          </FormItem>
        </Form>
      );
    }
  }
);

export default MeritReviewerForm;