import React from "react";
import AppComponent from "../../AppComponent";
import { isEmpty } from "../../Utils";
import { OfficeHoursButton } from "../../ExportDataToApps";

import { Form, Input, Select, Button, message, Alert } from "antd";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const TempAccessRequestForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {
    state = {
      endpoint: "/api/tempaccess/ta/",
      submitted: false,
    };

    handleSubmit = (e) => {
      e.preventDefault();
      const { endpoint } = this.state;
      const { form } = this.props;

      form.validateFields((err, values) => {
        if (err) { return; }

        const data = values;
        this.doPost(
          endpoint,
          (data) => {
            if (data) {
              message.success("Your request has been submitted.");
              this.setState({ submitted: true });
            }
          },
          JSON.stringify(data)
        );
      });
    };

    render() {
      const { submitted } = this.state;
      const { form, ta_requests } = this.props;
      const { getFieldDecorator, getFieldValue } = form;

      const REQUEST_REASON_TYPES = {
        "TA application": "Teaching Assistant Application",
        "Student petition": "Student Petition Access",
        "Office hours": "Office Hours Login",
        Other: "Other",
      };

      const taRequest = getFieldValue("request_type") === "TA application";
      const studentPetitionRequest = getFieldValue("request_type") === "Student petition";
      const OfficeHoursLogin = getFieldValue("request_type") === "Office hours";
      const otherRequest = getFieldValue("request_type") !== "Other";

      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true,
      };

      return (
        <div>
          {submitted ? (
            <Alert
              message="We will process your request. You may not submit a request of this kind again for 90 days."
              type="info"
            ></Alert>
          ) : (
            <Form onSubmit={this.handleSubmit}>
              <FormItem
                {...formItemLayout}
                className="login-left"
                label="Access Request"
                extra="Please select why you would like access"
              >
                {getFieldDecorator("request_type", {
                  rules: [
                    { required: true, message: "Please select an option" },
                  ],
                })(
                  <Select>
                    {Object.keys(REQUEST_REASON_TYPES).map((el) => (
                      <Option key={el} value={el}>
                        {REQUEST_REASON_TYPES[el]}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              {getFieldValue("request_type") && (
                <>
                  {taRequest && isEmpty(ta_requests) ? (
                    <>
                      <p>
                        Teaching Assistant positions for Khoury classes are
                        generally reserved for Khoury students, and requests are
                        only accepted in exceptional cases. If you believe you
                        have such a case, please submit the form below.
                      </p>
                      <p>
                        Please provide a justification for accessing the
                        Teaching Assistant portal. You can submit one request
                        every 90 days. If approved, you'll receive an email
                        notification and can re-login. If you continue to see
                        the{" "}
                        <i>
                          We are unable to determine your affiliation to Khoury
                        </i>{" "}
                        screen after receiving an email, your request was
                        denied.
                      </p>
                      <FormItem
                        {...formItemLayout}
                        label="Reasoning"
                        className="login-left"
                        extra="Please provide context for your request."
                      >
                        {getFieldDecorator("justification", {
                          rules: [
                            {
                              required: true,
                              message: "Please provide context.",
                            },
                          ],
                        })(<TextArea style={{ width: 360 }} rows={4} />)}
                      </FormItem>
                      <Button type="primary" onClick={this.handleSubmit}>
                        Submit
                      </Button>
                    </>
                  ) : taRequest && !isEmpty(ta_requests) ? (
                    <Alert
                      message="You have requested access to TA applications within the last 90 days. If approved, you'll receive an email notification and can re-login. If not approved, please wait until the next cycle to apply again."
                      type="error"
                    />
                  ) : studentPetitionRequest ? (
                    <div>
                      <p>
                        We are working on implementing a request form for access
                        to student petitions. Please continue to email the
                        Khoury Advising team with your petition information.{" "}
                      </p>
                    </div>
                  ) : OfficeHoursLogin ? (
                    <div>
                      <OfficeHoursButton {...this.props} />
                    </div>
                  ) :  (
                    <div>
                      <p>
                        You will need to contact{" "}
                        <a href="mailto:khoury-admin@ccs.neu.edu">
                          the Khoury Admin Site administrators
                        </a>{" "}
                        and include your name, NUID, major, and why you are
                        requesting access.
                      </p>
                      <p>
                        <b>
                          All Teaching Assistant requests must go through the TA
                          process and <u>will not</u> be considered through
                          email. The Khoury Admin Site administrators do not
                          approve or deny these requests.
                        </b>
                      </p>
                      <p style={{ fontSize: "10px" }}>
                        <i>
                          Note: All requests must come from a Northeastern
                          affiliated e-mail, such as @northeastern.edu.
                        </i>
                      </p>
                    </div>
                  )}
                </>
              )}
            </Form>
          )}
        </div>
      );
    }
  }
);

class AccessRequest extends AppComponent {
  state = {
    endpoint: "/api/tempaccess/ta/",
    loading_requests: true,
    ta_requests: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.doGet(this.state.endpoint, (data) =>
      this.setState({ ta_requests: data, loading_requests: false })
    );
  };

  render() {
    const { ta_requests } = this.state;

    return <TempAccessRequestForm {...this.props} ta_requests={ta_requests} />;
  }
}

export { AccessRequest, TempAccessRequestForm };
