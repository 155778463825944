import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import moment from "moment-timezone";
import { renderStatus, text_max, oxford, add_brs, get_check_nocheck, renderFundStatus, isEmpty } from "../../Utils";
import { WorkflowSubmit } from "../WorkflowSubmit";
import { WorkflowTable } from "../WorkflowTable";
import CustomTabs from './../../CustomTabs';
import {default as HistoricPhDStudentFundingTable} from '../../PhD/PhDStudentFundingTable';


import { Popover, Table, Form, Tabs, Input, Divider, Select, Button, InputNumber, message, Radio, Switch, Typography, Modal, Alert, Descriptions, DatePicker } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const TabPane = Tabs.TabPane;
const { RangePicker } = DatePicker

const FUNDING_TYPES = {
  TA: "TA",
  RA: "RA",
  KHOURY_GRA: "Khoury GRA",
  INTERNAL: "Internal Khoury Fellowship",
  EXTERNAL: "External Fellowship",
  INTERN: "Internship",
  OTHER: "Other",
  SUNSET_KF: "Khoury Fellowship (Historic)"
}

const PhDStudentFundingForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      funds: [{index: null, percentage: 100}],
      funding: null,
      error: null,
      notes: null,
    }
    
    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    componentDidMount() {
      this.reset();
    }

    checkTotal = () => {
      return this.state.funds.reduce((r,a) => r+a.percentage, 0) == 100;
    }
    
    validateFundForm() {
      this.setState({ error: this.checkTotal() ? null : "The percentages must sum to 100%." });
      return this.checkTotal();
    }

    reset = () => {
      this.setState({ funding: null, funds: [{index: null, percentage: 100}], error: null, notes: null });
      this.props.form.resetFields();
    }
    
    handleSubmit = () => {
      if (!this.checkTotal()) {
        this.setState({ error: "The percentages must sum to 100%"})
        return;
      }

      const { semesters, user, student, semester, form } = this.props;

      form.validateFields((err, values) => {
        if (!err) {
          const { funding, funds, notes } = this.state;
          const details = funds.filter(f => f.index != null).map(f => ({
            fund: f.index, 
            fraction: f.percentage/100,
          }));
          const data = { 
            funds: details, 
            notes: notes,
            student: student.id,
            semester: semester, 
            user: user.id, 
            steps: [], 
            instructor: user.instructor, 
            funding_type: funding,
            dual_internship_ra_funding: values.dual_internship_ra_funding,
            internship_start_date: moment.min(values.internship_dates),
            internship_end_date: moment.max(values.internship_dates),
            instructor_of_record: values.instructor_of_record,
            ta: funding == "TA", 
            khoury_fellowship: funding == "Fellowship", 
            notes: funding == "OTHER" || funding == "EXTERNAL" ? notes : null,
          };
          this.props.onSubmit(data, this.reset);
          } else {
            message.error(err)
        }
      })
    };

    //This function creates the funding details portion of the form. It is called depending on the funding type a student receives.

    createFundingForm = () => {
      const { getFieldDecorator } = this.props.form;
      const { funds, error, funding } = this.state;
      const { student } = this.props;
      const sem = this.get_semester(this.props.semesters.length == 1 ? this.props.semesters[0] : this.props.semesters[1]);
      const advisors = student ? student.advisors.find(ah => (this.get_semester(ah.start).code <= sem.code) && (ah.end == null || (this.get_semester(ah.end).code >= sem.code))) : { advisors: [] };
      const fundlist = this.fund_list().filter(f => f.active && f.owners.map(e => this.get_employee(e.owner).faculty).filter(a => a != null).reduce((r,a) => r || advisors.advisors.includes(a), false));

      return (
        <>
          <p>Please enter how you wish this student to be funded.  If you wish to only fund the student from a single index, simply select that index.  If you wish to fund the student from multiple indices, select Add Fund and then enter the percentage breakdown across the two indices.</p>
            { error ? ( <div><Alert message={ error } type="error" showIcon /></div> ) : "" }
            { funds ? funds.map((e, idx) =>
              <React.Fragment key={ "frag" + idx } >
                <FormItem {...this.formItemLayout} label={ "Fund " + (idx+1) }>
                  {getFieldDecorator("fund" + idx, {
                    rules: [{ required: true, message: 'Please select a fund.' }],
                    initialValue: e ? e.index : null,
                    onChange: e => { funds[idx].index = e; this.setState({ funds: funds }); this.validateFundForm(); },
                    })(<Select showSearch style={{ width: 540 }} filterOption={ this.filter }>
                      { fundlist.map(f => <Option key={ f.id } value={ f.id } disabled={ funds.find((fund, i) => i != idx && f.id == fund.index) }>{ [text_max(this.print_full_fund(f.id), 40), " ("].concat(oxford(f.owners.map(o => this.print_employee(o.owner)))).concat([")"]) }</Option> )}
                    </Select>)}
                </FormItem>
                { funds.length > 1 ? (
                    <FormItem {...this.formItemLayout} label={ "Percentage " + (idx+1) }>
                    {getFieldDecorator("percentage" + idx, {
                      rules: [{ required: true, message: 'Please enter the percentage of funding from this fund.' }],
                      initialValue: e ? e.percentage : 100,
                      onChange: e => { funds[idx].percentage = e; this.setState({ funds: funds }); this.validateFundForm(); },
                      })(<InputNumber min={1} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />)}
                  </FormItem>
                ) : null }
              </React.Fragment>
            ) : null }
          <p/>
          <FormItem {...this.formItemLayout} label=" " colon={ false }><Button icon="plus" onClick={ () => this.setState({ funds: funds.concat([{index: null, percentage: 50}]) }) }>Add Fund</Button></FormItem> 
        </>
      );
    }
    
    render() {
      const { visible, onCancel, onCreate, form, item, sections, campus, semester, student, user, all_students, disabled, semesters } = this.props;
      const { getFieldDecorator } = form;
      const { funds, error, funding } = this.state;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      const sem = this.get_semester(this.props.semesters.length == 1 ? this.props.semesters[0] : this.props.semesters[1]);
      const advisors = student ? student.advisors.find(ah => (this.get_semester(ah.start).code <= sem.code) && (ah.end == null || (this.get_semester(ah.end).code >= sem.code))) : { advisors: [] };

      const disabledDate = current => {
        const startDate = new Date(sem.startdate);
        const endDate = new Date(sem.enddate);
        return current && (current < startDate || current > endDate);
      };

      return (
        <>
          <p>Please use the form below to submit your proposal for how you wish { student ? this.print_full_student(student) : "" } to be funded during the { this.print_semester(semester) } semester.  If you wish to fund them on a TA, simply select Teaching Assistant (TA).  If they will be funded on a grant(s), select Research Assistantship (RA) and select where they will be funded from.  If they are on a Khoury PhD Fellowship, select that option.</p>
          <p>If the student will only be here part of the time, will be paid a fellowship directly from an external source, will be on a leave, or will be on an internship, you should select Other, and enter more details in the text box that shows up.</p>
          <p>If you have any questions or concerns, please email <a href="mailto:khoury-graduateprograms@northeastern.edu">Khoury Graduate Programs</a>.  If an index that you expect to see does not show up, please contact <a href="mailto:khoury-grants@northeastern.edu">Khoury Grants Administration</a>.</p>
          { student ? (
            <Form>
              <FormItem {...this.formItemLayout} label="Semester">
                {getFieldDecorator('semester', {
                  initialValue: semester,
                })(<Select showSearch style={{ width: 220 }} filterOption={ this.filter } disabled={ true } >
                  <Option value={ semester }>{ this.print_semester(semester) }</Option>
                </Select>
                )}
              </FormItem>
              <FormItem {...this.formItemLayout} label="Funding">
                {getFieldDecorator('funding', {
                  initialValue: funding,
                  rules: [{ required: true, message: 'Please select how this student will be funded.' }],
                  onChange: (event) => { this.setState({ funding: event, funds: [{index: null, percentage: 100}] }) },
                })(<Select showSearch style={{ width: 220 }} filterOption={ this.filter } disabled={ disabled }>
                  <Option value="RA">Research Assistantship (RA)</Option>
                  <Option value="KHOURY_GRA">Khoury GRA</Option>
                  <Option value="TA">Teaching Assistantship (TA)</Option>
                  <Option value="EXTERNAL">External Fellowship</Option>
                  <Option value="INTERNAL">Internal Khoury Fellowship</Option>
                  <Option value="INTERN">Internship</Option>
                  <Option value="OTHER">Other</Option>
                </Select>
                )}
              </FormItem>
              { funding == "RA" ? (
                <React.Fragment>
                  <Divider orientation="center">RA Funding Breakdown</Divider>
                  <>{this.createFundingForm()}</>
                </React.Fragment>
              ) : funding == "OTHER" ? (
                <FormItem {...this.formItemLayout} label="Details">
                  {getFieldDecorator('notes', {
                    rules: [{ required: true, message: 'Please enter more details about how this student will be funded.' }],
                    onChange: e => this.setState({ notes: e.target.value }),
                  })(<TextArea rows={ 4 } placeholder="Examples of information to include:&#10;Graduated/Graduating, Leave of Absence, Withdrawn from Program, US Military, Self-funded, Industry Track, University Employee, Fulbright."/>
                  )}
                </FormItem>
              ) : funding == "EXTERNAL" ? (
                <>
                <FormItem {...this.formItemLayout} label="Fellowship source">
                  {getFieldDecorator('notes', {
                    rules: [{ required: true, message: 'Please enter more details about the Fellowship source.' }],
                    onChange: e => this.setState({ notes: e.target.value }),
                  })(<TextArea rows={ 1 } style={{ width: 400 }} placeholder="National Science Foundation, Google, etc."/>
                  )}
                </FormItem>
                  <React.Fragment>
                  <Divider orientation="center">Top-off Funding & 10% Bonus Source</Divider>
                  <p> Faculty advisors must enter a funding source for any top-off funding needed to close the gap between the external award's stipend and 
                    Khoury College's stipend (example, the external award provides a stipend of $40,000 for the year versus the Khoury College stipend of $50,000 for the year). 
                    In addition, all students who are awarded an external fellowship are eligible to receive a 10% bonus on top of the standard Khoury College PhD stipend rate. 
                  </p>
                  <p>The Top-off & 10% Bonus can be funded from the <b>faculty advisor's grant</b> or <b>overhead return account.</b></p>
                  <>{this.createFundingForm()}</>
                </React.Fragment>
                </>
              ) : funding == "INTERN" ? (
                <>
                  <FormItem {...formItemLayout} label="Internship Dates" extra="Please select the date range of the internship.">
                    {getFieldDecorator("internship_dates", { 
                      rules: [
                        { required: true, message: "Please provide dates" },
                      ],
                    })
                    (
                      <RangePicker/>
                    )}
                  </FormItem>
                  <FormItem {...this.formItemLayout} label="Additional RA Funding?" extra={"Would you like your student to be funded as a RA before their internship start date and/or after their internship end date? Note that students need to work for a minimum of 5 business days, and will be paid a full pay period cycle (i.e., from September 1 to September 15). If not, the student's stipend will be paused for the entirety of the semester by default."}>
                    {getFieldDecorator('dual_internship_ra_funding', {
                      rules: [{ required: true, message: 'Please select an option.' }]
                      })
                      (<RadioGroup>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </RadioGroup>
                    )}
                  </FormItem>
                  {/* The below form item is conditionally rendered if the faculty member
                  has specified RA funding should be continued. */}
                  {form.getFieldValue('dual_internship_ra_funding') &&
                    <React.Fragment>
                     <Divider orientation="center">Additional RA Funding Breakdown</Divider>
                     <>{this.createFundingForm()}</>
                    </React.Fragment>
                  }
                </>
              ) : funding == "TA" ? (
                <>
                <Alert 
                  message="Please note: TA positions are assigned at least one month prior to the start of a new semester. If you're uncertain that your student can commit to a TAship 
                    (e.g., you'd like to fund them as an RA but are waiting on grant approvals), please select &ldquo;Other&rdquo; above and enter additional details about the student's funding plans."
                  type="warning"
                />

                  <FormItem {...this.formItemLayout} label="Instructor of Record" extra={"Is your student serving as an Instructor of Record?"}>
                    {getFieldDecorator('instructor_of_record', {
                      rules: [{ required: false, message: 'Please select an option.' }]
                      })
                      (<RadioGroup>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </RadioGroup>
                    )}
                  </FormItem>
                </>
              ) : null }
              <FormItem {...this.formItemLayout} label=" " colon={ false }><Button type="primary" onClick={ this.handleSubmit } disabled={ disabled }>Submit</Button></FormItem>
            </Form> ) : null }
          </>
      );
    }
  }
);

class PhDStudentFundingSearchForm extends AppComponent {
  render() {
    const { form } = this.props;
    const semesters = this.semester_list().filter(s => s.speed == 1.0);
    
    return (
      <>
        <FormItem {...this.formItemLayout} label="Semester" extra="Optionally select the semester of the funding proposal.">
          {form.getFieldDecorator("phdstudentfundingproposal__semester", {
            onChange: this.props.onSearchChange,
          })(<Select style={{ width: 200 }} allowClear>
            { semesters.map(s => <Option key={ s.id } value={ s.id }>{ this.print_semester(s.id) }</Option> )}
          </Select>)}
        </FormItem>
        <FormItem {...this.formItemLayout} label="Student" extra="Optionally enter the name of the student.">
        {form.getFieldDecorator("phdstudentfundingproposal__student__name", {
            onChange: this.props.onSearchChange,
         })(<Input style={{ width: 300 }} />)}
        </FormItem>
      </>
    )
  }
}

class PhDStudentFunding extends AppComponent {
  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
      <Descriptions bordered title={ "PhD Student Funding Proposal" }>
        <Descriptions.Item label="Requestor">{ this.link_full_instructor(item.instructor) }</Descriptions.Item>
        <Descriptions.Item label="Submitted">{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
        <Descriptions.Item label="Semester">{ this.print_semester(item.semester) }</Descriptions.Item>
        <Descriptions.Item label="Student"><Link to={ this.getLink("/employee/phd/" + item.student) }>{ item.student_name }</Link></Descriptions.Item>
        <Descriptions.Item label="Funding type">{FUNDING_TYPES[item.funding_type]}</Descriptions.Item>
        {item.notes && item.funding_type != "EXTERNAL" ? 
           <Descriptions.Item label="Notes">{ item.notes }</Descriptions.Item>
           : null
        }
        {item.funding_type == "RA" || ((item.funding_type === "") && (!isEmpty(item.funds))) ?
        <Descriptions.Item label="Funding source" span={2}>{ add_brs(item.funds.map((fd, idx) => (<span key={ idx }>{ [<b>{ [(fd.fraction*100), "%"] }</b>, " from ", this.print_full_fund(fd.fund), " (", oxford(this.get_fund(fd.fund).owners.map(o => this.print_employee(o.owner))), ")"] }</span>))) }</Descriptions.Item>
        : null}
        {item.funding_type == "EXTERNAL" && 
          <>
            <Descriptions.Item label="Fellowship Source" span={2}>{item.notes}</Descriptions.Item>
            <Descriptions.Item label="10% Top-off Source" span={2}>{ add_brs(item.funds.map((fd, idx) => (<span key={ idx }>{ [<b>{ [(fd.fraction*100), "%"] }</b>, " from ", this.print_full_fund(fd.fund), " (", oxford(this.get_fund(fd.fund).owners.map(o => this.print_employee(o.owner))), ")"] }</span>))) }</Descriptions.Item>
          </>
        }
        {item.funding_type == "INTERN" ? 
        <>
          <Descriptions.Item label="Internship Dates">{ moment(item.internship_start_date).format("MMM DD, YYYY") + " to " + moment(item.internship_end_date).format("MMM DD, YYYY")}</Descriptions.Item>
          <Descriptions.Item label="Additional RA Funding?">{ item.dual_internship_ra_funding == true ? "Yes" : "No" }</Descriptions.Item>
          { (item.dual_internship_ra_funding === true) ? 
            <Descriptions.Item label="RA Funding Source" span={2}>{ add_brs(item.funds.map((fd, idx) => (<span key={ idx }>{ [<b>{ [(fd.fraction*100), "%"] }</b>, " from ", this.print_full_fund(fd.fund), " (", oxford(this.get_fund(fd.fund).owners.map(o => this.print_employee(o.owner))), ")"] }</span>))) }</Descriptions.Item>
          : null}
        </>
        : null}
         {(item.funding_type == "TA") && 
          <>
            <Descriptions.Item label="Instructor of Record" span={1}>{item.instructor_of_record === true ? "Yes" : item.instructor_of_record === false ? "No" : "N/A"}</Descriptions.Item>
          </>
        }

        
      </Descriptions>
    );
  }
}

class PhDStudentFundingTable extends WorkflowTable {
  get_columns = () => {      
    return [{
      title: "Semester",
      align: 'left',
      width: 90,
      render: (text, record, idx) => this.print_semester(record.record.semester)
    }, {
      title: "Instructor",
      align: 'left',
      width: 110,
      render: (text, record, idx) => this.link_instructor(record.record.instructor)
    }, {
      title: "Student",
      align: 'left',
      width: 110,
      render: (text, record, idx) => <Link to={ this.getLink("/employee/phd/" + record.record.student) }>{ record.record.student_name }</Link>
    }, {
      title: "Type",
      align: 'left',
      render: (text, record, idx) => FUNDING_TYPES[record.record.funding_type],
    }, {
      title: "Source",
      alight: 'left',
      render: ( text, record, idx) => record.record.funds ? <span>{ add_brs(record.record.funds.map((fd, idx) => (<span key={ idx }>{ [<b>{ [(fd.fraction*100), "%"] }</b>, " from ", this.print_full_fund(fd.fund), " (", oxford(this.get_fund(fd.fund).owners.map(o => this.print_employee(o.owner))), ")"] }</span>))) }</span> : "N/A"

    }];
  }
}

class PhDStudentFundingTab extends WorkflowSubmit {
  state = {
    endpoint: "/api/phd/funding/",
    visible: true,
  }

  includeHeader = () => {
    return false;
  }
  
  getSemester = () => {
    const { semesters } = this.props;
    return semesters.length > 1 ? semesters[1] : semesters[0];
  }

  getData = () => {
    const { student, semesters } = this.props;
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint + "?student=" + student.id + "&semester=" + this.getSemester(), data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "PhD Student Funding Proposal";
  }

  get_workflowtype = () => {
    return "phdstudentfundingproposal";
  }

  get_name_plural = () => {
    return "PhD Student Funding Proposals";
  }
  
  get_form = (func) => {
    const { student, disabled } = this.props;
    
    return <PhDStudentFundingForm {...this.props} onSubmit={ this.submit_form } student={ student } semester={ this.getSemester() } disabled={ disabled } />;
  }
  
  get_record_view = (record) => {
    return <PhDStudentFunding {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func(); }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return null;
  }  
}

class PhDStudentFundingOverview extends AppComponent {
  state = {
      endpoint_students: "/api/phd/",
      students: [],
      loading_students: true,      
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.doGet(this.state.endpoint_students + "?advisors__advisors=" + this.props.user.instructor, data => this.setState({ students: data, loading_students: false }));
  }

  render() {
    const { semester, semesters } = this.props;
    const { loading_students, students } = this.state;
      
    const sem = this.get_semester(this.props.semesters.length == 1 ? this.props.semesters[0] : this.props.semesters[1]);
    
    const mystudents = students.filter(s => (s.left == null || this.get_semester(s.left) >= sem.code) && s.advisors.find(ah => ah.advisors.find(a => a == this.props.user.instructor) && (this.get_semester(ah.start).code <= sem.code) && (ah.end == null || (this.get_semester(ah.end).code >= sem.code))));

    const already_ended = moment(this.get_semester(semesters[0]).enddate, "YYYY-MM-DD") <= moment();

    const alert = already_ended ? ( <Alert message="Semester has ended" description={ "The " + semester + " semester has ended, so the form below is read-only.  To update student funding now, please contact Khoury Grants administration directly." } type="warning" showIcon /> ) : null;
        
    return (
      <Content {...this.props} title={ "PhD student funding for " + semester } breadcrumbs={ [{ link: "/employee", text: "Employee" }, { link: "/employee/phd/overview", text: "PhD" }, { text: "Funding" }] }>
        <p>Below is a list of funding proposals that have been made for each of your students for the { semester } semester.  Proposals that have been submitted are marked as Accepted; only the most recent Accepted proposal is the active one (all previous proposals are discarded when a new proposal is made). </p>
                
        <p>If any of your advisees do not show up, or if any students show up who are not your advisees, please email <a href="mailto:khoury-graduateprograms@northeastern.edu">Khoury Graduate Programs</a> to get this corrected.</p>

        { alert ? <>{ alert }<p/></> : null }

        <CustomTabs {...this.props}>
          { mystudents.map(s => {
            const funds = loading_students ?  null : s.funding_proposal.filter(p => semesters.includes(p.semester));
            const prev_funds = loading_students ? null : s.funding.map(s => s.funds.map((f, idx) => { f.semester = s.semester; f.rowspan = s.funds.length; f.index = idx; return f; })).flat();
            
            return (
              <TabPane tab={ [this.print_full_student(s), " ", get_check_nocheck(funds != null && funds?.length > 0) ] } key={ s.id }>
                <PhDStudentFundingTab {...this.props} student={ s } disabled={ already_ended }/>

                <Divider orientation="left">Prior funding history</Divider>
                <p>Below is the history of prior funding for { this.print_full_student(s) }.</p>
                <HistoricPhDStudentFundingTable {...this.props} funds={ prev_funds } loading={ loading_students } hide_fields={ ['submitted_at', 'submitted_by', 'accepted_at', 'student', 'nuid', 'actions'] } />
                  
              </TabPane>
            );
          } ) }
        </CustomTabs>
      </Content>
    );
  }
}

export { FUNDING_TYPES, PhDStudentFundingOverview, PhDStudentFunding, PhDStudentFundingTable, PhDStudentFundingSearchForm };