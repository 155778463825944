import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend, Interval } from "bizcharts";
import AppComponent from "../../AppComponent";
import { CSVLink } from "react-csv";
import Content from "../../Content";
import { isEmpty } from "../../Utils";
import { Button, Table, Form, Switch, Spin, Divider, Select, Typography, Alert} from "antd";

const FormItem = Form.Item;
const Option = Select.Option;

const { Text } = Typography;

class MSCourseSurveyOverview extends AppComponent {
  state = {
    endpoint: "/api/petition/ms/coursesurvey/",
    coursevotes: [],
    loading_coursevotes: true,
    chart_level: "",
    chart_position: "course*Result",
    chart_xaxis: "Result",
    endpoint_viewable: "/api/petitionviewable/",
    visible: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(
      this.state.endpoint_viewable +
        "?semester=" +
        this.props.semesters.join(","),
      (data) => {
        this.setState({ visible: data[0]?.petitions_visible_mcs });
      }
    );
    this.doGet(
      this.state.endpoint + "?semester=" + this.props.semesters.join(","),
      (data) => this.setState({ coursevotes: data, loading_coursevotes: false })
    );
  };

  onChange = (checked) => {
    const { semesters } = this.props;
    const semester = this.get_semester(semesters[0]);
    const id = semester.id;
    this.doPatch(
      "/api/petitionviewable/" + id + "/",
      () => {
        this.setState({ visible: checked });
      },
      JSON.stringify({ petitions_visible_mcs: checked })
    );
  };


  votes_sorter = (coursevotes) => {
    const filteredData = {};
    const { chart_level } = this.state;

    coursevotes.forEach((coursevote) => {
      coursevote["courses"].forEach((course) => {
        const course_id = course["course"];
        const preference_rank = course["preference_rank"];
        const on_campus_required = course["on_campus_required"];

        // Check if the course matches the specified preference rank or if no rank is selected
        if (preference_rank === chart_level || chart_level === "") {
          // Initialize filteredData[course_id] if it doesn't exist
          if (!filteredData[course_id]) {
            filteredData[course_id] = { votes: 0, on_campus_required: 0 };
          }

          // Update votes count
          filteredData[course_id].votes += 1;

          // Update on_campus_required count if the condition is met
          if (on_campus_required) {
            filteredData[course_id].on_campus_required += 1;
          }
        }
      });
    });

    const chartData = [];
    Object.keys(filteredData).forEach((course) =>
      chartData.push({
        course: this.print_course(course),
        votes: filteredData[course].votes,
        on_campus_required: filteredData[course].on_campus_required,
      })
    );
    return chartData;
  };

  further_sorting = (courseVoteTotals) => {
    const bizChart = [];

    courseVoteTotals.forEach((courseVote) => {
      bizChart.push({
        course: courseVote["course"],
        type: "Votes",
        Result: courseVote["votes"] - courseVote["on_campus_required"],
      });
      bizChart.push({
        course: courseVote["course"],
        type: "In Person",
        Result: courseVote["on_campus_required"],
      });
    });

    return bizChart;
  };

  

  render() {
    const { semester } = this.props;
    const {
      coursevotes,
      loading_coursevotes,
      chart_level,
      chart_position,
      chart_xaxis,
    } = this.state;

    const courseVoteTotals = this.votes_sorter(coursevotes);
    const bizChartFormat = this.further_sorting(courseVoteTotals);

    const graphColumns = {
      Result: {
        tickInterval: 1,
      },
    };

    const columns = [
      {
        title: "Course",
        key: "course",
        render: (text, record, idx) => record.course,
        sorter: (a, b) => a.course.split(" ").pop() - b.course.split(" ").pop(),
        download: (record) => this.print_full_course(record.course),
      },
      {
        title: "Votes",
        key: "votes",
        render: (text, record, idx) => record.votes,
        sorter: (a, b) => a.votes - b.votes,
        download: (record) => record.votes,
      },
      {
        title: "On-campus course required",
        key: "on_campus_required",
        render: (text, record, idx) => record.on_campus_required,
        download: (record) => record.on_campus_required,
      },
    ];

    return (
      <Content
        {...this.props}
        title={"MS Course Survey Results"}
        breadcrumbs={[
          { link: "/staff", text: "Staff" },
          { text: "Petition" },
          { text: "Course Survey" },
          { text: semester },
        ]}
      >
        <div style={{ display: "flex", paddingBottom: 10 }}>
          <span style={{ display: "flex", marginLeft: "auto" }}>
            <Text strong> close / open the survey window &nbsp;</Text>
            <Switch checked={this.state.visible} onChange={this.onChange} />
          </span>
        </div>
        {!this.state.visible && (
          <Alert
            message="The Petition Window is Closed"
            description="Currently, students cannot submit new surveys."
            type="warning"
            showIcon
          />
        )}
        <Divider orientation="left">Overview</Divider>
        <p>
          Graphical representation of course preferences submitted by MS
          Students.
        </p>
        {loading_coursevotes ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          <>
            {coursevotes?.length > 0 && (
              <Form layout="inline">
                <Select
                  style={{ width: 200 }}
                  value={chart_level}
                  onChange={(e) => this.setState({ chart_level: e })}
                >
                  <Option key={""} value={""}>
                    All Votes
                  </Option>
                  {[
                    ...Array(
                      Math.max(
                        ...coursevotes.map((el) =>
                          el.courses ? el.courses.length : 1
                        )
                      )
                    ),
                  ].map((_, idx) => (
                    <Option key={idx + 1} value={idx + 1}>
                      {this.print_ta_preference(idx + 1) + " Preference"}
                    </Option>
                  ))}
                </Select>
              </Form>
            )}
            <Chart
              height={400}
              data={bizChartFormat.sort((a, b) => b.Result - a.Result)}
              scale={graphColumns}
              forceFit
            >
              <Legend />
              <Axis name="course" label={{ rotate: 30, offset: 10 }} />
              <Axis name="Result" title="Result" />
              <Geom
                type="intervalStack"
                position={chart_position}
                color={"type"}
              />
            </Chart>
          </>
        )}
        <Divider orientation="left">Votes per Course</Divider>
        <p>
          Table displaying all submitted votes for class requests.{" "}
          {chart_level && (
            <>
              <b>Note:</b> Currently filtered by{" "}
              {this.print_ta_preference(chart_level)} preference
            </>
          )}
        </p>
        <>
          <CSVLink
            filename={
              chart_level
                ? "MSCourseSurvey" + "Preference" + chart_level + ".csv"
                : "MSCourseSurveyAllResults.csv"
            }
            data={this.votes_sorter(coursevotes).sort(
              (a, b) => b.votes - a.votes
            )}
          >
            <Button
              icon="download"
              style={{ marginBottom: 15 }}
              disabled={isEmpty(coursevotes)}
            >
              Download
            </Button>
          </CSVLink>
        </>
        <Table
          {...this.props}
          dataSource={this.votes_sorter(coursevotes).sort(
            (a, b) => b.votes - a.votes
          )}
          columns={columns}
          loading={loading_coursevotes}
          rowKey="id"
        />
      </Content>
    );
  }
}

export { MSCourseSurveyOverview };
