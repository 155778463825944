import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import { ElectivePetitionForm } from "../GenericForms";
import Transcript from "../../../Transcript";

import { Icon, Radio, Steps, Table, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
import { oxford } from '../../../Utils';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { Step } = Steps;

class PhDElectivePetition extends AppComponent {

  getCurrentAdvisors = (current_code, record) => {
    const current_advisors = record.advisors.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    return current_advisors ? oxford(current_advisors.advisors.map(e => this.print_instructor(e))) : <i>None</i>; 
  }

  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
        <Descriptions bordered title={ "PhD Elective Petition submission by " + record.created_by}>
          <Descriptions.Item label="Submitted" span={ 2 }>{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" span={ 2 }>{[ record.created_by + " ", <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" span={ 2 }>{ item.phdstudent.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 2 }>{<Transcript {...this.props} nuid={item.phdstudent.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Degree" span={ 2 }>{ item.phdstudent.degree ? this.print_degree(item.phdstudent.degree): "Couldn't load" }</Descriptions.Item>
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(item.phdstudent.campus) }</Descriptions.Item>
          <Descriptions.Item label={<p>Advisor <small>(*at submission)</small></p>} span={ 2 }>{ this.getCurrentAdvisors(this.find_semester(record.created_at), item.phdstudent) }</Descriptions.Item>
          <Descriptions.Item label="Matriculated" span={ 2 }>{ item.phdstudent.matriculated ? this.print_semester(item.phdstudent.matriculated) : "Couldn't load"  }</Descriptions.Item>
          <Descriptions.Item label="Semester" span={ 2 }>{this.print_semester(item.elective_petition.semester)}</Descriptions.Item>
          { item.elective_petition.petition_type == "khoury" ? (
            <Descriptions.Item label="Khoury Course" span={ 2 }>{this.print_course(item.elective_petition.khoury_course)}</Descriptions.Item> 
          ): ([
            <Descriptions.Item label="Non-Khoury Course" span={ 2 }>{ item.elective_petition.non_khoury_course }</Descriptions.Item>,
            <Descriptions.Item label="Non-Khoury CRN" span={ 2 }>{ item.elective_petition.non_khoury_crn }</Descriptions.Item>
          ])}
          <Descriptions.Item label="Rationale" span={ 4 }>{ item.elective_petition.rationale }</Descriptions.Item>
          <Descriptions.Item label="Attached Files" span={ 4 }>{[record.files.map(el => <a onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type +".pdf"}</a>) ]}</Descriptions.Item>
        </Descriptions>
      );
   }
}

class PhDElectivePetitionTable extends WorkflowTable {
  get_columns = () => {
    return [
      {
        title: "Petition Type",
        key: "petition_type",
        width: 120,
        render: (text, record, idx) => record.record.petition_type
      }
    ];
  }
}

class PhDElectivePetitionOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/phd/electivepetition/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "PhD Elective Petition";
  }

  get_workflowtype = () => {
    return "phdelectivepetition";
  }

  get_name_plural = () => {
    return "PhD Elective Petition";
  }
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "phd" }, { text: "Elective Petition" }];
  }
  
  get_form = (func) => {
    return <ElectivePetitionForm {...this.props} formType={"phd"} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <PhDElectivePetition {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
      <p>	To request to take a course outside of your department as an elective.</p>
    );
  }  
}

export { PhDElectivePetitionOverview, PhDElectivePetition, PhDElectivePetitionTable };