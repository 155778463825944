import React, { Component } from "react";
import { BrowserRouter as Router, Switch as RouterSwitch, Route, Link, Redirect, withRouter } from "react-router-dom";
import moment from "moment-timezone";
import Markdown from 'react-markdown'
import QRCode from 'qrcode.react';
import Moment from 'react-moment';
import queryString from 'query-string';
import AppComponent from './AppComponent';
import Content from './Content';
import { add_dividers, get_check, get_nocheck, format_nuid, get_check_nocheck, oxford } from "./Utils";

import { PageHeader, Layout, Menu, Breadcrumb, Icon, Input, Select, DatePicker, Table, Tag, Tooltip, Spin, Radio, Button, Divider, Form, Switch, message, Modal, List, Card, InputNumber, Alert, Upload, Checkbox } from 'antd';
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { SubMenu } = Menu;
const { Sider, Footer } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const PollForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      poll: null,
      
      endpoint_voters: "/api/polls/voters/",
      loading_voters: true,
      voters: [],
      query_id: 0,
    }

    componentDidMount() {
      this.reset();
    }

    componentDidUpdate(prevProps) {
      if ((prevProps.poll != this.props.poll) || (prevProps.semester != this.props.semester)) {
        this.reset();
      }
    }

    reset = () => {
      this.setState({
        title: this.props.poll ? this.props.poll.title : null,
        text: this.props.poll ? this.props.poll.text : null,
        view_date: this.props.poll && this.props.poll.view_date ? moment(this.props.poll.view_date, "YYYY-MM-DD") : null,
        pub_date: this.props.poll ? moment(this.props.poll.pub_date, "YYYY-MM-DD") : null,
        end_date: this.props.poll ? moment(this.props.poll.end_date, "YYYY-MM-DD") : null,
        exp_date: this.props.poll ? moment(this.props.poll.exp_date, "YYYY-MM-DD") : null,
        types: this.props.poll ? this.props.poll.types : [],
        subtypes: this.props.poll ? this.props.poll.subtypes : [],
        ranks: this.props.poll ? this.props.poll.ranks : [],
        tenure_homes: this.props.poll ? this.props.poll.tenure_homes : [],
        campuses: this.props.poll ? this.props.poll.campuses : [],
        committees: this.props.poll ? this.props.poll.committees : [],
        instructors: this.props.poll ? this.props.poll.instructors : [],
        result_vis: this.props.poll ? this.props.poll.result_vis : "S",
        creator_result_vis: this.props.poll ? this.props.poll.creator_result_vis : true,
        admin_result_vis: this.props.poll ? this.props.poll.admin_result_vis : true,
        vote_vis: this.props.poll ? this.props.poll.vote_vis : "A",
        choices: this.props.poll ? this.props.poll.choices : [null, null],
      }, this.getVoters);
    }
    
    getVoters = () => {
      const { form } = this.props;
      const { query_id } = this.state;
      
      this.setState({ query_id: query_id+1 }, () => {
        const data = JSON.stringify({ committees: form.getFieldValue("committees"), ranks: form.getFieldValue("ranks"), types: form.getFieldValue("types"), subtypes: form.getFieldValue("subtypes"), tenure_homes: form.getFieldValue("tenure_homes"), instructors: form.getFieldValue("instructors"), campuses: form.getFieldValue("campuses") });
        this.doPost(this.state.endpoint_voters, data => this.receiveVoters(query_id, data.voters), data);
      });
    }
    
    receiveVoters = (my_id, data) => {
      const { query_id } = this.state;
      
      if (my_id +1 == query_id) {
        this.setState({ voters: data, loading_voters: false });
      }
    }

    disabledDate = (value) => {
      return moment().startOf('day') > value.valueOf();
    }

    render() {
      const { visible, onCancel, onCreate, form, poll, sections, campus } = this.props;
      const { getFieldDecorator } = form;
      const { title, text, view_date, pub_date, end_date, exp_date, types, subtypes, ranks, tenure_homes, campuses, committees, instructors, result_vis, creator_result_vis, admin_result_vis, vote_vis, choices, voters, loading_voters } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Modal
          visible={ visible }
          title={ poll ? "Edit poll" : "Create a new poll" }
          okText={ poll ? "Save" : "Create" }
          onCancel={ () => { onCancel(); this.reset();  } }
          onOk={ () => { onCreate(choices); this.reset();  } }
          width={ 800 }
        >
        <p>This form allows you to create a new poll for faculty.  Fill out the items below and your poll will be created.</p>
        <Form onSubmit={this.handleSubmit} >
          <Divider orientation="left">Basic information</Divider>
          <p>Please provide the basic information for this poll.</p>
          <FormItem {...formItemLayout} label="Title" extra="Please provide a brief title for your poll.">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Please provide a title' }],
              initialValue: title,
            })(<Input width={ 250 } />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Description" extra={ ["Please provide the information to present to the faculty regarding your poll.  You can put ", <a href="https://www.markdownguide.org" target="_blank">Markdown</a>, " formatting."] }>
            {getFieldDecorator('text', {
              rules: [{ required: true, message: 'Please provide a description' }],
              initialValue: text,
            })(<TextArea rows={ 6 } />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Result Visibility" extra="Please select who should be able to view the results of the poll. The creator of the poll can always view the results.">
            {getFieldDecorator('result_vis', {
              rules: [{ required: true, message: 'Please select an option.' }],
              initialValue: result_vis,
            })(<RadioGroup>
              <Radio value="S">Admins only</Radio>
              <Radio value="G">Voters (All)</Radio>
              <Radio value="V">Voters (Voted)</Radio>
              <Radio value="P">Anyone</Radio>
            </RadioGroup>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Creator Result Visibility" extra="Please select if the creator should be able to view results before voting ends.">
            {getFieldDecorator('creator_result_vis', {
              rules: [{ required: true, message: 'Please select an option.' }],
              initialValue: creator_result_vis,
            })(<RadioGroup>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </RadioGroup>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Admin Result Visibility" extra="Please select if Admins should be able to view results before voting ends.">
            {getFieldDecorator('admin_result_vis', {
              rules: [{ required: true, message: 'Please select an option.' }],
              initialValue: admin_result_vis,
            })(<RadioGroup>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </RadioGroup>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Vote Visibility" extra="Please select whether individual voters names should be visible along with the results.">
            {getFieldDecorator('vote_vis', {
              rules: [{ required: true, message: 'Please select an option.' }],
              initialValue: vote_vis,
            })(<RadioGroup>
              <Radio value="A">Votes are anonymous</Radio>
              <Radio value="C">Voters can choose</Radio>
              <Radio value="F">Votes are visible</Radio>
            </RadioGroup>
            )}
          </FormItem>
          <Divider orientation="left">Timeline</Divider>
          <p>Please provide the timeline for this poll, including the voting period and the date after which the poll will no longer be visible.</p>
          <FormItem {...formItemLayout} label="Able to View" extra="Please provide the date (inclusive) you want viewing to start. If left blank, it will not be viewable until the voting starts.">
            {getFieldDecorator('view_date', {
              rules: [{ required: false, message: 'Please provide a viewing date.' }],
              initialValue: view_date,
            })(<DatePicker disabledDate={ this.disabledDate } />)}
          </FormItem>         
          <FormItem {...formItemLayout} label="Voting Start" extra="Please provide the date (inclusive) you want voting to start.">
            {getFieldDecorator('pub_date', {
              rules: [{ required: true, message: 'Please provide a voting start date.' }],
              initialValue: pub_date,
            })(<DatePicker disabledDate={ this.disabledDate } />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Voting End" extra="Please provide the date (inclusive) you want voting to end.">
            {getFieldDecorator('end_date', {
              rules: [{ required: true, message: 'Please provide a voting end date' }],
              initialValue: end_date,
            })(<DatePicker disabledDate={ this.disabledDate } />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Poll Expires" extra="Please provide the date the poll and its results to disappear on (inclusive).  We recommend having a poll expire no more than one week after voting ends.">
            {getFieldDecorator('exp_date', {
              rules: [{ required: true, message: 'Please provide an expiry date' }],
              initialValue: exp_date,
            })(<DatePicker disabledDate={ this.disabledDate } />)}
          </FormItem>
          <Divider orientation="left">Voters</Divider>
          <p>By default, all instructors across all campuses and ranks will be able to vote.  To limit the set of voters, use the controls below; instructors must meet <i>each</i> of the criteria below in order to vote.</p>
          <FormItem {...formItemLayout} label="Campus" extra="Select the campuses that should be able to vote, or leave blank to not limit by campus.">
            {getFieldDecorator('campuses', {
              initialValue: campuses,
//              onChange: this.setState({ loading_voters: true }, this.getVoters),
              onChange: this.getVoters,
            })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } mode="multiple" placeholder="All campuses">
              { this.campus_list().filter(el => el.popular).map(el => <Option key={ el.id } value={ el.id }>{ this.print_campus(el.id) }</Option> ) }
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Faculty Types" extra="Select the faculty types that should be able to vote, or leave blank to not limit by type.">
            {getFieldDecorator('types', {
              initialValue: types,
              onChange: this.getVoters,
            })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } mode="multiple" placeholder="All faculty types">
              { this.instructortype_list().map(el => <Option key={ el.id } value={ el.id }>{ el.mytype }</Option> ) }
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Faculty Sub-types" extra="Select the faculty sub-types that should be able to vote, or leave blank to not limit by sub-type.">
            {getFieldDecorator('subtypes', {
              initialValue: subtypes,
              onChange: this.getVoters,
            })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } mode="multiple" placeholder="All faculty sub-types">
              { this.instructorsubtype_list().map(el => <Option key={ el.id } value={ el.id }>{ this.print_full_instructorsubtype(el.id) }</Option> ) }
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Faculty Ranks" extra="Select the faculty ranks that should be able to vote, or leave blank to not limit by rank.">
            {getFieldDecorator('ranks', {
              initialValue: ranks,
              onChange: this.getVoters,
            })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } mode="multiple" placeholder="All faculty ranks">
              { this.instructorrank_list().map(el => <Option key={ el.id } value={ el.id }>{ this.print_full_instructorrank(el.id) }</Option> ) }
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Faculty Tenure Homes" extra="Select the tenure homes that should be able to vote, or leave blank to not limit by tenure home.">
            {getFieldDecorator('tenure_homes', {
              initialValue: tenure_homes,
              onChange: this.getVoters,
            })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } mode="multiple" placeholder="All faculty tenure homes">
              { this.college_list().map(el => <Option key={ el.id } value={ el.id }>{ this.print_college(el.id) }</Option> ) }
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Committees" extra="Select the committees that should be able to vote, or leave blank to not limit by committee.">
            {getFieldDecorator('committees', {
              initialValue: committees,
              onChange: this.getVoters,
            })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } mode="multiple" placeholder="All committees">
              { this.committeeyear_list().filter(cy => cy.year == this.get_year()).map(el => <Option key={ el.committee } value={ el.committee }>{ this.print_committee(el.committee) }</Option> ) }
            </Select>
            )}
          </FormItem>
          <Divider orientation="left">Additional Voters</Divider>
          <p>You can add additional voters for this poll, beyond those who are eligible from the previous section.</p>
          <FormItem {...formItemLayout} label="Instructors" extra="You can add additional eligible voters individually here.">
            {getFieldDecorator('instructors', {
              initialValue: instructors,
              onChange: this.getVoters,
            })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } mode="multiple" placeholder="No additional instructors">
              { this.instructor_list().map(el => <Option key={ el.id } value={ el.id }>{ this.print_instructor(el.id) }</Option> ) }
            </Select>
            )}
          </FormItem>
          <Divider orientation="left">{ loading_voters ? "?" : voters.length } Eligible Voters</Divider>
          <p>Below is the list of eligible voters in this poll, according to your choices above.</p>
          { loading_voters ? <Spin tip="Loading eligible voters" /> : <p><i>{ oxford(voters.map(i => this.print_full_instructor(i))) }</i></p> }
          
          <Divider orientation="left">Choices</Divider>
          <p>Please enter the choices that will be presented to voters for this poll; each voter will be required to pick exactly once of these choices.</p>
          { choices ? choices.map((e, idx) =>
            <div key={ idx }><FormItem {...formItemLayout} label={ "Choice " + (idx+1) } extra="Please enter the (brief, 50-character max) text for this choice.">
              {getFieldDecorator("choice" + idx, {
                rules: [{ required: true, message: 'Please provide the text for this option.' }],
                initialValue: e ? e.text : null,
                })(<Input width={ 250 } maxLength={ 50 } />)}
            </FormItem>
            <FormItem {...formItemLayout} label={ "Description " + (idx+1) } extra={ ["Please enter the description for this choice.  You can use ", <a href="https://www.markdownguide.org" target="_blank">Markdown</a>, " formatting."] }>
            {getFieldDecorator("description" + idx, {
              rules: [{ required: true, message: 'Please provide a description for this option.' }],
              initialValue: e ? e.description : null,
              })(<TextArea rows={ 3 } />)}
          </FormItem><Divider dashed /></div>
          ) : null }
            <p><Button icon="plus" onClick={ () => this.setState({ choices: choices.concat([null]) }) }>Add Choice</Button></ p>
          <Divider orientation="left">Documents</Divider>
          <p>You can add documents to the poll once you have created it.  An "Add Document" button will appear once the poll is created.</p>
        </Form>
      </Modal>
      );
    }
  }
);

class PollVote extends AppComponent {
  state = {
    endpoint: "/api/polls/",
    choice: null,
    visible: false,
    poll: null,
    loading: true,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.doGet(this.state.endpoint + this.props.poll + "/", data => this.setState({ poll: data, loading: false }));
  }

  done = () => {
    this.props.history.push(this.getLink("/employee/polls/"));
  }

  vote = (choice, visible) => {
    this.doPost(this.state.endpoint + "vote/", this.done, JSON.stringify({ choice_id: choice, visible: visible }));
  }

  printVotes = (votes) => {
    return Array.isArray(votes) ? votes.join("") : votes;
  }

  render() {
    const { choice, visible, loading, poll, endpoint } = this.state;
    const { done } = this.props;

    if (loading) {
      return <Spin tip="Loading poll" />;
    }

    if (!poll) {
      return <Content {...this.props} title={ "Not Found" } breadcrumbs={ [{ link: "/employee", text: "Employee" }, { link: "/employee/polls", text: "Polls" }, { text: "Not Found" }] }>
            <Alert message="Cannot View Poll" description={ "The Poll you are trying to view is not viewable yet" } type="warning" showIcon /> 
          </Content>;
    }

    const radioStyle = { display: 'block', height: '30px', lineHeight: '30px', };
    
    const over = moment(poll.end_date, "YYYY-MM-DD").isBefore(moment(), 'day');
    const before = moment().isBefore(moment(poll.pub_date, "YYYY-MM-DD"), 'day');
    const not_viewable = !this.permission("can", "admin") && (poll.view_date ? moment().isBefore(moment(poll.view_date, "YYYY-MM-DD"), 'day') : before);
    const voted = poll.choices.find(e => e.voted);
    const myvote = choice ? choice : poll.choices.find(e => e.voted) ? poll.choices.find(e => e.voted).id : null;

    return (
      <Content {...this.props} title={ poll.title } breadcrumbs={ [{ link: "/employee", text: "Employee" }, { link: "/employee/polls", text: "Polls" }, { text: poll.title }] }>
        { not_viewable ? 
          ( <Alert message="Cannot View Poll" description={ "The Poll you are trying to view is not viewable yet" } type="warning" showIcon /> )
       : ( <React.Fragment>
        <Markdown source={ poll.text } />

        { poll.attachments?.length > 0 ? (
          <span>
            <Divider orientation="left">Documents</Divider>
            <p>Below are any documents the poll creator added to the poll.</p>
        
            { poll.attachments.map((a, idx) => <div id={ idx }><a onClick={ () => this.openPDF(a[1], "poll-document-" + idx + ".pdf") }>{ a[0] }</a></div>) }
          </span>
        ) : null }
        <Divider orientation="left">Choices</Divider>
        <p>Note: { poll.vote_vis == "A" ? "Your vote is anonymous to those who can see the poll results." : poll.vote_vis == "C" ? "You can choose whether or not to reveal your vote below." : "Your vote will be visible to those who can see the poll results." }</p>
        <RadioGroup value={ myvote } disabled={ over || voted || before } onChange={ e => this.setState({ choice: e.target.value }) }>
          { poll.choices.map(e => <Radio key={ e.id } style={ radioStyle } value={ e.id }><b>{ e.text }</b> { e.voters ? " (" + (e.voters.length + " voters" + (e.voters.filter(a => a != true).length ? ": " + this.printVotes(oxford(e.voters.filter(a => a != true).map(this.print_full_instructor))) : "")) + ")" : e.description ? [" — ", ( <Markdown unwrapDisallowed={true} disallowedTypes={ ["paragraph"] } source={ e.description } /> )] : null }</Radio>) }
        </RadioGroup>
        { ! (over || voted || before ) && poll.vote_vis == "C" ? <div><Divider orientation="left">Vote Visibility</Divider><Checkbox key="visible" checked={ visible } onChange={e => this.setState({ visible: e.target.checked })}>Make my name visible alongside my vote</Checkbox></div> : null }
        <p/>
        { voted || over || before ? (
          <div><Button type="primary" onClick={ this.done }>Back</Button></div>
        ) : (
          <div>
            <Button onClick={ this.done }>Cancel</Button>
            <Button style={{ marginLeft: "10px" }} disabled={ choice == null } onClick={ e => this.vote(choice, visible) } type="primary">Vote</Button>
          </div>
        ) }
       </React.Fragment> )}
      </Content>
    )
  }
}

class PollList extends AppComponent {
  state = {
    show_inactive: false,
    show_allcampuses: false,

    endpoint: "/api/polls/",
    polls: [],
    loading: true,
    
    endpoint_document: "/api/polls/attachment/",

    modal_visible: false,
    modal_item: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.doGet(this.state.endpoint, data => this.setState({ polls: data, loading: false }));
  }

  handleCreateUpdate = (choices) => {
    const form = this.formRef.props.form;
    const { modal_item } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }

      this.setState({ modal_visible: false });
      values.view_date = values.view_date ? moment(values.view_date).format("YYYY-MM-DD") : null;
      values.pub_date = moment(values.pub_date).format("YYYY-MM-DD");
      values.end_date = moment(values.end_date).format("YYYY-MM-DD");
      values.exp_date = moment(values.exp_date).format("YYYY-MM-DD");

      values.choices = choices.map((e, idx) => { return { text: values["choice" + idx], description: values["description" + idx] }; });
      Object.keys(values).forEach(e => e.match("choice([0-9]+)") ? delete values[e] : null);
      Object.keys(values).forEach(e => e.match("description([0-9]+)") ? delete values[e] : null);

      if (modal_item) {
        this.doPatch(this.state.endpoint + modal_item.id + "/", () => { message.success("Edited poll."); form.resetFields(); this.getData(); }, JSON.stringify(values));
      } else {
        this.doPost(this.state.endpoint, () => { message.success("Created new poll."); form.resetFields(); this.getData(); }, JSON.stringify(values));
      }
    });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  results_visible_before = who => {
    return (
      <Tooltip title={who == "A" ? "Admins" : "Creator" + " can view results before vote ends."} key={who}>
        <Tag color={who == "A" ? "green" : "geekblue"}>{who}</Tag>
      </Tooltip>
    );
  };

  render() {
    const { polls, loading, modal_visible, modal_item } = this.state;
    

    const columns = [
      {
        title: 'ID',
        key: 'id',
        width: 80,
        render: (data, record, index) => this.permission("can", "admin") ? ( <Link to={ this.getLink("/employee/polls/" + record.id) }>{ record.id }</Link> ) : record.id,
      }, {
        title: 'Name',
        key: 'name',
        width: 200,
        render: (data, record, index) => moment() > moment(record.pub_date) && (this.permission("can", "admin") || record.result_vis != "S") ? ( <Link to={ this.getLink("/employee/polls/" + record.id) }>{ record.title }</Link> ) : record.title,
      }, {
        title: "Documents",
        key: 'documents',
        width: 150,
        render: (data, record, index) => record.created_by == this.props.user.person.id ? <Upload beforeUpload={ (file) => { const isGt2M = file.size > 20 * 1024 * 1024; if (isGt2M) { message.error('Attachment must be smaller than 20 MB'); } return !isGt2M; } } accept=".pdf" action={ this.state.endpoint_document + record.id + "/"} headers={{ Authorization: this.getAuthorizationHeader() }}><Button><Icon type="upload" /> Add Document</Button></Upload> : null,
      }, {
        title: 'Result Visibility',
        key: 'result',
        width: 130,
        render: (data, record, index) => record.result_vis == "S" ? "Admins only" : record.result_vis == "V" ? "Voters (Voted)" : record.result_vis == "G" ? "Voters (All)" : "Public",
      }, {
        title: 'Vote Visibility',
        key: 'vote',
        align: 'center',
        width: 130,
        render: (data, record, index) => record.vote_vis == "F" ? "All voters" : record.vote_vis == "A" ? "Anonymous" : "Voter choice",
      }, {
        title: 'Viewing Start',
        key: 'viewable',
        width: 100,
        render: (data, record, index) => ( record.view_date ? <Moment format="MMM D, YYYY">{ record.view_date }</Moment>: "When Voting Starts"),
      }, {
        title: 'Voting Start',
        key: 'published',
        width: 100,
        render: (data, record, index) => ( <Moment format="MMM D, YYYY">{ record.pub_date }</Moment> ),
      }, {
        title: 'Voting End',
        key: 'ends',
        width: 100,
        render: (data, record, index) => ( <Moment format="MMM D, YYYY">{ record.end_date }</Moment> ),
      }, {
        title: 'Poll Expires',
        key: 'expires',
        width: 100,
        render: (data, record, index) => record.exp_date ? ( <Moment format="MMM D, YYYY">{ record.exp_date }</Moment> ) : "Never",
      }, {
        title: 'Your Vote',
        key: 'vote',
        width: 100,
        render: (data, record, index) => record.choices.filter(e => e.voted).length ? oxford(record.choices.filter(e => e.voted).map(e => e.text)) : moment() > moment(record.pub_date) && moment().startOf('day') <= moment(record.end_date) ? <Link key="vote" to={ this.getLink("/employee/polls/" + record.id) }>Vote now</Link> : this.permission("can", "admin") || (record.created_by == this.props.user.person.id) || (record.view_date && (moment() > moment(record.view_date))) ? [<Link key="preview" to={ this.getLink("/employee/polls/" + record.id) }>Preview</Link>, " ", this.permission("can", "admin") || (record.created_by == this.props.user.person.id) ? [<Divider key="mine" type="vertical" />, " ", <a onClick={ () => this.setState({ modal_visible: true, modal_item: record }) }>Edit</a>] : null].flat() : null,
      }, {
        title: 'Results',
        key : 'results',
        width: 125,
        render: (data, record, index) => moment().isBefore(moment(record.pub_date, "YYYY-MM-DD"), 'day') ? "Poll not started" : record.choices.map(e => <div><b>{ e.text }:</b> { Array.isArray(e.voters) ? e.voters.length : e.voters }</div>),
      }];

    return loading ? <Spin tip="Loading polls" /> : (
      <Content {...this.props} title={ "Poll List" } breadcrumbs={ [{ link: "/employee", text: "Employee" }, { text: "Polls" }] }>
        <PollForm {...this.props} visible={ modal_visible } onCreate={ this.handleCreateUpdate } poll={ modal_item } onCancel={ () => this.setState({ modal_visible: false }) } wrappedComponentRef={ this.saveFormRef } />
        <p>Below is the list of all currently active polls, and ones that are completed but that have not yet expired.  If you have not voted in a poll but are eligible, you will see a Vote now link on the right side; click that to read more about the poll and place your vote.  To see the results of a poll you have voted on (if they are visible), click on the title of the poll itself.</p>
        <p><Button type="primary" icon="plus" onClick={ () => this.setState({ modal_visible: true, modal_item: null }) }>New Poll</Button></ p>
        <Table {...this.props} loading={ loading } dataSource={ polls } columns={ columns } scroll={{ x: 800 }} bordered={ false } pagination={ false } size="small" rowKey="id" key={ "table-all" } />
      </Content>
    );
  }
}

export default PollList;
export { PollVote };
