import React, { Component } from "react";
import { Link } from "react-router-dom";
import key from "weak-key";
import { CSVLink } from "react-csv";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import { oxford, get_nocheck, print_load_component } from './Utils';

import { Table, Form, Switch, Layout, Tooltip, Icon, Button, Alert } from 'antd';
const FormItem = Form.Item;

class SectionTable extends AppComponent {
  state = {
    show_deleted: false,
  };

  handleToggle = prop => (enable) => {
    this.setState({ [prop]: enable });
  }

  formatCrosslist = (crosslistval) => {
    return (<span className="crosslist-data">{crosslistval ? crosslistval : "?"} + </span>);
  }

  formatEnrollment = (text, record, idx) => {
    const { data } = this.props;

    const crosslist = record.crosslist ? data.find(el => el.id == record.crosslist) : null;
    const value = crosslist ? (record.enrollment ? record.enrollment : "?") : record.enrollment;
    const crosslistvalue = crosslist ? this.formatCrosslist(crosslist.enrollment) : "";

    const enrl = record.enrollment;
    const capacity = record.capacity;

    var color = "section-enrollment-other";

    if (enrl > capacity) {
      color = "section-enrollment-overfull";
    } else if (enrl == capacity) {
      color = "section-enrollment-full";
    } else if (enrl > 0.9 * capacity) {
      color = "section-enrollment-nearfull";
    } else if (enrl < 0.3 * capacity) {
      color = "section-enrollment-nearempty";
    }

    if (!this.permission("can", "staff")) {
      color = "";
    }

    return (<React.Fragment>{crosslistvalue}<span className={color}>{value}</span></React.Fragment>);
  }

  bucket = (size) => {
    if (isNaN(size) || size == 0) { return ""}
    else if (size < 20) { return "<20"; }
    else if (size < 30) { return "20-29"; }
    else if (size < 40) { return "30-39"; }
    else if (size < 50) { return "40-49"; }
    else return ">49";
  }

  formatBucket = (text, record, idx) => {
    const { data } = this.props;

    const crosslist = record.crosslist ? data.find(el => el.id == record.crosslist) : null;

    if ((!record.ugrad_stats) && (!crosslist || !crosslist.ugrad_stats)) {
      return null;
    }

    if ((!record.capacity) || (crosslist && !crosslist.capacity)) {
      return "?";
    }

    const enrollment = record.enrollment + (crosslist ? crosslist.enrollment : 0);
    const capacity = record.capacity + (crosslist ? crosslist.capacity : 0);

    const e_bucket = this.bucket(enrollment);
    const c_bucket = this.bucket(capacity);

    var color = "";

    if ((e_bucket != c_bucket) && (enrollment < capacity)) {
      color = "section-enrollment-nearempty";
    } else if ((e_bucket != c_bucket) && (enrollment > capacity)) {
      color = "section-enrollment-overfull";
    }

    return (<span className={color}>{c_bucket}</span>);
  }

  formatCapacity = (text, record, idx) => {
    const { data } = this.props;

    const crosslist = record.crosslist ? data.find(el => el.id == record.crosslist) : null;
    const value = crosslist ? (record.capacity ? record.capacity : "?") : record.capacity;
    const crosslistvalue = crosslist ? this.formatCrosslist(crosslist.capacity) : "";

    const size = record.room ? record.room.size : null;
    const enrl = record.enrollment;
    const capacity = record.capacity;

    if (size) {
      var color = "section-capacity-other";

      if (enrl > size) {
        color = "section-capacity-oversold";
      } else if (capacity > size) {
        color = "section-capacity-overbooked";
      }

      if (!this.permission("can", "staff")) {
        color = "";
      }

      return (<React.Fragment>{crosslistvalue}<span className={color}>{value}</span></React.Fragment>);
    }

    return (<React.Fragment>{crosslistvalue}{value}</React.Fragment>);
  }

  get_crosslist_tooltip = (record, data) => {
    const { courses } = this.props;
    return "Crosslisted with " + this.print_crosslist(data, record.crosslist);
  }

  //Planned(unofficial) sections will not yet be assigned a section number or crn and will return undefined
  print_crosslist = (data, id) => {
    const section = data.find(el => el.id == id);
    if (section) {
      return this.print_section(section);
    } else {
      return "section " + id;
    }
  }

  render() {
    const { show_deleted } = this.state;
    const { data, hide_fields, warnings, show_fields, instructorDecorator, meetingtimeDecorator, semesters, course_planning_open, campus } = this.props;
    const showdata = data.filter(el => show_deleted ? true : !el.deleted);

    const uniqueCampuses = [...new Set(data.map(section => section.campus ? section.campus : "Unknown"))];

    const campusFilters = uniqueCampuses.map(campus => ({
      text: campus !== "Unknown" ? this.print_campus(campus): "Unknown",
      value: campus,
    }));

    // If section data doesn't return a campus, filters won't work as expected
    // This is used in the campus column to remove filters if this is the case
    const hasUnknown = campusFilters.some(campus => campus.text === "Unknown")

    const allcolumns = [
      {
        title: 'Semester',
        key: 'semester',
        fixed: 'left',
        width: 100,
        render: (text, record, idx) => this.print_semester(record.semester),
        download: (record) => this.print_semester(record.semester),
      }, {
        title: 'Course',
        key: 'course',
        fixed: 'left',
        width: 80,
        render: (text, record, idx) => record.course ? this.permission("can", "staff") || this.permission("can", "faculty") ? this.link_course(record.course) : this.print_course(record.course) : record.course_name,
        download: (record) => record.course ? this.print_course(record.course) : record.course_name,
      }, {
        title: 'Num',
        dataIndex: 'number',
        key: 'num',
        align: 'right',
        download: (record) => record.number,
      }, {
        title: 'Crosslist',
        key: 'crosslist',
        render: (text, record, idx) => record.crosslist ? (<Tooltip title={this.get_crosslist_tooltip(record, data)}><Icon type="swap" /></Tooltip>) : "",
        download: (record) => record.crosslist ? this.get_crosslist_tooltip(record, data) : ""
        // this.get_crosslist_tooltip(record, data)
      }, {
        title: '',
        key: 'ugrad_stats',
        render: (text, record, idx) => this.permission("can", "staff") || this.permission("can", "faculty") ? record.ugrad_stats ? (<Tooltip title="Counts for undergrad stats"><Icon type="bar-chart" /></Tooltip>) : null : null,
      }, {
        title: '',
        key: 'warnings',
        render: (text, record, idx) => warnings && warnings.find(el => el.id == record.id) ? (<Tooltip title={warnings.filter(el => el.id == record.id).map(el => el.warning).join(", ")}><Icon type="warning" theme="twoTone" twoToneColor="#faad14" /></Tooltip>) : "",
      }, {
        title: 'CRN',
        key: 'crn',
        align: 'right',
        render: (text, record, idx) => this.link_section(record.id, record.crn),
        download: (record) => record.crn,
      }, {
        title: 'Title',
        key: 'title',
        width: 300,
        render: (text, record, idx) => record.title ? <i>{record.title}</i> : record.course ? this.print_course_title(record.course) : null,
        download: (record) => record.title ? record.title : record.course ? this.get_course(record.course).title : null,
      }, {
        title: 'Attributes',
        key: 'honacc',
        render: (text, record, idx) => record.honors ? "HON" : record.accelerated ? "ACC" : record.nustart ? "NUSt" : record.nuin ? "NUin" : "",
        download: (record) => record.honors ? "HON" : record.accelerated ? "ACC" : record.nustart ? "NUSt" : record.nuin ? "NUin" : "N/A",
      }, {
        title: 'Campus',
        key: 'campus',
        render: (text, record, idx) => record.campus ? this.print_campus(record.campus) : "Unknown",
        download: (record) => this.print_campus(record.campus),
        filters: campusFilters.length < 1 || hasUnknown ? null : campusFilters,
        filterMultiple: true, 
        onFilter: (value, record) => record.campus == value,
      }, {
        title: 'Method',
        key: 'method',
        render: (text, record, idx) => this.print_method(record.method),
        download: (record) => this.print_method(record.method),
      }, {
        title: 'Meeting Time',
        key: 'meetingtime',
        width: 190,
        render: (text, record, idx) => [this.print_meetingtime(record.meetingtime)].concat(meetingtimeDecorator && record.meetingtime ? record.instructors.map(el => ["\u00A0", meetingtimeDecorator(record, el)]) : []),
        download: (record) => this.print_meetingtime(record.meetingtime),
      }, {
        title: 'Instructors',
        key: 'instructors',
        width: 150,
        render: (text, record, idx) => record.instructors ? oxford(record.instructors.map(el => [this.link_instructor(el), instructorDecorator ? ["\u00A0", instructorDecorator(record, el)] : []].flat())) : null,
        download: (record) => record.instructors ? this.print_instructor_list(record.instructors) : null,
      }, {
        title: 'IAs',
        key: 'ias',
        render: (text, record, idx) => record.ias ? oxford(record.ias.map(ia => ia.email ? <a href={"mailto:" + ia.email}>{ia.lastname}, {ia.firstname.charAt(0)}.</a> : ia.lastname + ", " + ia.firstname.charAt(0))) : null,
        download: (record) => record.ias ? oxford(record.ias.map(ia => ia.lastname + ", " + ia.firstname.charAt(0) + ".")) : null,
      }, {
        title: 'Room',
        key: 'room',
        width: 100,
        render: (text, record, idx) => this.permission("can", "staff") || this.permission("can", "faculty") ? [this.link_room(record.room), record.room && this.get_semester(record.semester).nuflex && this.get_room(record.room).nuflex_type ? [" (", this.print_nuflex_type(record.room), ")"] : ""] : this.print_room(record.room),
        download: (record) => this.print_room(record.room),
      }, {
        title: 'Size',
        key: 'size',
        align: 'right',
        render: (text, record, idx) => record.room ? this.get_semester(record.semester).nuflex ? this.get_room(record.room).nuflex_capacity : this.get_room(record.room).capacity : "",
        download: (record) => record.room ? this.get_semester(record.semester).nuflex && this.get_room(record.room).nuflex_capacity ? this.get_room(record.room).nuflex_capacity : this.get_room(record.room).capacity : "",
      }, {
        title: 'Capacity',
        dataIndex: 'capacity',
        key: 'capacity',
        align: 'right',
        render: this.formatCapacity,
        download: (record) => record.capacity
      }, {
        title: 'Enrollment',
        dataIndex: 'enrollment',
        key: 'enrollment',
        align: 'right',
        render: this.formatEnrollment,
        download: (record) => record.enrollment
      }, {
        title: 'Bucket',
        key: 'bucket',
        dataIndex: 'enrollment',
        align: 'right',
        render: this.formatBucket,
        download: (record) => this.bucket(record.capacity + (record.crosslist && data.find(el => el.id == record.crosslist) ? record.crosslist.capacity : 0)),
      }, {
        title: 'Waitlist',
        dataIndex: 'waitlist_enrollment',
        key: 'waitlist',
        align: 'right',
        download: (record) => record.waitlist_enrollment,
      }, {
        title: 'TRACE',
        dataIndex: 'trace_mean',
        key: 'trace',
        render: (text, record, idx) => (<NumberFormat displayType="text" decimalScale={1} fixedDecimalScale={true} value={text} />),
        align: 'right',
        download: (record) => record.trace_mean,
      }, {
        title: 'Load',
        key: 'loadcount',
        render: (text, record, idx) => (<NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.get_total_section_loadcount(record, record.crosslist ? data.find(s => s.id == record.crosslist) : null)} />),
        align: 'right',
        download: (record) => this.get_total_section_loadcount(record, record.crosslist ? data.find(s => s.id == record.crosslist) : null),
      }, {
        title: 'Notes',
        key: 'notes',
        render: (text, record, idx) => record.notes ? (<Tooltip title={record.notes}><Icon type="solution" /></Tooltip>) : "",
        align: 'right',
        download: (record) => (record.notes)
      }, {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        width: 80,
        fixed: 'right',
        render: this.props.getActions,
      }];

    var hide = this.permission("can", "admin") || (this.props.planning && (this.permission("can", "ugradadmin") || this.permission("can", "gradadmin"))) ? hide_fields.concat([""]) : hide_fields.concat(["actions", ""]);
    hide = this.permission("can", "staff") ? hide : this.props.trace ? hide.concat(["loadcount", "bucket"]) : hide.concat(["trace", "loadcount", "bucket"]);
    hide = !warnings ? hide.concat(["warnings"]) : hide;


    if (!this.get_semester(semesters[0]).nuflex) {
      hide = hide.concat(["ias"]);
    }

    if (show_fields && show_fields.includes("trace")) {
      hide = hide.filter(x => x != "trace");
    }

    if (show_fields && show_fields.includes("campus")) {
      hide = hide.filter(x => x != "campus");
    }

    if (show_fields && show_fields.includes("withdraw")) {
      allcolumns.push({ title: "Withdrawn?", key: "withdraw", align: 'right', render: (text, record, idx) => record.withdraw ? get_nocheck() : null });
    }

    const columns = allcolumns.filter(el => !hide.includes(el.key));
    const hide_links = this.props.hide_links || false;

    return (
      <span>
        <Form layout="inline">
          {hide_links || this.props.planning || !(this.permission("can", "staff") || this.permission("can", "faculty")) ? null : (
            <FormItem label="Show Deleted">
              <Switch checked={show_deleted} onChange={this.handleToggle('show_deleted')} />
            </FormItem>
          )}

          {this.props.onNew ? (<React.Fragment><Button type="primary" icon="plus" onClick={this.props.onNew} disabled={!course_planning_open}>New Section</Button> </React.Fragment>) : null}
          {this.props.onImport ? (<React.Fragment><Button icon="import" onClick={this.props.onImport} disabled={!course_planning_open}>Import</Button> </React.Fragment>) : null}
          {this.props.onRefresh ? (<React.Fragment><Button icon="redo" onClick={this.props.onRefresh} style={{ float: 'right' }}>Refresh</Button> </React.Fragment>) : null}
          {this.permission("cannot", "student") && !hide_links ? (<CSVLink filename="schedule_export.csv" data={this.getCsvData(showdata, columns)}><Button icon="download">Download</Button></CSVLink>) : null}
        </Form>
        <p />

        <Table {...this.props} tableLayout='auto' dataSource={showdata} columns={columns} scroll={{ x: true }} bordered={false} pagination={false} size="small" rowKey="id" rowClassName={(obj, idx) => obj.deleted ? "schedule-section-cancelled" : warnings && warnings.find(el => el.id == obj.id) ? "schedule-section-warning" : null} />
      </span>
    );
  }
}

SectionTable.defaultProps = {
  hide_fields: [],
  getActions: () => "",
}

export default SectionTable;