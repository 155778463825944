import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import { CourseWaiverForm } from "../GenericForms";
import Transcript from "../../../Transcript";

import { Icon, Radio, Table, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

class MSCourseCreditWaiver extends AppComponent {
  render() {
   const { record } = this.props;
   const item = record.record;

   let course_petition = item.course_petition;
   let waiverTypes =  course_petition.waiver_types.map(el => el.waiver_type);
  
    return (
        <Descriptions bordered title={ "MS Course/Transfer Petition submission by " + record.created_by}>
          <Descriptions.Item label="Submitted" span={ 2 }>{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" span={ 2 }>{[ record.created_by + " ", <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" span={ 2 }>{ item.student.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 2 }>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(item.student.campus) }</Descriptions.Item>
          <Descriptions.Item label="Core Class" span={ 2 }>{this.print_course(course_petition.khoury_course)}</Descriptions.Item>
          <Descriptions.Item label="Waiver Type" span={ 2 }>{ waiverTypes.join(", ") }</Descriptions.Item>
          {course_petition.non_khoury_courses.map((el, idx) => (
            [
              <Descriptions.Item label="" span={ 4 }><strong>Non-Khoury Course Information {+ idx + 1}</strong></Descriptions.Item>,
              <Descriptions.Item label="Course Name" span={ 2 }>{ el.course }</Descriptions.Item>,
              <Descriptions.Item label="University / College" span={ 2 }>{ el.university }</Descriptions.Item>,
              <Descriptions.Item label="Credits" span={ 2 }>{ el.credits }</Descriptions.Item>,
              <Descriptions.Item label="Semester" span={ 2 }>{ this.print_semester(el.semester) }</Descriptions.Item>,
              <Descriptions.Item label="Instructor Name" span={ 2 }>{ el.instructor_name }</Descriptions.Item>,
              <Descriptions.Item label="Instructor Email" span={ 2 }>{ <a key="email" href={"mailto:" + el.instructor_email }>{el.instructor_email}</a>}</Descriptions.Item>,
              <Descriptions.Item label="Course Grade" span={ 2 }>{ el.grade ? this.print_grade(el.grade): "N/A" }</Descriptions.Item>,
              <Descriptions.Item label="Graduate Level" span={ 2 }>{ el.course_level? "Yes": "No" }</Descriptions.Item>,
              <Descriptions.Item label="Count towards another degree?" span={ 2 }>{ el.course_degree? "Yes": "No" }</Descriptions.Item>,
              <Descriptions.Item label="Notes" span={ 2 }>{ el.rationale }</Descriptions.Item>
            ]
          ))}
          { waiverTypes.includes("Experience") && [
            <Descriptions.Item label="" span={ 4 }><strong>Relevant Work Experience</strong></Descriptions.Item>,
            <Descriptions.Item label="Rationale" span={ 2 }>{ course_petition.work_experience }</Descriptions.Item>
          ]}
          <Descriptions.Item label="Attached Files" span={ 4 }>{[record.files.map(el => <><a onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type +".pdf"}</a><br /></>) ]}</Descriptions.Item>
        </Descriptions>

      );
   }
}


class MSCourseCreditWaiverOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ms/coursepetition/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "MS Course/Credit Petition";
  }

  get_workflowtype = () => {
    return "mscoursepetition";
  }

  get_name_plural = () => {
    return "MS Course/Credit Petitions";
  }     
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "MS" }, { text: "Course Petition" }];
  }
  
  get_form = (func) => {
    return <CourseWaiverForm {...this.props} formType={"ms"} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <MSCourseCreditWaiver {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
      <>
        <p>Please use the below form to submit a MS course or credit petition, for more information <strong><a href="https://www.khoury.northeastern.edu/information-for-overview/current-masters-and-certificate-students/masters-academic-support/masters-academic-procedures/" target="_blank">please visit here.</a></strong> You can either:</p>
        <ul>
          <li>Request the waiving of a core course requirement, usually to be replaced with another course. Must be submitted one week before your program begins.</li>
          <li>Transfer credit from another institution to your program in Khoury College. Can be submitted any time after matriculation.</li>
        </ul>
      </>
    )
  }  
}

export { MSCourseCreditWaiverOverview, MSCourseCreditWaiver };