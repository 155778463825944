import React, { Component } from "react";
import key from "weak-key";
import moment from "moment";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './../AppComponent';
import StatusComponent from './../StatusComponent';
import { Status } from './../StatusComponent';
import Preference from './../Preference';
import Content from './../Content';
import TAApplicationTable from './TAApplicationTable';
import TAHoursTable from './TAHoursTable';
import CustomTabs from './../CustomTabs';
import { isEmpty, add_dividers, add_dividers_horiz, format_percent, format_decimal, getTAStatus, add_brs, oxford } from './../Utils';

import { Rate, PageHeader, Descriptions, Timeline, Collapse, Table, Alert, Form, Tabs, Switch, Button, Card, Radio, List, Layout, Input, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, message, Modal } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

class TAApplicationReviewForm extends AppComponent {
  options = [{ id: 0, text: "No"},
             { id: 1, text: "No answer"},
             { id: 2, text: "Maybe"},
             { id: 3, text: "Probably"},
             { id: 4, text: "Definitely"},
             { id: 5, text: <Icon type="delete" />}
             ];

  render() {
    const { taapplication, pref, getPrefs, groupOrCourse } = this.props;

    return  ( <Preference {...this.props}
                          style={{ width: "450px"}}
                          key={ "pref-" + taapplication.id }
                          defaultValue={ null }
                          value={ pref }
                          options={ this.options }
                          onChange={ getPrefs }
                          editable={ (taapplication.state == "Submitted" || taapplication.state == "Reviewed") || (taapplication.campus && groupOrCourse.group) }
                          endpoint="/api/ta/rate/"
                          addNewData={ f => { f["taapplication"] = taapplication.id; f["instructor"] = this.props.user.instructor; return f; } } /> );
  }
}

class TAApplicationStudentModal extends AppComponent {
  state = {
    visible: false,
  }

  reviewTable = ( data, hide_fields ) => {
    let columns = [
      {
        title: 'Course',
        key: 'course',
        width : 100, 
        render: (text, record, idx) => this.print_course(record.course),
      },{
        title: 'Semester',
        key: 'semester',
        width : 100, 
        render: (text, record, idx) => this.print_semester(record.semester),
      },{
        title: 'Instructor',
        key: 'instructor',
        width : 150, 
        render: (text, record, idx) => this.print_instructor(record.instructor),
      },{
        title: 'Preference',
        key: 'preference',
        width : 150, 
        render: (text, record, idx) => <Rate disabled count={ 3 } key={record.id} defaultValue={ record.preference > 1 ? record.preference - 1 : record.preference } />,
      },{
        title: 'Notes',
        key: 'notes',
        render: (text, record, idx) => record.notes? record.notes : "",
      }];

    columns = columns.filter(el => !hide_fields || !hide_fields.includes(el.key));

    return(
      <Table {...this.props} dataSource={ data } columns={ columns } bordered={ false } pagination={ false } size="small" rowKey="id" key={ "table" } />
    )
  }

  truncateDisplayedEmail = ( email ) => {
    return email && email.length > 20 ? email.substring(0, 20) + "..." : email;
  }

  genericDetails = (taapplication) => {
    return (
      <React.Fragment>
        <Descriptions column={ 1 } bordered>
          <Descriptions.Item label="Email" span={ 2 }><a key="email" href={"mailto:" + taapplication.ta.email }>{ this.truncateDisplayedEmail(taapplication.ta.email) }</a></Descriptions.Item>
          <Descriptions.Item label="Level" span={ 2 }>{ taapplication.ta.level == "PH" ? "PhD" : taapplication.ta.level }</Descriptions.Item>
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(taapplication.ta.campus) }</Descriptions.Item>
          <Descriptions.Item label="GPA(*)" span={ 2 }>{ taapplication.ta.gpa == 0 ? "No data" : taapplication.ta.gpa }</Descriptions.Item>
        </Descriptions>
      </React.Fragment>
    )
  }

  applicantDetails = ( taapplication ) => {
    return (
      <React.Fragment>
        <Descriptions column={ 1 } bordered>
          <Descriptions.Item label="Rank" span={ 2 }>{ this.print_ta_preference(taapplication.preference) }</Descriptions.Item>
          <Descriptions.Item label="Taken" span={ 2 }>{ taapplication.semester_taken ? this.print_semester(taapplication.semester_taken) : "No" }</Descriptions.Item>
          { taapplication.semester_taken &&
          <Descriptions.Item label="Grade(*)" span={ 2 }>{ taapplication.grade ? this.print_grade(taapplication.grade) : "N/A" }</Descriptions.Item>}
          { taapplication.ta.available_date != null && (
            <Descriptions.Item label="Available On" span={ 2 }>{ moment(taapplication.ta.available_date, "YYYY-MM-DD").format('M/DD') }</Descriptions.Item>)}
          { !taapplication.ta.available && (
            <Descriptions.Item label="Looking for Co-Ops?" span={ 2 }>{ "Yes" }</Descriptions.Item>)}
          <Descriptions.Item label="Resume" span={ 2 }>{ taapplication.ta.resume ? <a key="resume" onClick={ () => this.openPDF(taapplication.ta.resume, "resume-" + taapplication.ta.firstname + "-" + taapplication.ta.lastname + ".pdf") }>Download</a> : "None"  }</Descriptions.Item>
          
        </Descriptions>
      </React.Fragment>
    )
  }

  findTAReviews = (taapplication, course, semester) => {
    let tareviews = taapplication.reviews ? taapplication.reviews.filter(el => el.preference != 1 && el.course == course && el.semester == semester) : [];
    return (
    <React.Fragment>
      <p>{
        tareviews.map(
        review => <p><Rate disabled count={ 3 } key={review.id} defaultValue={ review.preference > 1 ? review.preference - 1 : review.preference } />
        <span> by {" "}
        <strong>{this.print_full_instructor(review.instructor)}</strong>
        {(review.notes ? " - " + review.notes : "")}</span></p>)
      }</p>
            
    </React.Fragment>)
  }

  render() {
    const { visible } = this.state;
    const { taapplication } = this.props;

    const overview = [
      { title: "Photo", content: <div className="student-photo"><img src={ taapplication.ta.photo_url }/></div> },
      { title: "Generic TA Details", content: <div>{this.genericDetails(taapplication)}</div> },
      { title: "Course Specific Details", content: <div>{this.applicantDetails(taapplication)}</div> },
    ];

    return (
      <React.Fragment>
        <Button onClick={ () => this.setState({ visible: true })}>View</Button>
        <Modal width={ 1400 } visible={ visible } title={ this.print_course(taapplication.course) + " - " + this.print_full_student(taapplication.ta) } 
        footer={ <Button type="primary" onClick={ () => this.setState({ visible: false }) }>Close</Button> } onCancel={ () => this.setState({ visible: false })}>
          <Card bordered={false} style={{ overflow: 'auto', height: 'auto' }}>
          <p>Below is an overview of { taapplication.ta.firstname } as a TA and their TA application to your course. <b>Note:</b> Fields with an asterisk are self reported.</p>
          <Divider orientation="left">Overview</Divider>
          <List
            grid={ this.grid }
            dataSource={ overview }
            renderItem={item => ( <List.Item><Card size="small" title={ item.title }>{ item.content }</Card></List.Item> )}
          />
           <Divider orientation="left">Student Provided Answers to Application Questions</Divider>
            <p><b>Why do you want to be a TA?</b></p>
            <p style={{ whiteSpace: "pre-wrap" }}>{ taapplication.ta.why_become_ta_question ? taapplication.ta.why_become_ta_question : "N/A" }</p>
            <p><b>What excites you and what challenges do you foresee as a TA?</b></p>
            <p style={{ whiteSpace: "pre-wrap" }}>{ taapplication.ta.excites_challenges_as_ta_question ? taapplication.ta.excites_challenges_as_ta_question : "N/A" }</p>
            <p><b>Note (free-form field) from student on this specific application:</b></p>
            <p style={{ whiteSpace: "pre-wrap" }}>{ taapplication.notes ? taapplication.notes : "No notes attached." }</p>

          <Divider orientation="left">TA History</Divider>
          <p>Below is a brief timeline of TA positions held by { taapplication.ta.firstname }.
          The colors of the bullets represent different states:  <span className="ant-timeline-item-head ant-timeline-item-head-green" />&nbsp;&nbsp;&nbsp; 
          hired applications, <span className="ant-timeline-item-head ant-timeline-item-head-red" />&nbsp;&nbsp;&nbsp; 
          and all those that have been resigned, rejected or refused.</p>
          { taapplication.history.length != 0 ? 
            ( taapplication.history.map(el => 
            <>
            <Timeline.Item key={ this.print_course(el.course) } color={ el.state == "Rejected" ? "red" : "green" }>
            <b>{ this.print_course(el.course) }</b>: { this.print_semester(el.semester)} - { el.state }
            {this.findTAReviews(taapplication, el.course, el.semester)}
            </Timeline.Item> 
            </>)) : <i>No previous TA positons.</i> 
          }
          <Divider orientation="left">Recommendations</Divider>
          <p>Below are all course staff recommendations of { taapplication.ta.firstname} as a student.</p>
          { this.reviewTable( taapplication.recommendations ? taapplication.recommendations.filter(el => el.preference != 1) : [], ["semester"] )}
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}


class TAApplicationReview extends AppComponent {
  state = {
      endpoint_schedule: "/api/schedule/",
      sections: [],
      sections_staff: [],
      loading_schedule: true,
      loading_sections_staff: true,

      endpoint_taapplication: "/api/ta/application/",
      taapplications: [],
      loading_taapplications: true,
      
      endpoint_groups: "/api/schedule/group/",
      groups: [],
      loading_groups: true,

      endpoint_tareview: "/api/ta/rate/",
      tareviews: [],
      loading_tareviews: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taapplication + "?semester=" + this.props.semesters.join(","),
                  data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_schedule + "?semester=" + this.props.semesters.join(",") + "&instructors=" + this.props.user.person.id,
                  data => this.setState({ sections: data , loading_schedule: false }));
    this.doGet(this.state.endpoint_schedule + "?semester=" + this.props.semesters.join(",") + "&instructor_staff=" + this.props.user.person.id,
                  data => this.setState({ sections_staff: data , loading_sections_staff: false }));
    this.doGet(this.state.endpoint_groups + "?semester=" + this.props.semesters.join(","),
                  data => this.setState({ groups: data , loading_groups: false }));
                  
    this.getReviews();
  }

  getReviews = () => {
    this.doGet(this.state.endpoint_tareview + "?taapplication__semester=" + this.props.semesters.join(",") ,
                  data => this.setState({ tareviews: data, loading_tareviews: false }));
  }

  render() {
    const { semester, tab } = this.props;
    const { loading_schedule, sections, sections_staff, loading_sections_staff, loading_taapplications, taapplications, loading_tareviews, tareviews, loading_groups, groups } = this.state;

    const loading = loading_schedule || loading_taapplications || loading_tareviews || loading_sections_staff || loading_groups;
    const mysections = sections.filter(el => el.loadcount > 0).concat(sections_staff).filter(el => this.get_course(el.course).lab_for.length == 0);
    var mygroups = groups.filter(g => g.sections.find(s => mysections.map(mys => mys.id).includes(s.id)) != null);
    const mysections_not_in_groups = mysections.filter(s => groups.find(g => g.sections.find(gs => gs.id == s.id)) == null);
    const course_list = this.course_campus_semester_list_from_sections(mysections_not_in_groups);
    
    mygroups = mygroups.map(g => {
      return { group: g, enrollment: g.sections.filter(el => !el.deleted).reduce((r,a) => r + a.enrollment, 0), ta_ratio: 25, semester: g.sections[0].semester };
    });
    
    const group_course_list = mygroups.concat(course_list);

    return (
      <Content {...this.props} title={ semester + " TA Reviews" } breadcrumbs={ [{ link: "/teaching", text: "Teaching" }, { text: "TA" }, { text: "Reviews"}] }>
        <p>Welcome to the new TA review form.  This site is designed for faculty to review applications to serve as course assistants for their courses. For each course you are scheduled to teach, you can submit reviews below using the buttons on the right side. For each student, we have shown their self-reported data regarding their academic performance and their previous course assistant history. Note that the list below only includes students <i>not</i> already assigned to your class.</p>

        <p>As you fill out the reviews, all of your entries will be automatically saved; if an error occurs, a popup will be shown.  The applications are broken down into those you have yet to review, those who you have already reviewed, and those who have been assigned to your class.</p>
        
        <p>In the table below, we also include suggestions about potential future TAs from other instructors.  <b>Recommendations</b> refers to suggestions of good future TAs from students enrolled in the class.  <b>TA Review</b> refers to suggestions for students who served as a TA.  In both cases, you can mouseover the suggestions to view more information about the suggestion. The <b>shaded rows</b> in the table for sections with dedicated TAs refer to applicants who applied to all sections.</p>

        <p>Below is a list of TA statuses and their meaning:</p>
        <ul>
          <li><i>Submitted</i> - The student has submitted an application</li>
          <li><i>Reviewed</i> - A faculty member / staff course coordinator has reviewed their application.</li>
          <li><i>Pre-Assigned</i> - The student has been assigned but has not been notified as they might still be moved around.</li>
          <li><i>Assigned</i> - The student has been (visible to them) assigned to a course or section group.</li>
          <li><i>Accepted / Refused</i> - The student has either accepted or refused the position.</li>
          <li><i> I-9 incomplete</i> - means 1 of 2 things:
          <ul>
            <li>The student completed the I-9 process, but SEO has not processed it. <b>Can</b> begin working.</li>
            <li>The student has not completed the I-9 process. <b>Cannot</b> begin working.</li>
          </ul>
          <p><b>The student should know whether they completed the I-9 process or not.</b></p>
          </li>
          <li><i>EIB / Hire-Submitted</i> - I-9 processed by SEO. Khoury HR has submitted the student’s hire in Workday (waiting on SEO to approve). The student can begin working if they haven’t already.</li>
          <li><i>Hired</i> - SEO has approved the hire request. The student has been fully hired and sent time sheet information. The student now has access to timesheets.</li>
          <li><i>Rejected / Resigned</i>  - They have left the position.</li>
        </ul>  

        <p><i>Note:</i> You may need to disable some of your ad blockers on this site if you wish to view resumes (they open using a base64 blob link, which some ad blockers prevent from working).</p>

        { group_course_list.map((gc, idx) => {
          var apps = [];
          var courseTARation;
          
          if (gc.course) {
            apps = taapplications.filter(el => el.course == gc.course && el.campus == gc.campus && el.semester == gc.semester);
            courseTARation = this.get_course(gc.course).ta_ratio
          } else {
            const group_apps = taapplications.filter(taa => taa.group == gc.group.id);
            const course_apps = taapplications.filter(taa => gc.group.sections.find(s => taa.course == s.course && taa.semester == s.semester && taa.campus == s.campus));
            
            apps = group_apps.concat(course_apps.filter(taa => group_apps.find(ga => ga.ta.id == taa.ta.id) == null));
            courseTARation = this.get_course(gc.group.sections[0].course).ta_ratio
          }

          apps = apps.filter(el => el.unassignable == false)
          const specific_tareviews = tareviews.filter(el => el.instructor == this.props.user.instructor);

          const assigned = apps.filter(el => (el.state == "Pre-Assigned" || el.state == "Assigned" || el.state == "Accepted" || el.state == "Hire-Submitted" || el.state == "EIB" || el.state == "I-9-incomplete" || el.state == "Hired") && (gc.course || (el.group == gc.group.id)));          
          const not_assigned = apps.filter(el => assigned.find(ael => ael.id == el.id) == null);
          
          const not_reviewed = not_assigned.filter(el => specific_tareviews.find(elr => elr.taapplication == el.id) == null);
          const reviewed = not_assigned.filter(el => specific_tareviews.find(elr => elr.taapplication == el.id));
          
          const enrolled = gc.enrollment;
          const estimated = Math.round(enrolled * this.get_semester(gc.semester).speed / (1.0 * (courseTARation)));
          
          return (
          <React.Fragment key={ "frag-" + idx }>
            <Divider orientation="left">{ gc.course ? [this.print_full_course(gc.course), ", ", this.print_campus(gc.campus), ", ", this.print_semester(gc.semester)] : gc.group.name } ({ enrolled } enrolled, { estimated } TAs estimated)</Divider>
            <CustomTabs  {...this.props} default_Active_Key={tab}>
              <TabPane tab="Not reviewed" disabled={ !loading && not_reviewed.length == 0 } key="not-reviewed">
                <p>The table below shows the TAs you have yet to review.  Once you review them, they will move to the "Reviewed" tab, where you can edit your review later if you wish.  Once TAs begin being assigned to your class, they move to the "Assigned" tab.</p>
                <p> If needed, you can <a href={ "mailto:" + not_reviewed.map(el => el.ta.email).join(",") }>send an email to all these TAs</a>.</p>
                <TAApplicationTable {...this.props} campus={ gc.campus } taapplications={ not_reviewed } 
                groups={ groups } allTaapplications={ taapplications } loading={ loading } 
                tareviews={ tareviews } hide_fields={ ["jobId", "nuid", "payRate", "hours", "course", "username", "semester", "submitted_at"] }
                getTAProfile={ e => <TAApplicationStudentModal {...this.props}  taapplication={ e }/>}
                getAction={ e => 
                  <TAApplicationReviewForm {...this.props} taapplication={ e } key={ "review-" + e.id } 
                    pref={ specific_tareviews.find(el => el.taapplication == e.id) } 
                    getPrefs={ this.getReviews } groupOrCourse={ gc } /> }
                actionWidth={ 400 } rowClassName={ record => gc.group && record.campus ? "taapplication-course" : null } />
              </TabPane>

              <TabPane tab="Reviewed" disabled={ !loading && reviewed.length == 0 } key="reviewed">
                <p>The table below shows the TAs you have already reviewed, but who have not been assigned to your course.  You can update your review if you wish.</p>
                <p> If needed, you can <a href={ "mailto:" + reviewed.map(el => el.ta.email).join(",") }>send an email to all these TAs</a>.</p>
                <TAApplicationTable {...this.props} campus={ gc.campus } taapplications={ reviewed } 
                  groups={ groups } allTaapplications={ taapplications } loading={ loading } 
                  tareviews={ tareviews } 
                  hide_fields={ ["jobId", "nuid", "payRate", "hours", "course", "username", "semester", "submitted_at"] } 
                  getAction={ 
                    e => 
                      <TAApplicationReviewForm {...this.props} taapplication={ e } key={ "review-" + e.id } 
                        pref={ specific_tareviews.find(el => el.taapplication == e.id) } 
                        getPrefs={ this.getReviews } groupOrCourse={ gc } /> 
                    }
                  actionWidth={ 400 }
                  getTAProfile={ e => <TAApplicationStudentModal {...this.props}  taapplication={ e }/>} rowClassName={ record => gc.group && record.campus ? "taapplication-course" : null }
                />
              </TabPane>
              <TabPane tab="Assigned" disabled={ !loading && assigned.length == 0 } key="assigned">
                { gc.course ? <p>The table below shows the TAs assigned to <i>all</i> instances of { this.print_course(gc.course) } in { this.print_campus(gc.campus) } during the { this.print_semester(gc.semester) } semester, except for any sections that have been requested to be assigned separtely.  Thus, if there are multiple sections taught by different instructors, these TAs will need to be divided between the instructors.</p> : <p>The table below shows the TAs assigned to <i>all</i> sections in the { gc.group.name } group of sections.</p> }
                <p>If needed, you can <a href={ "mailto:" + assigned.map(el => el.ta.email).join(",") }>send an email to all these TAs</a>.</p>
                <TAApplicationTable {...this.props} campus={ gc.campus } taapplications={ assigned } groups={ groups } allTaapplications={ taapplications } loading={ loading } tareviews={ tareviews } hide_fields={ ["jobId", "nuid", "payRate", "hours", "course", "username", "semester", "submitted_at"] } getAction={ e => <TAApplicationReviewForm {...this.props} taapplication={ e } key={ "review-" + e.id } pref={ specific_tareviews.find(el => el.taapplication == e.id) } getPrefs={ this.getReviews } groupOrCourse={ gc } /> } getTAProfile={ e => <TAApplicationStudentModal {...this.props}  taapplication={ e }/>} actionWidth={ 400 } rowClassName={ record => gc.group && record.campus ? "taapplication-course" : null } />
              </TabPane>
            </CustomTabs>
          </React.Fragment> );
        } ) }

        { group_course_list.length == 0 ? ( <Alert message="No sections found." description={ "We did not find any sections that you are listed as an instructor for during the " + semester + " semester." } type="warning" showIcon /> ) : null }
      </Content>
    );
  }
}

class TAHoursFaculty extends AppComponent {
  state = {
      endpoint_schedule: "/api/schedule/",
      sections: [],
      sections_staff: [],
      loading_schedule: true,
      loading_sections_staff: true,

      endpoint_taapplication: "/api/ta/application/",
      taapplications: [],
      loading_taapplications: true,

      endpoint_groups: "/api/schedule/group/",
      groups: [],
      loading_groups: true,

      endpoint_tahours: "/api/ta/hours/",
      tahours: [],
      loading_tahours: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.setState({ loading_schedule: true, loading_sections_staff: true, loading_taapplications: true, loading_tahours: true } , () => this.getData());
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taapplication + "?semester=" + this.props.semesters.join(",") + "&state=Hired",
                  data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_schedule + "?deleted=False&semester=" + this.props.semesters.join(",") + "&instructors=" + this.props.user.person.id,
                  data => this.setState({ sections: data , loading_schedule: false }));
    this.doGet(this.state.endpoint_schedule + "?semester=" + this.props.semesters.join(",") + "&instructor_staff=" + this.props.user.person.id,
                  data => this.setState({ sections_staff: data , loading_sections_staff: false }));
    this.doGet(this.state.endpoint_tahours + "?taapplication__semester=" + this.props.semesters.join(","),
                  data => this.setState({ tahours: data, loading_tahours: false }));
    this.doGet(this.state.endpoint_groups + "?semester=" + this.props.semesters.join(","),
                  data => this.setState({ groups: data , loading_groups: false }));
  }

  render() {
    const { sections, sections_staff, loading_schedule, taapplications, loading_taapplications, tahours, loading_tahours, loading_sections_staff, loading_groups, groups } = this.state;
    const { user, semester } = this.props;

    const loading = loading_schedule || loading_taapplications || loading_tahours || loading_sections_staff || loading_groups;

    const mysections = sections.filter(el => el.loadcount > 0).concat(sections_staff).filter(el => this.get_course(el.course).lab_for.length == 0);
    var mygroups = groups.filter(g => g.sections.find(s => mysections.map(mys => mys.id).includes(s.id)) != null);
    const mysections_not_in_groups = mysections.filter(s => groups.find(g => g.sections.find(gs => gs.id == s.id)) == null);
    const course_list = this.course_campus_semester_list_from_sections(mysections_not_in_groups);
    
    mygroups = mygroups.map(g => {
      return { group: g, semester: g.sections[0].semester };
    });

    const group_course_list = mygroups.concat(course_list);


    return (
      <Content {...this.props} title={ semester + " TA Hours Billed" } breadcrumbs={ [{ link: "/teaching", text: "Teaching" }, { text: "TA" }, { text: "Hours"}] }>
      { group_course_list.map((gc, idx) => {
        var apps = [];
        
        if (gc.course) {
          apps = taapplications.filter(el => el.course == gc.course && el.campus == gc.campus && el.semester == gc.semester);
        } else {
          apps = taapplications.filter(taa => taa.group == gc.group.id);
        }

        return (
          <React.Fragment key={ "frag-" + gc.id }>
            <Divider orientation="left">{ gc.course ? [this.print_full_course(gc.course), ", ", this.print_campus(gc.campus), ", ", this.print_semester(gc.semester)] : gc.group.name }</Divider>
            <TAHoursTable {...this.props} key={ "table-" + gc.id } taapplications={ apps } tahours={ tahours } loading={ loading } hide_fields={ ['course', 'pay_rate', 'total'] } tasemester={ gc.semester } />
            </React.Fragment> );
        } ) }

        { group_course_list.length == 0 ? ( <Alert message="No sections found." description={ "We did not find any sections that you are listed as an instructor for during the " + semester + " semester." } type="warning" showIcon /> ) : null }
      </Content>
    );
  }
}

const TAReviewForm = Form.create({ name: 'form_not_in_modal' })(
  class extends AppComponent {
    state = {
      changed: false,
    }
    
    render() {
      const { review, taapplication, handleCreateUpdate, form, disabled } = this.props;
      const { getFieldDecorator } = form;
      const { changed } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      
      return (
        <Form>
          <FormItem {...formItemLayout} label=" "><div className="student-photo"><img src={ taapplication.ta.photo_url } /></div></FormItem>
          <FormItem {...formItemLayout} label="Hire again?" extra="Please enter whether you would recommend this TA to another instructor.">
            {getFieldDecorator('preference', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review && review.preference != 1 ? review.preference : null,
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 220 }} disabled={ disabled } >
                <Option key={ 0 } value={ 0 }>No</Option> 
                <Option key={ 2 } value={ 2 }>Maybe</Option> 
                <Option key={ 3 } value={ 3 }>Probably</Option> 
                <Option key={ 4 } value={ 4 }>Definitely</Option> 
              </Select>)}
          </FormItem>
          <FormItem {...formItemLayout} label="Notes" extra="Please provide any additional comments you wish about your review.">
            {getFieldDecorator('notes', {
              initialValue: review ? review.notes : null,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={ 4 } disabled={ disabled }/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label=" " colon={ false }>
            <Button type="primary" onClick={ () => handleCreateUpdate(taapplication, form, () => this.setState({ changed: false })) } disabled={ disabled || !changed }>{ review == null ? "Submit" : "Update" }</Button>
          </FormItem>
        </Form> 
      );
    }
  }
);

const TARecommendationForm = Form.create({ name: 'form_not_in_modal' })(
  class extends AppComponent {
    state = {
      changed: false,
    }
    
    render() {
      const { recommendation, student, section, handleCreateUpdate, form, disabled } = this.props;
      const { getFieldDecorator } = form;
      const { changed } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      
      return (
        <Form>
          <FormItem {...formItemLayout} label=" " colon={false}><div className="student-photo"><img src={ student.photo_url } /></div></FormItem>
          <FormItem {...formItemLayout} label="Recommend?" extra="Please enter whether you would recommend this student as a potential future TA.">
            {getFieldDecorator('preference', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: recommendation && recommendation.preference != 1 ? recommendation.preference : null,
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 220 }} disabled={ disabled } >
                <Option key={ 0 } value={ 0 }>No</Option> 
                <Option key={ 2 } value={ 2 }>Maybe</Option> 
                <Option key={ 3 } value={ 3 }>Probably</Option> 
                <Option key={ 4 } value={ 4 }>Definitely</Option> 
              </Select>)}
          </FormItem>
          <FormItem {...formItemLayout} label="Notes" extra="Please provide any additional comments you wish about your recommendation.">
            {getFieldDecorator('notes', {
              initialValue: recommendation ? recommendation.notes : null,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={ 4 } disabled={ disabled }/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label=" " colon={ false }>
            <Button type="primary" onClick={ () => handleCreateUpdate(student, section, form, () => this.setState({ changed: false })) } disabled={ disabled || !changed }>{ recommendation == null ? "Submit" : "Update" }</Button>
          </FormItem>
        </Form> 
      );
    }
  }
);



class TAReview extends AppComponent {
  state = {
      endpoint_schedule: "/api/schedule/",
      sections: [],
      sections_staff: [],
      loading_schedule: true,
      loading_schedule_staff: true,

      endpoint_groups: "/api/schedule/group/",
      groups: [],
      loading_groups: true,

      endpoint_review: "/api/ta/review/",
      reviews: [],
      loading_reviews: true,

      endpoint_recommendation: "/api/ta/recommendation/",
      recommendations: [],
      loading_recommendations: true,

      endpoint_section: "/api/schedule/section/",
      endpoint_taapplication: "/api/ta/application/",

      modal_visible: false,
      modal_student: null,
      modal_section: null,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.setState({ loading_schedule: true, loading_reviews: true, loading_schedule_staff: true, loading_groups: true, sections: [], sections_staff: [], reviews: [], groups: [] } , () => this.getData());
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_schedule + "?deleted=False&semester=" + this.props.semesters.join(",") + "&instructors=" + this.props.user.person.id, data => this.setState({ sections: data , loading_schedule: false }));
    this.doGet(this.state.endpoint_schedule + "?deleted=False&semester=" + this.props.semesters.join(",") + "&instructor_staff=" + this.props.user.person.id, data => this.setState({ sections_staff: data , loading_schedule_staff: false }));
    this.doGet(this.state.endpoint_groups + "?semester=" + this.props.semesters.join(","),
    data => this.setState({ groups: data , loading_groups: false }, this.getSections));
    this.getReviews();
  }
  
  getReviews = () => {
    this.doGet(this.state.endpoint_review + "?semester=" + this.props.semesters.join(",") + "&instructor=" + this.props.user.instructor,
                  data => this.setState({ reviews: data , loading_reviews: false }));
    this.doGet(this.state.endpoint_recommendation + "?section__semester=" + this.props.semesters.join(",") + "&instructor=" + this.props.user.instructor,
                  data => this.setState({ recommendations: data , loading_recommendations: false }));
    
  }
  
  getSections = () => {
    const { sections, sections_staff, groups, loading_schedule, loading_schedule_staff, loading_groups } = this.state;
    
    if (!loading_schedule && !loading_schedule_staff && !loading_groups)  {
      sections.forEach(s => {
        let group = groups.find(g => g.sections.find(gs => gs.id == s.id));
        this.doGet(this.state.endpoint_section + s.id + "/", 
                  data => this.setState({ sections: sections.map(ss => { if (ss.id == s.id) { ss.students = data.students; } return ss; }) }));

        if (group == null) {
        this.doGet(this.state.endpoint_taapplication + "?campus=" + s.campus + "&semester=" + s.semester + "&course=" + s.course + "&state=Hired",
                  data => this.setState({ sections: sections.map(ss => { if (ss.id == s.id) { ss.tas = data; } return ss; }) })); 
        } else {
          this.doGet(this.state.endpoint_taapplication + "?group=" + group.id + "&state=Hired",
                  data => this.setState({ sections: sections.map(ss => { if (ss.id == s.id) { ss.tas = data; } return ss; }) }));
        }
      });
      sections_staff.forEach(s => {
        let group = groups.find(g => g.sections.find(gs => gs.id == s.id));
        this.doGet(this.state.endpoint_section + s.id + "/", 
                  data => this.setState({ sections_staff: sections_staff.map(ss => { if (ss.id == s.id) { ss.students = data.students; } return ss; }) }));
        if (group == null) {
        this.doGet(this.state.endpoint_taapplication + "?campus=" + s.campus + "&semester=" + s.semester + "&course=" + s.course + "&state=Hired",
                  data => this.setState({ ections_staff: sections_staff.map(ss => { if (ss.id == s.id) { ss.tas = data; } return ss; }) })); 
        } else {
          this.doGet(this.state.endpoint_taapplication + "?group=" + group.id + "&state=Hired",
                  data => this.setState({ ections_staff: sections_staff.map(ss => { if (ss.id == s.id) { ss.tas = data; } return ss; }) }));
        }
      });
    }
  }

  handleCreateUpdateTAReview = (taapplication, form, callback) => {
    const { reviews } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }
            
      values["ta"] = taapplication.ta.id;
      values["course"] = taapplication.course;
      values["campus"] = taapplication.campus;
      values["semester"] = taapplication.semester;
      values["instructor"] = this.props.user.instructor;

      const review = reviews.find(tar => tar.course == taapplication.course && tar.semester == taapplication.semester && tar.ta == taapplication.ta.id);
            
      if (review) {
        this.doPatch(this.state.endpoint_review + review.id + "/", (data) => { if (data) { message.success("Updated review."); this.getReviews(); callback(); } }, JSON.stringify(values));
      } else {
        this.doPost(this.state.endpoint_review, (data) => { if (data) { message.success("Submitted review."); this.getReviews(); callback(); } }, JSON.stringify(values));
      }
    });
  }

  handleCreateUpdateTARecommendation = (student, section, form, callback) => {
    const { recommendations } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }
            
      values["student"] = student.id;
      values["section"] = section.id;
      values["instructor"] = this.props.user.instructor;
      
      const recommendation = recommendations.find(tar => tar.section == section.id && tar.student == student.id);
            
      if (recommendation) {
        this.doPatch(this.state.endpoint_recommendation + recommendation.id + "/", (data) => { if (data) { message.success("Updated recommendation."); this.getReviews(); callback(); } }, JSON.stringify(values));
      } else {
        this.doPost(this.state.endpoint_recommendation, (data) => { if (data) { message.success("Submitted recommendation."); this.getReviews(); callback(); } }, JSON.stringify(values));
      }
      if (values["preference"] >= 2) {
        this.openModal(student, section);
      }
    });
  }
  openModal = (student, section) => {
    this.setState({ modal_visible: true, modal_student: student, modal_section: section });
  }

  closeModal = () => {
    this.setState({ modal_visible: false, modal_student: null, modal_section: null});
  }

  openEmailClient = (student, emailTemplate) => {
    let subject =  "I think you'd make a great TA"
    window.open(`mailto:${student.email}?subject=${subject}&body=${encodeURIComponent(emailTemplate)})`, '_blank');
    this.closeModal();
  }

  copyToClipboard = (emailTemplate) => {
    const { modal_student } = this.state;

    const additionToClipboard = `************************
Student Name: ${modal_student ? this.print_full_student(modal_student) : ""}
Email: ${modal_student ? modal_student.email : ""}
************************

`;

    navigator.clipboard.writeText(''.concat(additionToClipboard, emailTemplate));
    message.success("Copied to clipboard");
  }
  

  render() {
    const { semesters, semester } = this.props;
    const { loading_schedule, sections, loading_schedule_staff, sections_staff, reviews, recommendations, loading_reviews, loading_recommendations, modal_visible, modal_student, modal_section } = this.state;
    
    const loading = loading_schedule || loading_schedule_staff || loading_recommendations || loading_reviews;
    const alert = loading ? null : sections.length == 0 && sections_staff.length == 0 ? <Alert message="No sections found." description={ "We did not find any sections that you are listed as an instructor or staff instructor for during the " + semester + " semester." } type="warning" showIcon /> : moment(this.get_semester(semesters[0]).startdate, "YYYY-MM-DD") > moment() ? <Alert message="Semester not started." description={ "The " + semester + " semester has yet to start; you cannot submit reviews or recommendations until the semester has started." } type="warning" showIcon /> : null;

    let emailTemplate = `Hello ${modal_student ? modal_student.firstname : ""},

I'm reaching out to you specifically because I think that you'd be a great candidate to TA for ${modal_section ? this.print_course(modal_section.course) : ""}. Your prior performance and work ethic gives me confidence that you would be a great fit for the role.

If you are interested in applying, you'll need to apply via the Khoury TA portal: https://admin.khoury.northeastern.edu/students/ta/application/. You'll need a Khoury account to do this, and here are the details:
    
- All applications for a TA position must be submitted via your Khoury account.
- When the application window opens, you typically have a week to submit your application.
- If you are a Khoury student (if you have a DS or CS major or combined major, for instance), you may already have a Khoury account (if you don’t have an account yet, you can create one).
- If you are not a Khoury student, then I'll need to let the administrators know that they should approve your request (I'd advise doing this now!) — so do let me know if this is the case!
- Instructions for creating a Khoury account can be found here: https://northeastern.service-now.com/kb_view.do?sysparm_article=KB0011758

By the way, if you are interested in being a TA for a future semester, that’s fine! You’ll use the same information described above…just keep an eye out for announcements that applications are open!

As a TA, your primary job is helping students learn the material that we cover in class. To do this, you do not need to be an encyclopedia of facts (in fact, it's better if you aren't!). We look for students who are excited to work with their peers, help fellow students understand and debug their code, and assist learners in thought processes. TAs are also responsible for conducting various course administrative tasks such as grading, office hours, etc.

We really appreciate our TAs and believe they are essential to the experiential learning process at Northeastern. An added benefit of being a TA is that you’ll be able to deepen your own understanding of the material, hone your communication and collaboration skills, and have the opportunity to build relationships with faculty and your peers.`;


    return (
      <Content {...this.props} title="TA Reviews and Future TA Recommendations" breadcrumbs={ [{ link: "/teaching", text: "Teaching" }, {text: "TA" }, { text: "Review" }] }>
      <p>This page lets you submit reviews of your current TAs as well as recommendations of your current students who you think might be good candidates for being future TAs.  Below, each of your courses during the { semester } semester is shown under a different tab.  Within each tab, the top section provides forms for you to review your TAs and the bottom section provides forms for you to review your currently enrolled students. </p>
        { alert }
        { loading ? <Spin tip="Loading data..." /> : (
          <>
            <CustomTabs {...this.props}>
              { sections.concat(sections_staff).map(s => {
                  const tas = s.tas ? s.tas.map(taa => { return { title: this.print_full_student(taa.ta), content: <TAReviewForm {...this.props} taapplication={ taa } review={ reviews.find(tar => tar.course == s.course && tar.semester == s.semester && tar.ta == taa.ta.id) } handleCreateUpdate={ this.handleCreateUpdateTAReview } disabled={ alert != null } /> }; }) : [];

                  const students = s.students ? s.students.map(st => { return { title: this.print_full_student(st), content: <TARecommendationForm {...this.props} student={ st } section={ s } recommendation={ recommendations.find(tar => tar.section == s.id && tar.student == st.id) } handleCreateUpdate={ this.handleCreateUpdateTARecommendation } disabled={ alert != null } /> }; }) : [];
                  
                  return (
                    <TabPane tab={ this.print_course(s.course) + " CRN " + s.crn } key={ s.crn }>
                      <Divider orientation="left">Assigned TAs</Divider>
                      <p>Below is a list of the TAs who are currently assigned to your class.  Please fill out this brief survey to let us know how they did, and whether you'd recommend hiring them as a TA to a colleague.  For non-coordinated classes with multiple sections, some of theses TAs may have worked for other instructors; please feel free to leave those forms blank.</p>
                      { "tas" in s ? <List grid={ this.grid } dataSource={ tas } renderItem={item => ( <List.Item><Card size="small" title={ item.title }>{ item.content }</Card></List.Item> )} /> : <div className="loading"><Spin  tip="Loading TAs..." /></div> }
                      
                      <Divider orientation="left">Enrolled Students</Divider>
                      <p>Below is a list of the students who are currently enrolled in your class.  Please fill out the form for any of them who you think might serve as a good TA for this class (or for another class) in the future.  Please also add any notes you feel are appropriate to explain your score.  You <b>do not</b> need to fill out the form for every student; it's completely fine to leave most blank.</p>
                      { "students" in s ? <List grid={ this.grid } dataSource={ students } renderItem={item => ( <List.Item><Card size="small" title={ item.title }>{ item.content }</Card></List.Item> )} />  : <div className="loading"><Spin  tip="Loading students..."  /></div> }
                    </TabPane>
                  );
                }) }
            </CustomTabs>
            <Modal
            destroyOnClose={ true }
            visible={ modal_visible }
            title={ "Email Student" }
            onOk={ () => { this.openEmailClient(modal_student, emailTemplate) }}
            onCancel={ () => { this.closeModal() }}
            okText = { "Open in e-mail client" }
            cancelText = { "Close" }
            width={ 600 } >
            <p>If you would like to email the student and suggest they apply for a TA position, you can use the below text as a template for your email.</p>
            <TextArea rows={ 20 } defaultValue={ emailTemplate } disabled></TextArea>
            <br />
            <br />
            <div align= "right">
              <Button onClick={() => this.copyToClipboard(emailTemplate)} >Copy to clipboard</Button>
            </div>
          </Modal>
        </>
        ) }
      </Content>
    );
  }
}


export { TAApplicationReview, TAHoursFaculty, TAReview };
