import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import moment from "moment-timezone";
import { renderStatus, text_max, oxford, add_brs } from "../../Utils";
import { WorkflowSubmit } from "../WorkflowSubmit";
import { WorkflowTable } from "../WorkflowTable";

import { Icon, DatePicker, Space, Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Radio, Switch, Typography, Modal, Alert, Descriptions, Tooltip } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const CourseOverloadForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      endpoint_sections: "/api/schedule/",
      sections: [],
      loading_sections: true,
      instructor: null,
      requestedBy: "faculty",
      loadcheckbox: false,
      loadcount: null,
      selected_semester: this.props.semesters[0],
    }


    componentDidMount() {
      this.getData();
    }

    getData = () => {
      this.doGet(this.state.endpoint_sections + "?semester=" + this.props.semesters.join(","), data => { this.setState({ sections: data, loading_sections: false }) });
    }

    componentDidUpdate(prevProps) {
      if (prevProps.semester !== this.props.semester) {
        this.reset();
        this.getData();
      }
    }

    reset = () => {
      this.setState({ instructor: null, loadcheckbox: false, loadcount: null, requestedBy: "faculty", selected_semester: this.props.semesters[0] })
      this.props.form.resetFields();
    }

    handleSubmit = () => {
      const form = this.props.form;
      const { loadcount } = this.state;

      form.validateFields((err, values) => {
        if (!err) {
          values["loadcount"] = loadcount;
          if (("start_date" in values) && values["start_date"]) {
            values["start_date"] = moment(values["start_date"]).format('YYYY-MM-DD')
          }
          this.props.onSubmit(values, this.reset());
        } else {
          message.error(err);
        }
      });
    };

    render() {
      const { form } = this.props;
      const { instructor, sections, loading_sections, requestedBy, loadcheckbox, loadcount, selected_semester } = this.state;
      const { getFieldDecorator } = form;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      let individualSections = loading_sections ? [] : sections.filter(el => el.instructors.includes(instructor) && el.semester == form.getFieldValue("semester"));

      return (
        <>
          <p>Please fill out the form below AFTER the section for which the faculty member is doing an over has been created in Banner. This request will then be routed to the Khoury Administration, and finally to Khoury Human Resources to submit the overload payment request. If you have any questions about this process, please reach out to the Associate Dean of Undergraduate Programs or the Associate Dean for Graduate Programs, as appropriate.</p>
          <Form>
            <Form.Item {...formItemLayout} label="Semester" extra="You can change your semester in the top right corner.">
              {getFieldDecorator("semester", { initialValue: this.props.semesters[0], onChange: (_, event) => { this.setState({ selected_semester: event }) } })
                (<Select style={{ width: 360 }} disabled={this.props.semesters.length == 1} >
                  {this.props.semesters.map(el => (
                    <Option key={el} value={el}>{this.print_semester(el)}</Option>
                  ))}
                </Select>
                )}
            </Form.Item>
            <FormItem {...formItemLayout} label="Instructor" extra="Please select the instructor who should be paid the overload.”">
              {getFieldDecorator("instructor", {
                initialValue: instructor, rules: [{ required: true, message: "Please select an instructor" }],
                onChange: (event) => { this.setState({ instructor: event }); form.resetFields(["loadcount", "section"]) }
              })
                (
                  <Select showSearch style={{ width: 360 }} filterOption={this.filter} allowClear >
                    {this.faculty_list().map(el => <Option key={el.id} value={el.id}>{this.print_full_instructor(el.id)}</Option>)}
                  </Select>
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Section" extra="Please select the section that they should be paid an overload for.  If the section does not appear here, please verify that it exists in Banner.">
              {getFieldDecorator("section.id", { rules: [{ required: true, message: "Select a section" }], onChange: (_, event) => { this.setState({ loadcount: this.get_course(event.key).loadcount }) } })
                (
                  <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                    {individualSections.map(el => (
                      <Option key={el.course} value={el.id}>{this.print_section(el)}</Option>
                    ))}
                  </Select>
                )}
            </FormItem>
            <FormItem {...formItemLayout} label={"Override load"} extra="The default load fraction should be used in almost all cases. Only select this if the fraction of the instructor’s load for which this course counts should deviate from the default. A justification is required.">
              {getFieldDecorator("loadcheckbox", { rules: [{ required: false }], onChange: (event) => { this.setState({ loadcheckbox: event }) } })
                (
                  <Switch checked={loadcheckbox} />,
                )}
            </FormItem>
            {loadcheckbox && (
              <>
                <FormItem {...formItemLayout} label="Load" extra="Please enter the updated load value (fraction of a course).">
                  {getFieldDecorator("loadcount", { rules: [{ required: true, message: "Please enter a number" }], initialValue: loadcount, onChange: (event) => this.setState({ loadcount: event }) })
                    (
                      <InputNumber min={0} max={10} step={0.5} />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Load Justification" extra={"Please provide a reason for adjusting the load fraction"}>
                  {getFieldDecorator("load_justification", {
                    rules: [{ required: true, message: "Please input a justification." }], initialValue: ""
                  })
                    (<TextArea style={{ width: 360 }} rows={4} />)}
                </FormItem>
              </>
            )}
            <FormItem {...this.formItemLayout} label="Requested By" extra={"Almost all overloads are requested by the faculty member. Please select College here only if the College asked the faculty member to teach this section due to unique circumstances. This option should only be used very rarely, and justification is required."}>
              {getFieldDecorator('requested_by', {
                rules: [{ required: true, message: 'Please select an option.' }], initialValue: requestedBy, onChange: event => this.setState({ requestedBy: event.target.value })
              })
                (<RadioGroup>
                  <Radio value={"faculty"}>Faculty</Radio>
                  <Radio value={"college"}>College</Radio>
                </RadioGroup>
                )}
            </FormItem>
            {requestedBy == "college" && (
              <FormItem {...formItemLayout} label="Justification" extra={"Please provide more details about why the College required this section to be taught in this particular semester, and why other teaching resources were not available to cover this section."}>
                {getFieldDecorator("justification", {
                  rules: [{ required: true, message: "Please input a justification." }], initialValue: ""
                })
                  (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>
            )}
            <FormItem {...this.formItemLayout} label="Start Date" extra={"Enter this value only if the instructor is “taking over” the section in the middle of the semester, when another instructor is no longer able to teach this class. In all other instances, leave this field blank."}>
              {getFieldDecorator('start_date', {
                rules: [{ required: false, message: 'Please select an option.' }]
              })
                (
                  <DatePicker disabledDate={d => selected_semester == null || moment(this.get_semester(selected_semester).startdate) > d || moment(this.get_semester(selected_semester).enddate) < d} />
                )}
            </FormItem>
            <FormItem {...this.formItemLayout} label=" " colon={false}><Button type="primary" onClick={this.handleSubmit}>Submit</Button></FormItem>
          </Form>
        </>
      );
    }
  }
);

class CourseOverloadSearchForm extends AppComponent {
  render() {
    const { form } = this.props;

    return (
      <>
        <FormItem {...this.formItemLayout} label="Instructor" extra="Optionally select the instructor who was paid the overload.">
          {form.getFieldDecorator("overloadrequest__instructor", {
            onChange: this.props.onSearchChange,
          })(
            <Select showSearch style={{ width: 200 }} filterOption={this.filter} allowClear >
              {this.faculty_list().map(el => <Option key={el.id} value={el.id}>{this.print_full_instructor(el.id)}</Option>)}
            </Select>
          )}
        </FormItem>
      </>
    )
  }
}

class CourseOverload extends AppComponent {
  render() {
    const { record } = this.props;
    const item = record.record;

    const semester = this.get_semester(item.section.semester);
    const taught_portion = moment(semester.enddate).diff(moment(item.start_date), 'days') * 1.0 / moment(semester.enddate).diff(moment(semester.startdate), 'days');
    const taught_fraction = item.start_date && moment(item.start_date) >= moment(semester.startdate) && moment(item.start_date) <= moment(semester.enddate) ? taught_portion >= 0.75 ? 1.0 : taught_portion >= 0.5 ? 0.75 : taught_portion >= 0.25 ? 0.5 : 0.25 : 1.0;

    const fraction = (item.requested_by == "college" ? 1 / 6 : 1.0) * item.loadcount * taught_fraction;
    const rate = Math.round(fraction * 10000) / 100 + "% of " + (item.requested_by == "college" ? "Base Salary" : "PT Rate");

    return (
      <Descriptions bordered title={"Course Overload Request for " + item.instructor}>
        <Descriptions.Item label="Requestor">{record.created_by}</Descriptions.Item>
        <Descriptions.Item label="Submitted">{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Instructor">{this.link_full_instructor(item.instructor)}</Descriptions.Item>
        <Descriptions.Item label="Semester">{this.print_semester(item.section.semester)}</Descriptions.Item>
        <Descriptions.Item label="Section">{this.print_section(item.section)}</Descriptions.Item>
        <Descriptions.Item label="Load Count">{[item.loadcount + " ", item.load_justification ? <Tooltip title="Custom Load"><Icon type="warning" /></Tooltip> : ""]}</Descriptions.Item>
        {item.load_justification && (
          <Descriptions.Item label="Load Justification" span={2}>{item.load_justification}</Descriptions.Item>
        )}
        <Descriptions.Item label="Requested By" span={2}>{item.requested_by == "college" ? "College" : "Faculty member"}</Descriptions.Item>
        <Descriptions.Item label="Start Date" span={2}>{item.start_date ? item.start_date : <i>N/A</i>}</Descriptions.Item>
        {item.requested_by == "college" && (
          <Descriptions.Item label="Justification" span={2}>{item.justification}</Descriptions.Item>
        )}
        <Descriptions.Item label="Calculated Rate" span={2}>{rate}</Descriptions.Item>
      </Descriptions>
    );
  }
}

class CourseOverloadTable extends WorkflowTable {

  getRate = (record) => {
    const item = record.record;
    const semester = this.get_semester(item.section.semester);
    const taught_portion = moment(semester.enddate).diff(moment(item.start_date), 'days') * 1.0 / moment(semester.enddate).diff(moment(semester.startdate), 'days');
    const taught_fraction = item.start_date && moment(item.start_date) >= moment(semester.startdate) && moment(item.start_date) <= moment(semester.enddate) ? taught_portion >= 0.75 ? 1.0 : taught_portion >= 0.5 ? 0.75 : taught_portion >= 0.25 ? 0.5 : 0.25 : 1.0;
    const fraction = (item.requested_by == "college" ? 1 / 6 : 1.0) * item.loadcount * taught_fraction;
    const rate = Math.round(fraction * 10000) / 100 + "% of " + (item.requested_by == "college" ? "Base Salary" : "PT Rate");
    return rate;
  }

  get_columns = () => {
    return [
      {
        title: "Instructor",
        key: "instructor",
        width: 80,
        render: (text, record, idx) => record.record.instructor ? this.print_instructor(record.record.instructor) : "",
      }, {
        title: "Semester",
        key: "semester",
        width: 80,
        render: (text, record, idx) => record.record.section.semester ? this.print_semester(record.record.section.semester) : ""
      }, {
        title: "Section",
        key: "section",
        width: 80,
        render: (text, record, idx) => record.record.section ? this.print_section(record.record.section) : ""
      }, {
        title: "Load Count",
        key: "load_count",
        width: 80,
        render: (text, record, idx) => [record.record.loadcount + " ", record.record.load_justification ? <Tooltip title="Custom Load"><Icon type="warning" /></Tooltip> : ""]
      }, {
        title: "Load Justification",
        key: "load_justification",
        width: 120,
        render: (text, record, idx) => record.record.load_justification ? record.record.load_justification : ""
      }, {
        title: "Requested By",
        key: "requested_by",
        width: 80,
        render: (text, record, idx) => record.record.requested_by == "college" ? "College" : "Faculty member",
      }, {
        title: "Start Date",
        key: "start_date",
        width: 80,
        render: (text, record, idx) => (record.record.start_date ? record.record.start_date : "")
      }, {
        title: "Calculated Rate",
        key: "calculated_rate",
        width: 80,
        render: (text, record, idx) => this.getRate(record)
      }, 
    ];
  }
}

class CourseOverloadOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/load/overloadrequest/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "Course Overload Request";
  }

  get_workflowtype = () => {
    return "overloadrequest";
  }

  get_name_plural = () => {
    return "Course Overload Requests";
  }

  get_breadcrumbs = () => {
    return [{ link: "/faculty", text: "Faculty" }, { text: "Course Overload" }];
  }

  get_form = (func) => {
    return <CourseOverloadForm {...this.props} onSubmit={this.submit_form} />;
  }

  get_record_view = (record) => {
    return <CourseOverload {...this.props} record={record} />
  }

  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null }, JSON.stringify(data));
  }

  get_overview_text = () => {
    return (
      <div>
        <p>This form is for administrators who schedule courses (e.g., Associate Deans and Campus Directors) to request that an overload be paid to a faculty member for teaching a course above and beyond their contract load. This form is not to be submitted by individual faculty members. If you believe you are owed an overload that has not been paid, please reach out to your Campus Director or Associate Dean, and they can verify this is the case and submit the form on your behalf.</p>
      </div>
    );
  }
}

export { CourseOverloadOverview, CourseOverload, CourseOverloadTable, CourseOverloadSearchForm };