import React, { Component } from "react";
import AppComponent from "../AppComponent";
import Content from "../Content";
import queryString from 'query-string';
import moment from "moment-timezone";
import { renderStatus, oxford, add_brs, isEmpty } from "../Utils";
import CustomTabs from "../CustomTabs";

//Faculty 
import { SummerSupportProposal, SummerSupportProposalTable, SummerSupportProposalSearchForm } from "./Faculty/SummerSupport";
import { BuyoutRequest, BuyoutRequestTable, BuyoutRequestSearchForm } from "./Faculty/Buyout";
import { ParentalReductionRequest, ParentalReductionRequestTable, ParentalReductionRequestSearchForm } from "./Faculty/ParentalReduction";
import { ResearchReductionRequest, ResearchReductionRequestTable, ResearchReductionRequestSearchForm } from "./Faculty/ResearchReduction";
import { PhDStudentFunding, PhDStudentFundingTable, PhDStudentFundingSearchForm } from "./Faculty/PhDStudentFunding";
import { CourseOverload, CourseOverloadTable, CourseOverloadSearchForm } from "./Faculty/CourseOverload";
import { TransactionMove, TransactionMoveTable } from "./Faculty/TransactionMove";
import { EquipmentRefresh, EquipmentRefreshTable } from "./Faculty/EquipmentRefresh";

//undergrad
import { UGPrerequisiteOverride, UGPrerequisiteOverrideTable } from "./Student/Undergrad/PrerequisiteOverride";
import { UGConcentrationDeclaration, UGConcentrationDeclarationTable } from "./Student/Undergrad/ConcentrationDeclaration";
import { UGHomeCollegeChange, UGHomeCollegeChangeTable } from "./Student/Undergrad/HomeCollegeChange";
import { UGScholarshipApplication, UGScholarshipApplicationTable } from "./Student/Undergrad/ScholarshipApplication";
import { UGCoopAppeal, UGCoopAppealTable } from "./Student/Undergrad/CoopAppeal";

//PhD
import { PhDPaperRequirement, PhDPaperRequirementTable } from "./Student/PhD/PaperRequirement";
import { PhDThesisApproval, PhDThesisApprovalTable } from "./Student/PhD/ThesisApproval";
import { PhDCourseWaiver } from "./Student/PhD/CourseWaiver";
import { PhDElectivePetition, PhDElectivePetitionTable } from "./Student/PhD/ElectivePetition";

//Graduate
import { MSCourseAuthorization, MSCourseAuthorizationTable } from "./Student/Graduate/CourseAuthorization";
import { MSCourseCreditWaiver } from "./Student/Graduate/CourseCreditWaiver";
import { MSElectivePetition } from "./Student/Graduate/ElectivePetition";
import { MSThesisForm, MSThesisFormTable } from "./Student/Graduate/ThesisForm";
import { MSIncompleteGradeContract, MSIncompleteGradeContractTable } from "./Student/Graduate/MSIncompleteGradeContract";

//Generic 
import { CourseWaiverTable, ElectivePetitionTable } from "./Student/GenericForms";
import { UGMSConferenceSupport, UGMSConferenceSupportTable, UGMSConferenceSupportSearchForm } from "./Student/UGMSConferenceSupport";

//Temp Access Request
import { TATempAccessRequest, TATempAccessRequestTable } from "./TempAccessRequest/TAAccessRequest";

//Export
import PhDFundingExport from "./DataExport/PhDFundingExport";
import PhDPaperRequirementExport from "./DataExport/PhDPaperRequirementExport";
import UGMSConferenceSupportExport from "./DataExport/UGMSConferenceSupportExport";
import UGScholarshipApplicationExport from "./DataExport/UGScholarshipApplicationExport"

import { Popover, Table, Form, Input, Divider, Spin, Select, Button, InputNumber, message, Switch, DatePicker, Typography, Steps, Modal, Icon, Popconfirm, Tabs, Badge, Descriptions, Radio, Comment, Avatar } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { Text } = Typography;
const { Step } = Steps;
const { TabPane } = Tabs;

const WORKFLOWTYPES = {
  "buyoutrequest": { group: "faculty", name: "Buyout", render: (props, record) => <BuyoutRequest {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <BuyoutRequestTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} />, search: (props) => <BuyoutRequestSearchForm {...props} /> },
  "phdstudentfundingproposal": { group: "faculty", name: "PhD Funding", render: (props, record) => <PhDStudentFunding {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <PhDStudentFundingTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} />, search: (props) => <PhDStudentFundingSearchForm {...props} /> },
  "summersupportproposal": { group: "faculty", name: "Summer Support", render: (props, record) => <SummerSupportProposal {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <SummerSupportProposalTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} />, search: (props) => <SummerSupportProposalSearchForm {...props} /> },
  "parentalreductionrequest": { group: "faculty", name: "Parental Teaching Relief", render: (props, record) => <ParentalReductionRequest {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <ParentalReductionRequestTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} />, search: (props) => <ParentalReductionRequestSearchForm {...props} /> },
  "researchreductionrequest": { group: "faculty", name: "Research Reduction", render: (props, record) => <ResearchReductionRequest {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <ResearchReductionRequestTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} />, search: (props) => <ResearchReductionRequestSearchForm {...props} /> },
  "overloadrequest": { group: "faculty", name: "Course Overload", render: (props, record) => <CourseOverload {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <CourseOverloadTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} />, search: (props) => <CourseOverloadSearchForm {...props} /> },
  "transactionmove": { group: "faculty", name: "Transaction Move", render: (props, record) => <TransactionMove {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <TransactionMoveTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "equipmentrefresh": { group: "faculty", name: "Equipment Refresh", render: (props, record) => <EquipmentRefresh {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <EquipmentRefreshTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },

  "ugprerequisiteoverride": { group: "ug", name: "UG Prereq Override", render: (props, record) => <UGPrerequisiteOverride {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <UGPrerequisiteOverrideTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "ugconcentrationdeclaration": { group: "ug", name: "UG Concentration Declaration", render: (props, record) => <UGConcentrationDeclaration {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <UGConcentrationDeclarationTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "ughomecollegechange": { group: "ug", name: "UG Home College Change", render: (props, record) => <UGHomeCollegeChange {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <UGHomeCollegeChangeTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "ugscholarshipapplication": { group: "ug", name: "UG Scholarship Application", render: (props, record) => <UGScholarshipApplication {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <UGScholarshipApplicationTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "ugcoopappeal": { group: "ug", name: "UG Coop Appeal", render: (props, record) => <UGCoopAppeal {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <UGCoopAppealTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },

  "phdpaperrequirement": { group: "phd", name: "PhD Paper Requirement", render: (props, record) => <PhDPaperRequirement {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <PhDPaperRequirementTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "phdthesiscommittee": { group: "phd", name: "PhD Thesis Committee Approval", render: (props, record) => <PhDThesisApproval {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <PhDThesisApprovalTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "phdcoursepetition": { group: "phd", name: "PhD Course Waiver", render: (props, record) => <PhDCourseWaiver {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <CourseWaiverTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "phdelectivepetition": { group: "phd", name: "PhD Elective Petition", render: (props, record) => <PhDElectivePetition {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <ElectivePetitionTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },

  "mscourseauthorization": { group: "ms", name: "MS Course Authorization", render: (props, record) => <MSCourseAuthorization {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <MSCourseAuthorizationTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "mscoursepetition": { group: "ms", name: "MS Course/Credit Petition", render: (props, record) => <MSCourseCreditWaiver {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <CourseWaiverTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "mselectivepetition": { group: "ms", name: "MS Elective Petition", render: (props, record) => <MSElectivePetition {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <ElectivePetitionTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "msthesisform": { group: "ms", name: "MS Thesis Form", render: (props, record) => <MSThesisForm {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <MSThesisFormTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "msincompletegradecontract": { group: "ms", name: "MS Incomplete Grade Contract", render: (props, record) => <MSIncompleteGradeContract {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <MSIncompleteGradeContractTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },
  "ugmsconferencesupportpetition": { group: "ms", name: "UG/MS Conference Support", render: (props, record) => <UGMSConferenceSupport {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <UGMSConferenceSupportTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} />, search: (props) => <UGMSConferenceSupportSearchForm {...props} /> },

  "tatempaccessrequest": { group: "staff", name: "TA Temp Access Request", render: (props, record) => <TATempAccessRequest {...props} record={record} />, table: (props, data, reload, loading, type, onView) => <TATempAccessRequestTable {...props} data={data} loading={loading} type={type} onView={onView} reload={reload} /> },

}

const DOWNLOADABLE = [
  "phdstudentfundingproposal",
  "phdpaperrequirement",
  "ugmsconferencesupportpetition",
  "ugscholarshipapplication",
]

const WorkflowProcessForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {

    state = {
      decision: null,
      next_step: null,
      assignee_type: null,
    }

    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    handleSubmit = () => {
      this.props.onSubmit(this.props.step)
    }

    handleSubmitComment = () => {
      this.props.onSubmitComment(this.props.step)
    }

    render() {
      const { form, steps, loading } = this.props;
      const { decision, next_step, assignee_type } = this.state;
      const { getFieldDecorator } = form;

      const current_step = steps.find(s => s.decision == "Assigned");
      const next_steps = steps.filter(s => s.stepnumber > current_step.stepnumber);

      const first_non_skippable_step = next_steps.find(s => !s.skipable);
      const next_steps_options = first_non_skippable_step ? next_steps.filter(s => s.stepnumber <= first_non_skippable_step.stepnumber) : [];

      const actual_next_step = (next_steps_options.length > 1 ? next_steps_options.find(s => s.stepnumber == next_step) : next_steps_options?.length > 0 ? next_steps_options[0] : null);

      const current_year = this.semester_list().filter(s => moment(s.startdate, "YYYY-MM-DD") <= moment())[0].year;
      const committee = this.committeeyear_list().find(cy => cy.id == current_step.assigned_committeeyear);
      const can_approve = (current_step.assigned_committeeyear == null) || (committee && committee.members.find(m => m.instructor == this.props.user.instructor && m.chair));

      return can_approve ? (
        <Form>
          <FormItem {...this.formItemLayout} label="Decision" extra="Please select the decision you wish to make on this record.">
            {getFieldDecorator("decision", {
              rules: [{ required: true, message: 'Please select a decision.' }],
              onChange: e => this.setState({ decision: e }),
            })(<Select style={{ width: 200 }}>
              <Option key="Accepted" value="Accepted">Approve</Option>
              <Option key="Rejected" value="Refused">Deny</Option>
              {current_step.assignable ? <Option key="Reassigned" value="Reassigned">Reassign</Option> : null}
            </Select>)}
          </FormItem>
          <FormItem {...this.formItemLayout} 
            label="Notes" 
            extra={
              <span>
                Please provide a brief justification for this decision.
                {current_step.justification_visible ? (
                  <><b> Note:</b> This will be made visible to the requestor.</>
                ) : ( "" )}
              </span>
            }>
            {getFieldDecorator('justification', {
              rules: [{ required: true, message: 'Please provide a brief justification.' }],
            })(<TextArea rows={2} />
            )}
          </FormItem>
          {(next_steps_options.length > 1) && (decision == "Accepted") ? (
            <FormItem {...this.formItemLayout} label="Next step" extra="The subsequent step can be skipped.  Please select the next step for this workflow.">
              {getFieldDecorator("next_stepnumber", {
                rules: [{ required: true, message: 'Please select the next step.' }],
                onChange: e => this.setState({ next_step: e }),
              })(<Select style={{ width: 400 }}>
                {next_steps_options.map(ns => (
                  <Option key={ns.id} value={ns.stepnumber}>Step {ns.stepnumber}: {ns.title} {ns.skipable ? " (skipable)" : ""}</Option>
                ))}
              </Select>)}
            </FormItem>
          ) : null}

          {(actual_next_step && (decision != "Refused") && (actual_next_step.assigned_committeeyear == null) && (actual_next_step.assigned_positionyear == null) && (actual_next_step.assigned_employee_names.length == 0)) || (decision == "Reassigned") ? (
            <>
              <FormItem {...this.formItemLayout} label="Assignee type" extra={decision == "Reassigned" ? "Please select the type of assignee to whom this step should be reassigned." : "Please select the type of assignee for the next step."}>
                {getFieldDecorator('assignee_type', {
                  rules: [{ required: true, message: 'Please select an option.' }],
                  initialValue: null,
                  onChange: event => this.setState({ assignee_type: event.target.value }),
                })(<RadioGroup>
                  <Radio value="employee">Employee</Radio>
                  <Radio value="committee">Committee</Radio>
                  <Radio value="position">Position</Radio>
                </RadioGroup>
                )}
              </FormItem>

              {assignee_type == "employee" ? (
                <FormItem {...this.formItemLayout} label="Employee">
                  {getFieldDecorator('assigned_employee', {
                    rules: [{ required: true, message: 'Please select an employee to be the assignee.' }],
                  })(<Select showSearch style={{ width: 400 }} filterOption={this.filter} >
                    {this.employee_list().map(i => (
                      <Option key={i.id} value={i.id}>{this.print_full_employee(i.id)}</Option>
                    ))}
                  </Select>
                  )}
                </FormItem>
              ) : null}
              {assignee_type == "committee" ? (
                <FormItem {...this.formItemLayout} label="Committee" extra="Please select the committee to assign the next step to.">
                  {getFieldDecorator("assigned_committee", {
                    rules: [{ required: true, message: 'Please select a committee to be the assignee.' }],
                  })(<Select style={{ width: 400 }}>
                    {this.committee_list().filter(c => c.active && this.committeeyear_list().find(cy => cy.committee == c.id && cy.year == current_year)).map(c =>
                      <Option key={c.id} value={c.id}>{this.print_committee(c.id)}</Option>
                    )}
                  </Select>
                  )}
                </FormItem>
              ) : null}
              {assignee_type == "position" ? (
                <FormItem {...this.formItemLayout} label="Position" extra="Please select the position to assign the next step to.">
                  {getFieldDecorator("assigned_position", {
                    rules: [{ required: true, message: 'Please select a position to be the assignee.' }],
                  })(<Select style={{ width: 400 }}>
                    {this.position_list().filter(p => p.active && this.positionyear_list().find(py => py.position == p.id && py.year == current_year)).map(p =>
                      <Option key={p.id} value={p.id}>{this.print_position(p.id)}</Option>
                    )}
                  </Select>
                  )}
                </FormItem>
              ) : null}
            </>
          ) : null}
          <FormItem {...this.formItemLayout} label=" " colon={false}><Button type="primary" loading={loading} onClick={this.handleSubmit}>Submit</Button></FormItem>
        </Form>
      ) : (
        <Form>
          <FormItem {...this.formItemLayout} label="Comment" 
            extra={
              <span>
                Please provide a brief justification for this decision.
                {current_step.justification_visible ? (
                  <><b> Note:</b> This will be made visible to the requestor.</>
                ) : ( "" )}
              </span>
            }
          >
            {getFieldDecorator('comment', {
              rules: [{ required: true, message: 'Please provide a brief comment.' }],
            })(<TextArea rows={2} />
            )}
          </FormItem>
          <FormItem {...this.formItemLayout} label=" " colon={false}><Button type="primary" loading={loading} onClick={this.handleSubmitComment}>Submit</Button></FormItem>
        </Form>
      );
    }
  });

const WorkflowSearchForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {

    state = {
      type: null,
    }

    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    handleSubmit = () => {
      this.props.onSearch();
    }

    render() {
      const { form, steps } = this.props;
      const { type } = this.state;
      const { getFieldDecorator } = form;

      return (
        <Form>
          <FormItem {...this.formItemLayout} label="ID" extra="Optionally enter the ID of the workflow item.">
            {getFieldDecorator("workflow_id", {
              rules: [{ required: false, message: 'Please enter a workflow ID.' }],
              onChange: this.props.onSearchChange,
            })(<InputNumber style={{ width: 300 }} />)}
          </FormItem>
          <FormItem {...this.formItemLayout} label="Type" extra="Please select the type of workflow item to search for.">
            {getFieldDecorator("workflow__abstract_workflow_type", {
              rules: [{ required: !form.getFieldValue("workflow_id"), message: 'Please select a type of workflow item.' }],
              onChange: e => this.setState({ type: e }, this.props.onSearchChange),
            })(<Select style={{ width: 300 }}>
              {Object.keys(WORKFLOWTYPES).map(t => (
                <Option key={t} value={t}>{WORKFLOWTYPES[t].name}</Option>
              ))}
            </Select>)}
          </FormItem>
          <FormItem {...this.formItemLayout} label="Requestor" extra="Optionally enter the name of the requestor.">
            {getFieldDecorator("requestor", {
              onChange: this.props.onSearchChange,
            })(<Input style={{ width: 300 }} />)}
          </FormItem>
          <FormItem {...this.formItemLayout} label="Current assignee" extra="Optionally enter the name of the current assignee.">
            {getFieldDecorator("assignee", {
              onChange: this.props.onSearchChange,
            })(<Input style={{ width: 300 }} />)}
          </FormItem>
          <FormItem {...this.formItemLayout} label="State" extra="Optionally select the state of the workflow item.">
            {getFieldDecorator("state", {
              onChange: this.props.onSearchChange,
            })(<Select style={{ width: 300 }} allowClear={true}>
              <Option value="Assigned">In progress</Option>
              <Option value="Accepted">Approved</Option>
              <Option value="Refused">Denied</Option>
              <Option value="Cancelled">Cancelled</Option>
            </Select>)}
          </FormItem>
          <FormItem {...this.formItemLayout} label="Created between" extra="Optionally enter the date range of when the item was created.">
            {getFieldDecorator("created_at__gt", {
              onChange: this.props.onSearchChange,
            })(<DatePicker placeholder="Start" style={{ width: 150 }} />)}
            {"  to  "}
            {getFieldDecorator("created_at__lt", {
              onChange: this.props.onSearchChange,
            })(<DatePicker placeholder="End" style={{ width: 150 }} />)}
          </FormItem>

          {type && WORKFLOWTYPES[type].search ? (
            <>
              <FormItem {...this.formItemLayout} label=" " colon={false}><b>{WORKFLOWTYPES[type].name}-specific search fields</b></FormItem>
              {WORKFLOWTYPES[type].search(this.props)}
            </>
          ) : null}


          <FormItem {...this.formItemLayout} label=" " colon={false}><Button type="primary" onClick={this.handleSubmit}>Search</Button></FormItem>

        </Form>
      );
    }
  });

class WorkflowProcess extends AppComponent {
  state = {
    modal_item: null,
    modal_type: null,
    modal_historic: false,

    search_data: null,
    search_type: null,
    loading_search: false,
    loading_submit: false
  }


  // problem when search type is empty, e.g., when no data is returned the table errors out as it doesn't know what table to display 
  componentDidMount() {
    const values = queryString.parse(window.location.search);
    if (values['workflow_item_id']) {
      const item = this.props.workflows && this.props.workflows.find(i => i.id == parseInt(values['workflow_item_id']));
      if (item) {
        this.setState({ modal_type: item['abstract_workflow_type'], modal_item: item });
      } else {
        this.setState({ loading_search: true }, () => this.doGet("/api/workflow/?" + "historic=true&workflow_id=" + parseInt(values['workflow_item_id']), data => isEmpty(data.results) ? message.error("No workflow with that ID found") : this.setState({ loading_search: false, search_data: data, search_type: data.results[0].abstract_workflow_type, search_values: "historic=true&workflow_id=" + parseInt(values['workflow_item_id']), modal_type: data.results[0]?.abstract_workflow_type, modal_item: data.results[0], modal_historic:true })));
      }
    }
  }

  get_status = (status) => {
    if ((status == "Accepted") || (status == "Skipped") || (status == "Reassigned")) {
      return "finish";
    } else if (status == "Assigned") {
      return "process";
    } else if ((status == "Refused") || (status == "Cancelled")) {
      return "error";
    } else if (status == "Unassigned") {
      return "wait";
    }
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  saveSearchFormRef = (formRef) => {
    this.searchFormRef = formRef;
  }

  check_item = () => {
    //currently assigned workflows in queue AFTER submit
    const { workflows } = this.props;
 
    //open workflow
    const { modal_item } = this.state;

    const new_item = workflows.find(i => i.id == modal_item.id);
    if (new_item) {
      this.setState({ modal_item: new_item, loading_submit: false });
    } else {
      this.setState({ modal_item: null, modal_type: null, loading_submit: false });
    }
  }

  
  handleSubmit = (step) => {
    const form = this.formRef.props.form;

    form.validateFields((err, values) => {
        if (err) { 
            return; 
        }
        this.setState({ loading_submit: true });
        this.doPatch("/api/workflow/step/" + step.id + "/", () => {
            this.props.updateWorkflows(this.check_item);
        }, JSON.stringify(values))
    });
  }

  handleSubmitComment = (step) => {
    const form = this.formRef.props.form;

    form.validateFields((err, values) => {
      if (err) { 
        return; 
      }
      this.setState({ loading_submit: true });
      values["workflow_step_record_id"] = step.id;
      this.doPost("/api/workflow/step/comment/", () => {
        this.props.updateWorkflows(this.check_item); 
      }, JSON.stringify(values));
    });
  }

  handleSearch = () => {
    const form = this.searchFormRef.props.form;

    form.validateFields((err, values) => {
      if (err) { return; }

      if (values['created_at__lt']) {
        values['created_at__lt'] = moment(values['created_at__lt']).format("YYYY-MM-DD");
      }

      if (values['created_at__gt']) {
        values['created_at__gt'] = moment(values['created_at__gt']).format("YYYY-MM-DD");
      }

      const params = new URLSearchParams("historic=true");
      Object.keys(values).forEach(k => values[k] ? params.set(k, ((typeof values[k] === 'boolean' && values[k] === true) ? "True" : values[k])) : null);

      this.setState({ loading_search: true }, () => this.doGet("/api/workflow/?" + params.toString(), data => !isEmpty(data["results"]) ? this.setState({ loading_search: false, search_data: data, search_type: values["workflow__abstract_workflow_type"] ? values["workflow__abstract_workflow_type"] : data["results"][0]["abstract_workflow_type"], search_values: values }) : null));
    });
  }

  handlePagenation = (link) => {
    this.setState({ loading_search: true }, () => this.doGet(link, data => this.setState({ loading_search: false, search_data: data })));
  }

  print_step = (s, steps) => {
    const { modal_historic, loading_submit } = this.state;

    const assignees = [s.assigned_employee_names, s.assigned_committeeyear ? this.print_committeeyear(this.get_committee_year(s.assigned_committeeyear)) : null, s.assigned_positionyear ? this.link_position(this.get_position_year(s.assigned_positionyear).position) : null].flat().filter(i => i != null);

    return (
      <>
        <p style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: s.description }}></p>
        <Descriptions>
          <Descriptions.Item label="Assigned to" span={3}>{oxford(assignees)}</Descriptions.Item>
          <Descriptions.Item label="State"><b>{s.decision}</b></Descriptions.Item>
          <Descriptions.Item label="Updated at">{moment(s.state_last_changed_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
          <Descriptions.Item label="Updated by">{s.edited_by}</Descriptions.Item>
          {s.comments.length ? (
            <Descriptions.Item label="Comments" span={3}>{
              s.comments.map(c => (
                <Comment author={c.employee} datetime={moment(c.created_at).fromNow()} content={c.comment} avatar={<Avatar size="small" icon="user" />} />
              ))
            }</Descriptions.Item>
          ) : null}
          {s.justification ? <Descriptions.Item label="Justification" span={3}>{s.justification}</Descriptions.Item> : null}
        </Descriptions>
        {s.decision == "Assigned" && !modal_historic ? (
          <WorkflowProcessForm {...this.props} step={s} onSubmit={this.handleSubmit} onSubmitComment={this.handleSubmitComment} wrappedComponentRef={this.saveFormRef} steps={steps} loading={loading_submit}/>
        ) : null}
      </>
    );
  }

  copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard");
  }

  URLIcon = (id) => {
    return(
      <Icon type="link" onClick={() => this.copyToClipBoard(window.location.origin + "/employee/workflow/?workflow_item_id=" + id)}/>
    );
  }

  render() {
    const { workflows } = this.props;
    const { visible, modal_item, modal_type, search_type, search_data, loading_search, search_values } = this.state;
    const tabs = [...new Set(workflows.map(wr => wr.abstract_workflow_type))].sort();

    var active = Object.keys(WORKFLOWTYPES).find(workflowtype => workflows.filter(wr => wr.abstract_workflow_type == workflowtype)?.length > 0);
    if (!active ) {
      active = Object.keys(WORKFLOWTYPES)[0];
    }


    return (
      <Content {...this.props} title={"Workflows"} breadcrumbs={[{ link: "/employee", text: "Employee" }, { text: "Workflows" }]}>

        <p>Below is a list of all of the workflow items that are currently assigned to you.  Each tab contains a different type of workflow item, and the badge indicates the number of items that need attention.</p>
        <p><b>Note:</b> We ony display tabs with workflows in your queue.</p>
        <CustomTabs {...this.props} default_Active_Key={active} >
          {Object.keys(WORKFLOWTYPES).map(workflowtype => {
            const mywrs = workflows.filter(wr => wr.abstract_workflow_type == workflowtype);
            if (mywrs.length !== 0) {
              return (
                <TabPane tab={<Badge count={mywrs.length} offset={[2, 0]}><span className={mywrs?.length > 0 ? "workflow-tab" : ""}>{WORKFLOWTYPES[workflowtype].name}</span></Badge>} key={workflowtype}>
                  <p>Below are all of the {WORKFLOWTYPES[workflowtype].name} workflow items that curently need your attention.</p>
                  {WORKFLOWTYPES[workflowtype].table(this.props, mywrs, null, false, "process", (record) => this.setState({ modal_item: record, modal_type: workflowtype, modal_historic: false }))}
                </TabPane>
              );
            }
          })}
          <TabPane tab="Previous" key="previous">
            <p>You can use the form below to search for workflow items that were previously in your queue (either are completed or are currently in process).  Note that this will only show the most recent 50 results, so you can use the search fields above to further refine which workflow items you are looking for.</p>
            <WorkflowSearchForm {...this.props} onSearch={this.handleSearch} wrappedComponentRef={this.saveSearchFormRef} onSearchChange={t => this.setState({ search_type: null, search_data: null })} />

            {search_data ? (
              <div>
                <Divider orientation="left">Search results</Divider>
                <p>Below are {search_data.results.length} out of {search_data.count} total workflow items that match your query.  To view more, use the buttons above to move through the results or refine your query further using the fields above.</p>

                <Button icon="left" disabled={loading_search || (search_data.previous == null)} onClick={() => this.handlePagenation(search_data.previous)}>Prev</Button>
                {" "}
                <Button icon="right" disabled={loading_search || (search_data.next == null)} onClick={() => this.handlePagenation(search_data.next)}>Next</Button>
                {" "}
                {DOWNLOADABLE.includes(search_type) && (
                  <div style={{ float: 'right' }}>
                    {search_type == "phdstudentfundingproposal" && (<PhDFundingExport {...this.props} disabled={loading_search} searchValues={search_values} />)}
                    {search_type == "phdpaperrequirement" && (<PhDPaperRequirementExport {...this.props} disabled={loading_search} searchValues={search_values} />)}
                    {search_type == "ugmsconferencesupportpetition" && (<UGMSConferenceSupportExport {...this.props} disabled={loading_search} searchValues={search_values} />)}
                    {search_type == "ugscholarshipapplication" && (<UGScholarshipApplicationExport {...this.props} disabled={loading_search} searchValues={search_values} />)}
                  </div>
                )}
                <p>{" "}</p>
                {WORKFLOWTYPES[search_type].table(this.props, search_data.results, null, loading_search, "historic", (record) => this.setState({ modal_item: record, modal_type: search_type, modal_historic: true }))}
              </div>
            ) : null}
          </TabPane>
        </CustomTabs>
        <Modal width={1000} visible={modal_item != null} title={["View and process record ", (modal_item ? [modal_item.id, "  ", this.URLIcon(modal_item?.id)] : "")]} onCancel={() => this.setState({ modal_item: null, modal_type: null })} footer={null}>

          {modal_item ?
            <>
              {WORKFLOWTYPES[modal_type].render(this.props, modal_item)}

              <Divider orientation="left">Workflow history</Divider>
              <Steps direction="vertical" size="medium">
                {modal_item.steps.map((s, idx) => {
                  const status = this.get_status(s.decision);
                  const cancelled = modal_item.steps.find(se => se.decision == "Cancelled");

                  const properties = [s.skipable ? "skipable" : null, s.cancelable ? "cancellable" : null, s.assignable ? "assignable" : null].filter(p => p != null);
                  const title = [s.title, (properties?.length > 0 ? [" (", oxford(properties), ")"] : "")];

                  if (s.decision == "Cancelled") {
                    return (<Step icon={<Icon type="stop" />} status={status} key={s.id} title={title} description={this.print_step(s, modal_item.steps)} />);
                  } else if (s.decision == "Accepted") {
                    return (<Step icon={<Icon type="check-circle" />} status={status} key={s.id} title={title} description={this.print_step(s, modal_item.steps)} />);
                  } else if (s.decision == "Refused") {
                    return (<Step icon={<Icon type="exclamation-circle" />} status={status} key={s.id} title={title} description={this.print_step(s, modal_item.steps)} />);
                  } else if (s.decision == "Reassigned") {
                    return (<Step icon={<Icon type="issues-close" />} status={status} key={s.id} title={title} description={this.print_step(s, modal_item.steps)} />);
                  } else if (s.decision == "Skipped") {
                    return (<Step icon={<Icon type="minus-circle" />} status={status} key={s.id} title={title} description={this.print_step(s, modal_item.steps)} />);
                  } else {
                    return (<Step status={status} key={s.id} title={title} description={cancelled && cancelled.stepnumber > s.stepnumber ? "Cancelled" : s.decision == "Unassigned" ? "Pending" : this.print_step(s, modal_item.steps)} />);
                  }
                }
                )}
              </Steps>
            </>
            : null}
        </Modal>
      </Content>
    );
  }
}

export { WorkflowProcess, WORKFLOWTYPES };
