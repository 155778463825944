import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import SectionTable from './SectionTable';
import CustomTabs from "./CustomTabs";
import Content from './Content';
import { add_dividers, if_empty, isEmpty, add_brs, oxford, get_check_nocheck, renderFundStatus } from "./Utils";

import { PageHeader, Alert, Tabs, Table, Typography, Form, Switch, Tooltip, Icon, Layout, Menu, Dropdown, Spin, Calendar, Col, Statistic, Row, List, Card, Divider, Button, Modal, DatePicker, Select, Input, Checkbox, InputNumber, Badge, Tag, Breadcrumb, message } from 'antd';
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;

const { Text, Title } = Typography;

const dateFormat = 'YYYY-MM-DD';

class FundCard extends AppComponent {
  render() {
    const data = this.fund_list()?.filter(f => f.active && f.owners.find(fo => fo.owner == this.props.user.employee));

    const columns_funds = [
      {
        title: 'Index',
        key: 'index',
        width: 65,
        render: (text, record, idx) => record.update_datetime ? this.link_fund(record.id) : record.index,
      }, {
        title: 'Name',
        key: 'name',
        render: (text, record, idx) => this.print_fund_with_owners(record.id),
      }
    ];

    return (
      <Card size="small" title={"Your active indexes"}>
        {!isEmpty(data) ? (
          <Table {...this.props} dataSource={data} columns={columns_funds} bordered={false} pagination={false} size="small" rowKey={(record) => record.title} />
        ) : <i>No funds found</i>}
      </Card>);
  }
}

const FundForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {}

    componentDidMount() {
      this.reset();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.item != this.props.item) {
        this.reset();
      }
    }

    reset = () => {
      this.setState({
        active: this.props.item ? this.props.item.active : true,
        owners: this.props.item ? this.props.item.owners.map(e => e.owner) : [],
        viewers: this.props.item ? this.props.item.viewers : [],
        custom_name: this.props.item ? this.props.item.custom_name : null,
      });
      this.props.form.resetFields();
    }

    render() {
      const { visible, onCancel, onCreate, form, item, sections, campus } = this.props;
      const { getFieldDecorator } = form;
      const { index, grant, name, active, start_date, end_date, owners, viewers, college, custom_name } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Modal
          visible={visible}
          title={item ? "Edit index " : "Add a new index"}
          okText={item ? "Save" : "Create"}
          onCancel={() => { onCancel(); this.reset(null); }}
          onOk={() => { onCreate() }}
          width={800}
        >
          <Form onSubmit={this.handleSubmit} >
            <Divider orientation="left">Index Overview</Divider>
            <FormItem {...formItemLayout} label="Fund">{item ? item.fund : null}</FormItem>
            <FormItem {...formItemLayout} label="Organization">{item ? item.organization : null}</FormItem>
            <FormItem {...formItemLayout} label="Program">{item ? item.program : null}</FormItem>
            <FormItem {...formItemLayout} label="Index">{item ? item.index : null}</FormItem>
            <FormItem {...formItemLayout} label="Name">{item ? item.name : null}</FormItem>
            <FormItem {...formItemLayout} label="Grant Number">{item ? item.grant : null}</FormItem>
            <FormItem {...formItemLayout} label="Start Date">{item && item.start_date ? item.start_date : null}</FormItem>
            <FormItem {...formItemLayout} label="End Date">{item && item.end_date ? item.end_date : null}</FormItem>
            <FormItem {...formItemLayout} label="Administering College">{item ? this.print_college(item.college) : null}</FormItem>
            <Divider orientation="left">Update</Divider>
            <FormItem {...formItemLayout} label="Owner(s)">
              {getFieldDecorator('owners', {
                initialValue: owners,
              })(<Select showSearch mode="multiple" style={{ width: 360 }} filterOption={this.filter} >
                {this.employee_list().map(i => (<Option key={i.id} value={i.id}>{this.print_full_employee(i.id)}</Option>))}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Viewer(s)">
              {getFieldDecorator('viewers', {
                initialValue: viewers,
              })(<Select showSearch mode="multiple" style={{ width: 360 }} filterOption={this.filter} >
                {this.employee_list().map(i => (<Option key={i.id} value={i.id}>{this.print_full_employee(i.id)}</Option>))}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Custom Name">
              {getFieldDecorator('custom_name', {
                initialValue: custom_name,
                onChange: (event) => { this.setState({ custom_name: event }) },
              })(<Input style={{ width: 360 }} placeholder={this.props.item ? this.props.item.name : ""} />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Active">
              {getFieldDecorator('active', {
                initialValue: active,
                valuePropName: 'checked',
                onChange: (event) => { this.setState({ active: event }) },
              })(<Checkbox>
              </Checkbox>
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

const AddFundForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {}

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({
        index: null,
        owners: [],
        viewers: [],
        active: true,
      });
      this.props.form.resetFields();
    }

    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      const { index, active, owners, viewers } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Modal
          visible={visible}
          title={"Add a new index"}
          okText={"Create"}
          onCancel={() => { onCancel(); this.reset(null); }}
          onOk={() => { onCreate() }}
          width={800}
        >
          <p>Use the form below to add a new index to the Khoury Admin system.  This form will automatically import the index's data (fund number, organization number, etc) from Banner.</p>
          <Form onSubmit={this.handleSubmit} >
            <FormItem {...formItemLayout} label="Index">
              {getFieldDecorator('index', {
                rules: [{ required: true, message: 'Please enter an index.' }],
                initialValue: index,
              })(<InputNumber min={100000} max={999999} />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Owner(s)">
              {getFieldDecorator('owners', {
                initialValue: owners,
              })(<Select showSearch mode="multiple" style={{ width: 360 }} filterOption={this.filter} >
                {this.employee_list().map(i => (<Option key={i.id} value={i.id}>{this.print_full_employee(i.id)}</Option>))}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Viewer(s)">
              {getFieldDecorator('viewers', {
                initialValue: viewers,
              })(<Select showSearch mode="multiple" style={{ width: 360 }} filterOption={this.filter} >
                {this.employee_list().map(i => (<Option key={i.id} value={i.id}>{this.print_full_employee(i.id)}</Option>))}
              </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Active">
              {getFieldDecorator('active', {
                initialValue: active,
                valuePropName: 'checked',
                onChange: (event) => { this.setState({ active: event }) },
              })(<Checkbox>
              </Checkbox>
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);


class FundTable extends AppComponent {

  render() {
    const { funds, hide_columns, can_view, getActions } = this.props;

    const columns = [
      {
        title: 'Index',
        key: 'index',
        width: 80,
        render: (text, record, idx) => can_view ? this.link_fund_staff(record.id) : this.print_fund(record.id),
      }, {
        title: 'Fund',
        key: 'fund',
        width: 80,
        render: (text, record, idx) => record.fund,
      }, {
        title: 'Org',
        key: 'index',
        width: 80,
        render: (text, record, idx) => record.organization,
      }, {
        title: 'Grant',
        dataIndex: 'grant',
        key: 'grant',
        width: 100,
      }, {
        title: 'Banner Name',
        key: 'name',
        dataIndex: 'name',
        width: 200,
      }, {
        title: 'Custom Name',
        key: 'custom_name',
        dataIndex: 'custom_name',
        width: 200,
      }, {
        title: 'Active',
        key: 'active',
        width: 80,
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'Inactive',
            value: false,
          }],
        onFilter: (value, record) => record.active == value,
        render: (text, record, idx) => get_check_nocheck(record.active),
      }, {
        title: 'Start',
        key: 'start',
        width: 70,
        render: (text, record, idx) => record.start_date ? moment(record.start_date).format("MM/DD/YY") : null,
      }, {
        title: 'End',
        key: 'end',
        width: 70,
        render: (text, record, idx) => record.end_date ? moment(record.end_date).format("MM/DD/YY") : null,
      }, {
        title: 'Owners',
        key: 'owners',
        width: 200,
        render: (text, record, idx) => oxford(record.owners.map(e => this.link_employee_instructor(e.owner))),
      }, {
        title: 'Balance',
        key: 'balance',
        width: 100,
        filters: [
          {
            text: 'Negative',
            value: 'fund-negative',
          }],
        onFilter: (value, record) => record.status == value,
        render: (text, record, idx) => record.balance ? renderFundStatus(record.status, <NumberFormat value={record.balance} prefix="$" thousandSeparator="," displayType='text' decimalScale={2} fixedDecimalScale={true} />, false) : null,
      }, {
        title: 'College',
        key: 'college',
        width: 100,
        render: (text, record, idx) => this.print_college_tag(record.college),
      }, {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        width: 80,
        render: getActions,
      }];

    const mycolumns = columns.filter(c => !hide_columns || !hide_columns.includes(c.key));

    return (
      <Table {...this.props} dataSource={funds} columns={mycolumns} scroll={{ x: 1200 }} bordered={false} pagination={{ pageSize: 100 }} size="small" rowKey="id" />
    );
  }
}

class FinanceFundList extends AppComponent {
  state = {
    endpoint: "/api/funds/",
    modal_visible: false,
    selected_fund: null,
    searchedValue: "",

    add_modal_visible: false,
  }

  doUpdate = (id, data) => {
    this.doPatch(this.state.endpoint + id + "/", () => { message.success("Edited fund."); this.props.updateFunds(); this.setState({ modal_visible: false, selected_fund: null }, () => this.formRef.reset()); }, JSON.stringify(data));
  }

  handleUpdate = () => {
    const form = this.formRef.props.form;
    const { selected_fund } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }

      this.setState({ modal_visible: false });
      values["owners"] = values.owners.map(e => { return { owner: e, fraction: 1.0 / values.owners.length }; });

      this.doUpdate(selected_fund, values);
    });
  }

  handleCreate = () => {
    const form = this.addFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        values["owners"] = values.owners.map(e => { return { owner: e, fraction: 1.0 / values.owners.length }; });
        values["name"] = "ignore";
        this.doPost(this.state.endpoint, (r) => { if (r) { message.success("Added fund."); this.props.updateFunds(); } this.setState({ add_modal_visible: false }, () => form.resetFields()); }, JSON.stringify(values));
      }
    });
  }

  getActions = (text, record, idx) => {
    const menu = (
      <Menu>
        <Menu.Item>
          <a onClick={() => this.doUpdate(record.id, { active: !record.active })}>{record.active ? "Mark inactive" : "Mark active"}</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.setState({ selected_fund: record.id }, this.setState({ modal_visible: true }))}>{"Edit"}</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <a className="ant-dropdown-link">
          <div style={{ width: "75px" }}>Actions <Icon type="down" /></div>
        </a>
      </Dropdown>
    );
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  saveAddFormRef = (formRef) => {
    this.addFormRef = formRef;
  }

  searchTable = (record) => {
    const { searchedValue } = this.state;
    let lowercaseSearchValue = searchedValue.toLowerCase()
    return (record.index && record.index.toString().includes(lowercaseSearchValue)) || (record.organization && record.organization.toString().includes(lowercaseSearchValue)) || (record.grant && record.grant.includes(lowercaseSearchValue)) || this.print_fund(record.id).toString().startsWith(lowercaseSearchValue) || record.owners.some(owners => this.print_employee(owners.owner).toLowerCase().includes(lowercaseSearchValue)) || record.name.toLowerCase().includes(lowercaseSearchValue);
  }

  render() {
    const { modal_visible, selected_fund, searchedValue, add_modal_visible } = this.state;
    const funds = this.fund_list();
    const can_view = !this.props.user.impersonator && (this.props.user.groups.map(el => el.name).includes("finance") || this.props.user.groups.map(el => el.name).includes("grants"));

    return (
      <Content {...this.props} title="Index List" breadcrumbs={[{ link: "/employee", text: "Employee" }, { text: "Indexes" }]}>
        <p>This page lists all indexes that are used within Khoury, showing the fund, organization, grant, and index numbers; description; and list of faculty who are able to view that index.  In order to edit an index, use the Actions on the right hand side.  Indexes are automatically added by the Banner crawling scripts.</p>
        <Divider orientation="left">Funds</Divider>

        <FundForm
          {...this.props}
          item={selected_fund ? this.get_fund(selected_fund) : null}
          wrappedComponentRef={this.saveFormRef}
          visible={modal_visible}
          key={"form-" + selected_fund}
          onCancel={() => { this.formRef.reset(); this.setState({ modal_visible: false, selected_fund: null }) }}
          onCreate={this.handleUpdate} />
        <AddFundForm
          {...this.props}
          wrappedComponentRef={this.saveAddFormRef}
          visible={add_modal_visible}
          key={"add-form"}
          onCancel={() => { this.addFormRef.reset(); this.setState({ add_modal_visible: false }) }}
          onCreate={this.handleCreate} />
        <Input placeholder="Search for index, name or owner" style={{ width: 250 }} allowClear={true} onChange={e => this.setState({ searchedValue: e.target.value })}></Input>
        <div style={{ float: 'right', paddingBottom: 20 }}>
          <Button type="primary" icon="plus" onClick={() => this.setState({ add_modal_visible: true })}>Add New Fund</Button>
        </div>
        <FundTable {...this.props} funds={funds.filter(el => searchedValue ? this.searchTable(el) : el)} can_view={can_view} hide_columns={can_view ? [] : ["balance"]} getActions={this.getActions} />
      </Content>
    );
  }
}

class FundList extends AppComponent {
  state = {
    endpoint: "/api/funds/transactions/",
    transactions: [],
    loading_transactions: true,

    endpoint_payroll: "/api/funds/payroll/",
    payroll: [],
    loading_payroll: true,

    selected_year: null,
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    const data = this.fund_list().filter(f => f.active && (f.owners.find(fo => fo.owner == this.props.user.employee) || f.viewers.find(fo => fo == this.props.user.employee)) && f.asof_datetime != null);
    if (data?.length > 0) {
      this.doGet(this.state.endpoint + "?index=" + data.map(f => f.id).join(",") + "&date__gt=" + moment(data[0].asof_datetime).format("YYYY-MM-DD"), data => this.setState({ transactions: data, loading_transactions: false }));
      this.doGet(this.state.endpoint_payroll + "?index=" + data.map(f => f.id).join(","), data => this.setState({ payroll: data, loading_payroll: false }));
    } else {
      this.setState({ transactions: [], loading_transactions: false, payroll: [], loading_payroll: false });
    }
  }

  getPayees = (account, payee_map) => {
    const mypayroll = this.getPayroll();

    return [...new Set(mypayroll.filter(p => p.account == account).map(p => p.payee_id))].sort((x, y) => payee_map[x] < payee_map[y] ? -1 : 1);
  }

  getPayroll = () => {
    const { payroll, selected_year } = this.state;

    return payroll.filter(p => (selected_year == null) || (moment(p.pay_date, "YYYY-MM-DD").format('YYYY') == selected_year));
  }

  getColumnsPayroll = (payee_funds, account) => {
    const mypayroll = this.getPayroll();

    return payee_funds.map(p => {
      return {
        title: this.link_fund(p),
        key: p,
        width: 100,
        align: "right",
        render: (text, record, idx) => mypayroll.filter(l => (l.payee_id == record) && (l.index == p) && (l.account == account)).reduce((a, r) => a + r.amount, 0) != 0 ? <NumberFormat value={mypayroll.filter(l => (l.payee_id == record) && (l.index == p) && (l.account == account)).reduce((a, r) => a + r.amount, 0)} prefix="$" thousandSeparator="," displayType='text' decimalScale={2} fixedDecimalScale={true} /> : null,
      };
    });
  }

  render() {
    const { loading_transactions, transactions, loading_payroll, payroll, selected_year } = this.state;
    const data = this.fund_list().filter(f => f.active && (f.owners.find(fo => fo.owner == this.props.user.employee) || f.viewers.find(fo => fo == this.props.user.employee)));
    const columns_funds = [
      {
        title: 'Index',
        key: 'index',
        width: 65,
        fixed: 'left',
        render: (text, record, idx) => record.update_datetime ? window.location.pathname.includes("faculty") ? this.link_fund(record.id) : this.link_fund_staff(record.id) : record.index,
      }, {
        title: 'Fund',
        key: 'fund',
        width: 65,
        render: (text, record, idx) => record.fund,
      }, {
        title: 'Org.',
        key: 'org',
        width: 65,
        render: (text, record, idx) => record.organization,
      }, {
        title: 'Name',
        key: 'name',
        width: 200,
        render: (text, record, idx) => record.name,
      }, {
        title: 'Owners',
        key: 'owners',
        width: 200,
        render: (text, record, idx) => oxford(record.owners.map(o => this.link_employee_instructor(o.owner))),
      }, {
        title: 'Your Split',
        key: 'split',
        align: 'right',
        width: 80,
        render: (text, record, idx) => [record.owners.find(o => o.owner == this.props.user.employee).fraction * 100, "%"],
      }, {
        title: 'Last Close',
        key: 'close',
        width: 140,
        render: (text, record, idx) => record.asof_datetime ? moment(record.asof_datetime).format("MMM Do YYYY") : null,
      }, {
        title: 'Latest Transaction',
        key: 'transaction',
        width: 140,
        render: (text, record, idx) => transactions.filter(t => t.index == record.id)?.length > 0 ? transactions.filter(t => t.index == record.id).reduce((a, r) => moment(r.date) > a ? moment(r.date) : a, moment(0)).format("MMM Do YYYY") : null,
      }, {
        title: 'Balance',
        key: 'balance',
        align: 'right',
        width: 140,
        render: (text, record, idx) => loading_transactions || loading_payroll ? <i>Loading...</i> : record.balance != null ? <NumberFormat value={record.balance - transactions.filter(t => t.index == record.id).reduce((a, r) => r.revenue_amount + a, 0) - transactions.filter(t => t.index == record.id).reduce((a, r) => r.commitment_amount + a, 0) + transactions.filter(t => (t.index == record.id) && (t.rule == "BD01" || t.rule == "BD04")).reduce((a, r) => r.budget_amount + a, 0)} prefix="$" thousandSeparator="," displayType='text' decimalScale={2} fixedDecimalScale={true} /> : <i>Unknown</i>,
      }
    ];

    const mypayroll = this.getPayroll();

    const payee_map = {};
    mypayroll.forEach(p => payee_map[p.payee_id] = p.payee);

    const base_column_payroll = [{
      title: 'Payee',
      key: 'payee',
      render: (text, record, idx) => payee_map[record],
    }];

    const accounts = [...new Set(mypayroll.map(p => p.account))].sort((x, y) => this.get_account(x).description < this.get_account(y).description ? -1 : 1);
    const payee_funds = [...new Set(mypayroll.map(p => p.index))].sort((x, y) => this.get_fund(x).index < this.get_fund(y).index ? -1 : 1);
    const payroll_years = [...new Set(payroll.map(p => moment(p.pay_date, "YYYY-MM-DD").format('YYYY')))].sort();

    let breadcrumb = [{ link: "/employee", text: "Employee" }, { text: "Indexes" }]

    return (
      <Content {...this.props} title={"Active Index Details"} breadcrumbs={breadcrumb}>
        <p>This page lists all of the active indexes which you are an owner or viewer for. Note that finance information is only updated roughly weekly, so the information below may be slightly out of date (you can see the updated date in the Updated column).  Click on any index to see a detailed list of transactions.</p>
        <CustomTabs {...this.props}>
          <TabPane tab="Overview" key="overview">
            {loading_transactions ? <Spin tip="Loading recent transactions..." /> : data?.length > 0 ? (
              (<Table {...this.props} dataSource={data} columns={columns_funds} scroll={{ x: 800 }} bordered={false} pagination={false} size="small" rowKey={(record) => record.index} />)
            ) : <i>No indexes found</i>}
          </TabPane>

          <TabPane tab="Payees" key="payees">
            <p>Filter by year: <Select defaultValue={null} style={{ width: 200 }} onChange={y => this.setState({ selected_year: y })}>
              <Option value={null}>All years</Option>
              {payroll_years.map(y => <Option value={y}>Calendar year {y}</Option>)}
            </Select></p>
            {loading_payroll ? <Spin tip="Loading payroll records..." /> : mypayroll?.length > 0 ?
              accounts.map(a => (
                <>
                  <Divider orientation="left">{this.get_account(a).description}</Divider>
                  <Table {...this.props} dataSource={this.getPayees(a, payee_map)} columns={base_column_payroll.concat(this.getColumnsPayroll(payee_funds, a))} scroll={{ x: 800 }} bordered={false} pagination={false} size="small" rowKey={(record) => record.index} />
                </>
              ))
              : <i>No payees found</i>}
          </TabPane>
        </CustomTabs>
      </Content>);
  }
}

class TransactionTable extends AppComponent {

  merge_deposits = (transactions) => {
    const deposits = transactions.filter(t => t.rule == "BD01" || t.rule == "BD04");
    if (deposits?.length > 0) {
      const merged = [...new Set(deposits.map(t => t.date))].map(d => {
        const matching = deposits.filter(t => t.date == d).map(t => {
          const r = { ...t };
          r.revenue_amount = -1 * r.budget_amount;
          r.date = null;
          r.child = true;
          return r;
        });

        return {
          "id": "deposit-" + d.date,
          "account": null,
          "commitment_amount": null,
          "date": d,
          "description": "Deposit or grant increment",
          "fund": matching[0].index,
          "revenue_amount": matching.reduce((a, r) => a + -1 * r.budget_amount, 0),
          "rule": "",
          "document": matching[0].document,
          "children": matching,
        };
      });

      return transactions.filter(t => !(t.rule == "BD01" || t.rule == "BD04")).concat(merged);
    } else {
      return transactions;
    }
  }

  merge_invoices = (transactions) => {
    const invoices = transactions.filter(t => t.invoice != null);
    if (invoices?.length > 0) {
      const merged = [...new Set(invoices.map(t => t.invoice))].map(i => {
        const matching = invoices.filter(t => t.invoice == i);
        const children = matching.map(t => {
          const r = { ...t };
          r.date = null;
          r.child = true;
          return r;
        });

        const who = matching.find(t => t.rule == "INNI" || t.rule == "ICNI") ?
          matching.find(t => t.rule == "INNI" || t.rule == "ICNI").description :
          matching.find(t => t.reference && t.reference.startsWith("DOCREF#:  ")) ?
            matching.find(t => t.reference && t.reference.startsWith("DOCREF#:  ")).reference.substring(10) :
            "Unknown";

        return {
          "id": "invoice-" + i,
          "account": null,
          "commitment_amount": null,
          "date": matching[0].date,
          "description": "Invoice or reimbursement (" + who + ")",
          "fund": matching[0].index,
          "revenue_amount": matching.reduce((a, r) => a + r.revenue_amount, 0),
          "rule": "",
          "document": matching[0].document,
          "children": children,
        };
      });

      return transactions.filter(t => t.invoice == null).concat(merged);
    } else {
      return transactions;
    }
  }

  identify_redistributed = (payroll, transactions) => {
    payroll.redistributed = null;

    if (payroll.payee_id && (payroll.amount > 0)) {
      const redistributed = transactions.find(t => (payroll.payee_id == t.payee_id) && (payroll.pay_id == t.pay_id) && (payroll.transaction_date < t.transaction_date) && (payroll.pay_date == t.pay_date) && (t.amount < 0));
      if (redistributed) {
        payroll.redistributed_amount = redistributed.amount;
        payroll.redistributed = redistributed.transaction_date;
      }
    } else if (payroll.payee_id && (payroll.amount < 0)) {
      const redistributed = transactions.find(t => (payroll.payee_id == t.payee_id) && (payroll.pay_id == t.pay_id) && (payroll.transaction_date > t.transaction_date) && (payroll.pay_date == t.pay_date) && (t.amount > 0));
      if (redistributed) {
        payroll.redistributed_amount = redistributed.amount;
        payroll.redistributed = redistributed.transaction_date;
      }
    }

    return payroll;
  }

  merge_salary = (transactions, payroll) => {
    var salaries = transactions.filter(t => t.rule == "HGNL" || t.rule == "PF01");

    if (salaries?.length > 0) {
      const merged = [...new Set(salaries.map(t => t.document))].map(d => {
        const matching = salaries.filter(t => t.document == d);
        const date = matching[0].date;

        var redistributed = null;
        const payrolls = payroll.map(p => this.identify_redistributed(p, payroll)).filter(p => (p.document == d) && matching.find(m => p.account == m.account)).map(p => {
          redistributed = (p.adjustment != "Original") && (redistributed == null) ? p.adjustment : redistributed;

          return {
            "id": p.id,
            "account": p.account,
            "commitment_amount": null,
            "date": p.adjustment != "Original" ? p.pay_date : null,
            "description": p.payee,
            "fund": p.index,
            "revenue_amount": p.amount,
            "rule": "HGNL",
            "document": p.document,
            "adjustment": p.adjustment,
            "redistributed": p.redistributed,
            "child": true,
          };
        });

        const fringe = matching.filter(t => t.rule == "PF01").map(f => {
          const r = { ...f };
          r.date = (redistributed ? r.date : null);
          r.child = true;
          return r;
        })

        const all = payrolls.concat(fringe);

        return {
          "id": "salary-" + d,
          "salary": true,
          "account": null,
          "commitment_amount": null,
          "date": date,
          "description": "Salary and fringe" + (redistributed ? " (" + redistributed + ")" : ""),
          "fund": matching[0].index,
          "revenue_amount": matching.reduce((a, r) => a + r.revenue_amount, 0),
          "rule": "",
          "document": d,
          "children": all,
        };
      });

      return transactions.filter(t => !(t.rule == "HGNL" || t.rule == "PF01")).concat(merged);
    } else {
      return transactions;
    }
  }

  filter_commitments = (transactions, show_commitments) => {
    return transactions;
    //    return show_commitments ? transactions : transactions.filter(t => t.commitment_amount == null);
  }

  merge_other = (transactions) => {
    transactions.forEach(t => {
      if (!t.children) {
        t.children = [];
      }
    });

    return transactions;
  }

  get_accounts = (months) => {
    const accounts = {};

    Object.keys(months).forEach(month => {
      const year = this.month_to_year(month);
      accounts[year] = accounts[year] ? accounts[year] : {};

      months[month]["transactions"].filter(t => !(t.rule == "BD01" || t.rule == "BD04")).forEach(t => {
        if (t["children"]?.length > 0) {
          t["children"].filter(c => !(c.rule == "BD01" || c.rule == "BD04")).forEach(c => {
            if (!isNaN(c["revenue_amount"])) {
              accounts[year][c["account"]] = accounts[year][c["account"]] ? accounts[year][c["account"]] + c["revenue_amount"] : c["revenue_amount"];
            }
          });
        } else {
          if ((!isNaN(t["revenue_amount"])) && (t["account"] != null)) {
            accounts[year][t["account"]] = accounts[year][t["account"]] ? accounts[year][t["account"]] + t["revenue_amount"] : t["revenue_amount"];
          }
        }
      });

      months[month]["overhead"].forEach(t => {
        accounts[year][918] = accounts[year][918] ? accounts[year][918] + t["revenue_amount"] : t["revenue_amount"];
      });

    });

    return accounts;
  }

  get_transactions = (show_commitments) => {
    const { transactions, fund, payroll } = this.props;

    const months = {};

    this.merge_other(this.filter_commitments(this.merge_invoices(this.merge_salary(this.merge_deposits(transactions), payroll)), show_commitments)).map(t => {
      const month = moment(t.date).format("YYYY-MM");
      if (!(month in months)) {
        months[month] = { "overhead": [], "transactions": [], "commitments": [], "all": [] };
      }

      if (t.rule == "GRIC") {
        months[month]["overhead"].push(t);
      } else {
        if (t.commitment_amount != null) {
          months[month]["commitments"].push(t);
        } else {
          months[month]["transactions"].push(t);
        }

        if (show_commitments || t.commitment_amount == null) {
          months[month]["all"].push(t);
        }
      }

    });

    const balance_asof = moment(fund.asof_datetime).format("YYYY-MM");
    const future_months = Object.keys(months).filter(m => m > balance_asof);
    const future_spent = future_months.reduce((a, m) => months[m]["transactions"].reduce((a, r) => r.revenue_amount + a, 0) + a, 0);
    const future_overhead = future_months.reduce((a, m) => months[m]["overhead"].reduce((a, r) => r.revenue_amount + a, 0) + a, 0);
    const future_committed = future_months.reduce((a, m) => months[m]["commitments"].reduce((a, r) => r.commitment_amount + a, 0) + a, 0);

    var balance = fund.balance - (future_spent + future_overhead + future_committed);
    var commitments = fund.commitments + future_committed;
    Object.keys(months).sort().reverse().forEach(m => {
      const spent = months[m]["transactions"].reduce((a, r) => r.revenue_amount + a, 0);
      const overhead = months[m]["overhead"].reduce((a, r) => r.revenue_amount + a, 0);
      const committed = months[m]["commitments"].reduce((a, r) => r.commitment_amount + a, 0);

      months[m]["all"] = months[m]["all"].sort((a, b) => a.date < b.date);

      months[m]["all"].unshift({
        'id': 'month-' + m,
        'date': m > balance_asof ? (<b>Month Ongoing</b>) : (<b>Month End</b>),
        'description': m > balance_asof ? (<i>Note: Transactions/overhead may not yet be finalized</i>) : null,
        'balance': balance,
        'commitments': commitments,
        'spent': spent,
        'overhead': overhead,
        'decommitted': committed,
      })

      balance += spent;
      balance += overhead;
      balance += committed;
      commitments -= committed;
    });

    return months;
  }

  month_to_year = (month) => {
    const { fiscal_year_start } = this.props;

    const year = month.substring(0, 4);
    const mon = month.substring(5, 7);

    var result = year;
    if ((fiscal_year_start != "01") && (mon >= fiscal_year_start)) {
      result = "" + (Number(year) + 1);
    }

    return result;
  }

  get_payees = (months) => {
    const payees = {};

    Object.keys(months).forEach(month => {
      const year = this.month_to_year(month);
      payees[year] = payees[year] ? payees[year] : {};
      months[month]["all"].forEach(a => {
        if (a["salary"]) {
          a["children"].forEach(c => {
            if (c["rule"] == "HGNL") {
              const name = c["description"];
              payees[year][name] = payees[year][name] ? payees[year][name] : {};

              const account = c["account"];
              payees[year][name][account] = payees[year][name][account] ? payees[year][name][account] + c["revenue_amount"] : c["revenue_amount"];
            }
          })
        }
      })
    });

    return payees;
  }

  dollars = (amount, child) => {
    if (amount == null) {
      return null;
    }

    const base = <NumberFormat value={amount} prefix="$" thousandSeparator="," displayType='text' decimalScale={2} fixedDecimalScale={true} />;
    const rebase = child ? <i>{base}</i> : <b>{base}</b>;
    return amount < 0 ? <span class="transaction-negative">{rebase}</span> : rebase;
  }

  render() {
    const { transactions, hide_columns, getActions, show_commitments, nogroup, selected, fiscal_year_start } = this.props;

    const columns = [
      {
        title: 'Date',
        key: 'date',
        width: 135,
        dataIndex: 'date',
        hide_commitment: false,
      }, {
        title: 'Rule',
        dataIndex: 'rule',
        width: 60,
        key: 'rule',
        hide_commitment: false,
      }, {
        title: 'Description',
        key: 'description',
        render: (text, record, idx) => record.redistributed ? record.revenue_amount > 0 ?
          <>{record.description} <Tooltip title={"This payment appears to have been " + (record.redistributed_amount == record.amount ? "fully" : "partially") + " reversed on " + moment(record.redistributed).format("MMMM Do YYYY") + "."}><Icon type="history" /></Tooltip></> :
          <>{record.description} <Tooltip title={"This payment appears to be a " + (record.redistributed_amount == record.amount ? "full" : "partial") + " reversal of a payment on " + moment(record.redistributed).format("MMMM Do YYYY") + "."}><Icon type="history" /></Tooltip></> :
          record.description,
        hide_commitment: false,
      }, {
        title: 'Account',
        key: 'account',
        render: (text, record, idx) => record.account ? this.get_account(record.account).description : null,
        hide_commitment: false,
      }, {
        title: 'Commitment',
        key: 'commitment_amount',
        align: 'right',
        width: 120,
        render: (text, record, idx) => this.dollars(record.commitment_amount ? record.commitment_amount : record.decommitted, record.child),
        hide_commitment: true,
      }, {
        title: 'Spent',
        key: 'revenue_amount',
        align: 'right',
        width: 120,
        render: (text, record, idx) => this.dollars(record.revenue_amount ? record.revenue_amount : record.spent, record.child),
        hide_commitment: false,
      }, {
        title: 'Overhead',
        key: 'overhead',
        align: 'right',
        width: 120,
        render: (text, record, idx) => this.dollars(record.overhead, record.child),
        hide_commitment: false,
      }, {
        title: 'Available',
        key: 'available',
        align: 'right',
        width: 120,
        render: (text, record, idx) => this.dollars(record.balance + record.commitments, record.child),
        hide_commitment: true,
      }, {
        title: 'Committed',
        key: 'commited',
        align: 'right',
        width: 120,
        render: (text, record, idx) => this.dollars(record.commitments, record.child),
        hide_commitment: true,
      }, {
        title: 'Balance',
        key: 'balance',
        align: 'right',
        width: 120,
        render: (text, record, idx) => this.dollars(record.balance, record.child),
        hide_commitment: false,
      }];

    var mycolumns = columns.filter(c => (!c.hide_commitment) || show_commitments);

    if (nogroup) {
      mycolumns = columns.filter(c => c.key != "balance" && c.key != "overhead" && c.key != "available" && c.key != "commited" && c.key != "commitment_amount");

      if (this.props.brief) {
        mycolumns = mycolumns.filter(c => c.key != "rule" && c.key != "account")
      }

      const rowSelection = selected ? {
        onChange: (selectedRowKeys, selectedRows) => {
          selected ? selected(selectedRowKeys) : null
        },
      } : null;

      return <Table {...this.props} rowSelection={rowSelection} dataSource={transactions} columns={mycolumns} scroll={{ x: this.props.brief ? 500 : 1200 }} bordered={false} pagination={false} size="small" rowKey="id" defaultExpandAllRows={true} />
    } else {
      const months = this.get_transactions(show_commitments);
      const payees = this.get_payees(months);

      const payee_columns = [{
        title: "Payee",
        key: "Payee",
        render: (text, record, idx) => record["name"],
      }, {
        title: "Account",
        key: "Account",
        render: (text, record, idx) => this.get_account(record["account"]).description,
      }].concat(Object.keys(payees).filter(y => Object.keys(payees[y])?.length > 0).map(y => {
        return {
          title: y,
          key: y,
          align: "right",
          render: (text, record, idx) => this.dollars(payees[y][record["name"]] ? payees[y][record["name"]][record["account"]] : null),
        };
      })).concat([{
        title: "Total",
        key: "total",
        align: "right",
        render: (text, record, idx) => this.dollars(Object.keys(payees).map(y => payees[y][record["name"]] ? payees[y][record["name"]][record["account"]] : 0).reduce((a, r) => a + r, 0)),
      }]);

      const payee_rows = {};
      Object.keys(payees).forEach(y => {
        Object.keys(payees[y]).forEach(n => {
          Object.keys(payees[y][n]).forEach(a => {
            payee_rows[n] = payee_rows[n] ? payee_rows[n] : {};
            payee_rows[n][a] = 1;
          })
        })
      });

      const payee_final = [];
      Object.keys(payee_rows).sort().forEach(n =>
        Object.keys(payee_rows[n]).forEach(a =>
          payee_final.push({ "name": n, "account": a })));

      const accounts = this.get_accounts(months);

      const account_columns = [{
        title: "Account",
        key: "Account",
        render: (text, record, idx) => this.get_account(record).description,
      }].concat(Object.keys(accounts).filter(y => Object.keys(accounts[y])?.length > 0).map(y => {
        return {
          title: y,
          key: y,
          align: "right",
          render: (text, record, idx) => this.dollars(accounts[y][record]),
        };
      })).concat([{
        title: "Total",
        key: "total",
        align: "right",
        render: (text, record, idx) => this.dollars(Object.keys(accounts).map(y => accounts[y][record] ? accounts[y][record] : 0).reduce((a, r) => a + r, 0)),
      }]);

      const accounts_rows = {};
      Object.keys(accounts).forEach(y => {
        Object.keys(accounts[y]).forEach(a => {
          accounts_rows[a] = 1;
        })
      });

      const accounts_final = Object.keys(accounts_rows).sort(this.account_comparator);

      return (Object.keys(months)?.length > 0 ?
        <CustomTabs {...this.props}>
          <TabPane tab="Transactions" key="transactions">
            {Object.keys(months).sort().reverse().map(m => (
              <>
                <Divider orientation="left">{moment(m + "-01").format("MMMM YYYY")}</Divider>
                <Table {...this.props} dataSource={months[m]["all"]} columns={mycolumns} scroll={{ x: 1200 }} bordered={false} pagination={false} size="small" rowKey="id" defaultExpandAllRows={true} />
              </>
            ))}
          </TabPane>
          <TabPane tab="Breakdown" key="breakdown">
            <Table {...this.props} dataSource={accounts_final} columns={account_columns} scroll={{ x: 1200 }} bordered={false} pagination={false} size="small" rowKey="id" defaultExpandAllRows={true} />
          </TabPane>
          <TabPane tab="Payees" key="payees">
            <Table {...this.props} dataSource={payee_final} columns={payee_columns} scroll={{ x: 1200 }} bordered={false} pagination={false} size="small" rowKey="id" defaultExpandAllRows={true} />
          </TabPane>
        </CustomTabs> : <i>No transactions found.</i>
      );
    }
  }
}

class FundDetails extends AppComponent {
  state = {
    endpoint: "/api/funds/transactions/",
    transactions: [],
    loading_transactions: true,

    endpoint_payroll: "/api/funds/payroll/",
    payroll: [],
    loading_payroll: true,

    fiscal_year_start: this.get_fund(this.props.match.params.fund_id).start_date ? this.get_fund(this.props.match.params.fund_id).start_date.substring(5, 7) : "07",

    show_commitments: false,
  }

  get_start_month = () => {
    const fund = this.get_fund(this.props.match.params.fund_id);

    if (fund.start_date) {
      return fund.start_date.substring(5, 7);
    }

    return "07";
  }

  componentDidMount() {
    this.getData();
    //    this.setState({fiscal_year_start: this.get_start_month()});
  }

  getData = () => {
    this.doGet(this.state.endpoint + "?index=" + this.props.match.params.fund_id, data => this.setState({ transactions: data, loading_transactions: false }));
    this.doGet(this.state.endpoint_payroll + "?index=" + this.props.match.params.fund_id, data => this.setState({ payroll: data, loading_payroll: false }));
  }

  get_basics = () => {
    const fund = this.get_fund(this.props.match.params.fund_id);

    return (
      <div>
        <div><b>Index</b>: {fund.index}</div>
        <div><b>Fund</b>: {fund.fund}</div>
        <div><b>Organization</b>: {fund.organization}</div>
        <div><b>Program</b>: {fund.program}</div>
        <div><b>Banner Name</b>: {fund.name}</div>
        <div><b>Custom Name</b>: {fund.custom_name}</div>
        <div><b>Owner(s)</b>: {oxford(fund.owners.map(a => [this.print_employee(a.owner), " (", a.fraction * 100, "%)"]))}</div>
        <div><b>Viewer(s)</b>: {oxford(fund.viewers.map(a => this.print_employee(a)))}</div>
        <div><b>Grant Number</b>: {fund.grant ? fund.grant : null}</div>
        <div><b>Start Date</b>: {fund.start_date ? fund.start_date : null}</div>
        <div><b>End Date</b>: {fund.end_date ? fund.end_date : null}</div>
        <div><b>College</b>: {this.print_college(fund.college)}</div>
      </div>
    )
  }

  get_fiscal_year = () => {
    const { fiscal_year_start } = this.state;

    return (
      <div>
        <div><b>Fiscal Year Start</b>:
          <Select style={{ marginLeft: '20px', width: 120 }} onChange={e => this.setState({ fiscal_year_start: e })} defaultValue={fiscal_year_start}>
            <Option value="01">January 1st</Option>
            <Option value="02">February 1st</Option>
            <Option value="03">March 1st</Option>
            <Option value="04">April 1st</Option>
            <Option value="05">May 1st</Option>
            <Option value="06">June 1st</Option>
            <Option value="07">July 1st</Option>
            <Option value="08">August 1st</Option>
            <Option value="09">September 1st</Option>
            <Option value="10">October 1st</Option>
            <Option value="11">Novembe 1st</Option>
            <Option value="12">December 1st</Option>
          </Select>
        </div>
      </div>
    )
  }

  render() {
    const { transactions, payroll, loading_transactions, loading_payroll, fiscal_year_start } = this.state;

    const loading = loading_payroll || loading_transactions;
    const fund = this.get_fund(this.props.match.params.fund_id);

    var chars = [
      { title: "Basics", content: this.get_basics() },
      { title: "Controls", content: this.get_fiscal_year() },
    ];

    const show_commitments = true;

    return (
      <Content {...this.props} title={"Index " + fund.index + " Details"} breadcrumbs={[{ link: "/employee", text: "Employee" }, { link: "/employee/fund", text: "Indexes" }, { text: fund.index }]}>
        <p>This page lists the transactions on index {fund.index} {fund.name}.</p>

        <List grid={this.grid}
          dataSource={chars}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />

        {loading ? <Spin tip="Loading transactions..." /> : <TransactionTable {...this.props} transactions={transactions} fund={fund} payroll={payroll} show_commitments={show_commitments} fiscal_year_start={fiscal_year_start} />}
      </Content>
    );
  }
}

export { FinanceFundList, FundTable, FundCard, FundDetails, FundList, TransactionTable };