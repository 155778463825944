import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import queryString from 'query-string'
import key from 'weak-key';

import { Layout, Menu, Breadcrumb, Icon, Table, Tag, Tooltip, Spin, Divider, message } from 'antd';
const { SubMenu } = Menu;
const { Content, Sider, Footer } = Layout;

const JSON_CONTENT_TYPE = "application/json";

class LoggedInComponent extends Component {

  controller = new AbortController();

  componentWillUnmount() {
    this.cancelRequests();
  }
  
  cancelRequests() { 
    this.controller.abort();
    this.controller = new AbortController();
  }

  getAuthorizationHeader = () => {
    return "Token " + this.props.token;
  }

  doFetch = (method, endpoint, headers, body, expected_code, expected_content_type, func, no_error_log) => {
    headers["Authorization"] = this.getAuthorizationHeader();

    fetch(endpoint, {
      headers: headers,
      method: method,
      body: body,
      signal: this.controller.signal,
    }).then(response => {
      if ((response.status !== 200) && (response.status !== expected_code) &&
          !((response.status == 500) && response.headers.get('Content-Type') == JSON_CONTENT_TYPE)) {
        if ((response.status == 401) || (response.status == 403)) {
          this.props.logged_out(false);
        } else {
          if (no_error_log) {
            message.error("Encountered error logging error");
          } else {
            message.error("Got response code " + response.status + " loading from " + endpoint + ".");
          }
        }
      } else if ((expected_code == 204) && (method == "DELETE")) {
        message.success("Deleted item")
        return null;
      } else if (response.headers.get('Content-Type') != expected_content_type) {
        message.error("Got response of unexpected content type " + response.headers.get('Content-Type') + " loading from " + endpoint + ".")
        return null;
      } else if (expected_content_type == JSON_CONTENT_TYPE) {
        return response.json();
      } else {
        return response.blob();
      }
    }).then(data => {
      if (data && (data.is_error)) {
        if (no_error_log) {
          message.error("Encountered error logging error");
        } else {
          message.error("Received error: " + data.status_code + ": " + data.message + ".");
        }
      } else {
        func(data);
      }
    }, error => {
      if (! ((error instanceof DOMException) && (error.name == "AbortError"))) {
        message.error("Caught error: " + error);
      }
    });
  }

  doGet = (endpoint, func) => {
    this.doFetch('GET', endpoint, {}, null, 200, JSON_CONTENT_TYPE, func);
  }

  doGetContentType = (endpoint, type, func) => {
    this.doFetch('GET', endpoint, {}, null, 200, type, func);
  }

  doPost = (endpoint, func, body) => {
    this.doPostContentType(endpoint, func, body, JSON_CONTENT_TYPE);
  }

  doPatch = (endpoint, func, body) => {
    this.doPatchContentType(endpoint, func, body, JSON_CONTENT_TYPE);
  }

  doPut = (endpoint, func, body) => {
    this.doPutContentType(endpoint, func, body, JSON_CONTENT_TYPE);
  }

  doDelete = (endpoint, func, body) => {
    this.doDeleteContentType(endpoint, func, body, JSON_CONTENT_TYPE);
  }

  doPostContentType = (endpoint, func, body, type) => {
    this.doFetch('POST', endpoint, { "Content-Type": type }, body, 201, JSON_CONTENT_TYPE, func);
  }

  doPatchContentType = (endpoint, func, body, type) => {
    this.doFetch('PATCH', endpoint, { "Content-Type": type }, body, 200, JSON_CONTENT_TYPE, func);
  }

  doPutContentType = (endpoint, func, body, type) => {
    this.doFetch('PUT', endpoint, { "Content-Type": type }, body, 200, JSON_CONTENT_TYPE, func);
  }

  doDeleteContentType = (endpoint, func, body, type) => {
    this.doFetch('DELETE', endpoint, { "Content-Type": type }, body, 204, JSON_CONTENT_TYPE, func);
  }

  openPDF = (path, filename) => {
    this.doFetch('GET', path, {}, null, 200, "application/pdf", data => {
      if (data) {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = window.URL.createObjectURL(file);
  
        // Open new windows and show PDF
        // window.open(fileURL);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = filename;
        link.click();
        setTimeout(function(){
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(fileURL);
        }, 100);
      } else {
        message.error("Unable to load document.")
      }
    });
  }

  doAction = (endpoint, data, action, func ) => {
    const callback = (responseData) => {
      if (responseData) {
        const successMessage = action === "update" ? "updated" : "submitted";
        message.success("Item successfully " +  successMessage + ".");
        func(responseData);
      }
    };
  
    if (action === "update") {
      this.doPut(endpoint, callback, JSON.stringify(data));
    } else if (action === "add") {
      this.doPost(endpoint, callback, JSON.stringify(data));
    } else if (action === "delete") {
      this.doDelete(endpoint, callback, JSON.stringify(data));
    }
  }

}

export default LoggedInComponent;
