import React, { Component } from "react";
import moment from "moment-timezone";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './../AppComponent';
import StatusComponent from './../StatusComponent';
import { Status } from './../StatusComponent';
import Preference from './../Preference';
import { CSVLink } from "react-csv";
import { add_dividers, add_dividers_horiz, format_percent, format_decimal, getTAStatus, add_brs, avg } from './../Utils';

import { PageHeader, Typography, Popover, Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, Rate, message, Button } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const { Text, Title } = Typography;

class TAApplicationTable extends AppComponent {
  state = {
    selected: false,
  }

  avg_review = (record) => {
    const { tareviews } = this.props;

    if (!tareviews) { return 0; }
    return avg(tareviews.filter(el => el.taapplication == record.id && el.preference != 1).map(el => el.preference + 1));
  }

  review_sort_array = (record) => {
    const { allTaapplications } = this.props;
    return [getTAStatus(record.ta.id, allTaapplications)?.length > 0 ? 1 : 0, -1 * this.avg_review(record), record.preference];
  }

  sort_order = (a, b) => {
    const a_a = this.review_sort_array(a);
    const b_a = this.review_sort_array(b);

    for (var i = 0; i <= a_a.length; i++) {
      if (a_a[i] != b_a[i]) { return a_a[i] - b_a[i]; }
    }

    return 0;
  }

  print_rec = (el, type) => {
    return <Tooltip key={"tt-" + el.instructor + "-" + el.course + "-" + el.semester} title={type + " in " + this.print_course(el.course) + (el.semester ? " during " + this.print_semester(el.semester) : "") + " by " + this.print_full_instructor(el.instructor) + ": " + (el.preference == 0 ? "No" : el.preference == 2 ? "Maybe" : el.preference == 3 ? "Probably" : "Definitely") + (el.notes ? " (notes: " + el.notes + ")" : "")}><span><Rate disabled count={3} key={el.id} defaultValue={el.preference > 1 ? el.preference - 1 : el.preference} /></span></Tooltip>
  }

  download_rec = (record) => {
    return record ? record.filter(el => el.preference != 1).map(el => el.preference > 1 ? el.preference - 1 : el.preference) : "";
  }

  print_recommendations = (record) => {
    return record.recommendations ? add_brs(record.recommendations.filter(el => el.preference != 1).map(el => this.print_rec(el, "Recommendation"))) : null;
  }

  print_reviews = (record) => {
    return record.reviews ? add_brs(record.reviews.filter(el => el.preference != 1).map(el => this.print_rec(el, "Review as a TA"))) : null;
  }

  print_group_name = (record) => {
    const { groups } = this.props;
    let group = groups.find(g => g.id == record.group)
    return group ? group.name : null;
  }


  render() {
    const { loading, taapplications, getTAProfile, getAction, getHours, getJobId, getPayRate, width, actionWidth, hide_fields, hoursWidth, allTaapplications, campus, tareviews, assign_table, groups } = this.props;
    const { selected } = this.state;

    const semesterFilter =
      this.props.semesters.map(el => ({
        text: this.print_semester(el),
        value: this.print_semester(el)
      }));

    const campuses = Array.from(new Set(taapplications.map(el => el.ta.campus)));

    const allcolumns = [
      {
        title: 'Photo',
        key: 'photo',
        fixed: window.screen.width < 750 ? null : 'left',
        render: (text, record, idx) => record.ta.photo_url ? <Popover content={(<img src={record.ta.photo_url} />)} title={this.print_full_student(record.ta)} trigger="hover"><div className="student-photo"><img src={record.ta.photo_url} /></div></Popover> : null,
      }, {
        title: 'Student',
        key: 'student',
        fixed: window.screen.width < 750 ? null : 'left',
        width: 160,
        render: (text, record, idx) => [this.print_full_student_reverse(record.ta) + " ", <a key="email" href={"mailto:" + record.ta.email}><Icon type="mail" theme="twoTone" /></a>, " ", record.ta.resume ? <a key="resume" onClick={() => this.openPDF(record.ta.resume, "resume-" + record.ta.firstname + "-" + record.ta.lastname + ".pdf")}><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" /></a> : null, " ", record.ta.available_date ? <Tooltip key="late" title={"Student is not available until " + moment(record.ta.available_date, "YYYY-MM-DD").format("MMMM Do YYYY")}><Icon type="warning" theme="twoTone" twoToneColor="orange" /></Tooltip> : null, " ", record.ta.available ? null : <Tooltip key="looking" title="Student is also looking for co-ops"><Icon type="warning" theme="twoTone" twoToneColor="red" /></Tooltip>, record.state_last_changed_at ? (moment(record.state_last_changed_at, "YYYY-MM-DD").format('M/DD') == moment(new Date(), "YYYY-MM-DD").format('M/DD')) ? <Tooltip key="edited" title={"Edited today at " + moment(record.state_last_changed_at, "YYYY-MM-DD HH:mm").format('HH:mm')}><Icon type="edit" /></Tooltip> : null : null],
        sorter: assign_table ? this.sort_order : null,
        defaultSortOrder: assign_table ? 'ascend' : null,
        download: (record) => this.print_full_student_reverse(record.ta),
      }, {
        title: 'NUID',
        key: 'nuid',
        width: 80,
        render: (text, record, idx) => record.ta.nuid,
        download: (record) => record.ta.nuid,

      }, {
        title: 'email',
        key: 'email',
        render: (text, record, idx) => null,
        download: (record) => record.ta.email,
      }, {
        title: 'Level',
        key: 'level',
        width: 100,
        filters: [{
          text: 'MS',
          value: 'MS',
        }, {
          text: 'UG',
          value: 'UG',
        }, {
          text: 'PhD',
          value: 'PH',
        }],
        render: (text, record, idx) => record.ta.level == "PH" ? (<Tooltip key="advisor" title={"Advised by " + this.print_full_instructor(record.ta.advisor)}>PhD</Tooltip>) : record.ta.level,
        sorter: (a, b) => a.ta.level.localeCompare(b.ta.level),
        onFilter: (value, record) => record.ta.level.indexOf(value) === 0,
        download: (record) => record.ta.level,
      }, {
        title: 'TA Campus',
        key: 'campus',
        width: 125,
        render: (text, record, idx) => campus && (campus != record.ta.campus) ? <span style={{ color: "red" }}>{this.print_campus(record.ta.campus)}</span> : this.print_campus(record.ta.campus),
        filters: campuses.map(item => ({
          text: this.print_campus(item),
          value: item
        })),
        onFilter: (value, record) => record.ta.campus == value,
        sorter: (a, b) => this.campus_sort(this.get_campus(a.campus), this.get_campus(b.campus)),
        download: (record) => this.print_campus(record.ta.campus),
      }, {
        title: 'Semester',
        key: 'semester',
        width: 115,
        filters: semesterFilter,
        render: (text, record, idx) => this.print_semester(record.semester),
        sorter: (a, b) => this.semester_sort(this.get_semester(a.semester), this.get_semester(b.semester)),
        onFilter: (value, record) => this.print_semester(record.semester).includes("Summer") && this.print_semester(record.semester).includes(value),
        download: (record) => this.print_semester(record.semester),
      }, {
        title: 'Submitted',
        key: 'submitted_at',
        width: 100,
        render: (text, record, idx) => moment(record.submitted_at, "YYYY-MM-DD").format('M/DD'),
        sorter: (a, b) => moment(a.submitted_at, "YYYY-MM-DD").format('M/DD') - moment(b.submitted_at, "YYYY-MM-DD").format('M/DD'),
        download: (record) => moment(record.submitted_at, "YYYY-MM-DD").format('M/DD'),
      }, {
        title: 'Rank',
        key: 'ranking',
        width: 90,
        filters: [{
          text: '1',
          value: 1,
        }, {
          text: '2',
          value: 2,
        }, {
          text: '3',
          value: 3,
        }],
        render: (text, record, idx) => this.print_ta_preference(record.preference),
        sorter: (a, b) => a.preference - b.preference,
        onFilter: (value, record) => record.preference == value,
        download: (record) => record.preference,
      }, {
        title: 'Course',
        key: 'course',
        width: 100,
        filters: [{
          text: 'CS',
          value: 'CS',
        }, {
          text: 'DS',
          value: 'DS',
        }, {
          text: 'CY',
          value: 'CY',
        }, {
          text: 'DA',
          value: 'DA',
        }, {
          text: 'IS',
          value: 'IS',
        }],
        render: (text, record, idx) => record.campus ? this.print_course(record.course) : this.print_group_name(record),
        onFilter: (value, record) => this.print_subject_from_course(record.course) == value,
        sorter: (a, b) => a.course - b.course,
        download: (record) => this.print_course(record.course),
      }, {
        title: 'GPA',
        key: 'gpa',
        width: 60,
        render: (text, record, idx) => record.ta.gpa ? <NumberFormat fixedDecimalScale={true} decimalScale={2} displayType={'text'} value={record.ta.gpa} /> : null,
        sorter: (a, b) => b.ta.gpa - a.ta.gpa,
        download: (record) => record.ta.gpa,
      }, {
        title: 'Taken',
        key: 'taken',
        width: 120,
        render: (text, record, idx) => this.print_semester(record.semester_taken),
        sorter: (a, b) => this.semester_sort(this.get_semester(a.semester_taken), this.get_semester(b.semester_taken)),
        download: (record) => this.print_semester(record.semester_taken),
      }, {
        title: 'Grade',
        key: 'grade',
        width: 80,
        render: (text, record, idx) => this.print_grade(record.grade),
        sorter: (a, b) => this.grade_sort(this.get_grade(a.grade), this.get_grade(b.grade)),
        download: (record) => this.print_grade(record.grade),
      }, {
        title: 'TA History',
        key: 'history',
        width: 200,
        render: (text, record, idx) => add_brs(record.history.map(taa => [this.link_course(taa.course), " (", this.print_semester(taa.semester), taa.state == "Rejected" ? "—Rejected" : "", ")"])),
        sorter: (a, b) => a.history.length - b.history.length,
        download: (record) => record.history.map(taa => this.print_course(taa.course) + " (" + this.print_semester(taa.semester) + (taa.state == "Rejected" ? "—Rejected" : "") + ")"),
      }, {
        title: 'TA Reviews',
        key: 'tareviews',
        width: 120,
        render: (text, record, idx) => this.print_reviews(record),
        download: (record) => this.download_rec(record.reviews),
      }, {
        title: 'Recommendations',
        key: 'recommendations',
        width: 125,
        render: (text, record, idx) => this.print_recommendations(record),
        download: (record) => this.download_rec(record.recommendations),
      }, {
        title: 'App Reviews',
        key: 'reviews',
        width: 180,
        filters: [{
          text: 'No Answer',
          value: 0,
        }, {
          text: 'No',
          value: 1,
        }, {
          text: 'Maybe',
          value: 3,
        }, {
          text: 'Probably',
          value: 4,
        }, {
          text: 'Definitely',
          value: 5,
        }],
        render: (text, record, idx) => tareviews && add_brs(tareviews.filter(el => el.taapplication == record.id && el.preference != 1).map(el => <Tooltip title={"Rating by " + this.print_full_instructor(el.instructor)}><span><Rate disabled key={el.id} count={4} defaultValue={el.preference} /></span></Tooltip>)),
        sorter: (a, b) => this.avg_review(a) - this.avg_review(b),
        onFilter: (value, record) => (this.avg_review(record) >= value) && (this.avg_review(record) < (value + 1)),
        download: (record) => tareviews && tareviews.filter(el => el.taapplication == record.id && el.preference != 1).map(el => el.preference),
      }, {
        title: 'Profile',
        key: 'profile',
        width: 160,
        render: (text, record, idx) => getTAProfile ? getTAProfile(record) : null,
      }, {
        title: 'Why be a TA?',
        key: 'why_become_ta_question',
        width: 0,
        hidden: true,
        download: (record) => record.ta.why_become_ta_question ? record.ta.why_become_ta_question.replace(/"/g, '""') : record.ta.why_become_ta_question,
      }, {
        title: 'What excites or challenges do you foresee?',
        key: 'excites_challenges_as_ta_question',
        width: 0,
        hidden: true,
        download: (record) => record.ta.excites_challenges_as_ta_question ? record.ta.excites_challenges_as_ta_question.replace(/"/g, '""') : record.ta.excites_challenges_as_ta_question,
      }, {
        title: 'Notes',
        key: 'notes',
        width: 0,
        hidden: true,
        download: (record) => record.notes ? record.notes.replace(/"/g, '""') : record.notes,
      }, {
        title: 'Status',
        key: 'status',
        width: 160,
        render: (text, record, idx) => getTAStatus(record.ta.id, allTaapplications.filter(el => this.semester_conflict(record.semester, el.semester))).map(el => <b>{[el.state, " ", el.campus ? this.print_course(el.course) : this.print_group_name(el), " (", el.hours].concat(el.campus && (el.campus != this.props.campus) ? [", ", <span style={{ color: "red" }}>{this.print_campus(el.campus)}</span>, ")", <br />] : [")", <br />])}</b>),
        sorter: (a, b) => getTAStatus(a.ta.id, allTaapplications.filter(el => this.semester_conflict(a.semester, el.semester))).map(el => el.state).length > getTAStatus(b.ta.id, allTaapplications.filter(el => this.semester_conflict(b.semester, el.semester))).map(el => el.state).length,
        download: (record) => getTAStatus(record.ta.id, allTaapplications.filter(el => this.semester_conflict(record.semester, el.semester))).map(el => el.state + " " + this.print_course(el.course)),
      }, {
        title: 'Job ID',
        key: 'jobId',
        width: 110,
        render: (text, record, idx) => getJobId(record),
        download: (record) => record.hire_id,
      }, {
        title: 'Pay Rate (per hr)',
        key: 'payRate',
        width: 125,
        render: (text, record, idx) => getPayRate(record),
        download: (record) => record.pay_rate,
      }, {
        title: 'Hours',
        key: 'hours',
        width: 80,
        render: (text, record, idx) => getHours(record),
        download: (record) => record.hours,
      }, {
        title: 'Action',
        key: 'action',
        width: actionWidth,
        render: (text, record, idx) => getAction(record),
      }, {
        title: '',
        fixed: 'right',
      }];

    const columns = allcolumns.filter(el => !el.hidden && (!hide_fields || !hide_fields.includes(el.key)) && (!selected && getTAProfile !== undefined ? !["history", "tareviews", "recommendations"].includes(el.key) : []));
    const downloadableColumns = allcolumns.filter(el => ["nuid"].includes(el.key) || (!hide_fields || !hide_fields.includes(el.key)) && !["profile", "action", "photo"].includes(el.key));

    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <span style={{ display: "flex", marginLeft: "auto" }}>
            {getTAProfile && (
              <div style={{ padding: "5px" }}>
                <Text strong>Show review columns &nbsp;</Text>
                <Switch checked={selected} onChange={e => this.setState({ selected: e })} />
              </div>
            )}
            {!loading && (<CSVLink filename="ta_applications_export.csv" data={this.getCsvData(taapplications, downloadableColumns)}><Button icon="download">Download</Button></CSVLink>)}
          </span>
        </div>
        <br />
        <Table {...this.props} dataSource={taapplications} columns={columns.filter(el => el.key != "email")} loading={loading} scroll={{ x: true }} bordered={false} pagination={false} size="small" rowKey="id" key={"table"} />
      </React.Fragment>);
  }
}

export default TAApplicationTable;
