import React, { Component } from "react";
import moment from "moment-timezone";
import AppComponent from './../AppComponent';
import Content from './../Content';
import { add_dividers, add_dividers_horiz, format_percent, format_decimal, is_assigned, getTAStatus, get_check_nocheck, renderAuditStatus, copy, getSemesterWeeks } from './../Utils';

import { Popover, Popconfirm, Button, PageHeader, Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, InputNumber, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, Tabs, message, Modal } from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;


const StudentCreateForm = Form.create({ name: "form_not_in_modal" })(
    class extends AppComponent {
  
    handleSubmit = () => {
      const form = this.props.form;
      form.validateFields((err, values) => {
        if (!err) {
          this.doPost("/api/student/", (data) => { form.resetFields(), this.props.completeHook(data); }, JSON.stringify(values) );
        } else {
          message.error(err);
        }
      });
    };
  
    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };
  
      return (
        <React.Fragment>
          <Form>
            <FormItem {...formItemLayout} label="Firstname" extra={"Please enter the firstname of the Student"}>
            {getFieldDecorator("firstname", { rules: [{ required: true , message: "Please enter a firstname" }], initialValue: "" })
                (
                <Input style={{ width: 360 }} placeholder="First Name"/>
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Lastname" extra={"Please enter the lastname of the Student"}>
            {getFieldDecorator("lastname", { rules: [{ required: true , message: "Please enter a lastname" }], initialValue: "" })
                (
                <Input style={{ width: 360 }} placeholder="Last Name" />
                )}
            </FormItem>
            <FormItem {...formItemLayout} className="login-left" label="NUID" extra={ "Please enter the Students NUID" }>
            {getFieldDecorator('nuid', { rules: [{ required: true }]})
                (
                <InputNumber style={{ width: 180 }} min={0} max={ 999999999 } step={1}  />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label=" " colon={ false }>
                <>
                    <Button onClick={ () => this.props.completeHook()}>Cancel</Button>
                    {" "}
                    <Button type="primary" onClick={ () => this.handleSubmit() }>Create</Button>
                </>
            </FormItem>
          </Form>
        </React.Fragment>
      );
    }
  });


  export default StudentCreateForm 