import React, { Component } from "react";
import moment from "moment-timezone";
import AppComponent from '../../AppComponent';
import Content from '../../Content';
import { manageStateItems, Spin} from "../../Utils";
import FileUpload  from "../../FileUpload";

import { Icon, Table, Button, Divider, Form, Alert, Upload } from 'antd';

const FormItem = Form.Item;

const SelfReviewForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      uploadedList: [],
    }

    handleFileUpdate = (fileData) => {
      if (fileData) {
          this.setState({ uploadedList: [fileData] });  // Add or update the file data
      } else {
          this.setState({ uploadedList: [] });  // Clear the file data
      }
    };

    reset = () => {
      this.setState({ uploadedList: [] });
      this.props.form.resetFields();
    }

    handleSubmit = () => {
      const uploadedList = this.state.uploadedList;
      const { updateState, year, self_merit, endpoint } = this.props;
      const form = this.props.form;
  
      form.validateFields((err, values) => {
        if (err) { return; }
  
        const data = {
          "year": year,
          "file": uploadedList[0]["fileData"], // If uploadedList in the format: "uploadedList" = [{"fileData": file, "type": type}] 
          "faculty": this.props.user.instructor,
        };

        const specificMerit = self_merit[0]
        const action = specificMerit ? "update" : "add";
  
        this.doAction(specificMerit ? endpoint + specificMerit.id.toString() + "/" : endpoint, data, action, (updatedData) => {
          updateState(updatedData, action);
          this.reset();
        });
      });
    };


    render() {
      const { form, self_merit, disabled } = this.props;

      return (
        <>
          <div style={{ marginTop: "15px" }}>
            <Form>
              <p>Please use the below space to upload your retrospective memorandum, you cannot proceed without upload.</p>
              <strong>Note: You can only upload one document per field. It must be a PDF and no larger than 10 MB.</strong>
              <FileUpload
                    label="Retrospective Memorandum"
                    type="Retrospective Memorandum"
                    description="Please submit your retrospective memorandum as a PDF. Max 10MB."
                    onFileUpdate={this.handleFileUpdate}
                    uploadedList={this.state.uploadedList}
                    form={form}
                />
              <FormItem {...this.formItemLayout} label=" " colon={false}>
                <Button type="primary" disabled={disabled} onClick={() => this.handleSubmit()}>{self_merit?.[0] ? "Resubmit" : "Submit"}</Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);

class SelfReview extends AppComponent {
  state = {
    endpoint: "/api/merit/teaching/selfreview/",
    self_merit: [],
    loading_merit: true,

  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester != this.props.semester) {
      this.getData();
    }
  }

  getData = () => {
    this.getMerit();
  }

  getMeritYear = () => this.calendar_year(this.props.semesters[0]);

  getMerit = () => {
    this.doGet(
      this.state.endpoint + "?faculty=" + this.props.user.instructor + "&year=" + this.getMeritYear(),
      data => {
        this.setState({ self_merit: data, loading_merit: false }, () => {
        });
      }
    );
  };
  
  updateState = (updatedData, action) => {
    this.setState(
      (prevState) => {
        const updatedSelfMerit = manageStateItems(prevState, 'self_merit', action, updatedData);
        return { ...updatedSelfMerit};
      }
    );
  }

  checkMeritFormOpen = semester_ids => {
    return semester_ids.map(sem => this.get_semester(sem).teaching_merit_selfreview).some(Boolean)
  }

  render() {
    const { self_merit, loading_merit, endpoint } = this.state;
    const formVisible = this.checkMeritFormOpen(this.props.semesters)

    const disabled = this.getMeritYear() + '.12' > moment().format('YYYY.MM') || this.getMeritYear() < moment().format('YYYY')-1;
    const year = this.getMeritYear()
   
    // if the form is not open and the current year is greater than the merit year, an alert that the form is archival will be displayed
    // if the form is not open and the current year is the same or greater, an alert that the year is not finished yet so the form isn't open will be displayed.
    const alert = this.getMeritYear() < moment().format('YYYY') && !formVisible ? 
      (<Alert message="Year now archived" description={"The " + this.getMeritYear() + " year is now archived; you may view but not edit your merit report."} type="warning" showIcon />)
      : (!formVisible ) ? 
      (<Alert message="Retrospective Memorandum Period is not yet available and / or is closed." description={"The " + this.getMeritYear() + " year has not finished yet, so you cannot submit your merit report just yet."} type="warning" showIcon />) 
      : null;

    const columns = [
      {
        title: "Submitted",
        key: "submitted_at",
        width: 100,
        render: (text, record, idx) => moment(record.submitted_at).format("MMM DD, YYYY HH:mm")
      },
      {
        title: "File Upload",
        key: "file",
        width: 100,
        render: (text, record, idx) => <a onClick={() => this.openPDF(record.file, "retrospective " + record.year + ".pdf")}><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{"retrospective " + record.year + ".pdf"}</a>
      },
    ];

    return (
      <Content {...this.props} title={"Teaching Faculty Merit Retrospective Memorandum for " + this.getMeritYear(this.props.semesters)} breadcrumbs={[{ link: "/faculty", text: "Faculty" }, { text: "Teaching Merit" }, { text: "Retrospective Memorandum" }]}>
        <p>The Khoury College Merit process is an annual process that is used as input to the annual evaluation performed each year by the Dean.  The process was proposed, voted on, and approved by the faculty; it is a part of the College's Bylaws.</p>
        <React.Fragment>
          <Divider orientation="left">
            Submitted Retrospective Memorandum
          </Divider>
          {loading_merit ? <Spin tip="Loading merit data" /> 
            : 
            <Table
              dataSource={self_merit}
              columns={columns}
              loading={loading_merit}
              rowKey={(record) => record.index}
              pagination={false}
            />
          }
          <React.Fragment>
            <Divider orientation="left">Upload Retrospective Memorandum</Divider>
         
            <p> </p>
            {this.checkMeritFormOpen(this.props.semesters) ?  
              <SelfReviewForm 
                {...this.props} 
                updateState={ this.updateState } 
                self_merit={ self_merit }
                year={year}
                disabled={ disabled }
                endpoint={ endpoint }
              />
            :  alert 
            }
          </React.Fragment>

        </React.Fragment>

      </Content>
    );
  }
}


export { SelfReview };
