import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './../AppComponent';
import StatusComponent from './../StatusComponent';
import { Status } from './../StatusComponent';
import Preference from './../Preference';
import Content from './../Content';
import CustomTabs from './../CustomTabs';
import PhDStudentFundingTable from './PhDStudentFundingTable';
import { add_brs, add_dividers, add_dividers_horiz, format_percent, format_decimal, is_assigned, getTAStatus, copy, getSemesterWeeks, oxford, format_nuid, get_check_nocheck, text_max, pin } from './../Utils';

import { Popconfirm, Button, PageHeader, Timeline ,Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, InputNumber, Icon, Menu, Dropdown, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, Tabs, Modal, Spin, message } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

const ratings = ["Satisfactory", "Marginal", "Unsatisfactory"];
const statuses = ["Active", "On Internship", "On Leave", "Transition to MS"];

class PhDFacultyCard extends AppComponent {
  state = {
      endpoint_students: "/api/phd/",
      students: [],
      loading_students: true,
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    if (this.props.user.instructor) {
      this.doGet(this.state.endpoint_students + "?advisors__advisors=" + this.props.user.instructor, data => this.setState({ students: data, loading_students: false }));
    }
  }

  render() {
    const { loading_students, students } = this.state;
    
    const sem = this.get_semester(this.props.semesters.length == 1 ? this.props.semesters[0] : this.props.semesters[1]);
    
    const mystudents = students.filter(s => s.advisors.find(ah => (ah.left == null || this.get_semester(ah.left) >= sem.code) && ah.advisors.find(a => a == this.props.user.instructor) && (this.get_semester(ah.start).code <= sem.code) && (ah.end == null || (this.get_semester(ah.end).code >= sem.code))));

    return this.props.user.instructor ? ( <Card size="small" title={ "Your PhD students in " + this.props.semester }>{ loading_students ? ( <Spin tip="Loading students" /> ) : mystudents.length ? add_brs(mystudents.map(el => ( <span key={ el.id }><Link key={ el.id } to={ this.getLink("/employee/phd/" + el.id) }>{ this.print_full_student(el) }</Link> ({ this.print_semester(el.matriculated) }-{ (el.left ? this.print_semester(el.left) : "Present") })</span> ) )) : <i>No PhD students found</i> }</Card> ) : null;
  }
}

const PhDAdvisorReviewForm = Form.create({ name: 'form_not_in_modal' })(
  class extends AppComponent {
    state = {
      changed: false,
    }
    
    render() {
      const { student, review, handleCreateUpdate, form, disabled } = this.props;
      const { getFieldDecorator } = form;
      const { changed } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      
      return (
        <Form>
          <FormItem {...formItemLayout} label="Academic Progress" extra="Please comment on the progress of the student since the last academic review in terms of research as well as towards a thesis.">
            {getFieldDecorator('notes', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review ? review.notes : null,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={ 4 } disabled={ disabled }/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Advising Relationship" extra="Please comment on the advising relationship you've developed with the student. Do you meet regularly? How often do you meet one-on-one?">
            {getFieldDecorator('advising_relationship', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review ? review.advising_relationship : null,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={ 2 } disabled={ disabled }/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Proposed Rating" extra="Please indicate your suggested rating for the student.">
            {getFieldDecorator('proposed_rating', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review ? review.proposed_rating : null,
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 220 }} disabled={ disabled } >
              { ratings.map(r => ( <Option key={ r } value={ r }>{ r }</Option> )) }
              </Select>)}
          </FormItem>
          <FormItem {...formItemLayout} label="Status" extra="Please indicate the status for the student for the upcoming semester.">
            {getFieldDecorator('status', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review ? review.status : null,
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 220 }} disabled={ disabled } >
              { statuses.map(r => ( <Option key={ r } value={ r }>{ r }</Option> )) }
              </Select>)}
          </FormItem>
          <FormItem {...formItemLayout} label="Proposed Comment" extra="Please provide a proposed comment for the Academic Review letter sent to the student. Please provide thoughtful, specific, and concise feedback that addresses the student's progress, accomplishments, areas of growth, and trajectory into the next semester. For students receiving a Marginal or Unsatisfactory, the comment should explain the rating and give specific advice on what the student should do to improve.">
            {getFieldDecorator('proposed_statement', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review ? review.proposed_statement : null,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={ 4 } disabled={ disabled }/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label=" " colon={ false }>
            <Button type="primary" onClick={ () => handleCreateUpdate(student, form, () => this.setState({ changed: false })) } disabled={ disabled || !changed }>{ review == null ? "Submit" : "Update" }</Button>
          </FormItem>
        </Form>
      );
    }
  }
);

const PhDTAReviewForm = Form.create({ name: 'form_not_in_modal' })(
  class extends AppComponent {
    state = {
      changed: false,
    }

    render() {
      const { student, photo_url, review, course, campus, handleCreateUpdate, form, disabled } = this.props;
      const { getFieldDecorator } = form;
      const { changed } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Form>
          <FormItem {...formItemLayout} label=" " colon={ false }>
            <div className="student-photo"><img src={ photo_url }/></div>
          </FormItem>
          <FormItem {...formItemLayout} label="Rating" extra="Please indicate your overall rating for the student as a TA.">
            {getFieldDecorator('rating', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review ? review.rating : null,
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 220 }} disabled={ disabled } >
              { ratings.map(r => ( <Option key={ r } value={ r }>{ r }</Option> )) }
              </Select>)}
          </FormItem>
          <FormItem {...formItemLayout} label="Comments" extra="Please comment on the student's performance as a TA.">
            {getFieldDecorator('notes', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review ? review.notes : null,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={ 4 } disabled={ disabled }/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label=" " colon={ false }>
            <Button type="primary" onClick={ () => handleCreateUpdate(student, course, campus, form, () => this.setState({ changed: false })) } disabled={ disabled || !changed }>{ review == null ? "Submit" : "Update" }</Button>
          </FormItem>
        </Form> 
      );
    }
  }
);

const PhDCourseReviewForm = Form.create({ name: 'form_not_in_modal' })(
  class extends AppComponent {
    state = {
      changed: false,
    }
    
    render() {
      const { student, photo_url, review, course, handleCreateUpdate, form, disabled } = this.props;
      const { getFieldDecorator } = form;
      const { changed } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      
      return (
        <Form>
          <FormItem {...formItemLayout} label=" " colon={ false }>
            <div className="student-photo"><img src={ photo_url }/></div>
          </FormItem>
          <FormItem {...formItemLayout} label="Grade" extra="Please select the student's current estimated grade.">
            {getFieldDecorator('expected_grade', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review ? review.expected_grade : null,
              onChange: () => this.setState({ changed: true }),
            })(<Select style={{ width: 220 }} disabled={ disabled } >
              { this.grade_list().map(g => ( <Option key={ g.id } value={ g.id }>{ g.grade }</Option> )) }
              </Select>)}
          </FormItem>
          <FormItem {...formItemLayout} label="Comments" extra="Please comment on the student's performance in your course.">
            {getFieldDecorator('notes', {
              rules: [{ required: true, message: 'Please answer the question.' }],
              initialValue: review ? review.notes : null,
              onChange: () => this.setState({ changed: true }),
            })(<TextArea rows={ 4 } disabled={ disabled }/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label=" " colon={ false }>
            <Button type="primary" onClick={ () => handleCreateUpdate(student, course, form, () => this.setState({ changed: false })) } disabled={ disabled || !changed }>{ review == null ? "Submit" : "Update" }</Button>
          </FormItem>
        </Form> 
      );
    }
  }
);

class PhDAdvisorReview extends AppComponent {
  state = {
      endpoint: "/api/phd/advisorreview/",
      reviews: null,
      loading_reviews: true,

      endpoint_students: "/api/phd/",
      students: [],
      loading_students: true,

      endpoint_taapplication: "/api/ta/application/",
      taapplications: [],
      loading_taapplications: true,

      endpoint_schedule: "/api/schedule/",
      sections: [],
      loading_schedule: true,

      endpoint_tareview: "/api/phd/tareview/",
      tareviews: null,
      loading_tareviews: true,

      endpoint_section: "/api/schedule/section/",
      section_data: [],
      loading_section_data: true,

      endpoint_coursereview: "/api/phd/coursereview/",
      coursereviews: null,
      loading_coursereviews: true,

  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.setState({ section_data: [] }, this.getData);
    }
  }
  
  getCanonicalSemester = () => {
    return this.props.semesters.length > 1 ? this.props.semesters[1] : this.props.semesters[0];
  }

  getData = () => {
    this.doGet(this.state.endpoint_students + "?advisors__advisors=" + this.props.user.instructor, data => this.setState({ students: data, loading_students: false }));
    this.doGet(this.state.endpoint_taapplication + "?semester=" + this.props.semesters.join(",") + "&state=Hired&ta__level=PH",
                    data => this.setState({ taapplications: data, loading_taapplications: false }));
    if (this.props.user.instructor) {
      this.doGet(this.state.endpoint_schedule + "?semester=" + this.props.semesters.join(",") + "&instructors=" + this.props.user.person.id,
                      data => this.setState({ sections: data , loading_schedule: false }, () => this.getSections(data.slice(0))));
    } else {
      this.setState({ sections: [], loading_schedule: false, loading_section_data: false });
    }
    this.getAdvisorReviews();
    this.getTAReviews();
    this.getCourseReviews();
  }
  
  getSections = (sections) => {
    const { section_data } = this.state;
        
    if (sections.length == 0) {
      this.setState({ loading_section_data: false });
    } else {
      const section = sections.shift();
      this.doGet(this.state.endpoint_section + section.id + "/", data => this.setState({ section_data: section_data.concat(data)}, () => this.getSections(sections)));
    }
  }
  
  getAdvisorReviews = () => {
    this.doGet(this.state.endpoint + "?semester=" + this.getCanonicalSemester(), data => this.setState({ reviews: data, loading_reviews: false }));    
  }
  
  getTAReviews = () => {
    this.doGet(this.state.endpoint_tareview + "?semester=" + this.getCanonicalSemester(), data => this.setState({ tareviews: data, loading_tareviews: false }));    
  }

  getCourseReviews = () => {
    this.doGet(this.state.endpoint_coursereview + "?semester=" + this.getCanonicalSemester(), data => this.setState({ coursereviews: data, loading_coursereviews: false }));
  }
  
  handleCreateUpdate = (student, form, callback) => {
    const { semesters, semester } = this.props;
    const { reviews } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }
      
      values["advisor"] = this.props.user.instructor;
      values["student"] = student;
      values["semester"] = this.getCanonicalSemester();
      
      const review = reviews.find(r => (r.student == student) && (r.advisor == this.props.user.instructor));
            
      if (review) {
        this.doPatch(this.state.endpoint + review.id + "/", (data) => { if (data) { message.success("Updated review."); this.getAdvisorReviews(); callback(); } }, JSON.stringify(values));
      } else {
        this.doPost(this.state.endpoint, (data) => { if (data) { message.success("Submitted review."); this.getAdvisorReviews(); callback(); } }, JSON.stringify(values));
      }
    });
  }

  handleCreateUpdateTA = (student, course, campus, form, callback) => {
    const { semesters, semester } = this.props;
    const { tareviews } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }
      
      values["instructor"] = this.props.user.instructor;
      values["student"] = student;
      values["course"] = course;
      values["campus"] = campus;
      values["semester"] = this.getCanonicalSemester();
      
      const review = tareviews.find(tar => tar.course == course && tar.semester == this.getCanonicalSemester() && tar.student_id == student);
            
      if (review) {
        this.doPatch(this.state.endpoint_tareview + review.id + "/", (data) => { if (data) { message.success("Updated review."); this.getTAReviews(); callback(); } }, JSON.stringify(values));
      } else {
        this.doPost(this.state.endpoint_tareview, (data) => { if (data) { message.success("Submitted review."); this.getTAReviews(); callback(); } }, JSON.stringify(values));
      }
    });
  }

  handleCreateUpdateCourse = (student, course, form, callback) => {
    const { semesters, semester } = this.props;
    const { coursereviews } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }
      
      values["instructor"] = this.props.user.instructor;
      values["student"] = student;
      values["course"] = course;
      values["semester"] = this.getCanonicalSemester();
      
      const review = coursereviews.find(cr => cr.course == course && cr.semester == this.getCanonicalSemester() && cr.student == student);
            
      if (review) {
        this.doPatch(this.state.endpoint_coursereview + review.id + "/", (data) => { if (data) { message.success("Updated review."); this.getCourseReviews(); callback(); } }, JSON.stringify(values));
      } else {
        this.doPost(this.state.endpoint_coursereview, (data) => { if (data) { message.success("Submitted review."); this.getCourseReviews(); callback(); } }, JSON.stringify(values));
      }
    });
  }

  render() {
    const { semester, semesters, form } = this.props;
    const { loading_reviews, reviews, students, loading_students, loading_taapplications, taapplications, loading_schedule, sections, loading_tareviews, tareviews, section_data, loading_section_data, coursereviews, loading_coursereviews } = this.state;

    const alert = semester.startsWith("Summer") ? ( <Alert message="No academic reivew" description={ "We do not have academic reviews during summer semesters." } type="warning" showIcon /> ) : moment(this.get_semester(semesters[0]).startdate, "YYYY-MM-DD") > moment() ? ( <Alert message="Semester not yet started" description={ "The " + semester + " semester has not started yet, so you cannot submit  reviews." } type="warning" showIcon /> ) : moment(this.get_semester(this.getCanonicalSemester()).enddate, "YYYY-MM-DD") < moment() ? ( <Alert message="Semester already over" description={ "The " + semester + " semester has already finished, so you cannot submit reviews." } type="warning" showIcon /> ) : null;

    const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    const sem = this.get_semester(this.getCanonicalSemester());
    const mystudents = students.filter(s => (s.left == null || this.get_semester(s.left) >= sem.code) && s.advisors.find(ah => ah.advisors.find(a => a == this.props.user.instructor) && (this.get_semester(ah.start).code <= sem.code) && (ah.end == null || (this.get_semester(ah.end).code >= sem.code))));

    const myapps = taapplications.filter(taa => sections.find(s => s.course == taa.course && semesters.includes(s.semester) && s.campus == taa.campus));
    const course_list = this.course_campus_semester_list_from_sections(sections).filter(el => this.get_course(el.course).loadcount > 0);
    
    const section_students = section_data.map(s => { s.students = s.students.filter(st => st.phdstudent != null); return s; });
        
    return (
      <Content {...this.props} title={ "Faculty reviews for " + semester } breadcrumbs={ [{ link: "/employee", text: "Employee" }, { text: "PhD" }, { text: "Academic Review" }] }>
        <p>Below are the forms to fill out for your advisees, your PhD student TAs (if any exist), and any PhD students in your courses for the { semester } semester.  If you have any questions or concerns (e.g., if certain students are no longer your advisees or other advisees are missing), you should email <a href="mailto:khoury-phdprograms@northeastern.edu">Khoury PhD Programs</a>.  If any PhD student TAs worked for sections other than yours, simply leave those forms blank. </p>  
        
        { alert }

        { semester.startsWith("Summer") ? null : loading_students || loading_reviews || loading_taapplications || loading_schedule || loading_tareviews || loading_coursereviews || loading_section_data ?  <Spin tip="Loading data" /> : (
          <React.Fragment>
            <p>&nbsp;</p>
            <CustomTabs {...this.props} default_Active_Key={ mystudents?.length > 0 ? mystudents[0].id : null }>
              { mystudents.map(s => {
                  const review = reviews.find(ar => ar.advisor == this.props.user.instructor && ar.student == s.id);
                
                  return (
                    <TabPane tab={ [this.print_full_student(s), " ", get_check_nocheck(review != null) ] } key={ s.id }>
                      <p>Below is the academic review form for { this.print_full_student(s) } for the { semester } semester.</p>
                      <p>Please remember to have a one-on-one meeting with the student, ideally before Academic Review, to look back and look forward at the student's PhD progress and plans. At the meeting, advisors should discuss the advisee's progress and provide feedback, but also convey that the Academic Review rating is determined collectively by the committee. Students have been advised that advisors will use this meeting to discuss the student's strengths and weaknesses, suggest specific strategies to improve skills (writing, research, presentation, time management, etc.), make research plans for the next 6 months, discuss the student's future career goals (academia, industry, etc.) and offer advice on what research/courses/internships/networking the student should engage in to better prepare themselves to achieve those goals. Students have been asked to use this opportunity to ask for specific advice on the aforementioned aspects, communicate what their future career goals are, and communicate what additional forms of support they think they might need from their advisor.  First-year students have also been advised to use this opportunity to ask  "how things work" (e.g., Academic Review, courses, conferences, networking, interactions with your research group, attending seminars and hiring talks, work-life balance, etc.) --- i.e., advisors should clarify the unwritten expectations in academia for junior students.</p>
                      <PhDAdvisorReviewForm {...this.props} student={ s.id } review={ review } handleCreateUpdate={ this.handleCreateUpdate } disabled={ alert != null} />
                    </TabPane>
                  );
                }) }
                
                { course_list.map(c => {
                  const theseapps = myapps.filter(el => el.course == c.course && el.campus == c.campus && el.semester == c.semester);
                  const app_cards = theseapps.map(taa => { return { title: this.print_full_student(taa.ta), content: <PhDTAReviewForm {...this.props} campus={ c.campus } course={ c.course } student={ taa.ta.student } photo_url={ taa.ta.photo_url } review={ tareviews.find(tar => (tar.course == c.course) && (tar.semester == c.semester) && (tar.student_id == taa.ta.student)) } handleCreateUpdate={ this.handleCreateUpdateTA } disabled={ alert != null} /> }; })
                  
                  return (
                    <TabPane tab={ [this.print_course(c.course) + " TAs ", get_check_nocheck(theseapps.length == tareviews.filter(tar => (tar.course == c.course) && (tar.semester == c.semester)).length)] } key={ "ta-" + c.course + "-" + c.campus + "-" + c.semester } disabled={ theseapps.length == 0 }>
                      <p>Please also fill out the review form(s) for PhD students who TAed for your { this.print_full_course(c.course) } section during the { semester } semester.</p>
                      <List grid={ this.grid } dataSource={ app_cards } renderItem={item => ( <List.Item><Card size="small" title={ item.title }>{ item.content }</Card></List.Item> )} />
                    </TabPane>
                  );
                } ) }
                
                { section_students.map(s => {
                  const student_cards = s.students.map(st => { return { title: this.print_full_student(st), content: ( <PhDCourseReviewForm {...this.props} course={ s.course } student={ st.phdstudent } photo_url={ st.photo_url } review={ coursereviews.find(cr => (cr.course == s.course) && (cr.semester == s.semester) && (cr.student == st.phdstudent)) } handleCreateUpdate={ this.handleCreateUpdateCourse } disabled={ alert != null} /> ) }; });
                  
                  return (
                    <TabPane tab={ [this.print_course(s.course) + " (" + s.crn + ") students ", get_check_nocheck(s.students.length == coursereviews.filter(cr => (cr.course == s.course) && (cr.semester == s.semester)).length)] } key={ "stud-" + s.course + "-" + s.campus + "-" + s.crn } disabled={ s.students.length == 0 }>
                      <p>Please also fill out the review form(s) for PhD students who are enrolled in your { this.print_full_course(s.course) } section during the { semester } semester.</p>
                      <List grid={ this.grid } dataSource={ student_cards } renderItem={item => ( <List.Item><Card size="small" title={ item.title }>{ item.content }</Card></List.Item> )} />
                    </TabPane>
                  ); 
                }) }
              </CustomTabs>
            </React.Fragment>
          ) }                  
      </Content>
    );
  }
}


export { PhDFacultyCard, PhDAdvisorReview };
