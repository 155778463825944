import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import moment from "moment-timezone";
import { renderStatus, text_max, oxford, add_brs } from "../../Utils";
import { WorkflowSubmit } from "../WorkflowSubmit";
import { WorkflowTable } from "../WorkflowTable";

import { Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Radio, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const MONTHS = ["May", "June", "July", "August"];

const SummerSupportProposalForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      funds: {},
      fundings: {},
      errors: {},
      error: null,
      notes: null,
      admin_stipend: false,
    }

    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      const funds = {};
      const fundings = {};
      MONTHS.forEach(m => { funds[m] = [{ fund: null, percentage: 100 }]; fundings[m] = "None"; });
      this.setState({ notes: "", funds: funds, errors: {}, others: {}, fundings: fundings, error: null });
      this.props.form.resetFields();
    }

    validateMonth = (month) => {
      const { funds } = this.state;

      if (!funds[month]) {
        return null;
      }

      if (funds[month].reduce((r, a) => r + a.percentage, 0) > 100) {
        return "The percentages must sum no more than 100%.";
      }

      return null;
    }

    validateForm = () => {
      const { funds, fundings } = this.state;
      const errors = {};

      var total = 0;
      MONTHS.forEach(m => {
        const result = this.validateMonth(m);
        if (result) { errors[m] = result; }

        if (fundings[m] == "Grants") {
          total += funds[m].map(f => f.percentage).reduce((r, a) => r + a, 0);
        }
      });

      if (total > 320) {
        this.setState({ error: "You can only request up to 3.2 total months." });
      } else {
        this.setState({ error: null });
      }

      this.setState({ errors: errors });

      return (Object.keys(errors).length == 0) && (total <= 320);
    }

    handleSubmit = () => {
      const { semesters, user } = this.props;
      const { others, fundings, funds, notes, admin_stipend } = this.state;

      if (this.validateForm()) {
        const data = { months: [], notes: notes, year: this.get_semester(semesters[0]).year, user: user.id, steps: [], instructor: user.instructor, admin_stipend: admin_stipend };
        MONTHS.forEach(m => {
          if (fundings[m] == "Other") {
            data.months.push({ month: m, other: others[m], details: [] });
          } else if (fundings[m] == "Grants") {
            data.months.push({ month: m, other: null, details: funds[m].map(f => { return { index: f.fund, fraction: f.percentage / 100, work_description: f.work_description } }) });
          }
        });

        this.props.onSubmit(data, this.reset);
      }
    }

    render() {
      const { visible, onCancel, onCreate, form, item, sections, campus, semester, month, user } = this.props;
      const { getFieldDecorator } = form;
      const { error, errors, fundings, funds, others } = this.state;

      const year = this.get_semester(this.props.semesters.length == 1 ? this.props.semesters[0] : this.props.semesters[1]).year;
      const fundlist = this.fund_list().filter(f => f.active && f.owners.find(fo => fo.owner == this.props.user.employee));

      return (
        <>
          <p>Please use the form below to submit your proposal for how you wish your summer support to be funded during the {year} summer. If you have any questions or concerns, please email <a href="mailto:b.morris@northeastern.edu">Barbara Morris</a>.  If an index that you expect to see does not show up, please contact <a href="mailto:khoury-grants@northeastern.edu">Khoury Grants Administration</a>.</p>
          <p>If you wish to amend a previously-submitted proposal, please simply submit a new proposal.  The Khoury Grants office will simply use the most recent one that you submit.</p>
          {error ? (<div><Alert message={error} type="error" showIcon /><p /></div>) : ""}
          <Form>
            <FormItem {...this.formItemLayout} label="Overall notes" extra="You can optionally include any general notes here, but please use the fields below to enter how you wish your summer support to be funded.">
              {getFieldDecorator('notes', {
                initialValue: "",
                onChange: (event) => { this.setState({ notes: event.target.value }) },
              })(<TextArea rows={4} />
              )}
            </FormItem>
            <FormItem {...this.formItemLayout} label="Administrative stipend?" extra={"Please enter whether or not you are receiving an administrative stipend."}>
              {getFieldDecorator('admin_stipend', {
                rules: [{ required: true, message: 'Please select an option.' }],
                initialValue: false,
                onChange: event => this.setState({ admin_stipend: event.target.value }),
              })(<RadioGroup>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </RadioGroup>
              )}
            </FormItem>
            {MONTHS.map(month => (
              <>
                <Divider orientation="center" dashed={true}>{month} support</Divider>
                <FormItem {...this.formItemLayout} label="Source">
                  {getFieldDecorator('funding' + month, {
                    initialValue: fundings[month],
                    rules: [{ required: true, message: 'Please select how this month will be funded.' }],
                    onChange: (event) => { fundings[month] = event; funds[month] = [{ index: null, percentage: 100 }]; this.setState({ fundings: fundings, funds: funds }) },
                  })(<Select showSearch style={{ width: 220 }} filterOption={this.filter}>
                    <Option value="None">No summer support</Option>
                    <Option value="Grants">Grants</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                  )}
                </FormItem>

                {fundings[month] == "Grants" ? (
                  <React.Fragment key={month}>
                    <FormItem {...this.formItemLayout} label=" " colon={false}>Please enter how you wish your summer salary for {this.props.month} to be funded.  If you wish to fund this month from multiple indices, select Add Fund to add more indices.</FormItem>
                    {errors[month] ? (<div><Alert message={errors[month]} type="error" showIcon /></div>) : ""}
                    {funds[month] ? funds[month].map((e, idx) =>
                      <React.Fragment key={"frag" + month + idx} >
                        <FormItem {...this.formItemLayout} label={"Fund " + (funds[month].length > 1 ? (idx + 1) : "")} extra="Please select the fund you wish your summer support to come from.">
                          {getFieldDecorator("fund" + month + idx, {
                            rules: [{ required: true, message: 'Please select a fund.' }],
                            initialValue: e ? e.fund : null,
                            onChange: e => { funds[month][idx].fund = e; this.setState({ funds: funds }); this.validateForm(); },
                          })(<Select showSearch style={{ width: 540 }} filterOption={this.filter}>
                            {fundlist.map(f => <Option key={f.id} value={f.id} disabled={funds[month].find((fund, i) => i != idx && f.id == fund.fund)}>{[text_max(this.print_full_fund(f.id), 40), " ("].concat(oxford(f.owners.map(o => this.print_instructor(o.instructor)))).concat([")"])}</Option>)}
                          </Select>)}
                        </FormItem>
                        <FormItem {...this.formItemLayout} label={"Percentage " + (funds[month].length > 1 ? (idx + 1) : "")} extra="Enter the fraction of one summer month you wish to bill against this index (the total must sum to no more than 100%, but can sum to less if you wish to take less than a full month).">
                          {getFieldDecorator("percentage" + month + idx, {
                            rules: [{ required: true, message: 'Please enter the percentage of funding from this fund.' }],
                            initialValue: e ? e.percentage : 100,
                            onChange: e => { funds[month][idx].percentage = e; this.setState({ funds: funds }); this.validateForm(); },
                          })(<InputNumber min={1} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />)}
                        </FormItem>
                        <FormItem {...this.formItemLayout} label="Work Description" extra="Please provide 1-2 sentences outlining the work to be performed.">
                          {getFieldDecorator('work_description' + month + idx, {
                            rules: [{ required: true, message: 'Please enter more details about how this month will be funded.' }],
                            onChange: (event) => { funds[month][idx].work_description = event.target.value; this.setState({ funds: funds }); this.validateForm(); },
                          })(<TextArea rows={2} />
                          )}
                        </FormItem>
                      </React.Fragment>
                    ) : null}
                    <FormItem {...this.formItemLayout} label=" " colon={false}><Button icon="plus" onClick={() => { funds[month] = funds[month].concat([{ fund: null, percentage: 1 }]); this.setState({ funds: funds }, this.validateForm); }}>Add Fund</Button></FormItem>
                  </React.Fragment>
                ) : fundings[month] == "Other" ? (
                  <FormItem {...this.formItemLayout} label="Details" extra="Please enter details about how your support will be funded.  Please be sure to include both the source of the funds and the amount you expect to bill.  You should only use this entry box if the indices you wish to bill against have not yet been created or do not show in the list.">
                    {getFieldDecorator('other' + month, {
                      rules: [{ required: true, message: 'Please enter more details about how this month will be funded.' }],
                      onChange: (event) => { others[month] = event.target.value; this.setState({ others: others }) },
                    })(<TextArea rows={4} />
                    )}
                  </FormItem>
                ) : null}
              </>
            ))}
            <FormItem {...this.formItemLayout} label=" " colon={false}><Button type="primary" onClick={this.handleSubmit}>Submit</Button></FormItem>
          </Form>
        </>
      );
    }
  }
);

class SummerSupportProposalSearchForm extends AppComponent {
  render() {
    const { form } = this.props;
    const year = this.year_list();

    return (
      <>
        <FormItem {...this.formItemLayout} label="Year" extra="Optionally select the year of the summer support proposal.">
          {form.getFieldDecorator("summersupportproposal__year", {
            onChange: this.props.onSearchChange,
          })(<Select style={{ width: 150 }} allowClear>
            {year.map(y => <Option key={y} value={y}>{y}</Option>)}
          </Select>)}
        </FormItem>
      </>
    )
  }
}

class SummerSupportProposal extends AppComponent {
  render() {
    const { record } = this.props;
    const item = record.record;

    return (
      <Descriptions bordered title={"Summer support proposal for " + item.year}>
        <Descriptions.Item label="Requestor">{this.link_full_instructor(item.instructor)}</Descriptions.Item>
        <Descriptions.Item label="Submitted">{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Year">{item.year}</Descriptions.Item>
        <Descriptions.Item label="Stipend?">{item.admin_stipend ? "Yes" : "No"}</Descriptions.Item>
        <Descriptions.Item label="Notes" span={2}>{item.notes ? item.notes : <i>No general notes provided</i>}</Descriptions.Item>

        {MONTHS.map(m => {
          const month = item.months.find(mon => mon.month == m);
          return (
            <Descriptions.Item key={m} label={m} span={3}>
              {month ? month.other ? <span><b>Other</b>: {month.other}</span> :
                add_brs(month.details.map((fd, idx) => <span key={idx}>{[<b>{[(fd.fraction * 100), "%"]}</b>, " from ", this.print_full_fund(fd.index), " (", oxford(this.get_fund(fd.index).owners.map(o => this.print_instructor(o.instructor))), ")", <br />, <i>Work description: </i>,  fd.work_description]}</span>)) :
                <i>No summer support requested</i>}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    );
  }
}

class SummerSupportProposalTable extends WorkflowTable {
  get_columns = () => {
    return MONTHS.map(m => {
      return {
        title: m,
        key: m,
        width: 150,
        align: 'center',
        render: (text, record, idx) => {
          const data = record.record.months.find(rm => rm.month == m);
          if (data) {
            if (data.other) {
              return "Other";
            } else {
              return data.details.map(fd => ((fd.fraction * 100) + "% from " + this.print_fund(fd.index))).join(", ");
            }
          } else {
            return "—";
          }
        }
      };
    });
  }
}

class SummerSupportProposalOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/faculty/summersupport/",
    visible: true,
  }

  getYear = () => {
    const { semesters } = this.props;
    return this.get_semester(semesters[0]).year;
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint + "?year=" + this.getYear(), data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "Summer Support Proposal for " + this.getYear();
  }

  get_workflowtype = () => {
    return "summersupportproposal";
  }

  get_name_plural = () => {
    return "Summer Support Proposals for " + this.getYear();
  }

  get_breadcrumbs = () => {
    return [{ link: "/faculty", text: "Faculty" }, { text: "Summer Support" }];
  }

  get_form = (func) => {
    return <SummerSupportProposalForm {...this.props} onSubmit={this.submit_form} />;
  }

  get_record_view = (record) => {
    return <SummerSupportProposal {...this.props} record={record} />
  }

  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func(); }, JSON.stringify(data));
  }

  get_overview_text = () => {
    return (
      <div>
        <p>In the table below, please see a list of all of your submissions for summer support during the {this.getYear()} summer months.  Once you submit a proposal, it is routed to the Khoury Grants group and then to the Dean's office for approval.  The Khoury Grants group will verify that the requested amount of summer support is available and that the grant allows it.</p>
        <p>Note that according to University rules, faculty members can normally take up to 3.2 months of summer support each year.  However, certain funding agencies limit the total amount of summer funding that can be taken per PI (e.g., the National Science Foundation typically limits PIs to no more than two months total across all NSF projects).</p>
        <p>To receive summer support on May 15th, the proposal needs to be submitted by April 7th. Any requests received after April 7th, cannot be guaranteed.</p>
      </div>
    );
  }
}

export { SummerSupportProposalOverview, SummerSupportProposal, SummerSupportProposalTable, SummerSupportProposalSearchForm };