import React, { Component } from "react";
import moment from "moment-timezone";
import AppComponent from '../../AppComponent';
import Content from '../../Content';
import CustomTabs from "../../CustomTabs";
import MeritReviewerForm from "./ReviewerForm";
import { add_dividers, get_check, get_nocheck, format_nuid, get_star, get_pausecheck, oxford, get_merit_tag, add_dividers_horiz, Spin, get_merit_score, add_brs, if_empty, isEmpty } from "../../Utils";

import { PageHeader, Layout, Dropdown, Menu, Breadcrumb, Icon, Input, Select, DatePicker, Table, Tabs, Tag, Tooltip, Radio, Button, Divider, Form, Switch, message, Modal, List, Card, InputNumber, Alert, Upload, Steps } from 'antd';

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;
const { Option } = Select;


class TeachingFacultyMeritReview extends AppComponent {
  state = {
    //Endpoint to see what assignments as a primary reviewer the user has for this year. 
    endpoint_assignments: "/api/merit/teaching/committeeassignments/",
    merit_assignments: [],
    loading_assignments: true,

    //endpoint to see what reviews are already completed
    endpoint_reviews: "/api/merit/teaching/committeereview/",
    reviews: [],
    loading_reviews: true,

    filter: ""
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester != this.props.semester) {
      this.getData();
    }
  }

  getData = () => {
    this.getAssignments();
    this.getReviews();
  }

  getMeritYear = () => {return this.get_year(this.props.semesters[0]) - 1;}

  getAssignments = () => {
    this.doGet(this.state.endpoint_assignments + "?faculty=" + this.props.user.instructor + "&year=" + this.getMeritYear(), data => this.setState({ merit_assignments: data, loading_assignments: false }));
  }

  getReviews = () => {
    this.doGet(this.state.endpoint_reviews + "?faculty=" + this.props.user.instructor + "&year=" + this.getMeritYear(), data => this.setState({ reviews: data, loading_reviews: false }));
  }

  getCurrentReview = (facultyId) => {
    const reviews = this.state.reviews;
    const review = reviews.find(el => el.reviewee === facultyId);
    return review || null;
  }

  displaySuccessMessage = (result) => {
    if (result.is_complete) {
      message.success("Review has been submitted successfully.");
    } else {
      message.success("Your progress has been saved successfully.");
    }
  };

  handleCreateUpdate = (callback, data) => {
    const form = this.formRef.props.form;
    const reviews = this.state.reviews;
    const review = reviews.find(el => el.reviewee === data.reviewee)

    form.validateFields((err, values) => {
      if (err) { return; }
      if (review) {
        this.doPatch(this.state.endpoint_reviews + review.id + "/", (result) => {
          if (result) {
            this.displaySuccessMessage(result);
            this.getReviews();
            callback();
          }
        }, JSON.stringify(data));
      } else {
        this.doPost(this.state.endpoint_reviews, (result) => {
          if (result) {
            this.displaySuccessMessage(result);
            this.getReviews();
            callback();
          }
        }, JSON.stringify(data));
      }
    });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleFilterClick = (filterType) => {
    this.setState((prevState) => ({
      filtersSelected: {
        ...prevState.filtersSelected,
        [filterType]: !prevState.filtersSelected[filterType]
      }
    }));
  };

  handleSelect = (filterType) => {
    this.setState({
      filter: filterType
    });
  };

  // applyFilter function filters assignments based on selected criteria.
  // If filter is 'empty', it returns assignments without matching reviews.
  // For 'submitted' or 'inProgress', it filters assignments based on review status.
  // If no filter is selected, it returns all assignments.
  applyFilter = (assignments, reviews) => {
    const filter = this.state.filter;

    const filterConditions = {
      empty: assignment => !reviews.some(review => review.reviewee === assignment.reviewee),
      deanInProgress: assignment => {
        const review = reviews.find(review => review.reviewee === assignment.reviewee);
        return review && review.dean_review_started;
      },
      submitted: assignment => {
        const review = reviews.find(review => review.reviewee === assignment.reviewee);
        return review && review.is_complete && !review.dean_review_started;
      },
      inProgress: assignment => {
        const review = reviews.find(review => review.reviewee === assignment.reviewee);
        return review && !review.is_complete && !review.dean_review_started;
      }
    };

    const stateCountMap = {
      empty: 0,
      deanInProgress: 0,
      submitted: 0,
      inProgress: 0
    };

    const filteredAssignments = assignments.filter(assignment => {
      const condition = filterConditions[filter];
      const conditionResult = condition ? condition(assignment) : true;

      // This count map returns the count of reviews in each state
      Object.keys(stateCountMap).forEach(filterKey => {
        const condition = filterConditions[filterKey];
        if (condition(assignment)) {
          stateCountMap[filterKey]++;
        }
      });

      return conditionResult;
    });

  return { filteredAssignments, stateCountMap };
    
  };

  renderIcon = (iconIndex) => {
    const iconMap = {
      0: get_nocheck(),
      1: get_pausecheck(),
      2: get_check(),
      3: <Icon key="edit" type="edit" theme="twoTone" twoToneColor="#1890ff"/>,
    };

    return iconMap[iconIndex];
  }

  getTabIcon = (reviewee, reviews) => {
    const review = reviews.find(el => el.reviewee === reviewee);
  
    let iconIndex;
    if (!review) {
      iconIndex = 0;
    } else if (review && review.dean_review_started) {
      iconIndex = 3;
    } else if (review && review.is_complete && !review.dean_review_started) {
      iconIndex = 2;
    } else if (review && !review.is_complete && !review.dean_review_started){
      iconIndex = 1;
    }
  
    return this.renderIcon(iconIndex);
  }


  get_merit_status = semester_ids => {
    return semester_ids.map(sem => this.get_semester(sem).teaching_merit_reviewerform).some(Boolean)
  }

  render() {
    const { merit_assignments, loading_assignments, loading_reviews, reviews } = this.state;
    
    const specific_merit_assignments = merit_assignments?.filter(review => {
      return review.reviewers && review.reviewers.some(reviewer => reviewer.reviewer_faculty === this.props.user.instructor);
    });

    const { filteredAssignments, stateCountMap }= this.applyFilter(specific_merit_assignments, reviews);

    filteredAssignments.sort((a, b) => {
      const lastnameA = a.reviewee_lastname;
      const lastnameB = b.reviewee_lastname;

      return lastnameA.localeCompare(lastnameB);
    });

    const totalReviewCount = Object.keys(specific_merit_assignments).length

    const formVisible = this.get_merit_status(this.props.semesters)

    return (
      <Content
        {...this.props}
        title={"Teaching Faculty Merit Review Form for Review Year " + this.getMeritYear(this.props.semesters)}
        breadcrumbs={[
          { link: "/faculty", text: "Faculty" },
          { text: "Teaching Merit" },
          { text: "Review" }
        ]}
      >
        <p>Below is a list of faculty members for which you are the primary reviewer.</p>

        {loading_assignments || loading_reviews ? (
          <Spin tip="Loading merit data" />
        ) : formVisible ?       
        (
          <>
            <Form>
              <FormItem label="Filter by review state" style={{ float: 'right' }}>
                <Select style={{ width: 250 }} defaultValue="" onChange={event => this.handleSelect(event)}>
                  <Option key={""} value={""}>All ({totalReviewCount}) </Option>
                  <Option key={"E"} value={"empty"}>{this.renderIcon(0)}&nbsp; Not Started ({stateCountMap["empty"]}) </Option>
                  <Option key={"IP"} value={"inProgress"}>{this.renderIcon(1)}&nbsp; In Progress ({stateCountMap["inProgress"]}) </Option>
                  <Option key={"S"} value={"submitted"}>{this.renderIcon(2)}&nbsp; Submitted ({stateCountMap["submitted"]})</Option>
                  <Option key={"DIP"} value={"deanInProgress"}>{this.renderIcon(3)}&nbsp; Dean Review In Progress ({stateCountMap["deanInProgress"]})</Option>

                </Select>
              </FormItem>
            </Form>
            <Divider></Divider>
            <CustomTabs {...this.props}>
              {filteredAssignments.map((f) => (
                 <TabPane key={f.id}
                 tab={[
                   this.print_full_instructor(f.reviewee),
                   " ",
                   this.getTabIcon(f.reviewee, reviews)
                 ]}
               >
                <Divider orientation="left">Merit Review for {this.print_full_instructor(f.reviewee)}</Divider>
                  {(!f.dean_review_started) ? (
                    <>
                      {!f.self_review_complete && (
                        <Alert message="This faculty member has not completed their retrospective memorandum for this review year." type="error" style={{marginBottom: '1em'}}></Alert>
                      )}
                      {(this.getCurrentReview(f.reviewee))?.is_complete && (
                        <Alert message="You have submitted a merit review for this faculty member. You may edit and resubmit your review below until the deadline." style={{marginBottom: '1em'}}></Alert>
                      )}
                      
                      <MeritReviewerForm 
                        {...this.props} 
                        handleCreateUpdate={this.handleCreateUpdate} 
                        wrappedComponentRef={this.saveFormRef} 
                        currentFacultySelected={f.reviewee} 
                        getMeritYear={this.getMeritYear} 
                        review={this.getCurrentReview(f.reviewee)}
                        deanReview={false}
                      />
                    </>
                  ) : (
                    <span><Alert message="The dean has started editing this review." type="info"></Alert></span>
                  )}
                </TabPane>
              ))}
            </CustomTabs>
          </>
        ) : <Alert message="Review Period is closed." type="warning" showIcon />
        }
      </Content>
    );
  }
}

export { TeachingFacultyMeritReview, MeritReviewerForm };
