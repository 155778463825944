import React, { Component } from "react";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './AppComponent';
import StatusComponent from './StatusComponent';
import { Status } from './StatusComponent';
import Preference from './Preference';
import PreferenceNote from './PreferenceNote';
import Content from './Content';
import { add_dividers, add_dividers_horiz, format_percent, format_decimal } from './Utils';

import { PageHeader, Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, message, Typography } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { Text, Title } = Typography;


class TeachingPreferences extends AppComponent {
  state = {
      endpoint: "/api/planning/",
      sections: [],
      loading: true,

      endpoint_editable: "/api/schedule/",
      editable: true,
      loading_editable: true,

      endpoint_course_prefs: "/api/preferences/course/",
      course_prefs: [],
      loading_course_prefs: true,

      endpoint_meetingtime_prefs: "/api/preferences/meetingtime/",
      meetingtime_prefs: [],
      loading_meetingtime_prefs: true,

      show_other_courses: false,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  handleToggle = (e) => {
    this.setState({ show_other_courses: e });
  }

  getData = () => {
    this.doGet(this.state.endpoint + "?campus=" + this.props.campus + "&semester=" + this.props.semesters.join(","),
                  data => this.setState({ sections: data, loading: false }));
    this.doGet(this.state.endpoint_editable + "?campus=" + this.props.campus + "&semester=" + this.props.semesters.join(","),
                  data => this.setState({ editable: data && data.length == 0, loading_editable: false }));
    this.getPrefs();
  }

  getPrefs = () => {
    this.doGet(this.state.endpoint_course_prefs + "?semester=" + this.props.semesters.join(",") + "&faculty=" + this.props.user.instructor,
                  data => this.setState({ course_prefs: data, loading_course_prefs: false }));
    this.doGet(this.state.endpoint_meetingtime_prefs + "?semester=" + this.props.semesters.join(",") + "&faculty=" + this.props.user.instructor,
                  data => this.setState({ meetingtime_prefs: data, loading_meetingtime_prefs: false }));
  }

  render_pref = (record, loading, desc, pref) => {
    return ( <List key={ "list-" + record.id }
                   grid={ this.grid_preference }
                   loading={ loading  }
                   size="small"
                   dataSource={ [1] }
                   renderItem={ record => [(
                     <List.Item key={ "item" }>
                       { desc }
                     </List.Item> ), (
                     <List.Item key={ "pref" } style={{float: 'right'}}>
                       { pref }
                     </List.Item> )] } />
            );
  }


  render_course_pref = (record, sem) => {
    const { loading, loading_course_prefs, course_prefs, editable } = this.state;

    const pref = course_prefs.find(el => el.course == record.id && el.semester == sem);
    return this.render_pref(record, loading || loading_course_prefs,
                            ( <List.Item.Meta
                                title={ this.link_full_course(record.id) }
                                description= { this.print_course_description_short(record.id) } /> ),
                            ( <Preference {...this.props}
                                key={ record.id + "-" + (pref ? pref.id : null)}
                                course={ record.id }
                                editable={ editable }
                                value={ pref }
                                onChange={ this.getPrefs }
                                endpoint="/api/preferences/course/"
                                addNewData={ f => { f["course"] = record.id; f["semester"] = sem; } } /> ));
  }

  render_meetingtime_pref = (record, sem) => {
    const { loading, loading_meetingtime_prefs, meetingtime_prefs, editable } = this.state;

    const pref = meetingtime_prefs.find(el => el.meetingtime == record.id && el.semester == sem);
    return this.render_pref(record, loading || loading_meetingtime_prefs,
                            ( <List.Item.Meta title={ this.print_meetingtime(record.id) } /> ),
                            ( <Preference {...this.props}
                                key={ record.id + "-" + (pref ? pref.id : null)}
                                meetingtime={ record.id }
                                editable={ editable }
                                value={ pref }
                                onChange={ this.getPrefs }
                                defaultValue={ 2 } 
                                endpoint="/api/preferences/meetingtime/"
                                addNewData={ f => { f["meetingtime"] = record.id; f["semester"] = sem; } } /> ));
  }

  render_notes = (sem) => {
    const { editable } = this.state;

    return ( <PreferenceNote {...this.props}
                key={ "notes-" + sem }
                editable={ editable }
                endpoint="/api/preferences/note/"
                initialArgs={ "?semester=" + sem + "&faculty=" + this.props.user.instructor }
                addNewData={ f => f["semester"] = sem } /> );
  }

  render() {
    const { semester, campus } = this.props;
    const { editable, loading, sections, loading_course_prefs, loading_meetingtime_prefs, show_other_courses } = this.state;

    return (
      <Content {...this.props} title={ semester + " Teaching Preferences"} breadcrumbs={ [{ link: "/teaching", text: "Teaching" }, { text: "Preferences" }, { text: semester }] }>
        <p>Welcome to the new teaching preferences form.  Please enter your course and meeting time preferences below; at the bottom is a free-form text input if you would like to provide more details.  All of your entries will be automatically saved; if an error occurs, a popup will be shown.</p>

        { editable ? "" : ( <Alert message="Semester has already been planned" description={ "Published sections for the " + semester + " semester already exist; thus the preferences form below is read-only." } type="warning" showIcon /> ) }

        { this.props.semesters.map(sem => {

          const mysections = sections.filter(el => el.semester == sem);
          const course_list = this.course_list_from_sections(mysections).filter(el => el.loadcount > 0);
          const other_course_list = this.course_list_missing_from_sections(mysections).filter(el => el.loadcount > 0 && !el.deprecated);
          const meetingtime_list = this.meetingtime_list().filter(el => el.popular_campus.includes(campus));
          
          return (
            <React.Fragment key={ sem }>
              <Divider orientation="left">{ this.print_semester(sem) } Course Preferences</Divider>
              <p>Below is the list of courses we expect to offer during { this.print_semester(sem) }. If the course you wish to teach is not listed, please use the next section to add your preference for other classes. By default, all classes are listed as "No"; you are free to adjust this as desired.</p>
              { add_dividers_horiz(course_list.map(el => this.render_course_pref(el, sem))) }
              <p>If you are interested in teaching a course not shown in the list above, please specify it below (you can view the list by clicking on "Show Other Courses" below). Note that we may not be able to offer many of these courses each semester, due to the requirements of our degree programs.</p>
              <p style={{ textAlign: 'right', width: '100%' }} >
                <Text strong> Show Other Courses &nbsp;</Text>
                <Switch checked={show_other_courses} onChange={this.handleToggle} />
              </p>
              { show_other_courses ? add_dividers_horiz(other_course_list.map(el => this.render_course_pref(el, sem))) : "" }
              <Divider orientation="left">{ this.print_semester(sem) } Meeting Time Preferences</Divider>
              <p>You may use the form below to specify any preferences regarding the course schedule. Typically, introductory undergrad courses are taught three days a week, upper level undergrad and doctoral courses are taught twice weekly, and masters classes are taught once weekly in the evenings. We will do our best to honor time preferences, but there are many constraints that are out of our hands, including room availability, coordinating with other courses, avoiding popular time slots, etc.</p>
              { add_dividers_horiz(meetingtime_list.map(el => this.render_meetingtime_pref(el, sem))) }
              
              <Divider orientation="left">{ this.print_semester(sem) } Preference Notes</Divider>
              <p>Please include any additional notes below about your teaching duties for the { this.print_semester(sem) } semester, for example if you expect to be on sabbatical or to have a course release. Additionally, if you are scheduled to teach multiple classes please indicate whether you would prefer multiple sections of the same class or would prefer different classes, and whether you would prefer to have your teaching concentrated on a few days or spread out across the week. If there is a meeting time you are interested in and it is not listed, you can note it here. You can find a list of common meeting times on the  <a href="https://registrar.northeastern.edu/article/semester-class-sequence-schedules/" target="_blank">Registrar's Website.</a></p>
              { this.render_notes(sem) }
            </React.Fragment> );
          }
      )}
      </Content>
    );
  }
}

export default TeachingPreferences;