import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";

import { Icon, Radio, Steps, Table, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
import { MinusCircleOutlined } from '@ant-design/icons';
import { oxford, isEmpty} from '../../../Utils';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { Step } = Steps;

const PhDThesisApprovalForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      khourymembers: 0,
      nonkhourymembers: 1,
      uploadedList: [],
    }

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({ khourymembers: 0, nonkhourymembers: 1, uploadedList: []}); 
      this.props.form.resetFields();
      this.props.form.setFieldsValue({["thesis_proposal_upload"]: []});
    }

        
    handleSubmit = () => {
      const { uploadedList } = this.state;
      const form = this.props.form;
      form.validateFields((err, values) => {
        if (!err) {
          if (values["non_members"]){
            if (values.members) {
              values["members"] = [...values["members"], ...values["non_members"]].filter(e => {return e});
            } else {
              values["members"] = values["non_members"].filter(e => {return e});
            }
            delete values["non_members"];
          }
          let members = values["members"].filter(e => {return e}); 
          delete values["members"];
          
          let advisor_statement = values["advisor_statement"];
          delete values["advisor_statement"];

          this.props.onSubmit({...(values),...{thesis_form: {members: members, advisor_statement: advisor_statement}}, ...{files: uploadedList}}, this.reset());
        } else {
          message.error(err);
        }
      });
    };

    getFile = (type) => {
      const { uploadedList } = this.state;
      return(
        <React.Fragment>
          {(uploadedList.filter(el => el[1]["type"] == type).length != 0) && (
            <div style={{marginTop:"10px"}}>
              <p>{ type } <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={ () => this.onRemove(type)} /></p>
            </div>
          )}
        </React.Fragment>
      )};


    setFile = (file, type) => {
      this.setState(state => ({
        uploadedList: [...state.uploadedList, [window.btoa(file), type]],
      }));
    };

    beforeUpload = (file, type) => {
      if (file.size / 1024 / 1024 > 10) {
        message.error('File is larger than 10MB');
        return;
      }
      const reader = new FileReader();
      const setFile = this.setFile;
      reader.onload = function(event) {
        setFile(event.target.result, type);
      };
      reader.readAsBinaryString(file);
  };

    onRemove = type => {
      this.setState(state => {
        const matches = (el) => el[1]["type"] == type;
        const index = state.uploadedList.findIndex(matches);
        const newuploadedList = state.uploadedList.slice();
        newuploadedList.splice(index, 1);
        return {
          uploadedList: newuploadedList,
        };
      });
    };
    
    disableUpload = (type) => {
      const { uploadedList } = this.state;
      return uploadedList.filter(el => el[1]["type"] == type).length != 0;
    }

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const { khourymembers, nonkhourymembers } = this.state;
      
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };
      
      return (
        <>
          <p>With the help of a student’s faculty advisor, students will select the Comprehensive Committee consisting of at least four members to be approved by the PhD Committee. </p>

          <u>The four members must include:​​​​​​​</u>
            <ul>
              <li>The advisor</li>
              <li>Two other faculty members from the College</li>
              <li>An external examiner</li>
            </ul>
          <u>If a student is co-advised, the four members should be: </u>
            <ul>
              <li>Main advisor </li>
              <li>Co-advisor as one other college faculty</li>
              <li>Another college faculty</li>
              <li>An external examiner</li>
            </ul>
          <p> 
          <p>
          <b>
          These criteria represent the minimum requirements. For example, it is acceptable for a committee to consist of 3 Khoury faculty members and 2 external faculty members, in addition to the advisor.
          </b>
          </p>
          <i>
           Full regulations can be found in the University Catalog's&nbsp; 
              <a href="https://catalog.northeastern.edu/graduate/academic-policies-procedures/regulations-phd-programs/" target="_blank"> 
                Regulations and Requirements for Doctor of Philosophy (PhD) Programs 
              </a>
               , the PhD CS Curriculum&nbsp;
              <a href="https://catalog.northeastern.edu/graduate/computer-information-science/computer-science/computer-science-phd/#text" target="_blank">
                Overview
              </a>
              , and the Graduate Resource SharePoint, specifically&nbsp;
              <a href="https://northeastern.sharepoint.com/sites/KhouryResourceCenter/SitePages/Information-for-Computer-Science-PhD-Students.aspx?CT=1697214092821&OR=OWA-NT&CID=f680e01e-303e-2227-685f-fb8e49563f52&WSL=1" target="_blank">
              Proposal / Comprehensive Exam.
              </a> 
            </i>
            </p>
          
          <p>If you wish to amend a previously-submitted Thesis Committee approval, please simply submit a new one. The Khoury PhD staff will use the most recent one that you submit.</p>
          <div style={{marginTop:"15px"}}>
          <Form>
          <FormItem {...formItemLayout} label="Thesis Proposal Title" extra={ "Please enter your thesis proposal title."}>
                  {getFieldDecorator("thesis_proposal_title", {
                  rules: [{ required: true, message: "Please input a thesis proposal title" }], initialValue: ""})
                  (<Input style={{ width: 360 }} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Thesis Proposal Abstract" extra={"Please copy and paste the abstract from your thesis proposal. Max 500 words."}>
                  {getFieldDecorator("thesis_proposal_abstract", {
                  rules: [{ required: true, message: "Please input a thesis proposal abstract" }], initialValue: ""})
                  (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Advisor Statement" extra={ "A short paragraph from the thesis advisor justifying the choice of the Committee. One sentence per member of the Committee will suffice."}>
                  {getFieldDecorator("advisor_statement", {
                  rules: [{ required: true, message: "Please input an advisor statement" }], initialValue: ""})
                  (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>
              <Divider orientation="left">Khoury Examiners</Divider>
                <Form.Item {...formItemLayout} label="Advisor(s)">
                  <p>Your Advisor(s) -- <small>(Will be automatically added, no need to add them below)</small></p>
                </Form.Item>
                {[...Array(khourymembers)].map((el, idx) =>
                <>
                  <FormItem {...formItemLayout} label={"Khoury Committee Member " + (idx + 1)} extra={"Please select Khoury committee member " + (idx + 1)}>
                    <>
                    {getFieldDecorator("members." + (idx + 1) + ".employee", { rules: [{required: false, message: "Please select a committee member"}],
                        })
                        (
                          <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                          {this.employee_list().map(el => (
                            <Option key={el.id} value={el.id}>{this.print_full_employee(el.id)}</Option>
                          ))}
                        </Select>
                      )}
                      {" "}
                      {idx == [...Array(khourymembers)].length - 1 && idx >= 0 ? ( <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={ () => this.setState({ khourymembers: khourymembers - 1})} /> ) : null}
                    </>
                  </FormItem>
                </>
                )}
                <FormItem {...formItemLayout} label=" " colon={ false }>
                  <Button onClick={() => this.setState({ khourymembers: khourymembers + 1})}>Add Khoury Committee Member</Button>
                </FormItem>
              <Divider orientation="left">Non-Khoury (External) Examiner </Divider>
                {[...Array(nonkhourymembers)].map((el, idx) =>
                <>
                  <FormItem {...this.formItemLayout} label=" " colon={ false }>
                    <h4>{"External Member " + (idx + 1)}</h4>
                  </FormItem>
                  <FormItem {...formItemLayout} label="Firstname" extra="Please enter their first name">
                  {getFieldDecorator("non_members." + (idx + 1) + ".firstname", { rules: [{required: true, message: "Please enter their first name"}]})
                      (
                        <Input style={{ width: 360 }} />         
                      )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="Lastname" extra="Please enter their last name">
                  {getFieldDecorator("non_members." + (idx + 1) + ".lastname", { rules: [{required: true, message: "Please enter their last name"}]})
                      (
                        <Input style={{ width: 360 }} />          
                      )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="Email" extra="Please enter their email">
                  {getFieldDecorator("non_members." + (idx + 1) + ".email", { rules: [{type: 'email', required: true, message: "Please enter an email"}]})
                    (
                      <Input style={{ width: 360 }}/>      
                    )}
                  </FormItem>
                <FormItem {...formItemLayout} label="Website" extra="In case the external examiner does not have a homepage or the page does not contain biographical information, a link to their curriculum vita or a pdf of the vita must be included.">
                {getFieldDecorator("non_members." + (idx + 1) + ".website", { rules: [{type: 'url', required: false,  message: 'Must be a URL beginning with https://www' }], initialValue: "",})
                  (
                    <Input style={{ width: 360 }}/>   
                  )}
                </FormItem>
                <FormItem {...this.formItemLayout} label="External Examiner CV" extra={ "Please upload a copy of the External Examiners CV.  It must be in PDF format and no larger than 2 MB. You must provide a firstname and lastname before being able to upload." } >
                {getFieldDecorator("cv_upload_" + (idx + 1), { rules: [{required: form.getFieldValue("non_members." + (idx + 1) + ".website") == "",  message: 'If you do not provide a website, you have to upload a CV' }] })(
                  <Upload beforeUpload={(file) => this.beforeUpload(file, {"type": form.getFieldValue("non_members." + (idx + 1) + ".firstname") + " " + form.getFieldValue("non_members." + (idx + 1) + ".lastname") + " CV"})} accept=".pdf" showUploadList={false}>
                    <Button disabled={!form.getFieldValue("non_members." + (idx + 1) + ".firstname") || !form.getFieldValue("non_members." + (idx + 1) + ".lastname") || form.getFieldValue("non_members." + (idx + 1) + ".website") != "" || this.disableUpload(form.getFieldValue("non_members." + (idx + 1) + ".firstname") + " " + form.getFieldValue("non_members." + (idx + 1) + ".lastname") + " CV")}><Icon type="upload" />Upload CV</Button>
                    {this.getFile(form.getFieldValue("non_members." + (idx + 1) + ".firstname") + " " + form.getFieldValue("non_members." + (idx + 1) + ".lastname") + " CV")}
                  </Upload>
                )}
                </FormItem>
              </>)}
              <FormItem {...formItemLayout} label=" " colon={ false }>
                <>
                  <Button onClick={() => this.setState({ nonkhourymembers: nonkhourymembers + 1})}>Add External Committee Member</Button>
                  {" "}
                  {[...Array(nonkhourymembers)].length > 1 ? ( <Button type="danger" onClick={ () => this.setState({ nonkhourymembers: nonkhourymembers - 1})}>Delete</Button> ) : null}
                </>
              </FormItem>
              <FormItem {...this.formItemLayout} label=" " colon={ false }>
                <Button type="primary" onClick={ () => this.handleSubmit() }>Submit</Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);        
 

class PhDThesisApproval extends AppComponent {

  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
        <Descriptions bordered title={ "PhD Thesis Committee Approval submitted by " + record.created_by}>
          <Descriptions.Item label="Submitted" span={ 2 }>{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" span={ 2 }>{[ record.created_by + " ", <a key="email" href={"mailto:" + item.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" span={ 2 }>{ item.phdstudent.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 2 }>{<Transcript {...this.props} nuid={item.phdstudent.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Degree" span={ 2 }>{ item.phdstudent.degree ? this.print_degree(item.phdstudent.degree): "Couldn't load" }</Descriptions.Item>
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(item.phdstudent.campus) }</Descriptions.Item>
          <Descriptions.Item label="Matriculated" span={ 2 }>{ item.phdstudent.matriculated ? this.print_semester(item.phdstudent.matriculated) : "Couldn't load"  }</Descriptions.Item>
          <Descriptions.Item label="Thesis Proposal Title" span={ 4 }>{ item.thesis_proposal_title }</Descriptions.Item>
          <Descriptions.Item label="Thesis Proposal Abstract" span={ 4 }>{ item.thesis_proposal_abstract }</Descriptions.Item>
          <Descriptions.Item label="Advisor Statement" span={ 4 }>{ item.thesis_form.advisor_statement }</Descriptions.Item>
          <Descriptions.Item label="" span={ 4 }><strong>Committee Members</strong></Descriptions.Item>
          { item.thesis_form.members.map((el, idx) => (
            <Descriptions.Item label={"Member " + (idx + 1) + (el.external ? " (External)" : el.advisor ? " ( Advisor)" : "")} span={ 4 }>{
                [(el.external ? el.firstname + " "+ el.lastname : this.print_full_employee(el.employee))  + " ", (el.member_email ? <a key="email" href={"mailto:" + el.member_email}><Icon type="mail" theme="twoTone" /></a>: null)," ",( el.website ? <a key="website" href={el.website} target="_blank"><Icon type="home" theme="twoTone" /></a> : null) ] }</Descriptions.Item>
          ))}
          { !isEmpty(record.files) && (
          <Descriptions.Item label="Attached Files" span={ 4 }>{[record.files.map(el => <a onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type +".pdf"}</a>) ]}</Descriptions.Item>
          )}
        </Descriptions>
      );
   }
}

class PhDThesisApprovalTable extends WorkflowTable {
  renderMembers = (members) => {
    return members[0]?.advisor ? members : members.reverse()
  }

  get_columns = () => {
    return [
      {
        title: "Thesis Proposal Title",
        key: "thesis_proposal_title",
        width: 200,
        render: (text, record, idx) => record.record.thesis_proposal_title,
      }, {
        title: "Advisor Statement",
        key: "advisor_statement",
        width: 300,
        render: (text, record, idx) => record.record.thesis_form.advisor_statement,
      }, {
        title: "Committee Members",
        key: "members",
        width: 180,
        render: (text, record, idx) => this.renderMembers(record.record.thesis_form.members).map((el, idx) => <p key={el.id}>{(idx+1) + ". " + (el.external ? el.firstname + " "+ el.lastname : this.print_full_employee(el.employee)) + (el.advisor ? " (Advisor)" : el.external ? " (External)" : "")}</p>),
        
      }
    ];
  }
}

class PhDThesisApprovalOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/phd/thesiscommittee/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "PhD Thesis Committee Approval";
  }

  get_workflowtype = () => {
    return "phdthesiscommittee";
  }

  get_name_plural = () => {
    return "PhD Thesis Committee Approvals";
  }
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "phd" }, { text: "Thesis Committee Approval" }];
  }
  
  get_form = (func) => {
    return <PhDThesisApprovalForm {...this.props} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <PhDThesisApproval {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
      <div>
        <p>Before you fill out this form, please ensure:</p>
        <ul>
          <li>You have written an abstract (max 500 words) for your thesis proposal.</li>
          <li>You have set up a Comprehensive Exam Committee. Do this with the help of your faculty advisor. Requirement details below.
          </li>
        </ul>
        <p>After receiving approval, select a date for your Comprehensive Exam and complete this <strong><a href="https://neu.co1.qualtrics.com/jfe/form/SV_3RjMdGIx61w4CEe" target="_blank">form</a></strong> so that your proposal may be advertised to the larger Khoury community.</p>

        <p><strong>Please allow two weeks for the PhD committee to review, discuss, vote, and communicate the outcome. Do not schedule your Comprehensive Exam until you have received approval.</strong></p>
      </div>
    );
  }  
}

export { PhDThesisApprovalOverview, PhDThesisApproval, PhDThesisApprovalTable };