import React from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import {  Icon,  Menu,  Dropdown,  Switch,  Modal,  Input,  Typography,  Alert, Button, Divider} from "antd";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import EditableTable from "../../EditableComponents";
import { get_check_nocheck } from "../../Utils";
import { renderStatus } from "../../Utils";
import Transcript from "../../Transcript";

const { Text, Title } = Typography;

const divStyle = {
  margin: 100,
  width: 360
};

class ReviewForm extends AppComponent { 
  state = {
    message: "",
    endpoint: "/api/petition/staffnote/"
  };

  handleOk = () => {
    this.doPost(
      this.state.endpoint,
      () => {
        this.props.hanldeSubmit();
        this.handleCancel();
      },
      JSON.stringify({ petition: this.props.id, text: this.state.message })
    );
  };

  handleCancel = () => {
    this.setState({ message: "" });
    this.props.handleCancel();
  };

  render() {
    return (
      <Modal
        title={"Review of Petition (Shared with Student)"}
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        Message:{" "}
        <Input.TextArea
          value={this.state.message}
          onChange={e => {
            this.setState({ message: e.target.value });
          }}
          style={{ divStyle }}
          rows={3}
        />
      </Modal>
    );
  }
}

export default class Petition extends AppComponent {
  state = {
    endpoint: this.props.endpoint,
    review: false,
    review_id: 1,
    visible: false,
    loading: true,
    sections: [],

    endpoint_section: "/api/schedule/",
    loading_sections: true,
    endpoint_viewable: "/api/petitionviewable/",
    visibility_type: ""
  };

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.endpoint !== this.props.endpoint ||
      prevProps.semester !== this.props.semester
    ) {
      this.state.endpoint = this.props.endpoint;
      this.state.loading = true;
      this.state.loading_sections = true;
      this.update();
    }
  }

  csv_data = (petitions) => {
    const { sections } = this.state;
    var download_data = JSON.parse(JSON.stringify(petitions));
    download_data.forEach(el => {
      el["crn"] = el["any_section"] ? "Any" : el["crn"].join(";")
      el["semester"] = this.print_semester(el["semester"]);
      el["course"] = this.print_course(el["course"]);
      el["selected_drop_course"] = el["selected_drop_course"] ? this.print_course(el["selected_drop_course"]) : "N/A";

    });

    //download_data.map(el => delete el["staff_notes"], delete el["sections"]);
    ['staff_notes', 'sections', 'any_section'].forEach(e => delete download_data[e]);
    return download_data;
  }

  update = () => {
    const { endpoint, endpoint_section } = this.state;
    const { semesters } = this.props;
    const visibility_type = endpoint.includes("majorchange")
      ? "petitions_visible_mc"
      : endpoint.includes("patternofattendance")
      ? "petitions_visible_pa"
      : endpoint.includes("closedcourse")
      ? "petitions_visible_cc"
      : "petitions_visible_tz";
    this.setState({ visibility_type: visibility_type });
    this.doGet(
      this.state.endpoint_viewable +
        "?semester=" +
        this.props.semesters.join(","),
      data => {
        this.setState({ visible: data[0][visibility_type] });
      }
    );
    this.doGet(
      endpoint + (visibility_type != "petitions_visible_tz" ? (visibility_type == "petitions_visible_cc" ? "?semester=" :  "?section__semester=") : "?section_new__semester=") + this.props.semesters.join(","),
      petitions => {
        this.setState({ petitions, loading: false });
      }
    );
    this.doGet(
      endpoint_section +
        "?semester=" +
        this.props.semesters.join(","),
      sections => this.setState({ sections, loading_sections: false })
    );
  };

  cancel = () => {
    this.setState({ review: false });
  };

  action = (id, action) => {
    this.setState({ review: true, review_id: id, decision: action });
  };

  submit = (id, decision) => {
    this.doPatch( this.state.endpoint + id + "/", () => { this.update(); }, JSON.stringify({decision: decision}) );
  };

  getActions = (text, record, idx) => {
    const menu = (
      <Menu>
        <Menu.Item>
          <a onClick={() => this.submit(record.id, "Submitted")}>Remove Decision</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.submit(record.id, "Self-Enrolled")}>Self-Enrolled</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.submit(record.id, "Accepted")}>Accept</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.submit(record.id, "Denied")}>Deny</a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} disabled={ record.decision == "Cancelled" }>
        <a className="ant-dropdown-link">
          Actions <Icon type="down" />
        </a>
      </Dropdown>
    );
  };

  onChange = checked => {
    const { semesters } = this.props;
    const { visibility_type } = this.state;
    const semester = this.get_semester(semesters[0]);
    const id = semester.id;
    this.doPatch("/api/petitionviewable/" + id + "/",() => {this.setState({ visible: checked });},JSON.stringify({ [visibility_type]: checked }));
  };

  render() {
    const { semester } = this.props;
    const { petitions, loading, review, review_id, endpoint, loading_sections, sections} = this.state;

    const base = [
      {
        title: "NUID",
        key: "nuid",
        fixed: "left",
        width: 80,
        render: (text, record, idx) => record.nuid,
        sorter: (a, b) => a.nuid - b.nuid
      },
      {
        title: "Name",
        key: "name",
        width: 150,
        sorter: (a, b) => a.lastname.localeCompare(b.lastname),
        render: (text, record, idx) => [ record.firstname + " " + record.lastname, " ",
          <a key="email" href={"mailto:" + record.email}>
            <Icon type="mail" theme="twoTone" />
          </a>
        ],
      },
      {
        title: "Submission Date",
        key: "created_at",
        width: 150,
        sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
        render: (text, record, idx) =>
          moment(record.created_at).format("MMMM Do YYYY")
      }
    ];

    const closed_course = props => [
      {
        title: "Semester",
        key: "semester",
        width: 100,
        render: (text, record, idx) => this.print_semester(record.semester)
      },
      {
        title: "Transcript",
        key: "transcript",
        width: 120,
        render: (text, record, idx) => <Transcript {...props} nuid={record.nuid} />
      },
      // {
      //   title: "CRN(s)",
      //   key: "crn",
      //   width: 70,
      //   render: (text, record, idx) => record.any_section ? "Any" : record.crn.join(",")
      // },
      {
        title: "Course",
        key: "course",
        width: 80,
        render: (text, record, idx) =>  this.print_course(record.course),
        sorter: (a, b) => a.course - b.course
      },
      {
        title: "Course to Drop",
        key: "selected_drop_course",
        width: 80,
        render: (text, record, idx) => record.selected_drop_course ? this.print_course(record.selected_drop_course) : 
        record.selected_drop_non_khoury_course ? record.selected_drop_non_khoury_course : "N/A",
        sorter: (a, b) => a.selected_drop_course ? a.selected_drop_course : a.selected_drop_non_khoury_course - b.selected_drop_course ? b.selected_drop_course : b.selected_drop_non_khoury_course
      },{
        title: "Student Rationale",
        width: 200,
        key: "rationale",
        render: (text, record, idx) => <div style={{width : '200px', overflowY : 'auto', height : '80px'}}>{record.rationale}</div>
      }
    ];

    const timezone_change = props => [
      {
        title: "Semester",
        key: "semester",
        width: 120,
        render: (text, record, idx) => sections.length && typeof sections.find(el => el.id == record.section_new) !== "undefined" ? this.print_semester(sections.find(el => el.id == record.section_new).semester) : ""
      },
      {
        title: "Transcript",
        key: "transcript",
        width: 120,
        render: (text, record, idx) => <Transcript {...props} nuid={record.nuid} />
      },
      {
        title: "Course",
        key: "course",
        width: 200,
        render: (text, record, idx) => sections.length && typeof sections.find(el => el.id == record.section_new) !== "undefined" ? this.print_full_course(sections.find(el => el.id == record.section_new).course) : "",
        sorter: (a, b) => sections.find(el => el.id == a.section_new).course - sections.find(el => el.id == b.section_new).course
      },
      {
        title: "Current CRN",
        key: "section_old",
        width: 150,
        render: (text, record, idx) => sections.length && typeof sections.find(el => el.id == record.section_old) !== "undefined" ? sections.find(el => el.id == record.section_old).crn : "",
        sorter: (a, b) => sections.find(el => el.id == a.section_old).crn - sections.find(el => el.id == b.section_old).crn,
      },
      {
        title: "Requested CRN",
        key: "section_new",
        width: 150,
        render: (text, record, idx) => sections.length && typeof sections.find(el => el.id == record.section_new) !== "undefined" ? sections.find(el => el.id == record.section_new).crn : "",
        sorter: (a, b) => sections.find(el => el.id == a.section_new).crn - sections.find(el => el.id == b.section_new).crn,
      },
      {
        title: "Student Rationale",
        width: 150,
        key: "rationale",
        render: (text, record, idx) => <div style={{width : '200px', overflowY : 'auto', height : '80px'}}>{record.rationale}</div>
      }
    ];

    const major_change = [
      // {
      //   title: "Current College",
      //   dataIndex: "current_college",
      //   key: "current_college"
      // },
      {
        title: "POA",
        dataIndex: "POA",
        key: "POA",
        width: 50
      },
      {
        title: "Current Major",
        dataIndex: "current_major",
        key: "current_major",
        width: 150,
        render: (text, record, index) =>
          text ? this.print_major(parseInt(text)) : ""
      },

      {
        title: "Desired Major",
        dataIndex: "desired_major",
        key: "desired_major",
        width: 150,
        render: (text, record, index) =>
          text ? this.print_major(parseInt(text)) : ""
      },
      {
        title: "Current GPA",
        dataIndex: "gpa",
        key: "gpa",
        align: "center",
        width: 50
      },
      {
        title: "Last Term GPA",
        dataIndex: "last_term_gpa",
        key: "last_term_gpa",
        align: "center",
        width: 50
      },
      {
        title: "First semester",
        key: "is_first_semester",
        width: 50,
        align: "center",
        render: (text, record, index) => get_check_nocheck(text)
      },
      {
        title: "Taken Fundies",
        dataIndex: "semester_taken_fundies",
        key: "semester_taken_fundies",
        width: 150,

        render: (text, record, index) =>
          record.semester_taken_fundies &&
          this.semester_list().find(el => {
            return el.id == record.semester_taken_fundies;
          }).name
      },
      {
        title: "Taken Co-op Preparation",
        dataIndex: "taken_coop_prep",
        key: "taken_coop_prep",
        width: 50,
        align: "center",
        render: (text, record, index) => get_check_nocheck(text)
      },
      {
        title: "Taken Co-op",
        dataIndex: "taken_coop",
        key: "taken_coop",
        width: 50,
        align: "center",
        render: (text, record, index) => get_check_nocheck(text)
      },
      {
        title: "Spoken to Khoury advisor",
        dataIndex: "spoken_khoury_advisor",
        key: "spoken_khoury_advisor",
        width: 50,
        align: "center",
        render: (text, record, index) => get_check_nocheck(text)
      },
      {
        title: "Student Rationale",
        width: 150,
        key: "reason_for_changing",
        render: (text, record, idx) => <div style={{width : '200px', overflowY : 'auto', height : '80px'}}>{record.reason_for_changing}</div>
      }
    ];

    const pattern_change = [
      {
        title: "POA",
        key: "POA",
        width: 80,
        render: (text, record, idx) => record.POA
      },
      {
        title: "Preferred Cycle",
        key: "preferred_cycle",
        width: 100,
        render: (text, record, idx) => record.preferred_cycle
      },
      {
        title: "Been on Co-op?",
        key: "coop_status",
        width: 50,
        align: "center",
        render: (text, record, idx) => get_check_nocheck(record.coop_status)
      },
      {
        title: "Next Co-op",
        key: "next_coop_cycle",
        width: 100,
        render: (text, record, idx) =>
          this.print_semester(record.next_coop_cycle)
      },
      {
        title: "Co-ops Planned",
        key: "coop_amount",
        width: 50,
        align: "center",
        render: (text, record, idx) => record.coop_amount
      },
      {
        title: "Talked to Advisor?",
        key: "advisor",
        width: 50,
        align: "center",
        render: (text, record, idx) => get_check_nocheck(record.advisor)
      },
      {
        title: "Student Rationale",
        key: "rationale",
        width: 150,
        render: (text, record, idx) => <div style={{width : '200px', overflowY : 'auto', height : '80px'}}>{record.rationale}</div>
      }
    ];

    const descision = [
      {
        title: "Decision",
        key: "decision",
        width: 100,
        align: "center",
        render: (text, record, idx) => renderStatus(record.decision),
        filters: [
          {
            text: 'Accepted',
            value: 'Accepted',
          },
          {
            text: 'Denied',
            value: 'Denied',
          },
          {
            text: 'Self-Enrolled',
            value: 'Self-Enrolled',
          },
          {
            text: 'Cancelled',
            value: 'Cancelled',
          },
          {
            text: 'Submitted',
            value: 'Submitted',
          },
        ],
        onFilter: (value, record) => record.decision.indexOf(value) === 0,
      },
      {
        title: "Actions",
        key: "actions",
        width: 100,
        align: "right",
        render: this.getActions
      }
    ];

    const major_change_columns = [...base, ...major_change, ...descision];
    const pattern_change_columns = [...base, ...pattern_change, ...descision];
    const closed_course_columns = [...base, ...closed_course(this.props),, ...descision];
    const timezone_change_columns = [...base, ...timezone_change(this.props),, ...descision];

    const columns = endpoint.includes("majorchange")
      ? major_change_columns
      : endpoint.includes("patternofattendance")
      ? pattern_change_columns
      : endpoint.includes("closedcourse") 
      ? closed_course_columns
      : timezone_change_columns;

    const title = endpoint.includes("majorchange")
      ? "Change Major"
      : endpoint.includes("patternofattendance")
      ? "Pattern of Attendance"
      : endpoint.includes("closedcourse")
      ? "Closed Course"
      : "Timezone Course Change";

    return (
      <Content
        {...this.props}
        title={"Petition"}
        breadcrumbs={[
          { link: "/staff", text: "Staff" },
          { text: "Petition" },
          { text: title },
          { text: semester }
        ]}
      >
        <div style={{ display: "flex" }}>
          <Title level={3}>{title}</Title>
          <span style={{ display: "flex", marginLeft: "auto" }}>
            <Text strong> close / open the petition window &nbsp;</Text>
            <Switch checked={this.state.visible} onChange={this.onChange} />
          </span>
        </div>
        <div style={{ display: "flex" }}>
          {!loading && !loading_sections && (<CSVLink filename="petition_export.csv" data={this.csv_data(petitions)}><Button icon="download">Download</Button></CSVLink>)}
        </div>
        {!this.state.visible && (
          <Alert
            message="The Petition Window is Closed"
            description="Currently, students cannot submit new applications"
            type="warning"
            showIcon
          />
        )}
        <Divider orientation="left">Submitted Petitions</Divider>
        <EditableTable
          dataSource={petitions}
          columns={columns}
          pagination={false}
          size="small"
          loading={loading || loading_sections}
          rowKey="id"
          scroll={{ x: 1300 }}
          handleSave={this.handleSave}
        />

        <ReviewForm
          {...this.props}
          visible={review}
          id={review_id}
          handleCancel={this.cancel}
          hanldeSubmit={this.submit}
        />
      </Content>
    );
  }
}
