import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";

import { Icon, Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const CONCENTRATIONS = ["Artificial Intelligence","Foundations","Human-Centered Computing","Software","Systems"];

const UGConcentrationDeclarationForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
    }
    
    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.props.form.resetFields();
    }

    handleSubmit = () => {
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit( values , this.reset());
        } else {
          message.error(err);
        }
      });
    };

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };
      
      return (
        <>
          <p>If you wish to amend a previously-submitted petition, please simply submit a new petition. The Khoury Undergrad team will simply use the most recent one that you submit.</p>
          <h4>Note: Only students in the BS and BA Computer Science major may choose a concentration.</h4>
          {" "}
        <Form>
          <FormItem {...formItemLayout} label="Concentration" extra="Please select a concentration.">
            {getFieldDecorator("concentration", { rules: [{required: true, message: "Please select a concentration"}] })
            (
              <Select style={{ width: 360 }} filterOption={ this.filter }>
                { CONCENTRATIONS.map(el => <Option key={ el } value={ el }>{ el }</Option> ) }
              </Select>
            )}
          </FormItem>
          <FormItem {...this.formItemLayout} label=" " colon={ false }><Button type="primary" onClick={ this.handleSubmit }>Submit</Button></FormItem>
        </Form>
        </>
      );
    }
  }
);        
 
class UGConcentrationDeclaration extends AppComponent {
  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
        <Descriptions bordered title={ "Concentration Declaration request by " + record.created_by}>
          <Descriptions.Item label="Submitted" span={ 2 }>{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" span={ 2 }>{[ record.created_by + " ", <a key="email" href={"mailto:" + item.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" span={ 2 }>{ item.student.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 2 }>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(item.student.campus) }</Descriptions.Item>
          <Descriptions.Item label="Concentration" span={ 4 }>{ item.concentration }</Descriptions.Item>
        </Descriptions>
      );
   }
}

class UGConcentrationDeclarationTable extends WorkflowTable {
  get_columns = () => {
    return [{
        title: "Concentration",
        key: "concentration",
        width: 200,
        render: (text, record, idx) => record.record.concentration
      }
    ];
  }
}

class UGConcentrationDeclarationOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ug/concentrationdeclaration/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "BS/BA CS Concentration Declaration";
  }

  get_workflowtype = () => {
    return "ugconcentrationdeclaration";
  }

  get_name_plural = () => {
    return "BS/BA CS Concentration Declarations";
  }
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "UG" }, { text: "BS/BA Concentration Declaration" }];
  }
  
  get_form = (func) => {
    return <UGConcentrationDeclarationForm {...this.props} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <UGConcentrationDeclaration {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
    <p>
      Please use this survey to select the Khoury Concentration you wish to pursue and it will be added to your record.
      Students are expected to declare their concentration by the end of their 4th semester. Students may only have one concentration.
      If you have questions, please email your Academic Advisor. </p>
    );
  }  
}

export { UGConcentrationDeclarationOverview, UGConcentrationDeclaration, UGConcentrationDeclarationTable };