import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import moment from "moment-timezone";
import { renderStatus, text_max, oxford, add_brs } from "../../Utils";
import { WorkflowSubmit } from "../WorkflowSubmit";
import { WorkflowTable } from "../WorkflowTable";

import { Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Radio, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const BuyoutRequestForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      funds: [],
      error: null,
      semester: null,
      notes: null,
      admin_stipend: false,
    }
    
    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    componentDidMount() {
      this.reset();
    }
    
    reset = () => {
      const funds = [{ fund: null, percentage: 100 }];
      this.setState({ notes: "", funds: funds, others: {}, error: null}); 
      this.props.form.resetFields();
    }

    validate = () => {
      const { funds } = this.state;
      
      if (funds.reduce((r,a) => r+a.percentage, 0) != 100) {
        return "The percentages must sum to 100%.";
      }
      
      return null;
    }
    
    validateForm = () => {
      const result = this.validate();
      this.setState({ error: result });

      return result == null;
    }
    
    handleSubmit = () => {
      const { semesters, user } = this.props;
      const { others, fundings, funds, notes, admin_stipend, semester, type } = this.state;
      
      if (this.validateForm()) {
        const details = funds.map(f => { return { fund: f.fund, fraction: f.percentage/100 }});
        const data = { funds: details, notes: notes, semester: semester, user: user.id, steps: [], instructor: user.instructor, type: type };
        
        this.props.onSubmit(data, this.reset);
      }
    }
        
    render() {
      const { visible, onCancel, onCreate, form, item, sections, campus, semester, user } = this.props;
      const { getFieldDecorator } = form;
      const { error, funds, others } = this.state;

      const semester_list = this.semester_list().filter(el => (moment(el.startdate, "YYYY-MM-DD") > moment()) && ((el.code % 100 == 10) || (el.code % 100 == 30))).reverse();
      const fundlist = this.fund_list().filter(f => f.active && f.owners.find(fo => fo.owner == this.props.user.employee));

      return (
        <>
          <p>Please use the form below if you wish to request a buyout.</p>
          <p>There are two types of buyouts: (1) buyouts that are charged 16.6% of base salary and do not require Dean approval, and (2) buyouts that are charged at 25% of base salary and do require Dean approval. The latter are usually only granted if the faculty member is preparing or commiting significant time to a major (Center-level) grant.   TT faculty members can take category (1) buyouts to result in a teaching load of 2 or higher; any further buyouts are category (2).  FTNTT faculty members can take category (1) buyouts to result in a teaching load of 3 or higher; any further buyouts are category (2).  Note that buyouts are calculated *after* other teaching reductions (administrative, research, service, etc) are taken into account.</p>

          { error ? ( <div><Alert message={ error } type="error" showIcon /><p/></div> ) : null }
          <Form>
            <FormItem {...this.formItemLayout} label="Semester">
              {getFieldDecorator('semester', {
                rules: [{ required: true, message: 'Please select the semester you wish to buy out during.' }],
                onChange: (event) => { this.setState({ semester: event }) },
              })(<Select showSearch style={{ width: 180 }}>
                  { semester_list.map(el => <Option key={ el.id } value={ el.id }>{ this.print_semester(el.id) }</Option> ) }
                </Select>
              )}
            </FormItem>

            <FormItem {...this.formItemLayout} label="Type">
              {getFieldDecorator('kind', {
                rules: [{ required: true, message: 'Please select the kind of buyout you are requesting.' }],
                onChange: (event) => { this.setState({ type: event }) },
            })(<Select showSearch style={{ width: 180 }}>
                  <Option value="3 to 2">Category (1)</Option>
                  <Option value="2 to 1">Category (2)</Option>
                </Select>
              )}
            </FormItem>

            <FormItem {...this.formItemLayout} label="Notes" extra="You can optionally include any general notes here, but please use the fields below to enter how you wish your buyout to be funded.">
              {getFieldDecorator('notes', {
                initialValue: "",
                onChange: (event) => { this.setState({ notes: event.target.value }) },
              })(<TextArea rows={ 4 } />
              )}
            </FormItem>
            <Divider orientation="center" dashed={ true }>Funding source</Divider>

            <FormItem {...this.formItemLayout} label=" " colon={ false }>Please enter how you wish your buyout to be funded.  If you wish to fund from multiple indices, select Add Fund to add more indices.</FormItem>
            { funds.map((e, idx) => (
              <React.Fragment key={ "frag" + idx }>
                <FormItem {...this.formItemLayout} label={ "Fund " + (funds.length > 1 ? (idx+1) : "") } extra="Please select the fund you wish your buyout to come from.">
                  {getFieldDecorator("fund" + idx, {
                    rules: [{ required: true, message: 'Please select a fund.' }],
                    initialValue: e ? e.fund : null,
                    onChange: e => { funds[idx].fund = e; this.setState({ funds: funds }); this.validateForm(); },
                    })(<Select showSearch style={{ width: 540 }} filterOption={ this.filter }>
                      { fundlist.map(f => <Option key={ f.id } value={ f.id } disabled={ funds.find((fund, i) => i != idx && f.id == fund.fund) }>{ [text_max(this.print_full_fund(f.id), 40), " ("].concat(oxford(f.owners.map(o => this.print_employee(o.owner)))).concat([")"]) }</Option> )}
                    </Select>)}
                </FormItem>
                <FormItem {...this.formItemLayout} label={ "Percentage " + (funds.length > 1 ? (idx+1) : "") } extra="Enter the fraction of the buyout you wish to bill against this index (the total must sum to 100%).">
                {getFieldDecorator("percentage" + idx, {
                  rules: [{ required: true, message: 'Please enter the percentage of funding from this fund.' }],
                  initialValue: e ? e.percentage : 100,
                  onChange: e => { funds[idx].percentage = e; this.setState({ funds: funds }); this.validateForm(); },
                  })(<InputNumber min={1} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />)}
                </FormItem>
              </React.Fragment>
            )) }
            <FormItem {...this.formItemLayout} label=" " colon={ false }><Button icon="plus" onClick={ () => { this.setState({ funds: funds.concat([{fund: null, percentage: 1}]) }, this.validateForm); } }>Add Fund</Button></FormItem>
            <FormItem {...this.formItemLayout} label=" " colon={ false }><Button type="primary" onClick={ this.handleSubmit }>Submit</Button></FormItem>
          </Form>
        </>
      );
    }
  }
);

class BuyoutRequestSearchForm extends AppComponent {
  render() {
    const { form } = this.props;
    const semesters = this.semester_list().filter(s => s.speed == 1.0);
    
    return (
      <>
        <FormItem {...this.formItemLayout} label="Semester" extra="Optionally select the semester of the buyout request.">
          {form.getFieldDecorator("buyoutrequest__semester", {
            onChange: this.props.onSearchChange,
          })(<Select style={{ width: 200 }} allowClear>
            { semesters.map(s => <Option key={ s.id } value={ s.id }>{ this.print_semester(s.id) }</Option> )}
          </Select>)}
        </FormItem>
      </>
    )
  }
}

class BuyoutRequest extends AppComponent {
  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
      <Descriptions bordered title={ "Buyout request" }>
        <Descriptions.Item label="Requestor">{ this.link_full_instructor(item.instructor) }</Descriptions.Item>
        <Descriptions.Item label="Submitted">{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
        <Descriptions.Item label="Semester">{ this.print_semester(item.semester) }</Descriptions.Item>
        <Descriptions.Item label="Category">{ item.kind }</Descriptions.Item>
        <Descriptions.Item label="Notes" span={3}>{ item.notes ? item.notes : <i>No notes provided</i> }</Descriptions.Item>
        <Descriptions.Item label="Source" span={3}>
          { add_brs(item.funds.map((fd, idx) => <span key={ idx }>{ [<b>{ [(fd.fraction*100), "%"] }</b>, " from ", this.print_full_fund(fd.fund), " (", oxford(this.get_fund(fd.fund).owners.map(o => this.print_instructor(o.instructor))), ")"] }</span>)) }
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

class BuyoutRequestTable extends WorkflowTable {
  get_columns = () => {      
    return [{
      title: "Source",
      align: 'left',
      render: (text, record, idx) => record.record.funds.map(fd => ((fd.fraction*100) + "% from " + this.print_fund(fd.fund))).join(", "),
    }, {
      title: "Notes",
      align: 'left',
      render: (text, record, idx) => record.record.notes,
    }];
  }
}

class BuyoutRequestOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/load/buyout/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "Buyout Request";
  }

  get_workflowtype = () => {
    return "buyoutrequest";
  }

  get_name_plural = () => {
    return "Buyout Requests";
  }
  
  get_breadcrumbs = () => {
    return [{ link: "/faculty", text: "Faculty" }, { text: "Buyout" }];
  }
  
  get_form = (func) => {
    return <BuyoutRequestForm {...this.props} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <BuyoutRequest {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func(); }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    const { semesters, semester } = this.props;
        
    return (
      <div>
        <p>Faculty can use the form below to enter a new request to use research funds to buy out of teaching duties.  Approval of buyout requests requires the review of both the academic Associate Deans (to quantify the impact of the buyout on the teaching schedule) as well as the review of the finance team (to ensure that the source of the buyout funds has a sufficient balance and allows a buyout).</p>

        { alert ? <>{ alert }<p/></> : null }
      </div>
    );
  }  
}

export { BuyoutRequestOverview, BuyoutRequest, BuyoutRequestTable, BuyoutRequestSearchForm };