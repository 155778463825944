import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import { ElectivePetitionForm } from "../GenericForms";
import Transcript from "../../../Transcript";

import { Icon, Radio, Table, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;          
 
class MSElectivePetition extends AppComponent {
  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
        <Descriptions bordered title={ "MS Elective Petition submission by " + record.created_by}>
          <Descriptions.Item label="Submitted" span={ 2 }>{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" span={ 2 }>{[ record.created_by + " ", <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" span={ 2 }>{ item.student.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 2 }>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(item.student.campus) }</Descriptions.Item>
          <Descriptions.Item label="Semester" span={ 2 }>{ this.print_semester(item.elective_petition.semester) }</Descriptions.Item>
          { item.elective_petition.petition_type == "khoury" ? (
            <Descriptions.Item label="Khoury Course" span={ 2 }>{this.print_course(item.elective_petition.khoury_course)}</Descriptions.Item> 
          ): ([
            <Descriptions.Item label="Non-Khoury Course" span={ 2 }>{ item.elective_petition.non_khoury_course }</Descriptions.Item>,
            <Descriptions.Item label="Non-Khoury CRN" span={ 2 }>{ item.elective_petition.non_khoury_crn }</Descriptions.Item>,
            <Descriptions.Item label="Non-Khoury Credits" span={ 2 }>{ item.elective_petition.non_khoury_credits }</Descriptions.Item> 
          ])}
          <Descriptions.Item label="Rationale" span={ 4 }>{ item.elective_petition.rationale }</Descriptions.Item>
          <Descriptions.Item label="Attached Files" span={ 4 }>{[record.files.map(el => <a key={el.attachment} onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type +".pdf"}</a>) ]}</Descriptions.Item>
        </Descriptions>
      );
   }
}

class MSElectivePetitionOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ms/electivepetition/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "MS Elective Petition";
  }

  get_workflowtype = () => {
    return "mselectivepetition";
  }

  get_name_plural = () => {
    return "MS Elective Petitions";
  }
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "MS" }, { text: "Elective Petition" }];
  }
  
  get_form = (func) => {
    return <ElectivePetitionForm {...this.props} formType={"ms"} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <MSElectivePetition {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
    return (
      <p>	To request to take a course outside of your department as an elective. For more information, <strong><a href="https://www.khoury.northeastern.edu/information-for-overview/current-masters-and-certificate-students/masters-academic-support/masters-academic-procedures/" target="_blank">please visit here.</a></strong></p>
    );
  }  
}

export { MSElectivePetitionOverview, MSElectivePetition };