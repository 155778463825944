import React, { Component } from "react";
import AppComponent from "./AppComponent";
import Content from "./Content";

import { Button, Table, Alert, Form, List, Input, InputNumber, Spin, Divider, Select, Tabs, message, Modal } from "antd";

const FormItem = Form.Item;
const Option = Select.Option;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

const EmployeeCreateForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {
    state = {
      successNUIDs: [],
      failedNUIDs: [],
      invalidNUIDs: [],
      loading: false,
    };

    handleSubmit = () => {
      const form = this.props.form;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }

        this.setState({ loading: true });
        values.nuids = values.nuids.replace(/\s+/g, "");

        this.doPost(
          "/api/employee/create/",
          (data) => {
            form.resetFields();
            this.setState({
              successNUIDs: data.success || [],
              failedNUIDs: data.failed || [],
              invalidNUIDs: data.invalid || [],
              loading: false,
            });
          },
          JSON.stringify(values)
        );
      });
    };

    createResultDisplay = (results, header) => (
      results.length > 0 && (
        <>
          <Divider />
          <h3>{header}</h3>
          <List
            bordered
            dataSource={results}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </>
      )
    );

    render() {
      const { form } = this.props;
      const { failedNUIDs, successNUIDs, invalidNUIDs, loading } = this.state;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true,
      };

      return (
        <Content
          {...this.props}
          title={"Employee Create"}
          breadcrumbs={[
            { link: "/site-admin", text: "Admin" },
            { text: "Todos" },
          ]}
        >
          <p>
            Enter comma-separated NUIDs of employees for whom you would like to
            create records.
          </p>
          <p>
            If an employee is found in Workday, an Employee record and Person
            record will be created for them. If they are in the faculty group in WorkDay,
            a barebones faculty record will be created as well.
          </p>

          <Form>
            <FormItem
              {...formItemLayout}
              className="login-left"
              label="NUID(s)"
              extra={"Please enter the Employee NUID(s)"}
            >
              {getFieldDecorator("nuids", {
                rules: [
                  {
                    required: true,
                    message: "Please enter at least one NUID.",
                  },
                ],
              })(
                <TextArea
                  style={{ width: 500 }}
                  rows={4}
                  placeholder="Enter NUIDs separated by commas (e.g., 5556666, 00555777)"
                />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label=" " colon={false}>
              <>
                <Button
                  type="primary"
                  onClick={this.handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Spin /> : "Create"}
                </Button>
              </>
            </FormItem>
          </Form>
          {this.createResultDisplay(successNUIDs, "Successful NUIDs")}
          {this.createResultDisplay(failedNUIDs, "NUIDs not found in Workday:")}
          {this.createResultDisplay(invalidNUIDs, "Invalid NUIDs")}
        </Content>
      );
    }
  }
);

export default EmployeeCreateForm;
