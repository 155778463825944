import React, { Component } from "react";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './AppComponent';
import StatusComponent from './StatusComponent';
import { Status } from './StatusComponent';
import Content from './Content';
import Preference from './Preference';
import PreferenceNote from './PreferenceNote';
import { add_dividers, add_dividers_horiz, format_percent, format_decimal } from './Utils';

import { Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, PageHeader, message } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

class CommitteePreferences extends AppComponent {
  state = {
      endpoint_committee_prefs: "/api/preferences/committee/",
      committee_prefs: [],
      loading_committee_prefs: true,
      
      editable: true,
  }

  componentDidMount() {
    this.getPrefs();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getPrefs();
    }
  }

  getPrefs = () => {
    this.doGet(this.state.endpoint_committee_prefs + "?committee_year__year=" + this.get_year() + "&faculty=" + this.props.user.instructor,
                  data => this.setState({ committee_prefs: data, loading_committee_prefs: false }));
    this.setState({ editable: this.committeeyear_list().filter(cy => cy.year == this.get_year())?.length > 0 && 
                              this.committeeyear_list().find(cy => cy.year == this.get_year() && cy.members?.length > 0) == null});
  }

  render_pref = (record, loading, desc, pref) => {
    return ( <List key={ "list-" + record.id }
                   grid={ this.grid_preference }
                   loading={ loading  }
                   size="small"
                   dataSource={ [1] }
                   renderItem={ record => [(
                     <List.Item key={ "item" }>
                       { desc }
                     </List.Item> ), (
                     <List.Item key={ "pref" } style={{float: 'right'}}>
                       { pref }
                     </List.Item> )] } />
            );
  }


  render_committee_pref = (record) => {
    const { loading_committee_prefs, committee_prefs, editable } = this.state;
    
    const pref = committee_prefs.find(el => el.committee_year == record.id);
    return this.render_pref(record, loading_committee_prefs,
                            ( <List.Item.Meta
                                title={ this.print_committee(record.committee) }
                                description= { this.print_committee_description(record.committee) } /> ),
                            ( <Preference {...this.props}
                                key={ record.id + "-" + (pref ? pref.id : null)}
                                committee={ record.committee }
                                editable={ editable }
                                value={ pref }
                                onChange={ this.getPrefs }
                                endpoint="/api/preferences/committee/"
                                addNewData={ f => { f["committee_year"] = record.id; } } /> ));
  }

  render_notes = () => {
    const { editable } = this.state;

    return ( <PreferenceNote {...this.props}
                key={ "notes-" + this.get_year() }
                editable={ editable }
                endpoint="/api/preferences/committeenote/"
                initialArgs={ "?year=" + this.get_year() + "&faculty=" + this.props.user.instructor }
                addNewData={ f => f["year"] = this.get_year() } /> );
  }

  render() {
    const { loading_committee_prefs, editable } = this.state;

    const year = this.get_year();
    const committeeyear_list = this.committeeyear_list().filter(cy => cy.year == year);

    return (
      <Content {...this.props} title={ this.print_year(year) + " Committee Preferences" } breadcrumbs={ [{ link: "/faculty", text: "Faculty" }, { link: "/faculty/committees", text: "Committees" }, { text: "Preferences" }, { text: this.print_year(year) }] }>
        <p>Welcome to the new committee preferences form.  Please enter your committee preferences below; at the bottom is a free-form text input if you would like to provide more details.  All of your entries will be automatically saved; if an error occurs, a popup will be shown.</p>

        { !editable && (committeeyear_list?.length > 0) ? ( <Alert message="Year has already been planned" description="Committee assignments already exist; thus the preferences form below is read-only." type="warning" showIcon /> ) : null }

        <Divider orientation="left">Committee Preferences</Divider>
        <p>Below is the list of committees we expect to enpanel during { this.print_year(year) }.</p>
        { add_dividers_horiz(committeeyear_list.map(this.render_committee_pref)) }

        { committeeyear_list.length == 0 ? ( <Alert message="Year has not yet been planned" description="Committees have not yet been planned, so there are no preferences to submit just yet." type="warning" showIcon /> ) : null }

        <Divider orientation="left">Preference Notes</Divider>
        <p>Please include any additional notes below about your service duties for the { this.print_year(year) } year, for example if you expect to be on sabbatical or to have a service release. </p>
        { this.render_notes() }
      </Content>
    );
  }
}

export default CommitteePreferences;