import React, { Component } from "react";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './AppComponent';
import StatusComponent from './StatusComponent';
import { Status } from './StatusComponent';
import Preference from './Preference';
import { add_dividers, add_dividers_horiz, format_percent, format_decimal } from './Utils';

import { Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, message } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

class PreferenceNote extends StatusComponent {
  state = {
    note_id: null,
    note_notes: "",
    loading_notes: true,
    note_timer: null,
  }

  componentDidMount() {
    super.componentDidMount();
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getInitialStatus = () => {
    return this.state.note_id ? this.STATUS_GOOD : this.STATUS_NONE;
  }

  onChange = (response) => {
    this.setState({ note_id: response ? response.id : null });
  }

  getData = () => {
    this.setState({ loading_notes: true });
    this.doGet(this.props.endpoint + this.props.initialArgs,
                  data => this.setState({ note_id: data && data?.length > 0 ? data[0].id : null, note_notes: data && data?.length > 0 ? data[0].notes : "", loading_notes: false, status: data && data?.length > 0 ? this.STATUS_GOOD : this.STATUS_NONE }));
  }

  getRequest = (new_value) => {
    const { endpoint, addNewData } = this.props;
    const { note_id } = this.state;

    const data = {"notes": new_value};

    if (note_id) {
      return { method: data.notes ? "PATCH" : "DELETE",
               endpoint: endpoint + note_id + "/",
               body: JSON.stringify(data),
               onChange: this.onChange };
    } else {
      addNewData(data);
      return { method: "POST",
               endpoint: endpoint,
               body: JSON.stringify(data),
               onChange: this.onChange };
    }
  }

  noteChanged = (new_value) => {
    const { note_timer, note_notes } = this.state;

    if (note_timer) {
      clearTimeout(note_timer);
    }

    if (new_value != note_notes & (/\S/.test(new_value) || new_value.length == 0)) {
      this.setState({
        note_timer: setTimeout(() => this.setState({ note_timer: null}, () => this.setValue(new_value)), 2000),
        note_notes: new_value,
        status: this.STATUS_PENDING,
      });
    }
  }

  render() {
    const { editable } = this.props;
    const { status, note_notes } = this.state;

    return ( <React.Fragment>
              <Form layout="inline">
                <FormItem label="Uploaded:" style={{float: 'right'}}><Status status={ status } /></FormItem>
              </Form>
              <TextArea rows={4} disabled={ !editable } onChange={ e => this.noteChanged(e.target.value) } value={ note_notes } />
            </React.Fragment>
           );
  }
}

export default PreferenceNote;
