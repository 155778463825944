import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";
import StudentCreateForm from '../../../Student/StudentForms';

import { Icon, Radio, Table, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions, Spin, Checkbox, DatePicker } from "antd";
import { oxford } from "../../../Utils";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const MSIncompleteGradeContractForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    dateFormat = 'YYYY-MM-DD';

    state = {
      endpoint_students: "/api/student/",
      students: [],
      loading_students: true,
      student: "",
      add_student: false,

      endpoint_sections: "/api/schedule/",
      sections: [],
      loading_sections: true,
    };

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({ student: "", students: [], sections: [], add_student: false });
      this.props.form.resetFields();
    }

    handleSubmit = () => {
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (!err) {
          values["deadline"] = moment(values["deadline"]).format('YYYY-MM-DD');
          if ("additional_support_mode" in values) {
            values["additional_support_mode"] = values["additional_support_mode"].map(el => ({ mode: el }));
          }
          if (values["section"] == "other") {
            delete values["section"]
          }
          if ("faculty_student_mode" in values) {
            values["faculty_student_mode"] = values["faculty_student_mode"].map(el => ({ mode: el }));
          }
          this.props.onSubmit(values, this.reset());
        } else {
          message.error(err);
        }
      });
    };

    getSections = (semester) => {
      if (semester) {
        this.doGet(this.state.endpoint_sections + "?semester=" + semester + "&deleted=False", data => { this.setState({ sections: data, loading_sections: false }) });
      }
    }

    loadStudents = (v) => {
      const { endpoint_students } = this.state;
      if (v) {
        this.setState({ loading_students: true, students: [] }, () => this.doGet(endpoint_students + (v ? "?search=" + v : ""), data => this.setState({ students: data, loading_students: false })));
      }
    }

    closeAddStudent = (data) => {
      this.setState({ student: data, add_student: false });
    }

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const { students, student, loading_students, add_student, sections } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };
      return (
        <>
          <p>If you wish to amend a previously-submitted MS grade incomplete grade contract, please simply submit a new contract. The Khoury graduate staff will use the most recent one that you submit.</p>
          <div style={{ marginTop: "15px" }}>
            <Form>
              <FormItem {...formItemLayout} label="Semester Course was Taken" extra={"Please input a semester"}>
                {getFieldDecorator("semester", {
                  rules: [{ required: true, message: "Please select a semester." }], onChange: (event) => { this.getSections(event) }
                })
                  (
                    <Select showSearch style={{ width: 360 }} filterOption={this.filter} allowClear={true} >
                      {this.semester_list().filter(el => moment(el.startdate).isBefore(moment().format("YYYY-MM-DD"))).map(el => <Option key={el.id} value={el.id}>{this.print_semester(el.id)}</Option>)}
                    </Select>
                  )}
              </FormItem>
              <FormItem {...formItemLayout} label="Section" extra="Please select the section.">
                {getFieldDecorator("section", { rules: [{ required: true, message: "Select a section" }] })
                  (
                    <Select showSearch style={{ width: 360 }} filterOption={this.filter} disabled={sections.length == 0}>
                      {sections.filter(el => this.get_course(el.course).number >= 5000).map(el => (
                        <Option key={el.course} value={el.id}>{this.print_section(el) + " - " + this.print_instructor_list(el.instructors)}</Option>

                      ))}
                      <Option key={"other"} value={"other"}>Other</Option>
                    </Select>
                  )}
              </FormItem>
              {form.getFieldValue("section") == "other" && (

                <FormItem {...formItemLayout} label="Course" extra="Please select the course.">
                  {getFieldDecorator("course", { rules: [{ required: true, message: "Select a course" }] })
                    (
                      <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                        {this.course_list().filter(el => el.number >= 5000 && !sections.some(sec => sec.course == el.id) && !el.deprecated).map(el => (
                          <Option key={el.id} value={el.id}>{this.print_course(el.id)}</Option>
                        ))}
                      </Select>
                    )}
                </FormItem>
              )}
              <FormItem {...formItemLayout} label={form.getFieldValue("section") == "other" ? "Instructor" : "New Instructor (if different)"} extra="Please select a instructor">
                {getFieldDecorator("instructor", { rules: [{ required: form.getFieldValue("section") == "other", message: "Please an instructor" }], initialValue: "" })
                  (
                    <Select showSearch style={{ width: 360 }} filterOption={this.filter} allowClear={true} >
                      {this.instructor_list().map(el => <Option key={el.id} value={el.id}>{this.print_full_instructor(el.id)}</Option>)}
                    </Select>
                  )}
              </FormItem>
              <FormItem {...formItemLayout} label="Reasoning" extra={"Please provide a reason for requesting incomplete grade"}>
                {getFieldDecorator("rationale", {
                  rules: [{ required: true, message: "Please provide a reason." }], initialValue: ""
                })
                  (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>
              <Divider orientation="left">Required course work to be made up</Divider>
              <p>Required course work to be made up (write the total number).</p>
              <FormItem {...formItemLayout} label="# of Assignments">
                {getFieldDecorator("coursework_assignments", {
                  rules: [{ required: true, message: "Please input a number." }], initialValue: ""
                })
                  (<InputNumber addonBefore={"# of Assignments"} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="# of Quizzes">
                {getFieldDecorator("coursework_quizzes", {
                  rules: [{ required: true, message: "Please input a number." }], initialValue: ""
                })
                  (<InputNumber addonBefore={"# of Quizzes"} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="# of Exams">
                {getFieldDecorator("coursework_exams", {
                  rules: [{ required: true, message: "Please input a number." }], initialValue: ""
                })
                  (<InputNumber addonBefore={"# of Exams"} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="# of Projects">
                {getFieldDecorator("coursework_projects", {
                  rules: [{ required: true, message: "Please input a number." }], initialValue: ""
                })
                  (<InputNumber addonBefore={"# of Projects"} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Other">
                {getFieldDecorator("coursework_other", {
                  rules: [{ required: false }], initialValue: ""
                })
                  (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="How will the student make up this work?" extra={"Please provide a short answer. For instance, The student will be expected to fulfill the missing coursework by taking the final exam at a rescheduled date."} colon={false}>
                {getFieldDecorator("rational_makeup_work", {
                  rules: [{ required: true, message: "Please provide a short answer." }], initialValue: ""
                })
                  (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>
              <Divider orientation="left">Advisor/Faculty Discussion</Divider>
              <p>This section ensures that the student has met with their faculty/advisor and discussed a specific plan. Advisors will use the following points to help determine expectations.</p>
              <FormItem {...formItemLayout} label={"Eligibility requirements"} extra={<><ul>
                <li>A passing grade in the course thus far</li>
                <li>At least 50% of the course completed</li>
                <li>Personal extenuating circumstance(s) which impact student’s ability to complete without accommodation</li>
              </ul></>}>
                {getFieldDecorator("eligibility_requirements", { valuePropName: 'checked', rules: [{ required: true, message: "Does this request meet the following requirements?" }] })
                  (
                    <Checkbox>Does this request meet the following requirements?</Checkbox>
                  )}
              </FormItem>
              <FormItem {...formItemLayout} label={"Faculty-Student"}>
                {getFieldDecorator("faculty_student", { valuePropName: 'checked', rules: [{ required: false, message: "Please select if check-ins are required." }] })
                  (
                    <Checkbox>Will the student be expected to check-in with the instructor and/or TA?</Checkbox>
                  )}
              </FormItem>
              {form.getFieldValue("faculty_student") && (
                <>
                  <FormItem {...formItemLayout} label={"Mode"}>
                    {getFieldDecorator("faculty_student_mode", { rules: [{ required: true, message: "Please select a mode." }] })
                      (
                        <Select style={{ width: 360 }} allowClear={true} mode="multiple">
                          {["In-person", "TA Only", "Virtual", "Email"].map(el => <Option key={el} value={el}>{el}</Option>)}
                        </Select>
                      )}
                  </FormItem>
                  <FormItem {...formItemLayout} label={"Frequency"}>
                    {getFieldDecorator("faculty_student_frequency", { rules: [{ required: true, message: "Please select a frequency." }] })
                      (
                        <Select style={{ width: 360 }} allowClear={true}>
                          {["Weekly", "TA Only", "Monthly", "Other"].map(el => <Option key={el} value={el}>{el}</Option>)}
                        </Select>
                      )}
                  </FormItem>
                </>
              )}
              <FormItem {...formItemLayout} label={"Additional support"}>
                {getFieldDecorator("additional_support", { valuePropName: 'checked', rules: [{ required: false, message: "Please select if TA assistance is available." }] })
                  (
                    <Checkbox>Did the instructor offer TA assistance?</Checkbox>
                  )}
              </FormItem>
              {form.getFieldValue("additional_support") && (
                <>
                  <FormItem {...formItemLayout} label={"Mode"}>
                    {getFieldDecorator("additional_support_mode", { rules: [{ required: true, message: "Please select a mode." }] })
                      (
                        <Select style={{ width: 360 }} allowClear={true} mode="multiple">
                          {["In-person", "Virtual", "Email"].map(el => <Option key={el} value={el}>{el}</Option>)}
                        </Select>
                      )}
                  </FormItem>
                  <FormItem {...formItemLayout} label={"Frequency"}>
                    {getFieldDecorator("additional_support_frequency", { rules: [{ required: true, message: "Please select a frequency." }] })
                      (
                        <Select style={{ width: 360 }} allowClear={true}>
                          {["Weekly", "Monthly", "Other"].map(el => <Option key={el} value={el}>{el}</Option>)}
                        </Select>
                      )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="# of Hours" extra={"How many hours per week can the student seek TA assistance?"}>
                    {getFieldDecorator("additional_support_ta_support", { rules: [{ required: true, message: "" }] })
                      (
                        <InputNumber addonBefore={"# of Hours"} />
                      )}
                  </FormItem>
                </>
              )}
              <Divider orientation="left">Timeframe</Divider>
              <div style={{ xs: { span: 24 }, sm: { span: 16 } }}>
                <p><b>Note:</b> These are suggested guidelines and can be used as discussion points when deciding the deadline with the instructor.</p>
                <ul>
                  <li>A week</li>
                  <ul>
                    <li>If a student has one assignment/quiz/exam to complete, and the personal extenuating circumstance prevented them from completing the work during the term.</li>
                  </ul>
                  <li>One term</li>
                  <ul>
                    <li>If the student has 25-50% of the course to complete, and the personal extenuating circumstance prevented them from completing the work during the term.</li>
                  </ul>
                  <li>Two terms</li>
                  <ul>
                    <li>If a student is expected to take a leave of absence or the instructor is not teaching the following term.</li>
                    <li>Students on F1 visas that meet the eligibility requirements, and may have 25-50% of the course to complete, since they require to be full-time and may need to spread out the workload.</li>
                  </ul>
                </ul>
              </div>
              <FormItem {...formItemLayout} label={"Deadline"}>
                {getFieldDecorator("deadline", {
                  rules: [{ required: true, message: "Please select a deadline." }]
                })
                  (
                    <DatePicker format={this.dateFormat} />
                  )}
              </FormItem>
              <FormItem {...this.formItemLayout} label=" " colon={false}>
                <Button type="primary" onClick={() => this.handleSubmit()}>Submit</Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);

class MSIncompleteGradeContract extends AppComponent {
  render() {
    const { record } = this.props;
    const item = record.record;

    return (
      <Descriptions bordered title={"MS Incomplete Grade Contract submission by " + record.created_by}>
        <Descriptions.Item label="Submitted" span={2}>{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Requestor" span={2}>{[record.created_by + " ", <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a>]}</Descriptions.Item>
        <Descriptions.Item label="Student" span={2}>{item.student.firstname + " " + item.student.lastname}</Descriptions.Item>
        <Descriptions.Item label="NUID" span={2}>{item.student.nuid}</Descriptions.Item>
        {this.permission("can", "staff") &&
          <Descriptions.Item label="" span={2}>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item>
        }
        <Descriptions.Item label="Semester" span={2}>{this.print_semester(item.semester)}</Descriptions.Item>
        {item.section ? (
          <>
            <Descriptions.Item label="Section" span={2}>{this.print_section(item.section)}</Descriptions.Item>
            <Descriptions.Item label="Instructor (Original)" span={2}>{this.print_instructor_list(item.section.instructors)}</Descriptions.Item>
            {item.instructor &&
              <>
                <Descriptions.Item label="New Instructor" span={2}>{this.print_instructor(item.instructor)}</Descriptions.Item>
              </>
            }
          </>
        ) : (
          <>
            <Descriptions.Item label="Course" span={2}>{this.print_course(item.course)}</Descriptions.Item>
            <Descriptions.Item label="Instructor" span={2}>{this.print_instructor(item.instructor)}</Descriptions.Item>
          </>

        )}
        <Descriptions.Item label="Deadline" span={2}>{moment(item.deadline).format("MMM DD, YYYY")}</Descriptions.Item>
        <Descriptions.Item label="Reasoning" span={4}>{item.rationale}</Descriptions.Item>
        <Descriptions.Item label="" span={4}><strong>Coursework Information</strong></Descriptions.Item>,
        <Descriptions.Item label="Assignments" span={2}>{item.coursework_assignments}</Descriptions.Item>
        <Descriptions.Item label="Quizzes" span={2}>{item.coursework_quizzes}</Descriptions.Item>
        <Descriptions.Item label="Exams" span={2}>{item.coursework_exams}</Descriptions.Item>
        <Descriptions.Item label="Projects" span={2}>{item.coursework_projects}</Descriptions.Item>
        <Descriptions.Item label="Other Work" span={2}>{item.coursework_other}</Descriptions.Item>
        <Descriptions.Item label="" span={2}></Descriptions.Item>
        <Descriptions.Item label="How will the student make up this work?" span={4}>{item.rational_makeup_work}</Descriptions.Item>

        {item.faculty_student &&
          <>
            <Descriptions.Item label="" span={4}><strong>Faculty Student Communication</strong></Descriptions.Item>,
            <Descriptions.Item label="Mode" span={2}>{oxford(item.faculty_student_mode.map(el => el.mode))}</Descriptions.Item>
            <Descriptions.Item label="Frequency" span={2}>{item.faculty_student_frequency}</Descriptions.Item>
          </>
        }

        {item.additional_support &&
          <>
            <Descriptions.Item label="" span={4}><strong>Additional Support</strong></Descriptions.Item>,
            <Descriptions.Item label="Mode" span={2}>{oxford(item.additional_support_mode.map(el => el.mode))}</Descriptions.Item>
            <Descriptions.Item label="Frequency" span={2}>{item.additional_support_frequency}</Descriptions.Item>
            {item.additional_support_ta_support &&
              <Descriptions.Item label="Ta support per week" span={2}>{item.additional_support_ta_support}</Descriptions.Item>
            }
          </>
        }

      </Descriptions>

    );
  }
}

class MSIncompleteGradeContractTable extends WorkflowTable {
  get_columns = () => {
    return [
      {
        title: "Semester",
        key: "semester",
        width: 120,
        render: (text, record, idx) => this.print_semester(record.record.semester),
      },
      {
        title: "Course",
        key: "course",
        width: 120,
        render: (text, record, idx) => record.record.section ? this.print_section(record.record.section) : this.print_course(record.record.course),
      }
    ];
  }
}

class MSIncompleteGradeContractOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ms/incompletegradecontract/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "MS Incomplete Grade Contract";
  }

  get_workflowtype = () => {
    return "msincompletegradecontract";
  }

  get_name_plural = () => {
    return "MS Incomplete Grade Contracts";
  }

  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "MS" }, { text: "Incomplete Grade Contracts" }];
  }

  get_form = (func) => {
    return <MSIncompleteGradeContractForm {...this.props} formType={"ms"} onSubmit={this.submit_form} />;
  }

  get_record_view = (record) => {
    return <MSIncompleteGradeContract {...this.props} record={record} />
  }

  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }


  get_overview_text = () => {
    return (
      <>
        <p>Khoury College graduate students may request an incomplete grade if there is a personal extenuating circumstance that has prevented them from completing a requirement in the course and thus will have a substantial impact on their final grade. An I grade must be resolved within one calendar year; however, an instructor has discretion to create an earlier deadline.</p>

        <p><b>Incomplete grade eligibility requirements:</b></p>
        <ul>
          <li>Student has met with their current academic advisor and instructor to discuss their unique circumstance</li>
          <li>Student must have a passing grade in the course thus far</li>
          <li>Student must have completed at least 50% of the course.</li>
          <li>Student must have a situation that is deemed by the advisor/instructor as a personal extenuating circumstance</li>
        </ul>
        <p>If a student is found to misrepresent their circumstances in the intercampus transfer process, they will be referred to Khoury College’s Academic Integrity Committee.</p>
      </>
    );
  }
}

export { MSIncompleteGradeContractOverview, MSIncompleteGradeContract, MSIncompleteGradeContractTable };