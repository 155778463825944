import React from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import { format_nuid } from "../../Utils";
import { Form, Table, Icon, Menu, Dropdown, Switch, Modal, Input, Typography, Alert, Button, Divider, Radio, Select } from "antd";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import { string } from "prop-types";
import GenericExport from "./GenericExport";


class PhDPaperRequirementExport extends GenericExport {
    renderAdvisors = (current_code, record) => {
        const current_advisors = record.advisors.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
        return current_advisors ? oxford(current_advisors.advisors.map(e => this.print_instructor(e))) : null; 
    }

    createCSV = (data) => {
        let returnData = [];

        data.forEach(el => {
            let record = el.record;
            let student = record.phdstudent;
            let rowObject = {
                "Created at": moment(el.created_at).format("MMM DD, YYYY HH:mm"),
                "Workflow ID": el.id,
                "Student Firstname": student.firstname_preferred ? student.firstname_preferred : student.firstname,
                "Student Lastname": student.lastname,
                "Student NUID": student.nuid,
                "Student Campus": this.print_campus(student.campus),
                "Email": el.email,
                "matriculated": this.print_semester(student.matriculated),
                "degree": this.print_degree(student.degree),
                "Advisor (at submission)": this.renderAdvisors(this.find_semester(record.created_at), student),
                "advisor_statement": record?.advisor_statement?.replace(/"/g, '""'),
                "presented_at_conf": record.presented_at_conf,
                "published_where": record?.published_where?.replace(/"/g, '""'),
            }

            el.steps.forEach( step => {
                    rowObject[step.title] = step.decision != "Unassigned" ? step.decision : "";
            })
            returnData.push(rowObject)
        });
        return returnData;
    }

    render() {

        const { disabled } = this.props;
        const { data } = this.state;

        return (
            <React.Fragment>
                {
                    !disabled && data ?
                        (
                            <CSVLink filename={"PhDPaperRequirement-Data-Export_" + moment().format("DD-MM-YYYY") + ".csv"} data={this.createCSV(data)}>
                                <Button type="primary">
                                    Download
                                </Button>
                            </CSVLink>
                        ) : (
                            <Button type="primary" loading={true}>Download</Button>
                        )
                }
            </React.Fragment>
        );
    }
}

export default PhDPaperRequirementExport;
