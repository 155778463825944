import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './../AppComponent';
import StatusComponent from './../StatusComponent';
import { Status } from './../StatusComponent';
import Preference from './../Preference';
import Content from './../Content';
import SectionTable from './../SectionTable';
import CustomTabs from './../CustomTabs';
import PhDStudentFundingTable from './PhDStudentFundingTable';
import { add_dividers, add_dividers_horiz, format_percent, format_decimal, is_assigned, getTAStatus, copy, getSemesterWeeks, oxford, format_nuid, get_check_nocheck, get_check, get_nonecheck, get_nocheck, Spin, SSOAllowAccess } from './../Utils';

import { Popconfirm, Button, Modal, PageHeader, Timeline, Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, InputNumber, Icon, Menu, Dropdown, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, Tabs, message } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

const MILESTONES = [{ title: "Course Requirement", days: 730 },
{ title: "Paper Requirement", days: 940 },
{ title: "Candidacy", days: 1201 },
{ title: "Proposal", days: 1201 },
{ title: "Teaching Assistant", days: 2034 },
{ title: "Defense", days: 2034 }];


const TRACKS = [{ key: "IND", value: "Industry" }];

class PhDStudentTable extends AppComponent {
  getCurrentAdvisors = (current_code, record, download = false) => {
    const current_advisors = record.advisors.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    return current_advisors ? oxford(current_advisors.advisors.map(e => this.print_instructor(e))) : download ? "" : <i>None</i>;
  }

  getCurrentCampus = (current_code, record, download = false) => {
    const current_campus = record.campuses.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    return current_campus ? this.print_campus(current_campus.campus) : download ? "" : <i>None</i>;
  }

  getCurrentDesk = (current_code, record, download = false) => {
    const desk_id = record.desks.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    const room = desk_id ? this.room_list().find(r => r.desks.find(d => d.id == desk_id.desk)) : null;
    const desk = room ? room.desks.find(d => d.id == desk_id.desk) : null;
    return desk ? [download ? this.print_room(room.id) : this.link_room(room.id), " desk ", desk.number] : download ? "" : null;
  }

  printName = (record) => this.print_full_student_reverse(record);

  render() {
    const { semester, semesters, loading, students, onSelect, hide_fields, simple, tableName } = this.props;

    const current_code = this.get_semester(semesters.length > 1 ? semesters[1] : semesters[0]).code;

    const exportFilename = tableName ? "-" + tableName.replace(/\s/g, '').toLowerCase() : "";

    var columns = [
      {
        title: 'Name',
        key: 'name',
        width: 140,
        render: (text, record, idx) => [onSelect ? <a key="name" onClick={() => onSelect(record)}>{[this.printName(record)]}</a> : <Link key="name" to={this.getLink("/employee/phd/" + record.id)}>{[this.print_full_student_reverse(record)]}</Link>, " ", record.email ? <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> : null],
        sorter: (a, b) => this.printName(a).localeCompare(this.printName(b)),
        download: (record) => this.printName(record),
      }, {
        title: 'NUID',
        key: 'nuid',
        width: 90,
        align: 'right',
        render: (text, record, idx) => format_nuid(record.nuid),
        sorter: (a, b) => a.nuid - b.nuid,
        download: (record) => format_nuid(record.nuid),
      }, {
        title: 'Login',
        key: 'login',
        render: (text, record, idx) => get_check_nocheck(record.sso_username),
        download: (record) => record.sso_username ? "Yes" : "No",
      },{
        title: 'Degree',
        key: 'degree',
        width: 90,
        render: (text, record, idx) => this.print_degree(record.degree),
        download: (record) => this.print_degree(record.degree),
      }, {
        title: semester + ' Advisors',
        key: 'advisors',
        width: 140,
        render: (text, record, idx) => this.getCurrentAdvisors(current_code, record),
        download: (record) => this.getCurrentAdvisors(current_code, record, true),
      // }, {
      //   title: semester + ' Campus',
      //   key: 'campus',
      //   render: (text, record, idx) => this.getCurrentCampus(current_code, record),
      //   download: (record) => this.getCurrentCampus(current_code, record, true),
      }, {
        title: semester + ' Desk',
        key: 'room',
        render: (text, record, idx) => this.getCurrentDesk(current_code, record),
        download: (record) => this.getCurrentDesk(current_code, record, true),
      }];

    if (!simple) {
      columns = columns.concat([{
        title: 'TA total',
        key: 'ta_total',
        width: 65,
        render: (text, record, idx) => record.ta.length,
        sorter: (a, b) => a.ta.length - b.ta.length,
        download: (record) => record.ta.length,
      }]);

      columns = columns.concat(MILESTONES.map(m => {
        return {
          title: m.title.replace("Requirement", "").replace("Teaching Assistant", "TA").replace("Candidacy", "Candid."),
          key: m.title, width: 90,
          render: (text, record, idx) => get_check_nocheck(record.milestones.find(e => e.milestone == m.title && e.approved_at) || (m.title == "Teaching Assistant" && record.ta?.length > 0) || (m.title == "Paper Requirement" && record.paper_requirement)),
          sorter: (a, b) => {
            const fa = a.milestones.find(e => e.milestone == m.title && e.approved_at) || (m.title == "Teaching Assistant" && a.ta?.length > 0) || (m.title == "Paper Requirement" && a.paper_requirement);
            const fb = b.milestones.find(e => e.milestone == m.title && e.approved_at) || (m.title == "Teaching Assistant" && b.ta?.length > 0) || (m.title == "Paper Requirement" && b.paper_requirement);
            return fa && fb ? 0 : fa ? 1 : fb ? -1 : 0;
          },
          download: (record) => record.milestones.find(e => e.milestone == m.title && e.approved_at) || (m.title == "Teaching Assistant" && record.ta?.length > 0) || (m.title == "Paper Requirement" && record.paper_requirement) ? "True" : "False",
        };
      }));

      columns = columns.concat([{
        title: 'Matric.',
        key: 'matriculated',
        width: 75,
        render: (text, record, idx) => this.print_semester(record.matriculated),
        sorter: (a, b) => this.semester_sort(this.get_semester(a.matriculated), this.get_semester(b.matriculated)),
        download: (record) => this.print_semester(record.matriculated),
      }, {
        title: 'Left',
        key: 'left',
        width: 75,
        render: (text, record, idx) => record.left ? this.print_semester(record.left) : null,
        download: (record) => record.left ? this.print_semester(record.left) : "",
      }, {
        title: 'Track',
        key: 'track',
        width: 80,
        render: (text, record, idx) => record.track ? TRACKS.find(track => track.key == record.track)?.value : "None",
        download: (record) => record.track ? TRACKS.find(track => track.key == record.track)?.value : "None",
      }, {
        title: semester + ' Funding',
        key: 'funding',
        render: (text, record, idx) => get_check_nocheck(record.funding_proposal.find(r => semesters.includes(r.semester)) != null),
        sorter: (a, b) => {
          const fa = a.funding_proposal.find(r => semesters.includes(r.semester));
          const fb = b.funding_proposal.find(r => semesters.includes(r.semester));
          return fa && fb ? 0 : fa ? 1 : fb ? -1 : 0;
        },
        download: (record) => record.funding_proposal.find(r => semesters.includes(r.semester)) != null ? "True" : "False",
      }, {
        title: semester + ' Self Review',
        key: 'selfreview',
        render: (text, record, idx) => get_check_nocheck(record.self_reviews.find(r => semesters.includes(r.semester)) != null),
        sorter: (a, b) => {
          const fa = a.self_reviews.find(r => semesters.includes(r.semester));
          const fb = b.self_reviews.find(r => semesters.includes(r.semester));
          return fa && fb ? 0 : fa ? 1 : fb ? -1 : 0;
        },
        download: (record) => record.self_reviews.find(r => semesters.includes(r.semester)) != null ? "True" : "False",
      }, {
        title: semester + ' Adv. Review',
        key: 'advisorreview',
        render: (text, record, idx) => get_check_nocheck(record.advisor_reviews.find(r => semesters.includes(r.semester)) != null),
        sorter: (a, b) => {
          const fa = a.advisor_reviews.find(r => semesters.includes(r.semester));
          const fb = b.advisor_reviews.find(r => semesters.includes(r.semester));
          return fa && fb ? 0 : fa ? 1 : fb ? -1 : 0;
        },
        download: (record) => record.advisor_reviews.find(r => semesters.includes(r.semester)) != null ? "True" : "False",
      }, {
        title: semester + ' Status',
        key: 'advreview',
        render: (text, record, idx) => record.advisor_reviews.filter(r => semesters.includes(r.semester)).map(r => r.status).join(", "),
        download: (record) => record.advisor_reviews.filter(r => semesters.includes(r.semester)).map(r => r.status).join(", ") ? record.advisor_reviews.filter(r => semesters.includes(r.semester)).map(r => r.status).join(", ") : null,
      }, {
        title: semester + ' Review',
        key: 'review',
        render: (text, record, idx) => record.reviews.find(r => semesters.includes(r.semester)) ? record.reviews.find(r => semesters.includes(r.semester)).rating : null,
        download: (record) => record.reviews.find(r => semesters.includes(r.semester)) ? record.reviews.find(r => semesters.includes(r.semester)).rating : null,
      }]);
    }

    if (hide_fields) {
      columns = columns.filter(c => !hide_fields.includes(c.key));
    }

    return (
      <>
        <div style={{ padding: "5px" }}>
          <CSVLink filename={"phdstudent" + exportFilename + ".csv"} data={this.getCsvData(students, columns)}><Button icon="download">Download</Button></CSVLink>
        </div>
        <Table {...this.props} tableLayout='auto' dataSource={students} columns={columns} loading={loading} scroll={{ x: 1200 }} bordered={false} pagination={false} size="small" sticky rowKey="nuid" key={"table"} />
      </>
    );
  }
}

class PhDStudentList extends AppComponent {
  state = {
    endpoint_students: "/api/phd/",
    students: [],
    loading_students: true,

    seleted_student: null,
    group: "area",
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_students,
      data => this.setState({ students: data, loading_students: false }));
  }

  clearSelected = () => {
    this.setState({ selected_student: null })
  }

  getTable = (title, students) => {
    const { loading_students, windowHeight } = this.state;
    const tableName = title

    return (
      <React.Fragment key={title}>
        <Divider orientation="left">{title}</Divider>
        <PhDStudentTable {...this.props} windowHeight={windowHeight} tableName={tableName} students={students} loading={loading_students} onSelect={student => this.setState({ selected_student: student })} />
      </React.Fragment>
    );
  }

  getCurrentAdvisors = (current_code, record) => {
    const current_advisors = record.advisors.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    return current_advisors ? oxford(current_advisors.advisors.map(e => this.print_instructor(e))) : <i>None</i>;
  }

  getCurrentCampus = (current_code, record) => {
    const current_campus = record.campuses.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    return current_campus ? this.print_campus(current_campus.campus) : <i>None</i>;
  }

  render() {
    const { semester, semesters } = this.props;
    const { students, loading_students, selected_student, group } = this.state;

    const sem = this.get_semester(this.props.semesters.length == 1 ? this.props.semesters[0] : this.props.semesters[1]);
    const mystudents = students.filter(ah => (ah.left == null || this.get_semester(ah.left).code >= sem.code) && this.get_semester(ah.matriculated).code <= sem.code);

    const other_students = group == "area" ?
      mystudents.filter(s => s.advisors.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code)) == null ||
        s.advisors.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code)).advisors.map(i => this.get_instructor(i).areas).flat().length == 0)
      : group == "advisor" ?
        mystudents.filter(s => s.advisors.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code)) == null)
        : group == "campus" ?
          mystudents.filter(s => s.campuses.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code)) == null)
          : group == "degree" ?
            mystudents.filter(s => s.degree == null)
            : group == "room" ?
              mystudents.filter(s => s.desks.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code)) == null)
              : group == "track" ?
                mystudents.filter(s => s.track == null)
                : [];

    const all_advisors = new Set(mystudents.map(s => s.advisors.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code))).filter(sa => sa != null).map(sa => sa.advisors).flat());

    const all_campuses = new Set(mystudents.map(s => s.campuses.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code))).filter(sa => sa != null).map(sa => sa.campus).flat());

    const params = { params: { phdstudent_id: selected_student ? selected_student.id : null }, };

    return (
      <Content {...this.props} title={"PhD Students"} breadcrumbs={[{ link: "/employee", text: "Employee" }, { text: "PhD" }, { text: "List" }]}>
        <p>Welcome to the Khoury PhD student overview page.  This page lists all current PhD students (i.e., those who have not left the program).  The students are divided up by area, which is calculated based on the advisor(s) of the student.</p>

        <p><b>Group by:</b> <Radio.Group buttonStyle="solid" onChange={e => this.setState({ group: e.target.value })} value={group}>
          <Radio.Button value="none">None</Radio.Button>
          <Radio.Button value="area">Area</Radio.Button>
          <Radio.Button value="advisor">Advisor</Radio.Button>
          <Radio.Button value="campus">Campus</Radio.Button>
          <Radio.Button value="degree">Degree</Radio.Button>
          <Radio.Button value="room">Room</Radio.Button>
          <Radio.Button value="track">Track</Radio.Button>
        </Radio.Group></p>

        {/* { group == "area" ? 
          this.area_list().map(a => {
            const these_students = mystudents.filter(s => s.areas.includes(a.id));
            return this.getTable(a.name, these_students); */}

        {group == "area" ?
          this.area_list().map(a => {
            const these_students = mystudents.filter(s => {
              const this_advisors = s.advisors.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code));
              return this_advisors && (this_advisors.advisors.find(i => this.get_instructor(i).areas.includes(a.id)) != null);
            });
            return this.getTable(a.name, these_students);
          }) : group == "advisor" ?
            this.instructor_list().filter(i => all_advisors.has(i.id)).map(i => {
              const these_students = mystudents.filter(s => {
                const this_advisors = s.advisors.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code));
                return this_advisors && this_advisors.advisors.includes(i.id);
              });
              return this.getTable(this.print_instructor(i.id), these_students);
            }) : group == "campus" ?
              this.campus_list().filter(c => all_campuses.has(c.id)).map(c => {
                const these_students = mystudents.filter(s => {
                  const this_campus = s.campuses.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code));
                  return this_campus && this_campus.campus == c.id;
                });
                return this.getTable(this.print_campus(c.id), these_students);
              }) : group == "degree" ?
                this.degree_list().filter(d => this.get_degreetype(d.degree_type).abbrv == "PHD").map(d => {
                  return this.getTable(this.print_degree(d.id), mystudents.filter(s => s.degree == d.id));
                }) : group == "room" ?
                  this.room_list().filter(r => r.phd_student_lab).map(r => {
                    const these_students = mystudents.filter(s => {
                      const this_desk = s.desks.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code));
                      return this_desk && r.desks.find(d => d.id == this_desk.desk);
                    });
                    return this.getTable(this.print_room(r.id), these_students)
                  }) : group == "track" ?
                    TRACKS.map(d => {
                      return this.getTable(d.value, mystudents.filter(s => s.track == d.key));
                    }) : group == "none" ?
                      this.getTable("All Students", mystudents)
                      : null}

        {this.getTable("Other Students", other_students)}

        {selected_student ? (
          <Modal width={1400} visible={selected_student != null} title={[this.print_full_student(selected_student), " (advisors: ", this.getCurrentAdvisors((this.get_semester(semesters.length > 1 ? semesters[1] : semesters[0]).code), selected_student), ")"]} onCancel={this.clearSelected} footer={<Button key="submit" type="primary" onClick={this.clearSelected}>Close</Button>}>
            <Card bordered={false} style={{ overflow: 'auto', height: 'auto' }}>
              <PhDStudentDetails {...this.props} key={selected_student} match={params} />
            </Card>
          </Modal>
        ) : null}

      </Content>
    );
  }
}

class PhDStudent extends AppComponent {
  state = {
    title: "Loading"
  }

  render() {
    const { title } = this.state;

    return (
      <Content {...this.props} title={title} breadcrumbs={[{ link: "/employee", text: "Employee" }, { link: "/employee/phd/overview", text: "PhD" }, { text: title }]}>
        <PhDStudentDetails {...this.props} setTitle={title => this.setState({ title: title })} />
      </Content>
    );
  }
}

class PhDStudentDetails extends AppComponent {
  state = {
    endpoint_student: "/api/phd/",
    student: null,
    loading_student: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    const { setTitle } = this.props;

    this.doGet(this.state.endpoint_student + this.props.match.params.phdstudent_id + "/",
      data => this.setState({ student: data, loading_student: false }, () => setTitle ? setTitle(this.print_full_student(data)) : null));
  }

  render() {
    const { semester, semesters } = this.props;
    const { student, loading_student } = this.state;
    const date_format = "MMM Do YYYY";

    if (loading_student) {
      return (<Spin tip="Loading data" />);
    }

    const current_code = this.get_semester(semesters.length > 1 ? semesters[1] : semesters[0]).code;
    var current_advisors = student.advisors.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));

    const overview = [
      { title: "Photo", content: <div className="student-photo"><img src={student.photo_url} /></div> },
      { title: "Advisor(s) during " + semester, content: current_advisors ? oxford(current_advisors.advisors.map(e => this.print_full_instructor(e))) : <i>None</i> },
      { title: "Thesis Title", content: student.thesis_title ? student.thesis_title : <i>Unknown</i> },
      { title: "Track", content: student.track ? TRACKS.find(track => track.key == student.track)?.value : <i>None</i> },
      { title: "Notes", content: student.notes ? student.notes : <i>None</i> },
    ];

    // Matriculation events
    var events = [{ color: "red", date: moment(this.get_semester(student.matriculated).startdate), text: "Matriculated into " + this.print_degree(student.degree) + " program" }];
    if (student.left) {
      events = events.concat([{ color: "red", date: moment(this.get_semester(student.left).enddate), text: "Left " + this.print_degree(student.degree) + " program" }]);
    }

    // Advising events
    events = events.concat(student.advisors.map(el => { return { color: "green", date: moment(this.get_semester(el.start).startdate), text: ["Starts being ", el.mentor ? "mentored" : "advised", " by "].concat(el.advisors.length == 0 ? [] : oxford(el.advisors.map(a => this.link_full_instructor(a)))) }; }));
    events = events.concat(student.advisors.filter(el => el.end != null).map(el => { return { color: "green", date: moment(this.get_semester(el.end).enddate), text: ["Ends being ", el.mentor ? "mentored" : "advised", " by "].concat(el.advisors.length == 0 ? [] : oxford(el.advisors.map(a => this.print_full_instructor(a)))) }; }));

    // Desk assignment events
    events = events.concat(student.desks.map(el => { return { color: "orange", date: moment(this.get_semester(el.start).startdate), text: ["Assigned desk ", el.number, " in room ", this.link_room(el.room)] } }));

    // Milestone events
    events = events.concat(student.milestones.filter(el => el.approved_at != null).map(el => { return { color: "blue", date: moment(el.approved_at), text: "Completed " + el.milestone + " milestone" }; }));
    if ((student.milestones.filter(el => el.milestone == "Teaching Assistant" && el.approved_at != null).length == 0) && student.ta?.length > 0) {
      const enddate = moment(this.get_semester(student.ta[0].semester).enddate, "YYYY-MM-DD");
      if (enddate < moment()) {
        events = events.concat([{ color: "blue", date: enddate, text: "Completed Teaching Assistant milestone" }]);
      }
    }
    if ((student.milestones.filter(el => el.milestone == "Paper Requirement") && student.paper_requirement)) {
      events = events.concat([{ color: "blue", date: moment(student.paper_requirement.approved_at), text: "Completed Paper Requirement milestone" }]);
    }

    events.sort((a, b) => a.date.valueOf() - b.date.valueOf());

    const milestones = MILESTONES.map(m => {
      var found = student.milestones.find(e => e.milestone == m.title);
      const due = moment(this.get_semester(student.matriculated).startdate, "YYYY-MM-DD").add(m.days, "days");
      var notes = null;

      if ((m.title == "Teaching Assistant") && student.ta?.length > 0) {
        const semester = this.get_semester(student.ta[0].semester);
        found = { submitted_at: moment(semester.startdate, "YYYY-MM-DD"), approved_at: moment(semester.enddate, "YYYY-MM-DD") < moment() ? moment(semester.enddate, "YYYY-MM-DD") : null };
        notes = ["Served as a TA for ", this.link_course(student.ta[0].course), " during ", this.print_semester(student.ta[0].semester), "."];
      }
      if ((m.title == "Paper Requirement") && student.paper_requirement) {
        found = { approved_at: student.paper_requirement.approved_at, milestone: "Paper Requirement", submitted_at: student.paper_requirement.submitted_at };
      }

      return { title: [m.title + "  ", found && found.approved_at ? get_check() : found && found.submitted_at ? get_nonecheck() : get_nocheck()], content: found ? <div><i>Due by:</i> {due.format(date_format)}<br /><i>Submitted on:</i> {moment(found.submitted_at).format(date_format)}<br /><i>Approved on:</i> {found.approved_at ? moment(found.approved_at).format(date_format) : <i>Pending</i>}<br />{notes}</div> : <div><i>Due by:</i> {due.format(date_format)}</div> }
    }
    );

    const sections = loading_student ? null : student.semesters;
    sections.sort((a, b) => this.get_semester(a.semester).code - this.get_semester(b.semester).code)

    const funds = loading_student ? null : student.funding.map(s => s.funds.map((f, idx) => { f.semester = s.semester; f.rowspan = s.funds.length; f.index = idx; return f; })).flat();

    const review_columns = [
      {
        title: 'Semester',
        key: 'semester',
        width: 160,
        render: (text, record, idx) => this.print_semester(record.semester),
      }, {
        title: 'Rating',
        key: 'rating',
        width: 100,
        render: (text, record, idx) => record.rating,
      }, {
        title: 'Notes',
        key: 'notes',
        render: (text, record, idx) => record.notes,
      }];

    const selfreview = loading_student ? null : student.self_reviews.find(sr => semesters.includes(sr.semester));
    const advisorreviews = loading_student ? [] : student.advisor_reviews.filter(sr => semesters.includes(sr.semester));
    const tareviews = loading_student ? [] : student.ta_reviews.filter(sr => semesters.includes(sr.semester));
    const coursereviews = loading_student ? [] : student.course_reviews.filter(sr => semesters.includes(sr.semester));

    return (
      <React.Fragment>
        <p>Below is an overview of the history and progress of {this.print_full_student(student)} in the {this.print_degree(student.degree)} PhD program.</p>

        <Divider orientation="left">Overview</Divider>
        <List
          grid={this.grid}
          dataSource={overview}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />

        <Divider orientation="left">Timeline</Divider>
        <p>Below is a brief timeline of the activities of {student.firstname} in the program.  The colors of the bullets represent different areas: <span className="ant-timeline-item-head ant-timeline-item-head-green" />&nbsp;&nbsp;&nbsp; represent changes in their advisors, <span className="ant-timeline-item-head ant-timeline-item-head-blue" />&nbsp;&nbsp;&nbsp; represent milestone completions, <span className="ant-timeline-item-head ant-timeline-item-head-red" />&nbsp;&nbsp;&nbsp; represent changes in their enrollment status, and <span className="ant-timeline-item-head ant-timeline-item-head-orange" />&nbsp;&nbsp;&nbsp; represent changes in their desk location.</p>
        <Timeline>
          {events.map(el => <Timeline.Item key={el.date + "-" + el.text} color={el.color}><b>{el.date.format(date_format)}</b>: {el.text}</Timeline.Item>)}
        </Timeline>

        <Divider orientation="left">Milestones</Divider>
        <List
          grid={this.grid}
          dataSource={milestones}
          renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
        />

        <Divider orientation="left">Courses</Divider>
        <SectionTable {...this.props} data={sections} loading={loading_student} show_fields={["withdraw"]} hide_fields={["bucket", "trace", "ugrad_stats", "crosslist", "warnings", "loadcount", "waitlist", "actions", "size", "capacity"]} />

        <Divider orientation="left">Funding</Divider>
        <PhDStudentFundingTable {...this.props} funds={funds} loading={loading_student} hide_fields={['submitted_at', 'submitted_by', 'accepted_at', 'student', 'nuid', 'actions']} />

        {semester.startsWith("Summer") ? null : (
          <React.Fragment>
            <Divider orientation="left">{semester} Self Review by {this.print_full_student(student)}</Divider>
            {!selfreview ? (<i>No self-review submitted.</i>) : (
              <React.Fragment>
                <p><b>Please describe what you did this semester (coursework, TA responsibilities, research).  For first year students, describe your progress on establishing your advisor and selecting a thesis topic.</b></p>
                <p style={{ whiteSpace: "pre-wrap" }}>{selfreview.statement}</p>
                <p><b>Did you attend a conference(s)? If so, where did you go? Did you present?</b></p>
                <p>{selfreview.conferences}</p>
                <p><b>Did you have a 1-1 meeting with your advisor?</b></p>
                <p>{selfreview.one_on_one ? "Yes" : "No"}</p>
                <p><b>Please describe any extra-curricular activities in which you have participated.</b></p>
                <p style={{ whiteSpace: "pre-wrap" }}>{selfreview.extra_curricular}</p>
                <p><b>What are your plans for the upcoming semester in terms of coursework and research?</b></p>
                <p style={{ whiteSpace: "pre-wrap" }}>{selfreview.plans}</p>
                <p><b>If any issues were raised in the previous review, how have you addressed them this semester?</b></p>
                <p style={{ whiteSpace: "pre-wrap" }}>{selfreview.issues}</p>
                <p><b>Please share any constructive suggestions or requests--for your advisor(s), research group, or the college--that you think may facilitate your progress.</b></p>
                <p style={{ whiteSpace: "pre-wrap" }}>{selfreview.suggestions}</p>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {semester.startsWith("Summer") || (current_advisors == null) ? null : current_advisors.advisors.map(a => {
          const ar = advisorreviews.find(ar => ar.advisor == a);

          return (
            <React.Fragment key={a}>
              <Divider orientation="left">{semester} Advisor Review by {this.print_full_instructor(a)}</Divider>
              {ar ? (
                <React.Fragment key={ar.id}>
                  <p><b>Please comment on the progress of the student this semester in terms of research as well as towards a thesis.</b></p>
                  <p style={{ whiteSpace: "pre-wrap" }}>{ar.notes}</p>
                  <p><b>Please indicate your suggested rating for the student.</b></p>
                  <p>{ar.proposed_rating}</p>
                  <p><b>Please indicate the status for the student in the upcoming semester.</b></p>
                  <p>{ar.status}</p>
                  <p><b>Please provide a (proposed) comment for the academic review letter sent to the student.</b></p>
                  <p style={{ whiteSpace: "pre-wrap" }}>{ar.proposed_statement}</p>
                </React.Fragment>
              ) : (<i>No advisor review submitted.</i>)}
            </React.Fragment>
          );
        })}

        {tareviews.map(ar => (
          <React.Fragment key={ar.id}>
            <Divider orientation="left">{semester} TA Review for {this.print_course(ar.course)} by {this.print_full_instructor(ar.instructor)}</Divider>
            <p><b>Please indicate your overall rating for the student as a TA.</b></p>
            <p>{ar.rating}</p>
            <p><b>Please comment on the performance of the student as a TA.</b></p>
            <p>{ar.notes}</p>
          </React.Fragment>
        ))}

        {coursereviews.map(ar => (
          <React.Fragment key={ar.id}>
            <Divider orientation="left">{semester} Review in {this.print_course(ar.course)} by {this.print_full_instructor(ar.instructor)}</Divider>
            <p><b>Please select their current estimated grade.</b></p>
            <p>{this.print_grade(ar.expected_grade)}</p>
            <p><b>Please comment on their performance in your course.</b></p>
            <p style={{ whiteSpace: "pre-wrap" }}>{ar.notes}</p>
          </React.Fragment>
        ))}

        <Divider orientation="left">Academic Review History</Divider>
        <Table {...this.props} dataSource={student.reviews} columns={review_columns} scroll={{ x: 800 }} bordered={false} pagination={false} size="small" rowKey={(record, idx) => idx + "-" + record.semester + "-" + record.rating} />
      </React.Fragment>
    );
  }
}

class PhDStudentData extends AppComponent {
  constructor(students, props) {
    super(props);
    this.students = students;
  }

  // ADVISING FUNCTIONS

  getSemesterAdvisors = (semester, record) => {
    return record.advisors.find(a => this.get_semester(a.start).code <= semester.code && (a.end == null || this.get_semester(a.end).code >= semester.code));
  }

  getAdvisees = (semester, instructor) => {
    return this.students.filter(st => this.getSemesterAdvisors(semester, st)).filter(st => this.getSemesterAdvisors(semester, st).advisors.includes(instructor)).filter(st => st.left == null || (this.get_semester(st.left).code >= semester.code))
  }

  getAdviseesYear = (semesters, instructor) => {
    const all = semesters.map(s => this.getAdvisees(s, instructor)).flat();
    const results = [];
    all.forEach(st => results.find(a => a.id == st.id) ? null : results.push(st));
    return results;
  }

  getAdviseePoints = (semester, student) => {
    const advisors = this.getSemesterAdvisors(semester, student);
    return advisors ? 0.25 / this.getSemesterAdvisors(semester, student).advisors.length : 0;
  }

  getSemesterAdviseePoints = (instructor, semester) => {
    return this.getAdvisees(semester, instructor).map((st) => this.getAdviseePoints(semester, st)).reduce((r, a) => r + a, 0);
  }

  getSemesterFunding = (semester, record) => {
    return record.funding.find(f => f.semester == semester.id);
  }

  getYearAdviseePoints = (instructor, cal_year, semesters) => {
    const advisor_points = {};

    semesters.filter(se => this.calendar_year(se.id) == cal_year).forEach(se => {
      this.getAdvisees(se, instructor).forEach((st) => advisor_points[st.id] = Math.min((advisor_points[st.id] || 0) + this.getAdviseePoints(se, st), 0.5));
    });

    return advisor_points;
  }

  // FUNDING FUNCTIONS

  getFundees = (semester, instructor) => {
    const employee = this.get_instructor(instructor).employee;

    return this.students.map(st => { st.thisfunding = this.getSemesterFunding(semester, st); return st; }).filter(st => st.thisfunding && st.thisfunding.funds.find(f => this.get_fund(f.fund).owners.find(o => o.owner == employee))).map(st => st.thisfunding.funds.map((f, idx) => { f.student = st; f.index = idx; f.rowspan = st.thisfunding.funds.length; return f; })).flat();
  }

  getFundeesYear = (semesters, instructor) => {
    const employee = this.get_instructor(instructor).employee;

    var mystudents = this.students.filter(st => {
      return semesters.filter(s => {
        const thisfunding = this.getSemesterFunding(s, st);
        return thisfunding ? thisfunding.funds.find(f => this.get_fund(f.fund).owners.find(o => o.owner == employee)) : false;
      }).reduce((r, a) => (a != false) | r, false);
    }).map(st => { st.myfunding = {}; return st; });

    mystudents = mystudents.map(st => {
      var max = 0;

      semesters.forEach(s => {
        st.myfunding[s.id] = this.getSemesterFunding(s, st);
        max = Math.max(max, st.myfunding[s.id] ? st.myfunding[s.id].funds.length : 0);
      });

      st.rowspan = max;
      return st;
    });

    return mystudents.map(st => {
      return [...Array(st.rowspan).keys()].map(e => { const myst = Object.assign({}, st); myst.index = e; return myst; })
    }).flat();
  }

  getFundeePoints = (instructor, funding) => {
    const funding_record = this.get_fund(funding.fund).owners.find(o => o.owner == this.get_instructor(instructor).employee);
    const effort_total = this.get_fund(funding.fund).owners.map(o => o.fraction).reduce((r, a) => r + a, 0);
    return funding_record ? 0.25 * funding.fraction * funding_record.fraction / effort_total : 0
  }

  getSemesterFundeePoints = (instructor, semester) => {
    return this.getFundees(semester, instructor).map((fu) => this.getFundeePoints(instructor, fu)).reduce((r, a) => r + a, 0);
  }

  getYearFundeePoints = (instructor, cal_year, semesters) => {
    const funder_points = {};

    semesters.filter(se => this.calendar_year(se.id) == cal_year).forEach(se => {
      this.getFundees(se, instructor).forEach((fu) => funder_points[fu.student.id] = Math.min((funder_points[fu.student.id] || 0) + this.getFundeePoints(instructor, fu), 0.5));
    });

    return funder_points;
  }

  getYearFundeePointsNoMax = (instructor, cal_year, semesters) => {
    const funder_points = {};

    semesters.filter(se => this.calendar_year(se.id) == cal_year).forEach(se => {
      this.getFundees(se, instructor).forEach((fu) => funder_points[fu.student.id] = (funder_points[fu.student.id] || 0) + this.getFundeePoints(instructor, fu));
    });

    return funder_points;
  }

  // OVERALL FUNCTIONS

  getYearPoints = (instructor, cal_year, semesters) => {
    const advisor_points = this.getYearAdviseePoints(instructor, cal_year, semesters);
    const funder_points = this.getYearFundeePoints(instructor, cal_year, semesters);

    return Object.values(advisor_points).reduce((r, a) => r + a, 0) + Object.values(funder_points).reduce((r, a) => r + a, 0);
  }
}

class PhDStudentAdvisingPointsTable extends AppComponent {
  render() {
    const { data, semesters, year, instructor, show_points } = this.props;

    var advisees = data.getAdviseesYear(semesters, instructor);
    const advisee_points = data.getYearAdviseePoints(instructor, year, semesters);
    const total_advisees = Object.values(advisee_points).reduce((r, a) => r + a, 0);

    const advisee_columns = [
      {
        title: 'Name',
        key: 'name',
        render: (text, record, idx) => [<Link key="name" to={this.getLink("/employee/phd/" + record.id)}>{[this.print_full_student_reverse(record)]}</Link>, " ", record.email ? <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> : null],
      }, {
        title: 'NUID',
        key: 'nuid',
        width: 90,
        align: 'right',
        render: (text, record, idx) => format_nuid(record.nuid),
      }];

    semesters.forEach(s => {
      const children = [
        {
          title: 'Advisors',
          className: 'table-border-left',
          key: 'advisors-' + s.name,
          render: (text, record, idx) => data.getSemesterAdvisors(s, record) ? oxford(data.getSemesterAdvisors(s, record).advisors.map(i => this.print_instructor(i))) : null,
        }];

      if (show_points) {
        children.push({
          title: 'Points',
          key: 'points-' + s.name,
          align: 'right',
          width: show_points ? 55 : 0,
          render: (text, record, idx) => <NumberFormat displayType="text" decimalScale={ 2 } fixedDecimalScale={ true } value={ data.getAdviseePoints(s, record) } /> ,
        });
      }

      advisee_columns.push({
        title: s.name,
        align: 'center',
        className: 'table-border-left',
        children: children,
      });
    });

    if (show_points) {
      advisee_columns.push({
          title: 'Total Points',
          key: 'points',
          align: 'right',
          width: 60,
          render: (text, record, idx) => <NumberFormat displayType="text" decimalScale={ 2 } fixedDecimalScale={ true } value={ advisee_points[record.id] } /> ,
        }
      );
    }

    return <Table {...this.props} dataSource={advisees} columns={advisee_columns} scroll={{ x: 800 }} bordered={false} pagination={false} size="small" rowKey="nuid" key={"table-advisees"} footer={ () => show_points ? (<div style={{ textAlign: "right" }}><b>Total advising points:</b> <NumberFormat displayType="text" decimalScale={ 2 } fixedDecimalScale={ true } value={ total_advisees } /></div>) : null }/>;
  }
}

class PhDStudentFundingPointsTable extends AppComponent {
  render() {
    const { data, semesters, year, instructor, show_points } = this.props;

    var fundees = data.getFundeesYear(semesters, instructor);
    const fundee_points = data.getYearFundeePointsNoMax(instructor, year, semesters);
    const total_funding = Object.values(fundee_points).reduce((r, a) => r + a, 0);


    const funding_columns = [
      {
        title: 'Name',
        key: 'name',
        render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: [<Link key="name" to={this.getLink("/employee/phd/" + record.id)}>{[this.print_full_student_reverse(record)]}</Link>, " ", record.email ? <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> : null] }; },
      }, {
        title: 'NUID',
        key: 'nuid',
        width: 90,
        align: 'right',
        render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: format_nuid(record.nuid) }; },
      }];

    semesters.forEach(s => {
      var children = [
        {
          title: 'Fund',
          key: 'fund-' + s.name,
          className: 'table-border-left',
          width: 50,
          render: (text, record, idx) => record.myfunding[s.id] && record.myfunding[s.id].funds.length > record.index ? this.print_fund(record.myfunding[s.id].funds[record.index].fund) : null,
        }, {
          title: 'Fund Owners',
          key: 'owners-' + s.name,
          render: (text, record, idx) => record.myfunding[s.id] && record.myfunding[s.id].funds.length > record.index ? oxford(this.get_fund(record.myfunding[s.id].funds[record.index].fund).owners.map(i => [this.print_employee(i.owner), " (", <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} suffix="%" value={100.0 * i.fraction} />, ")"])) : null,
        }, {
          title: 'Percentage',
          key: 'percent-' + s.name,
          align: 'right',
          render: (text, record, idx) => record.myfunding[s.id] && record.myfunding[s.id].funds.length > record.index ? (<NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} suffix="%" value={100.0 * record.myfunding[s.id].funds[record.index].fraction} />) : null,
        }];

      if (show_points) {
        children.push({
          title: 'Points',
          key: 'points-' + s.name,
          align: 'right',
          width: show_points ? 55 : 0,
          render: (text, record, idx) => record.myfunding[s.id] && record.myfunding[s.id].funds.length > record.index ? ( <NumberFormat displayType="text" decimalScale={ 2 } fixedDecimalScale={ true } value={ this.get_fund(record.myfunding[s.id].funds[record.index].fund).owners.find(i => i.owner == this.get_instructor(instructor).employee) ? 0.25 * record.myfunding[s.id].funds[record.index].fraction * this.get_fund(record.myfunding[s.id].funds[record.index].fund).owners.find(i => i.owner == this.get_instructor(instructor).employee).fraction / this.get_fund(record.myfunding[s.id].funds[record.index].fund).owners.map(i => i.fraction).reduce((r,a) => r+a, 0) : 0 } /> ) : null,
        });
      }

      funding_columns.push({
        title: s.name,
        align: 'center',
        className: 'table-border-left',
        children: children,
      });
    });

    if (show_points) {
      funding_columns.push({
          title: 'Total Points',
          key: 'points',
          align: 'right',
          width: 60,
          render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: <NumberFormat displayType="text" decimalScale={ 2 } fixedDecimalScale={ true } value={ fundee_points[record.id] } /> }; },
        }
      );
    }

    return <Table {...this.props} dataSource={fundees} columns={funding_columns} scroll={{ x: 800 }} bordered={false} pagination={false} size="small" rowKey={(record) => record.nuid + "-" + record.index} key={"table-advisees"} footer={ show_points ? (() => <div style={{ textAlign: "right" }}><b>Total funding points:</b> <NumberFormat displayType="text" decimalScale={ 2 } fixedDecimalScale={ true } value={ total_funding } /> </div>) : null }/>;
  } 
}

class PhDStudentTeachingLoadList extends AppComponent {
  state = {
    endpoint_students: "/api/phd/",
    students: [],
    data: new PhDStudentData([], this.props),
    loading_students: true,

    endpoint: "/api/load/",
    loads: [],
    loading_loads: true,

    selected_instructor: null,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    const { semesters } = this.props;
    this.doGet(this.state.endpoint_students,
      data => this.setState({ students: data, data: new PhDStudentData(data, this.props), loading_students: false }));
    this.doGet(this.state.endpoint + "?year=" + this.get_semester(semesters[0]).year,
      data => { this.setState({ loads: data, loading_loads: false }) });
  }

  clearSelected = () => { this.setState({ selected_instructor: null }) }

  getSemesterList = () => {
    const { semesters } = this.props;
    const curr_semester = semesters.length == 1 ? semesters[0] : semesters[1];

    const all_semesters = this.semester_list().map(s => { s.cal_year = s.name.substr(-4); return s; }).filter(s => s.speed == 1);
    const prev_semester = all_semesters.find(s => s.code == ((all_semesters.find(a => a.id == curr_semester).year - 1) + "10")); //moment() > moment(s.enddate, "YYYY-MM-DD"));
    const my_semesters = all_semesters.filter(s => s.speed == 1 && s.code <= prev_semester.code).filter(s => s.cal_year >= prev_semester.year - 3);
    my_semesters.reverse();
    return my_semesters;
  }

  getLoad = (score) => {
    return score < 1 ? 4 : score < 2 ? 3 : score < 3 ? 2.5 : 2;
  }

  getCalculationNotes = () => {
    return (<div>
      <p>The Khoury College Tenure-Track and Tenured Faculty By-Laws specify the teaching load policy used in the College.  These are summarized below, but the By-Laws remain authoritative. First, if a faculty member is pre-tenure, their teaching load is 2 courses per year.  Otherwise, the formula below applies:</p>
      <ol>
        <li>For each semester, a faculty member receives 0.25 points for advising a student.  If faculty members are co-advising a student, then the points are split equally between the advisors.</li>
        <li>For each semester, a faculty member receives 0.25 points for fully funding a student.  If the student was not fully funded (i.e., were partially on an internship), the points are allocated proportionally. If the funding for the student is split across multiple funds, the points are divided proportionally between the funds based on how much of the student's funding came from that fund.  If any of the funds has multple PIs, the points allocated to that fund are split between the PIs based on the effort percentages reported to Finance Administration team.</li>
        <li>For each calendar year, a faculty member can receive at most 0.5 points for advising a given student, and at most 0.5 points for funding a given student.  The sum of these points across all of their advised/funded students forms their <i>score</i> for that year.</li>
        <li>The faculty member's <i>overall score</i> is the maximum of three values: (a) the most recent calendar year score, (b) the average of the two most recent calendar year scores, and (c) the average of the three most recent calendar year scores.</li>
        <li>The overall score is converted to a teaching load for the upcoming academic year using the formula:
          <ul>
            <li>If the score is less than 1, the teaching load is 4 courses per year.</li>
            <li>If the score is less than 2, the teaching load is 3 courses per year.</li>
            <li>If the score is less than 3, the teaching load is 2.5 courses per year.</li>
            <li>Otherwise, the teaching load is 2 courses per year.</li>
          </ul></li>
      </ol>
      <p>Note that a teaching load of 2.5 is implemented as a teaching load of 3 courses in the first year, followed by a teaching load of 2 courses in the second year (if the faculty member has teaching load of  2.5 or lower in the second year).  Additionally, note that for years before AY2018-2019, the threshold for a 2 course per year teaching load was a score of 4; this was lowered to a score of 3 starting with AY2018-2019.</p></div>);

  }

  renderTable = (instructors, my_years, my_semesters) => {
    const { data, loading_students, loads, loading_loads } = this.state;

    var columns = [
      {
        title: 'Name',
        key: 'name',
        render: (text, record, idx) => <a onClick={() => this.setState({ selected_instructor: record.id })}>{this.print_full_instructor(record.id)}</a>,
      }];

    columns = columns.concat(my_years.map(y => {
      return {
        title: <span>Score {y}</span>,
        key: "year-" + y,
        align: 'right',
        width: 140,
        render: (text, record, idx) => (<NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={data.getYearPoints(record.id, y, my_semesters)} />)
      };
    }));

    columns = columns.concat(my_years.map(y => {
      const col = {
        title: "Avg " + y + "-" + my_years[my_years.length - 1],
        key: "avg-year-" + y,
        align: 'right',
        width: 140,
        render: (text, record, idx) => {
          const filter_years = my_years.filter(ye => ye >= y);
          const points = filter_years.map(ye => data.getYearPoints(record.id, ye, my_semesters)).reduce((r, a) => r + a, 0);

          return (<NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={points / filter_years.length} />);
        }
      };

      return col;
    }));

    columns = columns.concat([{
      title: "Overall Score",
      key: "overall",
      align: 'right',
      width: 100,
      render: (text, record, idx) => {
        const score = my_years.map(y => {
          const filter_years = my_years.filter(ye => ye >= y);
          const points = filter_years.map(ye => data.getYearPoints(record.id, ye, my_semesters)).reduce((r, a) => r + a, 0);
          return points / filter_years.length;
        }).reduce((r, a) => a > r ? a : r, 0);

        return (<b><NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={score} /></b>);
      },
    }, {
      title: "Load",
      key: "load",
      align: 'right',
      width: 140,
      render: (text, record, idx) => {
        const score = my_years.map(y => {
          const filter_years = my_years.filter(ye => ye >= y);
          const points = filter_years.map(ye => data.getYearPoints(record.id, ye, my_semesters)).reduce((r, a) => r + a, 0);
          return points / filter_years.length;
        }).reduce((r, a) => a > r ? a : r, 0);

        return (<b>{this.getLoad(score)}</b>);
      },
    }, {
      title: "Assigned",
      key: "assigned-load",
      align: 'right',
      width: 140,
      render: (text, record, idx) => {
        return (<b><i>{loads.find(l => l.instructor == record.id) ? loads.find(l => l.instructor == record.id).load : null}</i></b>);
      },
    }]);

    return (
      <Table {...this.props} dataSource={instructors} columns={columns} scroll={{ x: 1100 }} bordered={false} pagination={false} size="small" rowKey="nuid" key={"table-advisees"} />
    );
  }

  render() {
    const { semesters } = this.props;
    const { data, loading_students, loads, loading_loads } = this.state;

    const my_instructor = this.props.selected_instructor ? this.props.selected_instructor : this.state.selected_instructor;
    var my_semesters = this.getSemesterList();
    const my_years = my_semesters.filter(s => s.code % 100 == 10).map(s => s.cal_year);

    if (my_instructor) {
      const my_all_years = [];
      my_semesters.forEach(s => my_all_years.indexOf(s.cal_year) === -1 ? my_all_years.push(s.cal_year) : null);

      return (
        <Content {...this.props} title={"PhD Student-based Teaching Load for " + this.print_full_instructor(my_instructor)} breadcrumbs={[{ link: "/faculty", text: "Faculty" }, { text: "Teaching Load" }]}>
          <p>This page provides an overview of the teaching load calculation for {this.print_full_instructor(my_instructor)}.  Click on the different tabs to see the full breakdown. {this.props.selected_instructor ? null : (<span>You can also <a onClick={this.clearSelected}>go back to the list of all instructors</a>.</span>)} </p>

          {this.getCalculationNotes()}

          {loading_students || loading_loads ? <Spin tip="Loading data" /> : (
            <Tabs>
              {this.props.selected_instructor ? (
                <TabPane key="overview" tab="Overview">
                  {this.renderTable([this.get_instructor(this.props.selected_instructor)], my_years, my_semesters)}
                </TabPane>
              ) : null}
              {my_all_years.map(y => {
                const semesters = my_semesters.filter(s => s.name.includes(y));

                return (
                  <TabPane key={y} tab={y}>
                    <Divider orientation="left">{y} Advisees</Divider>
                    <PhDStudentAdvisingPointsTable {...this.props} data={data} semesters={semesters} year={y} instructor={my_instructor} />

                    <Divider orientation="left">{y} Fundees</Divider>
                    <PhDStudentFundingPointsTable {...this.props} data={data} semesters={semesters} year={y} instructor={my_instructor} />
                  </TabPane>
                );
              })}
            </Tabs>

          )}
        </Content>
      );
    } else {
      return (
        <Content {...this.props} title={"PhD Student-based Teaching Load"} breadcrumbs={[{ link: "/employee", text: "Employee" }, { text: "PhD" }, { text: "Teaching Load" }]}>
          <p>This page provides an overview of the teaching load calculation for all faculty, showing their semester-by-semester points, their yearly total, and their resulting score and teaching load.  Click on a faculty member below to see a breakdown of their calculation.</p>

          {this.getCalculationNotes()}

          {loading_students || loading_loads ? <Spin tip="Loading data" /> :
            <>
              {this.instructorrank_list().filter(r => r.subtype.mytype.mytype == "Tenured/Tenure-Track").map(r => {
                const instructors = this.instructor_list().filter(i => (this.get_rank_from_history(i.ranks, this.get_semester(semesters[0])) == r.id) && this.get_from_history(i.ranks, this.get_semester(semesters[0])).appointment.find(a => this.get_college(a.college).code == "CS") && this.get_college(this.get_from_history(i.ranks, this.get_semester(semesters[0])).tenure_home).code == "CS");
                if (instructors?.length > 0) {
                  return (<React.Fragment key={r.id}>
                    <Divider key={"div-" + r.id} orientation="left">{this.print_instructorrank(r.id)} (tenure home Khoury)</Divider>
                    {this.renderTable(instructors, my_years, my_semesters)}
                  </React.Fragment>);
                }
              })
              }
              {this.instructorrank_list().filter(r => r.subtype.mytype.mytype == "Tenured/Tenure-Track").map(r => {
                const instructors = this.instructor_list().filter(i => (this.get_rank_from_history(i.ranks, this.get_semester(semesters[0])) == r.id) && this.get_from_history(i.ranks, this.get_semester(semesters[0])).appointment.find(a => this.get_college(a.college).code == "CS") && this.get_college(this.get_from_history(i.ranks, this.get_semester(semesters[0])).tenure_home).code != "CS");
                if (instructors?.length > 0) {
                  return (<React.Fragment key={r.id}>
                    <Divider key={"div-" + r.id} orientation="left">{this.print_instructorrank(r.id)} (tenure home non-Khoury)</Divider>
                    {this.renderTable(instructors, my_years, my_semesters)}
                  </React.Fragment>);
                }
              })
              }
            </>
          }
        </Content>
      );
    }
  }
}

export { PhDStudentList, PhDStudent, PhDStudentTeachingLoadList, PhDStudentTable, PhDStudentAdvisingPointsTable, PhDStudentFundingPointsTable, PhDStudentData };