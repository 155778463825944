import React, { Component } from "react";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from '../AppComponent';
import LoadDetail from "./LoadDetail";
import { get_load_total, print_load_component } from "../Utils";
import { Table, Form, Switch, Layout, Tooltip, Icon, Divider, Button } from 'antd';
const FormItem = Form.Item;

class LoadTable extends AppComponent {
  state = {
    endpoint: "/api/load/",
    loads: [],
    loading_loads: true,

    show_courses: true,
    show_blank: (typeof this.props.show_blank !== 'undefined' ? this.props.show_blank : true),
  }

  handleToggle = prop => (enable) => {
    this.setState({ [prop]: enable });
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.setState({ loading_loads: true });
      this.getData();
    }
  }

  getData = () => {
    var args = [];
    if (this.props.show_instructors) {
      args.push("instructor=" + this.props.show_instructors.map(el => el.id).join(","));
    }
    if (this.props.show_years) {
      args.push("year=" + this.props.show_years.join(","));
    }
    this.doGet(this.state.endpoint + "?" + args.join("&"),
      data => { this.setState({ loads: data, loading_loads: false }) });
  }

  renderCourseCell = (courses, coordinators) => {
    var result = courses.filter(el => el.loadcount > 0 && !el.deleted)
      .map(el => [(<span key={el.crn}>{this.link_course(el.course)} </span>), (el.crosslist ? (<Icon key={"crosslist-" + el.id} type="swap" />) : "")])
      .reduce((r, a) => r.length ? r.concat([<br key={r.length} />]).concat(a) : a, []);

    return result.concat(coordinators.map(el => [(<span key={"coordinator-" + el.id}>{this.link_course(el.course)}{"\u00a0"}Crd </span>)])
      .reduce((r, a) => r.concat([<br key={r.length} />]).concat(a), []))
  }

  get_semester_load_data = (sections, coordinators) => {
    return {
      undergraduate: sections.filter(section => this.get_course(section.course).number < 5000),
      graduate: sections.filter(section => this.get_course(section.course).number >= 5000),
      undergraduate_coordinators: coordinators.filter(coord => this.get_course(coord.course).number < 5000),
      graduate_coordinators: coordinators.filter(coord => this.get_course(coord.course).number >= 5000),
      all: sections,
      all_coordinators: coordinators
    };
  }

  show_in_load_table = (instructor) => {
    const { campus, semesters, filter } = this.props;

    const appointment = this.get_from_history(instructor.ranks, this.get_semester(semesters[0]));
    const rank = appointment && appointment.rank ? this.get_instructorrank(appointment.rank) : null;

    return rank && (instructor.campus == campus) && (appointment.appointment.find(a => this.get_college(a.college).code == "CS")) &&
      (rank.subtype.mytype.mytype == "Tenured/Tenure-Track" || rank.subtype.mytype.mytype == "Full-Time Non-Tenure-Track") && (filter == null || filter(appointment));
  }

  filter_semestertype = (list, type) => {
    return list.filter(el => this.get_semester(el.semester).semestertype == type);
  }

  get_load_data = () => {
    const { sections, coordinators, instructors, show_instructors, show_years, campus, semesters } = this.props;
    const { loads, show_blank } = this.state;

    const campus_instructors = this.instructor_list().filter(this.show_in_load_table);

    const data = (show_years ? show_years : this.year_list()).map(year => {
      return (show_instructors ? show_instructors : campus_instructors).map(instructor => {
        const mysections = sections.filter(section => !section.deleted && section.instructors.find(el => el == instructor.id) && this.get_semester(section.semester).year == year);
        const mycoordinators = coordinators.filter(coord => coord.instructor == instructor.id && this.get_semester(coord.semester).year == year);
        const rank = this.get_from_history(instructor.ranks, this.get_semester(semesters[0]));

        return {
          year: year,
          instructor: instructor.id,
          assigned: loads.find(el => el.instructor == instructor.id && el.year == year),
          all: this.get_semester_load_data(mysections, mycoordinators),
          fall: this.get_semester_load_data(this.filter_semestertype(mysections, 10), this.filter_semestertype(mycoordinators, 10)),
          spring: this.get_semester_load_data(this.filter_semestertype(mysections, 30), this.filter_semestertype(mycoordinators, 30)),
          summer_1: this.get_semester_load_data(this.filter_semestertype(mysections, 40), this.filter_semestertype(mycoordinators, 40)),
          summer_full: this.get_semester_load_data(this.filter_semestertype(mysections, 50), this.filter_semestertype(mycoordinators, 50)),
          summer_2: this.get_semester_load_data(this.filter_semestertype(mysections, 60), this.filter_semestertype(mycoordinators, 60)),
          teaching_schedule: rank ? rank.teaching_schedule : null,
          sabbaticals: instructor.sabbaticals,
          leaves: instructor.leaves,
        };
      });
    }).reduce((r, a) => r.concat(a), []);

    return data.filter(el => show_blank || el.all.all.length || el.all.assigned);
  }

  get_full_load_total = (sections, coordinators, instructor) => {
    return sections.reduce((r, a) => r + this.get_instructor_section_loadcount(a, a.crosslist ? sections.find(s => s.id == a.crosslist) : null, instructor), 0) + coordinators.reduce((r, a) => r + a.loadcount, 0);
  }

  render_load_total = (key, sections, coordinators, instructor) => {
    return print_load_component(this.get_full_load_total(sections, coordinators, instructor), false, true);
  }

  get_sabbatical_leave_color = (i, idx) => {
    const { semesters } = this.props;

    const sabbatical = i.sabbaticals ? i.sabbaticals.find(s => (this.get_semester(s.start).code <= this.get_semester(semesters[0]).code) && (this.get_semester(s.end).code >= this.get_semester(semesters[0]).code)) : null;

    if (sabbatical) {
      return sabbatical.approved ? "sabbatical-approved" : "sabbatical-pending";
    } else {
      const leave = i.leaves ? i.leaves.find(s => (this.get_semester(s.start).code <= this.get_semester(semesters[0]).code) && (this.get_semester(s.end).code >= this.get_semester(semesters[0]).code)) : null;

      return leave ? "leave" : null;
    }
  }

  generate_semester_columns = (base_data, semester, semestertype) => {
    const { show_courses } = this.state;

    const field = semester.toLowerCase().replace(" ", "_");
    const total_used = base_data.reduce((r, a) => r + this.get_full_load_total(a[field].all, a[field].all_coordinators, a.instructor), 0);

    if (base_data.instructor) {
      const instructor = this.get_instructor(base_data.instructor);
      const appointment = this.get_from_history(instructor.ranks, semester);
      const hit = appointment && appointment.teaching_schedule && appointment.teaching_schedule.find(semester) == null;
    }

    const style = (record) =>
      record.teaching_schedule && (record.teaching_schedule.semesters.find(sem => sem == this.semester_list().find(s => s.year == record.year && s.semestertype == semestertype).id) == null) ? "teaching-elsewhere" :
        record.sabbaticals && record.sabbaticals.find(s => this.get_semester(s.start).code <= this.semester_list().find(s => s.year == record.year && s.semestertype == semestertype).code && this.get_semester(s.end).code >= this.semester_list().find(s => s.year == record.year && s.semestertype == semestertype).code && s.approved) ? "sabbatical-approved" :
          record.sabbaticals && record.sabbaticals.find(s => this.get_semester(s.start).code <= this.semester_list().find(s => s.year == record.year && s.semestertype == semestertype).code && this.get_semester(s.end).code >= this.semester_list().find(s => s.year == record.year && s.semestertype == semestertype).code) ? "sabbatical-approved" :
            record.leaves && record.leaves.find(l => this.get_semester(l.start).code <= this.semester_list().find(s => s.year == record.year && s.semestertype == semestertype).code && this.get_semester(l.end).code >= this.semester_list().find(s => s.year == record.year && s.semestertype == semestertype).code) ? "leave" :
              "";

    var children = [
      {
        title: '',
        key: field + '.load',
        align: 'right',
        width: 45,
        render: (text, record, idx) => {
          return {
            props: {
              className: style(record),
            },
            children: this.getCellValue(record, () => this.render_load_total(field + '.load', record[field].all, record[field].all_coordinators, record.instructor), () => print_load_component(total_used)),
          };
        },
      }, {
        title: 'Undergrad',
        key: field + '.undergrad',
        align: 'left',
        width: 95,
        render: (text, record, idx) => {
          return {
            props: {
              className: style(record),
            },
            children: this.getCellValue(record, () => this.renderCourseCell(record[field].undergraduate, record[field].undergraduate_coordinators)),
          };
        },
      }, {
        title: 'Graduate',
        key: field + '.graduate',
        align: 'left',
        width: 95,
        render: (text, record, idx) => {
          return {
            props: {
              className: style(record),
            },
            children: this.getCellValue(record, () => this.renderCourseCell(record[field].graduate, record[field].graduate_coordinators)),
          };
        },
      }
    ];

    if (semester == "Summer Full") {
      children.splice(1, 1);
    }

    if (!show_courses) {
      children = [];
    }

    var result = {
      title: semester,
      key: field + '.sections',
      align: (show_courses ? 'center' : 'right'),
      width: (show_courses ? 200 : 100),
      render: (text, record, idx) => this.getCellValue(record, () => show_courses ? "" : this.render_load_total(field + '.load', record[field].all, record[field].all_coordinators, record.instructor)),
    };

    if (show_courses) {
      result.children = children;
    }

    return result;
  }

  getCellValue = (record, normal, total) => {
    return record.is_total_line ? (total ? <b>{total()}</b> : null) : normal();
  }

  render() {
    const { hide_form, hide_fields, loading, onRefresh, show_total, semesters } = this.props;
    const { loading_loads, show_courses, show_blank } = this.state;

    const base_data = this.get_load_data();
    const data = base_data.concat(show_total ? { is_total_line: true } : []);

    const total_assigned = base_data.reduce((r, a) => r + get_load_total(a.assigned), 0);
    const total_used = base_data.reduce((r, a) => r + this.get_full_load_total(a.all.all, a.all.all_coordinators, a.instructor), 0);

    const allcolumns = [
      {
        title: 'Year',
        key: 'year',
        fixed: 'left',
        render: (text, record, idx) => this.getCellValue(record, () => this.print_year(record.year)),
      }, {
        title: 'Instructor',
        key: 'instructor',
        width: 160,
        render: (text, record, idx) => this.getCellValue(record, () => this.link_full_instructor(record.instructor), () => "Total"),
      }, {
        title: 'Rank',
        key: 'rank',
        render: (text, record, idx) => this.getCellValue(record, () => {
          const rank_id = this.get_rank_from_history(this.get_instructor(record.instructor).ranks, this.get_semester(semesters[0]));
          return rank_id ? this.print_instructorrank(rank_id) : null;
        }),
      }, {
        title: 'Assigned',
        key: 'assigned',
        align: 'right',
        render: (text, record, idx) => <LoadDetail {...this.props} load={record} getCellValue={this.getCellValue} totalAssigned={total_assigned} />,
      },
      this.generate_semester_columns(base_data, "Fall", 10),
      this.generate_semester_columns(base_data, "Spring", 30),
      this.generate_semester_columns(base_data, "Summer 1", 40),
      this.generate_semester_columns(base_data, "Summer Full", 50),
      this.generate_semester_columns(base_data, "Summer 2", 60),
      {
        title: 'Total',
        key: 'total ',
        align: 'right',
        width: 80,
        render: (text, record, idx) => this.getCellValue(record, () => this.render_load_total("total", record.all.all, record.all.all_coordinators, record.instructor), () => print_load_component(total_used)),
      }, {
        title: 'Remaining',
        key: 'remaining ',
        align: 'right',
        width: 80,
        render: (text, record, idx) => this.getCellValue(record, () => record.assigned ? print_load_component(get_load_total(record.assigned) - record.all.all.reduce((r, a) => r + this.get_instructor_section_loadcount(a, a.crosslist ? record.all.all.find(s => s.id == a.crosslist) : null, record.instructor), 0) - record.all.all_coordinators.reduce((r, a) => r + a.loadcount, 0), true, true) : "", () => print_load_component(total_assigned - total_used)),
      }];

    const columns = allcolumns.filter(el => (!hide_fields) || !hide_fields.includes(el.key));

    return (
      <React.Fragment>
        { !hide_form && (
          <Form layout="inline">
            {onRefresh ? <Button icon="redo" onClick={() => { this.getData(); onRefresh(); }}>Refresh</Button> : null}
            <FormItem label="Show Courses" style={{ float: 'right' }}>
              <Switch checked={show_courses} onChange={this.handleToggle('show_courses')} />
            </FormItem>
            <FormItem label="Show Blank" style={{ float: 'right' }}>
              <Switch checked={show_blank} onChange={this.handleToggle('show_blank')} />
            </FormItem>
          </Form>
        )}
        <Table {...this.props} tableLayout='auto' dataSource={data} loading={loading || loading_loads} columns={columns} scroll={{ x: (show_courses ? 1600 : 800) }} bordered={true} pagination={false} size="small" rowKey={(record) => record.year + "-" + record.instructor} />
      </React.Fragment>
    );
  }
}

export default LoadTable;
