import React, { Component } from "react";
import AppComponent from "../AppComponent";
import Content from "../Content";
import moment from "moment-timezone";
import { renderStatus } from "../Utils";
import { WORKFLOWTYPES } from "./WorkflowProcess";

import { Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Steps, Modal, Icon, Popconfirm } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { Step } = Steps;

class WorkflowSubmit extends AppComponent {
  state = {    
    visible: true,
    loading: true,
    data: [],
    
    modal_item: null,
  }
  
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.semester !== this.props.semester) || (prevProps.campus !== this.props.campus)) {
      this.getData();
    }
  }
  
  //  --- METHODS SUBCLASSES SHOULD IMPLEMENT ---
  
  includeHeader = () => {
    return true;
  }
  
  getData = () => {
    return "UNIMPLEMENTED";
  }
  
  get_name = () => {
    return "UNIMPLEMENTED";
  }
  
  get_workflowtype = () => {
    return "UNIMPLEMENTED";
  }
  
  get_breadcrumbs = () => {
    return "UNIMPLEMENTED";
  }
  
  get_form = (func) => {
    return "UNIMPLEMENTED";
  }
  
  get_overview_text = () => {
    return "UNIMPLEMENTED";
  }
  
  get_columns = () => {
    return "UNIMPLEMENTED";
  } 
  
  //   --- OTHER METHODS ---
  
  renderContent = () => {
    const { loading, visible, data, modal_item } = this.state;
    
    return (
      <>
        <>{ this.get_overview_text() }</>
        
        <Divider orientation="left">Submitted { this.get_name_plural() }</Divider>
        { WORKFLOWTYPES[this.get_workflowtype()].table(this.props, data, this.getData, loading, "submit", (record) => this.setState({ modal_item: record})) }
        
        { visible ? (
          <React.Fragment>
            <Divider orientation="left">Submit New { this.get_name() }</Divider>
            { this.get_form(this.getData) }
          </React.Fragment>
        ) : (
          <Divider orientation="center">{" "}Submissions for { this.get_name() } are Closed</Divider>
        )}
        
        <Modal width={ 1000 } visible={ modal_item != null } title={ "View workflow record" } onCancel={ () => this.setState({ modal_item: null }) } footer={ null }>
          { modal_item ? WORKFLOWTYPES[this.get_workflowtype()].render(this.props, modal_item) : null }
        </Modal>
      </> 
    );
  }
      
  render() {
    const { loading, visible, data, modal_item } = this.state;

    return this.includeHeader() ? (
      <Content {...this.props} title={ this.get_name() } breadcrumbs={ this.get_breadcrumbs() }>
        { this.renderContent() }
      </Content>
    ) : this.renderContent();
  }
}

export { WorkflowSubmit };