import React, { Component } from "react";
import moment from "moment-timezone";
import AppComponent from '../../AppComponent';
import Content from '../../Content';
import CustomTabs from "../../CustomTabs";
import { InstructorRanksTable } from "../../Instructor"
import LoadTable from "../../Load/LoadTable";
import SectionTable from '../../SectionTable';

import { add_dividers, add_brs, format_percent, format_decimal, format_nuid, get_check_nocheck, if_empty, oxford, median } from '../../Utils';

import { Table, Icon, Descriptions, Tabs, Divider, Tag, Spin, Alert } from "antd";
const TabPane = Tabs.TabPane;


class CommitteeOverview extends AppComponent {
  state = {
    instructorData: {},
    endpoint: "/api/schedule/",
    endpoint_coordinator: "/api/schedule/coordinator/",

    endpoint_reviewers: "/api/merit/teaching/committeeassignments/",
    reviewers: null,
    loading_reviewers: true,

    endpoint_selfreviews: "/api/merit/teaching/committee/selfreviews/",
    selfreviews: null,
    loading_selfreviews: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester != this.props.semester) {
      this.getData();
    }
  }

  getMeritYear = (semesters) => {
    return this.get_year(semesters[0]) - 1;
  }

  getData = () => {
    this.doGet(this.state.endpoint_reviewers + "?year=" + this.getMeritYear(this.props.semesters), data => this.setState({ reviewers: data, loading_reviewers: false }));
    this.doGet(this.state.endpoint_selfreviews + "?year=" + this.getMeritYear(this.props.semesters), data => this.setState({ selfreviews: data, loading_selfreviews: false }));
  }


  renderFacultyDetails = (name, file) => {
    return (
      <div style={{ margin: 0 }}>
        <Descriptions >
          <Descriptions.Item label="Retrospective Memorandum" span={1}>{file ? <><a onClick={() => this.openPDF(file, name + " retrospective.pdf")}><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{" retrospective.pdf"}</a><br /></> : "None Submitted"}</Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  renderNumberOrZero = (number, data) => {
    return data.length ? number : 0;
  }

  renderStats = (data, showTotal = false, roundTo = 0) => {
    const sum = data.reduce((acc, num) => acc + num, 0);

    return (
      <div style={{ margin: 0 }}>
        <Descriptions >
          <Descriptions.Item label="Values" span={4}>{"(N=" + data.length + "): " + data.map(el => el.toFixed(roundTo)).join(", ")}</Descriptions.Item>
          {showTotal && <Descriptions.Item label="Total" span={1}>{this.renderNumberOrZero(sum.toFixed(roundTo), data)}</Descriptions.Item>}
          <Descriptions.Item label="Min" span={1}>{this.renderNumberOrZero(Math.min(...data), data)}</Descriptions.Item>
          <Descriptions.Item label="Max" span={1}>{this.renderNumberOrZero(Math.max(...data), data)}</Descriptions.Item>
          <Descriptions.Item label="Mean" span={1}>{this.renderNumberOrZero((sum / data.length).toFixed(roundTo), data)}</Descriptions.Item>
          <Descriptions.Item label="Median" span={1}>{this.renderNumberOrZero(median(data).toFixed(roundTo), data)}</Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  renderBasics = (record) => {
    const { instructorData, selfreviews, reviewers } = this.state;
    return (
      <div>
        <CustomTabs {...this.props}>
          <TabPane tab="Basic Information" key="basic">
            {this.renderFacultyDetails(this.print_instructor(record.id), selfreviews?.find(el => el.faculty === record.id)?.file)}
            <Divider orientation="left">Ranks</Divider>
            <InstructorRanksTable {...this.props} instructor={record.id} />
            <Divider orientation="left">Committees</Divider>
            {this.get_committee_table(record.id)}
            <Divider orientation="left">Positions</Divider>
            {this.get_position_table(record.id)}
            <Divider orientation="left">Load Overview</Divider>
            {this.fullyLoadedInstructorData(record.id) &&
              <LoadTable {...this.props} sections={this.getInstructorFieldValue(record.id, "sections")} coordinators={this.getInstructorFieldValue(record.id, "coordinator")} hide_fields={["instructor", "rank"]} hide_form={true} show_blank={false} />}
          </TabPane>
          <TabPane tab="All Years" key="all">
            <Divider orientation="left">Courses Taught</Divider>
            {this.fullyLoadedInstructorData(record.id) ?
              <>
                <SectionTable {...this.props} data={this.getInstructorFieldValue(record.id, "sections")} trace={true} loading={!(record.id in instructorData)} hide_links={true} hide_fields={["actions", "ugrad_stats", "warnings", "honacc", "campus", "method", "meetingtime", "room", "instructors", "notes", "waitlist"]} />
                <Divider orientation="left">{"Students"}</Divider>
                {this.renderStats(this.getInstructorFieldValue(record.id, "sections").map(el => el.enrollment).filter(el => el), true, 0)}
                <Divider orientation="left">{"Trace"}</Divider>
                {this.renderStats(this.getInstructorFieldValue(record.id, "sections").map(el => el.trace_mean).filter(el => el), false, 1)}
              </> :
              <Spin tip="Loading data..." />}
          </TabPane>
          <TabPane tab={this.getMeritYear(this.props.semesters)} key={this.getMeritYear(this.props.semesters)}>
            <Divider orientation="left">Courses Taught</Divider>
            {this.fullyLoadedInstructorData(record.id) ?
              <>
                <SectionTable {...this.props} data={this.getInstructorFieldValue(record.id, "sections").filter(el => this.calendar_year(el.semester) == this.getMeritYear(this.props.semesters))} loading={!(record.id in instructorData)} hide_links={true} trace={true} hide_fields={["actions", "ugrad_stats", "warnings", "honacc", "campus", "method", "meetingtime", "room", "instructors", "notes", "waitlist"]} />
                <Divider orientation="left">{"Students"}</Divider>
                {this.renderStats(this.getInstructorFieldValue(record.id, "sections").filter(el => this.calendar_year(el.semester) == this.getMeritYear(this.props.semesters)).map(el => el.enrollment).filter(el => el), true, 0)}
                <Divider orientation="left">{"Trace"}</Divider>
                {this.renderStats(this.getInstructorFieldValue(record.id, "sections").filter(el => this.calendar_year(el.semester) == this.getMeritYear(this.props.semesters)).map(el => el.trace_mean).filter(el => el), false, 1)}
              </> :
              <Spin tip="Loading data..." />}
          </TabPane>
        </CustomTabs>
      </div>
    );
  }

  updateInstructorField = (instructorID, field, newValue) => {
    this.setState((prevState) => {
      return {
        instructorData: {
          ...prevState.instructorData,
          [instructorID]: {
            ...prevState.instructorData[instructorID],
            [field]: newValue,
          },
        },
      };
    });
  };

  getInstructorFieldValue = (instructorID, field) => {
    return this.state.instructorData[instructorID]?.[field];
  };

  fullyLoadedInstructorData = (facultyID) =>
    ['sections', 'coordinator'].every((field) => this.state.instructorData[facultyID]?.hasOwnProperty(field));

  getFacultyData = (faculty) => {
    const { instructorData } = this.state;

    if (faculty in instructorData) {
      return;
    }
    this.setState({ loading: true })
    this.doGet(this.state.endpoint + "?instructors__employee__faculty=" + faculty, data => this.updateInstructorField(faculty, "sections", data));
    this.doGet(this.state.endpoint_coordinator + "?instructor=" + faculty, data => this.updateInstructorField(faculty, "coordinator", data));
  };

  get_committee_table = (faculty) => {
    const committees = this.committeeyear_list().filter(cy => cy.members.find(i => i.instructor == faculty));
    const data = Object.values(committees.reduce((r, a) => { (r[a.year] = r[a.year] || { year: a.year, committees: [] }).committees.push(a); return r; }, {}));

    const columns = [
      {
        title: 'Year',
        key: 'year',
        width: 60,
        render: (text, record, idx) => this.print_year(record.year),
      }, {
        title: 'Committee(s)',
        key: 'committees',
        render: (text, record, idx) => add_brs(record.committees.map(el => this.print_committeemembership_committee(el.members.find(i => i.instructor == faculty))))
      }];

    return (data.length ? <Table {...this.props} columns={columns} dataSource={data} bordered={true} pagination={false} size="small" rowKey={(record) => record.year} /> : "No assignments found");
  }

  getCommitteeYear = (committeeyear) => {
    return this.committeeyear_list().find(e => e.id == committeeyear);
  }

  get_position_table = (faculty) => {
    const positions = this.positionyear_list().filter(cy => cy.holders.find(i => i == faculty));
    const data = Object.values(positions.reduce((r, a) => { (r[a.year] = r[a.year] || { year: a.year, positions: [] }).positions.push(a); return r; }, {}));

    const columns = [
      {
        title: 'Year',
        key: 'year',
        width: 60,
        render: (text, record, idx) => this.print_year(record.year),
      }, {
        title: 'Position(s)',
        key: 'positions',
        render: (text, record, idx) => add_brs(record.positions.map(this.print_positionyear))
      }];

    return (data.length ? <Table {...this.props} columns={columns} dataSource={data} bordered={true} pagination={false} size="small" rowKey={(record) => record.year} /> : "No positions found");
  }

  render() {
    const { selfreviews, reviewers } = this.state;

    let faculty_list = this.non_tenure_track_ft_faculty_list().filter(c => this.get_rank_from_history(c.ranks, this.get_semester(this.props.semesters[0]))).filter(c => this.get_instructorrank(this.get_rank_from_history(c.ranks, this.get_semester(this.props.semesters[0]))).subtype.subtype != "Co-Op").filter(el => reviewers?.find(review => el.id == review.reviewee));
    let campuses = [...new Set(faculty_list.map(fac => fac.campus))].sort((a, b) => this.print_campus(a).localeCompare(this.print_campus(b)));

    const columns = [
      {
        title: "Faculty",
        key: "faculty",
        width: 300,
        render: (text, record, idx) => this.print_full_employee(record.employee),
      }, {
        title: "Primary Reviewer",
        key: "primaryReviewer",
        width: 200,
        render: (text, record, idx) => reviewers?.flatMap(review => review.reviewers.filter(reviewer => reviewer && reviewer.primary_reviewer && review.reviewee == record.id))?.map(reviewer => this.print_full_instructor(reviewer.reviewer_faculty)),
      }, {
        title: "Submitted Retrospective",
        key: "submittedSelfReview",
        width: 200,
        render: (text, record, idx) => get_check_nocheck(selfreviews?.some(el => el.faculty == record.id)),
      },
    ];

    return (
      <Content {...this.props} title={"Teaching Faculty Merit Committee Overview for Review Year " + this.getMeritYear(this.props.semesters) } breadcrumbs={[{ link: "/faculty", text: "Faculty" }, { text: "Teaching Merit" }, { text: "Committee Overiew" }]}>
        {faculty_list.length == 0 && (
          <Alert message="No Review Assignments" description={"There are no merit review assignments defined yet."} type="warning" showIcon />
        )}
        <p>Below is a list of faculty members. Green checkmarks indicate they have submitted documentation for review. Click the plus sign to view details for each faculty member. </p>
        <CustomTabs {...this.props}>
          {campuses.map(campus =>
            <TabPane tab={this.print_campus(campus).split(',')[0]} key={campus}>
              <Table dataSource={faculty_list.filter(el => el.campus == campus)} columns={columns} loading={false} bordered={false} pagination={false} scroll={{ x: 800 }} rowKey={(record) => record.id}
                onExpand={(_, record) => this.getFacultyData(record.id)}
                expandedRowRender={record =>
                  this.renderBasics(record)} />
            </TabPane>
          )}
        </CustomTabs>
      </Content>
    );
  };
};

export { CommitteeOverview };