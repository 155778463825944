import React, { Component } from "react";
import moment from "moment-timezone";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import Content from './Content';
import SectionTable from './SectionTable';
import { PhDStudentTable } from './PhD/PhDStaff';
import CustomTabs from './CustomTabs';

import { PageHeader, Popconfirm, Alert, Table, Form, Switch, Layout, Select, Tabs, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Col, Statistic, Row, List, Card, Divider, Badge, Breadcrumb, message } from 'antd';
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const OptGroup = Select.OptGroup;


class Room extends AppComponent {
  state = {
      endpoint: "/api/room/",
      events: [],
      loading: this.get_room(this.props.match.params.room_id).live_id ? true : false,
      date: moment(new Date()),

      endpoint_sections: "/api/schedule/",
      sections: [],
      loading_sections: true,
      
      endpoint_assignments: "/api/room/deskassignment/",
      assignments: [],
      loading_assignments: true,  
  }

  componentDidMount() {
    this.getData();
  }

  selectedSemester = () => {
    return this.get_semester(this.props.semesters[this.props.semesters.length > 1 ? 1 : 0]);
  }

  dateChanged = (newdate) => {
    const { date } = this.state;
    if (newdate.format("YYYY-MM-DD") != date.format("YYYY-MM-DD")) {
      this.setState({ date: newdate, loading: true }, this.getData);
    } else {
      this.setState({ date: newdate });
    }
  }

  getData = () => {
    const { date } = this.state;
    const room = this.get_room(this.props.match.params.room_id);

    if (room.live_id) {
      this.doGet(this.state.endpoint + this.props.match.params.room_id + "/events/?date=" + date.format("YYYY-MM-DD"),
                    data => this.setState({ events: data, loading: false }));
    }

    if (room.phd_student_lab && this.permission("can", "gradadmin")) {
      this.doGet(this.state.endpoint_assignments + "?desk__room=" + this.props.match.params.room_id,
                    data => this.setState({ assignments: data, loading_students: false }));
    }

    this.doGet(this.state.endpoint_sections + "?room=" + this.props.match.params.room_id,
                  data => this.setState({ sections: data, loading_sections: false }));
  }

  dateCellRender = (value) => {
    const { events, date } = this.state;
    const listData = events[value.format("YYYY-MM-DD")];

    return (
     <List
        itemLayout="horizontal"
        dataSource={ listData }
        renderItem={item => (
          <List.Item>
            <List.Item.Meta>
              <Tooltip title={ "Organization: " + item.organization + ", Confirmation: " + item.confirmation + ", Headcount: " + item.attendees }>{ item.start + " - " + item.end + ": " + item.name + " "}<i>{ item.description }</i></Tooltip>
            </List.Item.Meta>
          </List.Item>
        )}
      />
    );
  }

  findConflictingAssignments = (assignments, start, end) => {
    return assignments.filter(a => (end == null || (this.get_semester(a.start).code <= this.get_semester(end).code)) &&
                                   (a.end == null || (this.get_semester(a.end).code >= this.get_semester(start).code)));
  }

  findAssignmentDesk = (desk) => {
    const { assignments } = this.state;
    const deskassignments = assignments.filter(a => a.desk == desk.id);
    const result = this.findConflictingAssignments(deskassignments, this.selectedSemester().id, this.selectedSemester().id);
    return result.length > 0 ? result[0] : null;
  }

  render() {
    const { loading, events, date, loading_sections, sections, loading_students, students } = this.state;

    const room = this.get_room(this.props.match.params.room_id);

    var chars = [
      { title: "Capacity", content: room.capacity ? room.capacity : "Unknown" },
      { title: "Khoury Owned?", content: room.ccis_owned ? "Yes" : "No" },
      { title: "PhD Student Lab?", content: room.phd_student_lab ? "Yes" : "No" },
      { title: "25Live ID", content: room.live_id ? room.live_id : "Unknown" },
      { title: "Active", content: room.active ? "Yes" : "No" }
    ];

    if (room.nuflex_type || room.nuflex_capacity) {
      chars = chars.concat([{ title: "NUFlex", content: <span><p><b>Type:</b> { room.nuflex_type ? this.print_nuflex_type(room.id) : <i>Unknown</i> }</p><p><b>Capacity:</b> { room.nuflex_capacity ? room.nuflex_capacity : <i>Unknown</i> }</p></span>}]);
    }

    const calendar = [( <Calendar fullscreen={false} onChange={ this.dateChanged } /> ),
                      ( <Card size="small" title={ "Events for " + date.format("dddd MMMM Do, YYYY") }>{ loading ? ( <div align="center"><Spin tip="Loading events" /></div> ) : this.dateCellRender(date) }</Card> )];
                      
    var columns = [
      {
        title: 'Desk',
        key: 'desk',
        width: 100,
        render: (text, record, idx) => record.number,
      },{
        title: 'Assignee',
        key: 'assignee',
        width: 200,
        render: (text, record, idx) => this.findAssignmentDesk(record) ? [this.print_person_first(this.findAssignmentDesk(record).person_detail), " ", this.findAssignmentDesk(record).person_detail.lastname] : null,
      },{
        title: 'Assignment Started',
        key: 'start',
        width: 80,
        render: (text, record, idx) => this.findAssignmentDesk(record) ? this.print_semester(this.findAssignmentDesk(record).start) : null,
      },{
        title: 'Assignment Ends',
        key: 'end',
        width: 80,
        render: (text, record, idx) => this.findAssignmentDesk(record) ? this.print_semester(this.findAssignmentDesk(record).end) : null,
      }
    ];

    return (
      <Content {...this.props} title={ this.print_full_room(room.id) + " (" + this.print_room(room.id) + ")"  } breadcrumbs={ [{ link: "/teaching", text: "Teaching" }, { link: "/teaching/rooms", text: "Rooms" }, { text: this.print_room(room.id) }] }>
        <Divider orientation="left">Characteristics</Divider>
        <List
          grid={ this.grid }
          dataSource={ chars }
          renderItem={item => ( <List.Item><Card size="small" title={ item.title }>{ item.content }</Card></List.Item> )}
        />

        <Divider orientation="left">Calendar of Events</Divider>
        {/* { room.live_id ? (
          <List
            grid={ this.grid_calendar }
            dataSource={ calendar }
            renderItem={item => ( <List.Item>{ item }</List.Item> )}
          />
        ) : null } */}
        <Alert message="Unable to load events" description="25Live no longer exposes room data; Please navigate to 25Live directly to view events." type="warning" showIcon /> 

        { sections && sections?.length > 0 ? (
          <React.Fragment>
            <Divider orientation="left">Section History</Divider>
            <SectionTable {...this.props} data={ sections } loading={ loading_sections } hide_fields={ ["room", "actions"] }  />
          </React.Fragment>
        ) : null }
        
        { (room.phd_student_lab && this.permission("can", "gradadmin")) ? (
          <React.Fragment>
            <Divider orientation="left">Desks Assigned</Divider>
            <Table {...this.props} scroll={{ x: true }} dataSource={room.desks} columns={columns} bordered={false} pagination={false} size="small" />
          </React.Fragment>
        ) : null }

        { room.floorplan ? (
          <React.Fragment>
            <Divider orientation="left">Floor Plan</Divider>
            <img id="floorplan" width={800}/>
            { this.doGetContentType(room.floorplan, "image/png", img => {
                const file = new Blob([img], {type: 'image/png'});
                const fileURL = window.URL.createObjectURL(file);
                document.getElementById("floorplan").setAttribute("src", fileURL);
              })
          }
          </React.Fragment>          
        ) : null }
      </Content>
    );
  }
}

class DeskAssignments extends AppComponent {

  state = {
    endpoint: "/api/room/deskassignment/",
    assignments: [],
    loading: true,

    endpoint_students: "/api/phd/",
    students: [],
    loading_students: true,

    confirm
  }

  componentDidMount() {
    this.getData();
  }

  getAssignments = (callback) => {
    this.doGet(this.state.endpoint, data => { this.setState({ assignments: data, loading: false}); if (callback) { callback(); }});
  }

  selectedSemester = () => {
    return this.get_semester(this.props.semesters[this.props.semesters.length > 1 ? 1 : 0]);
  }

  previousSemester = (semester) => {
    return this.semester_list().filter(s => s.speed == 1 && s.code < this.get_semester(semester).code)[0];
  }

  getData = () => {
    this.getAssignments();
    this.doGet(this.state.endpoint_students, students => this.setState({ students: students, loading_students: false }));
  }

  goUpdate = (assignment, values, callback) => {
    this.doPatch(this.state.endpoint + assignment.id + "/", () => { message.success("Updated assignment."); this.getAssignments(callback); }, JSON.stringify(values));
  }

  goDelete = (assignment, callback) => {
    this.doDelete(this.state.endpoint + assignment.id + "/", () => { message.success("Updated assignment."); this.getAssignments(callback); });
  }

  getAllStudents = () => {
    const { students } = this.state;

    const sem = this.selectedSemester();
    const mystudents = students.filter(ah => (ah.left == null || this.get_semester(ah.left).code >= sem.code) && this.get_semester(ah.matriculated).code <= sem.code);

    return mystudents;  
  }

  getAreaStudents = () => {
    const { students } = this.state;

    const sem = this.selectedSemester();
    const myareas = this.props.user.instructor ? this.get_instructor(this.props.user.instructor).areas : [];

    return this.getAllStudents().filter(s => {
      const this_advisors = s.advisors.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code));
      return this_advisors && (this_advisors.advisors.find(i => this.get_instructor(i).areas.find(a => myareas.includes(a))) != null);
    });  
  }

  getAllStaff = () => {
    const { students } = this.state;

    const sem = this.selectedSemester();
    const myareas = this.props.user.instructor ? this.get_instructor(this.props.user.instructor).areas : [];
    const mystaff = this.employee_list().filter(e => (!e.faculty) || (this.get_rank_from_history(this.get_instructor(e.faculty).ranks, this.selectedSemester()) == null) || ((this.get_instructorrank(this.get_rank_from_history(this.get_instructor(e.faculty).ranks, this.selectedSemester())).subtype.mytype.id != 1) && (this.get_instructorrank(this.get_rank_from_history(this.get_instructor(e.faculty).ranks, this.selectedSemester())).subtype.mytype.id != 2)));

    return mystaff.filter(e => e.areas.length > 0);
  }

  getAreaStaff = () => {
    const { students } = this.state;

    const myareas = this.props.user.instructor ? this.get_instructor(this.props.user.instructor).areas : [];
    return this.getAllStaff().filter(e => e.areas.find(a => myareas.includes(a)));
  }

  findConflictingAssignment = (desk, person) => {
    const { assignments } = this.state;

    const result = [];

    const areastudents = this.getAreaStudents();

    // Ok, here's where all the logic is
    // First let's figure out what we're looking at by collecting all of the 
    // assignments that exist for this desk 
    const deskassignments = assignments.filter(a => a.desk == desk.id);

    // is there an assignment to another person?
    var conflicts = this.findConflictingAssignments(deskassignments, this.selectedSemester().id, this.selectedSemester().id);
    if (conflicts.length == 1) {
      const conflict = conflicts[0];

      console.log(conflict);
  
      result.append("CONFLICT");
    } else if (conflicts.length > 1) {
      console.log("ERROR found multiple conflicting desk assignments")
    }

    // Next lets collect all of the assignments that exist for this person 
    const personassignments = assignments.filter(a => a.person == person);

    // is this person assigned to another desk?
    conflicts = this.findConflictingAssignments(personassignments, this.selectedSemester().id, this.selectedSemester().id);
    if (conflicts.length == 1) {
      const conflict = conflicts[0];

      console.log(conflict);
  
      result.append("CONFLICT");
    } else if (conflicts.length > 1) {
      console.log("ERROR found multiple conflicting desk assignments")
    }

    // AT THIS POINT, NO CONFLICTING ASSIGNMENTS FOR THIS DESK OR PERSON SHOULD EXIST

    return result;
  }


  handleNewAssignment = (desk, person) => {
    const { assignments } = this.state;

    const areastudents = this.getAreaStudents();

    // Ok, here's where all the logic is
    // First let's figure out what we're looking at by collecting all of the 
    // assignments that exist for this desk 
    const deskassignments = assignments.filter(a => a.desk == desk.id);

    // is there an assignment to another person?
    var conflicts = this.findConflictingAssignments(deskassignments, this.selectedSemester().id, this.selectedSemester().id);
    if (conflicts.length == 1) {
      const conflict = conflicts[0];

      //  - if it starts in a previous semester, end that one
      if (conflict.start != this.selectedSemester().id) {
        this.goUpdate(conflict, { end: this.previousSemester(this.selectedSemester().id).id }, () => this.handleNewAssignment(desk, person));

      //  - if it starts in this semester, delete it (we'll recreate)
      } else {
        this.goDelete(conflict, () => this.handleNewAssignment(desk, person));
      }

      return;
    } else if (conflicts.length > 1) {
      console.log("ERROR found multiple conflicting desk assignments")
    }

    // Next lets collect all of the assignments that exist for this person 
    const personassignments = assignments.filter(a => a.person == person);

    // is this person assigned to another desk?
    conflicts = this.findConflictingAssignments(personassignments, this.selectedSemester().id, this.selectedSemester().id);
    if (conflicts.length == 1) {
      const conflict = conflicts[0];

      //  - if it starts in a previous semester, end that one
      if (conflict.start != this.selectedSemester().id) {
        this.goUpdate(conflict, { end: this.previousSemester(this.selectedSemester().id).id }, () => this.handleNewAssignment(desk, person));

      //  - if it starts in this semester, switch the desk (DONE)
      } else {
        this.goUpdate(conflict, { desk: desk.id });
      }

      return;
    } else if (conflicts.length > 1) {
      console.log("ERROR found multiple conflicting desk assignments")
    }

    // AT THIS POINT, NO CONFLICTING ASSIGNMENTS FOR THIS DESK OR PERSON SHOULD EXIST

    // determine end semester BASED ON THIS DESK
    //  - find next assignment
    const nextassignments = deskassignments.filter(a => this.get_semester(a.start).code > this.selectedSemester().code).sort((a, b) => this.get_semester(a.start).code > this.get_semester(b.start).code ? 1 : -1);
    var end = null;
    console.log(nextassignments);
    if (nextassignments.length > 0) {
      const next = nextassignments[0];

      //    - if it is the same person, update the start (DONE)
      if (next.person == person) {
        this.goUpdate(next, { start: this.selectedSemester().id });
        return;   

      //    - otherwise, select preceding semester as our end
      } else {
        end = this.previousSemester(next.start);
      }
    } 

    // now check for end semester BASED ON THIS PERSON
    const nextpersonassignments = personassignments.filter(a => this.get_semester(a.start).code > this.selectedSemester().code).sort((a, b) => this.get_semester(a.start).code > this.get_semester(b.start).code ? 1 : -1);
    if (nextpersonassignments.length > 0) {
      const next = nextpersonassignments[0];
      console.log(nextpersonassignments);
//      end = (!end) || (end.code >= this.get_semester(next.start).code) ? this.previousSemester(next.start) : end;
    }
    
    // if it's a PhD student, we need to make sure that we don't assign beyond when they've left
    const student = areastudents.find(a => a.person == person);
    if (student && student.left && (end == null || (this.get_semester(student.left).code < end.code))) {
      end = this.get_semester(student.left);
    }

    //  - find preceding assignmnet
    const prevassignments = deskassignments.filter(a => a.end && this.get_semester(a.end).code < this.selectedSemester().code).sort((a, b) => this.get_semester(a.start).code < this.get_semester(b.start).code ? 1 : 0);
    if (prevassignments.length > 0) {
      const prev = prevassignments[0];

      //    - if it is the same person, and the end semester is the previous, update the end (DONE)
      if ((prev.person == person) && (this.previousSemester(this.selectedSemester().id).id == prev.end)) {
        this.goUpdate(prev, { end: end ? end.id : null });
        return;
      }
    }

    // create this one
    const values = { start: this.selectedSemester().id, end: end ? end.id : null, person: person, desk: desk.id };
    this.doPost(this.state.endpoint, () => { message.success("Created new assignment."); this.getAssignments(); }, JSON.stringify(values));
  }

  handleChange = (assignment, start, end) => {
    this.goUpdate(assignment, { start: start, end: end });
  }

  findConflictingAssignments = (assignments, start, end) => {
    return assignments.filter(a => (end == null || (this.get_semester(a.start).code <= this.get_semester(end).code)) &&
                                   (a.end == null || (this.get_semester(a.end).code >= this.get_semester(start).code)));
  }

  findAssignmentDesk = (desk) => {
    const { assignments } = this.state;
    const deskassignments = assignments.filter(a => a.desk == desk.id);
    return this.findConflictingAssignments(deskassignments, this.selectedSemester().id, this.selectedSemester().id);
  }

  findAssignmentPerson = (person) => {
    const { assignments } = this.state;
    const personassignments = assignments.filter(a => a.person == person);
    return this.findConflictingAssignments(personassignments, this.selectedSemester().id, this.selectedSemester().id);
  }

  printDesk = (mydesk) => {
    const room = this.room_list().find(r => r.desks.find(d => d.id == mydesk));
    const desk = this.get_room(room.id).desks.find(r => r.id == mydesk);

    return [this.print_room(room.id), " desk ", desk.number];
  }

  printAssigneeDropdown = (desk) => {
    const { assignments } = this.state;

//    const areastudents = this.getAreaStudents();
//    const areaStaff = this.getAreaStaff();

    const allStudents = this.getAllStudents();
    const allStaff = this.getAllStaff();
    const assignment = this.findAssignmentDesk(desk);
    const who = assignment.length > 0 ? assignment[0].person : null;

    return (
        <Select showSearch style={{ width: 300 }} filterOption={this.filter} value={who} onChange={e => this.handleNewAssignment(desk, e)}>
          <OptGroup label="Staff">
            {allStaff.map(s => (<Option key={s.person} value={s.person}>{this.print_person_first(s)} {s.lastname} { s.person == who ? null : this.findAssignmentPerson(s.person).length > 0 ? ["(assigned ", this.printDesk(this.findAssignmentPerson(s.person)[0].desk), ")"] : "(unassigned)" }</Option>))}
          </OptGroup>
          <OptGroup label="Ph.D. Students">
            {allStudents.map(s => (<Option key={s.person} value={s.person}>{this.print_person_first(s)} {s.lastname} { s.person == who ? null : this.findAssignmentPerson(s.person).length > 0 ? ["(assigned ", this.printDesk(this.findAssignmentPerson(s.person)[0].desk), ")"] : "(unassigned)" }</Option>))}
          </OptGroup>
        </Select>
      
    )
  }

  printAssigneeMentor = (desk) => {
    const { assignments, students } = this.state;

    const assignment = this.findAssignmentDesk(desk);
    const assignee = assignment.length > 0 ? assignment[0].person : null;

    const phdstudent = students.find(s => s.person == assignee);
    const employee = this.employee_list().find(e => e.person == assignee);
    const sem = this.selectedSemester();

    if (phdstudent) {
      const advisors = phdstudent.advisors.find(sa => this.get_semester(sa.start).code <= sem.code && (!sa.end || this.get_semester(sa.end).code >= sem.code));
      if (advisors) {
        return this.print_full_instructor_list(advisors.advisors);
      }
    } 

    if (employee) {
      const manager = this.employee_list().find(e => e.id == employee.manager);
      return manager ? [this.print_person_first(manager), " ", manager.lastname] : null;
    }

    return null;
  }

  printAssigneeTitle = (desk) => {
    const { assignments, students } = this.state;

    const assignment = this.findAssignmentDesk(desk);
    const assignee = assignment.length > 0 ? assignment[0].person : null;

    if (!assignee) {
      return null;
    }

    const phdstudent = students.find(s => s.person == assignee);
    const employee = this.employee_list().find(e => e.person == assignee);
    const sem = this.selectedSemester();

    if (phdstudent && this.get_semester(phdstudent.matriculated).code <= sem.code && (!phdstudent.left || this.get_semester(phdstudent.left).code >= sem.code)) {
      return "Ph.D. Student"
    } 

    if (employee) {
      return employee.title;
    }

    return "Not Found";
  }

  printAssigneeStartedDropdown = (desk) => {
    const { assignments, students } = this.state;

    var assignment = this.findAssignmentDesk(desk);
    if (assignment.length == 0) { 
      return null; 
    }
    assignment = assignment[0];

    const past_conflicts = assignments.filter(a => (a.desk == desk.id) && (a.id != assignment.id) && a.end).map(a => this.get_semester(a.end).code).filter(code => code < this.get_semester(assignment.start).code);
    const start_conflict = past_conflicts.length > 0 ? past_conflicts.sort()[past_conflicts.length - 1] : null;
    const end_conflict = assignment.end ? this.get_semester(assignment.end).code : null;

    return (
      <Select showSearch style={{ width: 140 }} value={ this.print_semester(assignment.start) } onChange={e => this.handleChange(assignment, e, assignment.end)}> 
          {this.semester_list().filter(s => s.speed == 1).map(s => <Option key={s.id} value={s.id} disabled={(end_conflict != null && s.code > end_conflict) || (s.code <= start_conflict)}>{this.print_semester(s.id)}</Option>)}
      </Select>
    );
  }

  printAssigneeEndedDropdown = (desk) => {
    const { assignments, students } = this.state;

    var assignment = this.findAssignmentDesk(desk);
    if (assignment.length == 0) { 
      return null; 
    }
    assignment = assignment[0];

    const start_conflict = this.get_semester(assignment.start).code;
    const future_conflicts = assignment.end ? assignments.filter(a => (a.desk == desk.id) && (a.id != assignment.id)).map(a => this.get_semester(a.start).code).filter(code => code > this.get_semester(assignment.end).code) : [];
    const end_conflict = future_conflicts.length > 0 ? future_conflicts.sort()[0] : null;

    return (
      <Select showSearch style={{ width: 140 }} value={assignment.end ? this.print_semester(assignment.end) : "Ongoing"} onChange={e => this.handleChange(assignment, assignment.start, e)}> 
          <Option key={null} value={null} disabled={end_conflict != null}>Ongoing</Option>
          {this.semester_list().filter(s => s.speed == 1).map(s => <Option key={s.id} value={s.id} disabled={(end_conflict != null && s.code >= end_conflict) || (s.code < start_conflict)}>{this.print_semester(s.id)}</Option>)}
      </Select>
    );
  }

  printAssigneeDelete = (desk) => {
    const { assignments, students } = this.state;

    var assignment = this.findAssignmentDesk(desk);
    if (assignment.length == 0) { 
      return null; 
    }
    assignment = assignment[0];

    return (
      <a onClick={e => this.goDelete(assignment)}><Icon type="delete" /></a>
    );
  }

  loadRoomLayout = (id) => {
    const room = this.get_room(id); 
    if (room.floorplan) {
      this.doGetContentType(room.floorplan, "image/png", img => {
        const file = new Blob([img], {type: 'image/png'});
        const fileURL = window.URL.createObjectURL(file);
        document.getElementById("spin-" + room.id).style.visibility = "hidden";
        document.getElementById("floorplan-" + room.id).setAttribute("src", fileURL);
      });
    } 
  }

  render() {
    const { loading, loading_students, students } = this.state;
    const { user } = this.props;

    const rooms = this.room_list().filter(r => r.phd_student_lab == true);

    var columns = [
      {
        title: 'Desk',
        key: 'desk',
        width: 100,
        render: (text, record, idx) => record.number,
      },{
        title: 'Assignee',
        key: 'assignee',
        width: 140,
        render: (text, record, idx) => this.printAssigneeDropdown(record),
      },{
        title: 'Mentor/Manager',
        key: 'mentor',
        width: 200,
        render: (text, record, idx) => this.printAssigneeMentor(record),
      },{
        title: 'Title',
        key: 'title',
        width: 140,
        render: (text, record, idx) => this.printAssigneeTitle(record),
      },{
        title: 'Assignment Started',
        key: 'start',
        width: 80,
        render: (text, record, idx) => this.printAssigneeStartedDropdown(record),
      },{
        title: 'Assignment Ends',
        key: 'end',
        width: 80,
        render: (text, record, idx) => this.printAssigneeEndedDropdown(record),
      },{
        title: 'Delete',
        key: 'delete',
        width: 60,
        align: 'center',
        render: (text, record, idx) => this.printAssigneeDelete(record),
      }
    ];

    return (
      <Content {...this.props} title={"Desk Assignments"} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { text: "Rooms" }, { text: "Desk Assignments" }]}>
        <p>Below is a list of all of the research labs that you have access to.  For each lab, you can update the assignments of Ph.D. students and research staff to desks.  Note that each assignment has a start and end semester (inclusive).  So, if a research scientist was assigned a desk for September 2023 through August 2024, you would select a start of Fall 2023 and an end of Summer 2024.  For students and staff who do not have a defined end date (e.g., most Ph.D. students and staff), you can simply select Ongoing as the end date and the assignment will persist until updated.</p>
        { loading || loading_students ? <Spin tip="Loading data..." /> : <CustomTabs {...this.props} onChange={this.loadRoomLayout}>
          {rooms.filter(r => this.permission("can", "admin") || this.permission("can", "gradadmin") || this.permission("can", "operations") || r.manager==this.props.user.employee).map((room, idx) => {
            return (
              <TabPane tab={this.print_room(room.id)} key={room.id}>
                { room.floorplan ? (
                  <React.Fragment>
                    <Divider orientation="left">Floor Plan</Divider>
                    <Spin id={"spin-" + room.id} tip="Loading floor plan" />
                    <img id={"floorplan-" + room.id} width="100%"/>
                  </React.Fragment>          
                ) : null }
                { idx == 0 ? this.loadRoomLayout(room.id) : null }
                <Divider orientation="left">Desk Assignments</Divider>
                <Table {...this.props} scroll={{ x: true }} dataSource={room.desks} columns={columns} bordered={false} pagination={false} size="small" />
              </TabPane>);})}
        </CustomTabs>
        }
      </Content>
    );
  }
}

export default Room;
export { DeskAssignments };