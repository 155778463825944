import React from "react";
import AppComponent from './AppComponent';
import { get_check, get_nocheck, get_nonecheck, get_pendingcheck } from './Utils';

import { Tooltip, message } from 'antd';

class Status extends AppComponent {
  get_message = () => {
    const { status } = this.props;

    return (status == this.STATUS_GOOD ? "Item is synced with server" : status == this.STATUS_FAILED ? "Item failed to update" : status == this.STATUS_NONE ? "No item exists on server" : "Item being synced with server");
  }

  get_icon = () => {
    const { status } = this.props;

    return (status == this.STATUS_GOOD ? get_check() : status == this.STATUS_FAILED ? get_nocheck() : status == this.STATUS_NONE ? get_nonecheck() : get_pendingcheck());
  }

  render() {
    return ( <Tooltip title={ this.get_message() }>{ this.get_icon() }</Tooltip> );
  }
}

class StatusComponent extends AppComponent {
  state = {
    status: this.STATUS_NONE,
  }

  componentDidMount() {
    this.setState({ status: this.getInitialStatus() });
  }

  setValue = (new_value) => {
    const request = this.getRequest(new_value);

    this.setState({ status: this.STATUS_PENDING }, () => {
      this.doFetch(request.method,
                   request.endpoint,
                   { "Content-Type": "application/json"},
                   request.body,
                   request.method == "POST" ? 201 : "DELETE" ? 204 : 200,
                   "application/json",
                   r => this.setState({ status: (r || request.method == "DELETE") ? this.STATUS_GOOD : this.STATUS_FAILED },
                   () => (r || request.method == "DELETE") ? request.onChange ? request.onChange(r) : null : message.error("Got error while communicating with server.")));

    });
  }
}


export default StatusComponent;
export { Status };