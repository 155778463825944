import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import { WorkflowTable } from "../WorkflowTable";
import { WorkflowSubmit } from "../WorkflowSubmit";
import moment from "moment-timezone";
import Transcript from "../../Transcript";
import NumberFormat from 'react-number-format';

import { Icon, Radio, Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions, Checkbox, DatePicker, Upload } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker
const { Text } = Typography;

const fundingTypes = {
  CAMPUS: "prior_campus_funding",
  STUDENT: "prior_student_involvement_funding",
  PROFESSOR: "prior_professor_funding"
}

const UGMSConferenceSupportForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      uploadedList: []
    }

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({ uploadedList: [] });
      this.props.form.resetFields();
    }

    handleSubmit = () => {
      const { uploadedList } = this.state;
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (err) { return; }
        
        const data = values;
        data.conference_start_date = moment.min(values.conference_dates);
        data.conference_end_date = moment.max(values.conference_dates);
        delete data.conference_dates;
        data.registration_date = moment(values.registration_date);
        Object.values(fundingTypes).forEach(type => { data[type] = data.funding?.includes(type) ? true : false });
        delete data.funding;

        this.props.onSubmit({ ...(data), ...{ files: uploadedList } }, this.reset());
      });
    };

    //functions for file upload
    getFile = (type) => {
      const { uploadedList } = this.state;
      return (
        <React.Fragment>
          {(uploadedList.filter(el => el[1]["type"] == type).length != 0) && (
            <div style={{ marginTop: "10px" }}>
              <p>{type} <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={() => this.onRemove(type)} /></p>
            </div>
          )}
        </React.Fragment>
      )
    };


    setFile = (file, type) => {
      this.setState(state => ({
        uploadedList: [...state.uploadedList, [window.btoa(file), type]],
      }));
    };

    beforeUpload = (file, type) => {
      const reader = new FileReader();
      const setFile = this.setFile;
      reader.onload = function (event) {
        setFile(event.target.result, type);
      };
      reader.readAsBinaryString(file);
    };

    onRemove = type => {
      this.setState(state => {
        const matches = (el) => el[1]["type"] == type;
        const index = state.uploadedList.findIndex(matches);
        const newuploadedList = state.uploadedList.slice();
        newuploadedList.splice(index, 1);
        return {
          uploadedList: newuploadedList,
        };
      });
    };

    disableUpload = (type) => {
      const { uploadedList } = this.state;
      return uploadedList.filter(el => el[1]["type"] == type).length != 0;
    }

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

      return (
        <>
          <p>If you wish to amend a previously-submitted petition, please simply submit a new petition. The Khoury team will simply use the most recent one that you submit.</p>
          <Form>
            <FormItem {...formItemLayout} label="Level" extra="Please select your level of study.">
              {getFieldDecorator("level", { rules: [{ required: true, message: "Please select an option" }] })
                (
                  <Radio.Group>
                    <Radio style={{ display: 'block' }} value={"UG"}>Undergrad</Radio>
                    <Radio style={{ display: 'block' }} value={"MS"}>Masters</Radio>
                  </Radio.Group>
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Conference Name" extra={"What is the name of the conference you are planning on attending?"} colon={false}>
              {getFieldDecorator("conference", {
                rules: [{ required: true, message: "Please enter the name of the conference." }], initialValue: ""
              })
                (<Input style={{ width: 360 }} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Link" extra={"Please include a link (URL with https://www) to the conference."} colon={false}>
              {getFieldDecorator("link", {
                rules: [{ type: 'url', required: true, message: 'Must be a URL beginning with https://www' }],
                initialValue: ""
              })
                (<Input style={{ width: 360 }} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Location" extra={"Please enter the conference location."} colon={false}>
              {getFieldDecorator("location", {
                rules: [{ required: true, message: "Conference location is required." }], initialValue: ""
              })
                (<Input style={{ width: 360 }} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Date(s) of Conference" extra="Please select the date or date range for the conference.">
              {getFieldDecorator("conference_dates", {
                rules: [{ required: true, message: "Please provide dates" },],
              })
                (<RangePicker />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Date Registration Opens" extra="Please select the date registration opens.">
              {getFieldDecorator("registration_date", {
                rules: [{ required: true, message: "Please provide a date" },],
              })
                (<DatePicker />
                )}
            </FormItem>

            <FormItem {...formItemLayout} label="Rationale" extra={"How will this contribute to enhancing your academic experience and supporting your goals?"} colon={false}>
              {getFieldDecorator("rationale", {
                rules: [{ required: true, message: "Please provide a short answer." }], initialValue: ""
              })
                (<TextArea style={{ width: 360 }} rows={4} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="DEIAB" extra={"How does this Conference Relate to DEIAB?"} colon={false}>
              {getFieldDecorator("deiab_relation", {
                rules: [{ required: true, message: "Please provide a short answer." }], initialValue: ""
              })
                (<TextArea style={{ width: 360 }} rows={4} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Community Benefit" extra={"What can you bring back from your experience to the NU community?"} colon={false}>
              {getFieldDecorator("community_benefit", {
                rules: [{ required: true, message: "Please provide a short answer." }], initialValue: ""
              })
                (<TextArea style={{ width: 360 }} rows={4} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Presenting" extra="Are you presenting at this conference?">
              {getFieldDecorator("presenting_at_conf", { rules: [{ required: true, message: "Please select an option" }] })
                (
                  <Radio.Group>
                    <Radio style={{ display: 'block' }} value={true}>Yes</Radio>
                    <Radio style={{ display: 'block' }} value={false}>No</Radio>
                  </Radio.Group>
                )}
            </FormItem>
            {form.getFieldValue('presenting_at_conf') == true &&
              <FormItem {...formItemLayout} label="Presenting Details" extra={"Please upload proof of this and/or your proposal. It must be a PDF and no larger than 10 MB."} >
                {getFieldDecorator("ugms_conference_support_upload", { rules: [{ required: true }] })(
                  <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "UGMS Conference Support Petition" })} accept=".pdf" showUploadList={false}>
                    <Button disabled={this.disableUpload("UGMS Conference Support Petition")}><Icon type="upload" />Upload</Button>
                    {this.getFile("UGMS Conference Support Petition")}
                  </Upload>
                )}
              </FormItem>
            }
            <FormItem {...formItemLayout} label="Attendance Mode" extra="Will you be attending in person or virtually?">
              {getFieldDecorator("attendance_mode", { rules: [{ required: true, message: "Please select an option" }] })
                (
                  <Radio.Group>
                    <Radio style={{ display: 'block' }} value={"In person"}>In Person</Radio>
                    <Radio style={{ display: 'block' }} value={"Virtually"}>Virtually</Radio>
                  </Radio.Group>
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Cost of Registration" extra={"Please enter the cost of registration"} colon={false}>
              {getFieldDecorator("registration_cost", {
                rules: [{ required: true, message: "Cost of registration is required." }], initialValue: ""
              })
                (
                  <InputNumber min={0} max={100000} step={1} precision={0} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Estimated cost of travel" extra={"Please enter the estimated cost of travel"} colon={false}>
              {getFieldDecorator("travel_cost", {
                rules: [{ required: true, message: "Estimated cost of travel is required." }], initialValue: ""
              })
                (
                  <InputNumber min={0} max={100000} step={1} precision={0} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                )}
            </FormItem>
            <FormItem {...formItemLayout} label={"If you received funding, please check all sources that apply:"} colon={false}>
              {getFieldDecorator("funding", { valuePropName: 'checked', rules: [{ required: false, message: "Does this request meet the following requirements?" }] })
                (
                  <Checkbox.Group
                    options={[
                      { label: "Professor", value: fundingTypes.PROFESSOR },
                      { label: "Student Involvement", value: fundingTypes.STUDENT },
                      { label: "Campus", value: fundingTypes.CAMPUS }
                    ]}>
                  </Checkbox.Group>
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="Other funding" extra={"Please describe any funding sources not listed above"} colon={false}>
              {getFieldDecorator("prior_other_funding", {
                initialValue: ""
              })
                (<Input style={{ width: 360 }} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Khoury support this year" extra="Have you received Khoury conference funding to another conference this academic year?">
              {getFieldDecorator("previous_khoury_support", { rules: [{ required: true, message: "Please select an option" }] })
                (
                  <Radio.Group>
                    <Radio style={{ display: 'block' }} value={true}>Yes</Radio>
                    <Radio style={{ display: 'block' }} value={false}>No</Radio>
                  </Radio.Group>
                )}
            </FormItem>
            <FormItem {...this.formItemLayout} label=" " colon={false}><Button type="primary" onClick={this.handleSubmit}>Submit</Button></FormItem>
          </Form>
        </>
      );
    }
  }
);


class UGMSConferenceSupportSearchForm extends AppComponent {
  render() {
    const { form } = this.props;

    return (
      <>
        <FormItem {...this.formItemLayout} label="Level" extra="Optionally select the level of the student.">
          {form.getFieldDecorator("ugmsconferencesupportpetition__level", { onChange: this.props.onSearchChange, })
            (<Select style={{ width: 200 }} allowClear>
              {["UG", "MS"].map(l => <Option key={l} value={l}>{l}</Option>)}
            </Select>)}
        </FormItem>
        <FormItem {...this.formItemLayout} label="Presenting" extra="Optionally select if the student is presenting.">
          {form.getFieldDecorator("ugmsconferencesupportpetition__presenting_at_conf", { rules: [{ required: false, message: "" }] })
            (
              <Radio.Group>
                <Radio style={{ display: 'block' }} value={"True"}>Yes</Radio>
                <Radio style={{ display: 'block' }} value={"False"}>No</Radio>
              </Radio.Group>
            )}
        </FormItem>
      </>
    )
  }
}


class UGMSConferenceSupport extends AppComponent {
  render() {
    const { record } = this.props;
    const item = record.record;
    const filesAttached = record.files?.length > 0;

    //Conditional madness below to make sure the historical form displays nicely as well as the new form

    return (
      <Descriptions bordered title={"UG/MS Conference Support request by " + record.created_by}>
        <Descriptions.Item label="" span={4}><strong>Student Information</strong></Descriptions.Item>
        <Descriptions.Item label="Submitted" span={1}>{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Requestor" span={2}>{[record.created_by + " ", <a key="email" href={"mailto:" + item.email}><Icon type="mail" theme="twoTone" /></a>]}</Descriptions.Item>
        <Descriptions.Item label="NUID" span={1}>{item.student.nuid}</Descriptions.Item>
        <Descriptions.Item label="Campus" span={2}>{this.print_campus(item.student.campus)}</Descriptions.Item>
        <Descriptions.Item label="Level" span={1}>{item.level}</Descriptions.Item>
        {this.permission("can", "staff") &&
          <Descriptions.Item label="" span={2}>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item>
        }
        <Descriptions.Item label="" span={4}><strong>Conference Information</strong></Descriptions.Item>
        {item.location ?
          <>
            <Descriptions.Item label="Conference Name" span={2}>{item.conference}</Descriptions.Item>
            <Descriptions.Item label="Location" span={2}>{item.location}</Descriptions.Item>
            <Descriptions.Item label="Link" span={4}>{item.link}</Descriptions.Item>
            <Descriptions.Item label="Registration Cost" span={2}>{<NumberFormat value={item.registration_cost} prefix="$" thousandSeparator="," displayType='text' decimalScale={0} />}</Descriptions.Item>
            <Descriptions.Item label="Estimated Travel Cost" span={2}>{<NumberFormat value={item.travel_cost} prefix="$" thousandSeparator="," displayType='text' decimalScale={0} />}</Descriptions.Item>
          </>
          :
          <Descriptions.Item label="Conference Name" span={4}>{item.conference}</Descriptions.Item>
        }
        {(item.registration_date || item.conference_start_date) &&
          <>
            <Descriptions.Item label="Date(s) of Conference" span={2}>{moment(item.conference_start_date).format("MMM DD, YYYY") + " to " + moment(item.conference_end_date).format("MMM DD, YYYY")}</Descriptions.Item>
            <Descriptions.Item label="Registration Date" span={2}>{moment(item.registration_date).format("MMM DD, YYYY")}</Descriptions.Item>
          </>
        }
        <Descriptions.Item label="Rationale" span={4}>{item.rationale}</Descriptions.Item>
        {item.deiab_relation &&
          <>
            <Descriptions.Item label="DEIAB Connection" span={4}>{item.deiab_relation}</Descriptions.Item>
            <Descriptions.Item label="Community Benefit" span={4}>{item.community_benefit}</Descriptions.Item>
          </>
        }
        <Descriptions.Item label="Presenting?" span={filesAttached ? 1 : 4}>          
          {item.presenting_at_conf ? "Yes" : "No"}
        </Descriptions.Item>
        {(filesAttached) &&
          <Descriptions.Item label="Attached Files" span={3}>{[record.files.map(el => <a onClick={() => this.openPDF(el.attachment, el.file_type + ".pdf")}><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type + ".pdf"}</a>)]}</Descriptions.Item>
        }        
        {(item.prior_student_involvement_funding !== null || item.prior_professor_funding !== null || item.prior_campus_funding !== null || item.previous_khoury_support !== null) ?
          <>
            <Descriptions.Item label="" span={4}><strong>Funding Already Received</strong></Descriptions.Item>
            <Descriptions.Item label="Campus funding" span={1}>{item.prior_campus_funding ? "Yes" : "No"}</Descriptions.Item>
            <Descriptions.Item label="Student involvement funding" span={2}>{item.prior_student_involvement_funding ? "Yes" : "No"}</Descriptions.Item>
            <Descriptions.Item label="Professor funding" span={1}>{item.prior_professor_funding ? "Yes" : "No"}</Descriptions.Item>
            <Descriptions.Item label="Other funding" span={2}>{item.other_funding ? item.other_funding : "No"}</Descriptions.Item>
            <Descriptions.Item label="Khoury Support" span={2}>{item.previous_khoury_support ? "Yes" : "No"}</Descriptions.Item>
          </>
          :
          <>
            <Descriptions.Item label="" span={4}><strong>Other Information</strong></Descriptions.Item>
            <Descriptions.Item label="I have high financial need" span={1}>{item.high_financial_need ? "Yes" : "No"}</Descriptions.Item>
            <Descriptions.Item label="This is a DEIAB related conference" span={2}>{item.deiab_related_conf ? "Yes" : "No"}</Descriptions.Item>
          </>
        }
      </Descriptions>
    );
  }
}

class UGMSConferenceSupportTable extends WorkflowTable {
  get_columns = () => {
    return [
      {
        title: "Level",
        key: "level",
        width: 50,
        render: (text, record, idx) => record.record.level,
      }, {
        title: "Conference",
        key: "conference",
        width: 200,
        render: (text, record, idx) => record.record.conference
      }
    ];
  }
}

class UGMSConferenceSupportOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ms-ug/conferencesupport/",
    endpoint_viewable: "/api/petitionviewable/",
    visible: false,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
    this.doGet(this.state.endpoint_viewable + "?semester=" + this.props.semesters.join(","), data => { this.setState({ visible: ((data[0] || {}).petitions_visible_cs || false) }); });
  }

  get_name = () => {
    return "Khoury Conference Funding Application";
  }

  get_workflowtype = () => {
    return "ugmsconferencesupportpetition";
  }

  get_name_plural = () => {
    return "Conference Funding Applications";
  }

  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "UG-MS" }, { text: "Conference Support" }];
  }

  get_form = (func) => {
    return <UGMSConferenceSupportForm {...this.props} onSubmit={this.submit_form} />;
  }

  get_record_view = (record) => {
    return <UGMSConferenceSupport {...this.props} record={record} />
  }

  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }

  get_overview_text = () => {
    return (
      <>
        <p>Khoury College provides funding to select students to support conference attendance. Prioritization is given to DEIAB focused conferences and to supporting students who are presenting at conferences.</p>
        <p>A maximum of $500 reimbursement may be awarded per student per academic year.
          This reimbursement may be applied towards travel costs, accommodation costs, and/or conference ticket purchase.
          Funding is not guaranteed.</p>
        <p>Applications are reviewed monthly. Application status can be viewed on the top of this page. Students may apply for funding up until 6 weeks prior to the conference start. Funding applications submitted after this deadline will not be considered.</p>
      </>
    );
  }
}

export { UGMSConferenceSupportOverview, UGMSConferenceSupport, UGMSConferenceSupportTable, UGMSConferenceSupportSearchForm };