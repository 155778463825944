import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import { WorkflowTable } from "../WorkflowTable";

import { Icon, Radio, Steps, Checkbox, Row, Table, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { Step } = Steps;

const CourseWaiverForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      course: 1,
      waiver_type: [],
      uploadedList: [],
    };

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({ waiver_type: [], uploadedList: [] });
      this.props.form.resetFields();
    }

    handleSubmit = () => {
      const { uploadedList } = this.state;
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (!err) {
          values["waiver_types"] = values["waiver_types"].map(el => ({ waiver_type: el }));
          if ("non_khoury_courses" in values) {
            values["non_khoury_courses"] = values["non_khoury_courses"].filter(e => { return e });
          }
          this.props.onSubmit({ ... this.props.formType == "phd" ? {advanced_entry: values["advanced_entry"], course_waiver_type: values["course_waiver_type"] } : {}, ...{ course_petition: values }, ...{ files: uploadedList } }, this.reset());
        } else {
          message.error(err);
        }
      });
    };

    requiredFields = () => {
      const { waiver_type } = this.state;
      return waiver_type != "Experience";
    }

    getFile = (type, field, required) => {
      const { uploadedList } = this.state;
      return (
        <React.Fragment>
          {(uploadedList.filter(el => el[1]["type"] == type).length != 0) && (
            <div style={{ marginTop: "10px" }}>
              <p>{type} <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={() => this.onRemove(type, field, required)} /></p>
            </div>
          )}
        </React.Fragment>
      )
    };

    setFile = (file, type) => {
      this.setState(state => ({
        uploadedList: [...state.uploadedList, [window.btoa(file), type]],
      }));
    };

    beforeUpload = (file, type) => {
      if (file.size / 1024 / 1024 > 10) {
        message.error('File is larger than 10MB');
        return;
      }
      const reader = new FileReader();
      const setFile = this.setFile;
      reader.onload = function (event) {
        setFile(event.target.result, type);
      };
      reader.readAsBinaryString(file);
    };

    setError = (formField) => {
      this.props.form.setFields({
        [formField] : {
          errors: [new Error('Please upload the requested documents')],
      }});
    }

    onRemove = (type, field, required) => {
      if (required) {
        this.setError(field);
      };
      this.setState(state => {
        const matches = (el) => el[1]["type"] == type;
        const index = state.uploadedList.findIndex(matches);
        const newuploadedList = state.uploadedList.slice();
        newuploadedList.splice(index, 1);
        return {
          uploadedList: newuploadedList,
        };
      });
    };

    disableUpload = (type) => {
      const { uploadedList } = this.state;
      return uploadedList.filter(el => el[1]["type"] == type).length != 0;
    }

    CourseModule = (idx, formItemLayout) => {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <React.Fragment>
          <FormItem {...this.formItemLayout} label=" " colon={false}>
            <h4>{"Course " + (idx + 1)}</h4>
          </FormItem>
          <FormItem {...formItemLayout} label="Course Name and Number" extra="Please enter a course name and number">
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".course", { rules: [{ required: this.requiredFields(), message: "Please enter a course name." }] })
              (<Input style={{ width: 360 }} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="College/University Name" extra="Please enter a College/University name">
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".university", { rules: [{ required: this.requiredFields(), message: "Enter a College/University name." }] })
              (<Input style={{ width: 360 }} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Number of Credits" extra="Please enter the number of credits">
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".credits", { rules: [{ required: this.requiredFields(), message: "Please enter a number." }] })
              (<InputNumber min={0} max={48} step={0.5} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Grade (if applicable)" extra="Please enter your grade" >
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".grade", { rules: [{ required: false }] })
              (
                <Select showSearch style={{ width: 360 }} filterOption={this.filter} allowClear={true} >
                  {this.grade_list().map(el => <Option key={el.id} value={el.id}>{this.print_grade(el.id)}</Option>)}
                </Select>
              )}
          </FormItem>
          <FormItem {...formItemLayout} label="Semester the course was/will be taken" extra="Please enter what semester the course was/will be taken">
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".semester", { rules: [{ required: this.requiredFields(), message: "Please a semester." }] })
              (
                <Select showSearch style={{ width: 360 }} filterOption={this.filter} allowClear={true} >
                  {this.semester_list().map(el => <Option key={el.id} value={el.id}>{this.print_semester(el.id)}</Option>)}
                </Select>
              )}
          </FormItem>
          <FormItem {...formItemLayout} label="Instructor First and Last Name" extra="Please enter the instructors first and last name">
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".instructor_name", { rules: [{ required: this.requiredFields(), message: "Please enter the instructors first and last name." }] })
              (<Input style={{ width: 360 }} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Instructors Email" extra="Please enter the email of the instructor">
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".instructor_email", { rules: [{ type: 'email', required: this.requiredFields(), message: "Please enter an email." }] })
              (<Input style={{ width: 360 }} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Was/Is this a graduate level course?" extra="Please select whether this was/is a graduate level course">
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".course_level", { rules: [{ required: this.requiredFields(), message: "Please select whether this was/is a graduate level course." }] })
              (
                <Radio.Group>
                  <Radio style={{ display: 'block' }} value={true}>Yes</Radio>
                  <Radio style={{ display: 'block' }} value={false}>No</Radio>
                </Radio.Group>
              )}
          </FormItem>
          <FormItem {...formItemLayout} label="Did this course count towards another degree you received?" extra="Please select if it was counted">
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".course_degree", { rules: [{ required: this.requiredFields(), message: "Please select whether it counted or not." }] })
              (
                <Radio.Group>
                  <Radio style={{ display: 'block' }} value={true}>Yes</Radio>
                  <Radio style={{ display: 'block' }} value={false}>No</Radio>
                </Radio.Group>
              )}
          </FormItem>
          <FormItem {...formItemLayout} label="Rationale" extra="Please provide a reason and any other comments.">
            {getFieldDecorator("non_khoury_courses." + (idx + 1) + ".rationale", { rules: [{ required: this.requiredFields(), message: "Please input a rationale." }] })
              (<TextArea style={{ width: 360 }} rows={4} />)}
          </FormItem>
        </React.Fragment>
      )
    }

    ExperienceModule = (formItemLayout) => {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <React.Fragment>
          <FormItem {...this.formItemLayout} label=" " colon={false}>
            <h4>{"Work Experience"}</h4>
          </FormItem>
          <FormItem {...formItemLayout} label="Work Experience " extra={"Please describe your experience and any other comments"}>
            {getFieldDecorator("work_experience", {
              rules: [{ required: true, message: "Please input a Work Experience." }]
            })
              (<TextArea style={{ width: 360 }} rows={6} />)}
          </FormItem>
        </React.Fragment>
      )
    }

    waiveTypeDisabled = (type) => {
      const { waiver_type } = this.state;
      if (type == "Taken") {
        return waiver_type.includes("Alternate") || waiver_type.includes("Transfer");
      } else if (type == "Transfer") {
        return waiver_type.includes("Taken") || waiver_type.includes("Experience") || waiver_type.includes("Alternate");
      } else if (type == "Alternate") {
        return waiver_type.includes("Taken") || waiver_type.includes("Experience") || waiver_type.includes("Transfer");
      } else {
        return waiver_type.includes("Alternate") || waiver_type.includes("Transfer");
      }
    }

    onWaiverChange = (checkedValues) => {
      this.setState({ waiver_type: checkedValues });
    }

    render() {
      const { form, formType } = this.props;
      const { getFieldDecorator } = form;
      const { waiver_type, course, uploadedList } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

      return (
        <>
          <p>If you wish to amend a previously-submitted petition, please simply submit a new  {formType == "phd" ? "waiver. The Khoury PhD" : "petition. The Khoury Graduate"} staff will use the most recent one that you submit.</p>
          <div style={{ marginTop: "15px" }}>
            <Form>
              <FormItem {...formItemLayout} label={formType == "phd" ? "I would like to waive the following core course because" : "I would like to request the following petition type:"} extra="Please select a type">
                {getFieldDecorator("waiver_types", { rules: [{ required: true, message: "Please select a waiver type." }] })
                  (
                    <Checkbox.Group onChange={this.onWaiverChange}>
                      <Row><Checkbox disabled={this.waiveTypeDisabled("Taken")} value='Taken'>I have already taken this course elsewhere</Checkbox></Row>
                      {formType != "phd" && (<Row><Checkbox disabled={this.waiveTypeDisabled("Transfer")} value='Transfer'>I would like to transfer Credit</Checkbox></Row>)}
                      {formType != "phd" && (<Row><Checkbox disabled={this.waiveTypeDisabled("Alternate")} value='Alternate'>I want to take a different course</Checkbox></Row>)}
                      <Row><Checkbox disabled={this.waiveTypeDisabled("Experience")} value='Experience'>I have work experience sufficient to waive this course</Checkbox></Row>
                    </Checkbox.Group>
                  )}
              </FormItem>
              {formType == "phd" && ( 
              <>
                <FormItem {...formItemLayout} label="Are you advanced entry?" extra="Please select if you are advanced entry or not.">
                  {getFieldDecorator("advanced_entry", { rules: [{ required: true, message: "Please select an option." }] })
                    (
                      <Radio.Group>
                        <Radio style={{ display: 'block' }} value={true}>Yes</Radio>
                        <Radio style={{ display: 'block' }} value={false}>No</Radio>
                      </Radio.Group>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="I am requesting to waive" extra="Please select a waiver type.">
                  {getFieldDecorator("course_waiver_type", { rules: [{ required: true, message: "Please select a waiver type." }] })
                    (
                      <Radio.Group>
                        <Radio style={{ display: 'block' }} value={"Breadth"}>A breadth area (curriculum prior to Summer 2023)</Radio>
                        <Radio style={{ display: 'block' }} value={"Specialization"}>A specialization (curriculum prior to Summer 2023)</Radio>
                        <Radio style={{ display: 'block' }} value={"Seminar"}>A course area, seminar (curriculum post Fall 2023)</Radio>
                        <Radio style={{ display: 'block' }} value={"Non-seminar"}>A course area, non-seminar (curriculum post Fall 2023)</Radio>
                      </Radio.Group>
                    )}
                </FormItem>
                </>
              )}
              <FormItem {...formItemLayout} label="Khoury Course" extra="Please enter a course">
                {getFieldDecorator("khoury_course", {
                  rules: [{ required: true, message: "Please enter a course" }]
                })(
                  <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                    {this.course_list_graduate().map(el => (
                      <Option key={el.id} value={el.id}>{this.print_full_course(el.id)}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              {waiver_type.length != 0 && (<Divider orientation="left">I hereby request the following {waiver_type.includes("Transfer") ? " credit transfer" : " course substitution"}</Divider>)}
              {(waiver_type.includes("Taken") || waiver_type.includes("Alternate") || waiver_type.includes("Transfer")) && (
                <>
                  {[...Array(course)].map((el, idx) =>
                    <>
                      {this.CourseModule(idx, formItemLayout)}
                    </>
                  )}
                  {waiver_type.includes("Taken") && (
                    <>
                      <FormItem {...formItemLayout} label=" " colon={false}>
                        {[...Array(course)].length < 2 ? (<Button onClick={() => this.setState({ course: course + 1 })}>Add another course</Button>) : null}
                        {" "}
                        {[...Array(course)].length > 1 ? (<Button type="danger" onClick={() => this.setState({ course: course - 1 })}>Delete</Button>) : null}
                      </FormItem>
                    </>
                  )}
                </>
              )}
              {waiver_type.includes("Experience") && (
                <>
                  {this.ExperienceModule(formItemLayout)}

                </>
              )}
              {(waiver_type.includes("Taken") || waiver_type.includes("Transfer") || waiver_type.includes("Alternate") || ((waiver_type.includes("Experience")) && (formType == "ms"))) && (
                <>
                  <Divider orientation="left">Add Documentation</Divider>
                  <p>Please use the below space to upload the required documents, you cannot proceed without upload.</p>
                  <strong>Note: You can only upload one document per field. It must be a PDF and no larger than 10 MB.</strong>
                  <FormItem {...formItemLayout} label="Transcript" extra={"Please upload a copy of your transcript."} >
                    {getFieldDecorator("transcript_upload", { rules: [{ required: true, message: 'Please upload a copy of your official transcript(s)' }] })(
                      <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Transcript" })} accept=".pdf" showUploadList={false}>
                        <Button disabled={this.disableUpload("Transcript")}><Icon type="upload" />Upload Transcript</Button>
                        {this.getFile("Transcript", "transcript_upload", true)}
                      </Upload>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="Course Syllabus" extra={"Please upload a copy of your course syllabus."} >
                    {getFieldDecorator("course_syllabus_upload", { rules: [{ required: true, message: 'Please upload a copy of your course syllabus' }] })(
                      <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Course Syllabus" })} accept=".pdf" showUploadList={false}>
                        <Button disabled={this.disableUpload("Course Syllabus")}><Icon type="upload" />Upload Course Syllabus</Button>
                        {this.getFile("Course Syllabus", "course_syllabus_upload", true)}
                      </Upload>
                    )}
                  </FormItem>
                  {waiver_type.includes("Transfer") ? (
                    <FormItem {...formItemLayout} label="Course Description" extra={"Please upload the course description."} >
                      {getFieldDecorator("course_description_upload", { rules: [{ required: true, message: 'Please upload a copy of your course description' }] })(
                        <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Course Description" })} accept=".pdf" showUploadList={false}>
                          <Button disabled={this.disableUpload("Course Description")}><Icon type="upload" />Upload Course Description</Button>
                          {this.getFile("Course Description", "course_description_upload", true)}
                        </Upload>
                      )}
                    </FormItem>
                  ) : (
                    <FormItem {...formItemLayout} label="Sample Assignment & Work" extra={"Please upload a sample assignment and / or work."} >
                      {getFieldDecorator("sample_assignment_upload", { rules: [{ required: false }] })(
                        <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Sample Assignment" })} accept=".pdf" showUploadList={false}>
                          <Button disabled={this.disableUpload("Sample Assignment")}><Icon type="upload" />Upload Sample Assignment & Work</Button>
                          {this.getFile("Sample Assignment", "sample_assignment_upload", false)}
                        </Upload>
                      )}
                    </FormItem>
                  )}
                </>
              )}
              {(waiver_type.includes("Experience")) && (formType == "ms") && (
                <>
                  <FormItem {...formItemLayout} label="Resume" extra={"Please upload a copy of your Resume."} >
                    {getFieldDecorator("resume_upload", { rules: [{ required: true, message: 'Please upload a copy of your resume' }] })(
                      <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Resume" })} accept=".pdf" showUploadList={false}>
                        <Button><Icon type="upload" />Upload Resume</Button>
                        {this.getFile("Resume", "resume_upload", true)}
                      </Upload>
                    )}
                  </FormItem>
                </>
              )}
              <FormItem {...this.formItemLayout} label=" " colon={false}>
                <Button type="primary" onClick={() => this.handleSubmit()}>Submit</Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);

class CourseWaiverTable extends WorkflowTable {
  get_columns = () => {
    return [
      {
        title: "Type",
        key: "waiver_type",
        width: 120,
        render: (text, record, idx) => record.record.course_petition.waiver_types.map(el => el.waiver_type).join(", "),
      },
      {
        title: "Core Class",
        key: "nu_course",
        width: 120,
        render: (text, record, idx) => (record.record.course_petition.khoury_course ? this.print_course(record.record.course_petition.khoury_course) : "")
      }
    ];
  }
}

const ElectivePetitionForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      petition_type: "khoury",
      uploadedList: [],
    };

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({ petition_type: "khoury", uploadedList: [] });
      this.props.form.resetFields();
    }

    setFile = (file, type) => {
      this.setState(state => ({
        uploadedList: [...state.uploadedList, [window.btoa(file), type]],
      }));
    };

    beforeUpload = (file, type) => {
      const reader = new FileReader();
      const setFile = this.setFile;
      reader.onload = function (event) {
        setFile(event.target.result, type);
      };
      reader.readAsBinaryString(file);
    };

    getFile = (type, field, required) => {
      const { uploadedList } = this.state;
      return (
        <React.Fragment>
          {(uploadedList.filter(el => el[1]["type"] == type).length != 0) && (
            <div style={{ marginTop: "10px" }}>
              <p>{type} <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={() => this.onRemove(type, field, required)} /></p>
            </div>
          )}
        </React.Fragment>
      )
    };

    setError = (formField) => {
      this.props.form.setFields({
        [formField] : {
          errors: [new Error('Please upload the requested documents')],
      }});
    }

    onRemove = (type, field, required) => {
      if (required) {
        this.setError(field);
      };
      this.setState(state => {
        const matches = (el) => el[1]["type"] == type;
        const index = state.uploadedList.findIndex(matches);
        const newuploadedList = state.uploadedList.slice();
        newuploadedList.splice(index, 1);
        return {
          uploadedList: newuploadedList,
        };
      });
    };

    handleSubmit = () => {
      const { uploadedList } = this.state;
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit({ ...values, ...{ files: uploadedList } }, this.reset());
        } else {
          message.error(err);
        }
      });
    };

    requiredFields = () => {
      const { petition_type } = this.state;
      return petition_type != "khoury";
    }

    render() {
      const { form, formType } = this.props;
      const { getFieldDecorator } = form;
      const { petition_type } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };
      return (
        <>
          <p>If you wish to amend a previously-submitted elective petition, please simply submit a new petition. The Khoury {formType == "phd" ? "PhD" : "Graduate"} team will use the most recent one that you submit.</p>
          <div style={{ marginTop: "15px" }}>
            <Form>
              <FormItem {...formItemLayout} label="I would like to submit the following elective petition" extra="Please select a reason">
                {getFieldDecorator("elective_petition.petition_type", { rules: [{ required: true }], initialValue: petition_type, onChange: (event) => this.setState({ petition_type: event.target.value }) })
                  (
                    <Radio.Group>
                      <Radio style={{ display: 'block' }} value={"khoury"}>A Khoury Course</Radio>
                      <Radio style={{ display: 'block' }} value={"non-khoury"}>A non-Khoury Northeastern Course</Radio>
                    </Radio.Group>
                  )}
              </FormItem>
              <FormItem {...formItemLayout} label="Semester the course will be taken" extra="Please enter what semester the course was/will be taken">
                {getFieldDecorator("elective_petition.semester", { rules: [{ required: true, message: "Please a semester" }] })
                  (
                    <Select showSearch style={{ width: 360 }} filterOption={this.filter} allowClear={true} >
                      {this.semester_list().map(el => <Option key={el.id} value={el.id}>{this.print_semester(el.id)}</Option>)}
                    </Select>
                  )}
              </FormItem>
              {petition_type == "khoury" ? (
                <FormItem {...formItemLayout} label="Khoury Course" extra="Please enter a course">
                  {getFieldDecorator("elective_petition.khoury_course", {
                    rules: [{ required: true, message: "Please enter a course" }]
                  })(
                    <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                      {this.course_list_graduate().map(el => (
                        <Option key={el.id} value={el.id}>{this.print_full_course(el.id)}</Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              ) : (
                <React.Fragment>
                  <FormItem {...formItemLayout} label="Course Name and Number" extra="Please enter a course name and number">
                    {getFieldDecorator("elective_petition.non_khoury_course", {
                      rules: [{ required: this.requiredFields(), message: "Please enter a course name" }]
                    })
                      (
                        <Input style={{ width: 360 }} />
                      )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="Course Registration Number (CRN)" extra="This information can be found on Banner">
                    {getFieldDecorator("elective_petition.non_khoury_crn", {
                      rules: [{ required: this.requiredFields(), message: "Please enter a CRN" }]
                    })
                      (
                        <InputNumber style={{ width: 360 }} min={10000} max={60000} />
                      )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="Number of Credits" extra="Please enter the number of credits">
                    {getFieldDecorator("elective_petition.non_khoury_credits", { rules: [{ required: this.requiredFields(), message: "Please enter a number." }] })
                      (<InputNumber min={0} max={48} step={0.5} />)}
                  </FormItem>
                </React.Fragment>
              )}
              <FormItem {...formItemLayout} label="Rationale" extra={"Please provide any other comments here"}>
                {getFieldDecorator("elective_petition.rationale", {
                  rules: [{ required: true, message: "Please input a rationale." }]
                })
                  (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>

              <Divider orientation="left">Add Documentation</Divider>
              <p>Please use the below space to upload the required documents, you cannot proceed without upload.</p>
              {formType == "ms" && (<p>Note: For courses taken outside of Northeastern University, you must submit sample assignments and work. Your form will be denied if you’re missing any required documents.</p>)}              
              <strong>Note: You can only upload one document per field. It must be a PDF and no larger than 10 MB.</strong>

              <FormItem {...formItemLayout} label="Course Syllabus" extra={"Please upload a copy of your course syllabus."} >
                {getFieldDecorator("course_syllabus_upload", { rules: [{ required: true, message: 'Please upload a copy of your course syllabus' }] })(
                  <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Course Syllabus" })} accept=".pdf" showUploadList={false}>
                    <Button><Icon type="upload" />Upload Course Syllabus</Button>
                    {this.getFile("Course Syllabus", "course_syllabus_upload", false)}
                  </Upload>
                )}
              </FormItem>
              {formType == "ms" && (
                <FormItem {...formItemLayout} label="Transcript" extra={"Please upload a copy of your transcript."} >
                  {getFieldDecorator("transcript_upload", { rules: [{ required: true, message: 'Please upload a copy of your official transcript(s)' }] })(
                    <Upload beforeUpload={(file) => this.beforeUpload(file, { "type": "Transcript" })} accept=".pdf" showUploadList={false}>
                      <Button><Icon type="upload" />Upload Transcript</Button>
                      {this.getFile("Transcript", "transcript_upload", false)}
                    </Upload>
                  )}
                </FormItem>
              )}
              <FormItem {...this.formItemLayout} label=" " colon={false}>
                <Button type="primary" onClick={() => this.handleSubmit()}>Submit</Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);

class ElectivePetitionTable extends WorkflowTable {
  get_columns = () => {
    return [
      // {
      //   title: "Requestor",
      //   key: "requestor",
      //   width: 200,
      //   render: (text, record, idx) => record.created_by
      // }, 
      {
        title: "Type",
        key: "petition_type",
        width: 120,
        render: (text, record, idx) => record.record.elective_petition.petition_type
      }
    ];
  }
}

export { CourseWaiverForm, CourseWaiverTable, ElectivePetitionForm, ElectivePetitionTable };