import React from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import { format_nuid } from "../../Utils";
import {  Form, Table, Icon,  Menu,  Dropdown,  Switch,  Modal,  Input,  Typography,  Alert, Button, Divider, Radio, Select} from "antd";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import { string } from "prop-types";
import { FUNDING_TYPES } from "./../Faculty/PhDStudentFunding";


class PhDFundingExport extends AppComponent {
    state = {
        fundingData: null,
        students: null,
    }

    componentDidMount() {
        this.getData();
        this.getWorkflowData();
    }
      
    componentDidUpdate(prevProps) {
        if (prevProps.searchValues !== this.props.searchValues){
           this.getWorkflowData();
        }
    }

    getData = () => {
        this.doGet("/api/phd/", data => this.setState({students : data}));
    }

    getWorkflowData = () => {
        const { searchValues } = this.props;
        if (searchValues) {
            const params = new URLSearchParams("historic=true&no_page=true");
            Object.keys(searchValues).forEach(k => searchValues[k] ? params.set(k, searchValues[k]) : null);
            this.doGet("/api/workflow/?" + params.toString(), data => this.setState({fundingData : data}));
        }
    }

    createFundData = (row, funds) => {
        if(!funds) return row;
        for (let i = 0; i < funds.length; i++) {
            let fundObject = this.get_fund(funds[i].fund)
            let columnName = "Stipend_Budget_" + (i + 1);
            row[columnName] = fundObject.index;
            columnName = "Stipend_Percent_" + (i + 1);
            row[columnName] = funds[i].fraction*100;
        }
        return row;
    }

    determineCandidacy = (student) => {
        let milestones = student.milestones;
        if (milestones) {
            let found = milestones.find(el => el.milestone == "Candidacy");
            return found !== undefined ? true : false;
        } else {
            return false
        }

    }  

    createCSV = () => {
        const { searchValues } = this.props;
        const { fundingData, students } = this.state;
        let data = [];
        
        fundingData.forEach(el => {
            if(el.steps[0].decision == "Accepted" || el.steps[0].decision == "Assigned") {
                let record = el.record;
                let instructor = this.get_instructor(record.instructor);
                let student = students.find(st => st.id == record.student)
                let rowObject = {
                    "created_at" : el.created_at,
                    "Candidacy": this.determineCandidacy(student),
                    "Degree": this.print_degree(student.degree),
                    "Student_First_Name": student.firstname,
                    "Student_Last_Name": student.lastname,
                    "Student NUID": format_nuid(student.nuid),
                    "Term": this.print_semester(record.semester),
                    "Record_Type": "SGA",
                    "SGA_Award_Type": record.funding_type == "TA" ? "T" : "R",
                    "Funding_College": "CS",
                    "Stipend_Amount": this.determineCandidacy(student) ? "$14,763.33" : "$13,733.33",
                    //"Stipend_Position_Number": null,
                    "Stipend_Budget_1": record.funding_type == "TA" ? 200988 : null,
                    "Stipend_Percent_1": 100,
                    "Stipend_Budget_2": null,
                    "Stipend_Percent_2": null,
                    "Amount_Type": null,
                    "Grant_Amount": null,
                    "Grant_Credit_Hours": 12,
                    "Grant_Budget_1": 237610,
                    "Grant_Object_1": record.funding_type == "TA" ? 2391 : 2392,
                    "Grant_Percent_1": 100,
                    "Grant_Budget_2": null,
                    "Grant_Object_2": null,
                    "Grant_Percent_2": null,
                    "Funding_Status": null,
                    "Funding_Type": FUNDING_TYPES[record.funding_type],
                    "Funding_Pathway": null,
                    "Funding_Partner": null,
                    "Funding_Advisor": format_nuid(instructor.nuid),
                    "College_Notes": null,
                    "Department_Notes": record.notes ? record.notes.replace(/"/g, '""'): null,
                }
                rowObject = this.createFundData(rowObject, record.funds)
                data.push(rowObject)
            }
        });
        return data;
    }

    deleteDuplicates = () => {

    }
  
    render() {

        const headers = [
            { label: 'created_at', key: 'created_at'},
            { label: 'Candidacy', key: 'Candidacy'},
            { label: 'Student_First_Name', key: 'Student_First_Name'},
            { label: 'Student_Last_Name', key: 'Student_Last_Name'},
            { label: 'Student NUID', key: 'Student NUID'},
            { label: 'Term', key: 'Term'},
            { label: 'Record_Type', key: 'Record_Type'},
            { label: 'SGA_Award_Type', key: 'SGA_Award_Type'},
            { label: 'Funding_College', key: 'Funding_College'},
            { label: 'Stipend_Amount', key: 'Stipend_Amount'},
            //{ label: 'Stipend_Position_Number', key: 'Stipend_Position_Number'},
            { label: 'Stipend_Budget_1', key: 'Stipend_Budget_1'},
            { label: 'Stipend_Percent_1', key: 'Stipend_Percent_1'},
            { label: 'Stipend_Budget_2', key: 'Stipend_Budget_2'},
            { label: 'Stipend_Percent_2', key: 'Stipend_Percent_2'},
            { label: 'Amount_Type', key: 'Amount_Type'},
            { label: 'Grant_Amount', key: 'Grant_Amount'},
            { label: 'Grant_Credit_Hours', key: 'Grant_Credit_Hours'},
            { label: 'Grant_Budget_1', key: 'Grant_Budget_1'},
            { label: 'Grant_Object_1', key: 'Grant_Object_1'},
            { label: 'Grant_Percent_1', key: 'Grant_Percent_1'},
            { label: 'Grant_Budget_2', key: 'Grant_Budget_2'},
            { label: 'Grant_Object_2', key: 'Grant_Object_2'},
            { label: 'Grant_Percent_2', key: 'Grant_Percent_2'},
            { label: 'Funding_Status', key: 'Funding_Status'},
            { label: 'Funding_Type', key: 'Funding_Type'},
            { label: 'Funding Pathway', key: 'Funding_Pathway'},
            { label: 'Funding Partner', key: 'Funding_Partner'},
            { label: 'Funding Advisor', key: 'Funding_Advisor'},
            { label: 'College Notes', key: 'College_Notes'},
            { label: 'Department Notes', key: 'Department_Notes'},
        ];

        const { disabled  } = this.props;
        const { fundingData, students } = this.state;
        let exportData = fundingData && students ? this.createCSV(): null;

        return (
            <React.Fragment>
                {
                !disabled && fundingData && exportData ? 
                    (
                        <CSVLink filename={"PhDFunding-Data-Export_" + moment().format("DD-MM-YYYY") + ".csv"} headers={headers} data={exportData}>
                            <Button type="primary">
                                Download
                            </Button>
                        </CSVLink>
                    ) : (
                        <Button type="primary"loading={true}>Download</Button> 
                    )
                }
            </React.Fragment>        
        );
    }
  }
  
  export default PhDFundingExport;
