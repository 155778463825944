import React from "react";
import { Button, Icon, message, Alert } from 'antd';
import AppComponent from "./AppComponent";
import Content from "./Content";


class ExportGraduateNU extends AppComponent {
    state = {
        endpoint: "/api/student/exportgraduatenu/",
        endpoint_staff: "/api/instructor/exportgraduatenu/",
    };

    handleCreateUpdate = () => {
        const timer = setTimeout(() => message.error("We are currently unable to reach the GraduateNU App. Please try again later."), 6000);
        message
        .loading('Sending data...', 1.0)
        .then(() => this.doPost(this.permission("cannot", "student") ? this.state.endpoint_staff : this.state.endpoint,
                (response) => { clearTimeout(timer), window.location = response["redirect"] },
                        JSON.stringify({
                            version: this.props.version,
                            groups: this.props.user.groups.map(el => el.name).join(", "),
                            platform: navigator.platform,
                            browser: navigator.appName + " " + navigator.appVersion
                        })));
    }

    

    render() {
        return (
            <Content {...this.props} title={ "GraduateNU Login" } breadcrumbs={ [{ link: "/teaching", text: "Teaching" }, { text: "GraduateNU" }, { text: "Login" }] }>
                < br />
                {this.permission("cannot", "student") ? (
                    <React.Fragment>
                    <p>
                        <b>GraduateNU</b> will receive:<br/>
                        <ul>
                            <li>Your first and lastname and email</li>
                        </ul> 
                    </p>
                    </React.Fragment>) : (

                    <React.Fragment>
                    <p>
                        <b>GraduateNU</b> will receive:<br/>
                        <ul>
                            <li>Your first and last name</li>
                            <li>Your email</li>
                            <li>Your Major</li>
                            <li>Your Photo</li>
                            <li>The list of courses on your transcript (Limited to CRN, course, semester and completion: passed or transfer)</li>
                        </ul> 
                    </p>
                    </React.Fragment>
                    )}
                    <Button type="default" onClick={ () => this.handleCreateUpdate() }><Icon type="form" />Continue to Graduate NU</Button>
            </Content>
        );
    }
}

class ExportOfficeHours extends AppComponent { 
    render(){
        return (
            <Content {...this.props} title={ "Office Hours Login" } breadcrumbs={ [{ link: "/teaching", text: "Teaching" }, { text: "Office Hours" }, { text: "Login" }] }>
                < br />
                <OfficeHoursButton {... this.props} />
            </Content>
        )
    }
}

class OfficeHoursButton extends AppComponent {
    state = {
        endpoint: "/api/student/exportofficehours/",
        endpoint_instr: "/api/instructor/exportofficehours/",
    };

    handleCreateUpdate = () => {
        const timer = setTimeout(() => message.error("We are currently unable to reach the Office Hours App. Please try again later."), 6000);
        message
        .loading('Sending data...', 1.0)
        .then(() => this.doPost((this.permission("can", "faculty") || this.permission("can", "staff")) ? this.state.endpoint_instr : this.state.endpoint,
                (response) => { clearTimeout(timer), window.location = response["redirect"] },
                        JSON.stringify({
                            version: this.props.version,
                            groups: this.props.user.groups.map(el => el.name).join(", "),
                            platform: navigator.platform,
                            browser: navigator.appName + " " + navigator.appVersion
                        })));
    }

    

    render() {
        return (
            <>
                < br />
                { (this.permission("can", "faculty") || this.permission("can", "staff") || this.permission("can", "teacher")) ? (
                    <React.Fragment>
                    <p>
                        <b>Khoury Office Hours</b> will receive:<br/>
                        <ul>
                            <li>The courses you are teaching and the courses you are listed as a coordinator</li>
                        </ul> 
                    </p>
                    </React.Fragment>) : (

                    <React.Fragment>
                    <p>
                        <b>Khoury Office Hours</b> will receive:<br/>
                        <ul>
                            <li>Your first and last name</li>
                            <li>Your email</li>
                            <li>Your Photo</li>
                            <li>Your enrolled courses</li>
                            <li>Courses you TA (if applicable)</li>
                        </ul> 
                    </p>
                    </React.Fragment>
                    )}
                    <Button type="default" onClick={ () => this.handleCreateUpdate() }><Icon type="form" />Continue to Khoury Office Hours</Button>
            </>
        );
    }
}

export { ExportGraduateNU, ExportOfficeHours, OfficeHoursButton };
