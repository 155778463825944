import React from "react";
import AppComponent from "./AppComponent";
import Content from "./Content";
import { Table, Form, Divider, Select, Button, message, Input, Alert } from "antd";

const { TextArea } = Input;
const FormItem = Form.Item;
const { Option } = Select;

const NetworkedDeviceForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {

    state = { 
      loading_check_name: true,
      uniqueDeviceName: true
    };

    handleSubmit = e => {
      const { form, getData, endpoint } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          const data = values;
          this.doPost(endpoint, (data) => {
              form.resetFields();
              getData();
          }, JSON.stringify(data));   
        } else {
          message.error(err);
        }
      });
    };

    checkUniqueness = e => {
      const { endpoint_check_name } = this.props;
      const deviceName = e.target.value;
      this.doGet(endpoint_check_name + "?device_name=" + deviceName,
        data => this.setState({ uniqueDeviceName: data.is_available, loading_check_name: false }));;
    }

    resetCheck = e => {
      this.setState({uniqueDeviceName: true})
    }

    render() {
      const { form } = this.props;
      const { uniqueDeviceName } = this.state;
      const { getFieldDecorator } = form;
  
      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true
      };

      return (
        <Form onSubmit={this.handleSubmit}>
            <>
              <FormItem
                {...formItemLayout}
                label={"Device Name"}
                extra={"Please enter your unique device name. 16 characters maximum."}
                validateStatus={uniqueDeviceName ? '' : 'error'}
                help={uniqueDeviceName ? '' : 'Device name already taken.'}
              >
                {getFieldDecorator("device_name", {
                  rules: [
                    { required: true, message: "Please enter the device name" }, 
                  ],
                })(
                  <Input rows={1} style={{ width: 360 }} maxLength={16} onBlur={this.checkUniqueness} onChange={this.resetCheck}/>
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={"MAC Address"}
                extra={"Your MAC Address should consist of 12 hexadecimal characters, separated by colons (:) or hyphens (-)."}
              >
                {getFieldDecorator("mac_address", {
                  rules: [{ required: true, message: "Please enter a valid MAC Address.", pattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/ }],
                })(
                  <TextArea rows={1} style={{ width: 360 }} />
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={"Building"}
                extra={"Please select the building where your device will be located"}
              >
                {getFieldDecorator("building", {
                  rules: [{ required: true, message: "Please select a building" }],
                })(
                  <Select showSearch style={{ width: 360 }} filterOption={this.filter}>
                    {this.building_list().filter(el => el.campus == this.props.campus).map(el => (
                      <Option key={el.id} value={el.id}>{el.name}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </>
          <FormItem
            {...formItemLayout}
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 }
            }}
          >
            <Button type="primary" htmlType="submit" disabled={!uniqueDeviceName} >
              {"Submit"}
            </Button>
          </FormItem>
        </Form>
      );
    }
  }
);

class NetworkedDeviceListOverview extends AppComponent {
  state = {
    endpoint: "/api/networkeddevice/",
    endpoint_check_name: "/api/networkeddevice/checkname/",
    loading_devices: true,
    viewdevices: [],
  };

  componentDidMount() {
    this.getData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    };
  };

  getData = () => {
    this.doGet(this.state.endpoint, data => this.setState({ viewdevices: data, loading_devices: false }));
  };

  deleteFromState = (recordId) => {
    this.setState((prevState) => {
      const updatedViewDevices = prevState.viewdevices.filter((record) => record.id !== recordId);
      return { viewdevices: updatedViewDevices };
    });
  };


  handleDelete = (recordId) => {
    const { endpoint } = this.state;
    this.doDelete(endpoint + recordId.toString() + "/", () => {
      this.deleteFromState(recordId);
    },
      JSON.stringify());
  }

  render() {
    const { loading_devices, endpoint, viewdevices, endpoint_check_name } = this.state;
    const loading = loading_devices;

    const columns = [
      {
        title: "Device Name",
        key: "device_name",
        width: 100,
        render: (text, record, idx) => record.device_name
      },
      {
        title: "MAC Address",
        key: "mac_address",
        width: 100,
        render: (text, record, idx) => record.mac_address
      },
      {
        title: "Building",
        key: "building",
        width: 100,
        render: (text, record, idx) => record.building_name
      },
      {
        title: "Actions",
        key: "actions",
        width: 100,
        render: (text, record, idx) => (
          record.person.id == this.props.user.person.id && (
            <span>
            <Divider type="vertical" />
            <Button type="danger" onClick={() => this.handleDelete(record.id)}>Delete</Button>
            </span>
          )
        )
      },
    ];

    return (
      <Content
        {...this.props}
        title={"Networked Device Form"}
        breadcrumbs={[ window.location.href.includes("faculty") ? { link: "/faculty", text: "Faculty" } : { link: "/students", text: "Students" }, { text: "Networked Device" }]}
      >
        <p>
          Welcome to the networked device form. 
          Please enter your unique device name, MAC address, and building to register your device for network access.
        </p>
        <React.Fragment>
          <Divider orientation="left">
            Submitted Networked Device Forms
          </Divider>
          <Table
            dataSource={viewdevices}
            columns={columns}
            loading={loading}
            rowKey={(record) => record.index}
          />
         
            <React.Fragment>
              <Divider orientation="left">Networked Devices List</Divider>
              {(this.state.viewdevices.length === 0) ?
                <p>
                  Please fill out the form below.
                </p>
                :
                <p>
                  Resubmit form below to edit your selections.
                </p>}
              <NetworkedDeviceForm
                {...this.props}
                getData={this.getData}
                endpoint={endpoint}
                endpoint_check_name={endpoint_check_name}
                viewdevices={viewdevices}
                loading={loading}
              />
            </React.Fragment>
        </React.Fragment>
      </Content>
    );
  };
};

export { NetworkedDeviceListOverview };
