import React, { Component } from "react";
import moment from "moment-timezone";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './../AppComponent';
import StatusComponent from './../StatusComponent';
import { Status } from './../StatusComponent';
import Preference from './../Preference';
import { add_dividers, add_dividers_horiz, format_nuid, format_percent, format_decimal, getTAStatus, add_brs, oxford } from './../Utils';

import { PageHeader, Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, Rate, message } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

class PhDStudentFundingTable extends AppComponent {
  render() {
    const { loading, funds, hide_fields } = this.props;

    const allcolumns = [
      {
        title: 'Student',
        key: 'student',
        width: 160,
        render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: this.print_full_student(record.student) }; },
      }, {
        title: 'NUID',
        key: 'nuid',
        width: 50,
        render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: format_nuid(record.student.nuid) }; },
      }, {
        title: 'Semester',
        key: 'semester',
        width: 130,
        render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: this.print_semester(record.semester) }; },
      }, {
        title: 'Submitted at',
        key: 'submitted_at',
        width: 130,
        render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: moment(record.submitted_at).format("MMMM Do YYYY, h:mm:ss a") }; },
      }, {
        title: 'Submitted by',
        key: 'submitted_by',
        width: 130,
        render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: this.print_instructor(record.instructor) }; },
      }, {
        title: 'Accepted at',
        key: 'accepted_at',
        width: 130,
        render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: record.accepted_at ? moment(record.accepted_at).format("MMMM Do YYYY, h:mm:ss a") : null }; },
      }, {
        title: 'Index',
        key: 'index',
        width: 80,
        render: (text, record, idx) => record.ta || record.khoury_fellowship || record.notes ? null : this.print_fund(record.fund),
      }, {
        title: 'Name',
        key: 'name',
        render: (text, record, idx) => {
          if (record.ta) {
            return "Teaching Assistant";
          } else if (record.khoury_fellowship) {
            return "Khoury PhD Fellowship";
          } else if (record.notes) {
            return "Other: " + record.notes;
          }
          
          const fund = this.get_fund(record.fund);
          if (fund.owners?.length > 0) {
            return [fund.name," ("].concat(oxford(fund.owners.map(o => this.print_employee(o.owner)))).concat([")"]);
          } else {
            return fund.name;
          }
        },
      }, {
        title: 'Percentage',
        key: 'percentage',
        align: 'right',
        width: 85,
        render: (text, record, idx) => ( <NumberFormat displayType="text" decimalScale={ 1 } fixedDecimalScale={ true } suffix="%" value={ 100*record.fraction } /> ),
      }, {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        width: 80,
        render: (text, record, idx) => { return { props: { rowSpan: record.index == 0 ? record.rowspan : 0 }, children: this.props.getActions(text, record, idx) }; },
      }
    ];

    const columns = allcolumns.filter(el => !hide_fields || !hide_fields.includes(el.key));

    return ( <Table {...this.props} tableLayout='auto' dataSource={ funds } columns={ columns } scroll={{ x: 1200 }} bordered={ false } pagination={ false } size="small" rowKey={ (record,idx) => idx + "-" + record.semester + "-" + record.fund + "-" + record.ta } />
 );
  }
}

export default PhDStudentFundingTable;
