import React, { Component } from "react";
import AppComponent from "./AppComponent";

class CustomRedirect extends AppComponent {
  componentDidMount() {
    const { to } = this.props;
    const { search } = window.location;
    const redirectTo = `${to}${search}`;
    this.props.history.push(redirectTo);
  }

  render() {
    return null;
  }
}

export default CustomRedirect;
