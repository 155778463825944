import React, { Component } from "react";
import { Layout, Card, List, Table, Spin, Form, Switch, Button, Modal, Input, Alert, InputNumber, Radio, Select, Menu, Dropdown, Icon, Tooltip, Checkbox, Divider, message } from 'antd';
const { Header, Content } = Layout;
import LoginLayout from './LoginLayout';
import { devLogin, devSearchUsers } from '../Utils';
import queryString from 'query-string';
import AppComponent from "../AppComponent";

const FormItem = Form.Item;

const Login = Form.create({ name: 'login' })(
  class extends AppComponent {
    state = {
      user: null,
      endpoint_users: "/api/dev/users/",
      users: [],
      loading_users: true,
      endpoint_devlogin: "/api/dev/login/",
    }

    loadUsers = (v) => {
      const { endpoint_users } = this.state;
      this.setState({ loading_users: true, users: [] }, () => devSearchUsers("?search=" + (v ? v.replace(",", "") : ""), data => this.setState({ users: data, loading_users: false })));
    }

    handleLogin = () => {
      const { logged_in } = this.props;
      const { user } = this.state;

      devLogin(user, response => {  logged_in(response.key, true) });
    }


    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const { loading_users, users } = this.state;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };


      return (

        <LoginLayout {...this.props}>
          <Card title="Khoury Administration Site" style={{ width: "40" }}>
            <p>Development Login</p>
            <Form>
              <FormItem {...formItemLayout} label="Login As">
                {getFieldDecorator('users', {
                })(<Select showSearch autocomplete="off" filterOption={this.filter} onSearch={this.loadUsers} onChange={e => this.setState({ user: e })} placeholder="Type here to search" notFoundContent={loading_users ? <Spin size="small" /> : null}>
                {users.map(u => <Option key={u.id} value={u.id}>{u.firstname} {u.lastname} ({u.email})</Option>)}
              </Select>)}
              </FormItem>
              <FormItem>
                <Button type="primary" onClick={() => this.handleLogin()}>Log in</Button>
              </FormItem>
            </Form>
          </Card>
        </LoginLayout>
      );
    }
  }
);

export default Login;
