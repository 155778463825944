import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";

import { Icon, Radio, Table, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Switch, Typography, Modal, Alert, Descriptions } from "antd";
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const MSCourseAuthorizationForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      takenCourseBefore: null,
      course: "",
      uploadedList: [],
    };

    componentDidMount() {
      this.reset();
    }
    
    reset = () => {
      this.setState({ petition_type: "khoury", uploadedList: [], course: "", takenCourseBefore: null }); 
      this.props.form.resetFields();
    }
        
    handleSubmit = () => {
      const { uploadedList } = this.state;
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (!err) {
          values["course"]  = ( values["course"] == "other" ? values["otherCourse"] : values["course"] );
          delete values["otherCourse"]; 
          this.props.onSubmit({...values, ...{files: uploadedList}}, this.reset());
        } else {
          message.error(err);
        }
      });
    };

    getFile = (type) => {
      const { uploadedList } = this.state;
      return(
        <React.Fragment>
          {(uploadedList.filter(el => el[1]["type"] == type).length != 0) && (
            <div style={{marginTop:"10px"}}>
              <p>{ type } <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={ () => this.onRemove(type)} /></p>
            </div>
          )}
        </React.Fragment>
      )};

    setFile = (file, type) => {
      this.setState(state => ({
        uploadedList: [...state.uploadedList, [window.btoa(file), type]],
      }));
    };

    beforeUpload = (file, type) => {
      if (file.size / 1024 / 1024 > 10) {
        message.error('File is larger than 10MB');
        return;
      }
      const reader = new FileReader();
      const setFile = this.setFile;
      reader.onload = function(event) {
        setFile(event.target.result, type);
      };
      reader.readAsBinaryString(file);
  };

    onRemove = type => {
      this.setState(state => {
        const matches = (el) => el[1]["type"] == type;
        const index = state.uploadedList.findIndex(matches);
        const newuploadedList = state.uploadedList.slice();
        newuploadedList.splice(index, 1);
        return {
          uploadedList: newuploadedList,
        };
      });
    };

    disableUpload = (type) => {
      const { uploadedList } = this.state;
      return uploadedList.filter(el => el[1]["type"] == type).length != 0;
    }

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const { takenCourseBefore, uploadedList, course } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };    

      return (
        <>
          <p>If you wish to amend a previously-submitted course authorization, please simply submit a new authorization. The Khoury graduate staff will use the most recent one that you submit.</p>
          <div style={{marginTop:"15px"}}>
            <Form>
              <FormItem {...formItemLayout} label="Course" extra="Please select a course">
                {getFieldDecorator("course", { rules: [{ required: true }], initialValue: course, onChange: event => this.setState({ course: event.target.value })})
                (
                  <Radio.Group>
                    <Radio style={{display: 'block'}} value={10557}>CS 8982 Readings Course</Radio>
                    <Radio style={{display: 'block'}} value={10527}>CS 8674 Project Course</Radio>
                    <Radio style={{display: 'block'}} value={112}>CS 5976 Directed Study</Radio>
                    <Radio style={{display: 'block'}} value={10595}>DS 7995 Project Course</Radio>
                    <Radio style={{display: 'block'}} value={10656}>CY 5984 Research Course</Radio>
                    <Radio style={{display: 'block'}} value={"other"}>Other</Radio>
                  </Radio.Group>
                )}
              </FormItem>
              { course == "other" && (
              <FormItem {...formItemLayout} label="Course" extra="Please enter a course">
                {getFieldDecorator("otherCourse", { rules: [{required: (course == "other"), message: "Please enter a course"}]
                })(
                  <Select showSearch style={{ width: 360 }} filterOption={false} showArrow={false} >
                    {this.course_list_graduate().map(el => (
                      <Option key={el.id} value={el.id}>{this.print_full_course(el.id)}</Option>
                    ))}
                  </Select>   
                )}
              </FormItem>
              )}
              <FormItem {...formItemLayout} label="Instructor" extra="Please select a instructor">
                {getFieldDecorator("instructor", { rules: [{required: true, message: "Please an instructor"}], initialValue: ""})
                (
                <Select showSearch style={{ width: 360 }} filterOption={ this.filter } allowClear={ true } >
                  { this.instructor_list().map(el => <Option key={ el.id } value={ el.id }>{ this.print_full_instructor(el.id) }</Option> ) }
                </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="Number of Credits" extra="Please enter the number of credits">
                {getFieldDecorator("credits", { rules: [{required: true, message: "Please enter a number"}], initialValue: ""})
                (
                  <InputNumber min={0} max={10} step={0.5} />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="For what semester are you requesting the authorization?" extra={"Please input a semester"}>
                {getFieldDecorator("semester", {
                rules: [{ required: true, message: "Please select a semester." }]})
                (
                  <Select showSearch style={{ width: 360 }} filterOption={ this.filter } allowClear={ true } >
                    { this.semester_list().map(el => <Option key={ el.id } value={ el.id }>{ this.print_semester(el.id) }</Option> ) }
                  </Select>
                  )}
              </FormItem>
              <FormItem {...formItemLayout} label="Have you taken a Readings or Project course before?" extra="Please select whether you've taken a Readings or Project course before.">
                {getFieldDecorator("taken_course_before", { rules: [{required: true, message: "Please select yes or no."}], initialValue: takenCourseBefore, onChange: event => this.setState({ takenCourseBefore: event.target.value })})
                (
                  <Radio.Group>
                    <Radio style={{display: 'block'}} value={true}>Yes</Radio>
                    <Radio style={{display: 'block'}} value={false}>No</Radio>
                  </Radio.Group>
                )}
              </FormItem>
              { takenCourseBefore && (
              <FormItem {...formItemLayout} label="What semester?" extra={"Please input a semester"}>
                {getFieldDecorator("semester_taken", {
                rules: [{ required: true, message: "Please select a semester." }]})
                (
                  <Select showSearch style={{ width: 360 }} filterOption={ this.filter } allowClear={ true } >
                    { this.semester_list().map(el => <Option key={ el.id } value={ el.id }>{ this.print_semester(el.id) }</Option> ) }
                  </Select>
                  )}
              </FormItem>
              )}
              <FormItem {...formItemLayout} label="Rationale" extra={"Please provide a reason and any other comments"}>
                {getFieldDecorator("rationale", {
                rules: [{ required: true, message: "Please input a rationale." }], initialValue: ""})
                (<TextArea style={{ width: 360 }} rows={4} />)}
              </FormItem>
              <Divider orientation="left">Add Documentation</Divider>
              <p>Please use the below space to upload the required documents, you cannot proceed without upload.</p>
              <strong>Note: You can only upload one document per field. It must be a PDF and no larger than 10 MB.</strong>
              <FormItem {...formItemLayout} label="Course Description" extra={ "Please upload a document that states the course description, deliverables, timeline and grading. This needs to be approved by the instructor first." } >
                {getFieldDecorator("course_description_upload", { rules: [{ required: true, message: 'Please upload a course description.' }] })(
                  <Upload beforeUpload={(file) => this.beforeUpload(file, {"type": "Course Description"})} accept=".pdf" showUploadList={false}>
                    <Button disabled={this.disableUpload("Course Description")}><Icon type="upload" />Upload Course Description</Button>
                    {this.getFile("Course Description")}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...this.formItemLayout} label=" " colon={ false }>
                <Button type="primary" onClick={ () => this.handleSubmit() }>Submit</Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);

class MSCourseAuthorization extends AppComponent {
  render() {
   const { record } = this.props;
   const item = record.record;
     
    return (
        <Descriptions bordered title={ "MS Course Authorization submission by " + record.created_by}>
          <Descriptions.Item label="Submitted" span={ 2 }>{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
          <Descriptions.Item label="Requestor" span={ 2 }>{[ record.created_by + " ", <a key="email" href={"mailto:" + record.email}><Icon type="mail" theme="twoTone" /></a> ]}</Descriptions.Item>
          <Descriptions.Item label="NUID" span={ 2 }>{ item.student.nuid }</Descriptions.Item>
          { this.permission("can", "staff") && 
          <Descriptions.Item label="" span={ 2 }>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item> }
          <Descriptions.Item label="Campus" span={ 2 }>{ this.print_campus(item.student.campus) }</Descriptions.Item>
          <Descriptions.Item label="Course" span={ 2 }>{ this.print_course(item.course) }</Descriptions.Item>
          <Descriptions.Item label="Semester Requested" span={ 2 }>{ this.print_semester(item.semester) }</Descriptions.Item>
          <Descriptions.Item label="Instructor" span={ 2 }>{ this.print_instructor(item.instructor) }</Descriptions.Item>
          <Descriptions.Item label="Credits" span={ 2 }>{ item.credits }</Descriptions.Item>
          <Descriptions.Item label="Taken Course Before" span={ 2 }>{ item.taken_course_before ? this.print_semester(item.semester_taken ): "No" }</Descriptions.Item>
          <Descriptions.Item label="Rationale" span={ 4 }>{ item.rationale }</Descriptions.Item>
          <Descriptions.Item label="Attached Files" span={ 4 }>{[record.files.map(el => <><a onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type +".pdf"}</a><br /></>) ]}</Descriptions.Item>
        </Descriptions>

      );
   }
}

class MSCourseAuthorizationTable extends WorkflowTable {
  get_columns = () => {
    return [
      {
        title: "Course",
        key: "course",
        width: 120,
        render: (text, record, idx) => this.print_course(record.record.course),
      },
      {
        title: "Taken Course Before",
        key: "taken_course_before",
        width: 120,
        render: (text, record, idx) => record.record.taken_course_before ? this.print_semester(record.record.semester_taken ): "No",
      }
    ];
  }
}

class MSCourseAuthorizationOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ms/courseauthorization/",
    visible: true,
  }

  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "MS Individual Instruction Course Authorization";
  }

  get_workflowtype = () => {
    return "mscourseauthorization";
  }

  get_name_plural = () => {
    return "MS Individual Instruction Course Authorizations";
  }     
  
  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "MS" }, { text: "Course Authorization" }];
  }

  get_form = (func) => {
    return <MSCourseAuthorizationForm {...this.props} formType={"ms"} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
    return <MSCourseAuthorization {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null ; }, JSON.stringify(data));
  }
  
  
  get_overview_text = () => {
    return (
      <p>Petition to take a project, reading or directed study course. For more information about these courses, <strong><a href="https://www.khoury.northeastern.edu/information-for-overview/current-masters-and-certificate-students/masters-academic-support/masters-academic-procedures/" target="_blank">please visit here.</a></strong> Your form will be denied if all the details required are not in the course description.</p>
    );
  }  
}

export { MSCourseAuthorizationOverview, MSCourseAuthorization, MSCourseAuthorizationTable };