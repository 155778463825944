import React, { Component } from "react";

import { PageHeader, Button, Tag, Collapse, Popover, Modal, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, InputNumber, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, Checkbox, message, Descriptions } from 'antd';

import AppComponent from "./AppComponent";

const TranscriptModal = props => {
  const {onCancel, onOk, visible,transcript,loading} = props;
  //let semesters = transcript.map(el => el.semester).filter((v, i, a) => a.indexOf(v) === i);
  //transcript.reverse();
  const course_columns = [
    {
      title: 'Semester',
      key: 'semester',
      render: (text, record, idx) => props.semester == props.print_semester(record.semester) ?  <b>{props.print_semester(record.semester)}</b> : props.print_semester(record.semester),
      
    },{
      title: 'CRN',
      key: 'crn',
      render: (text, record, idx) => record.crn,
      
    }, {
      title: 'Course',
      key: 'course',
      render: (text, record, idx) => record.id ? props.link_course(record.course) : record.course_name,
    }, {
      title: '',
      key: 'withdraw',
      render: (text, record, idx) => record.withdraw ? <Tag color="orange">Withdrawn</Tag> : "",
    },
  ];

  const degree_colums = [
    {
      title: 'Semester',
      key: 'semester',
      render: (text, record, idx) => record ? Object.keys(record)[0]: "",
    }, {
      title: 'Degree',
      key: 'degree',
      render: (text, record, idx) => record ? record[Object.keys(record)[0]]: "",
    },
  ];

      return (
        <Modal
          visible={visible}
          title={ "Transcript" }
          okText={ "Ok" }
          onCancel={ () => { onCancel();  } }
          onOk={ () => { onOk();  } }
          width={800}
          height={500}
        >
        { loading ? <div align="center"><Spin title="Loading transcript" /></div> : (
            <>
              <Descriptions bordered title={ "Details about student" }>
                <Descriptions.Item label="Matriculated">{ transcript.matriculated }</Descriptions.Item>
                <Descriptions.Item label="Projected Graduation">{ transcript.projected_graduation }</Descriptions.Item>
                <Descriptions.Item label="Catalog Term">{ transcript.catalog_term }</Descriptions.Item>
                <Descriptions.Item label="Academic Advisor">{ transcript.academic_advisor }</Descriptions.Item>
              </Descriptions>
              <div style={{paddingTop: "15px", paddingBottom: "15px"}}>
                <h3>Programs Enrolled</h3>
              </div>
              <Table dataSource={transcript.program_enrolled} columns={degree_colums} pagination={false} scroll={{ y: 500 }}/>
              <div style={{paddingTop: "15px", paddingBottom: "15px"}}>
                <h3>Courses Taken</h3>
              </div>
              <Table dataSource={transcript.courses} columns={course_columns} pagination={false} scroll={{ y: 500 }}/>
            </>
          )}
        </Modal>
      );
    };

class Transcript extends AppComponent {
  state = {
      modal_visible: false,
      endpoint: "/api/student/transcript/",
      transcript: [],
      loading: true,
    };

    getTranscript() {
        this.setState({ modal_visible: true })
        this.doGet(this.state.endpoint + this.props.nuid + "/", data => this.setState({ transcript: data, loading: false }));
    }
  
  render() {
    const {modal_visible, transcript,loading} = this.state;
    return (
      <React.Fragment>
        <Button type="default" onClick={ () => this.getTranscript() }><Icon type="form" />Transcript</Button>
              <TranscriptModal
                {...this.props}
                link_course = {this.link_course}
                print_semester = {this.print_semester}
                loading={loading}
                visible={ modal_visible }
                transcript={transcript}
                onCancel={ () => this.setState({ modal_visible: false }) }
                onOk={ () => this.setState({ modal_visible: false }) }
              />
      </React.Fragment>        
    );
  }
}   

export default Transcript;