import React, { Component } from "react";
import moment from "moment-timezone";
import key from "weak-key";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './../AppComponent';
import StatusComponent from './../StatusComponent';
import { Status } from './../StatusComponent';
import Preference from './../Preference';
import { add_dividers, add_dividers_horiz, format_percent, format_decimal } from './../Utils';

import { Tag, PageHeader, Collapse, Table, Alert, Button, Form, Popover, Switch, Modal, Popconfirm,  Card, Radio, List, Layout, Input, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, message } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;

const RequestAuditForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {

    render() {
      const { form, weekcolumns, visible, item, onCancel, onCreate } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
          <Modal
            visible={visible}
            title={item ? "Request Audit for " + this.print_full_student(item.ta) : "" }
            okText={ "Submit" }
            onCancel={ () => { onCancel(); }}
            onOk={ () => { onCreate(); }}
            width={400}
          >
            <Form onSubmit={ this.handleSubmit } >
              <FormItem {...formItemLayout} label="Weeks">
                {getFieldDecorator('week', {
                  rules: [{ required: true, message: 'Please select a week.' }],
                })(<Select mode="tags" style={{ width: '60%' }} onChange={this.handleChange} tokenSeparators={[',']}>
                  { weekcolumns.map(el => <Option key={ el.title } value={ el.title }>{ el.title }</Option> ) }
                </Select>
                )}
              </FormItem>
            </Form>
            <p>By clicking submit you will request an audit of the hours for this student. This action cannot be undone.</p>
          </Modal>
      );
    }
  }
);

class TAHoursTable extends AppComponent {
  state = {
      endpoint_taaudit: "/api/ta/audit/",
      taaudit: [],
      loading_taaudit: true,

      modal_visible: false,
      modal_item: "",
  }

  componentDidMount() {
    this.update();
  }

  update() {
    this.doGet(this.state.endpoint_taaudit, data => this.setState({ taaudit: data, loading_taaudit: false }));
  }

  handleAudit = () => {
    const form = this.formRef.props.form;
    const { modal_item } = this.state;
    form.validateFields((err, values) => {
      if (!err) {
        this.doPost(this.state.endpoint_taaudit, response => { this.setState({ modal_visible: false }); message.success("Submitted"); form.resetFields(); this.update(); }, JSON.stringify({"ta-application": modal_item.id, "week" : values['week'].toString()}));
      } else {
        message.error(err);
      }
    });
  }

  handleInstructorConfirm = (choice, record) => {
    const { taaudit } = this.state;
    const audit = taaudit.filter(el => el.taapplication.id == record.id);
    this.doPatch(this.state.endpoint_taaudit + audit[0].id + "/", response => { message.success("Submitted"); this.update(); }, JSON.stringify({"instructor_confirmed": choice}));
  }

  renderweek = (tahours, taapplication) => {
    const hours = tahours.filter(el => el.taapplication == taapplication.id);
    const value = hours.reduce((r, a) => r + a.hours, 0);
    let percent_worked = value / taapplication.hours;
    let clr = (percent_worked >= 0.75 ) ? ((percent_worked >= 0.9 ) ? ((percent_worked >= 1.0 ) ? "red": "volcano") : "gold") : "";
    return taapplication.ta.level == "PH" ? "—": value > 0 ? ( <Tag color={clr}><NumberFormat value={ value } displayType='text' decimalScale={ 2 } fixedDecimalScale={ true } /></Tag> ) : "";
  }

  total_hours = (tahours, ta_id, pay_rate) => {
    const hours = tahours.filter(el => el.taapplication == ta_id);
    const value = hours.reduce((r, a) => r + a.hours, 0);
    return value > 0 ? (<NumberFormat value={ value * pay_rate } prefix="$" thousandSeparator=","  displayType='text' decimalScale={ 0 } fixedDecimalScale={ true } />) : 0;
  }

  total_hours_sorter = (tahours, ta_id, pay_rate) => {
    const hours = tahours.filter(el => el.taapplication == ta_id);
    const value = hours.reduce((r, a) => r + a.hours, 0);
    return value;
  }

  getActions = (text, record, idx) => {
    const { taaudit, popover_visible } = this.state;
    const taaudits = taaudit.filter(el => el.taapplication.id == record.id && (el.status != "Inaccurate" || el.status != "Inaccurate"));
    return (taaudits.length ?
      (this.permission("can", "faculty") && (taaudits[0].instructor_confirmed == "Requested") ?
      <Popconfirm placement="topRight" arrowPointAtCenter title={ "Are the hours for week(s): " + taaudits[0].week + " correct?" }  onConfirm={() => this.handleInstructorConfirm("Accurate", record) } onCancel={() => this.handleInstructorConfirm("Inaccurate", record) } okText="Yes" cancelText="No"><Icon type="solution" style={{ fontSize: '16px' , color: '#CC4400'}}/></Popconfirm>:
      <Tooltip title="There already is an open audit."><Icon type="stop" style={{ fontSize: '16px'}}/></Tooltip>) :
      <a onClick={ () => this.setState({modal_item: record, modal_visible: true }) }><Icon type="audit" style={{ fontSize: '16px'}}/></a>);
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  render() {
    const { loading, taapplications, tahours, semester, hide_fields, tasemester, hoursminview } = this.props;
    const { loading_taaudit, modal_item, modal_visible } = this.state;

    const start = moment(this.get_semester(tasemester).startdate, "YYYY-MM-DD");
    const end = moment(this.get_semester(tasemester).enddate, "YYYY-MM-DD");

    var date = start;
    var dates = [start];
    while (date < end) {
      date = moment(date).add(1, "days");
      dates.push(date);
    }

    const app_ids = taapplications.map(el => el.id);

    const weeks = dates.reduce((r, a) => { (r[a.format("ww")] = r[a.format("ww")] || {"days": [], "tahours": []})["days"].push(a); return r; }, {});
    const weeknums = Object.keys(weeks);
    weeknums.sort();
    tahours.filter(el => app_ids.includes(el.taapplication)).forEach(el => { const w = moment(el.date, "YYYY-MM-DD").format("ww"); if (weeks[w]) { weeks[w]["tahours"].push(el) } });
    const weekcolumns = weeknums.map(el => { return { "title": weeks[el]["days"][0].format("M/DD") + "-" + weeks[el]["days"][weeks[el]["days"].length-1].format("M/DD"), "key": weeks[el][0], "align": "center", "render": (text, record, idx) => this.renderweek(weeks[el]["tahours"], record)}; });

    const columns = [
      {
        title: 'Student',
        key: 'student',
        width: 150,
        render: (text, record, idx) => [this.print_full_student_reverse(record.ta) + " ", <a key="email" href={ "mailto:" + record.ta.email }><Icon type="mail" theme="twoTone" /></a>, " ", record.ta.available_date ? <Tooltip key="late" title={ "Student is not available until " + moment(record.ta.available_date, "YYYY-MM-DD").format("MMMM Do YYYY") }><Icon type="warning" theme="twoTone" twoToneColor="orange" /></Tooltip> : null, " ", record.ta.available ? null : <Tooltip  key="looking" title="Student is also looking for co-ops"><Icon type="warning" theme="twoTone" twoToneColor="red" /></Tooltip> ],
      }, {
        title: 'Level',
        key: 'level',
        filters: [{
          text: 'MS',
          value: 'MS',
        }, {
          text: 'UG',
          value: 'UG',
        }, {
          text: 'PhD',
          value: 'PH',
        }],
        render: (text, record, idx) => record.ta.level == "PH" ? ( <Tooltip title={ "Advised by " + this.print_full_instructor(record.ta.advisor) }>PhD</Tooltip> ) : record.ta.level,
        onFilter: (value, record) => record.ta.level.indexOf(value) === 0,
      },  {
        title: 'Course',
        key: 'course',
        render: (text, record, idx) =>  this.print_course(record.course),
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        sorter: (a, b) => a.course - b.course,
      }, {
        title: 'Max',
        key: 'max',
        render: (text, record, idx) => record.hours,
      }, {
        title: 'Pay Rate',
        key: 'pay_rate',
        align: 'right',
        render: (text, record, idx) => record.ta.level == "PH" ? "—": "$" + record.pay_rate + "/hr",
        sorter: (a, b) => a.pay_rate - b.pay_rate,
      }, {
        title: 'Total',
        key: 'total',
        dataIndex: 'total',
        align: 'right',
        render: (text, record, idx) => record.ta.level == "PH" ? "—": this.total_hours(tahours, record.id, record.pay_rate),
        sorter: (a, b) => this.total_hours_sorter(tahours, a.id, a.pay_rate) - this.total_hours_sorter(tahours, b.id, b.pay_rate),
      }, {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: this.getActions,
      }].concat(weekcolumns);

    const mycolumns = columns.filter(el => !hide_fields || !hide_fields.includes(el.key));
    return (
      <div>
        <RequestAuditForm {...this.props} weekcolumns={ weekcolumns } item={ modal_item } visible={ modal_visible } onCancel={ () => this.setState({ modal_visible: false }) } wrappedComponentRef={ this.saveFormRef } onCreate={ this.handleAudit } />
        <Table {...this.props} dataSource={ taapplications } columns={ (this.permission("cannot", "student") ? mycolumns : weekcolumns) } loading={ loading || loading_taaudit } scroll={{ x: true }} bordered={ false } pagination={ false } size="small" rowKey="id" key={ "table" } />
      </div>
      );
  }
}

export default TAHoursTable;
