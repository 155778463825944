import React, { Component } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import key from "weak-key";
import moment from "moment-timezone";
import * as Sentry from "@sentry/react";

import { Divider, Tooltip, Tag, Icon, Spin as AntSpin, message, Descriptions, Popover, Button} from "antd";

export function is_northeastern_email(email) {
  return email.match(/^.*@(.*\.)*(neu|northeastern)\.edu$/) != null;
}

export function captureError(err, msg, data, user) {
  Sentry.captureException(err, scope => {
    if (msg)
      scope.addBreadcrumb({
        message: msg
      });
    scope.setUser({ id: user })
    if (data)
      scope.setContext("extra-data", data);
  });
}

export function login(username, password, code_2fa, nuid, callback) {
  fetch('/api/rest-auth/login/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: username, password: password, code_2fa: code_2fa, nuid: nuid }),
  }).then(response => {
    if ((response.status == 200) || (response.headers.get('content-type') == 'application/json')) {
      return response.json();
    } else {
      return { error: "Unexpected status received: " + response.status };
    }
  }).then(callback);
}

export function devLogin(user, callback) {
  fetch('/api/dev/login/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: user }),
  }).then(response => {
    if ((response.status == 200) || (response.headers.get('content-type') == 'application/json')) {
      return response.json();
    } else {
      return { error: "Unexpected status received: " + response.status };
    }
  }).then(callback);
}

export function devSearchUsers(search, callback) {
  fetch('/api/dev/users/' + search, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then(response => {
    if ((response.status == 200) || (response.headers.get('content-type') == 'application/json')) {
      return response.json();
    } else {
      return { error: "Unexpected status received: " + response.status };
    }
  }).then(callback);
}

export function ssologin(code, state, code_2fa, state_2fa, callback) {
  fetch('/api/sso/callback/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ code: code, state: state, code_2fa: code_2fa, state_2fa: state_2fa }),
  }).then(response => {
    if ((response.status == 200) || (response.headers.get('content-type') == 'application/json')) {
      return response.json();
    } else {
      return { error: "Unexpected status received: " + response.status };
    }
  }).then(callback);
}

export function logout(token, callback) {
  fetch('/api/rest-auth/logout/', {
    method: 'GET',
    headers: { 'Authorization': 'Token ' + token }
  })
    .then(response => {
      if ((response.status == 200) || (response.headers.get('content-type') == 'application/json')) {
        return response.json();
      } else {
        return { error: "Unexpected status received: " + response.status };
      }
    })
    .then(callback);
}

// This function determines if a user is allowed to access the app (to match API permissions) or should get the "we don't know who you are screen"
export function SSOAllowAccess(user) {
  // if someone is impersonating a user, they always get access
  if(Number.isFinite(user?.impersonator)){
    return true
  // If don't have an sso user, they shouldn't get access (shouldn't happen though ...)
  } else if (!user?.ssouser || Object.keys(user?.ssouser).length === 0) {
    return false;
  // Otherwise check if they have 1:n SSO user(s) with either allow_login or allow_login_override set. We don't care which user has it set.
  } else {
    return Object.entries(user.ssouser).some(([_, value]) => value.allow_login || value.allow_login_override );
  }
}

export function copy(o) {
  var output, v, key;
  output = Array.isArray(o) ? [] : {};
  for (key in o) {
    v = o[key];
    output[key] = typeof v === "object" ? copy(v) : v;
  }
  return output;
}

export function get_check_nocheck(val) {
  return val ? get_check() : get_nocheck();
}

export function isEmpty(obj) {
  if (obj) {
    for (var x in obj) { return false; }
    return true;
  }
  return true;
} 

export function removeDuplicates(array) {
  return [...new Set(array)];
}

export function manageStateItems(prevState, stateVariable, operation, data) {
  switch (operation) {
    case 'add':
      return {
        [stateVariable]: [...prevState[stateVariable], data]
      };
    case 'update':
      return {
        [stateVariable]: prevState[stateVariable].map(record =>
          record.id === data.id ? { ...record, ...data } : record
        )
      };
    case 'delete':
      return {
        [stateVariable]: prevState[stateVariable].filter(record => record !== data)
      };
    default:
      return prevState;
  }
}

export function get_check() {
  return (
    <Icon
      key="check"
      type="check-circle"
      theme="twoTone"
      twoToneColor="#52c41a"
    />
  );
}

export function get_nocheck() {
  return (
    <Icon
      key="nocheck"
      type="close-circle"
      theme="twoTone"
      twoToneColor="#eb2f96"
    />
  );
}

export function get_nonecheck() {
  return (
    <Icon
      key="nonecheck"
      type="minus-circle"
      theme="twoTone"
      twoToneColor="#999999"
    />
  );
}

export function get_pausecheck() {
  return (
    <Icon
      key="pausecheck"
      type="pause-circle"
      theme="twoTone"
      twoToneColor="#ff7f00"
    />
  );
}

export function get_pendingcheck() {
  return <AntSpin size="small" />;
}

export function get_star() {
  return (
    <Icon
      key="star"
      type="star"
      theme="twoTone"
      twoToneColor="#1890ff"
    />
  );
}

export function get_edit_icon() {
  return (
    <Icon
      key="edit"
      type="edit"
      theme="twoTone"
      twoToneColor="#ff7f00"
    />
  );
}

export function get_merit_tag(rating) {
  if (rating == "Exemplary") {
    return (<Tag color="green">{rating}</Tag>);
  } else if (rating == "Satisfactory") {
    return (<Tag color="blue">{rating}</Tag>);
  } else if (rating == "Unsatisfactory") {
    return (<Tag color="red">{rating}</Tag>);
  } else if (rating == "Not Applicable") {
    return (<Tag color="grey">{rating}</Tag>);
  } else {
    return "-";
  }
}

export function text_max(text, max) {
  return text.length > max ? text.substring(0, max - 3) + "..." : text;
}

// ----- TABLE LIST FORMATTING -----

export function add_dividers(list) {
  return list.reduce(
    (r, a) =>
      r.length
        ? r.concat([<Divider type="vertical" key={"div-" + r.length} />, a])
        : [a],
    []
  );
}

export function add_dividers_horiz(list) {
  return list.reduce(
    (r, a) =>
      r.length
        ? r.concat([
          <Divider
            style={{ margin: "-6px 0px 4px 0px" }}
            key={"div-" + r.length}
          />,
          a
        ])
        : [a],
    []
  );
}

export function add_brs(list) {
  return list.reduce(
    (r, a) => (r.length ? r.concat([<br key={"br-" + r.length} />, a]) : [a]),
    []
  );
}

export function if_empty(list, other) {
  return list?.length > 0 ? list : other;
}

// ----- ARRAY FUNCTIONS -----

export function avg(arr) {
  if (arr.length == 0) {
    return 0;
  }
  return arr.reduce((r, a) => r + a, 0) / (1.0 * arr.length);
}

// ----- STRING FUNCTIONS -----

export function oxford(list) {
  if (!list) {
    return "";
  }
  if (list.length == 0) {
    return "";
  }
  if (list.length == 1) {
    return list[0];
  }
  if (list.length == 2) {
    return [list[0], " and ", list[1]];
  }

  return list
    .slice(0, -1)
    .reduce((r, a) => r.concat([a, ", "]), [])
    .concat([" and ", list[list.length - 1]]);
}

export function format_percent(value, precision) {
  return (
    <NumberFormat
      decimalScale={precision}
      displayType={"text"}
      suffix="%"
      value={100 * value}
    />
  );
}

export function format_decimal(value, precision) {
  return (
    <NumberFormat decimalScale={precision} displayType={"text"} value={value} />
  );
}

export function format_nuid(nuid) {
  if (nuid) {
    var s = nuid + "";
    while (s.length < 9) {
      s = "0" + s;
    }
    return s;
  } else {
    return "";
  }
}

export function median(arr) {
  const mid = Math.floor(arr.length / 2), nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

// ----- LOAD COUNT FUNCTIONS -----

export function get_load_total(load) {
  if (!load) {
    return 0;
  }

  var result = load.load;
  if (load.overload) {
    result += load.overload;
  }
  if (load.buyout) {
    result -= load.buyout;
  }
  if (load.reduction) {
    result -= load.reduction;
  }

  return result;
}

export function print_load_component(val, show_sign, hide_zero) {
  if ((val < 0.01) && (val > -0.01)) { val = 0; }
  if ((val == 0) && hide_zero) { return null; }
  return [show_sign && (val > 0) ? "+" : null, (val < 0) ? "\u2013" : null, <NumberFormat key={`num-${val}`} displayType="text" fixedDecimalScale={true} decimalScale={2} value={Math.abs(val)} />];
}

// ----- TA FUNCTIONS -----

export function is_assigned(state) {
  return (
    state == "Pre-Assigned" ||
    state == "Assigned" ||
    state == "Accepted" ||
    state == "Hire-Submitted" ||
    state == "EIB" ||
    state == "I-9-incomplete" ||
    state == "Hired"
  );
}

export function getTAStatus(ta_id, apps) {
  return apps.filter(
    el => el.ta.id == ta_id && el.state != "Reviewed" && el.state != "Submitted"
  );
}

export function renderStatus(status) {
  status = status == "Pre-Assigned" ? "Reviewed" : status;
  const clr =
    status == "Submitted" || status == "Reviewed"
      ? "grey"
      : status == "Assigned" || status == "I-9-incomplete" || status == "Hire-Submitted" || status == "EIB" || status == "Self-Enrolled" || status == "Cancelled"
        ? "orange"
        : status == "Accepted" || status == "Hired"
          ? "green"
          : "red";
  return <Tag color={clr}>{status}</Tag>;
}

export function createFundBalanceToolTip(status, msg, text) {
  return <span class={status}><Tooltip title={msg}><Icon type="warning" /> {text}</Tooltip></span>;
}

export function renderFundStatus(status, text, showLowBalance = true) {
  return status && showLowBalance ?
    createFundBalanceToolTip(status, (status == "fund-low-balance" ? "This fund currently has insufficient funds" : "This fund is currently negative."), text)
    : status == "fund-negative" && !showLowBalance
      ? createFundBalanceToolTip(status, "This fund is currently negative.", text)
      : text;
}

export function renderAuditStatus(status) {
  const clr =
    status == "Requested" || status == "Submitted"
      ? "grey"
      : status == "Accurate"
        ? "green"
        : "red";
  return <Tag color={clr}>{status}</Tag>;
}

export function getSemesterWeeks(semester) {
  const start = moment(semester.startdate, "YYYY-MM-DD");
  const end = moment(semester.enddate, "YYYY-MM-DD");

  var date = start;
  var dates = [start];
  while (date < end) {
    date = moment(date).add(1, "days");
    dates.push(date);
  }

  return dates.reduce((r, a) => {
    (r[a.format("ww")] = r[a.format("ww")] || []).push(a);
    return r;
  }, {});
}

export function getWeekDeadline(lastdate) {
  const deadline = lastdate
    .clone()
    .tz("America/New_York")
    .isoWeekday("Sunday")
    .set({ hour: 23, minute: 59, second: 59 });

  // If the deadline is not after the original lastdate, add a week
  if (lastdate.format('YYYY-MM-DD HH:mm:ss') >= deadline.format('YYYY-MM-DD HH:mm:ss')) {
    deadline.add(1, 'week');
  }

  return deadline
}

export function isWeekConfirmable(lastdate) {
  return getWeekDeadline(lastdate) >= moment();
}

class Spin extends Component {
  render() {
    return (
      <div className="loading-center">
        <AntSpin tip={this.props.tip} />
      </div>
    );
  }
}

function check_merit_score(review) {
  return review && review.score_research && review.score_teaching && review.score_service;
}

function merit_points(score) {
  return score == "Unsatisfactory" || score == "Not Applicable" ? 0 : score == "Satisfactory" ? 1 : 2;
}

function review_merit_score(review) {
  return review.weight_research * merit_points(review.score_research) +
    review.weight_teaching * merit_points(review.score_teaching) +
    review.weight_service * merit_points(review.score_service);
}

export function get_merit_score(merit) {
  if ((!merit) || (!check_merit_score(merit.review_committee)) | (!check_merit_score(merit.review_dean))) {
    return null;
  }

  return (2.0 / 3.0 * review_merit_score(merit.review_committee)) + (1.0 / 3.0 * review_merit_score(merit.review_dean));
}

export { Spin };
