import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './../AppComponent';
import StatusComponent from './../StatusComponent';
import { Status } from './../StatusComponent';
import Preference from './../Preference';
import Content from './../Content';
import { add_brs, add_dividers, add_dividers_horiz, format_percent, format_decimal, is_assigned, getTAStatus, copy, getSemesterWeeks, oxford, format_nuid, get_nocheck, get_check, get_check_nocheck, Spin } from './../Utils';

import { Popconfirm, Button, PageHeader, Timeline ,Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, Tooltip, InputNumber, Icon, Menu, Dropdown, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, Tabs, Modal, message, Checkbox } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

const PhDStudentSelfReview = Form.create({ name: 'form' })(
  class extends AppComponent {
    state = {
        endpoint: "/api/phd/selfreview/",
        review: null,
        loading_review: true,
    }
  
    componentDidMount() {
      this.getData()
    }

    componentDidUpdate(prevProps) {
      if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
        this.getData();
      }
    }
    
    getCanonicalSemester = () => {
      return this.props.semesters.length > 1 ? this.props.semesters[1] : this.props.semesters[0];
    }
  
    getData = () => {
      this.doGet(this.state.endpoint + "?semester=" + this.getCanonicalSemester(), data => this.setState({ review: data?.length > 0 ? data[0] : null, loading_review: false }));
    }
    
    handleCreateUpdate = () => {
      const { semesters, semester } = this.props;
      const form = this.props.form;
      const { review } = this.state;
  
      form.validateFields((err, values) => {
        if (err) { return; }
        
        values["student"] = this.props.user.phd_student;
              
        if (review) {
          this.doPatch(this.state.endpoint + review.id + "/", () => { message.success("Updated self-review."); this.getData(); }, JSON.stringify(values));
        } else {
          this.doPost(this.state.endpoint, () => { message.success("Submitted self-review."); this.getData(); }, JSON.stringify(values));
        }
      });
    }
  
    render() {
      const { semester, semesters, form } = this.props;
      const { getFieldDecorator } = form;
      const { loading_review, review } = this.state;
  
      const alert = semester.startsWith("Summer") ? ( <Alert message="No academic reivew" description={ "We do not have academic reviews during summer semesters." } type="warning" showIcon /> ) : moment(this.get_semester(semesters[0]).startdate, "YYYY-MM-DD") > moment() ? ( <Alert message="Semester not yet started" description={ "The " + semester + " semester has not started yet, so you cannot submit a self review." } type="warning" showIcon /> ) : moment(this.get_semester(this.getCanonicalSemester()).enddate, "YYYY-MM-DD") < moment() ? ( <Alert message="Semester already over" description={ "The " + semester + " semester has already finished, so you cannot submit a self review." } type="warning" showIcon /> ) : null;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
          
      return (
        <Content {...this.props} title={ "PhD student self-review for " + semester } breadcrumbs={ [{ link: "/student", text: "Student" }, { text: "PhD" }, { text: "Self-Review" }] }>
          <p>Academic review is a process that is conducted in the Fall and Spring semesters to review each PhD student's academic progress.
             "Satisfactory progress" means: progress towards completing the milestones of your PhD program: 
             <a href="https://catalog.northeastern.edu/graduate/computer-information-science/computer-science/computer-science-phd/#programrequirementstext" target="_blank">Computer Science</a>; <a href="https://catalog.northeastern.edu/graduate/computer-information-science/cybersecurity/cybersecurity-bachelors-degree-entrance-phd/#programrequirementstext" target="_blank">Cybersecurity</a>; <a href="https://catalog.northeastern.edu/graduate/computer-information-science/health-informatics/personal-health-informatics-phd/#programrequirementstext/" target="_blank">Personal Health Informatics</a>.
             Particularly for students who are past the coursework and teaching/TA'ing milestones, 
             the primary criteria that the faculty will evaluate satisfactory progress on is progress on research. 
             Research progress can be demonstrated through activities such as: progress towards submitting a research paper, or progress towards developing a thesis topic.</p>

          <p>There are two parts to this process:</p>
          <ol>
            <li>A formal review by faculty in the student's research area, which results in an evaluation of satisfactory, marginal, or unsatisfactory. The faculty committee 
              will provide each student with a brief statement that summarizes their evaluation and, if necessary, directs the student to specific interventions to improve 
              their progress in the program. The student's advisor is asked to provide more detailed advice and feedback in person after the review, especially if the student 
              does not receive a "satisfactory."  The review process considers input from the student themselves, along with the student’s advisor(s) and faculty teaching courses 
              that the student is taking or TA’ing. Students provide input for the review by completing this review form, and typically provide no more than 3-4 sentences
              for each required question.</li>
            <p></p>
            <li> A one-on-one meeting of the student and their PhD advisor(s), where they can together look back and look forward at the student's PhD progress and plans. 
              Advisors should use the opportunity to discuss the student's strengths and weaknesses, suggest specific strategies to improve skills (writing, research, 
              presentation, time management, etc.), make research plans for the next 6 months, discuss the student's future career goals (academia, industry, etc.) 
              and offer advice on what research/courses/internships/networking the student should engage in to better prepare themselves to achieve those goals. 
              Students should use this opportunity to ask for specific advice on the aforementioned aspects, communicate what their future career goals are, 
              and communicate what additional forms of support they feel they might need from their advisor.  First-year students should also use this opportunity 
              to ask about "how things work" (e.g., Academic Review, courses, conferences, networking, interactions with your research group, attending seminars and 
              hiring talks, work-life balance, etc.) as academia has many unwritten expectations and advisors are the ones who clarify these for junior students.</li>
          </ol>

          { alert }
  
          { loading_review ?  <Spin tip="Loading review" /> : semester.startsWith("Summer") ? null : (
            <React.Fragment>
              <p>&nbsp;</p>
              <Form onSubmit={this.handleSubmit} >
                <FormItem {...formItemLayout} label="Semester" extra="You can switch semesters by using the semester control in the upper right corner of the screen.">
                  {getFieldDecorator('semester', {
                    rules: [{ required: true, message: 'Please select a semester.' }],
                    initialValue: this.getCanonicalSemester(),
                  })(<Select showSearch style={{ width: 220 }} filterOption={ this.filter } disabled={ true } >
                    <Option value={ this.getCanonicalSemester() }>{ semester }</Option>
                  </Select>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Academic Activities" extra="What academic activities have you engaged in since your last Academic Review? Briefly describe coursework, TA-ing, and research accomplishments. First-year students: describe your progress on establishing your advisor and identifying a research topic.">
                  {getFieldDecorator('statement', {
                    rules: [{ required: true, message: 'Please answer the question.' }],
                    initialValue: review ? review.statement : null,
                  })(<TextArea rows={ 4 } disabled={ alert != null }/>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Conferences" extra="Did you attend any conferences? If so, which conferences? Did you attend virtually or in person? Did you present?">
                  {getFieldDecorator('conferences', {
                    initialValue: review ? review.conferences : null,
                  })(<TextArea rows={ 4 } disabled={ alert != null }/>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Extra-Curriculars" extra="Have you organized, participated in, or volunteered for any extracurricular activities such as a Khoury social or mentoring event or a conference? Do you attend networking or meetup groups to connect with others who share your interests, either at Khoury or elsewhere?  Have you formed networking relationships with faculty members and other people in your research area and lab? Do you regularly attend research seminars and hiring talks at Northeastern?">
                  {getFieldDecorator('extra_curricular', {
                    initialValue: review ? review.extra_curricular : null,
                  })(<TextArea rows={ 4 } disabled={ alert != null }/>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Plans for next 6 months" extra="Looking ahead to the next semester, what are your plans in terms of research and/or coursework?">
                  {getFieldDecorator('plans', {
                    rules: [{ required: true, message: 'Please answer the question.' }],
                    initialValue: review ? review.plans : null,
                  })(<TextArea rows={ 4 } disabled={ alert != null }/>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Follow-up from last Academic Review" extra="If any issues were raised in the previous Academic Review, how have you addressed them this semester? How could we have supported you better in addressing these issues?">
                  {getFieldDecorator('issues', {
                    initialValue: review ? review.issues : null,
                  })(<TextArea rows={ 4 } disabled={ alert != null }/>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Had one-on-one Academic Review Meeting with Advisor" extra="Did you meet with your  advisor to discuss your progress this semester and plans for the next semester? (You can meet before or after Academic Review. If you haven't met yet, please come back and check this box if you meet in the next month.)">
                  {getFieldDecorator("one_on_one", { rules: [{required: true, message: "Please select yes or no."}], initialValue: review ? review.one_on_one : null,})
                  (
                    <Radio.Group>
                      <Radio style={{display: 'block'}} value={true}>Yes</Radio>
                      <Radio style={{display: 'block'}} value={false}>No</Radio>
                    </Radio.Group>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="General Feedback" extra="We welcome additional feedback to be shared with your advisors, faculty in your research area, and the College. Has anything contributed to your success that wasn't covered above? Did you enjoy a particular event or process and wish to expand that programming? Do you want us to focus on something specific in the upcoming year?">
                  {getFieldDecorator('suggestions', {
                    initialValue: review ? review.suggestions : null,
                  })(<TextArea rows={ 4 } disabled={ alert != null }/>
                  )}
                </FormItem>
                
                <p>
                  Finally, you can optionally provide confidential feedback on your PhD mentors/advisors. Your response to the Confidential Feedback question is sent directly to the PhD 
                  Advising team (the Assistant Director of Graduate Student Services & Assistant Dean for Graduate Administration), and is not shared with the Academic Review committee. 
                  Depending on the nature of your response, it may be shared with the Associate Dean for Graduate Programs, the Associate Dean for Faculty, the Senior Associate Dean 
                  for Academic Affairs, or the Dean. If your advisor is one of these Associate Deans, your response will not be shared with them. 
                </p>

                <FormItem {...formItemLayout} label="Confidential Feedback" extra="Please provide optional confidential feedback on your PhD mentor(s)/advisor(s).">
                  {getFieldDecorator('confidential', {
                    initialValue: "",
                  })(<TextArea rows={ 4 } disabled={ alert != null }/>
                  )}
                </FormItem>

                <FormItem {...formItemLayout} wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 }, }} >
                  <Button type="primary" onClick={ this.handleCreateUpdate } disabled={ alert != null }>{ review == null ? "Submit" : "Update" }</Button>
                </FormItem>
              </Form> 
            </React.Fragment>
          )}
        </Content>
      );
    }
  }
);

class PhDCard extends AppComponent {
  state = {
      endpoint_phd: "/api/phd/card/",
      phd: [],
      loading_phd: true,
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.doGet(this.state.endpoint_phd, data => this.setState({ phd: data[0], loading_phd: false }));
  }
  

  render() {

    const { loading_phd, phd } = this.state;

    const date_format = "MMM Do YYYY";

    let events = [];

    if (loading_phd) {
      return ( <Spin tip="Loading data" /> );
    }

    // Milestone events
    events = events.concat(phd.milestones.filter(el => el.approved_at != null).map(el => { return { color: "green", date: moment(el.approved_at), text: "Completed " + el.milestone + " milestone" }; }));
    if ((phd.milestones.filter(el => el.milestone == "Paper Requirement") && phd.paper_requirement)) {
      events = events.concat([{ color: "green", date: moment(phd.paper_requirement.approved_at), text: "Completed Paper Requirement milestone" }]);
    }
    
    events.sort((a,b) => a.date.valueOf() - b.date.valueOf());

    return ( <Card size="small" title={ "PhD Milestones" }>
                <Timeline mode={"left"}>{ events.map(el => <Timeline.Item dot={ get_check() } label={ "el.date" }><b>{el.text}</b>{": " + el.date.format(date_format)}</Timeline.Item>) }</Timeline> 
             </Card>);
  }
}



export { PhDStudentSelfReview, PhDCard };
