import React, { Component } from "react";
import {Button, Modal, Form, Input, Icon, Select, message } from 'antd';
import AppComponent from "./AppComponent";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const TYPES = [
  {id: "d2d09adf-0bf9-47a5-b315-70d094883c0b", name: "Bug"},
  {id: "6899234c-cb92-4b57-a3c5-b2217a999d43", name: "Feedback"},
  {id: "58fd3741-6466-4ce3-9474-31d351e48046", name:"Feature"}
];

const FeedbackForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {

    render() {
      const { visible, onCancel, onCreate, form} = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Modal
          visible={visible}
          title={ "Provide some Feedback" }
          okText={ "Save" }
          onCancel={ () => { onCancel();  } }
          onOk={ () => { onCreate();  } }
          width={800}
        >
        <Form onSubmit={ this.handleSubmit } >
        <FormItem {...formItemLayout} label="Type" extra="Please select the type of feedback.">
          {getFieldDecorator('label', {
            rules: [{ required: true, message: 'Please select a type.' }],
            initialValue: "",
          })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } >
            { TYPES.map((el) => <Option key={ el.name } value={ el.name }>{ el.name }</Option> ) }
          </Select>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Priority" extra="Please select a priority.">
          {getFieldDecorator('priority', {
            rules: [{ required: false }],
            initialValue: "",
          })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } >
            { ["N/A", "Low", "Medium", "High", "Urgent"].map((el) => <Option key={ el } value={ el }>{ el }</Option> ) }
          </Select>
          )}
        </FormItem>
          <FormItem {...formItemLayout} label="Title" extra="Please provide a title.">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Please input a title or summary.' }],
              initialValue: "",
            })(<Input style={{ width: 360 }} /> )}
          </FormItem>
          <FormItem {...formItemLayout} label="Extra" extra="Please provide any extra details. Markdown supported.">
            {getFieldDecorator('notes', {
              rules: [{ required: true, message: 'Please input extra details.' }],
              initialValue: "",
            })(<TextArea style={{ width: 360 }} rows={4} /> )}
          </FormItem>
        </Form>
        </Modal>
      );
    }
  }
);


class Feedback extends AppComponent {
  state = {
    modal_visible: false,
    endpoint: "/api/app_feedback/",
  };

  handleCreateUpdate = () => {
    const form = this.formRef.props.form;

    form.validateFields((err, values) => {
      if (err) { return; }

      this.setState({ modal_visible: false });
      this.doPost(
        this.state.endpoint,
        () => {
          message.success("Submitted Feedback.");
          form.resetFields();
        },
        JSON.stringify({
          title: values.title,
          label: values.label,
          notes: values.notes,
          priority: values.priority,
          version: this.props.version,
          groups: this.props.user.groups.map((el) => el.name).join(", "),
          platform: navigator.platform,
          browser: navigator.appName + " " + navigator.appVersion,
        })
      );
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    return (
      <React.Fragment>
        <Button
          type="default"
          onClick={() => this.setState({ modal_visible: true })}
        >
          <Icon type="form" />
          Provide Feedback
        </Button>
        <FeedbackForm
          {...this.props}
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.modal_visible}
          onCancel={() => this.setState({ modal_visible: false })}
          onCreate={this.handleCreateUpdate}
        />
      </React.Fragment>
    );
  }
}

export { Feedback };