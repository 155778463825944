import React, { Fragment } from "react";
import AppComponent from "../AppComponent";
import Content from "../Content";
import { Form, Tooltip, Divider, Select, Input, InputNumber, Button, message, Radio, Upload, Icon, Alert } from "antd";

const RadioGroup = Radio.Group;
const { TextArea } = Input;
const FormItem = Form.Item;
const { Option } = Select;


const NUMBEROFAPPLICATIONSALLOWED = [1, 2, 3];

const ApprenticeStudentApplicationForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {

    state = {
      uploadedList: []
    }

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({ uploadedList: [] });
      this.props.form.resetFields();
    }

    handleSubmit = e => {
      const { uploadedList } = this.state;
      const { form, semesters, updateSubmittedState, endpoint, viewform } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {

        if (!err) {
          const projects = [];
          NUMBEROFAPPLICATIONSALLOWED.forEach(num => {
            projects.push({
              project: values[`project${num}`],
              reasoning: values[`reasoning${num}`],
              preference_rank: num,
            });
            delete values[`project${num}`];
            delete values[`reasoning${num}`];
          });

          //formatting underrepresented_groups
          const groups = []
          values.underrepresented_groups.forEach(group => { groups.push({ 'name': group }) })
          values['underrepresented_groups'] = groups

          //formatting align_bridge_complete
          if (values['align_student'] == "N") {
            values['align_bridge_complete'] = "NA"
          }

          const data = { ...values, "semester": semesters[0], "project_choices": projects, ...{ files: uploadedList } };

          //POST
          if (viewform.length === 0) {
            this.doPost(endpoint, (data) => { message.success("Your application has been submitted."); this.reset(); updateSubmittedState(data); }, JSON.stringify(data));
          } else {
            this.doPut(endpoint + (viewform[0]["id"]).toString() + "/", (data) => { message.success("Your application has been updated."); this.reset(); updateSubmittedState(data); }, JSON.stringify(data));
          }
        } else {
          message.error(err);
        }
      });
    };

    getFile = (type, uploadedfileexists) => {
      const { uploadedList } = this.state;

      return (
        <React.Fragment>
          {((uploadedList.filter(el => el[1]["type"] == type).length != 0) || uploadedfileexists) ? (
            <div style={{ marginTop: "10px" }}>
              <p>{type} <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={() => this.onRemove(type)} /></p>
            </div>
          ) : null}
        </React.Fragment>
      )
    };

    setFile = (file, type) => {
      this.setState(state => ({
        uploadedList: [...state.uploadedList, [window.btoa(file), type]],
      }));
    };

    beforeUpload = (file, type) => {
      const reader = new FileReader();
      const setFile = this.setFile;
      reader.onload = function (event) {
        setFile(event.target.result, type);
      };
      reader.readAsBinaryString(file);
    };

    onRemove = type => {
      this.setState(state => {
        const matches = (el) => el[1]["type"] == type;
        const index = state.uploadedList.findIndex(matches);
        const newuploadedList = state.uploadedList.slice();
        newuploadedList.splice(index, 1);
        return {
          uploadedList: newuploadedList,
        };
      });
    };

    disableUpload = (type, uploadedfileexists) => {
      const { uploadedList } = this.state;
      return uploadedList.filter(el => el[1]["type"] == type).length != 0 || uploadedfileexists;
    }

    checkProject = (formFieldIdx, projectId) => {
      const { form } = this.props;
      const { getFieldsValue } = form;

      let projectKeys = Object.keys(getFieldsValue()).filter(el => el.includes("project") && !el.includes("project" + formFieldIdx));
      let fieldValues = getFieldsValue(projectKeys);

      return Object.values(fieldValues).includes(projectId)
    }

    createFileUpload = (type, fieldName, viewform, description) => {
      const { form } = this.props;
      const { getFieldDecorator } = form;

      let uploadedFile = viewform?.[0]?.files.reduce((result, obj) => (type in obj ? obj[type] : result), null)

      return (
        <React.Fragment>
          <FormItem {...this.formItemLayout} label={type} extra={description} >
            {getFieldDecorator(fieldName, {
              rules: [{ required: true }],
              initialValue: uploadedFile,
            })(<Upload beforeUpload={(file) => this.beforeUpload(file, { "type": type })} accept=".pdf" showUploadList={false}>
              <Button disabled={this.disableUpload(type, uploadedFile)}><Icon type="upload" />Upload</Button>
              {this.getFile(type, uploadedFile)}
            </Upload>)}
          </FormItem>
        </React.Fragment>
      );
    }

    render() {
      const { form, projects, viewform, UNDERREPRESENTEDGROUPS } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      const submissionPeriodClosed = !this.get_research_apprenticeship_application_status(this.props.semesters)

      return (
        <Form onSubmit={this.handleSubmit} >
          <>
            <FormItem {...formItemLayout} label="Academic Integrity" extra={"Do you have any academic integrity violations on your record?"} colon={false}>
              {getFieldDecorator("academic_integrity_violations", {
                rules: [{ required: true, message: "Please select your answer." }],
                initialValue: viewform?.[0]?.academic_integrity_violations
              })
                (<RadioGroup disabled={submissionPeriodClosed}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </RadioGroup>)}
            </FormItem>

            {/* If a student has academic violations, the rest of the form won't render. Instead, an Alert will be displayed  */}
            {!form.getFieldValue("academic_integrity_violations") ? (
              <>
                <FormItem {...formItemLayout} label="Align Program" extra={"Are you an Align program student?"} colon={false}>
                  {getFieldDecorator("align_student", {
                    rules: [{ required: true, message: "Please select your answer." }],
                    initialValue: (viewform && viewform?.[0]?.align_bridge_complete == "NA") ? "N" :
                      (viewform?.[0]?.align_bridge_complete == "Y") ? "Y" : ""
                  })
                    (<RadioGroup disabled={submissionPeriodClosed}>
                      <Radio value={"Y"}>Yes</Radio>
                      <Radio value={"N"}>No</Radio>
                    </RadioGroup>)}
                </FormItem>

                {/* If a student is in the Align program and hasn't completed the bridge courses, the rest of the form won't render.
                  Instead, an Alert will be displayed */}
                {form.getFieldValue("align_student") == "Y" ? (
                  <FormItem {...formItemLayout} label="Align Bridge Completion" extra={"If you are an Align student, will you be done with all Bridge courses by the end of the semester prior to this apprenticeship? (If no, you are not qualified for the Apprenticeship Program - Please do not proceed with the application.)"} colon={false}>
                    {getFieldDecorator("align_bridge_complete", {
                      rules: [{ required: true, message: "Please select your answer." }],
                      initialValue: viewform && viewform?.[0]?.align_bridge_complete
                    })
                      (<RadioGroup disabled={submissionPeriodClosed}>
                        <Radio value={"Y"}>Yes</Radio>
                        <Radio value={"N"}>No</Radio>
                      </RadioGroup>)}
                  </FormItem>
                ) : null
                }
                {form.getFieldValue("align_bridge_complete") != "N" ? (
                  <>
                    <FormItem
                      {...formItemLayout}
                      label={"Underrepresented Groups in Tech"}
                      extra={"Do you identify as any of the following underrepresented groups in tech? (Select all that apply)."}
                    >
                      {getFieldDecorator("underrepresented_groups", {
                        rules: [{ required: true, message: "Please select all that apply." }],
                        initialValue: viewform?.[0]?.underrepresented_groups?.map(el => el)
                      })(
                        <Select mode="multiple" showSearch style={{ width: 360 }} filterOption={this.filter} disabled={submissionPeriodClosed}>
                          {UNDERREPRESENTEDGROUPS.map(group => (
                            <Option key={group} value={group}>{group}</Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Overall GPA" extra={"Please enter your current cumulative GPA."}>
                      {getFieldDecorator('gpa', {
                        rules: [{ required: true, message: 'Please enter your GPA.' }],
                        initialValue: viewform?.[0]?.gpa
                      })(<InputNumber min={0} max={4} step={0.01} disabled={submissionPeriodClosed} />)}
                    </FormItem>
                    {this.createFileUpload("Transcript", "transcript_file_upload", viewform, "Please submit a copy of your unofficial transcript as a PDF. Max 10MB.")}
                    {this.createFileUpload("Resume", "resume_file_upload", viewform, "Please submit a copy of your CV / resume as a PDF. Max 10MB.")}
                    {NUMBEROFAPPLICATIONSALLOWED.map((el, idx) =>
                      <>
                        <FormItem
                          {...formItemLayout}
                          label={"Project Choice " + (idx + 1)}
                          extra={(idx === 0) ? ("Please select your first choice project.") : ("")}
                        >
                          {getFieldDecorator("project" + (idx + 1), {
                            rules: [{ required: true, message: "Please select a project." }],
                            initialValue: viewform?.[0]?.project_choices?.[idx].project,
                          })(
                            <Select showSearch style={{ width: 360 }} filterOption={this.filter} allowClear disabled={submissionPeriodClosed}>
                              {projects.map(el => (
                                <Option key={el.id} value={el.id} disabled={this.checkProject(idx + 1, el.id)}>{el.name} </Option>
                              ))}
                            </Select>
                          )}
                        </FormItem>
                        <FormItem {...formItemLayout}
                          label="Reasoning"
                          extra={(idx === 0) ? ("Please provide a brief description detailing your interest in this project.") : ("")}
                          colon={false}> .
                          {getFieldDecorator("reasoning" + (idx + 1), {
                            rules: [{ required: true, message: "Please provide a short answer." }],
                            initialValue: viewform?.[0]?.project_choices?.[idx]?.reasoning,
                          })
                            (<TextArea style={{ width: 360 }} rows={4} disabled={submissionPeriodClosed} />)}
                        </FormItem>
                      </>
                    )}
                    <FormItem {...this.formItemLayout} label=" " colon={false}>
                      <Button type="primary" htmlType="submit" disabled={submissionPeriodClosed}>{viewform.length !== 0 ? "Resubmit" : "Submit"}</Button>
                    </FormItem>
                  </>
                ) : (
                  <Alert
                    message={"You have indicated that you are an Align student that has not completed the Bridge courses, and as such, you are not eligable for this opportunity. Please be aware that this status will be checked before final allocation."}
                    type="error" showIcon
                  />
                )}
              </>) : (
              <Alert message={"You have indicated that you have committed an academic integrity violation, and as such, you are not eligible for this opportunity. Please be aware that this status will be checked before final allocation."} type="error" showIcon />
            )}
          </>
        </Form>
      );
    }
  }
);


class ApprenticeStudentApplication extends AppComponent {

  state = {
    endpoint: "/api/researchapprenticeship/application/",
    loading_applications: true,
    viewform: [],
    endpoint_projects: "/api/researchapprenticeship/project/",
    projects: [],
    loading_projects: true,
    endpoint_applicationopen: "/api/researchapprenticeship/application/open/",
    visible: false,
  };

  componentDidMount() {
    this.getData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    };
  };

  updateSubmittedState = (newData) => {
    this.setState({ viewform: [newData] });
  };

  getData = () => {
    this.doGet(this.state.endpoint_applicationopen + this.props.semesters[0] + "/", data => { this.setState({ visible: data }); });
    this.doGet(this.state.endpoint + "?semester=" + this.props.semesters.join(","), data => this.setState({ viewform: data, loading_applications: false }));
    this.doGet(this.state.endpoint_projects + "?semester=" + this.props.semesters[0], data => { this.setState({ projects: data, loading_projects: false }) });
  };

  render() {
    const { endpoint, viewform, endpoint_projects, loading_projects, projects, visible } = this.state;

    const UNDERREPRESENTEDGROUPS = ["Woman", "Hispanic/Latinx", "Black", "Native American/Indigenous", "Pacific Islander", "None/prefer not to answer"]

    return (
      <Content
        {...this.props}
        title={"Research Apprenticeship Application for " + this.props.semester}
        breadcrumbs={[{ link: "/student", text: "Student" }, { text: "Research Apprenticeship Application" }]}
      >
        <React.Fragment>
          {(visible) ? (
            <React.Fragment>
              <p>
                Congratulations on your nomination for the Khoury Research Apprenticeship Program!
                Please complete this application by the deadline in order to be considered.
                Faculty will start reviewing and interviewing applicants of interest until the deadline. Faculty are not required to interview all candidates.
                Although you may apply to as many as three projects, you will only be offered one project if selected.
              </p>
              <p>
                <b>Please do not reach out to individual faculty regarding the apprenticeship program - They will reach out to you if they are interested in pursuing the interview process.</b>
              </p>
              <Divider orientation="left">Research Apprenticeship Application</Divider>
              {
                (!this.get_research_apprenticeship_application_status(this.props.semesters)) ? (
                  <Alert message={"The Apprenticeship application for " + this.props.semester + " is closed."} type="warning"></Alert>
                ) :
                  <>
                    {(this.state.viewform?.length === 0) ?
                      <p> Please apply by completing the form below.</p>
                      :
                      <p><Alert message="You have already submitted an application. You may edit and resubmit your application below until the deadline."></Alert></p>
                    }
                  </>

              }
              {(this.get_research_apprenticeship_application_status || this.state.viewform?.length !== 0) &&
                <ApprenticeStudentApplicationForm {...this.props} getData={this.getData} updateSubmittedState={this.updateSubmittedState} endpoint={endpoint} endpoint_projects={endpoint_projects}
                  viewform={viewform} loading_projects={loading_projects} projects={projects} UNDERREPRESENTEDGROUPS={UNDERREPRESENTEDGROUPS} />
              }
            </React.Fragment>

          ) : (
            <Divider orientation="center">
              {" "}
              You are not eligible to submit an application.
            </Divider>
          )}
        </React.Fragment>
      </Content>
    );
  };
};

export { ApprenticeStudentApplication };