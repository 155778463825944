import React, { Component } from "react";
import AppComponent from "../../AppComponent";
import Content from "../../Content";
import moment from "moment-timezone";
import { renderStatus, text_max, oxford, add_brs } from "../../Utils";
import { WorkflowSubmit } from "../WorkflowSubmit";
import { WorkflowTable } from "../WorkflowTable";

import { Popover, Table, Form, Input, Divider, Select, Button, InputNumber, message, Radio, Switch, Typography, Modal, Alert, Descriptions, Upload, Icon } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const ResearchReductionRequestForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
	state = {
	  year: null,
	  notes: null,
	  admin_stipend: false,
      file: null,
	  fileList: [],
	}
	
	formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

	componentDidMount() {
	  this.reset();
	}
	
	reset = () => {
	  this.setState({ notes: "", year: null, others: {}, fileList: [] }); 
	  this.props.form.resetFields();
	}
  
    setFile = (file) => { this.setState({ file: window.btoa(file) }); }
	
	handleSubmit = () => {
	  const { semesters, user } = this.props;
	  const { notes, year, file } = this.state;
    
		const data = { notes: notes, year: year, instructor: user.instructor, file: file };
		
		this.props.onSubmit(data, this.reset);
	}

	handleChange = info => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);
		this.setState({ fileList });
	  };
		
	render() {
	  const { visible, onCancel, onCreate, form, item, sections, campus, semester, user } = this.props;
	  const { getFieldDecorator } = form;
      const { year, file } = this.state;
    
      const year_list = this.semester_list().filter(el => (el.code % 100 == 10) && moment(el.startdate, "YYYY-MM-DD") > moment()).map(el => el.year).reverse();
    
      const props = {
		onChange: this.handleChange,
      	onRemove: file => {
        	this.setState({ file: null });
      	},
      	beforeUpload: file => {
        	const reader = new FileReader();
        	const setFile = this.setFile;
        	reader.onload = function(event) {
          	setFile(event.target.result);
        	};
        	reader.readAsBinaryString(file);
      	}
      };
	  
	  return (
		<>
		  <p>Please use the form below if you wish to request a research reduction.  In general, the proposal document should be between 3 and 5 pages and should provide both an overview of the proposed research and arguments as to why the research proposal is likely to succeed, as well as specific plans for collaborations, dissemination, and/or funding (as appropriate).  If the faculty member previously received a research reduction, the proposal should describe the research outputs from the prior reduction.</p>
      
		  <Form>
			<FormItem {...this.formItemLayout} label="Year">
			  {getFieldDecorator('year', {
				rules: [{ required: true, message: 'Please select the year you wish to request a research reduction for.' }],
				onChange: (event) => { this.setState({ year: event }) },
			  })(<Select showSearch style={{ width: 180 }}>
				  { year_list.map(el => <Option key={ el } value={ el }>{ this.print_year(el) }</Option> ) }
				</Select>
			  )}
			</FormItem>

      <FormItem {...this.formItemLayout} label={ [<font color="red">*</font>, " Proposal"] } extra="Please upload a PDF of at most 5 pages containing your proposal.">
        <Upload {...props} fileList={this.state.fileList}>
          <Button>
            <Icon type="upload" /> Select File
          </Button>
        </Upload>
      </FormItem>

      <FormItem {...this.formItemLayout} label="Notes" extra="You can optionally include any general notes here, but please use the file upload to detail your research proposal.">
        {getFieldDecorator('notes', {
        initialValue: "",
        onChange: (event) => { this.setState({ notes: event.target.value }) },
        })(<TextArea rows={ 4 } />
        )}
      </FormItem>

	  <FormItem {...this.formItemLayout} label=" " colon={ false }><Button type="primary" onClick={ this.handleSubmit } disabled={ !file || !year }>Submit</Button></FormItem>
		  </Form>
		</>
	  );
	}
  }
);

class ResearchReductionRequestSearchForm extends AppComponent {
  render() {
	const { form } = this.props;
	const years = this.year_list();
	
	return (
	  <>
		<FormItem {...this.formItemLayout} label="Year" extra="Optionally select the year of the research reduction request.">
		  {form.getFieldDecorator("researchreductionrequest__year", {
			onChange: this.props.onSearchChange,
		  })(<Select style={{ width: 200 }} allowClear>
			{ years.map(s => <Option key={ s } value={ s }>{ this.print_year(s) }</Option> )}
		  </Select>)}
		</FormItem>
	  </>
	)
  }
}

class ResearchReductionRequest extends AppComponent {
  render() {
   	const { record } = this.props;
   	const item = record.record;
	 
	return (
	  <Descriptions bordered title={ "Research reduction request" }>
  		<Descriptions.Item label="Requestor">{ this.link_full_instructor(item.instructor) }</Descriptions.Item>
  		<Descriptions.Item label="Submitted">{ moment(record.created_at).format("MMM DD, YYYY HH:mm") }</Descriptions.Item>
  		<Descriptions.Item label="Year">{ this.print_year(item.year) }</Descriptions.Item>
  		<Descriptions.Item label="Notes" span={2}>{ item.notes ? item.notes : <i>No notes provided</i> }</Descriptions.Item>
  	  	<Descriptions.Item label="Proposal" span={ 4 }>{[record.files.map(el => <a onClick={ () => this.openPDF(el.attachment, "proposal.pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" /> proposal.pdf</a>) ]}</Descriptions.Item>
    </Descriptions>
	);
  }
}

class ResearchReductionRequestTable extends WorkflowTable {
  get_columns = () => {      
	return [{
	  title: "Year",
	  align: 'left',
	  render: (text, record, idx) => this.print_year(record.record.year),
	}, {
	  title: "Notes",
	  align: 'left',
	  render: (text, record, idx) => record.record.notes
	}];
  }
}

class ResearchReductionRequestOverview extends WorkflowSubmit {
  state = {
  	endpoint: "/api/load/researchreduction/",
  	visible: true,
  }

  getData = () => {
	this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
	return "Research Reduction Request";
  }

  get_workflowtype = () => {
	return "researchreductionrequest";
  }

  get_name_plural = () => {
	return "Research Reduction Requests";
  }
  
  get_breadcrumbs = () => {
	return [{ link: "/faculty", text: "Faculty" }, { text: "Research Reduction" }];
  }
  
  get_form = (func) => {
	return <ResearchReductionRequestForm {...this.props} onSubmit={ this.submit_form } />;
  }
  
  get_record_view = (record) => {
	return <ResearchReductionRequest {...this.props} record={ record } />
  }
  
  submit_form = (data, func) => {
	this.doPost(this.state.endpoint, () => { this.getData(); func(); }, JSON.stringify(data));
  }
  
  get_overview_text = () => {
	return (
	  <div>
    <p>Full-time non-tenure-track faculty can submit a research proposal with stated research goals in order to request a reduction in their teaching load by one course.  The research proposal is then reviewed by a faculty committee, which makes a recommendation to the Senior Associate Dean for Academic Affairs as to whether the reduction should be approved.  If their proposal is approved by the Dean, the faculty member's merit evaulation in the next year will also depend on their research, grant funding, and publication of research results.  Similarly, any subsequent requests for a research reduction will consider performance during the previous reduction.</p>
	  </div>
	);
  }  
}

export { ResearchReductionRequestOverview, ResearchReductionRequest, ResearchReductionRequestTable, ResearchReductionRequestSearchForm };