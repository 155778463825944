import React, { Component } from "react";
import { Link } from "react-router-dom";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import SectionTable from './SectionTable';
import Content from './Content';
import { add_dividers, if_empty, add_brs, get_merit_tag } from "./Utils";

import { PageHeader, Alert, Table, Form, Switch, Tooltip, Icon, Layout, Menu, Dropdown, Spin, Calendar, Col, Statistic, Row, List, Card, Divider, Badge, Tag, Breadcrumb, message } from 'antd';

class CommitteeCard extends AppComponent {
  render() {    
    const instructor = this.props.instructor || this.props.user.instructor;
    const year = this.props.year || this.get_year();
    const committeeyears = this.committeeyear_list().filter(cy => cy.year == year && cy.members.find(i => i.instructor == instructor));
    const show_score = this.props.show_score || false;
    
    return ( <Card size="small" title={ "Your committees in " + this.print_year(year) }>{ committeeyears.length ? add_brs(committeeyears.map(el => [( <Link key={ el.committee } to={ this.getLink("/faculty/committees/" + el.committee) }>{ this.print_committee(el.committee) }</Link>)].concat(el.members.find(i => i.instructor == instructor).chair ? ["\u00a0", ( <Tag size="small" key={ "chair-" + el.id } color="orange">Chair</Tag> )] : []).concat(show_score && el.members.find(i => i.instructor == instructor).score ? ["\u00a0", get_merit_tag(el.members.find(i => i.instructor == instructor).score)] : []))) : <i>No committee assignments found</i> }</Card> );
  }
}

class Committee extends AppComponent {
  state = {
      endpoint_committee_prefs: "/api/preferences/committee/",
      committee_prefs: [],
      loading_committee_prefs: true,
  }

  componentDidMount() {
    this.doGet(this.state.endpoint_committee_prefs + "?committee_year__committee=" + this.props.match.params.committee_id + "&committee_year__year=" + this.get_year(),
                  data => this.setState({ committee_prefs: data, loading_committee_prefs: false }));
  }

  getMailinglist = (committee_id) => {
    let mailinglist = this.print_committee_mailinglist(committee_id);
    if (mailinglist) {
      return (
        <React.Fragment>
          <a href={`mailto:${mailinglist}@lists.ccs.neu.edu`}>
            {mailinglist}@lists.ccs.neu.edu
          </a>
        </React.Fragment>
      );
    } else {
      return "None";
    }
  }

  get_membership_table = () => {
    const data = this.committeeyear_list().filter(cy => cy.committee == this.props.match.params.committee_id);
    
    const columns = [
      {
        title: 'Year',
        key: 'year',
        fixed: 'left',
        width: 120,
        render: (text, record, idx) => this.print_year(record.year),
      }, {
        title: 'Members',
        key: 'members',
        render: (text, record, idx) => add_dividers(record.members.map(this.print_committeemembership_instructor)),
      }];

    return <Table {...this.props} dataSource={ data } columns={ columns } scroll={{ x: 800 }} bordered={ false } pagination={ false } size="small" rowKey="year" key={ "table-all" } />
  }

  render() {
    const { committee_prefs, loading_committee_prefs } = this.state;

    const committees = [
      { title: "Willing", content: if_empty(add_brs(committee_prefs.filter(el => el.preference == 2).map(el => this.link_full_instructor(el.faculty))), "No responses.") },
      { title: "Ready", content: if_empty(add_brs(committee_prefs.filter(el => el.preference == 3).map(el => this.link_full_instructor(el.faculty))), "No responses.") },
      { title: "Eager", content: if_empty(add_brs(committee_prefs.filter(el => el.preference == 4).map(el => this.link_full_instructor(el.faculty))), "No responses.") },
    ];


    return (
      <Content {...this.props} title={ this.print_committee(this.props.match.params.committee_id) } breadcrumbs={ [{ link: "/faculty", text: "Faculty" }, { link: "/faculty/committees", text: "Committees" }, { text: this.print_committee(this.props.match.params.committee_id) }] }>
        <p>{ this.print_committee_description(this.props.match.params.committee_id) }</p>
        <p><b>Mailinglist: </b>{this.getMailinglist(this.props.match.params.committee_id)}</p>
        <Divider orientation="left">Membership History</Divider>
        { this.get_membership_table() }

        <Divider orientation="left">Committee Preferences for { this.print_year(this.get_year()) }</Divider>
        { loading_committee_prefs ? ( <Spin tip="Loading committee preferences" /> ) : <List
                  grid={ this.grid }
                  dataSource={ committees }
                  renderItem={item => ( <List.Item><Card size="small" title={ item.title }>{ item.content }</Card></List.Item> )}
                /> }
      </Content>
    );
  }
}

export default Committee;
export { CommitteeCard };