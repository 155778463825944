import React, { Component } from "react";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from './AppComponent';
import LoadTable from "./Load/LoadTable";
import SectionTable from './SectionTable';
import CustomTabs from "./CustomTabs";
import Content from './Content';
import { FundTable } from './Fund';
import { PhDStudentTable, PhDStudentTeachingLoadList } from './PhD/PhDStaff';
import { add_dividers, add_brs, format_percent, format_decimal, format_nuid, get_check_nocheck, if_empty, get_semester_load_data, oxford } from './Utils';

import { PageHeader, Table, Form, Switch, Card, List, Descriptions, Layout, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Tag, Tabs, Statistic, Row, Badge, Alert, Breadcrumb, Timeline, message } from 'antd';
const TabPane = Tabs.TabPane;
const Item = Descriptions.Item;

class MentorMenteeCard extends AppComponent {
  state = {
    endpoint: "/api/faculty/mentor/",
    mentor: null,
    mentees: [],
    loading_mentor: true,
    loading_mentees: true,
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.setState({ data: [], coordinator: [], coordinated: [], loading: true, loading_coordinator: true, loading_coordinated: true });
      this.getData();
    }
  }

  getData = () => {
    if (this.props.user.instructor) {
      this.doGet(this.state.endpoint + "?mentee=" + this.props.user.instructor + "&year=" + this.get_year(),
        data => this.setState({ mentor: data?.length > 0 ? data[0] : null, loading_mentor: false }));
      this.doGet(this.state.endpoint + "?mentor=" + this.props.user.instructor + "&year=" + this.get_year(),
        data => this.setState({ mentees: data, loading_mentees: false }));
    } else {
      this.setState({ loading_mentor: false, loading_mentees: false });
    }
  }

  render() {
    const { loading, loading_mentor, loading_mentees, mentor, mentees } = this.state;

    if (!this.props.show) { return null; }

    return (<Card size="small" title={"Your mentor and mentees in " + this.print_year(this.get_year())}>{loading_mentor || loading_mentees ? (<Spin tip="Loading data" />) :
      <span>
        <p><b>Mentor:</b> {mentor ? this.print_full_instructor(mentor.mentor) : <i>No mentor found</i>}</p>
        <b>Mentee(s):</b> {mentees?.length > 0 ? oxford(mentees.map(m => this.print_full_instructor(m.mentee))) : <i>No mentees found</i>}
      </span>}</Card>);
  }
}

class SabbaticalTable extends AppComponent {
  render() {
    const instructor = this.get_instructor(this.props.instructor);

    if (instructor.ranks?.length > 0 && this.is_tenure_track(instructor.ranks[instructor.ranks.length - 1].rank)) {
      const tt_start = instructor.ranks.filter(r => this.is_tenure_track(r.rank))[0];
      const events = instructor.sabbaticals.map(s => { return { kind: "sabbatical", object: s, year: this.get_semester(s.start).year }; }).concat(instructor.sabbaticaldeferrals.map(d => { return { kind: "deferral", object: d, year: d.year }; })).concat(instructor.leaves.filter(l => l.sabbatical_clock_extension > 0).map(l => { return { kind: "leave", object: l, year: this.get_semester(l.start).year }; })).concat(instructor.ranks.filter(r => r.appointment.reduce((r, a) => r + a.fraction, 0) < 35.0 / 40.0).map(r => { return { kind: "parttime", object: r, year: this.get_semester(r.start).year }; })).sort((a, b) => a.year - b.year);
      const next = this.get_next_sabbatical(instructor.id);

      return (
        <Timeline className="instructor-timeline">
          <Timeline.Item color="orange"><b>{this.print_semester(tt_start.start)}</b> Started tenured/tenure-track position</Timeline.Item>
          {events.map(s =>
            s.kind == "sabbatical" ? (
              <Timeline.Item color="green"><b>{this.print_semester(s.object.start)} {s.object.start == s.object.end ? "" : "- " + this.print_semester(s.object.end)}</b> On sabbatical {s.object.approved ? "" : <i>(pending)</i>}</Timeline.Item>
            ) : s.kind == "deferral" ? (
              <Timeline.Item color="red"><b>{this.print_year(s.object.year)}</b> Deferred sabbatical</Timeline.Item>
            ) : s.kind == "parttime" ? (
              <Timeline.Item color="purple"><b>{this.print_semester(s.object.start)} {s.object.end ? s.object.start == s.object.end ? "" : "- " + this.print_semester(s.object.end) : "- Present"}</b> Not full-time</Timeline.Item>
            ) : (
              <Timeline.Item color="cyan"><b>{this.print_semester(s.object.start)} {s.object.start == s.object.end ? "" : "- " + this.print_semester(s.object.end)}</b> On leave</Timeline.Item>
            )
          )}
          <Timeline.Item color="blue"><b>{next}</b> Eligible for sabbatical</Timeline.Item>
        </Timeline>);
    } else {
      return (<i>Not eligible for sabbatical</i>);
    }
  }
}

class SabbaticalCard extends AppComponent {
  render() {
    return (<Card size="small" title={"Your sabbatical timeline"}>
      <SabbaticalTable {...this.props} />
    </Card>);
  }
}

class TenureTrackTable extends AppComponent {
  render() {
    const instructor = this.get_instructor(this.props.instructor);

    if (instructor.ranks?.length > 0 && this.is_tenure_track_untenured(instructor.ranks[instructor.ranks.length - 1].rank)) {
      const tt_start = instructor.ranks.filter(r => this.is_tenure_track(r.rank))[0];
      const third_year = this.get_third_year_review(instructor.id);
      const tenure = this.get_tenure_review(instructor.id);
      const extensions = instructor.tenureclockextensions.reduce((a, r) => r.extension + a, 0);

      const events = instructor.leaves.filter(l => l.tenure_clock_extension > 0).map(l => { return { kind: "leave", object: l, year: this.get_semester(l.start).year }; }).concat(instructor.parentalreliefs.map(pr => { return { kind: "parental", object: pr, year: this.get_semester(pr.semester).year }; })).concat([{ kind: "review", object: "Third-year review", year: third_year }, { kind: "review", object: "Tenure review" + (extensions ? " (including " + extensions + " extension(s))" : ""), year: tenure }]).sort((a, b) => a.year - b.year);

      return (
        <Timeline className="instructor-timeline">
          <Timeline.Item color="orange"><b>{this.print_semester(tt_start.start)}</b> Started tenure-track position</Timeline.Item>
          {events.map(s =>
            s.kind == "review" ? (
              <Timeline.Item color="green"><b>{this.print_year(s.year)}</b> Due for {s.object} </Timeline.Item>
            ) : s.kind == "leave" ? (
              <Timeline.Item color="cyan"><b>{this.print_semester(s.object.start)} {s.object.start == s.object.end ? "" : "- " + this.print_semester(s.object.end)}</b> On leave</Timeline.Item>
            ) : (
              <Timeline.Item color="purple"><b>{this.print_semester(s.object.start)} {this.print_semester(s.object.semester)}</b> Received parental teaching relief { s.object.tenure_extension == true ? "(tenure clock extension)" : s.object.tenure_extension == false ? "(no tenure clock extension)" : null }</Timeline.Item>
            )
          )}
        </Timeline>);
    } else {
      return (<i>Not on the tenure track</i>);
    }
  }
}

class TenureTrackCard extends AppComponent {
  render() {
    if (!this.props.show) { return null; }

    return (<Card size="small" title={"Your tenure-track timeline"}>
      <TenureTrackTable {...this.props} />
    </Card>);
  }
}

class InstructorRanksTable extends AppComponent {
  render() {
    const instructor = this.get_instructor(this.props.instructor);

    return (
      <Timeline className="instructor-timeline">
        
        {instructor.ranks.map(r =>
          <Timeline.Item color="green" key={r.rank}><b>{this.print_semester(r.start)} - {r.end ? this.print_semester(r.end) : "Present"}</b> {this.print_instructorrank_and_appointment(r)}</Timeline.Item>)}
      </Timeline>
    );
  }
}


class InstructorRanksCard extends AppComponent {
  render() {
    if (!this.props.show) { return null; }

    return (<Card size="small" title={"Your rank history"}>
      <InstructorRanksTable {...this.props} />
    </Card>);
  }
}

class TeachingScheduleTable extends AppComponent {
  render() {
    const instructor = this.get_instructor(this.props.instructor);

    if (instructor.ranks?.length > 0) {
      return (
        <Timeline className="instructor-timeline">
          {instructor.ranks.map(r =>
            <Timeline.Item color="orange"><b>{this.print_semester(r.start)} - {r.end ? this.print_semester(r.end) : "Present"}</b> {r.teaching_schedule ? r.teaching_schedule.name : "Normal teaching schedule"}</Timeline.Item>
          )}
        </Timeline>);
    } else {
      return (<i>No ranks found</i>);
    }
  }
}

class ServiceScheduleTable extends AppComponent {
  render() {
    const instructor = this.get_instructor(this.props.instructor);

    if (instructor.ranks?.length > 0) {
      return (
        <Timeline className="instructor-timeline">
          {instructor.ranks.map(r =>
            <Timeline.Item color="orange"><b>{this.print_semester(r.start)} - {r.end ? this.print_semester(r.end) : "Present"}</b> {r.service_schedule ? r.service_schedule.name : "Normal service schedule"}</Timeline.Item>
          )}
        </Timeline>);
    } else {
      return (<i>No ranks found</i>);
    }
  }
}

class TeachingScheduleCard extends AppComponent {
  render() {
    return (<Card size="small" title={"Your Khoury teaching schedule"}>
      <TeachingScheduleTable {...this.props} />
    </Card>);
  }
}

class ServiceScheduleCard extends AppComponent {
  render() {
    return (<Card size="small" title={"Your Khoury service schedule"}>
      <ServiceScheduleTable {...this.props} />
    </Card>);
  }
}

class CoursesCoordinated extends AppComponent {
  state = {
    endpoint: "/api/schedule/",
    sections: [],
    loading: true,
  }

  componentDidMount() {
    this.doGet(this.state.endpoint + "?instructor_staff=" + this.props.match.params.instructor_id,
      data => this.setState({ sections: data, loading: false }))
  }

  render(){
    const { sections, loading } = this.state;
    return(
      <React.Fragment>
        <Divider orientation="left">Courses Coordinated</Divider>
        <SectionTable {...this.props} data={sections} loading={loading} show_fields={["campus"]} hide_fields={["actions"]} />
      </React.Fragment>

    );
  }
}


class Instructor extends AppComponent {
  state = {
    endpoint: "/api/schedule/",
    sections: [],
    loading: true,

    endpoint_planning: "/api/planning/",
    planning_sections: [],
    loading_planning: true,

    endpoint_coordinator: "/api/schedule/coordinator/",
    coordinators: [],
    loading_coordinators: true,

    endpoint_committee_prefs: "/api/preferences/committee/",
    committee_prefs: [],
    loading_committee_prefs: true,

    endpoint_committeenote: "/api/preferences/committeenote/",
    committee_note: "No notes submitted.",
    loading_committee_note: true,

    endpoint_course: "/api/preferences/course/",
    course_prefs: [],
    loading_course_prefs: true,

    endpoint_meetingtime: "/api/preferences/meetingtime/",
    meetingtime_prefs: [],
    loading_meetingtime_prefs: true,

    endpoint_note: "/api/preferences/note/",
    pref_note: [],
    loading_pref_note: true,

    endpoint_phd: "/api/phd/",
    phd: [],
    loading_phd: true,

    endpoint_mentor: "/api/faculty/mentor/",
    mentors: [],
    mentees: [],
    loading_mentors: true,
    loading_mentees: true,

  }

  componentDidMount() {
    this.doGet(this.state.endpoint + "?instructors__employee__faculty=" + this.props.match.params.instructor_id,
      data => this.setState({ sections: data, loading: false }));
    this.doGet(this.state.endpoint_planning + "?instructors=" + this.props.match.params.instructor_id,
      data => this.setState({ planning_sections: this.deduplicateSections(data), loading_planning: false }));
    this.doGet(this.state.endpoint_coordinator + "?instructor=" + this.props.match.params.instructor_id,
      data => this.setState({ coordinators: data, loading_coordinators: false }));
    this.doGet(this.state.endpoint_phd + "?advisors__advisors=" + this.props.match.params.instructor_id,
      data => this.setState({ phd: data, loading_phd: false }));
    this.doGet(this.state.endpoint_mentor + "?mentee=" + this.props.match.params.instructor_id,
      data => this.setState({ mentors: data, loading_mentors: false }));
    this.doGet(this.state.endpoint_mentor + "?mentor=" + this.props.match.params.instructor_id,
      data => this.setState({ mentees: data, loading_mentees: false }));
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_course + "?faculty=" + this.props.match.params.instructor_id + "&semester=" + this.props.semesters.join(","),
      data => {
        var prefs = data;
        prefs.sort((a, b) => this.course_comparator(this.get_course(a.course), this.get_course(b.course)));

        this.setState({ course_prefs: prefs, loading_course_prefs: false });
      });
    this.doGet(this.state.endpoint_meetingtime + "?faculty=" + this.props.match.params.instructor_id + "&semester=" + this.props.semesters.join(","),
      data => {
        var prefs = data;
        prefs.sort((a, b) => this.meetingtime_comparator(this.get_meetingtime(a.meetingtime), this.get_meetingtime(b.meetingtime)));

        this.setState({ meetingtime_prefs: prefs, loading_meetingtime_prefs: false });
      });
    this.doGet(this.state.endpoint_note + "?faculty=" + this.props.match.params.instructor_id + "&semester=" + this.props.semesters.join(","),
      data => { this.setState({ pref_note: data, loading_pref_note: false }) });
    this.doGet(this.state.endpoint_committee_prefs + "?faculty=" + this.props.match.params.instructor_id + "&committee_year__year=" + this.get_year(),
      data => this.setState({ committee_prefs: data, loading_committee_prefs: false }));
    this.doGet(this.state.endpoint_committeenote + "?faculty=" + this.props.match.params.instructor_id + "&year=" + this.get_year(),
      data => { data && data.length == 1 ? this.setState({ committee_note: data[0].notes, loading_committee_note: false }) : this.setState({ loading_committee_note: false }) });

  }

  get_committee_table = () => {
    const committees = this.committeeyear_list().filter(cy => cy.members.find(i => i.instructor == this.props.match.params.instructor_id));
    const data = Object.values(committees.reduce((r, a) => { (r[a.year] = r[a.year] || { year: a.year, committees: [] }).committees.push(a); return r; }, {}));

    const columns = [
      {
        title: 'Year',
        key: 'year',
        wdith: 60,
        render: (text, record, idx) => this.print_year(record.year),
      }, {
        title: 'Committee(s)',
        key: 'committees',
        render: (text, record, idx) => add_brs(record.committees.map(el => this.print_committeemembership_committee(el.members.find(i => i.instructor == this.props.match.params.instructor_id))))
        //add_brs(record.committees.map(el => [this.link_committee(el.committee)].concat(el.members.find(i => i.instructor == this.props.match.params.instructor_id).chair ? ["\u00a0", <Tag size="small" key={ "chair-" + el.id } color="orange">Chair</Tag>] : []).concat(el.members.find(i => i.instructor == this.props.match.params.instructor_id).ex_officio ? ["\u00a0", <Tag size="small" key={ "chair-" + el.id } color="green">Ex officio</Tag>] : []))),
      }];

    return (data.length ? <Table {...this.props} columns={columns} dataSource={data} bordered={true} pagination={false} size="small" rowKey={(record) => record.year} /> : "No assignments found");
  }

  getCommitteeYear = (committeeyear) => {
    return this.committeeyear_list().find(e => e.id == committeeyear);
  }

  get_position_table = () => {
    const positions = this.positionyear_list().filter(cy => cy.holders.find(i => i == this.props.match.params.instructor_id));
    const data = Object.values(positions.reduce((r, a) => { (r[a.year] = r[a.year] || { year: a.year, positions: [] }).positions.push(a); return r; }, {}));

    const columns = [
      {
        title: 'Year',
        key: 'year',
        wdith: 60,
        render: (text, record, idx) => this.print_year(record.year),
      }, {
        title: 'Position(s)',
        key: 'positions',
        render: (text, record, idx) => add_brs(record.positions.map(this.print_positionyear))
        //add_brs(record.positions.map(el => this.link_position(el.position))),
      }];

    return (data.length ? <Table {...this.props} columns={columns} dataSource={data} bordered={true} pagination={false} size="small" rowKey={(record) => record.year} /> : "No positions found");
  }

  get_mentor_table = () => {
    const { loading_mentors, mentors } = this.state;

    const columns = [
      {
        title: 'Year',
        key: 'year',
        wdith: 60,
        render: (text, record, idx) => this.print_year(record.year),
      }, {
        title: 'Mentor',
        key: 'mentor',
        render: (text, record, idx) => this.link_instructor(record.mentor),
      }];

    return (loading_mentors ? <Spin tip="Loading mentors" /> : mentors.length ? <Table {...this.props} columns={columns} dataSource={mentors} bordered={true} pagination={false} size="small" rowKey={(record) => record.year} /> : "No mentors found");
  }

  get_mentee_table = () => {
    const { loading_mentees, mentees } = this.state;

    const columns = [
      {
        title: 'Year',
        key: 'year',
        wdith: 60,
        render: (text, record, idx) => this.print_year(record.year),
      }, {
        title: 'Mentee(s)',
        key: 'mentor',
        render: (text, record, idx) => add_brs(record.mentees.map(i => this.link_instructor(i))),
      }];

    const my_mentees = [];
    mentees.forEach(m => {
      var rec = my_mentees.find(mm => mm.year == m.year);
      if (!rec) {
        rec = { year: m.year, mentees: [] };
        my_mentees.push(rec);
      }
      rec.mentees.push(m.mentee);
    });

    return (loading_mentees ? <Spin tip="Loading mentees" /> : mentees.length ? <Table {...this.props} columns={columns} dataSource={my_mentees} bordered={true} pagination={false} size="small" rowKey={(record) => record.year} /> : "No mentees found");
  }

  get_basics = () => {
    const instructor = this.get_instructor(this.props.match.params.instructor_id);

    return (
      <div>
        <div><b>NUID</b>: {format_nuid(instructor.nuid)}</div>
        <div><b>Campus</b>: {instructor.campus ? this.print_campus(instructor.campus) : "Unknown"}</div>
        <div><b>Email</b>: {<a key="email" href={"mailto:" + instructor.email}>{instructor.email}</a>}</div>
        <div><b>Area(s)</b>: {instructor.areas?.length > 0 ? add_brs(instructor.areas.map(a => <Tag key={a}>{this.get_area(a).abbrv}</Tag>)) : "None"}</div>
      </div>
    )
  }

  getAdvisors = (current_code, record) => {
    const current_advisors = record.advisors.find(a => this.get_semester(a.start).code <= current_code && (a.end == null || this.get_semester(a.end).code >= current_code));
    return current_advisors ? current_advisors.advisors : null;
  }

  render() {
    const { semesters, semester } = this.props;
    const { loading_planning, planning_sections, loading, loading_coordinators, sections, coordinators, loading_committee_prefs, loading_course_prefs, loading_meetingtime_prefs, loading_pref_note, committee_prefs, course_prefs, meetingtime_prefs, pref_note, loading_load, loading_committee_note, committee_note, loading_phd, phd } = this.state;
    const instructor = this.get_instructor(this.props.match.params.instructor_id);

    var chars = [
      { title: "Basics", content: this.get_basics() },
      { title: "Rank History", content: <InstructorRanksTable {...this.props} instructor={this.props.match.params.instructor_id} /> },
      { title: "Mentor History", content: this.get_mentor_table() },
    ];

    if (instructor.ranks?.length > 0 && this.is_tenure_track_untenured(instructor.ranks[instructor.ranks.length - 1].rank)) {
      chars = chars.concat([{ title: "Tenure-Track History", content: <TenureTrackTable {...this.props} instructor={this.props.match.params.instructor_id} /> }]);
    }

    if (instructor.ranks?.length > 0 && this.is_tenure_track(instructor.ranks[instructor.ranks.length - 1].rank)) {
      chars = chars.concat([{ title: "Sabbatical History", content: (<SabbaticalTable {...this.props} instructor={this.props.match.params.instructor_id} />) }]);
    }

    if (instructor.ranks.find(r => r.teaching_schedule)) {
      chars = chars.concat([{ title: "Teaching Schedule", content: <TeachingScheduleTable {...this.props} instructor={this.props.match.params.instructor_id} /> }]);
    }

    if (instructor.ranks.find(r => r.service_schedule)) {
      chars = chars.concat([{ title: "Service Schedule", content: <ServiceScheduleTable {...this.props} instructor={this.props.match.params.instructor_id} /> }]);
    }

    const service_chars = [
      { title: "Committees", content: this.get_committee_table() },
      { title: "Positions", content: this.get_position_table() },
      { title: "Mentees", content: this.get_mentee_table() },
    ];

    const committees = [
      { title: "Willing", content: if_empty(add_brs(committee_prefs.filter(el => el.preference == 2).map(el => this.link_committee(this.getCommitteeYear(el.committee_year).committee))), "No committees listed.") },
      { title: "Ready", content: if_empty(add_brs(committee_prefs.filter(el => el.preference == 3).map(el => this.link_committee(this.getCommitteeYear(el.committee_year).committee))), "No committees listed.") },
      { title: "Eager", content: if_empty(add_brs(committee_prefs.filter(el => el.preference == 4).map(el => this.link_committee(this.getCommitteeYear(el.committee_year).committee))), "No committees listed.") },
    ];

    const section_semesters = sections.map(el => this.get_semester(el.semester).code);
    const max_semester = section_semesters?.length > 0 ? Math.max(section_semesters) : 0;
    const all_sections = sections.concat(planning_sections.filter(el => this.get_semester(el.semester).code > max_semester));

    const funds = this.fund_list().filter(f => f.owners.find(o => o.instructor == this.props.match.params.instructor_id));

    const current_code = this.get_semester(semesters.length > 1 ? semesters[1] : semesters[0]).code;
    const current_phds = phd.filter(p => { const a = this.getAdvisors(current_code, p); return (this.get_semester(p.matriculated).code <= current_code) && (a != null) && a.includes(instructor.id) && (!p.left || this.get_semester(p.left).code >= current_code); });
    const former_phds = phd.filter(p => p.left && (this.get_semester(p.left).code < current_code) && this.getAdvisors(this.get_semester(p.left).code, p) && this.getAdvisors(this.get_semester(p.left).code, p).includes(instructor.id));
    const other_phds = phd.filter(p => (current_phds.find(cp => cp.id == p.id) == null) && (former_phds.find(cp => cp.id == p.id) == null));

    return (
      <Content {...this.props} title={this.print_full_instructor(instructor.id)} breadcrumbs={[{ link: "/faculty", text: "Faculty" }, { link: "/faculty/instructors", text: "Instructors" }, { text: this.print_full_instructor(instructor.id) }]}>

        <CustomTabs {...this.props}>
          <TabPane tab="Basics" key="basics">
            <List
              grid={this.grid}
              dataSource={chars}
              renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
            />
          </TabPane>
          {(instructor.ranks?.length > 0 && this.is_staff(instructor.ranks[instructor.ranks.length - 1].rank)) ?
            <TabPane tab="Academic" key="test">
              <Divider orientation="left">Courses Taught</Divider>
              <SectionTable {...this.props} data={all_sections} loading={loading} show_fields={["campus"]} hide_fields={["actions"]} />
              <CoursesCoordinated {...this.props} />
            </TabPane>
            : ([
              <TabPane tab="Research" key="research">
                <Divider orientation="left">{semester} PhD Students</Divider>
                <p>This list is all PhD students who {this.print_full_instructor(instructor.id)} was advising during the {semester} semester.</p>
                <PhDStudentTable {...this.props} students={current_phds} loading={loading_phd} />

                <Divider orientation="left">Former PhD Students</Divider>
                <p>This list is all PhD students of {this.print_full_instructor(instructor.id)} who have left the program (and was one of the advisors of) before or during the {semester} semester.</p>
                <PhDStudentTable {...this.props} students={former_phds} loading={loading_phd} />

                <Divider orientation="left">Other PhD Students</Divider>
                <p>This list is all other PhD students who {this.print_full_instructor(instructor.id)} has served as the advisor for.  This includes students who switched advisors, or who matriculated after the {semester} semester.</p>
                <PhDStudentTable {...this.props} students={other_phds} loading={loading_phd} />

                <Divider orientation="left">Funds</Divider>
                <FundTable {...this.props} funds={funds} hide_columns={["actions"]} />
              </TabPane>,
              <TabPane tab="Teaching" key="teaching">
                <Divider orientation="left">Load Overview</Divider>
                <LoadTable {...this.props} sections={all_sections} coordinators={coordinators} loading={loading || loading_coordinators} show_instructors={[instructor]} hide_fields={["instructor", "rank"]} show_blank={false} />

                <Divider orientation="left">Courses Taught</Divider>
                <SectionTable {...this.props} data={all_sections} loading={loading} show_fields={["campus"]} hide_fields={["actions"]} />

                {this.props.semesters.map(sem => {
                  const my_course_prefs = course_prefs.filter(el => el.semester == sem);
                  const courses = [
                    { title: "Willing", content: if_empty(add_brs(my_course_prefs.filter(el => el.preference == 2).map(el => this.link_full_course(el.course))), "No courses listed.") },
                    { title: "Ready", content: if_empty(add_brs(my_course_prefs.filter(el => el.preference == 3).map(el => this.link_full_course(el.course))), "No courses listed.") },
                    { title: "Eager", content: if_empty(add_brs(my_course_prefs.filter(el => el.preference == 4).map(el => this.link_full_course(el.course))), "No courses listed.") },
                  ];

                  const my_meetingtime_prefs = meetingtime_prefs.filter(el => el.semester == sem);
                  const meetingtime = [
                    { title: "No", content: if_empty(add_brs(my_meetingtime_prefs.filter(el => el.preference == 1).map(el => this.print_meetingtime(el.meetingtime))), "No meeting times listed.") },
                    { title: "Ready", content: if_empty(add_brs(my_meetingtime_prefs.filter(el => el.preference == 3).map(el => this.print_meetingtime(el.meetingtime))), "No meeting times listed.") },
                    { title: "Eager", content: if_empty(add_brs(my_meetingtime_prefs.filter(el => el.preference == 4).map(el => this.print_meetingtime(el.meetingtime))), "No meeting times listed.") },
                  ];

                  const my_pref_note = pref_note.filter(el => el.semester == sem);

                  return (
                    <React.Fragment key={sem}>
                      <Divider orientation="left">{this.print_semester(sem)} Course Preferences </Divider>
                      {loading_course_prefs ? (<Spin tip="Loading course preferences" />) : <List
                        grid={this.grid}
                        dataSource={courses}
                        renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)} />}

                      <Divider orientation="left">{this.print_semester(sem)} Meeting Time Preferences</Divider>
                      {loading_course_prefs ? (<Spin tip="Loading meeting time preferences" />) : <List
                        grid={this.grid}
                        dataSource={meetingtime}
                        renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)} />}

                      <Divider orientation="left">{this.print_semester(sem)} Teaching Preference Notes</Divider>
                      {loading_pref_note ? (<Spin tip="Loading preference notes" />) : my_pref_note.length == 1 && my_pref_note[0].notes ? my_pref_note[0].notes : "No notes submitted."}
                    </React.Fragment>);
                })}
              </TabPane>,
              <TabPane tab="Service" key="service">
                <Divider orientation="left">Service History</Divider>
                <List
                  grid={this.grid}
                  dataSource={service_chars}
                  renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)} />

                <Divider orientation="left">{this.print_year(this.get_year())} Committee Preferences</Divider>
                {loading_committee_prefs ? (<Spin tip="Loading committee preferences" />) : <List
                  grid={this.grid}
                  dataSource={committees}
                  renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)} />}

                <Divider orientation="left">{this.print_year(this.get_year())} Committee Preference Notes</Divider>
                {loading_committee_note ? (<Spin tip="Loading committee preference notes" />) : committee_note}
              </TabPane>
            ])}
        </CustomTabs>
      </Content>
    );
  }
}

export default Instructor;
export { MentorMenteeCard, SabbaticalCard, TenureTrackCard, InstructorRanksCard, TeachingScheduleCard, ServiceScheduleCard, InstructorRanksTable };