import React from "react";
import AppComponent from '../AppComponent';
import { print_load_component, isEmpty } from "../Utils";
import { Popover, Button } from "antd";

class LoadDetail extends AppComponent {
    state = {
        endpoint_overload: "/api/load/overload/details/",
        endpoint_reduction: "/api/load/reduction/details/",
        overload_data: [], 
        reduction_data: [],
    };
    
    
    getLoadDetailsData = (load, endpoint, stateVariable) => {
        this.doGet(
            `${endpoint}?year=${load.year}&faculty=${load.instructor}`,
            data => {
                if (data) {
                        this.setState({ [stateVariable]: data })
                }
            }
        );
    };

    handleVisibleChange = (visible) => {
        const { load } = this.props;
        const { endpoint_overload, endpoint_reduction } = this.state;
        if (visible) {
            if (load.assigned.overload > 0) {
                this.getLoadDetailsData(load, endpoint_overload, "overload_data");
            }
            if (load.assigned.reduction > 0) {
                this.getLoadDetailsData(load, endpoint_reduction, "reduction_data");
            }
        }
    };

    printLoadDetail = (loadType, output) => {
        return loadType > 0 && (
            <div>
                <strong>{output} of {print_load_component(loadType)}</strong>
            </div>
        )
    };

    load_reductions_formatting = (load) => {
        const { reduction_data } = this.state;
    
        if (load.reduction || load.reduction_research || load.reduction_parental_new || load.buyout) {
            return (
                <div>
                    {reduction_data?.map((reduction, index) => (
                        <div key={`reduction-${index}`}>
                            <strong>{reduction.kind} reduction of {print_load_component(reduction.loadcount)}</strong>
                            {reduction.notes && (
                                <i> ({reduction.notes})</i>
                            )}
                        </div>
                    ))}
                    {this.printLoadDetail(load.reduction_research, "Research reduction")}
                    {this.printLoadDetail(load.reduction_parental_new, "Parental reduction")}
                    {this.printLoadDetail(load.buyout, "Buyout")}
                </div>
            );
        } else {
            return null;
        }
    };

    pending_reductions_formatting = (load) => {
        if (load.pending_reduction_parental > 0 || load.pending_reduction_research > 0 || load.buyout_pending > 0) {
            return (
                <div>
                    {this.printLoadDetail(load.pending_reduction_research, "Pending research reduction")}
                    {this.printLoadDetail(load.pending_reduction_parental, "Pending parental reduction")}
                    {this.printLoadDetail(load.buyout_pending, "Pending buyout")}
                </div>
            );
        } else {
            return null;
        }
    };

    overload_formatting = (load) => {
        const { overload_data } = this.state;
        const totalOverloadDetailsCount = overload_data?.reduce((acc, detail) => acc + detail.loadcount, 0);

        const renderOverloadDetail = (overload, index) => (
            overload.loadcount > 0 && (
                <div key={`overload-${index}`}>
                    <strong>Overload of {print_load_component(overload.loadcount)}</strong>
                    {overload.justification || overload.load_justification ? (
                        <  i> ({overload.justification || overload.load_justification}) </i>
                    ) : null}
                </div>
            )
        );

        const overloadDetails = !isEmpty(overload_data) && (
            <div>
                {overload_data.map(renderOverloadDetail)}
            </div>
        );
    
        const remainingOverload = load.overload > 0 && load.overload - totalOverloadDetailsCount > 0 && (
            <div>
                <strong>Overload of {print_load_component(load.overload - totalOverloadDetailsCount)}</strong>
            </div>
        );
    
        return overloadDetails || remainingOverload || null;
    
    };

    pending_overload_formatting = (load) => {
        if (load.overload_pending > 0) {
            return (
                <div>
                    <strong>Pending overload of {print_load_component(load.overload_pending)}</strong>
                </div>
            );
        } else {
            return null;
        }
    };

    total_load_popover_formatting = (load) => {
        if (!load.assigned) {
            return null;
        }
        const loadAssigned = load.assigned
       
        const overloadContent = this.overload_formatting(loadAssigned);
        const reductionContent = this.load_reductions_formatting(loadAssigned);
        const pendingOverloadContent = this.pending_overload_formatting(loadAssigned);
        const pendingReductionContent = this.pending_reductions_formatting(loadAssigned);

        if (!reductionContent && !overloadContent && !pendingOverloadContent && !pendingReductionContent) {
            return null;
        }
        return (
            <div>
                {overloadContent}
                {reductionContent}
                {pendingReductionContent}
                {pendingOverloadContent}
            </div>
        );
    };

    print_load_tooltip = (load) => {
        if (!load) {
            return null;
        }

        let result = [print_load_component(load.load)];

        if (load.overload) {
            result = result.concat([" + ", print_load_component(load.overload)]);
        }
        if (load.overload_pending) {
            result = result.concat([" [+ ", print_load_component(load.overload_pending), "] "]);
        }
        if (load.buyout || load.reduction) {
            result = result.concat([" \u2013 ", print_load_component(load.buyout + load.reduction)]);
        }
        if (load.buyout_pending || load.reduction_pending) {
            result = result.concat([" [\u2013 ", print_load_component(load.buyout_pending + load.reduction_pending), "]"]);
        }

        return result;
    };

    render() {
        const { load, totalAssigned, getCellValue } = this.props;
        const content = this.total_load_popover_formatting(load);
        const result = getCellValue(load, () => this.print_load_tooltip(load.assigned), () => print_load_component(totalAssigned));
  
        return content ? (
            <Popover onVisibleChange={this.handleVisibleChange} content={content}>
                {result}
            </Popover>
        ) : (
            <>{result}</>
        );
    }
}

export default LoadDetail;
