import React from "react";
import AppComponent from "../AppComponent";
import Content from "../Content";
import { Table, Form, Divider, Select, Input, Button, message, Spin, Modal, Radio, Descriptions, Alert } from "antd";
import { manageStateItems } from "../Utils";

const { TextArea } = Input;
const FormItem = Form.Item;
const { Option } = Select;
const RadioGroup = Radio.Group;

const FacultyProjectSubmissionForm = Form.create({ name: "form_in_modal" })(
  class extends AppComponent {

    handleSubmit = () => {
      const { semesters, specificProject, endpoint, updateState } = this.props;
      const form = this.props.form;

      form.validateFields((err, values) => {
        if (err) { return; }
        {
          const faculty = values["faculty"] ? [...values["faculty"], this.props.user.instructor] : [this.props.user.instructor]
          const data = { ...values, "semester": semesters[0], "faculty": [...new Set(faculty)]};
          const action = specificProject ? "update" : "add";
  
          this.doAction(specificProject ? endpoint + specificProject.id.toString() + "/" : endpoint, data, action, (updatedData) => {
            form.resetFields();
            updateState(updatedData, action);
            });
        }
      });
    };

    render() {
      const { form, campus_list, semester, formClosed, specificProject} = this.props;
      const { getFieldDecorator } = form;
      const campusList = Object.values(campus_list)

      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true
      };

      return (
        <Form>
          <>
            <FormItem {...formItemLayout} label="Project name" extra={"Please enter the name of your project"} colon={false}>
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Please provide a name." }], initialValue: specificProject?.name
              })
                (<Input style={{ width: 360 }} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Description" extra={"Please provide a brief description of your project."} colon={false}>
              {getFieldDecorator("description", {
                rules: [{ required: true, message: "Please provide a description." }], initialValue:specificProject?.description
              })
                (<TextArea style={{ width: 360 }} rows={4} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Prerequisites" extra={"What prerequisites are required for this project?"} colon={false}>
              {getFieldDecorator("prerequisites", {
                rules: [{ required: true, message: "Please provide prerequisites" }], initialValue: specificProject?.prerequisites
              })
                (<TextArea style={{ width: 360 }} rows={2} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Attendance mode" extra={"Will this research take place in-person or remotely?"} colon={false}>
              {getFieldDecorator("attendance_mode", {
                rules: [{ required: true, message: "Please select your answer." }], initialValue: specificProject?.attendance_mode
              })
                (<RadioGroup >
                  <Radio value={"In-person"}>In-person</Radio>
                  <Radio value={"Remote"}>Remote</Radio>
                  <Radio value={"Either"}>Either</Radio>
                </RadioGroup>)}
            </FormItem>
            <FormItem {...formItemLayout} label="Campus">
              {getFieldDecorator('campus', {
                initialValue: specificProject ? specificProject.campus : this.props.user.campus,
                rules: [{ required: true, message: 'Please the campus(es) to import from.' }],
              })(<Select style={{ width: 360 }} >
                {campusList.filter(c => c.popular).map(c => <Option key={c.id} value={c.id}>{this.print_campus(c.id)}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem {...this.formItemLayout}
              label="Semester"
              extra={"Change the semester with the upper right corner dropdown. It should be set to the semester in which the apprenticeship will take place."}
              colon={false}
            >
              {getFieldDecorator('semester', {
                initialValue: semester,
              })
                (<Select showSearch style={{ width: 220 }} filterOption={this.filter} disabled={true} >
                  <Option value={semester}>{semester}</Option>
                </Select>
                )}
            </FormItem>
            <FormItem {...this.formItemLayout} label="Additional Instructors">
              {getFieldDecorator('faculty', {
                rules: [{ required: false, message: 'Please select any additional instructors associated with this project.' }], 
                initialValue: specificProject?.faculty?.filter(i => i !== this.props.user.instructor)
              })(<Select showSearch style={{ width: 400 }} filterOption={this.filter} mode="multiple">
                {this.instructor_list().filter(i => i.ranks.length > 0 && i.id !== this.props.user.instructor).map(i => (
                  <Option key={i.id} value={i.id}>{this.print_full_instructor(i.id)}</Option>
                ))}
              </Select>
              )}
            </FormItem>
            <FormItem {...this.formItemLayout} label=" " colon={false}>
              <Button type="primary" disabled={formClosed} onClick={() => this.handleSubmit()}> Submit </Button>
            </FormItem>
          </>
        </Form>
      );
    }
  }
);

class FacultyProjectSubmission extends AppComponent {

  state = {
    endpoint: "/api/researchapprenticeship/project/",
    loading_projects: true,
    projects: [],
    visible: false,
    specificProject: null,
  };

  componentDidMount() {
    this.getData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    };
  };

  getData = () => {
    this.doGet(this.state.endpoint + "?semester=" + this.props.semesters.join(","), data => this.setState({ projects: data, loading_projects: false }));
  };

  updateState = (updatedData, action) => {
    this.setState(
      (prevState) => {
        const updatedProjects = manageStateItems(prevState, 'projects', action, updatedData);
        return { ...updatedProjects, visible: false, specificProject: null };
      }
    );
  }

  handleDelete = ( e ) => {
    const { endpoint } = this.state;
    this.doDelete(endpoint + e.id.toString() + "/", () => { this.setState((prevState) => {
      const updatedProjects = manageStateItems(prevState, 'projects', "delete", e);
      return updatedProjects;
    }); }, JSON.stringify());
  }

  toggleModal = ( specificProject, visible) => {
    this.setState({ specificProject: specificProject, visible: visible });
  };


  render() {
    const { loading_projects, endpoint, projects, visible, specificProject } = this.state;
    const formClosed = !this.get_research_apprenticeship_nomination_status(this.props.semesters)

    const columns = [
      {
        title: "Name",
        key: "name",
        width: 200,
        render: (text, record, idx) => record.name
      },
      {
        title: "Actions",
        key: "actions",
        width: 100,
        render: (text, record, idx) => (
          <span>
            <>
              <Button type="primary" disabled={formClosed} onClick={() => this.toggleModal(record, true)}>Edit</Button>
              <Divider type="vertical" />
              <Button type="danger" disabled={formClosed} onClick={() => this.handleDelete(record)}>Delete</Button>
            </>
          </span>
        )
      },
    ];

    return (
      <Content
        {...this.props}
        title={"Research Apprenticeship Project Submission for " + this.props.semester}
        breadcrumbs={[{ link: "/faculty", text: "Faculty" }, { text: "Research Apprenticeship" }, { text: "Project Submission" }]}
      >
        <p>
          Welcome to the Khoury Research Apprenticeship Project Proposal form. The Khoury Research Apprenticeship is a prestigious and
          unique program held in the Fall and Spring semesters that provides current master’s
          students the opportunity to participate in relevant research opportunities while being mentored and supported by faculty advisors.
        </p>
        <p>
          Please submit your research project proposals below.
        </p>
        <p>
          Project submissions may only be edited and deleted prior to the deadline.
        </p>
        <React.Fragment>
          <Divider orientation="left">
            Submitted Apprenticeship Projects
          </Divider>
          <Table
            dataSource={projects}
            columns={columns}
            loading={loading_projects}
            rowKey={(record) => record.id}
            expandedRowRender={record =>
              <div style={{ margin: 0 }}>
                <p><strong>Description: </strong>{record.description}</p>
                <p><strong>Prerequisites: </strong>{record.prerequisites}</p>
                <p><strong>Campus: </strong>{this.print_campus(record.campus)}</p>
                <p><strong>Attendance Mode: </strong>{record.attendance_mode}</p>
                <p><strong>Faculty: </strong>{record.faculty.map(f => this.print_full_instructor(f)).join(", ")}</p>
              </div>}
          />
            <Modal
                title="Edit Project Submission"
                visible={visible}
                onCancel={() => this.toggleModal(null, false)}
                footer={null}
                width={800}
              >
                <FacultyProjectSubmissionForm
                  {...this.props}
                  specificProject={specificProject}
                  endpoint={endpoint}
                  updateState={this.updateState}
                  formClosed={formClosed}
                />
              </Modal>
          <React.Fragment>
            <Divider orientation="left">Research Apprenticeship Project Submission</Divider>
            {formClosed ?
              <Alert message="The project submission form is closed for this semester."></Alert>
              :
              <>
                <p>
                  Please complete the form below. Ensure that the semester selected is the one in which the apprenticeship will take place.
                </p>
                <FacultyProjectSubmissionForm
                  {...this.props}
                  endpoint={endpoint}
                  updateState={this.updateState}
                  formClosed={formClosed}
                />
              </>
            }
          </React.Fragment>
        </React.Fragment>
      </Content>
    );
  };
};

export { FacultyProjectSubmission };
