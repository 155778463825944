import React from 'react';
import { Upload, Button, Icon, Form } from 'antd';

const FileUpload = (props) => {
    const { label, type, description, form, uploadedList, required, onFileUpdate } = props;
    const { getFieldDecorator } = form;

    const beforeUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const fileData = window.btoa(event.target.result);

            if (onFileUpdate) {
                // Update the uploadedList state in the parent component
                
                //If submitting workflow files, the data will need to be transformed
                // to look like this: [fileData.fileData, { type: fileData.type }] in the 
                // parent component's handleFileUpdate function.
                onFileUpdate({ fileData, "type": type });
            }
        };
        reader.readAsBinaryString(file);
        return false; // Prevent the default upload behavior
    };

    const onRemove = () => {
        // Removes file from uploadedList state in parent component
        if (onFileUpdate) {
            onFileUpdate(null);
        }
    };

    const disableUpload = () => {
        // Use the uploadedList prop to determine if the upload button should be disabled
        return uploadedList && uploadedList.length > 0;
    };

    const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

    return (
        <Form.Item {...formItemLayout} label={label} extra={description}>
            {getFieldDecorator(type, {
                rules: [{ required, message: `Please upload ${label}` }],
            })(
                <Upload
                    beforeUpload={beforeUpload}
                    accept=".pdf"
                    showUploadList={false}
                >
                    <Button disabled={disableUpload()}><Icon type="upload" /> Upload</Button>
                </Upload>
            )}
            {uploadedList && uploadedList.length > 0 && (
                <div style={{ marginTop: "10px" }}>
                    <p>{label} <Icon type="minus-circle-o" style={{ color: '#d9534f' }} onClick={onRemove} /></p>
                </div>
            )}
        </Form.Item>
    );
};

export default FileUpload;
