import React, { Component } from "react";
import Markdown from 'react-markdown'
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import key from "weak-key";
import queryString from 'query-string';
import NumberFormat from 'react-number-format';
import { G2, Chart, Geom, Axis, Coord, Label, Legend, View, Guide, Shape, Facet, Util } from "bizcharts";
import AppComponent from './../AppComponent';
import StatusComponent from './../StatusComponent';
import { Status } from './../StatusComponent';
import Content from './../Content';
import Preference from './../Preference';
import CustomTabs from './../CustomTabs';
import TAHoursTable from './TAHoursTable';
import { add_dividers, add_dividers_horiz, format_percent, format_decimal, oxford, renderTtatus, getSemesterWeeks, isWeekConfirmable, getWeekDeadline, renderStatus, isEmpty } from './../Utils';

import { Tag, Popconfirm, Button, PageHeader, DatePicker, Collapse, Table, Alert, Form, Switch, Card, Radio, List, Layout, Input, InputNumber, Tooltip, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Select, Breadcrumb, Checkbox, Tabs, Upload, message, Modal } from 'antd';
import { useLayoutEffect } from "react";
const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;
const Panel = Collapse.Panel;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

const dateFormat = 'YYYY-MM-DD';

class TAApplicationCard extends AppComponent {
  state = {
      endpoint_taapplication: "/api/ta/application/",
      taapplications: [],
      loading_taapplications: true,
  
      endpoint_group: "/api/schedule/group/",
      groups: [],
      loading_groups: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.setState({ loading_taapplications: true, loading_groups: true }, this.getData);
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taapplication + "?ta__student__person=" + this.props.user.person.id + "&semester=" + this.props.semesters.join(","),
        data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_group + "?semester=" + this.props.semesters.join(","),
        data => this.setState({ groups: data, loading_groups: false }));
  }

  render() {
    const { loading_taapplications, taapplications, groups, loading_groups } = this.state;
    const data = taapplications.map(el => el.state == "Assigned" ? [el, <Link to={ this.getLink("/students/ta/accept") }><Alert message="Click to accept or deny the position" type="warning" showIcon /></Link>] : el).flat();

    const columns = [
      {
        title: 'Semester',
        key: 'semester',
        render: (text, record, idx) => record.semester ? this.print_semester(record.semester) : { children: record, props: { colSpan: 4 }},
      }, {
        title: 'Course',
        key: 'course',
        render: (text, record, idx) => record.course ? this.print_course(record.course) : { props: { colSpan: 0 }},
      }, {
        title: 'Campus/Section(s)',
        key: 'campus',
        render: (text, record, idx) => record.campus ? this.print_campus(record.campus) : record.group ? groups.find(g => g.id == record.group).name : { props: { colSpan: 0 }},
      }, {
        title: 'Status',
        key: 'status',
        align: 'center',
        render: (text, record, idx) => record.state ? renderStatus(record.state) : { props: { colSpan: 0 }},
        width: 60,
      }
    ];

    return ( <Card size="small" title={ "Your TA applications in " + this.props.semester }>
              { loading_taapplications || loading_groups ? ( <Spin tip="Loading applications" /> ) : taapplications?.length > 0 ? ( <Table {...this.props} dataSource={ data } columns={ columns } bordered={ false } pagination={ false } size="small" rowKey={ (record) => record.id } rowClassName={ (record, index) => record.course ? index == data.length-1 || data[index+1].course ? "" : "tr-no-bottom-border" : "tr-no-top-border" } /> ) : <i>No TA applications found</i> }
            </Card> );
  }
}

class IAAssignmentCard extends AppComponent {
  state = {
      endpoint_iaassignment: "/api/ia/assignment/",
      iaassignments: [],
      loading_iaassignments: true,

      endpoint_oncall: "/api/ia/oncall/",
      oncalls: [],
      loading_oncalls: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.setState({ loading_iaassignments: true }, this.getData);
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_iaassignment + "?section__semester=" + this.props.semesters.join(",") + "&ia__student__person=" + this.props.user.person.id,
                  data => this.setState({ iaassignments: data, loading_iaassignments: false }));
    this.doGet(this.state.endpoint_oncall + "?semester=" + this.props.semesters.join(",") + "&ia__student__person=" + this.props.user.person.id,
                  data => this.setState({ oncalls: data, loading_oncalls: false }));
  }

  render() {
    const { loading_iaassignments, iaassignments, loading_oncalls, oncalls } = this.state;

    const columns = [
      {
        title: 'Course',
        key: 'course',
        render: (text, record, idx) => record.section ? this.print_course(record.section.course) + " " + record.section.number : <i>N/A</i>,
      }, {
        title: 'Room',
        key: 'room',
        render: (text, record, idx) => record.section ? this.print_room(record.section.room) : <i>N/A</i>,
      }, {
        title: 'Time',
        key: 'time',
        render: (text, record, idx) => record.section ? this.print_meetingtime(record.section.meetingtime) : this.print_meetingtime(record.meetingtime),
      }, {
        title: 'Status',
        key: 'oncall',
        render: (text, record, idx) => record.section ? "Assigned" : "On Call",
      }
    ];

    return ( <Card size="small" title={ "Your IA assignments in " + this.props.semester }>
              { loading_iaassignments || loading_oncalls ? ( <Spin tip="Loading assignments" /> ) : iaassignments?.length > 0 || oncalls?.length > 0 ? ( <Table {...this.props} dataSource={ iaassignments.concat(oncalls) } columns={ columns } bordered={ false } pagination={ false } size="small" rowKey={ (record) => record.id } /> ) : <i>No IA assignments found</i> }
            </Card> );
  }
}


class TAHoursCard extends AppComponent {
  state = {
      endpoint_taapplication: "/api/ta/application/",
      taapplications: [],
      loading_taapplications: true,

      endpoint_hours: "/api/ta/hours/",
      hours: [],
      loading_hours: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taapplication + "?ta__student__person=" + this.props.user.person.id + "&semester=" + this.props.semesters.join(",") + "&state=Hired",
                  data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_hours + "?taapplication__ta__student__person=" + this.props.user.student.person.id + "&taapplication__semester=" + this.props.semesters.join(","),
                  data => this.setState({ hours: data, loading_hours: false }));
  }

  render() {
    const { loading_taapplications, taapplications, loading_hours, hours } = this.state;
    const apps = taapplications.filter(el => el.semester == this.props.single_semester);

    const weeks = getSemesterWeeks(this.get_semester(this.props.single_semester));
    const columns = [
      {
        title: 'Week',
        key: 'week',
        render: (text, record, idx) => typeof(record) == "string" ? weeks[record][0].format("M/DD") + "-" + weeks[record][weeks[record].length-1].format("M/DD") : { children: record, props: { colSpan: 3 }},
      }
    ].concat(apps.map(el => { return { title: this.print_course(el.course) + " hours", align: 'right', key: el.course, render: (text, record, idx) => typeof(record) == "string" ? hours.filter(h => h.taapplication == el.id && weeks[record].find(d => d.format("YYYY-MM-DD") == h.date)).reduce((r, a) => r + a.hours, 0) : { props: { colSpan: 0 }} }; }));

    var weeknums = Object.keys(weeks).filter(w => w <= moment().format("ww"));
    weeknums.sort();
    weeknums = weeknums.map(el => hours.filter(h => weeks[el].find(d => d.format("YYYY-MM-DD") == h.date) && h.confirmed_at == null)?.length > 0 ? [el, ( <Link to={ this.getLink("/students/ta/hours") + "&week=" + el }><Alert message="Hours not yet confirmed; click to do so." type="warning" showIcon /></Link> )] : el).flat();

    return ( <Card size="small" title={ "Your TA hours in " + this.print_semester(this.props.single_semester) }>
                { loading_taapplications || loading_hours ? ( <Spin tip="Loading hours" /> ) : apps.length == 0 ? <i>No positions found.</i> : <Table {...this.props} dataSource={ weeknums } columns={ columns } bordered={ false } pagination={ false } size="small" rowKey={ (record, idx) => idx } rowClassName={ (record, index) => typeof(record) == "string" ? index == weeknums.length-1 || typeof(weeknums[index+1]) == "string" ? "" : "tr-no-bottom-border" : "tr-no-top-border" } /> }
            </Card> );
  }
}

const TAForm = Form.create({ name: 'form_not_in_modal' })(
  class extends AppComponent {
    state = {
      endpoint_resume: "/api/ta/resume/",
      
      level: null,
      gpa: null,
      available: true,
      fully_available: true,
      available_date: null,
      resume: null,
      why_become_ta_question: null,
      excites_challenges_as_ta_question: null,
      edited: false,
    }

    componentDidMount() {
      this.update();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.ta !== this.props.ta) {
        this.update();
      }
    }

    update = () => {
      const { ta } = this.props;
      this.setState({
        level: ta ? ta.level : null,
        gpa: ta ? ta.gpa : null,
        available: ta ? ta.available : true,
        available_date: ta ? ta.available_date : null,
        fully_available: ta ? ta.available_date ? false : true : true,
        why_become_ta_question: ta ? ta.why_become_ta_question : null,
        excites_challenges_as_ta_question: ta ? ta.excites_challenges_as_ta_question : null,

        resume: ta ? ta.resume : null,

        edited: false,
      });
    }

    handleSubmit = (e) => {
      const { ta } = this.props;
      const { edited } = this.state;
      e.preventDefault();
      edited || !ta ? this.props.onCreateUpdate(e) : this.props.onPass(e);

    }

    handleFile = (file, fileList) => {
      const reader = new FileReader();
      reader.onloadend = () => this.setState({ resume: reader.result });
      reader.readAsBinaryString(file);
    }

    disabledDate = (value) => {
      return moment(this.get_semester(this.props.semesters[0]).startdate) > value.valueOf() || moment(this.get_semester(this.props.semesters[this.props.semesters.length-1]).enddate) <= value.valueOf();
    }

    render() {
      const { form, ta, semester, onCreateUpdate, getTA, user } = this.props;
      const { getFieldDecorator } = form;
      const { level, gpa, fully_available, available, available_date, resume, edited, why_become_ta_question, excites_challenges_as_ta_question } = this.state;

      const disabled = !excites_challenges_as_ta_question || !why_become_ta_question;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Form onSubmit={ this.handleSubmit } >
          <FormItem {...formItemLayout} label="Level" extra="Select your current level of study.">
            {getFieldDecorator('level', {
              rules: [{ required: true, message: 'Please select your current level.' }],
              initialValue: user.phd_student ? "PH" : level,
              onChange: event => this.setState({ level: event, edited: true }),
            })(<Select showSearch style={{ width: 180 }} filterOption={ this.filter } disabled={ user.phd_student }>
                <Option key="UG" value="UG">Undergraduate</Option>
                <Option key="MS" value="MS">Masters</Option>
                <Option key="PH" value="PH" disabled={ !user.phd_student }>Ph.D.</Option>
            </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Overall GPA" extra={        
            <>
            <p>Please enter your cumulative GPA as listed on your <strong>official transcript</strong>, if you haven't received any grades thus far, please enter 0. </p> 
                <ul>
                  <li><i>Note:</i>Submitting a falsified GPA can lead to dismissal of position.</li>
                </ul>
                </>     }>
            {getFieldDecorator('gpa', {
              rules: [{ required: true, message: 'Please enter your GPA.' }],
              initialValue: gpa,
              onChange: (event) => this.setState({ gpa: event, edited: true }),
            })(<InputNumber min={0} max={4} step={0.01} /> )}
          </FormItem>
          <FormItem {...formItemLayout} label="Looking for Co-ops?" colon={ false } extra={ "Please enter whether you are looking to go on Co-op for the semester you're planning on TAing. Students who are on co-op cannot simultaneously hold a position within the College." } >
            {getFieldDecorator('available', {
              rules: [{ required: true, message: 'Please select whether you are looking to go on Co-op.' }],
              initialValue: available,
              onChange: (event) => this.setState({ available: event.target.value, edited: true }),
            })(<RadioGroup><Tooltip title="Students who are on co-op cannot simultaneously hold a position within the College."><Radio value={ false }>Yes</Radio></Tooltip><Radio value={ true }>No</Radio></RadioGroup> )}
          </FormItem>
          <FormItem {...formItemLayout} label="Fully Available?" colon={ false } extra={ "Please enter whether you will be available starting the first day of the semester (" + moment(this.get_semester(this.props.semesters[0]).startdate).format('MMMM Do YYYY') + ")." } >
            {getFieldDecorator('fully_available', {
              rules: [{ required: true, message: 'Please select whether you will be fully available.' }],
              initialValue: fully_available,
              onChange: (event) => this.setState({ fully_available: event.target.value, edited: true }),
            })(<RadioGroup><Radio value={ true }>Yes</Radio><Radio value={ false }>No</Radio></RadioGroup> )}
          </FormItem>
          <FormItem {...formItemLayout} label="Available Date" extra={ "If you will not be available on the first day of the semester, please enter the date you will be available." } >
            {getFieldDecorator('available_date', {
              rules: [{ required: !fully_available, message: 'Please select the date you will be fully available.' }],
              initialValue: !fully_available && available_date ? moment(available_date) : null,
              onChange: (event) => { this.setState({ available_date: event, edited: true }) },
            })(<DatePicker disabled={ fully_available } format={ dateFormat } disabledDate={ this.disabledDate } /> )}
          </FormItem>
          <FormItem {...formItemLayout} label="Resume" extra={        
            <>
            <p>You are welcome to upload a copy of your resume if you wish. <strong>It must be a PDF and no larger than 2 MB.</strong> All resumes will be renamed to resume.pdf.</p>   
                <ul>
                  <li><i>Tip:</i> Tailoring your resume to the job responsibilities can really make a difference!</li>
                </ul>
                </>     }>
            {getFieldDecorator('resume', {
              onChange: (event) => this.setState({ resume: event, edited: true }),
            })(<span>
                 { resume && (typeof resume == "string") ? <div><a onClick={ () => this.openPDF(resume, "resume.pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" /> resume.pdf</a></div> : null }
                 <Upload beforeUpload={ (file) => { const isGt2M = file.size > 2 * 1024 * 1024; if (isGt2M) { message.error('Resume must be smaller than 2 MB'); } return !isGt2M; } } accept=".pdf" action={ this.state.endpoint_resume } headers={{ Authorization: this.getAuthorizationHeader() }}><Button><Icon type="upload" /> { resume ? "Replace" : "Upload"}</Button></Upload>
                </span>)}
          </FormItem>
          <FormItem {...formItemLayout} label="Why do you want to be a TA?" colon={ false } extra="What makes you an outstanding candidate for the TA role?">
                {getFieldDecorator('why_become_ta_question', {
                  rules: [{ required: true, message: 'Please answer the question.' }],
                  initialValue: why_become_ta_question,
                  onChange: (event) => this.setState({ why_become_ta_question: event.target.value, edited: true }),
                })(<TextArea rows={4} style={{ width: '60%' }}/> )}
              </FormItem>
              <FormItem {...formItemLayout} label="What excites you and what challenges do you foresee as a TA?" colon={ false } extra={                
                <>
                  <p>Use the below prompts to guide your response:</p>
                  <ul>
                    <li>Interacting with students from various backgrounds and in various settings</li>
                    <li>Evaluating/assessing student work</li>
                  </ul>
                </>}>
                {getFieldDecorator('excites_challenges_as_ta_question', {
                  rules: [{ required: true, message: 'Please answer the question.' }],
                  initialValue: excites_challenges_as_ta_question,
                  onChange: (event) => this.setState({ excites_challenges_as_ta_question: event.target.value, edited: true }),
                })(<TextArea rows={4} style={{ width: '60%' }} /> )}
              </FormItem>
          <FormItem {...formItemLayout} wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 }, }} >
            <Button disabled={ disabled } type="primary" htmlType="submit">{ ta ? edited || disabled ? "Update" : "No Updates Needed" : "Submit" }</Button>
          </FormItem>
        </Form>
      );
    }
  }
);

const TAApplicationForm = Form.create({ name: 'ta_form_not_in_modal' })(
  class extends AppComponent {
    state = {
      course: null,
      group: null,
      semester: null,
      campus: null,
      group: null,
      semester_taken: null,
      grade: null,
      heard_of_position: null,
      notes: "",

      edited: false,
    }

    componentDidMount() {
      this.update(false, this.props.taapplication);
      if (this.props.semesters.length == 1) {
        this.setState({semester: this.props.semesters[0]})
      } 
    }

    componentDidUpdate(prevProps) {
      if (prevProps.taapplication && this.props.taapplication && prevProps.taapplication.id != this.props.taapplication.id) {
        this.update(false, this.props.taapplication);
      } else if (prevProps.semester != this.props.semester) {
        if (this.props.semesters.length == 1) {
          this.setState({semester: this.props.semesters[0]})
        } else {
        this.setState({semester: null})
        }
     } 
    }

    update = (edited, taapplication) => {
      const { form } = this.props;
      this.setState({
        course: taapplication && taapplication.course !== undefined ? taapplication.course : this.state.course,
        group: taapplication && taapplication.group !== undefined ? taapplication.group : this.state.group,
        semester: taapplication && taapplication.semester !== undefined  ? taapplication.semester : this.state.semester,
        campus: taapplication && taapplication.campus !== undefined  ? taapplication.campus : this.state.campus,
        group: taapplication && taapplication.group !== undefined  ? taapplication.group : this.state.group,
        semester_taken: taapplication && taapplication.semester_taken !== undefined  ? taapplication.semester_taken : this.state.semester_taken,
        grade: taapplication && taapplication.grade !== undefined  ? taapplication.grade : this.state.grade,
        heard_of_position: taapplication && taapplication.heard_of_position !== undefined  ? taapplication.heard_of_position : this.state.heard_of_position,
        notes: taapplication && taapplication.notes !== undefined  ? taapplication.notes : this.state.notes,

        edited: edited,
      }, () => {
        if (edited) {
          form.setFieldsValue(taapplication);
        }
      });
    }

    handleSubmit = (e) => {
      const { taapplication } = this.props;
      const { edited } = this.state;
      e.preventDefault();
      this.setState({ edited: false }, () => this.props.onCreateUpdate(taapplication, this.props.form, this.props.preference));
    }

    handleDelete = (e) => {
      const { taapplication } = this.props;
      const { edited } = this.state;
      e.preventDefault();
      this.setState({ edited: false }, () => this.props.onDelete(taapplication, this.props.form, this.props.preference));
    }

    getSemesters = (course) => {
      return this.props.semesters.filter(sem => this.props.sections.find(el => el.semester == sem && el.course == course));
    }
    
    getCampusesAndSectionGroups = (course, semester) => {
      const { sections, groups } = this.props;
      
      const relevant_groups = groups.filter(g => g.semester == semester && g.sections.find(s => s.course == course));

      const campuses = this.campus_list().filter(c => sections.find(el => (el.campus == c.id) && (el.semester == semester) && (el.course == course) && (relevant_groups.find(g => g.sections.find(gs => gs.id == el.id)) == null)) != null).map(el => { return { campus: el.id, key: "campus-" + el.id }; });
      const groups_in_semester = relevant_groups.map(g => { return { group: g.id, key: "group-" + g.id }; });

      return groups_in_semester.concat(campuses);
    }
    
    getUnselectedCampusesAndSectionGroups = (course, semester) => {
      const { taapplications } = this.props;
      
      const options = this.getCampusesAndSectionGroups(course, semester);
      return options.filter(o => taapplications.find(ta => ta.semester == semester && ta.course == course && ta.campus == o.campus && ta.group == o.group) == null);
    }

    getAutoSelectedSemester = (course) => {
      return this.getSemesters(course).length == 1 ? this.getSemesters(course)[0] : null;
    }
    
    getAutoSelectedSemesterTaken = (course) => {
      const { sections } = this.props;
      
      const prev = this.props.ta.transcript.courses.find(a => !a.withdrawn && (a.semester < this.props.semesters[0]) && a.course == course);
      return prev ? prev.semester : null;
    }
      
    getAutoSelectedCampus = (course, semester) => {
      if (! semester) {
        semester = this.getAutoSelectedSemester(course);
      }

      if (semester) {
        const campuses = this.getUnselectedCampusesAndSectionGroups(course, semester);
        return campuses.length == 1 && campuses[0].campus ? campuses[0].campus : null; 
      } else {
        return null;
      }
    }

    getAutoSelectedGroup = (course, semester) => {
      if (! semester) {
        semester = this.getAutoSelectedSemester(course);
      }
    
      if (semester) {
        const groups = this.getUnselectedCampusesAndSectionGroups(course, semester);
        return groups.length == 1 && groups[0].group ? groups[0].group : null;
      } else {
        return null;
      }
    }

    getCourseList = (semester) => {
      const {sections, user, groups} = this.props;

      const sections_in_semester = sections.filter(sec => sec.semester == semester);          
      const course_list = this.course_list_from_sections(sections_in_semester).map(c => { c.local = sections_in_semester.filter(s => s.course == c.id && user.campuses.includes(s.campus))?.length > 0; c.group = false; c.online = sections_in_semester.filter(s => s.course == c.id && s.campus == 118)?.length > 0; return c; });

      return course_list.sort(this.course_comparator);
    }

    getCoursesTaken = (semester) => {
      const {ta, sections} = this.props;

      const course_list = this.getCourseList(semester);
      const course_list_local = course_list.filter(el => el.local || el.online);
      return course_list_local.filter(el => ta.transcript.courses.filter(a => !a.withdrawn && (this.get_semester(a.semester).code < this.get_semester(this.props.semesters[0]).code)).map(a => a.course).includes(el.id));
    }

    getCoursesNotTaken = (semester) => {
      const {ta} = this.props;

      const course_list = this.getCourseList(semester);
      const course_list_local = course_list.filter(el => el.local || el.online);
      return course_list_local.filter(el => !ta.transcript.courses.filter(a => !a.withdrawn && (this.get_semester(a.semester).code < this.get_semester(this.props.semesters[0]).code)).map(a => a.course).includes(el.id));
    }

    getCoursesRemote = (semester) => {
      const course_list = this.getCourseList(semester);
      return course_list.filter(el => !el.local && !el.online);
    }
    
    printCourseOption = (course) => {
      const { taapplication, taapplications, sections } = this.props;
      const { semester } = this.state;
      
      const options = this.getUnselectedCampusesAndSectionGroups(course.id, semester);
              
      return (
        <Option key={ course.id } value={ course.id } disabled={ (options.length == 0) && (!taapplication || (taapplication.course != course.id)) }>
          { this.print_full_course(course.id) } { course.online ? course.local ? "(offered locally and online)" : "(online)" : course.local ? "" : "(other campuses only)" }
        </Option>
      )
    }
    
    printCampusorSectionGroup = (campusorsectiongroup) => {
      const { taapplication, taapplications, sections, groups } = this.props;
      const { course, semester } = this.state;
      
      const selected = taapplications.find(ta => ta.semester == semester && ta.course == course && ta.campus == campusorsectiongroup.campus && ta.group == campusorsectiongroup.group);
      
      return (
        <Option key={ campusorsectiongroup.key } value={ campusorsectiongroup.key } disabled={ (!taapplication && (selected != null)) || (taapplication && taapplication.course != campusorsectiongroup.course && taapplication.group != campusorsectiongroup.group) }>
          { campusorsectiongroup.campus ? this.print_campus(campusorsectiongroup.campus) + " (all)": groups.find(g => g.id == campusorsectiongroup.group).name }
        </Option>
      );
    }

    showTANotes = ( campusorgroup ) => {
      const { groups } = this.props;
      let group = campusorgroup && campusorgroup.includes("group-") ? groups.filter(el => el.id == parseInt(campusorgroup.substring(6))) : null;
      return (( group != null && group.length != 0 && group[0].hasOwnProperty('TA_notes') && group[0].TA_notes) ? group[0].TA_notes : "N/A" );
    }

    render() {
      const { form, taapplication, sections, groups, taapplications, editable, semesters, user, ta } = this.props;
      const { getFieldDecorator } = form;
      const { course, group, semester_taken, grade, notes, semester, campus, heard_of_position, edited } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      const semester_list = this.semester_list().filter(el => el.code < this.get_semester(this.props.semesters[0]).code);
      const disabled = !editable || (taapplication && taapplication.state != "Submitted");

      return (
        <Form>
          { taapplication ? ( <FormItem {...formItemLayout} label="Status">{ renderStatus(taapplication.state) }</FormItem> ) : "" }
          <FormItem {...formItemLayout} label="Semester" extra="Please select the semester you are applying for.">
                {getFieldDecorator('semester', {
                  rules: [{ required: true, message: 'Please select a semester to apply for.' }],
                  initialValue: semester,
                  onChange: event => this.update(true, { semester: event,  course: null }),
                })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } allowClear={ true } disabled={ disabled }>
                  { this.props.semesters.map(el => <Option key={ el } value={ el }> { this.print_semester(el) } </Option> ) }
                </Select>
                )}
              </FormItem>
          { semester ? (
          <FormItem {...formItemLayout} label="Course" extra="Select the course you are applying for. Courses are offered at multiple campuses, but you are only likely to be hired for a course taught on your local campus or online.">
            {getFieldDecorator('course', {
              rules: [{ required: true, message: 'Please select a course.' }],
              initialValue: course,
              onChange: event => { form.resetFields(["campusorgroup"]); this.update(true, { course: event, campus: this.getAutoSelectedCampus(event), group: this.getAutoSelectedGroup(event), semester_taken: this.getAutoSelectedSemesterTaken(event), grade: null, notes: null }) },
            })(<Select showSearch style={{ width: 450 }} filterOption={ this.filter } allowClear={ true } disabled={ disabled }>
                  <OptGroup label="Courses you have taken">
                  { this.getCoursesTaken(semester).map(this.printCourseOption) }
                  </OptGroup>
                  <OptGroup label="Courses you have not taken">
                  { this.getCoursesNotTaken(semester).map(this.printCourseOption) }
                  </OptGroup>
                  <OptGroup label="Courses only at other campuses">
                  { this.getCoursesRemote(semester).map(this.printCourseOption) }
                  </OptGroup>
            </Select>
            )}
          </FormItem>
            ) : "" }
          { course ? (
            <React.Fragment>
              <FormItem {...formItemLayout} label="Section(s) or Campus" extra="Please select the specific section(s) or the campus that you are applying for.  For online courses, be sure to select the 'Online' campus.">
                {getFieldDecorator('campusorgroup', {
                  rules: [{ required: true, message: 'Please select a campus or specific section(s) to apply for.' }],
                  initialValue: campus ? "campus-" + campus : group ? "group-" + group : null,
                  onChange: (event) => this.setState({ campus: event.includes("campus") ? parseInt(event.substring(7)) : null, group: event.includes("group") ? parseInt(event.substring(6)) : null, edited: true }),
                })(<Select showSearch style={{ width: 450 }} filterOption={ this.filter } allowClear={ true } disabled={ disabled }>
                  { this.getCampusesAndSectionGroups(course, semester).map(this.printCampusorSectionGroup) }
                </Select>
                )}
              </FormItem>
              { form.getFieldValue("campusorgroup") && (
                <FormItem {...formItemLayout} label="Specific Course Notes:" >
                  <div style={{ width: 450 }}>
                    <Markdown linkTarget="_blank" source={ this.showTANotes(form.getFieldValue("campusorgroup")) } />
                  </div>
                </FormItem>
              )}
              <FormItem {...formItemLayout} label="Semester Taken" extra="If you have taken this class, the semester that you did will show here.">
                {getFieldDecorator('semester_taken', {
                  initialValue: semester_taken,
                  onChange: (event) => this.setState({ semester_taken: event, edited: true }),
                })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } allowClear={ true } disabled={ true }>
                  { semester_list.map(el => <Option key={ el.id } value={ el.id }>{ this.print_semester(el.id) }</Option> ) }
                </Select>
                )}
              </FormItem>

              <FormItem {...formItemLayout} label="Grade" extra={ "If you have taken this class, select the grade you received." } >
                {getFieldDecorator('grade', {
                  initialValue: grade,
                  onChange: (event) => this.setState({ grade: event, edited: true }),
                })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter } allowClear={ true } disabled={ disabled || !semester_taken }>
                  { this.grade_list().map(el => <Option key={ el.id } value={ el.id }>{ this.print_grade(el.id) }</Option> ) }
                </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="Advertised" extra="How did you hear about the teaching assistant (TA) position?">
            {getFieldDecorator('heard_of_position', {
              rules: [{ required: true, message: 'Please select an option' }],
              initialValue: heard_of_position,
              onChange: event => this.setState({ heard_of_position: event, edited: true }),
            })(<Select showSearch style={{ width: 360 }} filterOption={ this.filter }>
                <Option key="CE" value="CE" default>College email</Option>
                <Option key="AE" value="AE">Advisor email</Option>
                <Option key="AC" value="AC">Advisor conversation</Option>
                <Option key="FE" value="FE">Faculty email</Option>
                <Option key="FC" value="FC">Faculty conversation</Option>
                <Option key="PC" value="PC">Peer conversation</Option>
                <Option key="TAP" value="TAP">I was a TA previously</Option>
                <Option key="TA" value="TA">From a TA in one of my classes</Option>
            </Select>
            )}
          </FormItem>
              <FormItem {...formItemLayout} label="Notes" extra="Please enter any additional notes about this application.">
                {getFieldDecorator('notes', {
                  initialValue: notes,
                  onChange: (event) => this.setState({ notes: event.target.value, edited: true }),
                })(<TextArea rows={4} disabled={ disabled } /> )}
              </FormItem>
              <FormItem {...formItemLayout} wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 }, }} >
                { (this.props.user.campuses.includes(campus) || campus == 118 || group) || (taapplication && !edited) ? (
                    <Button type="primary" onClick={ this.handleSubmit } disabled={ taapplication && !edited }>{ taapplication ? "Update" : "Submit" }</Button>
                  ) : (
                  <Popconfirm placement="top" title="You are applying to TA a course at a campus other than your own.  You are unlikely to be hired for this position.  Are you sure?" onConfirm={ this.handleSubmit } okText="Yes" cancelText="No">
                    <Button type="primary">{ taapplication ? "Update" : "Submit" }</Button>
                  </Popconfirm> ) }
                { taapplication ? (
                  <Popconfirm placement="top" title="Are you sure you wish to delete this TA application?" onConfirm={ this.handleDelete } okText="Yes" cancelText="No">
                    <Button style={{ margin: "0px 8px" }} type="danger" disabled={ disabled }>Delete</Button>
                  </Popconfirm>
                 ) : null }
              </FormItem>
            </React.Fragment>
          ) : "" }
        </Form>
      );
    }
  }
);

class TAAcceptForm extends AppComponent {
  render() {
    const { taapplication, handleClick } = this.props;

    return ( <RadioGroup buttonStyle="solid" style={{ width: "240px", float: 'right'}}>
               <RadioButton value="Accepted" onClick={ e => handleClick(taapplication, e.target.value) }>Accept</RadioButton>
               <RadioButton value="Refused" onClick={ e => handleClick(taapplication, e.target.value) }>Reject</RadioButton>
             </RadioGroup> );
  }
}

const TAHoursForm = Form.create({ name: 'ta_form_not_in_modal' })(
  class extends AppComponent {
    state = {
      hours: null,
      notes: "",
      loading: false,
    }

    componentDidMount() {
      this.update();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.taapplication && this.props.taapplication && prevProps.taapplication.id != this.props.taapplication.id) {
        this.update();
      }
    }

    update = () => {
      const { tahours, onEdited, index } = this.props;

      this.setState({
        hours: tahours ? tahours.hours : null,
        notes: tahours ? tahours.notes : "",
      }, () => onEdited(index, false) );
    }

    handleSubmit = (e) => {
      const { form, tahours, onCreateUpdate, onEdited, index } = this.props;
      const { getFieldDecorator } = form;
      e.preventDefault();
      form.validateFields((err, values) => {
        if (err) { return; }
        this.setState({ loading: true}, () => onCreateUpdate(tahours, this.props.form, this.props.date, () => this.setState({ loading: false}, () => onEdited(index, false))));
      });
    }
    
    modified = () => {
      const { form, tahours } = this.props;
      const { hours, notes } = this.state;
      
      if (tahours) { 
        return true;
      }
      
      const result = hours > 0 || notes != "";
      if (! result) {
        form.resetFields();
      }
      
      return result;
    }

    render() {
      const { form, taapplication, alltahours, tahours, editable, onEdited, edited, index, status } = this.props;
      const { getFieldDecorator } = form;
      const { hours, notes, loading} = this.state;

      const max = taapplication.hours - alltahours.filter(h => !tahours || tahours.id != h.id).reduce((r, a) => r+a.hours, 0);

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Form onSubmit={ this.handleSubmit } >
          <FormItem {...formItemLayout} label="Hours" extra="Enter the number of hours worked, to the nearest 15 minutes (0.25 hours).">
            {getFieldDecorator('hours', {
              rules: [{ required: tahours || (notes != ""), message: 'Please enter your hours.' },
                      { validator: (rule, value, callback) => { this.props.form.getFieldValue('hours') * 4 % 1 == 0 ? callback() : callback("The hours field must be a multiple of 0.25 hours (15 minutes)") } }],
              initialValue: hours,
              onChange: (event) => this.setState({ hours: event }, () => onEdited(index, this.modified())),
            })(<InputNumber disabled={ !editable } min={ 0.0 } max={ max } step={ 0.25 } style={{ width: "80px" }} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Activities" extra="Enter a brief description of your activities during these hours.">
            {getFieldDecorator('notes', {
              rules: [{ required: tahours || (hours > 0), message: 'Please enter some details about your activities.' }],
              initialValue: notes,
              onChange: (event) => this.setState({ notes: event.target.value }, () => onEdited(index, this.modified())),
            })(<TextArea rows={2} disabled={ !editable } /> )}
          </FormItem>
          <FormItem {...formItemLayout} label="Status">
            { edited ? "Unsubmitted" : status ? status : "None" }
          </FormItem>
          <FormItem {...formItemLayout} wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 }, }} >
            <Button type="primary" htmlType="submit" disabled={ !editable || !edited || loading }>{ tahours ? "Update" : "Submit" }</Button>
          </FormItem>
        </Form>
      );
    }
  }
);

class ConfirmHoursModal extends AppComponent {
  state = {
    isModalVisible: false,
    enteredName: false
  }

  showModal = () => {
    this.setState({isModalVisible : true});
  }

  closeModal = () => {
    this.setState({isModalVisible : false, enteredName: false});
  }

  checkIfNameIsEntered = (e) => {
    this.setState({enteredName : e.target.value.toUpperCase() === this.print_person_first(this.props.user.person).toUpperCase() });
  }

  render() {
    const { isModalVisible } = this.state;
    const { onConfirm } = this.props;

    const nameWidth = 25*this.print_person_first(this.props.user.person).length + "px";

    return (
      <>
        <Button block type="primary" onClick={() => this.showModal()}>
          Confirm All Hours
        </Button>
        <Modal title="Confirm Hours"
          destroyOnClose={true}
          visible={isModalVisible}
          okText="Confirm"
          cancelText="Cancel"
          onOk={onConfirm}
          onCancel={this.closeModal}
          okButtonProps={{ disabled:  !this.state.enteredName}}>
          <div style={{paddingBottom: '1%'}}>
            <p style={{display: 'inline'}}>I, </p>
            <Input style={{width: nameWidth}} placeholder={ this.print_person_first(this.props.user.person) } onChange={this.checkIfNameIsEntered}/>
            <p style={{display: 'inline'}}> <b>attest</b> that the hours I am about to confirm are an accurate record of the work I performed.</p>
          </div>
          <b>Note:</b>
          <ul>
            <li>All submitted hours will be sent to the course instructor(s) for review</li>
            <li>Any hours that are <b>not</b> an accurate representation of your work will lead to your dismissal, a report filed with OSCCR and prevent you from holding any future roles within the College</li>
            <li>You must be <b>physically</b> present in the country of your position (US, Canada, or the UK). If you are not, you are in violation of visa rules, which will lead to your immediate termination.</li>
          </ul>
          { !this.state.enteredName ? <Alert message="Please enter your first-name on the first line before 'attest' to continue." type="error" /> : null }
        </Modal>
      </>
    );
  }
};

const TAHoursConfirmForm = Form.create({ name: 'ta_form_not_in_modal' })(
  class extends AppComponent {
    handleSubmit = () => {
      const { alltahours } = this.props;
      this.props.onConfirm(alltahours.slice(0));
    }

    render() {
      const { form, alltahours, taapplication, editable, deadline } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      return (
        <Form onSubmit={ this.handleSubmit } >
          <FormItem extra={ "Hit Confirm to confirm all of this week's hours for " + this.print_course(taapplication.course) + ". Once you do so, you will no longer be able to edit hours or add more hours for this week.  You must submit and confirm your hours by " + deadline + " your time in order to be paid on time." } >
            { alltahours?.length > 0 ?
                editable ? (
                  <div>
                    <ConfirmHoursModal {...this.props} onConfirm={ this.handleSubmit }/>
                    <br /> 
                  </div>
                ) : (
                  <React.Fragment>
                    <Alert message="Submit edited hours" description="Please submit your edited hours forms above before confirming your hours." type="warning" showIcon />
                    <p></p>
                    <Button block type="primary" disabled>Confirm All Hours</Button>
                  </React.Fragment>
              ) : (
                <React.Fragment>
                  <Alert message="Submit hours" description="You need to first submit some hours before you are able to confirm them." type="warning" showIcon />
                  <p></p>
                  <Button block type="primary" disabled>Confirm All Hours</Button>
                </React.Fragment>
              ) }
          </FormItem>
        </Form>
      );
    }
  }
);

const TAAuditForm = Form.create({ name: 'ta_form_not_in_modal' })(
  class extends AppComponent {
    state = {
      not_confirmed: true,
    }

    handleSubmit = (e) => {
      const { taaudit, form } = this.props;
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
            this.doPatch("/api/ta/audit/" + taaudit[0].id + "/", response => {  this.props.onResponse(); }, JSON.stringify({'explanation' : values['explanation']}));
        } else {
          message.error(err);
        }
      });
    }

    render() {
      const { form, taaudit } = this.props;
      const { not_confirmed } = this.state;
      const { getFieldDecorator } = form;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      return (
        <Form onSubmit={ this.handleSubmit } >
        <FormItem {...formItemLayout}  label="Please provide an explanation for the following week(s):">
          <span className="ant-form-text">{taaudit[0].week}</span>
        </FormItem>
        <FormItem {...formItemLayout} label="Explanation" extra="Please provide an explanation for the week(s) above.">
          {getFieldDecorator('explanation', {
            rules: [{ required: true, message: 'You must provide an explanation for the question above.' }],
            initialValue: "",
          })(<TextArea style={{ width: 360 }} rows={4} /> )}
        </FormItem>
        <FormItem {...formItemLayout} label="Confirmation" colon={ false } extra="Please confirm that your explanation is true to the best of your knowledge.">
          {getFieldDecorator('confirmation', {
            rules: [{ required: true, message: 'Please Confirm.' }],
            onChange: (event) => this.setState({ not_confirmed: event.target.value}),
          })(<RadioGroup><Radio value={ false }>Yes</Radio></RadioGroup> )}
        </FormItem>
        <FormItem {...formItemLayout} wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 }, }} >
          <Button type="primary" htmlType="submit" disabled={not_confirmed}>{ "Submit" }</Button>
        </FormItem>
      </Form>
      );
    }
    }
    );

class TAApplication extends AppComponent {
  state = {
      endpoint_ta: "/api/ta/student/",
      ta: null,
      loading_ta: true,

      endpoint_taapplication: "/api/ta/application/",
      taapplications: [],
      loading_taapplications: true,

      endpoint_sections: "/api/schedule/",
      sections: [],
      loading_sections: true,

      endpoint_groups: "/api/schedule/group/",
      groups: [],
      loading_groups: true,
            
      editable: true,
      confirmed: false,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_sections + "?semester=" + this.props.semesters.join(",") + "&deleted=False",
            data => this.setState({ sections: data , loading_sections: false }));
    this.doGet(this.state.endpoint_groups + "?semester=" + this.props.semesters.join(","),
            data => this.setState({ groups: data , loading_groups: false }));
    this.getTA();
    this.getApplications();
  }

  getTA = () => {
    this.doGet(this.state.endpoint_ta + "?student=" + this.props.user.student,
                  data => this.setState({ ta: data && data?.length > 0 ? data[0] : null , loading_ta: false }));
  }

  getApplications = () => {
    this.doGet(this.state.endpoint_taapplication + "?ta__student__person=" + this.props.user.person.id + "&semester=" + this.props.semesters.join(","),
                  data => this.setState({ taapplications: data, loading_taapplications: false }));
  }

  handleCreateUpdateTA = () => {
    const form = this.formRef.props.form;
    const { ta } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }

      const data = new FormData();
      
      if (values.available_date != null) {
        values.available_date = moment(values.available_date).format(dateFormat);
      }

      delete values["resume"];
      
      if (ta) {
        this.doPatch(this.state.endpoint_ta + ta.id + "/", response => { form.resetFields(); this.setState({ ta: response, confirmed: true }); }, JSON.stringify(values));
      } else {
        this.doPost(this.state.endpoint_ta, response => { form.resetFields(); this.setState({ ta: response, confirmed: true }); }, JSON.stringify(values));
      }
    });
  }

  handlePassTA = () => {
    this.setState({ confirmed: true });
  }

  handleCreateUpdateTAApplication = (taapplication, form, preference) => {
    const { ta } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }

      values['campus'] = values.campusorgroup.includes("campus") ? parseInt(values.campusorgroup.substring(7)) : null;
      values['group'] = values.campusorgroup.includes("group") ? parseInt(values.campusorgroup.substring(6)) : null;
      delete values.campusorgroup;

      if (taapplication) {
        this.doPatch(this.state.endpoint_taapplication + taapplication.id + "/", response => { form.resetFields(); this.getApplications() }, JSON.stringify(values));
      } else {
        values["preference"] = preference;
        this.doPost(this.state.endpoint_taapplication, response => { form.resetFields(); this.getApplications() }, JSON.stringify(values));
      }
    });
  }

  handleDeleteTAApplication = (taapplication, form, preference) => {
    const { semester } = this.props;

    form.validateFields((err, values) => {
      if (err) { return; }

      this.doDelete(this.state.endpoint_taapplication + taapplication.id + "/", response => { form.resetFields(); this.getApplications() });
    });
  }

  handlePass = () => {
    this.setState({ confirmed: true });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  render() {
    const { semester } = this.props;
    const { sections, groups, ta, taapplications, confirmed, loading_taapplications, loading_tas, loading_sections } = this.state;

    const loading = loading_taapplications || loading_tas || loading_sections;
    
    const actual_sections = sections.filter(s => this.get_course(s.course).lab_for.length == 0);
    return (
      <Content {...this.props} title={ semester + " TA Application" } breadcrumbs={ [{ link: "/students", text: "Students" }, { text: "TA" }, { text: "Application" }] }>
        <p>Welcome to the TA application form.  All of your entries will be automatically saved; if an error occurs, a popup will be shown.</p>

        { loading ? ( <Spin tip="Loading application data" /> ) :
          !this.get_taportal_status(this.props.semesters) ? (
            <React.Fragment>
              <Alert message="The TA portal is currently closed" description="We are currently not accepting TA applications. Please be on the lookout for an official email from the College about the application timeframe." type="warning" showIcon /> 
            </React.Fragment>

          ) : !ta || !confirmed ? (
            <React.Fragment>
              <Divider orientation="left">Basic Information</Divider>
              <p>Please fill out (or update) the form below, which will be used in reviewing your application for a TA position.</p>
              <TAForm {...this.props} ta={ ta } getTA={ this.getTA } onCreateUpdate={ this.handleCreateUpdateTA } wrappedComponentRef={ this.saveFormRef } onPass={ this.handlePass } />

              { !ta ? ( <Alert message="Fill out the basic information form above" description="You cannot enter applications until you fill out the basic information form above." type="warning" showIcon /> ) : (<Alert message="Confirm your basic information above" description="You cannot submit or update applications until you confirm the basic information above is correct." type="warning" showIcon /> ) }
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Divider orientation="left">Applications</Divider>
              <p>You can apply for up to three courses below; <b> you should apply in your order of preference (with your first application being your most preferred).</b>  
              You can also edit any previous applications that have not been reviewed. <b></b>After a round of (released) TA assignments, if any of your applications have been reviewed but none have been accepted you will be able to submit additional applications equal to the total number of reviewed applications.</p>
              <p><strong>Please Note:</strong>
                <ul>
                  <li>Students cannot hold a position within the College after having graduated.</li>
                  <li>Students who are on co-op cannot simultaneously hold a position within the College.</li>
                  <li>Full-time employees of Northeastern cannot hold a TA position.</li>
                </ul>
              </p>
              <p>Also note that Online is considered a distinct campus.  If you wish to apply to a course offered on multiple campuses (for example, both in Boston and Online), you will need to submit two separate applications, one for the course on each campus (in this example, one application for Boston and one application  for Online).</p>
            { taapplications.map((el, idx) => [( <Divider key={ "div-"+ idx } >Application { idx+1 }</Divider> ),
                                           ( <TAApplicationForm {...this.props} key={ el.id } ta={ ta } sections={ actual_sections } groups={ groups }
                                             taapplications={ taapplications } taapplication={ el } onCreateUpdate={ this.handleCreateUpdateTAApplication } onPass={ this.handlePassTA } onDelete={ this.handleDeleteTAApplication } editable={ confirmed } /> ) ]) }
            { taapplications.length < 3 || (taapplications.some(el => el.released) && taapplications.filter(el => !el.released).length < 3) ? (
              <React.Fragment>
                <Divider>New Application</Divider>
                <TAApplicationForm {...this.props} key={ "new-" + taapplications.length } ta={ ta } sections={ actual_sections } groups={ groups } taapplications={ taapplications } onCreateUpdate={ this.handleCreateUpdateTAApplication } preference={ taapplications.length + 1 } editable={ ta && confirmed } />
              </React.Fragment>
            ) : ( <Alert message="No more applications allowed." description="You cannot submit more applications until all of your existing applications have been reviewed (and none have been accepted).  You may update any of your non-reviewed applications above." type="warning" showIcon /> ) }
          </React.Fragment> ) }
      </Content>
    );
  }
}

class TAAccept extends AppComponent {
  state = {
      endpoint_taapplication: "/api/ta/application/",
      taapplications: [],
      loading_taapplications: true,

      endpoint_sections: "/api/schedule/",
      sections: [],
      loading_sections: true,

      endpoint_group: "/api/schedule/group/",
      groups: [],
      loading_groups: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_sections + "?semester=" + this.props.semesters.join(","),
                  data => this.setState({ sections: data , loading_sections: false }));
    this.doGet(this.state.endpoint_group + "?semester=" + this.props.semesters.join(","),
                  data => this.setState({ groups: data , loading_groups: false }));
    this.getApplications();
  }

  getApplications = () => {
    this.doGet(this.state.endpoint_taapplication + "?ta__student__person=" + this.props.user.person.id + "&semester=" + this.props.semesters.join(","),
                  data => this.setState({ taapplications: data, loading_taapplications: false }));
  }

  handleAccept = (taapplication, state) => {
    const data = { "state": state };
    this.doPatch(this.state.endpoint_taapplication + taapplication.id + "/", response => { this.getApplications() }, JSON.stringify(data));
  }

  // Given a TA training history object, render it in a human readable format 
  renderTrainingHistory = (history) => {
    if (!isEmpty(history)) {
      return history.map(el => el.trainings.map(training => training + " (" + this.print_semester(el.semester) + ")").join(", ")).join(", ")
    } else {
      return "Not Completed"
    }

  }

  render() {
    const { semester } = this.props;
    const { sections, taapplications, loading_taapplications, loading_sections, loading_groups, groups } = this.state;

    const apps = taapplications.filter(el => el.state == "Assigned" || el.state == "Accepted" || el.state == "EIB" || el.state == "Hire-Submitted" || el.state == "I-9-incomplete" || el.state == "Hired");

    const loading = loading_taapplications || loading_groups;
    const TACampus = apps.length ? apps[0].campus ? apps[0].campus : apps[0].ta.campus  : 0;
    const trainingComplete = apps.length ? this.renderTrainingHistory(apps[0].ta.trainings_completed) : false;

    return (
      <Content {...this.props} title={ semester + " TA Assignment Acceptance" } breadcrumbs={ [{ link: "/students", text: "Students" }, { text: "TA" }, { text: "Accept" }] }>
        <p>Welcome to the TA assignment acceptance form.  </p>

        { loading ? ( <Spin tip="Loading application data" /> ) : apps.length ? (
          <React.Fragment>
            <Divider orientation="left">Khoury College TA Policies</Divider>
            <p>Upon the recommendation of the Khoury Staff, we are pleased to offer you an appointment as a Teaching Assistant for the Khoury College of Computer Sciences.</p>
            { (TACampus == 116 || TACampus == 118 || TACampus == 6079) ? (
            <div>
            <ul>
              <li><i>Undergraduate & Master’s students:</i>  Please refer to the offer letter sent via email for information on pay-rates. Your official pay-rate will be listed in WorkDay once your hire is complete which will be closer to the start date. Students can only work up to a maximum of 15 hours a week.</li>
            </ul>
            <p><b>Note: Khoury does not employ students that have graduated, are an incoming student, are participating in an internship or co-op or have had an Academic Integrity Violation.</b></p>
            <p><b>Note: Full-time employees of Northeastern cannot be hired as a TA.</b></p>

            <p>This is a part time appointment within the Khoury College of Computer Sciences. As employees, you are required to abide by the following:</p>
            <ul>
              <li>You are required to enter in your actual hours worked each week while not exceeding the maximum allotted to your position. <b>Note: Falsifying hours will lead to your immediate termination and will be reported to OSCCR.</b></li>
              <li><i>Undergraduate and Masters students must attend the course assistant training that will be offered early in the semester (PhD are strongly encouraged to attend)</i>. You will be paid for this time and attendance is mandatory if you have not completed the training in a previous semester.</li>
              <li><i>You must submit your hours each week by the deadline of <b>Sundays at 11:59:59 PM Boston time</b></i> on the Khoury Admin Portal. Any hours submitted directly to Work Day without prior approval will lead to your dismissal. In the case of a holiday, please follow direction from the Student Employment Office in regard to submittal.</li>
              <li><i>You must have an I-9 completed and on file before you can begin working.</i></li>
              <ul>
                <li><i>Domestic Students:</i> I-9s can be completed at the Student Employment Office which is located at 175 Richards Hall. You must have one on file before you can work in the College. A hire cannot be processed without the I-9.</li>
                <li><i>International Students:</i> Please contact the Office of Global Services to start the necessary paperwork which includes applying for a Social Security Number. You will need to fill out <a href="https://www.northeastern.edu/ogs/home/work/on-campus/">an on-campus employment SSN online form</a>. You should list Rayanne DeRosa as the supervisor, title is HR Associate with the email r.derosa@northeastern.edu and phone number 617-373-5894.</li>
                </ul>
                </ul>
                </div>
              ) : (
                <div>
                <ul>
              <li><i>Master’s Students:</i> You will be paid $19.00 - $21.00 an hour with the ability to work up to 15 hours a week. It is not expected that you work this amount of hours each week. It is important to note that you cannot exceed the maximum allotted hours for this particular position.</li>
            </ul>

            <p><b>Note:</b> We do not hire students who are currently on co-op unless approved by the hiring manager. Additionally, Khoury does not employ students that have had an Academic Integrity Violation. Students may take a maximum of 9 credit hours while TAing. </p>

            <p>This is a part time appointment within the Khoury College of Computer Sciences. As employees, you are required to abide by the following:</p>
            <ul>
              <li>You are required to enter in your actual hours worked each week while not exceeding the maximum allotted to your position. <b>Note: falsifying hours will lead to immediate termination and will be reported to OSCCR.</b></li>
              <li><i>Masters students must attend the course assistant training that will be offered early in the semester</i>. You will be paid for this time and attendance is mandatory if you have not completed the training in a previous semester.</li>
              <li><i>You must submit your hours each week by the deadline of <b>Sundays at 8:59:59 PM Pacific Standard time</b></i> on the Khoury Admin Portal. Any hours submitted directly to Student Employement without prior approval will lead to your dismissal. In the case of a holiday, please follow direction from the Student Employment Office in regard to submittal.</li>
              <li>You must have an I-9 completed and on file before you can begin working; both domestic and international students must complete this step. Instructions on how to complete Section 1 of Form I-9 can be located on the NU Student Employment Office website.  To complete Section 2, you must bring physical documents to the front desk at your regional campus.</li>
              <ul>
                <li>International Students: Please refer to the <a href="https://studentemployment.neu.edu/cimages/Information%20for%20International%20Students.pdf">Student Employment Office Guide for International Students</a> for details on how to complete the I-9.  If you do not have an SSN, as the SEO Guide indicates, you must first request online and then obtain from OGS an Employment Letter for F-1 Students (please check the above guide for details). The Employment Letter Request form should include the name of the supervisor listed on your Offer Letter.</li>
              </ul>
              </ul>
              </div>
              )}
              <ul> 
              <li><i>You must adhere to the <a href="http://www.northeastern.edu/osccr/code-of-student-conduct/">Student Code of Conduct</a>, the Northeastern University Confidentiality Agreement (below)</i>, as well as the employment/professional guidelines laid out by your instructor/supervisor and the academic degree program of which you are a part. You are to be present and fulfill all expected employment responsibilities for the entirety of the semester. The scope of employment will include but not be limited to first class welcome/orientation through the grading of final examinations or final projects. Any violation or neglect of these requirements will be considered a violation of the Student Code of Conduct.</li>
            </ul>

            <p>We appreciate your desire to work with our faculty, staff and students. Please be mindful that you are now a representative of the Khoury College of Computer Sciences, as well as Northeastern University as a whole. You are expected to behave in a professional manner to those you encounter.</p>

            <Divider orientation="left">Northeastern University Confidentiality Agreement</Divider>
            <p>As an employee, student employee, work study student, volunteer, intern or co-op student of the Khoury College of Computer Sciences, at Northeastern University, I may have access to sensitive or confidential information. This confidentiality agreement serves to verify that I have been made aware of the strict prohibition against inappropriate use of sensitive or confidential information.</p>

            <p>I understand that Northeastern University expects me to hold in confidence any information I may become privy to in the course of my work, volunteer activities, work study, internship and/ or co-op. Because this information is solely available to me as a result of my employment, work study, volunteer activities, internship or co-op, I will not discuss, use, forward, print, copy, photograph, record or otherwise disseminate any confidential or sensitive information that is given, shown, or available to me, or which otherwise comes to my attention, for purposes outside the legitimate scope of my work.</p>

            <p>Examples of confidential information that I may become aware of during the course of my employment, volunteer activities, work study, internship or co-op at Northeastern University include, but are not limited to:</p>
            <ul>
              <li>Information regarding the financial circumstances, giving and payment records, or financial aid status of students, prospective students, employees, associates, donors, alumni, guests, and the family members of the aforementioned, as well as those of corporations and other organizations which have an established or potential relationship with Northeastern University.</li>
              <li>Information from or regarding the educational records of students, prospective students, alumni, employees, donors, associates and guests of the College.</li>
              <li>Information regarding the physical or mental health or personal affairs of any of the aforementioned individuals.</li>
              <li>Information pertaining to Northeastern University’s finances or budget, public relations plans or details, communications plans or details, or other internal or sensitive institutional information.</li>
              <li>Information regarding access to Northeastern’s electronic files of any kind, and information pertaining to intellectual property of any kind, written or unwritten.</li>
            </ul>
            <p>I further agree that during the term of my employment/association and following my separation with such employment/association, I will be bound by this agreement. I am aware that failure to abide by this agreement may subject me to disciplinary action up to and including my immediate termination from my position.</p>
            { apps.map(el => {
               
               let elementSections = [];
               let course = "";
               if (el.campus) {
                 course = this.print_full_course(el.course);
                 elementSections = sections.filter(s => (s.course == el.course) && (s.semester == el.semester) && (s.campus == el.campus) && (groups.find(g => g.sections.find(sec => sec.id == s.id)) == null));
               } else {
                 let foundGroups = groups.find(g => g.id == el.group);
                 if (foundGroups){
                  elementSections = foundGroups.sections;
                  course = "(" + this.print_course(el.course) + ") " + foundGroups.name;
                 }s
               }s

               const instr = oxford(this.instructor_list_from_sections(elementSections).map(i => this.print_full_instructor(i.id)));
               return (
                <React.Fragment key={ el.id }>
                  <Divider orientation="left">{ course }, { this.print_semester(el.semester) }</Divider>
                  <p><b>Hours per week:</b> { el.hours }<br/>
                     <b>Instructors:</b> {instr?.length > 0 ? instr : "Pending Instructor Assignment" }<br/>
                     <b>TA Training:</b> { trainingComplete } </p>
                  {el.state == "Assigned" ? <TAAcceptForm {...this.props} key={ el.id } taapplication={ el } handleClick={ this.handleAccept } /> : <RadioGroup buttonStyle="solid" style={{ width: "240px", float: 'right'}}><RadioButton value="Accepted" disabled={true} >Already Accepted</RadioButton></RadioGroup>}
              </React.Fragment> ) }) }
          </React.Fragment>) : ( <Alert message="No pending assignments found" description={ "We could not find any pending TA assignments for the " + semester + " semester." } type="warning" showIcon /> ) }

      </Content>
    );
  }
}

class TAHoursPanel extends AppComponent {
  state = {
    endpoint_hours: "/api/ta/hours/",
    edited: this.props.dates.map(el => false),
  }

  handleCreateUpdateTAHours = (tahours, form, date, func) => {
    const { taapplication, semester, getHours } = this.props;

    form.validateFields((err, values) => {
      if (err) { return; }

      if (tahours) {
        this.doPatch(this.state.endpoint_hours + tahours.id + "/", response => { if (response) { form.resetFields(); getHours(func); } }, JSON.stringify(values));
      } else {
        values["taapplication"] = taapplication.id;
        values["date"] = date.format("YYYY-MM-DD");
        this.doPost(this.state.endpoint_hours, response => { if (response) { form.resetFields(); getHours(func); } }, JSON.stringify(values));
      }
    });
  }

  handleHoursConfirm = (alltahours) => {
    const { getHours } = this.props;

    if (alltahours?.length > 0) {
      const data = {"confirmed_at": moment().format("YYYY-MM-DDTHH:mm:ssZ")};
      this.doPatch(this.state.endpoint_hours + alltahours.shift().id + "/", response => { if (response) { this.handleHoursConfirm(alltahours) } }, JSON.stringify(data));
    } else {
      getHours();
    }
  }

  setEdited = (idx, value) => {
    var { edited } = this.state;
    edited[idx] = value;
    this.setState({ edited: edited });
  }

  render() {
    const { taapplication, dates, tahours, active } = this.props;
    const { edited } = this.state;

    const today = moment();
    const alltahours = tahours.filter(h => dates.find(el => el.format("YYYY-MM-DD") == h.date));
    const hoursforweek = alltahours.reduce((r, a) => r + a.hours, 0)
    const anyconfirmed = alltahours.reduce((r, a) => a.confirmed_at || r, false);

    const weektext = dates[0].format("MMMM Do") + " to " + dates[dates.length-1].format("MMMM Do");
    const deadline = getWeekDeadline(dates[dates.length-1]).local().format("dddd MMMM Do YYYY, h:mm:ss a");

    var chars = dates.map((el, idx) => { return { title: el.format("dddd MMMM Do, YYYY"), content: <TAHoursForm {...this.props} tahours={ tahours.find(h => h.date == el.format("YYYY-MM-DD")) } alltahours={ alltahours } taapplication={ taapplication } date = { el } editable={ el <= today && !anyconfirmed && active } index={ idx } edited={ edited[idx] } status={ tahours.find(h => h.date == el.format("YYYY-MM-DD")) ? tahours.find(h => h.date == el.format("YYYY-MM-DD")).recorded_at ? "Reported" : tahours.find(h => h.date == el.format("YYYY-MM-DD")).confirmed_at ? "Confirmed" : "Submitted" : null } onCreateUpdate={ this.handleCreateUpdateTAHours } onEdited={ this.setEdited } />}; } );
    if ((! anyconfirmed) && active) {
      const anyedited = edited.reduce((r, a) => r || a, false);
      chars = chars.concat({ title: "Confirm Hours", content: <TAHoursConfirmForm {...this.props} alltahours={ alltahours } editable={ !anyedited } taapplication={ taapplication } onConfirm={ this.handleHoursConfirm } deadline={ deadline }/> });
    }
 
    return ( <React.Fragment>
                <React.Fragment>
                  <Alert message={"Weekly hours submitted: " + JSON.stringify(hoursforweek) + " out of " + JSON.stringify(taapplication.hours) } type="info" showIcon />
                  <p></p>
                </React.Fragment>
              { alltahours?.length > 0 ?
                  anyconfirmed ? (
                    <React.Fragment>
                      <Alert message="Hours confirmed" description={ "You have previously confirmed your hours for " + this.print_course(taapplication.course) + " for the week of " + weektext + "; thus, the hours below are not editable.  If there are any errors or missing hours, you will need to contact your campus specific Khoury TA hiring manager." } type="success" showIcon />
                      <p></p>
                    </React.Fragment>
                  ) : active ? (
                      <React.Fragment>
                        <Alert message="Hours not confirmed" description={ "You have not yet confirmed your hours for " + this.print_course(taapplication.course) + " for the week of " + weektext +"; you will not be paid until you do so.  Once you confirm your hours, will not be able to edit your hours or add any additional hours for this week. The deadline for submitting and confirming your hours is " + deadline + " your time.  You can confirm your hours using the box below." } type="warning" showIcon />
                      <p></p>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Alert message="Confirmation date passed" description={ "You have submitted hours for " + this.print_course(taapplication.course) + " for the week of " + weektext +", but you did not confirm them by the deadline of " + deadline + " your time.  Your hours were not submitted, and you will need to contact your campus specific Khoury TA hiring manager in order to submit your hours and be paid.  Be sure to confirm your hours by the following Sunday in the future." } type="error" showIcon />
                      <p></p>
                      </React.Fragment>
                ) : active ? (
                  <React.Fragment>
                    <Alert message="No hours submitted" description={ "You have not submitted any hours for " + this.print_course(taapplication.course) + " for the week of " + weektext +".  The deadline for submitting and confirming your hours is " + deadline + " your time." } type="info" showIcon />
                  <p></p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Alert message="No hours submitted, but deadline has passed" description={ "You have not submitted any hours for " + this.print_course(taapplication.course) + " for the week of " + weektext +".  However, the deadline for submitting and confirming hours was " + deadline + " your time.  If you neglected to submit hours you worked, you will need to contact your campus specific Khoury TA hiring manager in order to submit your hours and be paid.  Be sure to confirm your hours by the deadline in the future." } type="warning" showIcon />
                  <p></p>
                  </React.Fragment>
                ) }
              <List grid={ this.grid } dataSource={ chars } renderItem={item => ( <List.Item><Card size="small" title={ item.title }>{ item.content }</Card></List.Item> )} />
            </React.Fragment> );
    }
}

class TAHours extends AppComponent {
  state = {
      endpoint_taapplication: "/api/ta/application/",
      taapplications: [],
      loading_taapplications: true,

      endpoint_hours: "/api/ta/hours/",
      hours: [],
      loading_hours: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taapplication + "?ta__student__person=" + this.props.user.person.id + "&semester=" + this.props.semesters.join(",") + "&state=Hired",
                  data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.getHours(); 
  }

  getHours = (func) => {
    this.doGet(this.state.endpoint_hours + "?taapplication__ta__student__person=" + this.props.user.person.id + "&taapplication__semester=" + this.props.semesters.join(","),
                  data => { data ? this.setState({ hours: data, loading_hours: false }, func) : func ? func() : null });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }


  getContent = (taapplication, tahours) => {
    const start = moment(this.get_semester(taapplication.semester).startdate, "YYYY-MM-DD");
    const end = moment(this.get_semester(taapplication.semester).enddate, "YYYY-MM-DD");
    if (moment() < start) { return ( <Alert message="Semester has not started yet" description={ "The " + this.print_semester(taapplication.semester) + " semester has not yet begun; you cannot bill hours until it has started." } type="warning" showIcon /> ); }

    const weeks = getSemesterWeeks(this.get_semester(taapplication.semester));
    const weeknums = Object.keys(weeks);
    weeknums.sort();

    const values = queryString.parse(window.location.search);

    return ( <React.Fragment key={ taapplication.id }>
               <Divider orientation="left">{ this.print_course(taapplication.course) } (up to { taapplication.hours} hours/week)</Divider>
               <CustomTabs {...this.props} default_Active_Key={ values["week"] ? values["week"] : moment() >= start && moment() <= end ? moment().format("ww") : weeknums[0] }>
                 { weeknums.map(el => (
                   <TabPane disabled={ moment() < weeks[el][0] } tab={ weeks[el][0].format("M/DD") + "-" + weeks[el][weeks[el].length-1].format("M/DD") } key={ el }>
                      <TAHoursPanel {...this.props} taapplication={ taapplication } dates={ weeks[el] } tahours={ tahours } getHours={ this.getHours } active={ isWeekConfirmable(weeks[el][weeks[el].length-1]) }/>
                   </TabPane>
                  ) ) }
               </CustomTabs>
             </React.Fragment> );
  }

  render() {
    const { semester } = this.props;
    const { taapplications, loading_taapplications, hours, loading_hours } = this.state;

    const loading = loading_taapplications || loading_hours;


    return (
      <Content {...this.props} title={ semester + " TA Hours" } breadcrumbs={ [{ link: "/students", text: "Students" }, { text: "TA" }, { text: "Hours" }] }>
        <p>Welcome to the TA hours submission form. All of your entries will be automatically saved; if an error occurs, a popup will be shown.</p> 
          <ul>
            <li>You are required to enter in your actual hours worked each week while not exceeding the maximum allotted to your position.</li>
            <li><b>Note: Falsifying hours will lead to your immediate termination and you will be reported to OSCCR.</b></li>
            <li><b>Note: You must be physically present in the country of your assigned position (US, Canada, or the UK) to work and submit hours, regardless if you leave for vacation or an emergency. Failure to comply will result in violation of visa regulations, leading to immediate termination.</b></li>
          </ul>

        { loading ? ( <Spin tip="Loading hours" /> ) : taapplications?.length > 0 ? taapplications.map(el => this.getContent(el, hours.filter(h => h.taapplication == el.id))) : ( <Alert message="No hired assignments found" description={ "We could not find any pending TA assignments for the " + semester + " semester that have been fully processed." } type="warning" showIcon /> ) }
      </Content>
    );
  }
}

class TAAudit extends AppComponent {
  state = {
      endpoint_taaudit: "/api/ta/audit/",
      taaudit: [],
      loading_taaudit: true,

      endpoint_taapplication: "/api/ta/application/",
      taapplications: [],
      loading_taapplications: true,

      endpoint_hours: "/api/ta/hours/",
      hours: [],
      loading_hours: true,
  }


  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_taaudit, data => this.setState({ taaudit: data, loading_taaudit: false }));
    this.doGet(this.state.endpoint_taapplication + "?ta__student__person=" + this.props.user.person.id + "&semester=" + this.props.semesters.join(","),
                  data => this.setState({ taapplications: data, loading_taapplications: false }));
    this.doGet(this.state.endpoint_hours + "?taapplication__ta__student__person=" + this.props.user.person.id + "&taapplication__semester=" + this.props.semesters.join(","),
                  data => this.setState({ hours: data, loading_hours: false }));
  }

  render() {
    const { semester } = this.props;
    const { taaudit, loading_taaudit, taapplications, hours, loading_taapplications, loading_hours } = this.state;
    let audit_ta_app = (taaudit.length ? taapplications.filter(el => el.id == taaudit[0].taapplication.id) : []);
    return (
      <Content {...this.props} title={ semester + " TA Audit" } breadcrumbs={ [{ link: "/students", text: "Students" }, { text: "TA" }, { text: "Audit" }] }>
        { loading_taapplications || loading_taaudit || loading_hours  ? ( <Spin tip="Loading Audit data" /> ) : audit_ta_app.length ? (
          <React.Fragment>
          <p>Please be advised your submitted hours have been flagged for audit. You are required to submit a response to the below question and confirm that your answer is accurate to the best of your knowledge. 
          <strong> Not responding and taking the required action may result in the termination of your TA position with Khoury College.</strong></p>
          <p>Please respond by: {moment(taaudit[0].created_at).add(3, "d").format('M/DD')} <Tag color={moment().isAfter(moment(taaudit[0].created_at).add(3, "d")) ? "red": "green"}>{moment().isAfter(moment(taaudit[0].created_at).add(3, "d")) ? "Overdue": "Time Remaining"}</Tag></p>
          <TAHoursTable {...this.props} loading={ loading_taapplications || loading_hours || loading_taaudit} taapplications={ audit_ta_app} tahours={ hours } tasemester={ audit_ta_app[0].semester }/>
          < br />
        <TAAuditForm {...this.props} taaudit={ taaudit } onResponse={this.getData}/>
          </React.Fragment>) : ( <Alert message="No Audit Required" description={ "We could not find any pending Audits for your TA position for " + semester + " semester." } type="warning" showIcon /> ) }
      </Content>
    );
  }
}

export { TAApplication, TAAccept, TAHours, TAApplicationCard, TAHoursCard, TAAudit, TAAuditForm, IAAssignmentCard };
