import React from "react";
import AppComponent from "../../../AppComponent";
import Content from "../../../Content";
import { Radio, Table, Form, Divider, Select, Button, message, Alert } from "antd";
import { get_check, get_nocheck } from "../../../Utils";

const FormItem = Form.Item;
const { Option } = Select;

const MSCourseSurveyForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {

    handleSubmit = e => {
      const { form, updateSubmittedState, endpoint, semesters, viewsurvey } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
      if (err) { return; }

          const courses = [];
          const courseData = values;
          const on_campus_required= courseData["on_campus_required"]
          delete courseData["on_campus_required"]
          
          Object.values(courseData).map((course, index) => {
            courses.push({
              "course": course,
              "preference_rank": index + 1,
              "on_campus_required": on_campus_required
            })
          });
          const data = {
            courses,
            semester: semesters[0],
          };
          if (viewsurvey.length === 0) {
            this.doPost(endpoint, (data) => {
              if (data) {
                message.success("Your course preferences have been submitted.");
                updateSubmittedState(data);
                form.resetFields();
              }
            }, JSON.stringify(data));
        
            
          } else {
            this.doPatch(endpoint + (viewsurvey[0]["id"]).toString() + "/", (data) => {
              message.success("Your course preferences have been updated.");
              updateSubmittedState(data);
              form.resetFields();
            }, JSON.stringify({ "courses": data["courses"] }));
          }
      });
    };

    checkForDupPreferences = () => {
      let course1 = this.props.form.getFieldValue("course1");
      let course2 = this.props.form.getFieldValue("course2");
      const values = [course1, course2]

      return (new Set(Object.values(values))).size !== Object.values(values).length && !Object.values(values).includes("");
    }
 
    render() {
      const { form, viewsurvey } = this.props;
      const { getFieldDecorator, getFieldValue } = form;
  
      const preferencesAllowed = [1, 2];

      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true
      };

      return (
        <Form onSubmit={this.handleSubmit} >
          {preferencesAllowed.map((el, idx) =>
              <FormItem
                {...formItemLayout}
                label={"Preference " + (idx + 1)}
                key={idx}
                extra={(idx === 0) ? ("Please select the course you would most like to take.") : ("Please select another course you would like to take.")}
              >
                {getFieldDecorator("course" + (idx + 1), {
                  rules: [
                    { required: true, message: "Please select a course." },
                    // { validator: (rule, value, callback) => !this.checkForDupPreferences() ? callback() : callback("Choose unique courses")},
                  ],
                  
                  initialValue: viewsurvey?.[0]?.courses?.[idx]?.course,
                })(
                  <Select showSearch style={{ width: 360 }} filterOption={this.filter} onSelect={this.handleValuesChange}>
                    {this.course_list_graduate().map(el => (
                      <Option key={el.id} value={el.id}>{this.print_full_course(el.id)}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
          )}
          { this.checkForDupPreferences() && (getFieldValue("course1") && getFieldValue("course2")) && (
          <FormItem {...this.formItemLayout} label=" " colon={ false } style={{ paddingTop: 0 }}>
            { <p style={{color: "red", margin: 0}}>You must select unique courses</p> }
          </FormItem>
          )}
          <FormItem {...this.formItemLayout} label="On Campus" extra={"Do you need an on-campus course to maintain student status?"} > 
            {getFieldDecorator("on_campus_required", {
              rules: [{ required: true, message: "Please select an option."}],
              initialValue: viewsurvey.length == 0 ? "" : viewsurvey?.[0]?.courses?.[0]?.on_campus_required ? true : false
            })(
              <Radio.Group>
                    <Radio style={{ display: 'block' }} value={true}>Yes</Radio>
                    <Radio style={{ display: 'block' }} value={false}>No</Radio>
              </Radio.Group>
            )
            } 

          </FormItem>
          <FormItem
            {...formItemLayout}
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 }
            }}
          >
            <Button type="primary" htmlType="submit" disabled={this.checkForDupPreferences()}>
              {"Submit"}
            </Button>
          </FormItem>
        </Form>
      );
    }
  }
);

class MSCourseSurvey extends AppComponent {
  state = {
    endpoint: "/api/petition/ms/coursesurvey/",
    loading_survey: true,
    viewsurvey: [],
    // submitted: false,
    endpoint_viewable: "/api/petitionviewable/",
    visible: false,
  };

  componentDidMount() {
    this.getData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    };
  };

  updateSubmittedState = (data) => {
    this.setState({viewsurvey: [data]});
  };


  getData = () => {
    this.doGet(this.state.endpoint_viewable + "?semester=" + this.props.semesters.join(","), data => { this.setState({ visible: ((data[0] || {}).petitions_visible_mcs || false) }); });
    this.doGet(this.state.endpoint + "?semester=" + this.props.semesters.join(",") + "&student__person=" + this.props.user.person.id, data => this.setState({ viewsurvey: data, loading_survey: false }));
  };

  render() {
    const { loading_survey, endpoint, viewsurvey } = this.state;
    const loading = loading_survey;

    const columns = [
      {
        title: "Courses selected",
        key: "courses",
        width: 320,
        render: (text, record, idx) => <ul>{record.courses.map((course) => <li key={course.course}>Preference {course.preference_rank}: {this.print_full_course(course.course)} </li>)}</ul>
      },
      {
        title: "On-campus course needed",
        key: "on_campus",
        width: 320,
        render: (text, record, idx) => record.courses[0].on_campus_required ? get_check() : get_nocheck()
      },
    ];

    return (
      <Content
        {...this.props}
        title={"MS Course Preference Survey for " + this.props.semester}
        breadcrumbs={[{ link: "/student", text: "Students" }, { text: "MS" }, { text: "Course Survey" }]}
      >
        <p>
          Welcome to the new MS course preference survey. <strong>Note: This survey is only intended for collecting interest and is not used for any registration purposes.</strong>
          You are not guaranteed access to these courses. Please indicate your preference of courses for the upcoming semester. All of your entries will not be saved until you click
          submit; if an error occurs, a popup will be shown.
        </p>
        <React.Fragment>
          <Divider orientation="left">
            Submitted MS Course Survey
          </Divider>
          <Table
            dataSource={viewsurvey}
            columns={columns}
            loading={loading}
            rowKey="id"
          />
          {(this.state.visible) ? (
            <React.Fragment>
              <Divider orientation="left">MS Course Preference Survey</Divider>
              {(this.state.viewsurvey.length === 0) ?
                <p>
                  Please fill out the form below.
                </p>
                :
                <p>
                  Resubmit form below to edit your selections.
                </p>}
              <MSCourseSurveyForm
                {...this.props}
                getData={this.getData}
                updateSubmittedState={this.updateSubmittedState}
                endpoint={endpoint}
                viewsurvey={viewsurvey}
                loading={loading}
              />
            </React.Fragment>
          ) :
            <Divider orientation="center">
              {" "}
              Survey Period is Closed
            </Divider>}
        </React.Fragment>
      </Content>
    );
  };
};

export { MSCourseSurvey };
