import React from "react";
import AppComponent from './../AppComponent';
import Content from "./../Content";
import {  Form, Table, Icon,  Menu,  Dropdown,  Switch,  Modal,  Input,  Typography,  Alert, Button, Divider, Radio, Select} from "antd";
import moment from "moment-timezone";
import { get_check_nocheck, renderStatus } from "./../Utils";
import { CSVLink } from "react-csv";

const { TextArea } = Input;
const FormItem = Form.Item;

const DataFilterForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    state = {
      course: [],
      campus: [],
      ta_level: [],
      preference: [],
      state: [],
    }

    componentDidMount() {
      this.setState({ course: [], campus: [], ta_level: [], ta_preference: [], state: []});
      this.props.form.resetFields();
    }

    flattenObject = (ob, prefix = false, result = null) => {
      result = result || {};
    
      // Preserve empty objects and arrays, they are lost otherwise
      if (typeof ob === 'object' && ob !== null && Object.keys(ob).length === 0) {
        result[prefix] = Array.isArray(ob) ? [] : {};
        return result;
      }
    
      prefix = prefix ? prefix + '_' : '';
      for (const i in ob) {
        if (Object.prototype.hasOwnProperty.call(ob, i)) {
          if (typeof ob[i] === 'object' && ob[i] !== null) {
            // Recursion on deeper objects
            this.flattenObject(ob[i], prefix + i, result);
          } else {
            result[prefix + i] = ob[i];
          }
        }
      }
      return result;
    }

    buildFilter = (filter) => {
      let query = {};
      for (let keys in filter) {
          if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
          }
      }
      return query;
    }
  
    filterData = (data, query) => {
        data = data.map(el => this.flattenObject(el));
        const filteredData = data.filter( (item) => {
            for (let key in query) {
                if (item[key] === undefined || !query[key].includes(item[key])) {
                    return false;
                }
            }
            return true;
        });
        return filteredData;
    }

    get_TA_History = (taapp) => {
      return taapp && taapp.history ? taapp.history.map(taa => this.print_course(taa.course) + " " + this.print_semester(taa.semester) + " " + taa.state): "";
    }

    csv_data = (taapplications, tasemester, data) => {
      let download_data = JSON.parse(JSON.stringify(data));
      download_data.forEach(el => {
        let taapp = taapplications.find(taapp => el["id"] == taapp.id);
    
        el["ta_campus"] = this.print_campus(el["ta_campus"]);
        el["campus"] = this.print_campus(el["campus"]);
        el["course"] = this.print_course(el["course"]);
        el["history"] = this.get_TA_History(taapp);
        el["first_time_ta"] = taapp.history.filter(el => el.semester < tasemester && (el.state != "Rejected" || el.state != "Resigned")).length === 0;
        el["notes"] = "";
        el["semester"] = this.print_semester(el["semester"]);
        el["semester_ta"] = this.print_semester(el["semester_ta"]);
        el["semester_taken"] = this.print_semester(el["semester_taken"]);
        el["grade"] = el["grade"] ? this.print_grade(el["grade"]) : "";
        // el["ta_training_complete"] = !!el["ta_trainings_completed_0_semester"];
        el["ta_advisor"] = el["ta_advisor"] ? this.print_instructor(el["ta_advisor"]) : "";
    
        // Map through all the training objects to return the semester a completed semester took place
        let trainings_completed = taapp.ta.trainings_completed || [];
        let training_map = {
          "Synchronous Session 1 Completed": null,
          "Synchronous Session 2 Completed": null,
          "Synchronous Training Completed": null,
          "No synchronous training completed": null
        };
        let totalTrainings = 0;
        trainings_completed?.forEach(trainingObj => {
          trainingObj.trainings?.forEach(completed => {
            const semester = trainingObj.semester;
            if (completed === "Synchronous Session 1 Completed") {
              training_map["Synchronous Session 1 Completed"] = semester ? this.print_semester(semester) : "";
              totalTrainings += 1;
            } else if (completed === "Synchronous Session 2 Completed") {
              training_map["Synchronous Session 2 Completed"] = semester ? this.print_semester(semester) : "";
              totalTrainings += 1;
            } else if (completed === "Synchronous Training Completed") {
              training_map["Synchronous Training Completed"] = semester ? this.print_semester(semester) : "";
              totalTrainings += 1;
            } else if (completed === "No synchronous training completed") {
              training_map["No synchronous training completed"] = semester ? this.print_semester(semester) : "";
              totalTrainings += 1;
            }
            
          });
        });
    
        // Assign the training info to columns
        el["Synchronous Session 1 Completed"] = training_map["Synchronous Session 1 Completed"] || "";  
        el["Synchronous Session 2 Completed"] = training_map["Synchronous Session 2 Completed"] || "";  
        el["Total Trainings Completed"] = totalTrainings;
        el["Synchronous Training Completed"] = training_map["Synchronous Training Completed"] || "";  
        el["No synchronous training completed"] = training_map["No synchronous training completed"] || "";  
      });
      
      return download_data;
    };
    
  
    render() {
      const { form, filter_value, taapplications, tasemester } = this.props;
      const { getFieldDecorator } = form;
      const  { course, campus, ta_level, ta_preference, state } = this.state;
      const amount_exported_taapps = this.filterData(taapplications, this.buildFilter(this.state)).length;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 } }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, colon: true };

      const headers = [
          { label: 'NUID', key: 'ta_nuid'},
          { label: 'E-Mail', key: 'ta_email'},
          { label: 'First Name', key: 'ta_firstname'},
          { label: 'Last Name', key: 'ta_lastname'},
          { label: 'TA Campus', key: 'ta_campus'},
          { label: 'GPA', key: 'ta_gpa'},
          { label: 'Level', key: 'ta_level'},
          { label: 'Local', key: 'ta_local'},
          { label: 'TA History', key: 'history'},
          { label: 'First Time TA', key: 'first_time_ta'},
          { label: 'Advisor', key: 'ta_advisor'},
          { label: 'Available', key: 'ta_available'},
          { label: 'Available Date', key: 'ta_available_date'},
          { label: 'Course Semester', key: 'semester'},
          { label: 'Course Campus', key: 'campus'},
          { label: 'Course', key: 'course'},
          { label: 'Course Grade', key: 'grade'},
          { label: 'Preference', key: 'preference'},
          { label: 'State', key: 'state'},
          { label: 'Semester TAd', key: 'semester_ta'},
          { label: 'Semester Taken', key: 'semester_taken'},
          // { label: 'Completed Training', key: 'ta_training_complete'},
          { label: 'Synchronous Session 1 Completed', key: 'Synchronous Session 1 Completed' },
          { label: 'Synchronous Session 2 Completed', key: 'Synchronous Session 2 Completed' },
          { label: 'Synchronous Training Completed', key: 'Synchronous Training Completed' },
          { label: 'No Synchronous Training Completed', key: 'No synchronous training completed' },
          { label: 'Total Trainings Completed', key: 'Total Trainings Completed' },
          { label: 'Hire ID', key: 'hire_id'},
          { label: 'Hours', key: 'hours'},
          { label: 'Pay Rate', key: 'pay_rate'},
          { label: 'Record Last Changed', key: 'state_last_changed_at'},
          { label: 'Submitted At', key: 'submitted_at'}
      ];

      return (
        <>
          <p>To export TA application data to a spreadsheet (csv file), please adjust the filters below to fit your requirements. </p>
          <p><strong>Note: </strong>Leaving a filter blank will return all.</p>
          <Divider orientation="left">Data Filters</Divider>
          <Form>
            <Form.Item {...formItemLayout} label="Campus">
              {getFieldDecorator("campus", 
              { rules: [{ required: false }], initialValue: campus, onChange: (event) => this.setState({ campus: event }),})(
                <Select mode="multiple" style={{ width: 200 }} filterOption={this.filter}>
                  { [...filter_value.campus].map(el => ( <Select.Option key={el} value={el}> {this.print_campus(el)} </Select.Option> ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Course">
              {getFieldDecorator("course",
               { rules: [{ required: false }], initialValue: course, onChange: (event) => this.setState({ course: event }),})(
                <Select mode="multiple" style={{ width: 200 }} filterOption={this.filter}>
                  { [...filter_value.course].map(el => ( <Select.Option key={el} value={el}> {this.print_course(el)} </Select.Option> ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="TA Level">
              {getFieldDecorator("ta_level", 
              { rules: [{ required: false }], initialValue: ta_level, onChange: (event) => this.setState({ ta_level: event }),})(
                <Select mode="multiple" style={{ width: 200 }} filterOption={this.filter}>
                  { [...filter_value.level].map(el => ( <Select.Option key={el} value={el}> {el} </Select.Option> ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="TA Preference">
              {getFieldDecorator("preference", 
              { rules: [{ required: false }], initialValue: ta_preference, onChange: (event) => this.setState({ preference: event }),})(
                <Select mode="multiple" style={{ width: 200 }} filterOption={this.filter}>
                  { [...filter_value.preference].map(el => ( <Select.Option key={el} value={el}> {el} </Select.Option> ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="TA Status">
              {getFieldDecorator("ta_status", 
              { rules: [{ required: false }], initialValue: state, onChange: (event) => this.setState({ state: event }),})(
                <Select mode="multiple" style={{ width: 200 }} filterOption={this.filter}>
                  { [...filter_value.state].map(el => ( <Select.Option key={el} value={el}> {el} </Select.Option> ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              <p><strong>{amount_exported_taapps}</strong> TA Applications will be exported</p>
            </Form.Item>
            </Form>
            <span style={{ marginLeft: "auto" }}>
              <CSVLink filename={"TAApp-Data-Export_" + moment().format("DD-MM-YYYY") + ".csv"} headers={headers} data={this.csv_data(taapplications, tasemester, this.filterData(taapplications, this.buildFilter(this.state)))}><Button type="primary" disabled={amount_exported_taapps == 0}>Export</Button></CSVLink>
            </span>
        </>
      )
    }
  })

class TADataExport extends AppComponent {
    state = {
        modal_visible: false,
        data_columns: "",
        ta_campus: "",
        state: "",
        ta_level: "",
      };


    compute_filters = () => {
      const { taapplications } = this.props;

      let rObj = {course: new Set(), campus: new Set(), preference: new Set(),state: new Set(),level: new Set()};

      taapplications.forEach((el) => {
        rObj["course"].add(el.course)
        rObj["campus"].add(el.campus)
        rObj["preference"].add(el.preference)
        rObj["state"].add(el.state)
        rObj["level"].add(el.ta.level)
     })
     return rObj;
    }

    csv_data = () => {
      return [];
    }
  
    render() {
      return (
        <React.Fragment>
            <Button loading={this.props.loading} onClick={ () => this.setState({modal_visible: true})}>Export</Button>
                <Modal 
                  title="TA Data Export" 
                  visible={this.state.modal_visible} 
                  onCancel={ () => this.setState({modal_visible: false}) }
                  footer={null}
                  width={600}>
                    <DataFilterForm {...this.props} onSubmit={ this.submit_form } filter_value={this.compute_filters()} taapplications={this.props.taapplications} tasemester={ this.props.tasemester}/>
                </Modal>
        </React.Fragment>        
      );
    }
  }
  
  export default TADataExport;
