import React, { Component } from "react";
import { Layout, Card, List, Table, Spin, Form, Switch, Button, Modal, Input, Alert, InputNumber, Radio, Select, Menu, Dropdown, Icon, Tooltip, Checkbox, Divider, message } from 'antd';
const { Header, Content } = Layout;
import LoginLayout from './LoginLayout';
import { login, ssologin } from '../Utils';
import queryString from 'query-string';

const FormItem = Form.Item;

const LOGIN_TIMEOUT_SECONDS = 10;

const Login = Form.create({ name: 'login' })(
  class extends Component {

    state = {
      login_code: "",
      loading: false,
      need_2fa: false,
      login_error: null,
      state_2fa: null,
      access_request_needed: false,

      timer: null,
    };

    componentDidMount() {
      const sso_code = queryString.parse(window.location.search)?.code;
      const sso_state = queryString.parse(window.location.search)?.state;
      if (sso_code && sso_state) {
        this.handleSSOCode();
      }
    }

    componentWillUnmount() {
      clearTimeout(this.state.timer);
    }


    handleSSOCode = () => {
      const { logged_in } = this.props;
      const { state_2fa, login_code } = this.state;

      const sso_code = queryString.parse(window.location.search)?.code;
      const sso_state = queryString.parse(window.location.search)?.state;

      const timer = setTimeout(() => this.setState({ timer: null, loading: false, login_error: "We are experiencing issues reaching the authentication server, please try again later." }), 1000 * LOGIN_TIMEOUT_SECONDS);

      this.setState({ timer: timer, loading: true, login_error: null }, () => {
        ssologin(sso_code, sso_state, login_code, state_2fa, response => {
          clearTimeout(timer);
          this.setState({ loading: false, timer: null }, () => {
            if (response.key) {
              this.setState({ login_error: null }, () => logged_in(response.key, true));
            } else if (response["2fa_needed"]) {
              this.setState({ need_2fa: true, login_error: response["2fa_error"], state_2fa: response["state_2fa"] });
            } else {
              this.setState({ login_error: "Unrecognized response from server." });
            }
          });
        });
      });
    }

    //backend will return AccessRequest forms for students not recognized. Add above.

    validateForm() {
      const { login_code, need_2fa } = this.state;

      const sso_code = queryString.parse(window.location.search)?.code;

      return (!need_2fa || login_code);
    }

    submit = (e) => e.keyCode == 13 && this.state.login_code?.length > 0 ? this.handleSSOCode() : null;
    focus = (input) => input && input.focus();

    initiateLogin = () => {
      if ((window.location.origin + window.location.pathname) != window.location.href){
        sessionStorage.setItem('savedUrl', window.location.href)
      }
      window.open("/api/sso/login/", "_self")
    }

    render() {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const { login_code, loading, login_error, need_2fa, access_request_needed } = this.state;

      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };


      const sso_code = queryString.parse(window.location.search)?.code;

      return (

        <LoginLayout {...this.props}>

          <Card title="Khoury Administration Site" style={{ width: "40" }}>
            <p>Welcome to the Khoury College Administration site; Please use your My.Northeastern account to login.</p>

            { login_error ? ( <Alert message={ login_error } type="error" showIcon /> ) : "" }

            {sso_code && !login_error && !need_2fa ? (<Spin tip="Validating information from Microsoft SSO." />) : (
              <div>
                {!need_2fa ? (
                  <div>
                    <FormItem>
                      <Button type="primary" disabled={sso_code && !login_error} onClick={() => this.initiateLogin()}>Log in with my.Northeastern</Button>
                    </FormItem>
                  </div>
                ) : null}

                <Divider orientation="center">{need_2fa ? "Two Factor Authentication Code" : ""}</Divider>

                {need_2fa && !sso_code ? (<p>As an Admin, please enter the code from your generator below, it has limited validity before refreshing. <b>NOTE:</b> You will not receive a push notification from Northeastern's DUO service.</p>) : null}

                <Form>
                  {need_2fa ? (
                    <FormItem {...formItemLayout} label="2FA Code">
                      {getFieldDecorator('code', {
                        initialValue: login_code,
                        onChange: (e) => this.setState({ login_code: e }),
                      })(<InputNumber size="large" min={0} max={999999} ref={this.focus} onKeyDown={this.submit} />)}
                    </FormItem>
                  ) : null}

                  {need_2fa ? (
                    <FormItem>
                      <Button type="primary" onClick={sso_code ? this.handleSSOCode : null} loading={loading} disabled={!this.validateForm()}>
                        {!loading ? <Icon type="login" /> : null} {need_2fa ? "Submit" : "Log in with Khoury"}
                      </Button>
                    </FormItem>
                  ) : null}
                </Form>
              </div>
            )}
          </Card>
        </LoginLayout>
      );
    }
  }
);

export default Login;
