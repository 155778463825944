import React from "react";
import AppComponent from "../../../AppComponent";
import moment from "moment-timezone";
import { WorkflowSubmit } from "../../WorkflowSubmit";
import { WorkflowTable } from "../../WorkflowTable";
import Transcript from "../../../Transcript";
import FileUpload from "../../../FileUpload";

import { Icon, Upload, Form, Input, Divider, Select, Button, InputNumber, message, Typography, Descriptions, DatePicker, Tooltip, Checkbox, Spin } from "antd";
import { get_check } from "../../../Utils";
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const APPEALTYPE = {
  BTB: "Back-to-back co-op (Fall/Spring terms only)",
  EXT: "Co-op extension",
  GRA: "Graduating immediately following a co-op",
  LEA: "Going on co-op followed by medical leave or personal leave of absence",
  ELG: "Requesting to search for or accept a co-op without meeting co-op eligibility requirements",
  THR: "Requesting to conduct a third consecutive co-op search after not landing my two previous searches",
  TER: "Terminated from a previous co-op and requesting to search again",
  LAT: "Appealing to search for co-op after President's Day (Spring) or Indigenous People's Day (Fall)",
  REE: "Pursue co-op credit or regain eligibility after ending co-op early without prior approval",
  REL: "Pursue co-op credit or regain eligibility after starting co-op late without prior approval",
  RER: "Pursue co-op credit or regain eligibility after reneging on an accepted co-op offer",
  RRO: "Requesting to renege on a co-op offer",
  WAI: "Waive CS1210: Professional Development for Khoury Co-op",
  SDF: "Requesting to conduct a self-directed co-op as first co-op",
  SDN: "Requesting to conduct a self-directed co-op after my proposal was not approved",
  OTH: "Other"
}

const SITUATIONTYPE = {
  RCO: "I received a co-op offer, but have not accepted. I am appealing for approval.",
  ACO: "I received a co-op offer and accepted. I am appealing for approval.",
  NRO: "I have not yet secured a co-op offer. I am appealing to continue my search."
}

const UGCoopAppealForm = Form.create({ name: "form_in_modal" })(
  class extends AppComponent {
    state = {
      uploadedList: [],
    };

    componentDidMount() {
      this.reset();
    }

    reset = () => {
      this.setState({ uploadedList: [] });
      this.props.form.resetFields();
    };

    formatData = (values) => {
      values.decision_deadline = values.decision_deadline.format("YYYY-MM-DD");

      values.offers = [];

      if (values.offer1?.company_name && values.offer1?.position_name) {
        values.offers.push({ ...values.offer1, offer_number: 1 });
      }

      if (values.offer2?.company_name && values.offer2?.position_name) {
        values.offers.push({ ...values.offer2, offer_number: 2 });
      }
      delete values.offer1;
      delete values.offer2;

      return values;
    };

    handleSubmit = () => {
      const { uploadedList } = this.state;
      const form = this.props.form;

      form.validateFields((err, values) => {
        const data = this.formatData(values);
        if (err) {
          return;
        } else {
          this.props.onSubmit(
            { ...data, ...{ files: uploadedList } },
            this.reset()
          );
        }
      });
    };

    handleFileUpdate = (fileData) => {
      if (fileData) {
          // Transform the fileData into the required format
          const transformedData = [fileData.fileData, { type: fileData.type }];
          this.setState({ uploadedList: [transformedData] });
      } else {
          this.setState({ uploadedList: [] });  
      }
  };


    // This function creates a form that gets information about a student's co-op offer.
    // It is called conditionally in the main form.
    createOfferForm(offer_number, required) {
      const { form } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true,
      };
      let requireOfferFields =
        required ||
        form.getFieldValue(`offer${offer_number}.company_name`) ||
        form.getFieldValue(`offer${offer_number}.position_name`) ||
        form.getFieldValue(`offer${offer_number}.employer_contact`) ||
        form.getFieldValue(`offer${offer_number}.employer_email`);

      return (
        <React.Fragment>
          <FormItem
            {...formItemLayout}
            label={`Company Name`}
            extra={`Company Name`}
          >
            {getFieldDecorator(`offer${offer_number}.company_name`, {
              rules: [
                {
                  required: requireOfferFields || offer_number == 1,
                  message: `Please provide the name of company`,
                },
              ],
            })(<Input style={{ width: 360 }} />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={`Position Name`}
            extra="Include NUWorks Job ID if applicable"
          >
            {getFieldDecorator(`offer${offer_number}.position_name`, {
              rules: [
                {
                  required: requireOfferFields || offer_number == 1,
                  message: "Please provide the position name",
                },
              ],
            })(<Input style={{ width: 360 }} />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={`Name of Employer Contact`}
            extra="Name of Employer Contact"
          >
            {getFieldDecorator(`offer${offer_number}.employer_contact`, {
              rules: [
                {
                  required: requireOfferFields || offer_number == 1,
                  message: "Please provide the name of the employer contact",
                },
              ],
            })(<Input style={{ width: 360 }} />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={`Email address of Employer Contact`}
            extra="Email address of Employer Contact"
          >
            {getFieldDecorator(`offer${offer_number}.employer_email`, {
              rules: [
                {
                  type: "email",
                  required: requireOfferFields || offer_number == 1,
                  message: "Please provide the email of the employer contact",
                },
              ],
            })(<Input style={{ width: 360 }} />)}
          </FormItem>
        </React.Fragment>
      );
    }

    render() {
      const { form } = this.props;
      const { getFieldDecorator, getFieldValue } = form;
      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true,
      };

      const current_and_future_semesters = this.semester_list()
        .filter((semester) => moment(semester.enddate).isAfter(moment()))
        .reverse();

      let displayAdditionalOfferFields =
        getFieldValue("offer1.company_name") &&
        getFieldValue("offer1.position_name") &&
        getFieldValue("offer1.employer_contact") &&
        getFieldValue("offer1.employer_email") &&
        !getFieldValue("description_of_situation");
      

      return (
        <>
          <p>
            If you wish to amend a previously-submitted application, please
            simply submit a new application. The Khoury Undergrad team will
            simply use the most recent one that you submit.
          </p>
          <div style={{ marginTop: "15px" }}>
            <Form>
              <FormItem
                {...formItemLayout}
                label="Semester of Co-Op"
                extra="Semester that this co-op will be occurring (not necessarily the current term):"
              >
                {getFieldDecorator("semester_of_coop", {
                  rules: [
                    { required: true, message: "Please enter a semester" },
                  ],
                })(
                  <Select style={{ width: 360 }} filterOption={this.filter}>
                    {current_and_future_semesters.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {this.print_semester(el.id)}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="Co-Op's completed"
                extra="How many co-op's have you successfully completed?"
              >
                {getFieldDecorator("coops_completed", {
                  rules: [{ required: true, message: "Please enter the amount of co-op's completed",}],
                  initialValue: 0,
                  onChange: (e) =>
                    e > 10
                      ? form.setFieldsValue({ coops_completed: null })
                      : null,
                })(<InputNumber min={0} max={10} step={1} />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="Co-Op Advisor"
                extra="Who is your co-op advisor?"
              >
                {getFieldDecorator("coop_advisor", {
                  rules: [
                    {
                      required: true,
                      message: "Please select your co-op advisor",
                    },
                  ],
                })(
                    <Select style={{ width: 360 }} filterOption={this.filter} showSearch>
                      {this.instructor_list().map((el) => (
                        <Option key={el.id} value={el.id}>
                          {this.print_full_instructor(el.id)}
                        </Option>
                      ))}
                    </Select>
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="Appeal Type"
                extra="What best describes the type of appeal you’re submitting?"
              >
                {getFieldDecorator("appeal_type", {
                  rules: [
                    { required: true, message: "Please select an appeal type" },
                  ],
                })(
                  <Select
                    style={{ width: 600, height: 30 }}
                    filterOption={this.filter}
                  >
                    {Object.keys(APPEALTYPE).map((el) => (
                      <Option key={el} value={el}>
                        <Tooltip title={APPEALTYPE[el]}>
                          {APPEALTYPE[el]}
                        </Tooltip>
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              {/* This conditional checks to see if a student's appeal type is related to backing out of an offer. 
                If so, it will ask for information about the initial and subsequent offer */}
              {form.getFieldValue("appeal_type") === "BTB" && (
                <FormItem
                  {...formItemLayout}
                  label="Work Authorization"
                  extra="Do you need a work authorization visa for your co-op placement?"
                >
                  {getFieldDecorator("visa_need_indicated", {
                        rules: [{ required: false }],
                  })(
                    <Checkbox></Checkbox>
                  )}
                </FormItem>
              )}
              {form.getFieldValue("appeal_type") ? (
                <>
                  {form.getFieldValue("appeal_type") === "RER" ||
                  form.getFieldValue("appeal_type") === "RRO" ? (
                    <>
                      <Divider orientation="left">
                        Please provide information on the initial company you
                        accepted an offer from:
                      </Divider>
                      {this.createOfferForm(1, true)}
                      {displayAdditionalOfferFields && (
                        <>
                          <Divider orientation="left">
                            If applicable, please provide information on your
                            preferred offer:
                          </Divider>
                          {this.createOfferForm(2, false)}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {/* If the student's appeal type was NOT related to backing out of an offer,
                      this form item will ask for information about their situation */}
                      <FormItem
                        {...formItemLayout}
                        label="Situation"
                        extra="What best describes your situation?"
                      >
                        {getFieldDecorator("description_of_situation", {
                          rules: [
                            {
                              required: true,
                              message:
                                "Please select a description of your situation",
                            },
                          ],
                        })(
                          <Select
                            style={{ width: 600, height: 30 }}
                            filterOption={this.filter}
                          >
                            {Object.keys(SITUATIONTYPE).map((el) => (
                              <Option key={el} value={el}>
                                <Tooltip title={SITUATIONTYPE[el]}>
                                  {SITUATIONTYPE[el]}
                                </Tooltip>
                              </Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                      {form.getFieldValue("description_of_situation") &&
                      form.getFieldValue("description_of_situation") !==
                        "NRO" ? (
                        <>
                          {/* If the situation selected indicated that the student has secured an offer, 
                            they will be prompted to fill out information about the offer and company */}
                          <Divider orientation="left">
                            Please provide information about the company you
                            secured an offer from:
                          </Divider>
                          {this.createOfferForm(1, true)}
                        </>
                      ) : null}
                    </>
                  )}
                </>
              ) : null}
              <FormItem
                {...formItemLayout}
                label="Date decision needed by"
                extra="Note that it is not guarenteed we can respond by this date. Most students can expect to hear their appeal decision within 1-2 weeks of an appeal being submitted."
              >
                {getFieldDecorator("decision_deadline", {
                  rules: [{ required: true, message: "Please provide a date" }],
                })(<DatePicker format={"YYYY-MM-DD"} />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="Rationale"
                extra="Please provide your detailed rationale for submitting this appeal. Please be as comprehensive as possible and include any information that will aid the Appeal Committee in making a decision. For guidance on how to answer this question, review Question #2 of the Khoury Co-op Appeals - Student Guidelines PDF document sent to you by your Co-op Advisor."
              >
                {getFieldDecorator("rationale", {
                  rules: [
                    {
                      required: true,
                      message:
                        "Please provide your detailed rationale for submitting this appeal",
                    },
                  ],
                })(<TextArea style={{ width: 500 }} rows={5} />)}
              </FormItem>
              <FileUpload
                label="Supporting Documents"
                type="Coop Appeal"
                description="If it would be helpful to upload your academic plan, or other supporting documents, you can upload them here. Please combine multiple files into one PDF."
                onFileUpdate={this.handleFileUpdate}
                uploadedList={this.state.uploadedList}
                form={form}
                required={false}
              />
              <FormItem {...this.formItemLayout} label=" " colon={false}>
                <Button type="primary" onClick={this.handleSubmit}>
                  Submit
                </Button>
              </FormItem>
            </Form>
          </div>
        </>
      );
    }
  }
);


class UGCoopAppeal extends AppComponent {
  render() {
    const { record } = this.props;
    const item = record.record;
    return (
      <>
      <Descriptions bordered title={"UG Co-op Appeal by " + record.created_by}>
        <Descriptions.Item label="Submitted" span={2}>{moment(record.created_at).format("MMM DD, YYYY HH:mm")}</Descriptions.Item>
        <Descriptions.Item label="Requestor" span={2}>{[record.created_by + " ", <a key="email" href={"mailto:" + item.email}><Icon type="mail" theme="twoTone" /></a>]}</Descriptions.Item>
        <Descriptions.Item label="NUID" span={2}>{item.student.nuid}</Descriptions.Item>
        {(this.permission("can", "coop") || this.permission("can", "staff")) &&
          <Descriptions.Item label="" span={2}>{<Transcript {...this.props} nuid={item.student.nuid} />}</Descriptions.Item>}
          <Descriptions.Item label="Campus" span={2}>{this.print_campus(item.student.campus)}</Descriptions.Item>
          <Descriptions.Item label="Appeal Type" span={2}>{APPEALTYPE[item.appeal_type]}</Descriptions.Item>
          <Descriptions.Item label="Student indicated need for work authorization visa" span={2}>{item.visa_need_indicated ? get_check() : "Not indicated"}</Descriptions.Item>
          <Descriptions.Item label="Semester of Co-op" span={2}>{this.print_semester(item.semester_of_coop)}</Descriptions.Item>
          <Descriptions.Item label="Co-op Advisor" span={2}>{this.print_instructor(item.coop_advisor)}</Descriptions.Item>
          <Descriptions.Item label="Co-ops Completed" span={2}>{item.coops_completed}</Descriptions.Item>
          {item.description_of_situation ? 
            <Descriptions.Item label="Situation" span={4}>{SITUATIONTYPE[item.description_of_situation]}</Descriptions.Item> 
            : null}
          <Descriptions.Item label="Rationale" span={4}>{item.rationale}</Descriptions.Item>
          {record.files.length !== 0 &&
            <Descriptions.Item label="Attached Files" span={ 4 }>{[record.files.map(el => <a onClick={ () => this.openPDF(el.attachment, el.file_type +".pdf") }><Icon type="file-pdf" theme="twoTone" twoToneColor="#eb2f96" />{el.file_type +".pdf"}</a>) ]}</Descriptions.Item>
          }
          </Descriptions>
      {item.offers.length !== 0 &&
      <Descriptions bordered title={"Offers"} style={{paddingTop:25}}>
        {item.offers.map((offer) => (
              <React.Fragment key={offer.offer_number}>
                <Descriptions.Item 
                  label={
                    item.offers.length === 1
                      ? "Offer from:"
                      : offer.offer_number === 1
                      ? <b>Initial offer from:</b>
                      : <b>Preferred offer from:</b>
                  }
                  span={4}
                >
                  {offer.company_name}
                </Descriptions.Item>
                <Descriptions.Item label="Position Name" span={1}>{offer.position_name}</Descriptions.Item>
                <Descriptions.Item label="Employer Contact" span={2}>{offer.employer_contact + '  (' + offer.employer_email + ')' }</Descriptions.Item>
        
              </React.Fragment>
          ))}
      </Descriptions>
      }
      </>
    );
  }
}

class UGCoopAppealTable extends WorkflowTable {
  
  get_columns = () => {
    
    return [
      {
        title: "Semester of Co-op",
        key: "semester_of_coop",
        width: 100,
        render: (text, record, idx) => this.print_semester(record.record.semester_of_coop)
      },
      {
        title: "Appeal Type",
        key: "appeal_type",
        width: 120,
        render: (text, record, idx) => APPEALTYPE[record.record.appeal_type]
      }
    ];
  }
}

class UGCoopAppealOverview extends WorkflowSubmit {
  state = {
    endpoint: "/api/petition/ug/coopappeal/",
    loading: false,
    visible: true,
  }
  
  getData = () => {
    this.setState({ loading: true }, () => this.doGet(this.state.endpoint, data => { this.setState({ data: data, loading: false }) }));
  }

  get_name = () => {
    return "UG Co-op Appeal";
  }

  get_workflowtype = () => {
    return "ugcoopappeal";
  }

  get_name_plural = () => {
    return "UG Coop Appeals";
  }

  get_breadcrumbs = () => {
    return [{ link: "/student", text: "Students" }, { text: "UG" }, { text: "Coop Appeal" }];
  }

  get_form = (func) => {
    return <UGCoopAppealForm {...this.props} onSubmit={this.submit_form} />;
  }

  get_record_view = (record) => {
    return <UGCoopAppeal {...this.props} record={record} />
  }

  submit_form = (data, func) => {
    this.doPost(this.state.endpoint, () => { this.getData(); func ? func() : null; }, JSON.stringify(data));
  }

  get_overview_text = () => {
    return (
      <p>
        To begin this process, complete this Co-op Appeal Form with as much detail as possible. Upon receiving your appeal, an Appeal Decision Committee member will recommend an appropriate course of action, which is final and binding for all parties. The Appeal Decision Committee usually consists of the Director of Undergraduate Experiential Learning, the Sr. Associate Dean of Undergraduate Education & Experience, your Co-op Coordinator, and any other necessary stakeholders.
      </p>
    );
  }
}

export { UGCoopAppealOverview, UGCoopAppeal, UGCoopAppealTable };