import React, { Component } from "react";
import { Link } from "react-router-dom";
import Media from 'react-media';
import key from "weak-key";
import queryString from 'query-string'

import { Layout, Menu, Input, Spin, Alert, Icon, Popover, Button, Tooltip, Drawer, Modal, Divider, List, Badge, Radio, Select, Switch, Form, InputNumber, Popconfirm, message } from 'antd';

const { Header } = Layout;
const Option = Select.Option;
const FormItem = Form.Item;

import MenuSelect from "./MenuSelect";
import { Feedback } from "./Feedback";
import AppComponent from "./AppComponent";
import { UserEmailForm } from "./Login/UserEmailForm";
import { format_nuid, oxford, is_northeastern_email } from "./Utils";
// import { NotificationCenter } from "./Notifications";

const ResearchDetailsForm = Form.create({ name: 'Instruction Details Form' })(
  class extends AppComponent {

    render() {
      const { user, form, onCreate, onCancel, visible } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
      return (
        <Modal visible={visible} title="Update your Research Details" okText="Save" onCancel={onCancel} onOk={onCreate} width={500} closable={false}>
          {user.wordpress ? null : <Alert type="error" showIcon message={<p>We are unable to link your account with Wordpress, please <a href="mailto:khoury-admin@ccs.neu.edu?Subject=[Khoury Admin Portal] Missing Wordpress ID">contact the Khoury Admin Site administrators</a>.</p>} />}
          <br />
          <p>Please provide your Google Scholar and DBLP ID to allow us to link your data with the College's Main homepage. Once provided, your published research will automatically update on your profile and update as you publish new research.</p>
          <p><b>Note</b> We use DBLP and CSRankings as a data source and not all research will be available if not available there.</p>
          <Form>
            <FormItem {...this.formItemLayout} label="Google Scholar" extra="Please enter your Google Scholar ID, which is found in the URL">
              {getFieldDecorator('google_scholar', {
                rules: [{ required: false }],
                initialValue: user.google_scholar,
              })(<Input addonBefore={"/citations?user="} />)}
            </FormItem>
            <FormItem {...this.formItemLayout} label="DBLP" extra="Please enter your DBLP ID, which is found in the URL">
              {getFieldDecorator('dblp', {
                rules: [{ required: false }],
                initialValue: user.dblp,
              })(<Input addonBefore={"/pid/"} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

const NewLoadForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    render() {
      const { visible, onCancel, onCreate, form, new_loadcount } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal visible={visible} title="Edit New Load Configuration" okText="Save" cancelButtonProps={{ disabled: true }} onOk={onCreate} width={600} closable={false}>
          <Form onSubmit={this.handleSubmit}>
            <p>Please use the form below to edit the configuration of the logistic function used to implement the new load calculation.</p>

            <FormItem {...this.formItemLayout} label="min" extra="This is the minimum credit that any section would count towards the instructor's load.">
              {getFieldDecorator('min', {
                rules: [{ required: true, message: 'Please provide a minimum value.' }],
                initialValue: new_loadcount.min,
              })(<InputNumber min={0} step={0.01} />)}
            </FormItem>

            <FormItem {...this.formItemLayout} label="max" extra="This is the maximum credit that any section would count towards the instructor's load.">
              {getFieldDecorator('max', {
                rules: [{ required: true, message: 'Please provide a maximum value.' }],
                initialValue: new_loadcount.max,
              })(<InputNumber min={0} step={0.01} />)}
            </FormItem>

            <FormItem {...this.formItemLayout} label="x_0" extra="This is the size of class that represents value of symmetry, where the credit is halfway between the min and the max.">
              {getFieldDecorator('x_0', {
                rules: [{ required: true, message: 'Please provide a value.' }],
                initialValue: new_loadcount.x_0,
              })(<InputNumber min={0} step={1} />)}
            </FormItem>

            <FormItem {...this.formItemLayout} label="k" extra="This is a parameter that represents the 'steepness' of the logistic function.">
              {getFieldDecorator('k', {
                rules: [{ required: true, message: 'Please provide a value.' }],
                initialValue: new_loadcount.k,
              })(<InputNumber step={0.01} />)}
            </FormItem>

            <FormItem {...this.formItemLayout} label="Enrollment/Cap" extra="Choose whether the load value is based on the actual enrollment or the capacity.">
              {getFieldDecorator('enrollment_cap', {
                rules: [{ required: true, message: 'Please select one of the options.' }],
                initialValue: new_loadcount.enrollment_cap,
              })(<Radio.Group>
                <Radio value="enrollment">Enrollment</Radio>
                <Radio value="cap">Capacity</Radio>
              </Radio.Group>)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

const NewEmailForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    render() {
      const { visible, onCancel, onCreate } = this.props;
      return (
        <React.Fragment>
          <Modal
            title={"Add email address"}
            okText={"Close"}
            visible={visible}
            onCancel={onCancel}
            onCreate={onCreate}
            width={500}
            footer={null}
          >
            <UserEmailForm {...this.props} type="" onCreate={onCreate} />
          </Modal>
        </React.Fragment>
      );
    }
  }
);

class NavBar extends AppComponent {
  state = {
    drawer_visible: false,

    impersonate_visible: false,
    feedback_visible: false,
    impersonate_user: null,
    newload_visible: false,
    newemail_visible: false,
    researchdetails_visible: false,

    endpoint_users: "/api/permissions/users/",
    users: [],
    loading_users: true,

    endpoint_email: "/api/user/email/",
    endpoint_instructor: "/api/instructor/",
    endpoint_sync: "/api/user/sync/",
  }

  get_semester_select = () => {
    return this.props.semester ? (
      <MenuSelect style={{ float: 'right' }}
        width={140}
        key={"semester_select" + this.props.semester}
        onSet={this.props.onSetSemester}
        selected={this.props.semester}
        data={this.semester_list_grouped()} />
    ) : (<i>Loading semesters...</i>);
  }

  get_campus_select = () => {
    return this.props.campus ? (
      <MenuSelect style={{ float: 'right' }}
        width={120}
        key="campus_select"
        onSet={this.props.onSetCampus}
        selected={this.props.campus}
        data={this.campus_list().filter(el => el.popular && (this.permission("can", "admin") || this.props.user.campuses.includes(el.id)))} />
    ) : (<i>Loading...</i>);
  }

  loadUsers = (v) => {
    const { user } = this.props;
    const { endpoint_users, users } = this.state;
    this.setState({ loading_users: true, users: [] }, () => this.doGet(endpoint_users + "?search=" + (v ? v.replace(",", "") : ""), data => this.setState({ users: data, loading_users: false })));
  }

  handleEnter = (e) => {
    const { start_impersonate } = this.props;
    const { impersonate_user } = this.state;

    if (e.key === 'Enter' && impersonate_user != null) {
      start_impersonate(impersonate_user);
    }
  }

  get_impersonate = () => {
    const { start_impersonate } = this.props;
    const { impersonate_visible, impersonate_user, loading_users, users } = this.state;

    return (
      <Modal visible={impersonate_visible}
        title="Impersonate another user"
        okText="Start impersonating"
        onCancel={() => this.setState({ impersonate_visible: false })}
        onOk={() => start_impersonate(impersonate_user)}
        okButtonProps={{ disabled: impersonate_user == null }}
        width={600}
        destroyOnClose={true}
      >
        <p>To begin impersonating another user, select a user from the list below.  Once you begin impersonating the user, a banner will appear at the top of the screen; click on that banner to stop impersonating them.</p>

        <div style={{ textAlign: "center", width: 552 }} onKeyDown={this.handleEnter}>
          <Select autoFocus={true} showSearch autocomplete="off" style={{ width: 400 }} filterOption={this.filter} onSearch={this.loadUsers} onChange={e => this.setState({ impersonate_user: e })} placeholder="Type here to search" notFoundContent={loading_users ? <Spin size="small" /> : null}>
            {users.filter(u => u.id != this.props.user.id).map(u => <Option key={u.id} value={u.id}>{u.firstname} {u.lastname} ({u.email})</Option>)}
          </Select>
        </div>
      </Modal>
    );
  }

  get_feedback = () => {
    const { feedback_visible } = this.state;
    //const { feedback_visible, impersonate_user, loading_users, users } = this.state;

    return (
      <Feedback {...this.props} />
    );
  }

  resend_email = (email) => {
    const { endpoint_email } = this.state;
    this.doPost(endpoint_email, () => this.props.onUpdateUser(() => message.success(this.props.user.emails.find(e => e.email == email).verified ? "Email address verified" : "Verification email re-sent")), JSON.stringify({ email: email }));
  }

  delete_email = (id) => {
    const { endpoint_email } = this.state;
    this.doDelete(endpoint_email + id + "/", () => this.props.onUpdateUser());
  }

  do_sync = () => {
    const { endpoint_sync } = this.state;
    this.doGet(endpoint_sync, () => this.props.onUpdateUser(() => message.success("User information synced. Site will reload momentarily.", () => this.props.refresh())));
  }

  update_research_links = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) { return; }
      this.doPatch("/api/user/", () => this.props.onUpdateUser(() => message.success("Research details updated")), JSON.stringify(values));
    });
  }

  make_primary = (id) => {
    const { endpoint_email } = this.state;
    this.doPatch(endpoint_email + id + "/", () => this.props.onUpdateUser(() => message.success("Primary email changed")), JSON.stringify({ primary: true }));
  }

  swap_loadcount = () => {
    const v = this.props.new_loadcount;
    v.use = !v.use;
    this.props.set_new_loadcount(v);
  }

  handleUpdate = () => {
    const form = this.formRef.props.form;

    form.validateFields((err, values) => {
      if (err) { return; }

      const v = this.props.new_loadcount;
      Object.keys(values).forEach(k => v[k] = values[k]);
      this.setState({ newload_visible: false }, () => this.props.set_new_loadcount(v));
    });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  render() {
    const { match, group, logged_out, admin_site, enable_2fa, disable_2fa, stop_impersonate, user, workflows } = this.props;
    const { newload_visible, newemail_visible, system_alerts, researchdetails_visible } = this.state;
    const selected = (window.location.pathname.split("/")?.length > 0 ? [window.location.pathname.split("/")[1]] : []);
    const alerts = [];

    const offset = () => (alerts.length * 32) + "px";
    const add_alert = (level, message, func) => alerts.push(<span onClick={func} key={message}><Alert className="no-print" style={{ position: 'fixed', top: offset(), width: '100%', zIndex: 1000 }} type={level} message={message} banner /></span>);

    if (this.is_beta()) { add_alert("warning", "This is the development site"); }
    if (this.is_local()) { add_alert("warning", "This is a local site"); }
    if (user.impersonator) {
      add_alert("info", "You are impersonating " +
        (user.person.firstname_preferred ? user.person.firstname_preferred : user.person.firstname) +
        " " + user.person.lastname + ".  Click here to stop.", stop_impersonate);
    }

    this.props.alerts.forEach(a => add_alert("warning", a.message));

    const impersonate_disabled = user.impersonator ? "You are already impersonating someone" : !user.has_2fa ? "You must set up 2FA to impersonate others" : null;
    const admin_disabled = user.impersonator ? "Stop impersonating to use the Django admin site" : !user.has_2fa ? "You must set up 2FA to use the Django admin site" : null;

    const admin_button = (<Button type="default" onClick={admin_site} disabled={impersonate_disabled != null}><Icon type="login" /> Open Admin Site</Button>);
    const impersonate_button = (<Button type="default" onClick={() => this.setState({ impersonate_visible: true }, this.loadUsers)} disabled={admin_disabled != null}><Icon type="usergroup-add" /> Impersonate...</Button>);

    const sync_button = (<Button type="default" onClick={this.do_sync}><Icon type="reload" /> Sync Data</Button>);

    const load_button = (<React.Fragment><Switch checked={this.props.new_loadcount.use} onChange={this.swap_loadcount} /> <Button size="small" type="dashed" onClick={() => this.setState({ newload_visible: true })}><Icon type="calculator" /> Configure</Button></React.Fragment>);

    const impersonate = this.get_impersonate();
    const feedback = this.get_feedback();

    const VERSION = "1.7.10";
    const VERSION_DATE = "October 8th, 2024, 2:24:21 pm";

    const usermenu = (
      <div>
        <p>
          <b>Name:</b> {user.person ? [user.person.firstname_preferred ? [user.person.firstname_preferred, " (", user.person.firstname, ")"] : user.person.firstname, " ", user.person.lastname] : <i>None</i>}<br />
          {/* { user.ldapuser?.length > 0 ? user.ldapuser.map(l => (<div><b>LDAP Username:</b> {[l.username, " (", l.firstname, " ", l.lastname, ")"]}<br/></div>)) : <div><b>LDAP Username:</b> <i>No LDAP user found</i></div> } */}
          {user.ssouser?.length > 0 ? user.ssouser.map(s => (<div key={s.user_principal_name}><b>SSO UPN:</b> {[s.user_principal_name, " (", s.firstname, " ", s.lastname, ")"]}<br /></div>)) : <div><b>SSO UPN:</b> <i>No SSO user found</i></div>}
          <b>NUID:</b> {format_nuid(user.nuid)}<br />
          <b>Groups:</b> {oxford(user.groups.map(g => g.name))}<br />
          <b>Campus:</b> {oxford(user.campuses.map(this.print_campus))}{" "}{user.instructor ? user.campuses.includes(this.get_instructor(user.instructor)?.campus) ? null : <span class={"error"}><Tooltip title={"Your instructor campus is not part of your user campuses. Please contact the portal Administrators."}><Icon type="warning" /></Tooltip></span> : null}<br />
        </p>
        <p>
        </p>
        <p><b>Linked records:</b><br />
          Instructor: {user.instructor ? <Icon type="check-circle" /> : <Icon type="close-circle" />}<br />
          Student: {user.student ? <Icon type="check-circle" /> : <Icon type="close-circle" />}<br />
          PhD Student: {user.phd_student ? <Icon type="check-circle" /> : <Icon type="close-circle" />}<br />
        </p>
        <>
          {(user.instructor || user.phd_student) && (
            <><p><b>Research Details:</b><br />
              Area(s): {user.instructor ? oxford(this.get_instructor(user.instructor).areas.map(a => this.get_area(a).name)) : null}<br />
              Google Scholar: {user.google_scholar ? <Icon type="check-circle" /> : <Icon type="close-circle" />}<br />
              DBLP: {user.dblp ? <Icon type="check-circle" /> : <Icon type="close-circle" />}<br />
            </p><p><Button type="dashed" size="small" onClick={() => this.setState({ researchdetails_visible: true })}><Icon type="plus-square" />Update</Button><br />
              </p></>)}
        </>
        <p>
          <b>Email address(es):</b>
          {this.props.user.emails.map(e => (
            <span key={e.email}><br />{e.email} {e.primary ? <Tooltip placement="left" title="This is your primary Northeastern email address"><Icon type="mail" /></Tooltip> : <Tooltip placement="left" title="Delete this email address"><Popconfirm icon={<Icon type="question-circle-o" />} placement="left" title={"Are you sure you wish to delete " + e.email + "?"} onConfirm={() => this.delete_email(e.id)} okText="Yes" cancelText="No"><Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" style={{ float: 'right' }} className="icon-padding" /></Popconfirm></Tooltip>} {e.verified ? <Tooltip placement="left" title="Email address has been verified"><Icon type="check-circle" /></Tooltip> : <Tooltip placement="left" title="Resend verification email"><Popconfirm icon={<Icon type="question-circle-o" />} placement="left" title={"Are you sure you wish to resend the verification email to " + e.email + "?"} onConfirm={() => this.resend_email(e.email)} okText="Yes" cancelText="No"><Icon type="hourglass" theme="twoTone" style={{ float: 'right' }} className="icon-padding" /></Popconfirm></Tooltip>} {e.verified && !e.primary && is_northeastern_email(e.email) ? <Tooltip placement="left" title="Make this your primary Northeastern email address"><Popconfirm icon={<Icon type="question-circle-o" />} placement="left" title={"Are you sure you wish to make " + e.email + " your primary email?"} onConfirm={() => this.make_primary(e.id)} okText="Yes" cancelText="No"><Icon type="mail" theme="twoTone" style={{ float: 'right' }} className="icon-padding" /></Popconfirm></Tooltip> : null} </span>
          ))}
        </p>
        <p>
          <Button type="dashed" size="small" onClick={() => this.setState({ newemail_visible: true })}><Icon type="plus-square" /> Add Email Address</Button>
        </p>
        <p><b>Build:</b> {VERSION}<br />
          <b>Date:</b> {VERSION_DATE}<br /></p>
        {this.permission("can", "admin") ? <p><b>New Load:</b> {load_button}<br /></p> : null}
        {user.is_staff && (
          <>
            {user.has_2fa ? <p><Button type="default" onClick={disable_2fa}><Icon type="unlock" /> Disable 2FA</Button></p> : <p><Button type="default" onClick={enable_2fa}><Icon type="lock" /> Set up 2FA</Button></p>}
            <p>{admin_disabled ? <Tooltip placement="left" title={admin_disabled}>{admin_button}</Tooltip> : admin_button}</p>
          </>
        )}
        {this.permission("can", "admin") ? <p>{impersonate_disabled ? <Tooltip placement="left" title={impersonate_disabled}>{impersonate_button}</Tooltip> : impersonate_button}</p> : null}
        <p><Tooltip placement="left" title="Click here to re-sync your data with Banner and/or Workday; this is useful if your name or title has changed and you wish to import them into the Khoury Admin system">{sync_button}</Tooltip></p>
        <p>{feedback}</p>
        <Button type="primary" onClick={() => logged_out(true)}><Icon type="logout" /> Log out</Button>
      </div>
    );

    // const notification = (<NotificationCenter {...this.props} />);

    const menu = [
      { key: "employee", permission: this.permission("can", "employee"), right: false, content: (<Badge count={workflows ? workflows.length : 0} offset={[5, 0]} dot={true}>Employee</Badge>), link: this.getLink("/employee") },
      { key: "faculty", permission: this.permission("can", "faculty"), right: false, content: "Faculty", link: this.getLink("/faculty") },
      { key: "staff", permission: this.permission("can", "staff"), right: false, content: "Staff", link: this.getLink("/staff") },
      { key: "students", permission: this.permission("can", "student"), right: false, content: "Students", link: this.getLink("/students") },
      { key: "teaching", permission: true, right: false, content: "Teaching", link: this.getLink("/teaching") },
      { key: "site-admin", permission: this.permission("can", "admin"), right: false, content: "Admin", link: this.getLink("/site-admin") },
      { key: "userPopover", permission: true, right: true, content: (<Popover placement="bottomRight" content={usermenu} trigger="click" arrowPointAtCenter><Icon type="user" style={{ fontSize: '16px' }} /></Popover>) },
      // { key: "notificationIcon", permission: true, right: true, content: notification },
      { key: "semesterDropdown", permission: true, right: true, title: "Semester", content: this.get_semester_select() },
      { key: "campusDropdown", permission: this.permission("can", "admin") || user.campuses.length > 1, right: true, title: "Campus", content: this.get_campus_select(user.campuses) },
    ]

    return (
      <React.Fragment>
        {impersonate}
        {alerts}
        <Header className="header no-print" style={{ position: 'fixed', zIndex: 1000, width: '100%', top: offset() }}>
          <Media query="(max-width: 999px)">
            {matches =>
              matches ? (
                <Menu theme="dark" mode="horizontal" style={{ lineHeight: '64px', borderLeft: 5 }} selectedKeys={selected ? selected : "home"}>
                  <Menu.Item key="home"><Link to={this.getLink("/")}><b>Khoury Admin</b></Link></Menu.Item>
                  <Menu.Item key="menu-unfold" style={{ float: "right", paddingRight: 0 }}>
                    <Button type="primary" onClick={() => this.setState({ drawer_visible: true })}><Icon type="menu-unfold" style={{ marginRight: 0 }} /></Button>
                    <Drawer title="Menu" placement="right" closable={false} onClose={() => this.setState({ drawer_visible: false })} visible={this.state.drawer_visible}>
                      <List itemLayout="horizontal" dataSource={menu.filter(el => el.permission)} renderItem={el => el.link ? (<Link to={el.link}><List.Item><List.Item.Meta title={el.content} /></List.Item></Link>) : (<List.Item><List.Item.Meta title={el.title ? el.title : el.content} description={el.title ? el.content : null} /></List.Item>)} />
                    </Drawer>
                  </Menu.Item>
                  {/* <Menu.Item key="menu-notification" style={{ float: "right", paddingRight: "5px" }}>
                    {notification}
                  </Menu.Item> */}
                </Menu>
              ) : (
                <Menu theme="dark" mode="horizontal" style={{ lineHeight: '64px', borderLeft: 5 }} selectedKeys={selected ? selected : "home"}>
                  <Menu.Item key="home"><Link to={this.getLink("/")}><b>Khoury Admin</b></Link></Menu.Item>
                  {menu.map((el) => el.permission &&
                    <Menu.Item key={el.key}
                      style={el.right ? { float: "right", paddingLeft: "15px", paddingRight: "15px" } : { paddingLeft: "15px", paddingRight: "15px" }} >
                      {el.link ? <Link to={el.link}>{el.content}</Link> : el.content}</Menu.Item>)}
                </Menu>
              )
            }
          </Media>
        </Header>
        <NewLoadForm {...this.props} wrappedComponentRef={this.saveFormRef} visible={newload_visible} onCancel={() => this.setState({ newload_visible: false })} onCreate={this.handleUpdate} />
        <NewEmailForm {...this.props} wrappedComponentRef={this.saveFormRef} visible={newemail_visible} onCancel={() => this.setState({ newemail_visible: false })} onCreate={() => this.setState({ newemail_visible: false }, () => this.props.onUpdateUser(() => message.success("Verification email sent")))} />
        {(user.instructor || user.phd_student) && (<ResearchDetailsForm {...this.props} user={user} wrappedComponentRef={this.saveFormRef} visible={researchdetails_visible} onCancel={() => this.setState({ researchdetails_visible: false })} onCreate={() => this.setState({ researchdetails_visible: false }, () => this.update_research_links())} />)}
      </React.Fragment>
    );
  }
}

export default NavBar;
