import React, { Component } from "react";
import key from "weak-key";
import NumberFormat from 'react-number-format';
import AppComponent from '../AppComponent';
import LoadTable from './LoadTable';
import Content from '../Content';
import { add_dividers, add_brs, format_percent, format_decimal, format_nuid, get_check_nocheck, if_empty, print_load_component, get_semester_load_data } from '../Utils';

import { Table, Form, Switch, Card, List, Layout, Tooltip, Button, Icon, Menu, Dropdown, Spin, Calendar, Divider, Col, Statistic, Row, Badge, Breadcrumb, PageHeader, message } from 'antd';

class LoadCard extends AppComponent {
  state = {
      endpoint_schedule: "/api/schedule/",
      schedule: [],
      loading_schedule: true,

      endpoint_coordinator: "/api/schedule/coordinator/",
      coordinators: [],
      loading_coordinators: true,

      endpoint_load: "/api/load/",
      load: [],
      loading_load: true,
  }

  componentDidMount() {
    if (this.props.show) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
     if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
       this.setState({ loading: true });
       this.getData();
     }
   }

  getData = () => {
    if (this.props.user.instructor) {
      this.doGet(this.state.endpoint_schedule + "?deleted=False&semester__year=" + this.get_year() + "&instructors__employee__faculty=" + this.props.user.instructor,
                      data => this.setState({ schedule: data, loading_schedule: false }));
      this.doGet(this.state.endpoint_load + "?year=" + this.get_year() + "&instructor=" + this.props.user.instructor,
                      data => { this.setState({ load: data && data.length==1 ? data[0] : null, loading_load: false }) });
      this.doGet(this.state.endpoint_coordinator + "?semester__year=" + this.get_year() + "&instructor=" + this.props.user.instructor,
                      data => this.setState({ coordinators: data, loading_coordinators: false }));
    } else {
      message.error("You do not appear to be an instructor, so we are unable to fetch your teaching load.")
    }
  }

  render() {
    const { loading_schedule, loading_load, schedule, load, coordinators, loading_coordinators } = this.state;

    if (! this.props.show) { return null; }

    const total = load && (load.load + load.overload - load.reduction - load.buyout);

    var data = [{ title: "Base load", show_sign: false, load: load && load.load },
                { title: "Overloads", show_sign: true, load: load && load.overload > 0 && load.overload, pending: load && load.overload_pending > 0 && load.overload_pending },
                { title: "Reductions", show_sign: true, load: load && load.reduction > 0 && -1 * load.reduction, pending: load && load.reduction_pending > 0 && -1 * load.reduction_pending },
                { title: "Buyouts", show_sign: true, load: load && load.buyout > 0 && -1 * load.buyout, pending: load && load.buyout_pending > 0 && -1 * load.buyout_pending }].filter(el => el.load != false || el.pending != false);

    const courses = schedule.concat(coordinators).reduce((r, a) => { (r[a.semester] = r[a.semester] || []).push(a); return r; }, {});
    const keys = Object.keys(courses);
    keys.sort();
    const semesters = keys.map(el => { return { title: this.print_semester(el), show_sign: true, load: -1 * courses[el].reduce((r, a) => r + (a.crn ? this.get_instructor_section_loadcount(a, a.crosslist ? courses[el].find(s => s.id == a.crosslist) : null, this.props.user.instructor) : a.loadcount), 0) } });
    const taught = schedule.concat(coordinators).reduce((r, a) => r + (a.crn ? this.get_instructor_section_loadcount(a, a.crosslist ? schedule.find(s => s.id == a.crosslist) : null, this.props.user.instructor) : a.loadcount), 0);

    var data = data.concat(semesters);
    data = data.concat({ title: "Remaining", show_sign: false, load: total - taught });

    const columns_load = [
      {
        title: 'Category',
        key: 'category',
        dataIndex: 'title',
      }, {
        title: 'Load',
        key: 'load',
        align: 'right',
        width: 150,
        render: (text, record, idx) => <span key={`load-${idx}`}>{ [print_load_component(record.load, record.show_sign, false), " ", record.pending ? ["[", print_load_component(record.pending, record.show_sign, false), " pending]"] : ""] }</span>,
      }
    ];

    return (
      <Card size="small" title={ "Your teaching load summary for " + this.print_year(this.get_year()) }>
        { loading_schedule || loading_load || loading_coordinators ? ( <Spin tip="Loading data" /> ) : (
          <Table {...this.props} dataSource={ data } columns={ columns_load } bordered={ false } pagination={ false } size="small" rowKey={(record, index) => `${record.title}-${index}`} />
        ) }
      </Card> );
  }
}


class LoadList extends AppComponent {
  state = {
      endpoint: "/api/schedule/",
      sections: [],
      loading: true,

      endpoint_planning: "/api/planning/",
      planning: [],
      loading_planning: true,

      endpoint_coordinator: "/api/schedule/coordinator/",
      coordinators: [],
      loading_coordinators: true,
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
     if ((prevProps.campus !== this.props.campus) || (prevProps.semester !== this.props.semester)) {
       this.setState({ loading: true, loading_planning: true, loading_coordinators: true });
       this.getData();
     }
   }
  
  getData = () => {  
    this.doGet(this.state.endpoint + "?semester__year=" + this.get_year(),
                  data => this.setState({ sections: data, loading: false }));

    this.doGet(this.state.endpoint_planning + "?semester__year=" + this.get_year(),
                  data => this.setState({ planning: data, loading_planning: false }));

    this.doGet(this.state.endpoint_coordinator + "?semester__year=" + this.get_year(),
                  data => this.setState({ coordinators: data, loading_coordinators: false }));
  }


  render() {
    const { semester, semesters } = this.props;
    const { loading, loading_coordinators, loading_planning, sections, coordinators, planning } = this.state;

    const year = this.get_semester(semesters[0]).year;
    const sem_list = this.semester_list().filter(s => s.year == year);
    
    var data = sections;
    
    sem_list.forEach(sem => {
      if (! data.find(s => s.semester == sem.id)) {
        var ids = [];
        const list = planning.filter(s => s.semester == sem.id).filter(s => { if (ids.includes(s.id)) { return false; } else { ids.push(s.id); return true; } });
        data = data.concat(list);
      }
    });

    return (
      <Content {...this.props} title={ this.print_year(this.get_year()) + " Load Breakdown" } breadcrumbs={ [{ link: "/faculty", text: "Faculty" }, { text: "Loads" }] }>
        <p>Below is a breakdown of the load for all instructors during the { this.print_year(this.get_year()) } academic year.</p>

        <Divider orientation="left">Tenured/Tenure-Track (Khoury only)</Divider>
        <LoadTable { ...this.props } sections={ data } onRefresh={ this.getData } coordinators={ coordinators } loading={ loading || loading_coordinators || loading_planning } hide_fields= { ["year"] } show_years={ [ this.get_year() ] } show_total={ true } filter={ appointment => appointment && appointment.rank && this.get_instructorrank(appointment.rank).subtype.mytype.mytype == "Tenured/Tenure-Track" && appointment.tenure_home && this.get_college(appointment.tenure_home).code == "CS" && appointment.appointment.length == 1 }/>

        <Divider orientation="left">Tenured/Tenure-Track (joint, Khoury tenure home)</Divider>
        <LoadTable { ...this.props } sections={ data } onRefresh={ this.getData } coordinators={ coordinators } loading={ loading || loading_coordinators || loading_planning } hide_fields= { ["year"] } show_years={ [ this.get_year() ] } show_total={ true } filter={ appointment => appointment && appointment.rank && this.get_instructorrank(appointment.rank).subtype.mytype.mytype == "Tenured/Tenure-Track" && appointment.tenure_home && this.get_college(appointment.tenure_home).code == "CS" && appointment.appointment.length > 1 }/>

        <Divider orientation="left">Tenured/Tenure-Track (joint, non-Khoury tenure home)</Divider>
        <LoadTable { ...this.props } sections={ data } onRefresh={ this.getData } coordinators={ coordinators } loading={ loading || loading_coordinators || loading_planning } hide_fields= { ["year"] } show_years={ [ this.get_year() ] } show_total={ true } filter={ appointment => appointment && appointment.rank && this.get_instructorrank(appointment.rank).subtype.mytype.mytype == "Tenured/Tenure-Track" && appointment.tenure_home && this.get_college(appointment.tenure_home).code != "CS" }/>

        <Divider orientation="left">Teaching Faculty (Khoury only)</Divider>
        <LoadTable { ...this.props } sections={ data } onRefresh={ this.getData } coordinators={ coordinators } loading={ loading || loading_coordinators || loading_planning } hide_fields= { ["year"] } show_years={ [ this.get_year() ] } show_total={ true } filter={ appointment => appointment && appointment.rank && this.get_instructorrank(appointment.rank).subtype.subtype == "Teaching" && appointment.appointment.length == 1  }/>

        <Divider orientation="left">Teaching Faculty (joint)</Divider>
        <LoadTable { ...this.props } sections={ data } onRefresh={ this.getData } coordinators={ coordinators } loading={ loading || loading_coordinators || loading_planning } hide_fields= { ["year"] } show_years={ [ this.get_year() ] } show_total={ true } filter={ appointment => appointment && appointment.rank && this.get_instructorrank(appointment.rank).subtype.subtype == "Teaching" && appointment.appointment.length > 1  }/>
      </Content>
    );
  }
}

export default LoadList;
export { LoadCard };