import React, { Component } from "react";
import { Spin, Layout, Card, List, Table, Form, Tag, Switch, Button, Modal, Input, Alert, InputNumber, Radio, Select, Menu, Dropdown, Icon, Tooltip, Checkbox, Divider, Popconfirm, message, Steps} from 'antd';

import AppComponent from '../AppComponent';
import LoginLayout from './LoginLayout';
import { is_northeastern_email } from '../Utils';
const { Step } = Steps;
const { Header, Content } = Layout;

const FormItem = Form.Item;

const UserEmailForm = Form.create({ name: 'Required User Email' })(
  class extends AppComponent {

    state = {
      endpoint: '/api/user/email/',
      email: null,
      sent: false,
      code: null,
    }

    validateForm() {
      const { user, type } = this.props;
      const { email } = this.state;
      if (email) {
        if (type == "NU") {
          return is_northeastern_email(email);
        } else {
          return true;
        }
      }
    }
    
    handle_response = (response) => {
      if (response.error) {
        message.error("Email already in use.")
      } else {
        this.props.onUpdateUser(() => this.setState({ sent: true }, this.props.onCreate));
      }
    }    

    send_email = () => {
      const { getUser } = this.props;
      const { email, endpoint } = this.state;
      this.doPost(endpoint, this.handle_response, JSON.stringify({ "email": email }));
    };

    render () {
      const { form, type, user } = this.props;
      const { getFieldDecorator } = form;
      const { email } = this.state;
      const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

      const email_input_type = type == "NU" ? "Northeastern" : "";

      return (
        <div>
          { (type == "NU") && <p>In order to finish setting up your account, we need to know your Northeastern email address.  Please provide it below (it must end in <tt>neu.edu</tt> or <tt>northeastern.edu</tt> to be accepted.  If you wish to have a previous code re-sent, simply enter the same email address again.</p> }
          <p>Please enter your {email_input_type} email address below and hit "Verify".  After you do so, a verification email will be sent to the address you provided; please click on the link in that verification email in order to confirm your email address.</p>
          <Form> 
              <FormItem {...formItemLayout} className="login-left" label="Email address" extra={ "Please enter an email address" }>
                {getFieldDecorator('email', {
                  rules: [{type: 'email', message: 'Not a valid email address'}, { required: true, message: "Please enter your email"}],
                  initialValue: email ? email : "",
                  onChange: event => this.setState({ email: event.target.value }),
                  onPressEnter: e => this.validateForm() && this.send_email(),
                })(<Input style={{ width: 280 }} />)}
              </FormItem>
            <FormItem {...formItemLayout} label=" " colon={false}>
              <div className="login-left"><Button type="primary" disabled={ this.validateForm() != true } onClick={ this.send_email }>Verify</Button></div>
            </FormItem>
          </Form>
        </div>
      );
    }
  }
);

class UserEmailLogin extends AppComponent {
  state = {
    sent: false
  }
  
  render() {
    const { sent } = this.state;
    
    return (
      <LoginLayout {...this.props}>
        { !sent ? (
          <Card title="Enter Northeastern email address" style={{ width: "40" }}>
            <UserEmailForm {...this.props} type="NU" onCreate={ () => this.setState({ sent: true }) } />
          </Card>
        ) : (
          <Card title="Verification sent" style={{ width: "40" }}>
            <p>A verfication email has been sent to the address that you provided.  Please click on the link that is provided in that email and your account will be fully activated.  If you have any issues receiving the email or logging in afterwards, please <a href="mailto:khoury-admin@ccs.neu.edu">contact the Khoury Admin Site administrators</a>.</p>
          </Card>
        ) }
      </LoginLayout>
    );
  }
}

class UserEmailCodeEntered extends AppComponent {  
  state = {
    result: null,
  }

  componentDidMount() {
    this.enter_code();
  }

  enter_code = () => {
    const { email_code, email_id } = this.props;
    this.doPatch("/api/user/email/" + email_id + "/", (r) => this.setState({ result: r["success"] }), JSON.stringify({ code: email_code }));
  }

  render() {
    const { onDone } = this.props;
    const { result } = this.state;
    
    return (
      <LoginLayout {...this.props}>
        { result == null ? (
          <Card title="Code received" style={{ width: "40" }}>
            <p>We have received the code you provided and are verifying it...</p>
            <Spin tip="Verifiying code" />
          </Card>
        ) : result == true ? (
          <Card title="Code accepted" style={{ width: "40" }}>
            <p>We have accepted the code you entered and your email address has been validated.  Please click on the button below to log into the site.</p>
            <div style={{ float: 'right' }}><Button type="primary" onClick={ onDone }>Continue</Button></div>
          </Card>
        ) : (
          <Card title="Code not accepted" style={{ width: "40" }}>
            <p>We were unable to verify the code you provided; this could be because it expired.   Please try entering and verifying the email address again; if this problem persists, please <a href="mailto:khoury-admin@ccs.neu.edu">contact the Khoury Admin Site administrators</a>.</p>
            <div style={{ float: 'right' }}><Button type="primary" onClick={ onDone }>Retry</Button></div>
          </Card>
        ) }
      </LoginLayout>
    );
  }
}

export { UserEmailLogin, UserEmailForm, UserEmailCodeEntered };
