import React, { Component } from "react";
import AppComponent from "../../../AppComponent";
import Preference from "../../../Preference";
import Content from "../../../Content";

import { add_dividers_horiz, renderStatus } from "../../../Utils";
import { Button, Table, Alert, Form, Radio, List, Input, InputNumber, Divider, Select, Spin } from "antd";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const PlusOneStudentForm = Form.create({ name: "form_not_in_modal" })(
  class extends AppComponent {
    state = {
      gpa: null,
      grad_history: "",
      major: null,
      notes: "",
      plusone_student: null,
      taken_grad: null,
      edited: false
    };

    componentDidMount() {
      this.update();
    }
    componentDidUpdate(prevProps) {
      if (prevProps.student !== this.props.student) {
        this.update();
      }
    }

    update = () => {
      const { student, transcript, semesters } = this.props;
      const grad_history_taken = transcript && transcript.courses ? transcript.courses.filter(el => ("course" in el) && (this.print_number_from_course(el.course) >= 5000)).map(el => this.print_course(el.course)): [];
      this.setState({
        gpa: student ? student.gpa : null,
        grad_history: grad_history_taken,
        major: student ? student.major : null,
        notes: student ? student.notes : "",
        plusone_student: student ? student.plusone_student : null,
        taken_grad: grad_history_taken?.length > 0 ? 1 : 0
      });
    };

    handleSubmit = e => {
      const { student } = this.props;
      const { edited } = this.state;
      e.preventDefault();
      edited || !student ? this.props.onCreateUpdate(e) : this.props.onPass(e);
    };

    render() {
      const { form, student, semesters, sections } = this.props;
      const { getFieldDecorator } = form;
      const {
        gpa,
        grad_history,
        major,
        notes,
        plusone_student,
        taken_grad,
        edited
      } = this.state;

      const formItemLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        colon: true
      };

      var semester_list = this.semester_list().filter(
        el =>
          el.code >= this.get_semester(semesters[0]).code &&
          (el.code.slice(-2) == "10" ||
            el.code.slice(-2) == "30" ||
            el.code.slice(-2) == "60")
      );
      semester_list.reverse();

      const section_list = sections.filter(
        el => el.ugrad_stats && this.get_course(el.course).number >= 5000
      );

      return (
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label="Major"
            extra="Please select your current degree program."
          >
            {getFieldDecorator("major", {
              rules: [{ required: true, message: "Please select a major." }],
              initialValue: major,
              onChange: event => this.setState({ major: event, edited: true })
            })(
              <Select
                showSearch
                style={{ width: 360 }}
                filterOption={this.filter}
              >
                {this.major_list().map(el => (
                  <Option key={el.id} value={el.id}>
                    {this.print_major(el.id)}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="GPA"
            extra="Please enter your current GPA."
          >
            {getFieldDecorator("gpa", {
              rules: [{ required: true, message: "Please enter your GPA." }],
              initialValue: gpa,
              onChange: event => this.setState({ gpa: event, edited: true })
            })(<InputNumber min={0} max={4} step={0.1} />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="PlusOne Student?"
            colon={false}
            extra="Please select whether you are currently a PlusOne student."
          >
            {getFieldDecorator("plusone_student", {
              rules: [
                {
                  required: true,
                  message:
                    "Please select whether you are currently a PlusOne student."
                }
              ],
              initialValue: plusone_student,
              onChange: event =>
                this.setState({
                  plusone_student: event.target.value,
                  edited: true
                })
            })(
              <RadioGroup>
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </RadioGroup>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Graduate Course Enrollment History"
          >
            {getFieldDecorator("grad_history", {
              initialValue: grad_history,
              onChange: event =>
                this.setState({ edited: true })
            })(
              <Select
                disabled={true}
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="None"
              >
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Notes"
            extra="Please provide any additional notes."
          >
            {getFieldDecorator("notes", {
              initialValue: notes,
              onChange: event =>
                this.setState({ notes: event.target.value, edited: true })
            })(<TextArea rows={4} />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 }
            }}
          >
            <Button type="primary" htmlType="submit">
              {student
                ? edited
                  ? "Update and Edit Preferences"
                  : "Edit Preferences"
                : "Submit"}
            </Button>
          </FormItem>
        </Form>
      );
    }
  }
);

class UGPlusOnePreferences extends AppComponent {
  state = {
    endpoint_prefs: "/api/preferences/plusone/",
    prefs: [],
    loading_prefs: true,

    endpoint_sections: "/api/schedule/",
    sections: [],
    loading_sections: true,

    endpoint_student: "/api/plusone/student/",
    student: [],
    loading_student: true,

    editable: true,
    confirmed: false,
    endpoint_viewable: "/api/petitionviewable/",
    visible: false,

    endpoint_transcript: "/api/student/transcript/",
    transcript: [],
    loading_transcript: true,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.campus !== this.props.campus ||
      prevProps.semester !== this.props.semester
    ) {
      this.setState({ loading_sections: true })
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_transcript + this.props.user.nuid + "/", data => this.setState({ transcript: data, loading_transcript: false }));
    this.doGet(this.state.endpoint_viewable +"?semester=" + this.props.semesters.join(","), data => {this.setState({ visible: ((data[0]||{}).petitions_visible_po||false) });});
    this.doGet(
      this.state.endpoint_sections +
        "?semester=" +
        this.props.semesters.join(","),
      data => this.setState({ sections: data, loading_sections: false })
    );
    this.doGet(
      this.state.endpoint_student +
        "?person=" +
        this.props.user.person.id,
      data =>
        this.setState({
          student: data && data?.length > 0 ? data[0] : null,
          loading_student: false
        })
    );
    this.getPrefs();
  };

  getPrefs = () => {
    this.doGet(
      this.state.endpoint_prefs +
        "?section__semester=" +
        this.props.semesters.join(",") +
        "&person=" +
        this.props.user.person.id,
      data => this.setState({ prefs: data, loading_prefs: false })
    );
  };

  render_pref = (record, loading, desc, pref) => {
    return (
      <List
        key={"list-" + record.id}
        grid={this.grid_preference}
        loading={loading}
        size="small"
        dataSource={[1]}
        renderItem={record => [
          <List.Item key={"item"}>{desc}</List.Item>,
          <List.Item key={"pref"} style={{ float: "right" }}>
            {pref}
          </List.Item>
        ]}
      />
    );
  };

  options = [
    { id: 4, text: "1st" },
    { id: 3, text: "2nd" },
    { id: 2, text: "3rd" },
    { id: 5, text: "No" }
  ];

  render_section_pref = (record, sem) => {
    const {
      loading_prefs,
      loading_sections,
      prefs,
      editable,
      sections,
      student,
      confirmed,
      loading_student
    } = this.state;

    const section = sections.find(el => el.id == record.id);
    const pref = prefs.find(el => el.section == record.id);
    const unselectable = Object.keys(
      prefs.reduce((r, a) => {
        if (a.preference > 1 && a.section != record.id) {
          r[a.preference] = 1;
        }
        return r;
      }, {})
    );
    return this.render_pref(
      record,
      loading_prefs || loading_sections,
      <List.Item.Meta
        title={[
          this.print_full_course(section.course),
          "\u00a0\u00a0",
          <span key="crn">(CRN {section.crn})</span>
        ]}
        description={[
          <span key="instructor">
            <b>Instructor:</b> {this.print_instructor_list(section.instructors)}
          </span>,
          "\u00a0\u00a0",
          <span key="time">
            <b>Time:</b> {this.print_meetingtime(section.meetingtime)}
          </span>
        ]}
      />,
      <Preference
        {...this.props}
        key={record.id + "-" + (pref ? pref.id : null)}
        section={record.id}
        editable={
          editable && !loading_student && !loading_prefs && student && confirmed
        }
        value={pref}
        defaultValue={1}
        unselectable={unselectable}
        options={this.options}
        onChange={this.getPrefs}
        endpoint="/api/preferences/plusone/"
        addNewData={f => (f["section"] = record.id)}
      />
    );
  };

  handleCreateUpdate = () => {
    const form = this.formRef.props.form;
    const { student } = this.state;
    form.validateFields((err, values) => {
      values.taken_grad = values.grad_history?.length > 0
      values.grad_history = values.grad_history ? values.grad_history.join("; ") : "";
      if (err) {
        return;
      }

      if (student) {
        this.doPatch(
          this.state.endpoint_student + student.id + "/",
          response => {
            form.resetFields();
            this.setState({ student: response, confirmed: true });
          },
          JSON.stringify(values)
        );
      } else {
        this.doPost(
          this.state.endpoint_student,
          response => {
            form.resetFields();
            this.setState({ student: response, confirmed: true });
          },
          JSON.stringify(values)
        );
      }
    });
  };

  handlePass = () => {
    this.setState({ confirmed: true });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { semester } = this.props;
    const { sections, student, confirmed, prefs, transcript, loading_transcript, loading_sections } = this.state;

    const columns = [
      {
        title: "Course",
        key: "desired_course",
        render: (text, record, idx) =>
        typeof sections.find(el => el.id == record.section) !== "undefined"
            ? this.print_full_course(
                sections.find(el => el.id == record.section).course
              )
            : ""
      },
      {
        title: "Status",
        key: "decision",
        render: (text, record, idx) =>
          record.staff_approval
            ? renderStatus("Accepted")
            : renderStatus("Submitted")
      }
    ];

    const section_list = sections.filter(
      el => el.ugrad_stats && this.get_course(el.course).number >= 5000
    );

    return (
      <Content
        {...this.props}
        title={ "UG Graduate Course (PlusOne) Petition for " + semester }
        breadcrumbs={[{ link: "/student", text: "Students" }, { text: "UG" }, { text: "PlusOne" }]}
      >
        <p>
          Welcome to the new Graduate Course (PlusOne) preferences form. This form is only for Undergraduate Students. 
          Note: We cannot guarantee access to any of these classes; this survey is only used to register
          your interest in the course in case we are able to make it available
          to PlusOne students. All of your entries will be automatically saved;
          if an error occurs, a popup will be shown.
        </p>

        {!student || !confirmed  ? (
          <React.Fragment>
            <Table
              columns={columns}
              loading= { loading_sections }
              dataSource={prefs.filter(record => record.preference > 1)}
            />
            {this.state.visible && (
              <React.Fragment>
                <Divider orientation="left">
                  PlusOne Student Information
                </Divider>
                <p>
                  Please fill out (or update) the form below, which will be used
                  to help the advising staff determine which course(s) are the
                  best fit for your degree program.
                </p>
                { loading_transcript ? <div align="center"><Spin title="Loading transcript" /></div> :
                <PlusOneStudentForm
                  {...this.props}
                  transcript={transcript}
                  student={student}
                  onCreateUpdate={this.handleCreateUpdate}
                  wrappedComponentRef={this.saveFormRef}
                  onPass={this.handlePass}
                  sections={sections}
                />
                }


                {!student ? (
                  <Alert
                    message="Fill out PlusOne Student Information"
                    description="You cannot enter preferences until you fill out the PlusOne student information form above."
                    type="warning"
                    showIcon
                  />
                ) : (
                  <Alert
                    message="Confirm your PlusOne Student Information"
                    description="You cannot enter preferences until you confirm the PlusOne student information above is correct."
                    type="warning"
                    showIcon
                  />
                )}
              </React.Fragment>
            )}
            {!this.state.visible && (
              <Divider orientation="center">
                {" "}
                Application Period for {this.props.semester} is Closed
              </Divider>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>
              Below is the list of PlusOne courses we hope to offer during{" "}
              {semester}; please indicate your preferences for enrolling in
              these sections below.
            </p>
            <span>
              <Button
                style={{ display: "flex", marginLeft: "auto" }}
                onClick={() => this.setState({ confirmed: false })}
              >
                Go back
              </Button>
            </span>
            {this.props.semesters.map(sem => (
              <React.Fragment key={sem}>
                <Divider orientation="left">{this.print_semester(sem)}</Divider>
                {add_dividers_horiz(
                  section_list
                    .filter(el => el.semester == sem)
                    .map(el => this.render_section_pref(el, sem))
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </Content>
    );
  }
}

export default UGPlusOnePreferences;
