import React from "react";
import AppComponent from "../../AppComponent";
import moment from "moment-timezone";


class GenericExport extends AppComponent {
    state = {
        data: null,
    }

    componentDidMount() {
        this.getWorkflowData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchValues !== this.props.searchValues) {
            this.getWorkflowData();
        }
    }

    getWorkflowData = () => {
        const { searchValues } = this.props;
        if (searchValues) {
            const params = new URLSearchParams("historic=true&no_page=true");
            Object.keys(searchValues).forEach(k => searchValues[k] ? params.set(k, searchValues[k]) : null);
            this.doGet("/api/workflow/?" + params.toString(), data => this.setState({ data: data }));
        }
    }

    is_current_active_step = (s) => {
        return (s.decision == "Assigned") || (s.decision == "Reviewed");
    }
    
    is_current_step = (s) => {
        return this.is_current_active_step(s) || (s.decision == "Refused") || (s.decision == "Cancelled");
    }

    get_current_active_step = (record) => {
        return record.steps.find(s => this.is_current_active_step(s));
    }

    get_current_step = (record) => {
        const step = record.steps.find(s => this.is_current_step(s));
        if (step) { return step; }
        return record.steps[record.steps.length-1];
    }

    renderSteps = (currentStep) => {
        return currentStep.decision + " Comment:" + currentStep.justification + " last changed at " + moment(currentStep.state_last_changed_at).format("MMM DD, YYYY HH:mm");
    }
}

export default GenericExport;
