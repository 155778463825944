import React, { Component } from "react";
import { BrowserRouter as Router, Switch as RouterSwitch, Route, Link, Redirect } from "react-router-dom";
import moment from "moment-timezone";
import QRCode from 'qrcode.react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import AppComponent from './AppComponent';
import LoggedInComponent from "./LoggedInComponent";
import Content from "./Content";
import NavBar from "./NavBar";
import * as Sentry from "@sentry/react";
import CustomTabs from "./CustomTabs";
import CustomRedirect from './CustomRedirect';

import EmployeeCreateForm from "./CreateEmployee";
import Committee, { CommitteeCard } from "./Committee";
import Position, { PositionCard } from "./Position";
import Course from "./Course";
import LoadList, { LoadCard } from "./Load/LoadList";
import PollList, { PollVote } from "./PollList";
import Room, { DeskAssignments } from "./Room";
import Section from "./Section";
import SectionGroup from "./SectionGroup";
import Schedule, { ScheduleCard } from "./Schedule";
import { ScheduleBreakdown } from "./PlanningSchedule";
import PlanningSchedule from "./PlanningSchedule";
import TeachingPreferences from "./TeachingPreferences";
import CommitteePreferences from "./CommitteePreferences";
import PreferenceBreakdown from "./Preferences/TeachingPreferences/PreferenceBreakdown"
import { ExportOfficeHours } from "./ExportDataToApps";
import { FinanceFundList, FundCard, FundDetails, FundList } from "./Fund";
import { Merit, MeritList, MeritStaffList } from "./Merit/TenureTrack/TTMerit";
import { SelfReview, CommitteeOverview, TeachingFacultyMeritReview, DeanMeritReview } from "./Merit/NonTenureTrack";
import { NetworkedDeviceListOverview } from "./NetworkedDevice"

///// Retired
//import ViewPermissions from "./ViewPermissions";
//import { NotificationAdmin } from "./Notifications";

///// Instructor 
import Instructor from "./Instructor";
import { MentorMenteeCard, SabbaticalCard, TenureTrackCard, InstructorRanksCard, TeachingScheduleCard, ServiceScheduleCard } from "./Instructor";

///// Login
import Login from "./Login/LoginSSO";
import DevLogin from "./Login/LoginDev.js"
import UserRequirementsForm from "./Login/UserRequirementsForm";
import { UserEmailLogin, UserEmailCodeEntered } from "./Login/UserEmailForm";
import { CannotImpersonate, NoGroups, NoInstructor, NoPerson, NotKhoury } from "./Login/Fail";

///// PETITIONS
//Generic
import { WorkflowProcess } from "./Petition/WorkflowProcess";
import { UGMSConferenceSupportOverview } from "./Petition/Student/UGMSConferenceSupport"

//UG
import { UGPlusOnePreferences, UGClosedCoursePetition, UGPrerequisiteOverrideOverview, UGScholarshipApplicationOverview, UGHomeCollegeChangeOverview, UGConcentrationDeclarationOverview, UGCoopAppealOverview } from "./Petition/Student/Undergrad";
//PhD
import { PhDPaperRequirementOverview, PhDCourseWaiverOverview, PhDElectivePetitionOverview, PhDThesisApprovalOverview } from "./Petition/Student/PhD";
//MS
import { MSThesisFormOverview, MSCourseCreditWaiverOverview, MSElectivePetitionOverview, MSCourseAuthorizationOverview, MSCourseSurvey } from "./Petition/Student/Graduate";
//Faculty
import { SummerSupportProposalOverview, PhDStudentFundingOverview, BuyoutRequestOverview, ParentalReductionRequestOverview, ResearchReductionRequestOverview, CourseOverloadOverview, TransactionMoveOverview, EquipmentRefreshOverview } from "./Petition/Faculty";

//Staff
import PlusOneResponses from "./Petition/Staff/PlusOneStaff";
import PetitionStaff from "./Petition/Staff/GenericPetitionStaff";
import { MSCourseSurveyOverview } from "./Petition/Staff/MSCourseSurvey";

///// PhD
import { PhDStudentList, PhDStudent, PhDStudentTeachingLoadList } from "./PhD/PhDStaff";
import { PhDFacultyCard, PhDAdvisorReview } from "./PhD/PhDFaculty";
import { PhDStudentSelfReview, PhDCard } from "./PhD/PhDStudent";

///// TA
import { TAApplication, TAAccept, TAHours, TAApplicationCard, TAAudit, IAAssignmentCard } from "./TA/TAStudent";
import { TAApplicationReview, TAHoursFaculty, TAReview } from "./TA/TAFaculty";
import { TAAssign, TACollegeOverview, TAAssignCourse, TAHire, TAIndividual, TAAuditReview, TAHoursStaffCard, TAHR } from "./TA/TAStaff";

///// Research Apprenticeship
import { ApprenticeNomination } from "./Research Apprenticeship/ApprenticeNomination";
import { ApprenticeStudentApplication } from "./Research Apprenticeship/ApprenticeApplication";
import { ResearchProjectTable } from "./Research Apprenticeship/ProjectTable";
import { FacultyApplicationReview } from "./Research Apprenticeship/FacultyAppReview";
import { FacultyProjectSubmission } from "./Research Apprenticeship/FacultyProjectSubmission.js"

///// Utils 
import { logout, add_brs, add_dividers, get_check, get_nocheck, get_nonecheck, get_pausecheck, format_nuid, get_check_nocheck, oxford, print_load_component, format_decimal, SSOAllowAccess } from "./Utils";
import { PageHeader, Badge, Layout, Menu, Breadcrumb, Icon, Table, Tag, Radio, Checkbox, Input, Tooltip, Spin, Divider, Form, Switch, message, DatePicker, Modal, List, Card, InputNumber, Alert, Tabs, Upload, Button, Select, Typography } from 'antd';

const FormItem = Form.Item;
const { TextArea } = Input;
const { SubMenu } = Menu;
const { Option } = Select;
const { Sider, Footer } = Layout;
const TabPane = Tabs.TabPane;
const { Text, Title } = Typography;

const PREFIX = "";

const VERSION = "1.7.10";
const VERSION_DATE = "October 8th, 2024, 2:24:21 pm";

import 'array-flat-polyfill';

function FallbackComponent() {
  return (
    <Content title="Sorry, an error occurred" breadcrumbs={[]}>
      <p>We are sorry, but the page has run into an error and we automatically logged the error in our tracking system. If you run into this error repeatedly, please <a href="mailto:khoury-admin@ccs.neu.edu">contact the Khoury Admin Site administrators</a>.</p>
      <Button type="primary" onClick={() => window.location.reload(false)}>Refresh Page</Button>
    </Content>
  );
}

const myFallback = <FallbackComponent />;

class Root extends Component {
  state = {
    token: localStorage.getItem('token') || sessionStorage.getItem('token'),
    backgroundImage: "url(" + "/static/frontend/images/" + Math.floor(Math.random() * Math.floor(4)) + ".jpg" + ")"
  }

  admin_site = () => {
    const { token } = this.state;

    const cookie = "token=" + token + "; " + (location.protocol == 'https:' ? "secure; " : "") + "path=/admin/; samesite=Strict; max-age=" + (15 * 60);
    document.cookie = cookie;
    window.open("/admin/", "_blank");
  }

  clear_token = () => {
    // remove the token from any local storage
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");

    // remove any cookies we have 
    const cookie = "token=; " + (location.protocol == 'https:' ? "secure; " : "") + "path=/admin/; samesite=Strict";
    document.cookie = cookie;

    // go back to the login page
    this.setState({ token: null });
  }

  logged_in = (token, remember) => {
    if (remember) {
      localStorage.setItem('token', token);
    } else {
      sessionStorage.setItem('token', token);
    }

    this.setState({ token: token });

  }

  logged_out = (explicit) => {
    const { token } = this.state;

    // if the user explicitly logged out, we definitely want to call the logout API
    // and then force-delete the token from LS and the cookie
    if (explicit) {
      logout(token, response => {
        if (response.error) {
          message.error(response.error);
        } else {
          window.history.pushState({ path: window.location.origin }, '', window.location.origin);
          this.clear_token();
          window.open("https://login.microsoftonline.com/a8eec281-aaa3-4dae-ac9b-9a398b9215e7/oauth2/v2.0/logout");
        }
      });

      // however, if this wasn't an explicit logout (and was the result of a 401 or 403),
      // it could be that the token changed.  In which case we look to see if there's a
      // newer token in LS.  If so, we grab it.  Otherwise, it will bring the page back 
      // to the root.
    } else {
      // we first check if there's a newer token in LS.  If not, we delete the token which forces
      // a logout
      if (token == localStorage.getItem("token")) {
        message.error("You've been forced logged out, please log-in again.", 5);
        this.clear_token();

        // if there is a newer token, we refresh the page, which will have the effect of
        // loading the new token.  We also need to take care to remove the token from 
        // our session storage, as refreshing will not clear the session.  We explicitly
        // *do not* remove it from LS, as it may be updated there.
      } else {
        message.error("You've been forced logged out, please log-in again.", 5);
        sessionStorage.removeItem("token");
        location.reload();
      }
    }
  }

  render() {
    const { token, backgroundImage } = this.state;

    if (token) {
      return (<Router basename={PREFIX} key="router">
        <RouterSwitch>
          <Route path="/" render={(props) => <App {...props} key="app" token={token} logged_out={this.logged_out} admin_site={this.admin_site} backgroundImage={backgroundImage} />} />
        </RouterSwitch>
      </Router>);
    } else {
      if (window.location.hostname == "localhost" | window.location.hostname == "127.0.0.1") {
        return (<DevLogin logged_in={this.logged_in} backgroundImage={backgroundImage} />);
      } else {
        return (<Login logged_in={this.logged_in} backgroundImage={backgroundImage} />);
      }
    }
  }
}

class Enable2FA extends AppComponent {
  state = {
    endpoint: "/api/user/2fa/",
    loading: true,
    key: null,
    code: null,

    error: null,
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.doGet(this.state.endpoint, (data) => {
        this.setState({ key: data.key, loading: false });
      });
    }
  }

  reset = () => {
    this.setState({ loading: true, key: null, code: null, error: null });
  }

  tryConfirm = () => {
    const { onSuccess, onCancel } = this.props;
    const { code } = this.state;

    this.doPost(this.state.endpoint, (data) => {
      if (data.success) {
        this.reset();
        onSuccess();
      } else {
        this.setState({ error: "Your 2FA code was incorrect. Please double-check your entry and try again." })
      }
    }, JSON.stringify({ code: code }));
  }

  render() {
    const { visible, user, onCancel } = this.props;
    const { loading, key, error } = this.state;

    const issuer = "Khoury%20Admin" + (this.is_beta() ? "%20%28beta%29" : this.is_local() ? "%20%28local%29" : "");
    const url = loading ? null : "otpauth://totp/" + issuer + ":" + user.username + "?secret=" + key + "&issuer=" + issuer + "&algorithm=SHA1&digits=6&period=30";

    return (
      <Modal
        visible={visible}
        title={"Enable Two Factor Authentication"}
        okText={"Confirm"}
        onOk={() => { this.tryConfirm() }}
        onCancel={() => { this.reset(); onCancel() }}
        width={600} >
        <p>This will set up two-factor authentication (2FA) for logging in to the Khoury Administration site.
          You will need to load the key below into a mobile app for generating time-based one-time password (TOTP) codes, such as Google Authenticator.
          Once you have done so, enter the code displayed in the box below in order to confirm you have set it up correctly.</p>
        <p><b>Important note:</b> If you lose your mobile device or are otherwise unable to generate a code, <i>you will not be able to log in</i>.</p>
        {error ? <Alert type="error" showIcon message={error} /> : null}
        <Divider orientation="left">Step 1: Scan QR Code with mobile device</Divider>
        <p>Using your mobile 2FA app, scan the code below.</p>
        <div align="center">{loading ? <Spin tip="Loading 2FA data..." /> : <QRCode value={url} />}</div>
        <Divider orientation="left">Step 2: Enter 6-digit code shown on device</Divider>
        <p>Enter the code the app generates below to confirm the setup.</p>
        <div align="center"><InputNumber size="large" min={0} max={999999} onChange={e => this.setState({ code: e, error: null })} disabled={loading} ref={(input) => input && input.focus()} /></div>
      </Modal>
    );
  }
}

class Disable2FA extends AppComponent {
  state = {
    endpoint: "/api/user/2fa/",
    code: "",
    error: null,
  }

  tryConfirm = () => {
    const { onSuccess, onCancel } = this.props;
    const { code } = this.state;

    this.doDelete(this.state.endpoint, (data) => {
      if (data && !data.success) {
        this.setState({ error: "Your 2FA code was incorrect.  Please double-check your entry and try again." })
      } else {
        onSuccess();
      }
    }, JSON.stringify({ code: code }));
  }

  render() {
    const { visible, user, onCancel } = this.props;
    const { error } = this.state;

    return (
      <Modal
        visible={visible}
        title={"Are you sure you wish to remove 2FA?"}
        okText={"Remove 2FA"}
        onOk={() => { this.tryConfirm() }}
        okButtonProps={{ type: "danger" }}
        onCancel={onCancel}
        width={600} >
        <p>This will remove two-factor authentication (2FA) from your account on the Khoury Administration site.  This will make your account less secure, and it is not recommended. </p>
        {error ? <Alert type="error" showIcon message={error} /> : null}
        <Divider orientation="left">Enter 6-digit code shown on device</Divider>
        <p>Enter the code below to confirm removal of two-factor authentication.</p>
        <div align="center"><InputNumber size="large" min={0} max={999999} onChange={e => this.setState({ code: e, error: null })} ref={(input) => input && input.focus()} /></div>

      </Modal>
    );
  }
}

class App extends LoggedInComponent {

  state = {
    user: null,
    timer: null,
    alert_timer: null,

    semester_list: null,
    semesters_list_grouped: null,
    semestertype_list: null,
    area_list: null,
    campus_list: null,
    college_list: null,
    committee_list: null,
    committeeyear_list: null,
    position_list: null,
    positionyear_list: null,
    course_list: null,
    fund_list: null,
    account_list: null,
    grade_list: null,
    subject_list: null,
    employee_list: null,
    instructor_list: null,
    instructorrank_list: null,
    major_list: null,
    degree_list: null,
    degreetype_list: null,
    meetingtime_list: null,
    method_list: null,
    room_list: null,
    nucore_list: null,
    nupath_list: null,

    workflows: null,

    semester: null,
    semesters: null,
    campus: null,

    semester_default: null,
    loading_message: "",

    enable2fa_visible: false,
    disable2fa_visible: false,

    error_version: null,
    error_version_dismissed: false,

    alerts: [],

    new_loadcount: { use: false, min: 0.7, max: 1.5, x_0: 65, k: 0.05, enrollment_cap: "enrollment" },
  };

  getQueryString = (addTab, cleanse, tabName) => {
    const { user, campus, semester } = this.state;
    const values = queryString.parse(window.location.search);
    var query = "?semester=" + (semester ? semester : values["semester"]) + "&campus=" + (campus ? campus : values["campus"]) + (addTab && (values["tab"] || tabName) ? ("&tab=" + (tabName ? tabName : values["tab"])) : "");

    if (!cleanse && ("email_code" in values) && ("email_id" in values)) {
      query += "&email_code=" + values["email_code"] + "&email_id=" + values["email_id"];
    }

    if (addTab && ("workflow_item_id" in values)) {
      query += "&workflow_item_id=" + values["workflow_item_id"];
    }

    return query.replace(" ", "+");
  }

  updateQueryString = (func) => {
    window.history.pushState({}, "", location.pathname + this.getQueryString(true, false, null));
    if (func) {
      func();
    }
  }

  replaceQueryString = (tabName) => {
    window.history.replaceState({}, "", location.pathname + this.getQueryString(true, false, tabName));
  }

  setSemester = (semester) => {
    const { user } = this.state;
    const { semesters_list_grouped } = this.state;

    const semesters = semesters_list_grouped[semester].map(el => el.id);
    this.setState({ semester: semester, semesters: semesters }, this.updateQueryString);
    if (semesters[0] != user.semester) {
      this.doPatch("/api/user/", data => this.setState({ user: data }), JSON.stringify({ semester: semesters[0] }));
    }
  }

  setCampus = (campus) => {
    const { user } = this.state;

    this.setState({ campus: campus }, this.updateQueryString);
    if (campus != user.campus) {
      this.doPatch("/api/user/", data => this.setState({ user: data }), JSON.stringify({ campus: campus }));
    }
  }

  loadData = (name, endpoint, func) => {
    this.doGet(endpoint, data => {
      if (data) {
        var newdata = {};
        data.forEach(el => newdata[el.id] = el);
        func(newdata);
        this.setState({ loading_message: name.replace("_list", "s") });
      } else {
        message.error("Received null " + name + " list.");
      }
    });
  }

  loadDataDefault = (name, endpoint, func) => {
    this.loadData(name, endpoint, data => { this.setState({ [name]: data }) });
  }

  loadWorkflows = (func) => {
    this.doGet("/api/workflow/", data => this.setState({ workflows: data }, func));
  }

  semesterGroup = (semester) => {
    const result = semester.name.match(/^(\w+).* (\d+)$/);
    return result[1] + " " + result[2];
  }

  receiveSemesters = (data) => {
    const { user } = this.state;

    var possible = Object.values(data);
    possible.sort((a, b) => b.code - a.code);
    const semester_default = this.semesterGroup(possible.filter(el => moment(el.startdate, "YYYY-MM-DD") <= moment())[0]);
    const grouped = possible.reduce((r, a) => { r[this.semesterGroup(a)] = r[this.semesterGroup(a)] || []; r[this.semesterGroup(a)].unshift(a); return r; }, {});

    this.setState({ semester_list: data, semesters_list_grouped: grouped, semester_default: semester_default }, () => {
      // first set it to the default
      var semester = semester_default;

      // if the user has a semester in the DB, that overrides it
      if (user.semester) {
        const found = Object.keys(grouped).find(a => grouped[a].find(s => s.id == user.semester));

        if (found) {
          semester = found;
        }
      }

      // if there is a semester in the URL query, that trumps everything
      const values = queryString.parse(window.location.search);
      if (("semester" in values) && (values["semester"] in grouped)) {
        semester = values["semester"];
      }

      this.setSemester(semester);
    });
  }

  receiveCampuses = (data) => {
    const { user } = this.state;

    // first set it to the user's first campus
    var campus = user.campuses[0];

    // if the user has a campus in the DB, that overrides it
    if (user.campus) {
      campus = user.campus;
    }

    // if there is a campus in the URL query, that trumps everything
    const values = queryString.parse(window.location.search);
    if ("campus" in values) {
      const p_campus = parseInt(values["campus"]);

      // Verify the campus exists and it's one of the user's campuses
      if (data[p_campus] && user.campuses.includes(p_campus)) {
        campus = p_campus;
      }
    }

    this.setState({ campus_list: data }, () => this.setCampus(campus));
  }

  start_impersonate = (user) => {
    this.doGet("/api/impersonate/" + user + "/", this.refresh); //() => { window.location.reload(); })
  }

  stop_impersonate = () => {
    this.doGet("/api/impersonate/", this.refresh); //() => { window.location.reload(); })
  }

  refresh = () => {
    window.location = location.pathname + this.getQueryString(false, true);
  }

  enable_2fa = () => {
    this.setState({ enable2fa_visible: true });
  }

  enable_2fa_success = () => {
    const { user } = this.state;
    user.has_2fa = true;
    this.setState({ user: user, enable2fa_visible: false }, () => message.success("2FA has successfully been added to your account."));
  }

  enable_2fa_cancel = () => {
    this.setState({ enable2fa_visible: false }, () => message.info("Cancelled addition of 2FA to your account."));
  }

  disable_2fa = () => {
    this.setState({ disable2fa_visible: true });
  }

  disable_2fa_success = () => {
    const { user } = this.state;
    user.has_2fa = false;
    this.setState({ user: user, disable2fa_visible: false }, () => message.success("2FA has successfully been removed from your account."));
  }

  disable_2fa_cancel = () => {
    this.setState({ disable2fa_visible: false }, () => message.info("Retained 2FA on your account."));
  }

  getUser = (func) => {
    this.doGet("/api/user/", data => this.setState({ user: data }, func));  //, campus: data.campus ? data.campus : data.campuses[0]
  }

  getInstructors = (func) => {
    this.doGet("/api/faculty/", data => this.setState({ instructor_list: data }, func));
  }

  getAlerts = () => {
    const { user } = this.state;

    this.doGet("/api/systemalert/", data => this.setState({ alerts: data }));
  }

  handleSSORedictURL = () => {
    const savedUrl = sessionStorage.getItem('savedUrl');
    if (savedUrl) {
      sessionStorage.removeItem('savedUrl');
      const url = new URL(savedUrl);
      const pathname = url.pathname + url.search;
      window.history.replaceState(null, '', pathname);
      window.location.href = pathname;
    }
  }

  receiveUser = () => {

    if (SSOAllowAccess(this.state.user)) {
      this.handleSSORedictURL();
      this.getAlerts();
      this.setState({ alert_timer: setInterval(() => this.getAlerts(), 1000 * 60 * 10) });
      this.loadData("semester_list", "/api/semester/", this.receiveSemesters);
      this.loadData("campus_list", "/api/campus/", this.receiveCampuses);

      // Only proceed if we are a "full" user
      if (this.state.user?.nuid && this.state.user?.campuses && this.state.user?.person && SSOAllowAccess(this.state.user)) {
        this.loadDataDefault("area_list", "/api/faculty/area/");
        this.loadDataDefault("college_list", "/api/college/");
        this.loadDataDefault("course_list", "/api/course/");
        this.loadDataDefault("semestertype_list", "/api/semester/type/");
        this.loadDataDefault("meetingtime_list", "/api/meetingtime/");
        this.loadDataDefault("method_list", "/api/method/");
        this.loadDataDefault("employee_list", "/api/employee/");
        this.loadDataDefault("instructor_list", "/api/faculty/");
        this.loadDataDefault("instructorrank_list", "/api/faculty/rank/");
        this.loadDataDefault("major_list", "/api/degree/major/");
        this.loadDataDefault("degreetype_list", "/api/degree/degreetype/");
        this.loadDataDefault("degree_list", "/api/degree/degree/");
        this.loadDataDefault("subject_list", "/api/subject/");
        this.loadDataDefault("nucore_list", "/api/course/nucore/");
        this.loadDataDefault("nupath_list", "/api/course/nupath/");
        this.loadDataDefault("grade_list", "/api/grade/");
        this.loadDataDefault("room_list", "/api/room/");

        if (this.permission("can", "faculty") || (this.permission("can", "staff"))) {
          this.loadDataDefault("fund_list", "/api/funds/");
          this.loadDataDefault("account_list", "/api/accounts/");
          this.loadDataDefault("committee_list", "/api/committees/");
          this.loadDataDefault("committeeyear_list", "/api/committeeyears/");
          this.loadDataDefault("position_list", "/api/position/");
          this.loadDataDefault("positionyear_list", "/api/positionyears/");
          this.loadWorkflows();
        }
      }
    }
  }

  isCompletelyLoaded = () => {
    const { user, area_list, committee_list, committeeyear_list, position_list, positionyear_list, campus_list, college_list, semester_list, course_list, fund_list, account_list, employee_list, instructor_list, instructorrank_list, method_list, meetingtime_list, room_list, campus, semester, nucore_list, nupath_list, subject_list, semestertype_list, grade_list, major_list, degreetype_list, degree_list, workflows } = this.state;


    return user && semester_list && campus_list && college_list && area_list && course_list && semestertype_list && employee_list && instructor_list && meetingtime_list && method_list && room_list && nucore_list && nupath_list && subject_list && instructorrank_list && major_list && grade_list && degreetype_list && degree_list && campus && semester && ((this.permission("isnot", "faculty") && this.permission("isnot", "admin")) || (committee_list && committeeyear_list && position_list && positionyear_list)) && ((this.permission("isnot", "admin") && this.permission("is", "student")) || (fund_list && account_list && workflows));
  }

  handle_userrequirements = (data) => {
    const { campus } = this.state;
    this.doPatch('/api/user/',
      (result) => this.setState({ user: result, campus: result.campuses.includes(campus) ? campus : result.campus ? result.campus : result.campuses[0] }, () => { this.updateQueryString(this.receiveUser) }),
      JSON.stringify(data.campus && data.nuid ? { "campus": data.campus, "nuid": data.nuid } : data.campus ? { "campus": data.campus } : { "nuid": data.nuid }));
  };

  permission = (type, required) => {
    const perm = this.state.user.groups.map(el => el.name);
    if ((type == "can") || (type == "cannot")) {
      if (perm.includes("admin") && type == "can") return type == "can";
      return type == "can" ? perm.includes(required) : !perm.includes(required);
    } else if (type == "is") {
      return perm.includes(required);
    } else if (type == "isnot") {
      return !perm.includes(required);
    }
  }

  componentDidMount() {
    this.getUser(this.receiveUser);
    this.setState({ timer: setInterval(() => this.checkVersion(), 1000 * 60 * 60) });
  }

  componentWillUnmount() {
    const { timer, alert_timer } = this.state;

    if (timer) {
      clearInterval(timer);
    }

    if (alert_timer) {
      clearInterval(alert_timer);
    }
  }

  checkVersion = () => {
    this.doGet("/static/frontend/version.json", data => this.setState({ error_version: ((typeof data === "string") && (data != VERSION)) ? data : null }));
  }

  render() {
    const { match, admin_site, logged_out, backgroundImage } = this.props;
    const { enable2fa_visible, disable2fa_visible, loading_message, error_version, error_version_dismissed, alerts, user } = this.state;

    const baseProps = {
      version: VERSION,
      version_date: VERSION_DATE,

      token: this.props.token,
      user: this.state.user,
      semester: this.state.semester,
      semesters: this.state.semesters,
      campus: this.state.campus,
      logged_out: logged_out,

      area_list: this.state.area_list,
      committee_list: this.state.committee_list,
      committeeyear_list: this.state.committeeyear_list,
      position_list: this.state.position_list,
      positionyear_list: this.state.positionyear_list,
      fund_list: this.state.fund_list,
      account_list: this.state.account_list,
      semester_list: this.state.semester_list,
      semesters_list_grouped: this.state.semesters_list_grouped,
      semestertype_list: this.state.semestertype_list,
      campus_list: this.state.campus_list,
      college_list: this.state.college_list,
      course_list: this.state.course_list,
      nucore_list: this.state.nucore_list,
      nupath_list: this.state.nupath_list,
      grade_list: this.state.grade_list,
      employee_list: this.state.employee_list,
      instructor_list: this.state.instructor_list,
      instructorrank_list: this.state.instructorrank_list,
      major_list: this.state.major_list,
      degreetype_list: this.state.degreetype_list,
      degree_list: this.state.degree_list,
      method_list: this.state.method_list,
      meetingtime_list: this.state.meetingtime_list,
      room_list: this.state.room_list,
      subject_list: this.state.subject_list,
      onSetSemester: this.setSemester,
      onSetCampus: this.setCampus,
      onUpdateUser: this.getUser,
      onUpdateInstructor: this.getInstructors,
      refresh: this.refresh,

      workflows: this.state.workflows,
      updateWorkflows: this.loadWorkflows,

      updateFunds: () => this.loadDataDefault("fund_list", "/api/funds/"),
      updateCommitteeMemberships: () => this.loadDataDefault("committeeyear_list", "/api/committeeyears/"),

      getQueryString: this.getQueryString,
      updateQueryString: this.updateQueryString,
      replaceQueryString: this.replaceQueryString,

      new_loadcount: this.state.new_loadcount,
      set_new_loadcount: (val) => this.setState({ new_loadcount: val }),

      alerts: user ? alerts.filter(value => value.group.some(el => user.groups.map(el => el.name).includes(el.name))) : [],
    }

    if (!this.state.user || (SSOAllowAccess(this.state?.user) && (!this.state.semester_list || !this.state.campus_list))) {
      return (<div className="loading"><Spin tip="Logging you in..." /></div>);
    }


    const values = queryString.parse(window.location.search);
    if (("email_code" in values) && ("email_id" in values)) {
      return (<UserEmailCodeEntered {...baseProps} backgroundImage={backgroundImage} onDone={this.refresh} email_code={values["email_code"]} email_id={values["email_id"]} />);
    } else if ((!this.state.user.nuid || !this.state.user.campuses || this.state.user.campuses.length == 0) && this.state.user.impersonator) {
      return (<CannotImpersonate {...baseProps} stop_impersonate={this.stop_impersonate} backgroundImage={backgroundImage} />);
    } else if (!SSOAllowAccess(this.state.user)) {
      return (<NotKhoury {...baseProps} student={this.state.user.student} backgroundImage={backgroundImage} />);
    } else if (!this.state.user.nuid || !this.state.user.campuses || this.state.user.campuses.length == 0) {
      return (<UserRequirementsForm {...baseProps} handle_userrequirements={this.handle_userrequirements} user={this.state.user} campuses={this.state.campuses} backgroundImage={backgroundImage} />);
    } else if ((!this.state.user.impersonator) && (this.state.user.emails.filter(e => e.primary && e.verified) == 0)) {
      return (<UserEmailLogin {...baseProps} backgroundImage={backgroundImage} />);
    } else if (this.state.user.groups.length == 0) {
      return (<NoGroups {...baseProps} backgroundImage={backgroundImage} />);
    } else if ((this.permission("is", "faculty")) && (this.state.user.instructor == null)) {
      return (<NoInstructor {...baseProps} backgroundImage={backgroundImage} />);
    } else if (this.state.user.person == null) {
      return (<NoPerson {...baseProps} backgroundImage={backgroundImage} />);
    } else if (!this.isCompletelyLoaded()) {
      return (<div className="loading"><Spin tip={["Loading Khoury Admin...", <br key="foo" />, <small key="bar">Loaded {loading_message}</small>]} /></div>);
    } else {
      const submodules = Admin.submodules(baseProps).concat(Students.submodules(baseProps)).concat(Staff.submodules(baseProps))
        .concat(Faculty.submodules(baseProps)).concat(Teaching.submodules(baseProps)).concat(Employee.submodules(baseProps));
      return (
        <Sentry.ErrorBoundary fallback={myFallback} beforeCapture={scope => { scope.setUser({ id: this.state.user.id }) }}>
          {error_version && !error_version_dismissed ? (
            <Modal title="Updated Khoury Admin site available" visible={true} maskClosable={false} closable={false} okText="Refresh" cancelText="Continue" onOk={() => location.reload()} onCancel={() => this.setState({ error_version_dismissed: true })} >
              <p>An updated version of the Khoury Admin site (version {error_version}) is available; your web browser has a cached copy of older code (version {VERSION}).  It is strongly recommended that you refresh your window to get the latest code, otherwise you may experience inconsistent behavior.  Note that by refreshing, you may lose any unsaved work.  You therefore may dismiss this window and continue to use the old version if you wish.</p>
            </Modal>
          ) : null}

          <Layout key="main">
            <Enable2FA {...baseProps} visible={enable2fa_visible} onSuccess={this.enable_2fa_success} onCancel={this.enable_2fa_cancel} />
            <Disable2FA {...baseProps} visible={disable2fa_visible} onSuccess={this.disable_2fa_success} onCancel={this.disable_2fa_cancel} />
            <NavBar {...baseProps} key="navbar" logged_out={logged_out} admin_site={admin_site} enable_2fa={this.enable_2fa} disable_2fa={this.disable_2fa} start_impersonate={this.start_impersonate} stop_impersonate={this.stop_impersonate} />


            <RouterSwitch key="switch">
              <Route exact key="home" path="/" render={(props) => <Home {...props} {...baseProps} key="home" submodules={submodules} />} />
              <Route path="/teaching" render={(props) => <Teaching {...props} {...baseProps} />} />
              {this.permission("can", "faculty") &&
                <Route path="/faculty" render={(props) => <Faculty {...props} {...baseProps} />} />
              }
              {this.permission("can", "employee") &&
                <Route path="/employee" render={(props) => <Employee {...props} {...baseProps} />} />
              }
              {this.permission("can", "staff") &&
                <Route path="/staff" render={(props) => <Staff {...props} {...baseProps} />} />
              }
              {this.permission("can", "student") &&
                <Route path="/students" render={(props) => <Students {...props} {...baseProps} />} />
              }
              {this.permission("can", "admin") &&
                <Route path="/site-admin" render={(props) => <Admin {...props} {...baseProps} />} />
              }
              <Route render={(props) => <Redirect to="/" />} />
            </RouterSwitch>
          </Layout>
        </Sentry.ErrorBoundary>
      );
    }
  }
}

// ----- ABSTRACT CLASS -----

class AppModule extends AppComponent {

  renderItem = (item) => {
    const label = (<React.Fragment><Icon type={item.icon} />{item.name}</React.Fragment>);
    if (item.link) {
      return <Menu.Item key={item.key ? item.key : item.name}><Link to={this.getLink(item.link)}>{label}</Link></Menu.Item>
    } else {
      return <Menu.Item key={item.key ? item.key : item.name} disabled>{label}</Menu.Item>
    }
  }

  check_show = (e) => {
    if (!("show" in e)) { return true; }
    if (typeof e.show == "boolean") { return e.show; }
    if (typeof e.show == "string") { return this.permission("can", e.show); }
    if (typeof e.show == "object") { return e.show.reduce((r, a) => r || this.permission("can", a), false); }
    if (typeof e.show == "function") { return e.show(this); }
    return false;
  }

  renderSider = (items) => {
    const selected = items.map(e => e.items ? [e].concat(e.items) : [e]).flat().find(e => e.link == location.pathname);

    return (
      <Sider width={200}
        style={{ background: '#fff', marginTop: this.top_margin(), zIndex: 900, minHeight: "calc(100vh - " + this.top_margin() + "px)" }}
        collapsedWidth={0}
        breakpoint="lg"
        key="sider"
        className="no-print"
      >
        <Menu
          mode="inline"
          key="menu"
          defaultOpenKeys={items.filter(el => el.items).map(el => el.key ? el.key : el.name)}
          style={{ height: '100%', borderRight: 0 }}
          selectedKeys={selected ? [selected.key ? selected.key : selected.name] : []}
        >
          {items.filter(this.check_show).map(el => {
            if (el.items) {
              return (
                <SubMenu key={el.name} title={<span><Icon type={el.icon} /><span>{el.name}</span></span>}>
                  {el.items.filter(this.check_show).map(this.renderItem)}
                </SubMenu>
              );
            } else {
              return this.renderItem(el);
            }
          })}
          {items.filter(this.check_show).length == 0 && <Menu.Item key={"NTS"} disabled>{"No Links to Show"}</Menu.Item>}
        </Menu>
      </Sider>
    );
  }
}

class TodoCard extends AppComponent {
  state = {
    endpoint: "/api/todo/",
    todos: [],
    loading: true,
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.doGet(this.state.endpoint, data => this.setState({ todos: data, loading: false }));
  }

  findSemester = (sem) => {
    const { semesters_list_grouped } = this.props;
    return Object.keys(semesters_list_grouped).find(s => semesters_list_grouped[s].find(ss => ss.id == sem));
  }

  render() {
    const { loading, todos } = this.state;
    const { workflows } = this.props;

    const columns_load = [
      {
        title: 'Todo',
        key: 'todo',
        render: (text, record, idx) => record.abstract_workflow_type ? [get_nonecheck(), " ", <Link to={this.getLink("/employee/workflow/", { workflow_item_id: record.id })}>{ }{record.name}</Link>] : [record.completed ? get_check() : record.started ? get_pausecheck() : get_nonecheck(), " ", record.link ? (<Link to={this.getLink(record.link)} onClick={() => record.semester ? this.props.onSetSemester(this.findSemester(record.semester)) : null}>{ }{record.title}</Link>) : record.title, [record.semester ? [" (", this.print_semester(record.semester), ")"] : null]],
      }, {
        title: 'Due',
        key: 'due',
        align: 'right',
        render: (text, record, idx) => record.abstract_workflow_type ? null : moment(record.due).format("MMM DD, YYYY"),
      }
    ];

    return (
      <Card size="small" title={"Your todos"}>
        {loading ? (<Spin tip="Loading todos..." />) : (<Table {...this.props} dataSource={workflows ? todos.concat(workflows) : todos} columns={columns_load} bordered={false} pagination={false} size="small" rowKey={(record) => record.abstract_workflow_type ? record.id : record.title} />)}
      </Card>);
  }
}

const TodoCreate = Form.create({ name: 'form_not_in_modal' })(
  class extends AppComponent {
    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };
    dateFormat = 'YYYY-MM-DD';

    state = {
      endpoint: "/api/todo/",

      semester: null,
      mytype: null,
      visible: null,
      due: null,
      hide: null,
    }

    handleSubmit = (e) => {
      const form = this.props.form;
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          values["due"] = values["due"] ? moment(values["due"]).format(this.dateFormat) : null;
          values["visible"] = values["visible"] ? moment(values["visible"]).format(this.dateFormat) : null;
          values["hide"] = values["hide"] ? moment(values["hide"]).format(this.dateFormat) : null;

          this.doPost(this.state.endpoint, response => { message.success("Created todo"); form.resetFields(); this.setState({ semester: null, mytype: null, visible: null, due: null, hidden: null }) }, JSON.stringify(values));
        } else {
          message.error(err);
        }
      });
    }

    disabledDate = (value) => {
      return moment().subtract(1, "day") > value.valueOf();
    }

    render() {
      const { getFieldDecorator } = this.props.form;

      const semester_list = this.semester_list().filter(el => el.code >= this.get_semester(this.props.semesters[0]).code).reverse();
      const types = ["PhD student review", "PhD student self review", "PhD student funding survey", "Teaching preferences", "Committee preferences", "Buyout review", "Rate TA applications", "Accept TA position", "Review TAs", "Complete TT/T merit review", "Complete teaching merit retrospective memorandum"];

      return (
        <Content {...this.props} title={"Todo Create"} breadcrumbs={[{ link: "/site-admin", text: "Admin" }, { text: "Todos" }]}>
          <p>Below is a form for creating todos for users.  To use it, select the type of todo you wish to create, the semester you wish to create it for, and the visible date, due date, and hidden date.</p>

          <Form onSubmit={this.handleSubmit} >
            <FormItem {...this.formItemLayout} label="Type" extra={"Select the kind of todo this is."}>
              {getFieldDecorator('mytype', {
                rules: [{ required: true, message: 'Please select the type of todo.' }],
                onChange: (event) => { this.setState({ mytype: event }) },
              })(<Select style={{ width: 360 }}>
                {types.map(el => <Option key={el} value={el}>{el}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem {...this.formItemLayout} label="Semester" extra={"Select the semester this todo is for."}>
              {getFieldDecorator('semester', {
                rules: [{ required: true, message: 'Please select a semester.' }],
                onChange: (event) => { this.setState({ semester: event }) },
              })(<Select style={{ width: 360 }}>
                {semester_list.map(el => <Option key={el.id} value={el.id}>{this.print_semester(el.id)}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem {...this.formItemLayout} label="Visible Date" extra={"Select the date the todo will start to be shown (defaults to now)."} >
              {getFieldDecorator('visible', {
                onChange: (event) => { this.setState({ visible: event }) },
              })(<DatePicker format={this.dateFormat} disabledDate={this.disabledDate} />)}
            </FormItem>
            <FormItem {...this.formItemLayout} label="Due Date" extra={"Select the date the todo needs to be completed by."} >
              {getFieldDecorator('due', {
                rules: [{ required: true, message: 'Please select the due date.' }],
                onChange: (event) => { this.setState({ due: event }) },
              })(<DatePicker format={this.dateFormat} disabledDate={this.disabledDate} />)}
            </FormItem>
            <FormItem {...this.formItemLayout} label="Hidden Date" extra={"Select the date the todo will no longer be shown (defaults to the due date)."} >
              {getFieldDecorator('hide', {
                onChange: (event) => { this.setState({ hide: event }) },
              })(<DatePicker format={this.dateFormat} disabledDate={this.disabledDate} />)}
            </FormItem>
            <FormItem {...this.formItemLayout} wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 }, }} >
              <Button type="primary" htmlType="submit">{"Create"}</Button>
            </FormItem>
          </Form>
        </Content>
      );
    }
  }
);

// ----- TEACHING MODULE -----

class Teaching extends AppModule {
  static submodules(props) {

    const show_instructor_stuff = (props.user.instructor != null);

    var show_TA = false;
    try {
      show_TA = props.instructor_list[props.user.instructor] ? true : false;
    } catch (err) { }

    return [
      { icon: "book", name: "Courses", link: "/teaching/courses/" },
      {
        icon: "edit", name: "Planning", show: ["ugradadmin", "gradadmin"], items: [
          { icon: "schedule", name: "Worksheet", link: "/teaching/planning/" },
          { icon: "database", name: "Teaching Preferences", link: "/teaching/preferencesbreakdown/" },
        ]
      },
      {
        icon: "layout", name: "Rooms", show: ["faculty", "staff"], items: [
          { icon: "unordered-list", name: "Room List", link: "/teaching/rooms/" },
          { icon: "desktop", name: "Desk Assignments", link: "/teaching/rooms/desks/" },
        ]
      },
      { icon: "schedule", name: "Schedules", link: "/teaching/schedules/" },
      { icon: "sliders", name: "Breakdown", link: "/teaching/breakdown/", show: ["ugradadmin", "gradadmin"] },
      { icon: "like", name: "Preferences", link: "/teaching/preferences/", show: show_instructor_stuff, cards: [(<ScheduleCard {...props} />), (<LoadCard {...props} show={show_instructor_stuff} />)] },
      {
        icon: "solution", name: "TAs", show: show_TA, items: [
          { icon: "ordered-list", name: "Rate", link: "/teaching/ta/rate/" },
          { icon: "user-add", name: "Assigned", link: "/teaching/ta/assigned/" },
          { icon: "dollar", name: "Hours", link: "/teaching/ta/hours/" },
          { icon: "like", name: "Review", link: "/teaching/ta/review/" }
        ]
      },
      { icon: "link", name: "Office Hours Login", link: "/teaching/officehourslogin/" },
    ];
  }

  render() {
    const { location, match, semester, campus } = this.props;
    const sidemenu = this.renderSider(Teaching.submodules(this.props));

    return (
      <Layout>
        {sidemenu}
        <Layout style={{ padding: '0 0 0 0', marginTop: this.top_margin(), minHeight: "calc(100vh - " + this.top_margin() + "px)" }}>
          <Sentry.ErrorBoundary fallback={myFallback} beforeCapture={scope => { scope.setUser({ id: this.props.user.id }) }}>
            <RouterSwitch>
              <Route path="/teaching/courses/:course_id" render={(props) => <Course key={props.match.params.course_id} {...this.props} {...props} />} />
              <Route path="/teaching/courses" render={(props) => <CourseList {...this.props} {...props} />} />
              {(this.permission("can", "ugradadmin") || this.permission("can", "gradadmin")) && <Route path="/teaching/breakdown" render={(props) => <ScheduleBreakdown {...this.props} {...props} />} />}
              {(this.permission("can", "ugradadmin") || this.permission("can", "gradadmin")) && <Route path="/teaching/planning" render={(props) => <PlanningSchedule {...this.props} {...props} />} />}
              <Route path="/teaching/preferences" render={(props) => <TeachingPreferences {...this.props} {...props} />} />
              <Route path="/teaching/preferencesbreakdown" render={(props) => <PreferenceBreakdown {...this.props} {...props} />} />
              {(this.permission("can", "faculty") || this.permission("can", "staff")) && <Route path="/teaching/rooms/desks/" render={(props) => <DeskAssignments {...this.props} {...props} />} />}
              <Route path="/teaching/rooms/:room_id" render={(props) => <Room key={props.match.params.room_id} {...this.props} {...props} />} />
              <Route path="/teaching/rooms" render={(props) => <RoomList {...this.props} {...props} />} />
              <Route path="/teaching/schedules/section/:section_id" render={(props) => <Section key={props.match.params.section_id} {...this.props} {...props} />} />
              <Route path="/teaching/schedules/sectiongroup/:group_id" render={(props) => <SectionGroup key={props.match.params.group_id} {...this.props} {...props} />} />
              <Route path="/teaching/schedules" render={(props) => <Schedule {...this.props} {...props} />} />
              {(this.permission("cannot", "student") || this.permission("can", "faculty") || this.permission("can", "teacher")) && <Route path="/teaching/ta/rate/" render={(props) => <TAApplicationReview {...this.props} {...props} tab="not-reviewed" />} />}
              {(this.permission("cannot", "student") || this.permission("can", "faculty") || this.permission("can", "teacher")) && <Route path="/teaching/ta/assigned/" render={(props) => <TAApplicationReview {...this.props} {...props} tab="assigned" />} />}
              {(this.permission("cannot", "student") || this.permission("can", "faculty") || this.permission("can", "teacher")) && <Route path="/teaching/ta/hours/" render={(props) => <TAHoursFaculty {...this.props} {...props} />} />}
              {(this.permission("cannot", "student") || this.permission("can", "faculty") || this.permission("can", "teacher")) && <Route path="/teaching/ta/review/" render={(props) => <TAReview {...this.props} {...props} type="review" />} />}
              <Route path="/teaching/officehourslogin/" render={(props) => <ExportOfficeHours {...this.props} {...props} />} />
              <Route path="/teaching/" render={(props) => <TeachingHome {...this.props} {...props} submodules={Teaching.submodules(this.props)} />} />
            </RouterSwitch>
          </Sentry.ErrorBoundary>
        </Layout>
      </Layout>
    );
  }
}

class CourseList extends AppComponent {
  render() {
    var mycourses = this.course_list().filter(el => !el.deprecated);

    var columns = [
      {
        title: 'Course',
        key: 'name',
        width: 80,
        render: (text, record, idx) => this.link_course(record.id),
      }, {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
      }, {
        title: 'Crosslist',
        key: 'crosslist',
        width: 80,
        render: (text, record, idx) => record.crosslist ? this.link_course(record.crosslist) : null,
      }, {
        title: 'Hours',
        dataIndex: 'hours',
        width: 55,
        key: 'hours',
      }, {
        title: 'TA Ratio',
        dataIndex: 'ta_ratio',
        width: 80,
        key: 'ta_ratio',
      }, {
        title: 'Prerequisites',
        key: 'prerequisites',
        width: 200,
        render: (text, record, idx) => add_dividers(record.prerequisites.map(el => this.link_course(el))),
      }, {
        title: 'Corequisites',
        key: 'corequisites',
        width: 90,
        render: (text, record, idx) => add_dividers(record.corequisites.map(el => this.link_course(el))),
      }, {
        title: 'Replaces',
        render: (text, record, idx) => record.previous ? this.link_course(record.previous) : null,
        width: 80,
        key: 'previous',
      }, {
        title: 'NUPath',
        key: 'nupath',
        align: 'center',
        width: 110,
        render: (text, record, idx) => record.nupath.map(el => this.print_nupath(el)),
      }, {
        title: 'NUCore',
        key: 'nucore',
        align: 'center',
        width: 110,
        render: (text, record, idx) => record.nucore.map(el => this.print_nucore(el)),
      }, {
        title: 'PhD Area',
        key: 'area',
        align: 'center',
        width: 110,
        render: (text, record, idx) => record.area.map(el => this.print_area(el)),
      }, {
        title: 'Schedule',
        key: 'schedule',
        align: 'center',
        width: 150,
        render: (text, record, idx) => this.print_course_schedule(record.schedule),
      }];

    if (this.permission("is", "student")) {
      columns = columns.filter(c => c.key != "ta_ratio");
    }

    return (
      <Content {...this.props} title={"Course List"} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { text: "Courses" }]}>
        <p>Below is the list of all courses that are in the University Catalog.  Click on an individual course to see more information and a history of when it has been offered.</p>
        <CustomTabs {...this.props} default_Active_Key="CS">
          {this.subject_list().map(el => {
            const subject_courses = mycourses.filter(c => c.subject == el.id);
            if (subject_courses.length) {
              return (<TabPane tab={el.abbrv + ": " + el.name} key={el.abbrv}>
                <Table {...this.props} dataSource={subject_courses} columns={columns} scroll={{ x: 1400 }} bordered={false} pagination={false} size="small" rowKey="id" key={"table-" + el.abbrv} />
              </TabPane>
              );
            } else {
              return null;
            }
          })}
        </CustomTabs>
      </Content>
    );
  }
}

class RoomList extends AppComponent {
  render() {
    const { campus } = this.props;

    var myrooms = this.room_list().filter(el => el.building.campus == campus);

    const columns = [
      {
        title: 'Name',
        key: 'name',
        fixed: 'left',
        width: 100,
        render: (text, record, idx) => this.link_room(record.id),
      }, {
        title: 'Building',
        dataIndex: 'building.name',
        key: 'building',
      }, {
        title: 'Number',
        dataIndex: 'number',
        key: 'number',
        width: 100,
      }, {
        title: 'Capacity',
        dataIndex: 'capacity',
        key: 'title',
        width: 100,
      }, {
        title: 'Campus',
        key: 'campus',
        width: 120,
        render: (text, record, idx) => this.get_campus(record.building.campus).name,
      }, {
        title: '25Live ID',
        dataIndex: 'live_id',
        key: 'liveid',
        width: 100,
      }, {
        title: 'Active',
        key: 'active',
        width: 50,
        render: (text, record, idx) => get_check_nocheck(record.active),
      }, {
        title: 'Tags',
        key: 'tags',
        width: 140,
        render: (text, record, idx) => record.ccis_owned ? (<Tag color="green" key="Khoury">Khoury Owned</Tag>) : "",
      }];

    return (
      <Content {...this.props} title={"Room List"} breadcrumbs={[{ link: "/teaching", text: "Teaching" }, { text: "Rooms" }]}>
        {this.building_list().map(el => {
          const buildings_rooms = myrooms.filter(c => c.building.id == el.id);
          if (buildings_rooms.length) {
            return (<React.Fragment key={"fragment-" + el.id}>
              <Divider key={"div-" + el.id} orientation="left">{el.abbrv}: {el.name}</Divider>
              <Table {...this.props} dataSource={buildings_rooms} columns={columns} scroll={{ x: 1000 }} bordered={false} pagination={false} size="small" rowKey="id" key={"table-" + el.id} />
            </React.Fragment>
            );
          } else {
            return "";
          }
        })}
      </Content>
    );
  }
}

// ----- EMPLOYEE MODULE -----

class Employee extends AppModule {

  static submodules(props) {
    const fundviewer = props.fund_list ? Object.values(props.fund_list).some(el => el.viewers.includes(props.user.employee)) || Object.values(props.fund_list).some(f => f.owners.find(fo => fo.owner == props.user.employee)) : false;

    return [
      {
        icon: "form", name: "Indexes", show: () => fundviewer || props.user.groups.some(el => ["finance", "grants", "admin"].includes(el.name)), cards: [(<FundCard {...props} />)], items: [
          { icon: "dashboard", name: "Overview", show: ["finance", "grants"], link: "/employee/finance/fund/" },
          { icon: "dollar", name: "List", show: fundviewer, link: "/employee/fund/" },
        ]
      },
      {
        icon: "mail", name: "Mailing Lists", show: ["faculty", "hr", "finance", "grants", "marketing"], link: "/employee/mailinglists/"
      },
      {
        icon: "crown", name: "PhD", show: ["faculty", "gradadmin"], cards: [(<PhDFacultyCard {...props} />)], items: [
          { icon: "file-text", name: "Academic Review", show: "faculty", link: "/employee/phd/review/" },
          { icon: "dollar", name: "Funding", show: "faculty", link: "/employee/phd/funding/" },
          { icon: "profile", key: "PList", name: "List", link: "/employee/phd/overview/" }
        ]
      },
      { icon: "bar-chart", name: "Polls", show: "faculty", link: "/employee/polls/" },
      { icon: "schedule", name: "Positions", show: "faculty", cards: [(<PositionCard {...props} />)], link: "/employee/positions/" },
      {
        icon: "team", name: "Res. Apprenticeship", items: [
          { icon: "like", name: "Nomination Form", link: "/employee/apprenticeship/nomination/" },
          { icon: "bulb", name: "Project Submission", show: "faculty", link: "/employee/apprenticeship/projectsubmission/" },
          { icon: "swap", key: "RAList", name: "Application Review", show: "faculty", link: "/employee/apprenticeship/applicationreview/" },
        ]
      },
      { icon: "switcher", key: "Workflows", name: <Badge count={props.workflows ? props.workflows.length : 0} offset={[10, 0]}>Workflows</Badge>, link: "/employee/workflow/" },
    ];
  }

  render() {
    const sidemenu = this.renderSider(Employee.submodules(this.props));

    var year = this.props.semester_list[this.props.semesters[0]].name;
    year = parseInt(year.substring(year.length - 4));

    return (
      <Layout>
        {sidemenu}
        <Layout style={{ padding: '0 0 0 0', marginTop: this.top_margin(), minHeight: "calc(100vh - " + this.top_margin() + "px)" }}>
          <Sentry.ErrorBoundary fallback={myFallback} beforeCapture={scope => { scope.setUser({ id: this.props.user.id }) }}>
            <RouterSwitch>
              <Route path="/employee/mailinglists/" render={(props) => <MailinglistsList {...this.props} {...props} />} />
              <Route path='/employee/fund/:fund_id' render={(props) => <FundDetails {...this.props} {...props} />} />
              <Route path='/employee/finance/fund/' render={(props) => <FinanceFundList {...this.props} {...props} />} />
              <Route path='/employee/fund/' render={(props) => <FundList {...this.props} {...props} />} />
              <Route path='/employee/workflow/' render={(props) => <WorkflowProcess {...this.props} {...props} />} />
              <Route path="/employee/phd/overview/" render={(props) => <PhDStudentList {...this.props} {...props} />} />
              <Route path="/employee/phd/funding/" render={(props) => <PhDStudentFundingOverview {...this.props} {...props} />} />
              <Route path="/employee/phd/review/" render={(props) => <PhDAdvisorReview {...this.props} {...props} />} />
              <Route path="/employee/phd/:phdstudent_id/" render={(props) => <PhDStudent {...this.props} {...props} />} />
              <Route path="/employee/phd/load/" render={(props) => <PhDStudentTeachingLoadList {...this.props} {...props} />} />
              <Route path="/employee/polls/:poll_id" render={(props) => <PollVote {...this.props} {...props} poll={props.match.params.poll_id} />} />
              <Route path="/employee/polls/" render={(props) => <PollList {...this.props} {...props} />} />
              <Route path="/employee/apprenticeship/nomination/" render={(props) => <ApprenticeNomination {...this.props} {...props} />} />
              <Route path="/employee/apprenticeship/projectsubmission/" render={(props) => <FacultyProjectSubmission {...this.props} {...props} />} />
              <Route path="/employee/apprenticeship/applicationreview/" render={(props) => <FacultyApplicationReview {...this.props} {...props} />} />
              <Route path="/employee/positions/:position_id" render={(props) => <Position key={props.match.params.position_id} {...this.props} {...props} />} />
              <Route path="/employee/positions/" render={(props) => <PositionList {...this.props} {...props} />} />
              <Route path="/employee/" render={(props) => <EmployeeHome {...this.props} {...props} submodules={Employee.submodules(this.props)} />} />
            </RouterSwitch>
          </Sentry.ErrorBoundary>
        </Layout>
      </Layout>
    );
  }
}


// ----- FACULTY MODULE -----

class Faculty extends AppModule {
  static show_merit_committee(year, props) {
    try {
      const committee = Object.values(props.committee_list).find(c => c.name == "TT/T Merit Committee");
      const members = Object.values(props.committeeyear_list).find(c => c.committee == committee.id && c.year == (year + 1)).members;
      return members.find(cm => cm.instructor == props.user.instructor) != null;
    } catch (err) { }
    return false;
  }

  static show_ntt_merit_committee(year, props) {
    try {
      const committee = Object.values(props.committee_list).find(c => c.name == "Teaching Faculty Merit Committee");
      const members = Object.values(props.committeeyear_list).find(c => c.committee == committee.id && c.year == (year + 1)).members;
      return members.find(cm => cm.instructor == props.user.instructor) != null;
    } catch (err) { }
    return false;
  }

  static show_ntt_merit_dean(year, props) {
    try {
      const deans = Object.values(props.position_list).filter(c => c.name.includes("Associate Dean of Teaching Faculty")).map(p => p.id);
      const deanyears = Object.values(props.positionyear_list).filter(c => deans.includes(c.position) && c.year == (year + 1));
      const holders = deanyears.reduce((a, r) => a.concat(r.holders), []);
      return holders.includes(props.user.instructor);
    } catch (err) { }
    return false;
  }

  static show_merit_dean(year, props) {
    try {
      const deans = Object.values(props.position_list).filter(c => c.name.includes("Senior Associate Dean for Academic Affairs") || c.name.includes("Associate Dean for Faculty")).map(p => p.id);
      const deanyears = Object.values(props.positionyear_list).filter(c => deans.includes(c.position) && c.year == (year + 1));
      const holders = deanyears.reduce((a, r) => a.concat(r.holders), []);
      return holders.includes(props.user.instructor);
    } catch (err) { }
    return false;
  }

  static submodules(props) {
    const instructor = props.user.instructor;

    var show_merit = false;

    // Calculates the calendar year 
    var year = props.semester_list[props.semesters[0]].name;
    year = parseInt(year.substring(year.length - 4));

    // Calculates the previous calendar year from academic year  
    var teaching_merit_year = props.semester_list[props.semesters[0]].year - 1;

    const show_instructor_stuff = (instructor != null);
    var show_tt_stuff = false;
    var show_ttt_stuff = false;
    var show_ntt_stuff = false;

    // Only show if they are tenure-track and have a Khoury tenure home as their most recent appointment
    if (instructor && props.instructor_list[instructor].ranks && props.instructor_list[instructor].ranks?.length > 0) {
      const ranks = props.instructor_list[instructor].ranks;
      if (ranks[ranks.length - 1].tenure_home && (props.college_list[ranks[ranks.length - 1].tenure_home].code == "CS")) {
        if (props.instructorrank_list[ranks[ranks.length - 1].rank].subtype.mytype.mytype == "Tenured/Tenure-Track") {
          show_ttt_stuff = true;
        }
        if (props.instructorrank_list[ranks[ranks.length - 1].rank].subtype.subtype == "Tenure-Track") {
          show_tt_stuff = true;
        }
      }
      if (props.instructorrank_list[ranks[ranks.length - 1].rank].subtype.mytype.mytype == "Full-Time Non-Tenure-Track") {
        show_ntt_stuff = true;
      }
    }

    var all_cards = [(<InstructorRanksCard {...props} instructor={instructor} show={show_instructor_stuff} />), (<CommitteeCard {...props} show={show_instructor_stuff} />), (<MentorMenteeCard {...props} show={show_instructor_stuff} />)];
    const tt_cards = [(<TenureTrackCard {...props} instructor={instructor} show={show_tt_stuff} />), (<SabbaticalCard {...props} instructor={instructor} />)];

    if (instructor && props.instructor_list[instructor].ranks && props.instructor_list[instructor].ranks.find(r => r.teaching_schedule)) {
      all_cards = all_cards.concat([(<TeachingScheduleCard {...props} instructor={instructor} show={show_instructor_stuff} />)])
    }

    if (instructor && props.instructor_list[instructor].ranks && props.instructor_list[instructor].ranks.find(r => r.service_schedule)) {
      all_cards = all_cards.concat([(<ServiceScheduleCard {...props} instructor={instructor} show={show_instructor_stuff} />)])
    }

    return [
      { icon: "copy", name: "Bylaws", link: "/faculty/khoury-bylaws/" },
      {
        icon: "team", name: "Committees", show: "faculty", cards: all_cards, items: [
          { icon: "ordered-list", key: "CList", name: "List", link: "/faculty/committees/" },
          { icon: "like", name: "Preferences", show: "faculty", link: "/faculty/committees/preferences/" }
        ]
      },
      { icon: "import", name: "Courtesy", link: "/faculty/courtesy/" },
      { icon: "user", name: "Instructors", link: "/faculty/instructors/", show: "admin" },
      { icon: "bars", name: "Loads", link: "/faculty/loads", show: "admin" },
      {
        icon: "trophy", name: "Merit", show: show_ttt_stuff, cards: tt_cards, items: [
          { icon: "form", name: "Form", link: "/faculty/merit/form/" },
          { icon: "team", name: "Committee", link: "/faculty/merit/committee/", show: Faculty.show_merit_committee(year, props) },
          { icon: "safety", name: "Dean", link: "/faculty/merit/dean/", show: Faculty.show_merit_dean(year, props) },
        ]
      },
      {
        icon: "file-sync", name: "Teaching Merit", show: show_ntt_stuff, items: [
          { icon: "form", name: "Retrospective", link: "/faculty/merit/teaching/retrospective/" },
          { icon: "team", name: "Committee", link: "/faculty/merit/teaching/committee/", show: Faculty.show_ntt_merit_committee(teaching_merit_year, props) },
          { icon: "safety", name: "Reviewer Form", link: "/faculty/merit/teaching/review/", show: Faculty.show_ntt_merit_committee(teaching_merit_year, props) },
          { icon: "diff", name: "Dean Review", link: "/faculty/merit/teaching/dean/", show: Faculty.show_ntt_merit_dean(teaching_merit_year, props) },
        ]
      },
      {
        icon: "crown", name: "Petitions", show: "faculty", items: [
          { icon: "money-collect", name: "Course Buyouts", show: "faculty", link: "/faculty/buyout/" },
          { icon: "plus", name: "Course Overload", link: "/faculty/overloadrequest/" },
          { icon: "fork", name: "Parental Relief", link: "/faculty/parentalreduction/" },
          { icon: "bulb", name: "Research Reductions", show: show_ntt_stuff, link: "/faculty/researchreduction/" },
          { icon: "swap", name: "Transaction Move", link: "/faculty/transactionmove/" },
          { icon: "money-collect", name: "Summer Support", link: "/faculty/summersupport/" },
          { icon: "dollar", name: "Equipment Refresh", show: show_ntt_stuff, link: "/faculty/equipmentrefresh/" },
        ]
      },
      // { icon: "radar-chart", name: "Networked Devices", link: "/faculty/networkeddevice/" },
    ];
  }

  render() {
    const sidemenu = this.renderSider(Faculty.submodules(this.props));

    var year = this.props.semester_list[this.props.semesters[0]].name;
    year = parseInt(year.substring(year.length - 4));

    var teaching_merit_year = this.props.semester_list[this.props.semesters[0]].year - 1;

    return (
      <Layout>
        {sidemenu}
        <Layout style={{ padding: '0 0 0 0', marginTop: this.top_margin(), minHeight: "calc(100vh - " + this.top_margin() + "px)" }}>
          <Sentry.ErrorBoundary fallback={myFallback} beforeCapture={scope => { scope.setUser({ id: this.props.user.id }) }}>
            <RouterSwitch>
              <Route path="/faculty/buyout/" render={(props) => <BuyoutRequestOverview {...this.props} {...props} />} />
              <Route path="/faculty/committees/preferences/" render={(props) => <CommitteePreferences {...this.props} {...props} />} />
              <Route path="/faculty/committees/:committee_id" render={(props) => <Committee key={props.match.params.committee_id} {...this.props} {...props} />} />
              <Route path="/faculty/committees/" render={(props) => <CommitteeList {...this.props} {...props} />} />
              {this.permission("can", "admin") && <Route path="/faculty/instructors/:instructor_id" render={(props) => <Instructor key={props.match.params.instructor_id} {...this.props} {...props} />} />}
              <Route path="/faculty/courtesy/" render={(props) => <CourtesyList {...this.props} {...props} />} />
              {this.permission("can", "admin") && <Route path="/faculty/instructors/" render={(props) => <InstructorList {...this.props} {...props} />} />}
              {this.permission("can", "admin") && <Route path="/faculty/loads/" render={(props) => <LoadList {...this.props} {...props} />} />}
              <Route path="/faculty/merit/form/" render={(props) => <Merit {...this.props} {...props} />} />
              <Route path="/faculty/merit/teaching/retrospective/" render={(props) => <SelfReview {...this.props} {...props} />} />
              {Faculty.show_ntt_merit_committee(teaching_merit_year, this.props) && <Route path="/faculty/merit/teaching/committee/" render={(props) => <CommitteeOverview {...this.props} {...props} />} />}
              {Faculty.show_ntt_merit_committee(teaching_merit_year, this.props) && <Route path="/faculty/merit/teaching/review/" render={(props) => <TeachingFacultyMeritReview {...this.props} {...props} />} />}
              {Faculty.show_ntt_merit_dean(teaching_merit_year, this.props) && <Route path="/faculty/merit/teaching/dean/" render={(props) => <DeanMeritReview {...this.props} {...props} />} />}
              {Faculty.show_merit_committee(year, this.props) && <Route path="/faculty/merit/committee/" render={(props) => <MeritList {...this.props} {...props} review_type="Committee" />} />}
              {Faculty.show_merit_dean(year, this.props) && <Route path="/faculty/merit/dean/" render={(props) => <MeritList {...this.props} {...props} review_type="Dean" />} />}
              <Route path="/faculty/parentalreduction/" render={(props) => <ParentalReductionRequestOverview {...this.props} {...props} />} />
              <Route path="/faculty/researchreduction/" render={(props) => <ResearchReductionRequestOverview {...this.props} {...props} />} />
              <Route path="/faculty/overloadrequest/" render={(props) => <CourseOverloadOverview {...this.props} {...props} />} />
              <Route path="/faculty/transactionmove/" render={(props) => <TransactionMoveOverview {...this.props} {...props} />} />
              <Route path="/faculty/teachingload/" render={(props) => <PhDStudentTeachingLoadList {...this.props} {...props} selected_instructor={this.props.user.instructor} />} />
              <Route path="/faculty/summersupport/" render={(props) => <SummerSupportProposalOverview {...this.props} {...props} />} />
              <Route path="/faculty/equipmentrefresh/" render={(props) => <EquipmentRefreshOverview {...this.props} {...props} />} />
              <Route path="/faculty/networkeddevice/" render={(props) => <NetworkedDeviceListOverview {...this.props} {...props} />} />
              <Route path='/faculty/khoury-bylaws/' render={() => { window.open('https://github.khoury.northeastern.edu/faculty/khoury-bylaws', '_blank'); return <Redirect to="/faculty" /> }} />

              {/* Redirect to new employee tab from old faculty links */}

              <Route path='/faculty/fund/:fund_id' render={(props) => <CustomRedirect {...this.props} {...props} to={`/employee/fund/${props.match.params.fund_id}`} />} />
              <Route path="/faculty/fund/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/fund/" />} />
              <Route path="/faculty/mailinglists/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/mailinglists/" />} />
              <Route path="/faculty/phd/review/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/phd/review/" />} />
              <Route path="/faculty/phd/funding/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/phd/funding/" />} />
              <Route path="/faculty/phd/overview/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/phd/overview/" />} />
              <Route path="/faculty/phd/:phdstudent_id/" render={(props) => <CustomRedirect {...this.props} {...props} to={`/employee/phd/${props.match.params.phdstudent_id}/`} />} />
              <Route path="/faculty/polls/:poll_id" render={(props) => <CustomRedirect {...this.props} {...props} to={`/employee/polls/${props.match.params.poll_id}`} />} />
              <Route path="/faculty/polls/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/polls/" />} />
              <Route path="/faculty/positions/:position_id" render={(props) => <CustomRedirect {...this.props} {...props} to={`/employee/positions/${props.match.params.position_id}`} />} />
              <Route path="/faculty/positions/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/positions/" />} />
              <Route path="/faculty/apprenticeshipnomination/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/apprenticeshipnomination/" />} />
              <Route path="/faculty/applicationreview/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/applicationreview/" />} />
              <Route path="/faculty/workflow/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/workflow/" />} />

              <Route path="/faculty/" render={(props) => <FacultyHome {...this.props} {...props} submodules={Faculty.submodules(this.props)} />} />
            </RouterSwitch>
          </Sentry.ErrorBoundary>
        </Layout>
      </Layout>
    );
  }
}

class ModuleHome extends AppComponent {
  check_show = (e) => {
    if (!("show" in e)) { return true; }
    if (typeof e.show == "boolean") { return e.show; }
    if (typeof e.show == "string") { return this.permission("can", e.show); }
    if (typeof e.show == "object") { return e.show.reduce((r, a) => r || this.permission("can", a), false); }
    if (typeof e.show == "function") { return e.show(this); }
    return false;
  }

  get_cards = () => {
    const { submodules } = this.props;
    const chars = [<TodoCard {...this.props} />].concat(submodules.filter(el => el.cards && this.check_show(el)).map(el => el.cards).flat().filter(el => el != null));

    return (
      <List
        grid={this.grid}
        dataSource={chars}
        renderItem={(item, idx) => (<List.Item key={idx}>{item}</List.Item>)}
      />
    )
  };
}

class Home extends ModuleHome {
  render() {
    return (
      <Layout style={{ padding: '0 0 0 0', marginTop: this.top_margin(), minHeight: "calc(100vh - " + this.top_margin() + "px)" }}>
        <Content {...this.props} title={"Khoury Administration"} breadcrumbs={[{ text: "Home" }]}>
          Welcome to the Khoury Administration site!  Use the links above to navigate to different modules; the cards below provide a brief overview of the features available to you.
          <Divider orientation="left">Your snapshot</Divider>
          {this.get_cards()}
        </Content>
      </Layout>
    );
  }
}

class EmployeeHome extends ModuleHome {
  render() {
    return (
      <Content {...this.props} title={"Employee Services"} breadcrumbs={[{ text: "Employee" }]}>
        This page contains links to all available employee services; please use the links below or on the sidebar to navigate.
        <Divider orientation="left">Your snapshot</Divider>
        {this.get_cards()}
      </Content>
    )
  }
}

class FacultyHome extends ModuleHome {
  render() {
    return (
      <Content {...this.props} title={"Faculty Services"} breadcrumbs={[{ text: "Faculty" }]}>
        This page contains links to all available faculty services; please use the links below or on the sidebar to navigate.
        <Divider orientation="left">Your snapshot</Divider>
        {this.get_cards()}
      </Content>
    )
  }
}

class StudentHome extends ModuleHome {
  render() {
    return (
      <Content {...this.props} title={"Student Services"} breadcrumbs={[{ text: "Students" }]}>
        This page contains links to all available Students services; please use the links below or on the sidebar to navigate.
        <Divider orientation="left">Your snapshot</Divider>
        {this.get_cards()}
      </Content>
    )
  }
}

class StaffHome extends ModuleHome {
  render() {
    return (
      <Content {...this.props} title={"Staff Services"} breadcrumbs={[{ text: "Staff" }]}>
        This page contains links to all available Staff services; please use the links below or on the sidebar to navigate.
        <Divider orientation="left">Your snapshot</Divider>
        {this.get_cards()}
      </Content>
    )
  }
}

class AdminHome extends ModuleHome {
  render() {
    return (
      <Content {...this.props} title={"Admin Services"} breadcrumbs={[{ text: "Admin" }]}>
        This page contains links to all available Admin services; please use the links below or on the sidebar to navigate.
        <Divider orientation="left">Your snapshot</Divider>
        {this.get_cards()}
      </Content>
    )
  }
}

class TeachingHome extends ModuleHome {
  render() {
    return (
      <Content {...this.props} title={"Teaching Services"} breadcrumbs={[{ text: "Teaching" }]}>
        This page contains links to all available Teaching services; please use the links below or on the sidebar to navigate.
        <Divider orientation="left">Your snapshot</Divider>
        {this.get_cards()}
      </Content>
    )
  }
}

class CommitteeList extends AppComponent {
  render() {
    const { semester, campus } = this.props;

    const committeeyears = this.committeeyear_list().filter(cy => cy.year == this.get_year());
    committeeyears.forEach(c => c.members.sort((a, b) => this.instructor_comparator(this.get_instructor(a.instructor), this.get_instructor(b.instructor))));

    const columns = [
      {
        title: 'Name',
        key: 'name',
        width: 250,
        render: (text, record, idx) => this.link_committee(record.committee),
      }, {
        title: this.print_year(this.get_year()) + ' Members',
        key: 'members',
        render: (text, record, idx) => add_dividers(record.members.map(this.print_committeemembership_instructor)),
      }];

    return (
      <Content {...this.props} title={"Committee List"} breadcrumbs={[{ link: "/faculty", text: "Faculty" }, { text: "Committees" }]}>
        <p>Below is the list of all committees that were empaneled during the selected year.</p>
        <Table {...this.props} dataSource={committeeyears} columns={columns} scroll={{ x: 800 }} bordered={false} pagination={false} size="small" rowKey="id" key={"table-all"} />
      </Content>
    );
  }
}

class PositionList extends AppComponent {
  render() {

    const positionyears = this.positionyear_list().filter(cy => cy.year == this.get_year());
    positionyears.forEach(c => c.holders.sort((a, b) => this.instructor_comparator(this.get_instructor(a), this.get_instructor(b))));

    const columns = [
      {
        title: 'Name',
        key: 'name',
        render: (text, record, idx) => this.link_position(record.position),
      }, {
        title: this.print_year(this.get_year()) + ' Holder(s)',
        key: 'holders',
        render: (text, record, idx) => add_dividers(record.holders.map(this.print_instructor)),
      }];

    return (
      <Content {...this.props} title={"Position List"} breadcrumbs={[{ link: "/employee", text: "Employee" }, { text: "Positions" }]}>
        <p>Below is the list of all positions that were held during the selected year.</p>
        <Table {...this.props} dataSource={positionyears} columns={columns} scroll={{ x: 800 }} bordered={false} pagination={false} size="small" rowKey="id" key={"table-all"} />
      </Content>
    );
  }
}

class CourtesyList extends AppComponent {
  render() {
    const { semesters } = this.props;

    var myinstructors = this.instructor_list().filter(el => this.get_from_history(el.courtesyappointments, this.get_semester(semesters[0])));

    var columns = [
      {
        title: 'Name',
        key: 'name',
        width: 200,
        render: (text, record, idx) => this.link_full_instructor(record.id),
      }, {
        title: 'NUID',
        key: 'nuid',
        width: 100,
        render: (text, record, idx) => format_nuid(record.nuid),
      }, {
        title: 'Rank',
        key: 'rank',
        render: (text, record, idx) => this.get_from_history(record.ranks, this.get_semester(semesters[0])) ? this.print_instructorrank(this.get_from_history(record.ranks, this.get_semester(semesters[0])).rank) : null,
      }, {
        title: 'Appointment Start',
        key: 'start',
        render: (text, record, idx) => this.print_semester(this.get_from_history(record.courtesyappointments, this.get_semester(semesters[0])).start),
      }, {
        title: 'Appointment End',
        key: 'end',
        render: (text, record, idx) => this.print_semester(this.get_from_history(record.courtesyappointments, this.get_semester(semesters[0])).end),
      }, {
        title: 'Can Advise?',
        key: 'advise',
        width: 70,
        align: 'center',
        render: (text, record, idx) => this.get_from_history(record.courtesyappointments, this.get_semester(semesters[0])).can_advise ? get_check() : get_nocheck(),
      }];

    return (
      <Content {...this.props} title={"Courtesy Appointment List"} breadcrumbs={[{ link: "/faculty", text: "Faculty" }, { text: "Courtesy Appointments" }]}>
        <p>Below is a list of all courtesy appointments in Khoury active during the {this.props.semester} semester.</p>

        <Table {...this.props} dataSource={myinstructors} columns={columns} bordered={false} pagination={false} size="small" rowKey="id" key={"table"} />
      </Content>
    );
  }
}

class InstructorList extends AppComponent {
  state = {
    show_inactive: false,
    show_allcampuses: false,

    endpoint_mentor: "/api/faculty/mentor/",
    mentors: [],
    loading_mentors: true,
  };

  handleToggle = prop => (enable) => {
    this.setState({ [prop]: enable });
  }

  componentDidMount() {
    this.getData();
    this.setState({ show_allcampuses: this.props.user.groups.map(el => el.name).includes("admin") })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    }
  }

  getData = () => {
    this.doGet(this.state.endpoint_mentor, data => this.setState({ mentors: data, loading_mentors: false }));
  }

  get_sabbatical_leave_color = (i, idx) => {
    const { semesters } = this.props;
    const sabbatical = i.sabbaticals ? i.sabbaticals.find(s => (this.get_semester(s.start).code <= this.get_semester(semesters[0]).code) && (this.get_semester(s.end).code >= this.get_semester(semesters[0]).code)) : null;

    if (sabbatical) {
      return sabbatical.approved ? "sabbatical-approved" : "sabbatical-pending";
    } else {
      const leave = i.leaves ? i.leaves.find(s => (this.get_semester(s.start).code <= this.get_semester(semesters[0]).code) && (this.get_semester(s.end).code >= this.get_semester(semesters[0]).code)) : null;

      return leave ? "leave" : null;
    }
  }

  render() {
    const { campus, semesters } = this.props;
    const { show_inactive, show_allcampuses, group_by_rank, mentors } = this.state;
    var myinstructors = this.instructor_list().filter(el => (show_inactive || this.get_rank_from_history(el.ranks, this.get_semester(semesters[0]))) && (show_allcampuses || el.campus == campus) && (this.get_from_history(el.ranks, this.get_semester(semesters[0])) && this.get_from_history(el.ranks, this.get_semester(semesters[0])).appointment.find(ad => this.get_college(ad.college).code == "CS")));
    var mytypes = this.instructortype_list();

    var columns = [
      {
        title: 'Name',
        key: 'name',
        width: 175,
        render: (text, record, idx) => this.link_full_instructor(record.id),
      }, {
        title: 'NUID',
        key: 'nuid',
        width: 100,
        render: (text, record, idx) => format_nuid(record.nuid),
      }, {
        title: 'Area(s)',
        key: 'area',
        render: (text, record, idx) => record.areas?.length > 0 ? add_brs(record.areas.map(a => <Tag key={a}>{this.get_area(a).abbrv}</Tag>)) : null,
      }, {
        title: 'Campus',
        key: 'campus',
        render: (text, record, idx) => this.print_campus(record.campus),
      }, {
        title: 'Joint?',
        key: 'joint',
        render: (text, record, idx) => this.print_joint_appoinments(this.get_from_history(record.ranks, this.get_semester(semesters[0])), false),
      }, {
        title: 'Tenure Home',
        key: 'tenure',
        render: (text, record, idx) => this.print_college_tag(this.get_from_history(record.ranks, this.get_semester(semesters[0])).tenure_home),
      }, {
        title: 'Third-Year Review',
        key: 'third-year',
        render: (text, record, idx) => this.print_year(this.get_third_year_review(record.id)),
      }, {
        title: 'Tenure Review',
        key: 'tenure-review',
        render: (text, record, idx) => this.print_year(this.get_tenure_review(record.id)),
      }, {
        title: 'Next Sabbatical',
        key: 'sabbatical',
        render: (text, record, idx) => this.get_next_sabbatical(record.id),
      }, {
        title: 'Mentor',
        key: 'mentor',
        width: 150,
        render: (text, record, idx) => mentors.find(m => m.year == this.get_year() && m.mentee == record.id) ? this.link_full_instructor(mentors.find(m => m.year == this.get_year() && m.mentee == record.id).mentor) : null,
      }, {
        title: 'Mentee(s)',
        key: 'mentees',
        width: 150,
        render: (text, record, idx) => add_brs(mentors.filter(m => m.year == this.get_year() && m.mentor == record.id).map(m => this.link_full_instructor(m.mentee))),
      }, {
        title: 'Teaching Schedule',
        key: 'teaching_schedule',
        width: 100,
        render: (text, record, idx) => {
          const appointment = this.get_from_history(record.ranks, this.get_semester(semesters[0]));
          return appointment.teaching_schedule ? appointment.teaching_schedule.name : null;
        },
      }, {
        title: 'Service Load',
        key: 'service_load',
        width: 100,
        align: 'right',
        render: (text, record, idx) => {
          const positions = this.positionyear_list().filter(cy => cy.holders.find(i => i == record.id) && cy.year == this.get_year());
          const committees = this.committeeyear_list().filter(cy => cy.members.find(i => i.instructor == record.id) && cy.year == this.get_year()).map(el => el.members.find(i => i.instructor == record.id));
          return print_load_component(positions.reduce((r, a) => r + a.loadcount, 0) + committees.reduce((r, a) => r + a.loadcount, 0));
        },
      }, {
        title: 'Service',
        key: 'service',
        width: 320,
        render: (text, record, idx) => {
          const positions = this.positionyear_list().filter(cy => cy.holders.find(i => i == record.id) && cy.year == this.get_year());
          const committees = this.committeeyear_list().filter(cy => cy.members.find(i => i.instructor == record.id) && cy.year == this.get_year()).map(el => el.members.find(i => i.instructor == record.id));
          return add_brs(positions.map(this.print_positionyear).concat(committees.map(this.print_committeemembership_committee)));
        },
      }, {
        title: 'Service Schedule',
        key: 'service_schedule',
        width: 100,
        render: (text, record, idx) => {
          const appointment = this.get_from_history(record.ranks, this.get_semester(semesters[0]));
          return appointment.service_schedule ? appointment.service_schedule.name : null;
        },
      },
    ];

    if (!show_allcampuses) {
      columns = columns.filter(c => c.key != "campus");
    }

    return (
      <Content {...this.props} title={"Instructor List"} breadcrumbs={[{ link: "/faculty", text: "Faculty" }, { text: "Instructors" }]}>
        <p>Below is a list of all active instructors in Khoury, as of the {this.props.semester} semester.  Faculty who are on sabbatical are shown highlighted (yellow means their sabbatical has been requested, and red means their sabbatical has been approved).</p>
        <p style={{ textAlign: 'right', width: '100%' }} >
          <Text strong> Show all campuses &nbsp;</Text>
          <Switch checked={show_allcampuses} onChange={e => this.setState({ show_allcampuses: e })} />
        </p>

        <div><CustomTabs {...this.props} default_Active_Key="1">
          {mytypes.map(t => (
            <TabPane tab={this.print_instructortype(t.id)} key={t.id}>
              {this.instructorrank_list().filter(r => r.subtype.mytype.id == t.id).map(r => (
                <React.Fragment key={r.id}>
                  <Divider key={"div-" + r.id} orientation="left">{this.print_instructorrank(r.id) + " - (" + myinstructors.filter(c => this.get_rank_from_history(c.ranks, this.get_semester(semesters[0])) == r.id).length} total, {myinstructors.filter(c => this.get_rank_from_history(c.ranks, this.get_semester(semesters[0])) == r.id && this.get_from_history(c.ranks, this.get_semester(semesters[0])).appointment.length > 1).length} joint, {format_decimal(myinstructors.map(c => this.get_rank_from_history(c.ranks, this.get_semester(semesters[0])) == r.id ? (this.get_from_history(c.ranks, this.get_semester(semesters[0])).appointment.find(a => this.get_college(a.college).code == "CS")).fraction : 0).reduce((a, b) => a + b, 0), 2)} FTE)</Divider>

                  <Table {...this.props} scroll={{ x: true }} dataSource={myinstructors.filter(c => this.get_rank_from_history(c.ranks, this.get_semester(semesters[0])) == r.id)} rowClassName={this.get_sabbatical_leave_color} columns={r.subtype.mytype.mytype == "Staff" ? columns.filter(c => ["name", "nuid", "campus"].includes(c.key)) : columns.filter(c => (r.subtype.subtype == "Tenure-Track") || ((c.key != "tenure-review") && (c.key != "third-year"))).filter(c => (r.subtype.mytype.mytype == "Tenured/Tenure-Track") || ((c.key != "tenure") && (c.key != "sabbatical")))
                  } bordered={false} pagination={false} size="small" rowKey="id" key={"table-" + r.id} />
                </React.Fragment>
              ))}
            </TabPane>)
          )}
        </CustomTabs></div>
      </Content>
    );
  }
}

// ----- STAFF MODULE -----

class Staff extends AppModule {

  static submodules(props) {
    const fundviewer = props.fund_list ? Object.values(props.fund_list).some(el => el.viewers.includes(props.user.employee)) || Object.values(props.fund_list).some(f => f.owners.find(fo => fo.owner == props.user.employee)) : false;
    const cards = props.semesters.map(e => (<TAHoursStaffCard {...props} single_semester={e} />));
    return [
      { icon: "read", name: "Charters", show: ["ugradadmin", "gradadmin"], link: "/staff/charter/" },
      { icon: "highlight", name: "GFC", show: "hr", link: "/staff/gfc/" },
      { icon: "video-camera", name: "IAs", show: () => props.semester_list[props.semesters[0]].nuflex, link: "/staff/ia/" },
      { icon: "trophy", name: "Merit", show: "grants", link: "/staff/merit/" },
      {
        icon: "form", name: "Petitions", show: ["ugradadmin", "gradadmin", "advising"], items: [
          { icon: "plus", name: "UG PlusOne", link: "/staff/plusone/", show: ["ugradadmin", "advising"] },
          { icon: "form", name: "UG Closed Course", link: "/staff/closedcourse/", show: ["ugradadmin", "advising"] },
          { icon: "line-chart", name: "MS Course Survey", link: "/staff/coursesurvey/", show: ["gradadmin"] },
        ]
      },
      {
        icon: "team", name: "TAs", cards: cards, show: ["ugradadmin", "gradadmin", "hr"], items: [
          { icon: "select", name: "Assign", show: ["ugradadmin", "gradadmin"], link: "/staff/ta/assign/" },
          { icon: "user-add", name: "Hire", show: "hr", link: "/staff/ta/hire/" },
          { icon: "line-chart", name: "Overview", show: ["ugradadmin", "gradadmin", "hr"], link: "/staff/ta/overview/" },
          { icon: "audit", name: "Audit", show: ["ugradadmin", "gradadmin", "hr"], link: "/staff/ta/audit/" },
          { icon: "usergroup-delete", name: "HR", link: "/staff/ta/hr/", show: () => (props.user.is_staff) || (props.user.groups.map(el => el.name).includes("hr")) }
        ]
      },
      //      { icon: "calculator", name: "Teaching Load", show: "academic", link: "/staff/phd/load/" },
    ];
  }

  render() {
    const sidemenu = this.renderSider(Staff.submodules(this.props));
    return (
      <Layout>
        {sidemenu}
        <Layout style={{ padding: '0 0 0 0', marginTop: this.top_margin(), minHeight: "calc(100vh - " + this.top_margin() + "px)" }}>
          <Sentry.ErrorBoundary fallback={myFallback} beforeCapture={scope => { scope.setUser({ id: this.props.user.id }) }}>
            <RouterSwitch>
              <Route path='/staff/closedcourse/' render={(props) => <PetitionStaff {...this.props} {...props} endpoint={"/api/petition/ug/closedcourse/"} />} />
              <Route path='/staff/charter/' render={(props) => <CharterList {...this.props} {...props} />} />
              <Route path='/staff/plusone/' render={(props) => <PlusOneResponses {...this.props} {...props} />} />
              <Route path='/staff/coursesurvey/' render={(props) => <MSCourseSurveyOverview {...this.props} {...props} />} />
              <Route path='/staff/gfc/' render={(props) => <GFCList {...this.props} {...props} />} />
              <Route path='/staff/ia/' render={(props) => <IAList {...this.props} {...props} />} />
              <Route path='/staff/merit/' render={(props) => <MeritStaffList {...this.props} {...props} />} />
              <Route path="/staff/ta/view/:ta_id/" render={(props) => <TAIndividual {...this.props} {...props} key={props.match.params.ta_id} />} />
              <Route path="/staff/ta/assign/" render={(props) => <TAAssign {...this.props} {...props} />} />
              <Route path="/staff/ta/hire/" render={(props) => <TAHire {...this.props} {...props} />} />
              <Route path="/staff/ta/overview/" render={(props) => <TACollegeOverview {...this.props} {...props} />} />
              {(this.props.user.is_staff || this.props.user.groups.map(el => el.name).includes("hr")) && <Route path="/staff/ta/hr/" render={(props) => <TAHR {...this.props} {...props} />} />}
              <Route path="/staff/ta/audit/" render={(props) => <TAAuditReview {...this.props} {...props} />} />


              {/* Redirect to new employee tab from old staff links */}

              <Route path="/staff/apprenticeshipnomination/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/apprenticeshipnomination/" />} />
              <Route path="/staff/workflow/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/workflow/" />} />
              <Route path="/staff/phd/:phdstudent_id/" render={(props) => <CustomRedirect {...this.props} {...props} to={`/employee/phd/${props.match.params.phdstudent_id}/`} />} />
              <Route path="/staff/phd/overview/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/phd/overview/" />} />
              <Route path="/staff/phd/load/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/phd/load/" />} />
              <Route path="/staff/mailinglists/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/mailinglists/" />} />
              <Route path='/staff/fund/:fund_id' render={(props) => <CustomRedirect {...this.props} {...props} to={`/employee/fund/${props.match.params.fund_id}`} />} />
              <Route path="/staff/finance/fund/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/finance/fund/" />} />
              <Route path="/staff/fund/" render={(props) => <CustomRedirect {...this.props} {...props} to="/employee/fund/" />} />
              <Route path="/staff/" render={(props) => <StaffHome {...this.props} {...props} submodules={Staff.submodules(this.props)} />} />
            </RouterSwitch>
          </Sentry.ErrorBoundary>
        </Layout>
      </Layout>
    );
  }
}

class GFCList extends AppComponent {
  state = {
    endpoint: "/api/schedule/",
    loading: true,
    data: [],
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    }
  }

  getSemesters = () => {
    const { semester, semesters } = this.props;

    const year = this.get_semester(semesters[0]).year;
    return this.semester_list().filter(s => s.year < year && s.year >= year - 4);
  }

  getYears = () => {
    return [...new Set(this.getSemesters().map(s => s.year))].sort();
  }

  getInstructors = () => {
    return this.instructor_list().filter(i => {
      const rank = this.get_rank_from_history(i.ranks, this.get_semester(this.props.semesters[0]));
      return rank && (this.get_instructorrank(rank).subtype.mytype.mytype == "Part-time");
    });
  }

  getData = () => {
    const { semester, semesters } = this.props;

    this.doGet(this.state.endpoint + "?semester=" + this.getSemesters().map(s => s.id).join(',') + "&instructors__employee__faculty=" + this.getInstructors().map(i => i.id).join(",") + "&deleted=False&campus=" + this.campus_list().find(c => c.name == "Boston").id,
      data => this.setState({ data: data, loading: false }));
  }

  getCanonicalCourse = (c) => {
    const course = this.get_course(c);
    return course.next ? course.next : c;
  }

  getCoursesForInstructor = (instructor) => {
    const { data } = this.state;
    return [...new Set(data.filter(s => s.instructors.includes(instructor)).map(s => this.getCanonicalCourse(s.course)))];
  }

  render() {
    const { semesters } = this.props;
    const { loading, data } = this.state;

    var columns = [
      {
        title: 'Name',
        key: 'name',
        width: 200,
        render: (text, record, idx) => {
          return {
            children: this.link_full_instructor(record.instructor),
            props: { rowSpan: record.index == 0 ? record.total : 0 },
          };
        },
      }, {
        title: 'Campus',
        key: 'campus',
        width: 100,
        render: (text, record, idx) => {
          return {
            children: this.print_campus(this.get_instructor(record.instructor).campus),
            props: { rowSpan: record.index == 0 ? record.total : 0 },
          };
        },
      }, {
        title: 'Course',
        key: 'course',
        render: (text, record, idx) => this.link_course(record.course),
      }];

    columns = columns.concat(this.getYears().map(y => {
      return {
        title: "AY " + this.print_year(y),
        key: y,
        width: 275,
        render: (text, record, idx) => add_brs(data.filter(s => this.get_semester(s.semester).year == y && s.instructors.includes(record.instructor) && this.getCanonicalCourse(s.course) == record.course).map(s => <Link to={this.getLink("/teaching/schedules/section/" + s.id + "/")}>{[this.print_course(s.course), " ", s.crn, " (", this.print_semester(s.semester), ")"]}</Link>)),
      };
    }));

    columns.push({
      title: 'GFC',
      width: 75,
      align: 'center',
      render: (text, record, idx) => get_check_nocheck((this.getYears().map(y => data.find(s => this.get_semester(s.semester).year == y && s.instructors.includes(record.instructor) && this.getCanonicalCourse(s.course) == record.course)).filter(s => s == null).length <= 1) &&
        (this.getYears().slice(1, -1).map(y => data.find(s => this.get_semester(s.semester).year == y && s.instructors.includes(record.instructor) && this.getCanonicalCourse(s.course) == record.course)).filter(s => s == null).length == 0)),
    });

    const rows = this.getInstructors().map(i => { const courses = this.getCoursesForInstructor(i.id); return courses.map((c, idx) => { return { id: i.id + "-" + c, instructor: i.id, course: c, index: idx, total: courses.length }; }) }).flat();

    return (
      <Content {...this.props} title={"Good Faith Consideration"} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "Good Faith Consideration" }]}>
        <p>This page lists all part-time instructors who are marked as Active, the courses they are recorded as having taught in the prior three academic years, and whether their assignmnet has reached the threshold of Good Faith Consideration.</p>

        {loading ? <Spin title="Loading data" /> : (
          <Table {...this.props} dataSource={rows} columns={columns} bordered={false} pagination={false} size="small" rowKey="id" />
        )}
      </Content>
    );
  }
}

class IAList extends AppComponent {
  state = {
    endpoint: "/api/schedule/",
    loading: true,
    data: [],

    endpoint_oncalls: "/api/ia/oncall/",
    loading_oncalls: true,
    oncalls: [],
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    }
  }

  getData = () => {
    const { semester, semesters, campus } = this.props;

    this.doGet(this.state.endpoint + "?semester=" + semesters.join(',') + "&campus=" + campus,
      data => this.setState({ data: data, loading: false }));

    this.doGet(this.state.endpoint_oncalls + "?semester=" + semesters.join(',') + "&campus=" + campus,
      data => this.setState({ oncalls: data, loading_oncalls: false }));
  }

  render() {
    const { semesters } = this.props;
    const { loading, data, oncalls, loading_oncalls } = this.state;

    const sections = data.filter(s => (s.loadcount > 0) && s.room && !s.deleted)
      .filter(s => ["CS", "IS", "DS", "CY"].includes(this.get_subject(this.get_course(s.course).subject).abbrv))
      .filter(s => !["CS 1802", "CS 1101", "DS 2001", "CS 3001", "CS 1200", "CS 1210"].includes(this.print_course(s.course)));
    const meetingtimes_set = new Set(sections.map(s => s.meetingtime));
    const meetingtimes = this.meetingtime_list().filter(mt => meetingtimes_set.has(mt.id));

    const print_ia = ia => [ia.lastname, ", ", ia.firstname.charAt(0), "."];

    const print_single_section = s => [this.link_course(s.course), " section ", s.number, " (CRN ", this.link_section(s.id, s.crn), ")"];

    const print_section = section => {
      if (!section.crosslist) {
        return print_single_section(section);
      }

      const crosslist = data.find(s => s.id == section.crosslist);
      if (!crosslist) {
        return [print_single_section(section), " ", <Icon type="swap" />, " ?"];
      }
      if (this.print_course(crosslist.course) < this.print_course(section.course)) {
        return [];
      }

      return [print_single_section(section), " ", <Icon type="swap" />, " ", print_single_section(crosslist)];
    }

    return (
      <Content {...this.props} title={"Instructional Assistants"} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "Instructional Assistants" }]}>
        <p>This page lists all hired Instructional Assistants, who help with NUFlex in the classroom.</p>

        {loading || loading_oncalls ? <Spin title="Loading data" /> : (
          meetingtimes.map(mt => {
            const mysections = sections.filter(s => s.meetingtime == mt.id);
            const mysections_with_ias = mysections.filter(s => s.ias?.length > 0);
            const mysections_without_ias = mysections.filter(s => s.ias.length == 0);
            const myoncalls = oncalls.filter(oc => oc.meetingtime == mt.id);

            const chars = [
              { title: "Sections with IAs", content: mysections_with_ias?.length > 0 ? add_brs(mysections_with_ias.map(s => print_section(s)?.length > 0 ? [print_section(s), ": "].concat(oxford(s.ias.map(print_ia))) : []).filter(e => e?.length > 0)) : <i>None</i> },
              { title: "Sections without IAs", content: mysections_without_ias?.length > 0 ? add_brs(mysections_without_ias.map(s => print_section(s)).filter(e => e?.length > 0)) : <i>None</i> },
              { title: "On call", content: myoncalls?.length > 0 ? add_brs(myoncalls.map(print_ia)) : <i>None</i> }
            ];

            return (
              <React.Fragment>
                <Divider orientation="left">{this.print_meetingtime(mt.id)}</Divider>
                <List
                  grid={this.grid}
                  dataSource={chars}
                  renderItem={item => (<List.Item><Card size="small" title={item.title}>{item.content}</Card></List.Item>)}
                />
              </React.Fragment>
            );
          })
        )}
      </Content>
    );
  }
}

const CharterForm = Form.create({ name: 'form_in_modal' })(
  class extends AppComponent {
    formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, colon: true };

    render() {
      const { visible, onCancel, onCreate, form, charter, course } = this.props;
      const { getFieldDecorator } = form;

      return course ? (
        <Modal
          visible={visible}
          title={charter.id ? "Edit " + this.print_course(course) + " charter" : "Enter " + this.print_course(course) + " charter"}
          okText={charter.id ? "Save" : "Create"}
          onCancel={onCancel}
          onOk={onCreate}
          width={800}
        >
          <Form onSubmit={this.handleSubmit}>
            <p>Please use the form below to create or edit the charter for {this.print_full_course(course)}.  In all boxes, you are encouraged to use MarkDown format; you can find a cheat sheet <a target="_blank" href="https://www.markdownguide.org/cheat-sheet/">at this link</a>.  For example, you can make a list with embedded links with:</p>
            <pre>
              * Joe Smith, Alice Smith.  [Title](http://amazon.com/link).  Publisher.  Year
            </pre>
            <pre>
              * Another Author, Alice Smith.  [Title](http://amazon.com/link).  Publisher.  Year
            </pre>

            <FormItem {...this.formItemLayout} label="Textbooks" extra="Please enter the textbooks that are used for this course.  Feel free to include multiple options, and to indicate whether the textbooks are required or are optional.">
              {getFieldDecorator('textbooks', {
                rules: [{ required: true, message: 'Please provide information about textbooks.  If none are required, please say so.' }],
                initialValue: charter.textbooks,
              })(<TextArea rows={4} width={400} />
              )}
            </FormItem>

            <FormItem {...this.formItemLayout} label="Topics" extra="Please enter the major topics that will be covered in the course.">
              {getFieldDecorator('topics', {
                rules: [{ required: true, message: 'Please describe the topics of the course.' }],
                initialValue: charter.topics,
              })(<TextArea rows={4} width={400} />
              )}
            </FormItem>

            <FormItem {...this.formItemLayout} label="Activities" extra="Please enter the activities that students will be doing in the courses, whether the activities are graded or not.  Activities can include readings, research projects, assignments, labs, oral presentations, discussions, etc.">
              {getFieldDecorator('activities', {
                rules: [{ required: true, message: 'Please describe the activities students will complete.' }],
                initialValue: charter.activities,
              })(<TextArea rows={4} width={400} />
              )}
            </FormItem>

            <FormItem {...this.formItemLayout} label="Outcomes" extra="Please enter the outcomes will be expected of students once they complete the course.  Outcomes can include specific skills, concepts, etc.">
              {getFieldDecorator('outcomes', {
                rules: [{ required: true, message: 'Please describe the outcomes for the students.' }],
                initialValue: charter.outcomes,
              })(<TextArea rows={4} width={400} />
              )}
            </FormItem>

            <FormItem {...this.formItemLayout} label="Measurements" extra="Please enter the ways in which the student outcomes will be measured.  In other words, how will the students be graded in the course?">
              {getFieldDecorator('measurements', {
                rules: [{ required: true, message: 'Please describe the measurements.' }],
                initialValue: charter.measurements,
              })(<TextArea rows={4} width={400} />
              )}
            </FormItem>

            <FormItem {...this.formItemLayout} label="Links" extra="Please enter a few links to the URLs of recent instances of this course.">
              {getFieldDecorator('links', {
                initialValue: charter.links,
              })(<TextArea rows={4} width={400} />
              )}
            </FormItem>

            <FormItem {...this.formItemLayout} label="Notes" extra="Please enter any other notes about this course.">
              {getFieldDecorator('notes', {
                initialValue: charter.notes,
              })(<TextArea rows={4} width={400} />
              )}
            </FormItem>
          </Form>
        </Modal>
      ) : null;
    }
  }
);

class CharterList extends AppComponent {
  state = {
    endpoint: "/api/charters/",
    charters: [],
    loading: true,

    edit_charter: null,
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.doGet(this.state.endpoint, data => this.setState({ charters: data, loading: false }));
  }

  handleCreateUpdate = () => {
    const form = this.formRef.props.form;
    const { edit_charter } = this.state;

    form.validateFields((err, values) => {
      if (err) { return; }

      const charter = this.find_charter(edit_charter);

      if (charter.id) {
        this.doPatch(this.state.endpoint + charter.id + "/", () => { message.success("Edited charter."); this.setState({ edit_charter: null }, () => this.getData()); }, JSON.stringify(values));
      } else {
        values.course = edit_charter;
        this.doPost(this.state.endpoint, () => { message.success("Created charter."); this.setState({ edit_charter: null }, () => this.getData()); }, JSON.stringify(values));
      }
    });
  }

  find_charter = (id) => {
    const charter = this.state.charters.find(c => c.course == id);
    return charter ? charter : {};
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  render() {
    const { edit_charter } = this.state;

    var mycourses = this.course_list().filter(el => !el.deprecated && el.loadcount > 0);

    const columns = [
      {
        title: 'Course',
        key: 'name',
        width: 80,
        render: (text, record, idx) => this.link_course(record.id),
      }, {
        title: 'Title',
        dataIndex: 'title',
      }, {
        title: 'Topics',
        key: 'topics',
        render: (text, record, idx) => get_check_nocheck(this.find_charter(record.id).topics),
        align: 'center',
        width: 100,
      }, {
        title: 'Textbooks',
        key: 'textbooks',
        render: (text, record, idx) => get_check_nocheck(this.find_charter(record.id).textbooks),
        align: 'center',
        width: 100,
      }, {
        title: 'Outcomes',
        key: 'outcomes',
        render: (text, record, idx) => get_check_nocheck(this.find_charter(record.id).outcomes),
        align: 'center',
        width: 100,
      }, {
        title: 'Measurements',
        key: 'measurements',
        render: (text, record, idx) => get_check_nocheck(this.find_charter(record.id).measurements),
        align: 'center',
        width: 105,
      }, {
        title: 'Activities',
        key: 'activities',
        render: (text, record, idx) => get_check_nocheck(this.find_charter(record.id).activities),
        align: 'center',
        width: 100,
      }, {
        title: 'Links',
        key: 'links',
        render: (text, record, idx) => get_check_nocheck(this.find_charter(record.id).links),
        align: 'center',
        width: 100,
      }, {
        title: 'Actions',
        key: 'actions',
        render: (text, record, idx) => (<a onClick={() => this.setState({ edit_charter: record.id })}>{this.find_charter(record.id).id ? "Edit Charter" : "Create Charter"}</a>),
        align: 'center',
        width: 120,
      }];

    return (
      <Content {...this.props} title={"Course Charters"} breadcrumbs={[{ link: "/staff", text: "Staff" }, { text: "Charters" }]}>
        <p>Below is the list of all courses, along with the charters if they have been created.  You can use the links on the right to create or edit charters as needed.</p>
        <CustomTabs {...this.props} default_Active_Key="CS">
          {this.subject_list().map(el => {
            const subject_courses = mycourses.filter(c => c.subject == el.id);
            if (subject_courses.length) {
              return (<TabPane tab={el.abbrv + ": " + el.name} key={el.abbrv}>
                <Table {...this.props} dataSource={subject_courses} columns={columns} scroll={{ x: 1400 }} bordered={false} pagination={false} size="small" rowKey="id" key={"table-" + el.abbrv} />
              </TabPane>
              );
            } else {
              return null;
            }
          })}
        </CustomTabs>
        <CharterForm {...this.props} wrappedComponentRef={this.saveFormRef} visible={edit_charter != null} onCancel={() => this.setState({ edit_charter: null })} onCreate={this.handleCreateUpdate} course={edit_charter} charter={edit_charter ? this.find_charter(edit_charter) : {}} />
      </Content>
    );
  }
}

// ----- STUDENTS MODULE -----

class Students extends AppModule {
  static submodules(props) {
    return [
      {
        icon: "crown", name: "PhD", cards: [(<PhDCard {...props} />)], show: () => props.user.phd_student != null, items: [
          { icon: "file-text", name: "Academic Review", link: "/students/phd/review/" },
          { icon: "solution", name: "Course Waiver", link: "/students/phd/coursewaiver/" },
          { icon: "file-add", name: "Elective Petition", link: "/students/phd/electivepetition/" },
          { icon: "file-pdf", name: "Paper Requirement", link: "/students/phd/paperrequirement/" },
          { icon: "book", name: "Thesis Committee", link: "/students/phd/thesiscommittee/" },
          // { icon: "radar-chart", name: "Networked Devices", link: "/students/phd/networkeddevice/" }
        ]
      },
      {
        icon: "team", name: "TAs", cards: [(<TAApplicationCard {...props} />), (<IAAssignmentCard {...props} />)], show: "student", items: [
          { icon: "file-text", name: "Apply", link: "/students/ta/application/" },
          { icon: "check", name: "Accept", link: "/students/ta/accept/" },
          { icon: "dollar", name: "Hours", link: "/students/ta/hours/" },
        ]
      },
      {
        icon: "form", name: "Generic Petitions", show: () => props.user.phd_student == null || !props.user.phd_student_active, items: [
          { icon: "form", name: "Conference Support", link: "/students/ug-ms/conferencesupport/" }
        ]
      },
      {
        icon: "form", name: "Undergrad Petitions", show: () => props.user.phd_student == null, items: [
          { icon: "form", name: "Closed Course", link: "/students/ug/closedcourse/" },
          { icon: "project", name: "Concentration", link: "/students/ug/concentrationdeclaration/" },
          { icon: "plus", name: "Graduate Course", link: "/students/ug/plusone/" },
          { icon: "home", name: "Home College", link: "/students/ug/homecollegechange/" },
          { icon: "read", name: "Prerequisite Override", link: "/students/ug/prerequisiteoverride/" },
          { icon: "clock-circle", name: "Coop Appeal", link: "/students/ug/coopappeal/" },
          { icon: "fund", name: "Scholarships", link: "/students/ug/scholarshipapplication/" },
        ]
      },
      {
        icon: "form", name: "Graduate Petitions", show: () => props.user.phd_student == null || !props.user.phd_student_active, items: [
          { icon: "check-square", name: "Course Authorization", link: "/students/ms/courseauthorization/" },
          { icon: "solution", name: "Course/Credit Petition", link: "/students/ms/coursepetition/" },
          { icon: "file-add", name: "Elective Petition", link: "/students/ms/electivepetition/" },
          { icon: "form", name: "Thesis Form", link: "/students/ms/thesisform/" },
          { icon: "project", name: "Course Survey", link: "/students/ms/coursesurvey/" },
        ]
      },
      {
        icon: "form", name: "Res. Apprenticeship", show: "student", items: [
          { icon: "project", name: "Research Projects", link: "/students/researchprojects/" },
          { icon: "check-square", name: "Application", link: "/students/apprenticeapplication/" },
        ]
      }
    ];
  }

  render() {
    const sidemenu = this.renderSider(Students.submodules(this.props));

    return (
      <Layout>
        {sidemenu}
        <Layout style={{ padding: '0 0 0 0', marginTop: this.top_margin(), minHeight: "calc(100vh - " + this.top_margin() + "px)" }}>
          <Sentry.ErrorBoundary fallback={myFallback} beforeCapture={scope => { scope.setUser({ id: this.props.user.id }) }}>
            <RouterSwitch>
              <Route path="/students/ug/plusone/" render={(props) => <UGPlusOnePreferences {...this.props} {...props} />} />
              <Route path="/students/ug/prerequisiteoverride/" render={(props) => <UGPrerequisiteOverrideOverview {...this.props} {...props} />} />
              <Route path="/students/ug/scholarshipapplication/" render={(props) => <UGScholarshipApplicationOverview {...this.props} {...props} />} />
              <Route path="/students/ug/closedcourse/" render={(props) => <UGClosedCoursePetition {...this.props} {...props} />} />
              <Route path="/students/ug/homecollegechange/" render={(props) => <UGHomeCollegeChangeOverview {...this.props} {...props} />} />
              <Route path="/students/ug/concentrationdeclaration/" render={(props) => <UGConcentrationDeclarationOverview {...this.props} {...props} />} />
              <Route path="/students/ug/coopappeal/" render={(props) => <UGCoopAppealOverview {...this.props} {...props} />} />
              <Route path="/students/ug-ms/conferencesupport/" render={(props) => <UGMSConferenceSupportOverview {...this.props} {...props} />} />
              <Route path="/students/ta/application/" render={(props) => <TAApplication {...this.props} {...props} />} />
              <Route path="/students/ta/accept/" render={(props) => <TAAccept {...this.props} {...props} />} />
              <Route path="/students/ta/hours/" render={(props) => <TAHours {...this.props} {...props} />} />
              <Route path="/students/ta/audit/" render={(props) => <TAAudit {...this.props} {...props} />} />
              <Route path="/students/phd/networkeddevice/" render={(props) => <NetworkedDeviceListOverview {...this.props} {...props} />} />
              <Route path="/students/phd/review/" render={(props) => <PhDStudentSelfReview {...this.props} {...props} />} />
              <Route path="/students/phd/paperrequirement/" render={(props) => <PhDPaperRequirementOverview {...this.props} {...props} />} />
              <Route path="/students/phd/coursewaiver/" render={(props) => <PhDCourseWaiverOverview {...this.props} {...props} />} />
              <Route path="/students/phd/electivepetition/" render={(props) => <PhDElectivePetitionOverview {...this.props} {...props} />} />
              <Route path="/students/phd/thesiscommittee/" render={(props) => <PhDThesisApprovalOverview {...this.props} {...props} />} />
              <Route path="/students/ms/coursepetition/" render={(props) => <MSCourseCreditWaiverOverview {...this.props} {...props} />} />
              <Route path="/students/ms/electivepetition/" render={(props) => <MSElectivePetitionOverview {...this.props} {...props} />} />
              <Route path="/students/ms/courseauthorization/" render={(props) => <MSCourseAuthorizationOverview {...this.props} {...props} />} />
              <Route path="/students/ms/thesisform/" render={(props) => <MSThesisFormOverview {...this.props} {...props} />} />
              <Route path="/students/ms/coursesurvey/" render={(props) => <MSCourseSurvey {...this.props} {...props} />} />
              <Route path="/students/apprenticeapplication/" render={(props) => <ApprenticeStudentApplication {...this.props} {...props} />} />
              <Route path="/students/researchprojects/" render={(props) => <ResearchProjectTable {...this.props} {...props} />} />
              <Route path="/students/" render={(props) => <StudentHome {...this.props} {...props} submodules={Students.submodules(this.props)} />} />
            </RouterSwitch>
          </Sentry.ErrorBoundary>
        </Layout>
      </Layout>
    );
  }
}

// ----- Admin MODULE -----

class Admin extends AppModule {
  static submodules(props) {
    return [
      { icon: "bar-chart", name: "Grade Report", show: "admin", link: "/site-admin/gradereport/" },
      // {
      //   icon: "team", name: "Permissions", show: "admin", items: [
      //     { icon: "file-text", name: "View", link: "/site-admin/permissions/view/" }
      //   ]
      // },
      // { icon: "notification", name: "Notifications", show: "admin", link: "/site-admin/notifications/" },
      { icon: "dollar", name: "Grant Split Report", show: "admin", link: "/site-admin/grantsplitreport/" },
      { icon: "bar-chart", name: "PhD Funding Report", show: "admin", link: "/site-admin/phdfundingreport/" },
      { icon: "ordered-list", name: "Todos", link: "/site-admin/todos/" },
      { icon: "user-add", name: "Add Employee(s)", link: "/site-admin/employee/create/" },
    ];
  }

  render() {
    const sidemenu = this.renderSider(Admin.submodules(this.props));

    return (
      <Layout>
        {sidemenu}
        <Layout style={{ padding: '0 0 0 0', marginTop: this.top_margin(), minHeight: "calc(100vh - " + this.top_margin() + "px)" }}>
          <Sentry.ErrorBoundary fallback={myFallback} beforeCapture={scope => { scope.setUser({ id: this.props.user.id }) }}>
            <RouterSwitch>
              <Route path="/site-admin/gradereport/" render={(props) => <GradeReport {...this.props} {...props} />} />
              {/* <Route path="/site-admin/notifications/" render={(props) => <NotificationAdmin {...this.props} {...props} />} /> */}
              {/* <Route path="/site-admin/permissions/view/" render={(props) => <ViewPermissions {...this.props} {...props} />} /> */}
              <Route path="/site-admin/grantsplitreport/" render={(props) => <GrantSplitReport {...this.props} {...props} />} />
              <Route path="/site-admin/phdfundingreport/" render={(props) => <PhDFundingReport {...this.props} {...props} />} />
              <Route path="/site-admin/todos/" render={(props) => <TodoCreate {...this.props} {...props} />} />
              <Route path="/site-admin/employee/create/" render={(props) => <EmployeeCreateForm {...this.props} {...props} />} />
              <Route path="/site-admin/" render={(props) => <AdminHome {...this.props} {...props} submodules={Admin.submodules(this.props)} />} />
            </RouterSwitch>
          </Sentry.ErrorBoundary>
        </Layout>
      </Layout>
    );
  }
}

class GradeReport extends AppComponent {
  state = {
    endpoint: "/api/gradereport/",
    info: null,
  }

  render() {
    const { info } = this.state;

    return (
      <Content {...this.props} title={"Grade Report"} breadcrumbs={[{ link: "/site-admin", text: "Admin" }, { text: "Grade Report" }]}>
        <p>This interface is intended to allow administrators to upload the semester-by-semester grade report that the Registrar sends out.  This records the number of each type of grade that each CRN assigned, and is used to data analysis.</p>
        <p>The file is expected to be a <tt>.xlsx</tt> file, and should have the following columns: <tt>Academic Period</tt>, <tt>CRN</tt>, <tt>A</tt>, <tt>A-</tt>, <tt>B+</tt>, ... <tt>W</tt>.  Any additional columns will be ignored.</p>
        <Upload action={this.state.endpoint} headers={{ Authorization: this.getAuthorizationHeader() }} onChange={(info) => this.setState({ info: info })}>
          <Button type="primary">
            <Icon type="upload" /> Click to Upload
          </Button>
        </Upload>

        {info ?
          <React.Fragment>
            <Divider orientation="left">Result: {info.file.response ? info.file.response.success ? "Success" : "Failure" : "In Progress"}</Divider>
            {info.file.response && info.file.response.details && info.file.response.details.map(e =>
              <React.Fragment key={e.row}>
                <b>Row {e.row} ({e.course} CRN {e.crn}):</b> <font color={e.success ? "black" : "red"}>{e.success ? "Successfully uploaded row: " + e.message : "Failed to upload row: " + e.error}</font><br />
              </React.Fragment>
            )}
          </React.Fragment>
          : null}
      </Content>
    )
  }
}

class GrantSplitReport extends AppComponent {
  state = {
    endpoint: "/api/grantsplitreport/",
    info: null,
  }

  render() {
    const { info } = this.state;

    return (
      <Content {...this.props} title={"Grant Credit Split Report"} breadcrumbs={[{ link: "/site-admin", text: "Admin" }, { text: "Grant Credit Split Report" }]}>
        <p>This interface is intended to allow administrators to upload the report of credit split on grants by index that NU-RES shares.  It expects the format of report RSRCH0028.</p>
        <p>The file is expected to be a <tt>.xlsx</tt> file, and should have the following columns: <tt>Grant Code</tt>, <tt>Investigator</tt>, <tt>Award Split Percentage</tt>.  Any additional columns will be ignored.</p>
        <Upload action={this.state.endpoint} headers={{ Authorization: this.getAuthorizationHeader() }} onChange={(info) => this.setState({ info: info })}>
          <Button type="primary">
            <Icon type="upload" /> Click to Upload
          </Button>
        </Upload>

        {info ?
          <React.Fragment>
            <Divider orientation="left">Result: {info.file.response ? info.file.response.success ? "Success" : "Failure" : "In Progress"}</Divider>
            {info.file.response && info.file.response.details && info.file.response.details.map(e =>
              <React.Fragment key={e.row}>
                <b>Row {e.row}  :</b> <font color={e.success ? "black" : "red"}>{e.success ? "Successfully uploaded row: " + e.message : "Failed to upload row: " + e.error}</font><br />
              </React.Fragment>
            )}
          </React.Fragment>
          : null}
      </Content>
    )
  }
}

class PhDFundingReport extends AppComponent {
  state = {
    endpoint: "/api/phd/funding/report/",
    info: null,

    semester: null,
    append: false,
    test: true,
    ignore_missing_students: false,
  }

  render() {
    const { info, semester, append, test, ignore_missing_students } = this.state;

    const semester_list = this.semester_list().filter(s => s.speed == 1 && moment() > moment(s.enddate, "YYYY-MM-DD"));

    return (
      <Content {...this.props} title={"PhD Funding Report"} breadcrumbs={[{ link: "/site-admin", text: "Admin" }, { text: "PhD Funding Report" }]}>
        <p>This interface is intended to allow administrators to upload the semester-by-semester report of how PhD students were funded.  This records the funds against which PhD students were funded, and is used to teaching load and merit reports.</p>
        <p><b>NOTE:</b> This interface is written to accept <i>only</i> full reports for a given semester, and will <i>delete all existing PhD funding records</i> for the selected semester before adding the uploaded data.</p>
        <p>The file is expected to be a <tt>.xlsx</tt> file, and should have the following columns: <tt>SemesterCode</tt>, <tt>StudentNUID</tt>, <tt>Index</tt>, and <tt>Percentage</tt>.  The <tt>SemesterCode</tt> should be like "201910", and should <i>only</i> refer to Fall, Spring, or Full Summer semesters.  The <tt>Percentage</tt> should be the fraction of a full stipend a student was paid; these need not sum to 100 (i.e., if a student was on an internship).  Any additional columns will be ignored.</p>

        <p><b>Step 1:</b> Append or Replace?</p>
        <p><Radio.Group onChange={e => this.setState({ append: e.target.value })} value={append}>
          <Radio value={true}>Append records</Radio>
          <Radio value={false}>Replace all non-manual records</Radio>
        </Radio.Group></p>
        <p><Checkbox onChange={e => this.setState({ ignore_missing_students: e.target.checked })} checked={ignore_missing_students}>Ignore missing students</Checkbox></p>
        <p><Checkbox onChange={e => this.setState({ test: e.target.checked })} checked={test}>Test run (no changes made)</Checkbox></p>

        <p><b>Step 2:</b> Select a semester (only for replacing records)</p>
        <p><Select style={{ width: 150 }} onChange={s => this.setState({ semester: s })} disabled={append}>
          {semester_list.map(s => <Option value={s.id} key={s.id}>{this.print_semester(s.id)}</Option>)}
        </Select> </p>

        <p><b>Step 3:</b> Upload a data file</p>
        <Upload action={this.state.endpoint + "?semester=" + semester + "&append=" + append + "&test=" + test + "&ignore=" + ignore_missing_students} headers={{ Authorization: this.getAuthorizationHeader() }} onChange={(info) => this.setState({ info: info })} disabled={!append && semester == null}>
          <Button type="primary">
            <Icon type="upload" /> Click to Upload
          </Button>
        </Upload>

        {info ?
          <React.Fragment>
            <Divider orientation="left">Result: {info.file.response ? info.file.response.success ? "Success" : "Failure -- No changes made" : "In Progress"}</Divider>
            {info.file.response && info.file.response.details && info.file.response.details.map(e =>
              <React.Fragment key={e.row}>
                <b>Row {e.row} ({e.course} NUID {e.nuid} Index {e.index}):</b> <font color={e.success ? "black" : "red"}>{e.success ? "Successfully uploaded row: " + e.message : "Failed to upload row: " + e.error}</font><br />
              </React.Fragment>
            )}
          </React.Fragment>
          : null}
      </Content>
    )
  }
}

class MailinglistsList extends AppComponent {
  state = {
    endpoint: "/api/mailinglist/faculty/",
    data: [],
    loading: true,
  };

  componentDidMount() {
    this.doGet(this.state.endpoint, data => this.setState({ data: data, loading: false }));
  }

  render() {
    const { errors, loading, data } = this.state;

    const categories = [...new Set(data.map(l => l.category))].sort();

    return (
      <Content {...this.props} title={"Mailing Lists"} breadcrumbs={[{ link: "/employee", text: "Employee" }, { text: "Mailing Lists" }]}>
        <p>Below is a list of active mailing lists in Khoury.  If you are a admin, you can see all lists in the College; otherwise only lists you are on or can send to are shown.</p>
        <p>The names of the various mailing lists (and the ranks/types/subtypes which have mailing lists) are specified in the database, and can be updated if needed.  Note that the members of a mailing list are limited to those who (a) have logged into this site at some point, (b) have their rank listed correctly in the database, and (c) have a verified Northeastern email address attached to the profile.  Those who do not yet have a Northeastern email address will be prompted for one the next time they log in.</p>
        <p>If you believe any of these lists are incorrect, please use the Provide Feedback link in the User menu above to inform us and we will investigate the issue.</p>
        {loading ? <Spin tip="Loading mailing lists" /> :
          <CustomTabs {...this.props}>
            {categories.map(c =>
              <TabPane tab={c} key={c}>
                {data.filter(l => l.category == c).sort((a, b) => a.list_name.localeCompare(b.list_name)).map(ml => (
                  <div><p><b><a href={"mailto:" + ml.list_name + "@lists.ccs.neu.edu"}>{ml.list_name}</a></b>: {ml.description}<br />Old mailing list name: <i>{ml.alias_name ? ml.alias_name : "None"}</i><br />Members with NU email: <i>{ml.list_members.map(m => m.firstname + " " + m.lastname).join(", ")}</i><br />Members without NU email: <i>{ml.list_members_without_NU_email.map(m => m.firstname + " " + m.lastname).join(", ")}</i><br />Members without admin.khoury account: <i>{ml.list_members_without_account.map(m => m.firstname + " " + m.lastname).join(", ")}</i><br />Authorized senders: <i>{ml.authorized_senders.map(m => m.firstname + " " + m.lastname + (m.email ? " (" + m.email + ")" : "")).join(", ")}</i></p></div>
                ))}
              </TabPane>
            )}
          </CustomTabs>
        }
      </Content>
    );
  }
}


export default Root;
